<template>
  <div class="pt-2">
    <v-row class="px-3">
      <v-col cols="8">
        <v-tabs v-model="tab" background-color="transparent">
          <v-tab>Structure</v-tab>
          <v-tab>Validation</v-tab>
          <v-tab>Relationships</v-tab>
          <v-tab>Copy Hierarchy Exclusions</v-tab>
          <v-tab>Export Sections</v-tab>
          <v-tab>Export Format</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="4" class="d-flex align-center">
        <v-btn
          @click="addTemplate"
          title="New Template"
          class="mr-2"
          icon>
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
        <v-select
          label="Template"
          dense
          outlined
          v-model="template"
          :items="templates"
          item-text="tmpl_name"
          return-object
          hide-details
        >
          <template v-slot:selection="{ item }">
            <span :class="!item.tmpl_active ? 'text-decoration-line-through' : ''">
              {{ item.tmpl_name }}
            </span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item ripple>
              <v-list-item-content>
                <v-list-item-title :class="!item.tmpl_active ? 'text-decoration-line-through' : ''">
                  {{ item.tmpl_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>

        </v-select>
      </v-col>
    </v-row>
    <v-tabs-items class="mt-3" v-model="tab">
      <v-tab-item>
        <TemplateStructure v-if="template && tab === 0" v-model="template.tmpl_id" @headerUpdated="headerUpdated">
        </TemplateStructure>
      </v-tab-item>
      <v-tab-item>
        <TemplateValidation v-if="template && template.tmpl_id && tab === 1" v-model="template.tmpl_id">
        </TemplateValidation>
      </v-tab-item>
      <v-tab-item>
        <TemplateLinks v-if="template && template.tmpl_id && tab === 2" v-model="template.tmpl_id">
        </TemplateLinks>
      </v-tab-item>
      <v-tab-item>
        <TemplateHierarchyCopy v-if="template && template.tmpl_id && tab === 3" v-model="template.tmpl_id">
        </TemplateHierarchyCopy>
      </v-tab-item>
      <v-tab-item>
        <TemplateExportSections v-if="template && template.tmpl_id && tab === 4" v-model="template.tmpl_id">
        </TemplateExportSections>
      </v-tab-item>
      <v-tab-item>
        <TemplateExportFormat v-if="template && template.tmpl_id && tab === 5" v-model="template.tmpl_id">
        </TemplateExportFormat>
      </v-tab-item>
    </v-tabs-items>

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ResponseHandler from "@/components/ResponseHandler";
import TemplateStructure from "@/components/templateBuilder/cTemplateBuilderStructure";
import TemplateValidation from "@/components/templateBuilder/cAdminTemplateValidation";
import TemplateLinks from "@/components/templateBuilder/cTemplateBuilderLinks";
import TemplateHierarchyCopy from "@/components/templateBuilder/cTemplateBuilderHierarchyCopy";
import TemplateExportSections from "@/components/templateBuilder/cTemplateExportSections";
import TemplateExportFormat from "@/components/templateBuilder/cTemplateExportFormat";

export default {
  name: "vLandingConfig",
  components: {
    ResponseHandler,
    TemplateStructure,
    TemplateValidation,
    TemplateLinks,
	TemplateHierarchyCopy,
  TemplateExportSections,
  TemplateExportFormat
  },
  props: {},
  data: function () {
    return {
      isLoading: false,
      response: null,
      templates: [],
      template: null,
      tab: 0,
      showTemplateSettings: false,
      showTemplateEditor: false,
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  watch: {
    hierarchiesLoading(val) {
      if (!val) this.init();
    },
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
      docsLoading: (state) => state.docs.loading,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      docTypes: (state) => state.hierarchies.docTypes,
    })
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("templateBuilder/templateList")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const templates = resp.data.Data;
            this.templates = templates;
            if (this.templates.length !== 0) this.template = this.templates[0];
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    editTemplate(t) {
      this.template = t;
      this.showTemplateEditor = true;
    },
    headerUpdated(data) {
      const template = this.templates.find(t => t.tmpl_id === data.tmpl_id);
      if (template) {
        template.tmpl_name = data.tmpl_name;
        template.tmpl_active = data.tmpl_active;
      } else {
        this.templates.push(data);
        this.template = data;
      }
    },
    addTemplate() {
      this.tab = 0;
      this.template = {
        tmpl_id: null,
        tmpl_name: "Unknown",
        tmpl_active: 1
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

tr.inactive {
  background-color: #eee;
}

.v-tabs-items {
  background-color: transparent;
}
</style>