<template>
  <v-card flat style="max-height:100%; overflow-y: scroll; padding: 0 !important; margin: 0 !important;">
    <v-card-title v-if="config.contentGenerationConfig.enableGenerateSkills || config.contentGenerationConfig.enableGenerateJD"
      class="mb-6">
      <div>
        <v-tabs v-model="databaseTab">
          <v-tab>Library</v-tab>
          <v-tab v-if="config.contentGenerationConfig.enableGenerateSkills">Skills Generator</v-tab>
          <v-tab v-if="config.contentGenerationConfig.enableGenerateJD">JD Generator</v-tab>
        </v-tabs>
      </div>

      <v-spacer></v-spacer>
      <v-btn small icon @click="closeDatabasePanel()">
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title v-else class="pb-0">
      Database
      <v-spacer></v-spacer>
      <v-btn small icon @click="closeDatabasePanel()">
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text :class="[selectDoc ? 'docSelect' : 'docView']">
              <v-tabs-items v-model="databaseTab">
                <v-tab-item>
                  <v-row v-if="selectDoc && !databaseLoading">
                    <v-col cols="4">
                      <v-select 
                        dense
                        outlined 
                        placeholder="Source"
                        v-model="databaseDocSource"
                        multiple
                        :items="databaseSourceItems"
                        @change="filterDatabaseDocs(true)"
                        :menu-props="{
                          closeOnContentClick: false,
                          maxHeight: '400',
                          'offset-y': true,
                        }"
                        hide-details
                      >
                        <template v-slot:selection="{ item, index }">
                          {{ getSourceFilterText(item, index) }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        dense
                        outlined
                        append-icon="mdi-magnify"
                        placeholder="Search"
                        v-model="databaseFilters.text"
                        @keyup="filterDatabaseDocs(false, true)"
                        @click:append="filterDatabaseDocs(false)"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="d-flex align-center justify-end">
                      <v-menu
                        offset-y left
                        :close-on-content-click="false"
                        v-if="databaseDocSource.includes('External') && externalContentSource !== 'local'"
                        v-model="databaseFilters.showMenu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs" 
                            v-on="on"
                            icon>
                            <v-icon>mdi-filter</v-icon>
                          </v-btn>
                        </template>
                        <v-card class="overflow-hidden pa-3" width="500">
                          <v-row dense>
                            <v-col cols="6">
                              <v-select
                                v-model="databaseFilters.includedContractTypes"
                                dense
                                outlined
                                label="Contract Types"
                                multiple
                                :items="databaseFilters.availableContractTypes"
                                item-text="text"
                                item-value="value"
                                :menu-props="{ offsetY: true }"
                                v-on:keyup.enter="filterDatabaseDocs(false)"
                                hide-details
                                menu-off>
                              </v-select>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                v-model="databaseFilters.includedPTFT"
                                dense
                                outlined
                                label="Part/Full time"
                                multiple
                                :items="databaseFilters.availablePTFT"
                                item-text="text"
                                item-value="value"
                                :menu-props="{ offsetY: true }"
                                v-on:keyup.enter="filterDatabaseDocs(false)"
                                hide-details
                                menu-off>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-text-field
                                v-model="databaseFilters.minSalary"
                                dense
                                outlined
                                label="Min Salary"
                                type="Number"
                                v-on:keyup.enter="filterDatabaseDocs(false)"
                                hide-details>
                              </v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="databaseFilters.maxSalary"
                                dense
                                outlined
                                label="Max Salary"
                                type="Number"
                                v-on:keyup.enter="filterDatabaseDocs(false)"
                                hide-details>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-text-field
                                v-model="databaseFilters.location"
                                dense
                                outlined
                                label="Location"
                                v-on:keyup.enter="filterDatabaseDocs(false)"
                                hide-details>
                              </v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                v-model="databaseFilters.distance"
                                dense
                                outlined
                                label="Distance"
                                item-text="text"
                                item-value="value"
                                v-on:keyup.enter="filterDatabaseDocs(false)"
                                :items="databaseFilters.distances"
                                hide-details>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" class="d-flex justify-end align-center">
                              <v-btn color="primary" @click="filterDatabaseDocs(false)">
                                Search
                                <v-icon right>mdi-magnify</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectDoc && !databaseLoading">
                    <v-col
                      cols="12"
                      v-if="databaseViewMode === 'list'"
                      style="padding-right: 3px"
                    >
                      <v-data-table
                        dense
                        :footer-props="{
                          'items-per-page-options': [15, 50, 100, -1],
                        }"
                        :headers="databaseDocHeaders"
                        :items="databaseFilteredDocs"
                        item-key="doc_id"
                        style="width: 100%; margin-right: 3px"
                        class="elevation-0"
                      >
                        <template v-slot:[`item.source`]="{ item }">
                          <v-chip
                            small
                            class="status-chip"
                            label
                            :color="getSourceColor(item)"
                            >{{ item.source }}</v-chip
                          >
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            small
                            @click="viewDatabaseDoc(item)"
                            color="primary"
                          >
                            View
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <div
                    v-if="databaseLoading"
                    class="d-flex text-center align-center mt-6 flex-column"
                  >
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                      style="top: 45%"
                      class="mb-3"
                    ></v-progress-circular>
                    <p class="body-1">
                      Hey there, your content is on its way!<br />
                      Hang tight and get ready to be amazed
                    </p>
                  </div>
                  <v-row v-else-if="!selectDoc">
                    <v-col cols="12" dense class="d-flex">
                      <v-btn
                        v-if="selectDoc === false"
                        small
                        @click="toggleDatabaseDisplay"
                        color="secondary"
                        class="userPilotReturnToListBtn"
                      >
                        <v-icon left>mdi-chevron-double-left</v-icon>
                        Return to List
                      </v-btn>
                      <v-spacer></v-spacer>

                      <span
                        v-if="
                          databaseSelectionState.dragStart &&
                          databaseSelectionState.data === ''
                        "
                        style="color: orange"
                        ><v-icon small color="orange"
                          >mdi-close-octagon-outline</v-icon
                        >No Data selection</span
                      >
                      <span
                        dense
                        :title="databaseSelectionState.data"
                        style="color: green"
                        v-else-if="databaseSelectionState.data !== ''"
                        ><v-icon small color="green"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        Data Selected Succesfully</span
                      >
                      <div v-else-if="databaseItem" class="d-flex align-center mx-4">
                        <h3>{{ databaseItem.file_name }}</h3>
                        <v-btn small icon title="Download File" v-if="databaseItem.file_id" @click="nlpUtils.downloadFile(databaseItem.file_id)">
                          <v-icon>download</v-icon>
                        </v-btn>
                      </div>
                      
                      <v-spacer></v-spacer>
                      <template v-if="config.contentGenerationConfig.enableOptimize">
                        <v-btn color="primary" small
                          v-if="generatedData && generatedData.optimise && generatedData.showOptimise"
                          @click="generatedData.showOptimise = false"
                          class="userPilotShowOriginalBtn"
                        >
                          Show Original
                        </v-btn>
                        <v-btn v-else color="primary" small @click="generateContent('optimise')"
                          class="userPilotOptimiseBtn">
                          Optimise
                          <v-icon right>mdi-creation</v-icon>
                        </v-btn>
                      </template>
                    </v-col>
                    <div
                      @mousemove="makeSelection($event)"
                      @mousedown="registerClick($event)"
                      @mouseup="mouseUpEvent($event, true)"
                      class="pl-4 pb-4"
                    >
                      <div
                        v-if="generatedData && generatedData.optimise && generatedData.showOptimise"
                        class="draggableText"
                        @contextmenu="show"
                        @dragstart="dragStart"
                        @dragend="dragEnd"
                      >
                        <template v-if="generatedData.optimise">
                          <template v-for="(t, ti) in generatedData.optimise">
                            <h3 v-if="t.header" :class="['mb-2', 'userPilotOAIData' + t.header.replace(/\s+/g, '')]" :key="'h' + ti">{{ t.header }}</h3>
                            <p v-if="t.isList && t.parts && t.parts.length" :key="'l' + ti">
                              <ul>
                                <li v-for="(r, ri) in t.parts" :key="`rl_${ti}_${ri}`">
                                    {{ r }}
                                </li>
                              </ul>
                            </p>
                            <template v-else-if="t.parts && t.parts.length" v-for="(r, ri) in t.parts" >
                              <p :key="`rp_${ti}_${ri}`">{{ r }}</p>
                            </template>
                          </template>
                        </template>
                      </div>
                      <template v-else>
                        <v-alert v-if="documentSource === 'External' && externalContentSimilarity > externalContentMaxSimilarity && config.externalContentConfig.similarityError" text type="error">
                          <span v-html="config.externalContentConfig.similarityError"></span>
                          <span style="float:right; margin-right: 5px">Similarity: {{ Math.round(externalContentSimilarity * 100) }}&percnt;</span>
                        </v-alert>
                        <v-alert v-else-if="documentSource === 'External' && config.externalContentConfig.disclaimer" text type="warning">
                          <span v-html="config.externalContentConfig.disclaimer"></span>
                          <span style="float:right; margin-right: 5px">Similarity: {{ Math.round(externalContentSimilarity * 100) }}&percnt;</span>
                        </v-alert>

                        <span
                          class="draggableText"
                          @contextmenu="show"
                          @dragstart="dragStart"
                          @dragend="dragEnd"
                          v-html="originalDocText"
                        ></span>
                      </template>
                    </div>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row>
                    <v-col cols="9">
                      <v-text-field
                        dense
                        outlined
                        placeholder="Job Title"
                        v-model="generateJobTitle"
                        v-on:keyup.enter="generateContent('skills')"
                        hide-details
                        clearable
                        class="userPilotOAISkillsInput"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-end">
                      <v-btn
                        color="primary"
                        @click="generateContent('skills')"
                        :disabled="databaseLoading"
                        class="userPilotOAISkillsBtn"
                      >
                        Generate
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div
                    v-if="databaseLoading"
                    class="d-flex text-center align-center mt-6 flex-column"
                  >
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                      style="top: 45%"
                      class="mb-3"
                    ></v-progress-circular>
                    <p class="body-1" v-html="config.contentGenerationConfig.disclaimer">
                    </p>
                  </div>
                  <v-row v-else-if="generatedData && generatedData.skills"
                    class="mx-2 mt-4" dense
                    @mousemove="makeSelection($event)"
                    @mousedown="registerClick($event)"
                    @mouseup="mouseUpEvent($event, true)"
                  >
                    <div
                      class="draggableText"
                      @contextmenu="show"
                      @dragstart="dragStart"
                      @dragend="dragEnd"
                    >
                      <template v-if="generatedData.skills">
                        <template v-for="(t, ti) in generatedData.skills">
                          <h3 v-if="t.header" :class="['mb-2', 'userPilotOAIData' + t.header.replace(/\s+/g, '')]" :key="'h' + ti">{{ t.header }}</h3>
                          <p v-if="t.isList && t.parts && t.parts.length" :key="'l' + ti">
                            <ul>
                              <li v-for="(r, ri) in t.parts" :key="`rl_${ti}_${ri}`">
                                  {{ r }}
                              </li>
                            </ul>
                          </p>
                          <template v-else-if="t.parts && t.parts.length" v-for="(r, ri) in t.parts" >
                            <p :key="`rp_${ti}_${ri}`">{{ r }}</p>
                          </template>
                        </template>
                      </template>
                    </div>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row>
                    <v-col cols="9">
                      <v-text-field
                        dense
                        outlined
                        placeholder="Job Title"
                        v-model="generateJobTitle"
                        v-on:keyup.enter="generateContent('description')"
                        hide-details
                        clearable
                        class="userPilotOAIJDGenInput"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-end">
                      <v-btn
                        color="primary"
                        @click="generateContent('description')"
                        :disabled="databaseLoading"
                        class="userPilotOAIJDGenBtn"
                      >
                        Generate
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div
                    v-if="databaseLoading"
                    class="d-flex text-center align-center mt-6 flex-column"
                  >
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                      style="top: 45%"
                      class="mb-3"
                    ></v-progress-circular>
                    <p class="body-1" v-html="config.contentGenerationConfig.disclaimer">
                    </p>
                  </div>
                  <v-row v-else-if="generatedData && generatedData.description"
                    class="mx-2 mt-4" dense
                    @mousemove="makeSelection($event)"
                    @mousedown="registerClick($event)"
                    @mouseup="mouseUpEvent($event, true)"
                  >
                    <div
                      class="draggableText"
                      @contextmenu="show"
                      @dragstart="dragStart"
                      @dragend="dragEnd"
                    >
                      <template v-if="generatedData.description">
                        <template v-for="(t, ti) in generatedData.description">
                          <h3 v-if="t.header" :class="['mb-2', 'userPilotOAIData' + t.header.replace(/\s+/g, '')]" :key="'h' + ti">{{ t.header }}</h3>
                          <p v-if="t.isList && t.parts && t.parts.length" :key="'l' + ti">
                            <ul>
                              <li v-for="(r, ri) in t.parts" :key="`rl_${ti}_${ri}`">
                                  {{ r }}
                              </li>
                            </ul>
                          </p>
                          <template v-else-if="t.parts && t.parts.length" v-for="(r, ri) in t.parts" >
                            <p :key="`rp_${ti}_${ri}`">{{ r }}</p>
                          </template>
                        </template>
                      </template>
                    </div>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
              
              
            </v-card-text>
  </v-card>
</template>
  
<script>
import axios from "axios";
import nlpUtils from "@/common/nlpUtils.js";
// import utils from "@/common/utils.js";
import { mapState } from "vuex";

export default {
    name: "ContentDatabase",
    props: {
      data: Object,
      isNew: Boolean,
      multidoc: Boolean
    },
    data: function () {
        return {
          document: null,
          databaseFilteredDocs: [],
          uploadedDocLink: [],
          databaseLoading: false,
          selectDoc: true,
          documentSource: null,
          originalDocText: "",
          unmodifiedDocText: "",    
          filteredSuggestions: [],
          databaseSelectionState: {
            mouseDown: false,
            mouseUp: false,
            mouseMove: false,
            dragStart: false,
            dragEnd: false,
            data: "",
          },
          databaseSourceItems: [
            "Internal",
            "Upload"
          ],
          fileItems: [],
          filteredFileItems: [],
          databaseDocSource: [ "Internal", "Upload" ],
          databaseViewExternalDocs: false,
          suggestionSourceItems: [
            "Internal"
          ],
          docStatusAllowed: ["approved"],
          databaseDocTypeFilter: [],
          suggestionsSourceIncluded: [],
          externalContentSource: "local",
          databaseDocStatusIncluded: [],
          documentLanguage: { text: "English", value: "en", flagCode: "gb" },
          databaseTab: 0,
          databaseFilters: {
            text: "",
            showMenu: false,
            includedContractTypes: [],
            availableContractTypes: [
              { text: "Permanent", value: "permanent" },
              { text: "Contract", value: "contract" },
              { text: "Temp", value: "temp" }
            ],
            includedPTFT: [],
            availablePTFT: [
              { text: "Full Time", value: "fullTime" },
              { text: "Part Time", value: "partTime" }
            ],
            minSalary: "",
            maxSalary: "",
            location: "",
            distance: 10,
            distances: [
              { text: "10 miles", value: 10 },
              { text: "20 miles", value: 20 },
              { text: "50 miles", value: 50 },
              { text: "50 miles", value: 100 }
            ]
          },
          generateJobTitle: "",
          generatedData: {
            optimise: null,
            showOptimise: false,
            description: null,
            skills: null
          },
          databaseFilterTimeout: null,
          databaseDocHeaders: [
            { text: "Source", value: "source" },
            { text: "Job Title", value: "doc_name" },
            { text: "", value: "actions", sortable: false },
          ],
          databaseViewMode: "list",    
          
          databaseItem: null,      
        };
    },
    components: {},
    created() {
        this.init();
    },
    watch: {

    },
    computed: {
      ...mapState({
        config: (state) => state.settings.config,
        newUi: (state) => state.theme.newUi,
        statuses: (state) => state.hierarchies.statuses,
        docTypeSummary: (state) => state.docs.docTypeSummary,
        docStatusSummary: (state) => state.docs.docStatusSummary,
        cleanItems: (state) => state.docs.docsList,
      }),
    },
    methods: {
      loadExternalContentList(){
        this.databaseFilteredDocs.splice(0);
        let possibleError = false;
        this.databaseLoading = true;
        axios
          .get("externalContent/list", { 
            params: {
              jobTitle: this.databaseFilters.text || this.document?.doc_name,
              contractTypes: this.databaseFilters.includedContractTypes,
              ptft: this.databaseFilters.includedPTFT,
              minSalary: this.databaseFilters.minSalary,
              maxSalary: this.databaseFilters.maxSalary,
              location: this.databaseFilters.location,
              distance: this.databaseFilters.distance
            }
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              this.databaseFilteredDocs.push(...resp.data.Data.data);
              this.externalContentSource = resp.data.Data.source;
            }
            this.databaseLoading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.databaseLoading = false;
          });
      },      
      initSettings(){
        this.databaseViewEnabled = false;
        let setting = this.$loginState.user.settings.find(
          (s) => s.setting === "document_use_database_view"
        );
        let loadLinksSetting = this.$loginState.user.settings.some(
          (s) =>
            s.setting === "document_database_view_load_links" &&
            s.value === "true"
        );

        this.databaseViewExternalDocs = this.$loginState.user.settings.some(
          (s) =>
            s.setting === "document_database_view_allow_external" &&
            s.value === "true"
        );

        let docStatusSetting =  this.$loginState.user.settings.find(
          (s) => s.setting === "document_database_view_statuses"
        );
        if(docStatusSetting){
          this.docStatusAllowed = [
            ...this.docStatusAllowed.map(x => { return x.toLowerCase()}),
            ...JSON.parse(docStatusSetting.value).map(x => { return x.toLowerCase()})
          ];
        }

        if (!this.multidoc && this.document.uploadedDocLink.length) {
          this.sourceDoc = {
            doc_type: "External File",
            file_data: {
              file_id: this.document.uploadedDocLink[0].file_id,
              row_id: this.document.uploadedDocLink[0].row_id,
            },
          };
          this.selectDoc = false;
        }

        if (!loadLinksSetting) {
          this.showDatabasePanel = false;
        }

        if (!this.multidoc && setting !== undefined) {
          let docTypes = setting.value.split("|").map((a) => {
            let parts = a.split(":");
            if (parts.length === 2) {
              return { doc_type: parts[0], value: parts[1] };
            }
          });

          let currentDoc = docTypes.find(
            (x) => x.doc_type === this.document.doc_type
          );
          if (currentDoc !== undefined) {
            this.databaseViewEnabled = currentDoc.value === "true";

            if (
              this.databaseViewEnabled &&
              this.uploadedDocLink.length > 0 &&
              (loadLinksSetting || this.isNew) &&
              (this.document.language_code === "" || 
                (this.document.language_code === "en" &&
                //this.document.state.canEditDocParts))
                (this.document.state.uses_workflow && this.document.state.canEditDocParts) || !this.document.state.uses_workflow))
            ) {
              this.showDatabasePanel = true;
              this.showDatabase();
              this.sourceDoc = {
                doc_type: "External File",
                file_data: {
                  file_id: this.document.uploadedDocLink[0].file_id,
                  row_id: this.document.uploadedDocLink[0].row_id,
                },
              };
              this.compareFileInline(this.sourceDoc);
            }
          }
        }
      },
      init(){
        if(!this.multidoc){
          this.document = this.data.document;
          this.uploadedDocLink = this.document.uploadedDocLink;
        } 

        this.initSettings();
        this.showDatabase();

      },
      closeDatabasePanel(){
        this.$emit("closeDatabasePanel");
      },
      filterDatabaseDocs(reset, debounce) {
        if (debounce) {
          //throttle so as not to run search too frequently
          if (this.databaseFilterTimeout) {
            clearTimeout(this.databaseFilterTimeout);
            this.databaseFilterTimeout = null;
          }

          this.databaseFilterTimeout = setTimeout(() => this.filterDatabaseDocs(reset, false), 500);
          return;
        }

        this.databaseFilters.showMenu = false;
        this.databaseFilteredDocs.splice(0);
        if (reset) {
          this.databaseFilters.includedContractTypes.splice(0);
          this.databaseFilters.includedPTFT.splice(0);
          this.databaseFilters.minSalary = "";
          this.databaseFilters.maxSalary = "";
          this.databaseFilters.location = "";
          this.databaseFilters.distance = 10;
        }
        const hasAdvFilter = !!this.databaseFilters.includedContractTypes.length
          || !!this.databaseFilters.includedPTFT.length
          || !!this.databaseFilters.minSalary
          || !!this.databaseFilters.maxSalary
          || !!this.databaseFilters.location;

        let currentUser = this.$loginState.user.user_id;
        let docTypes = this.databaseDocTypeFilter.map((dbDoc) => dbDoc.docType);
        
        if (this.databaseDocSource.includes("External")) {
          this.loadExternalContentList();
        }
        
        if(this.databaseDocSource.includes("Internal") && !hasAdvFilter) {
          this.databaseFilteredDocs.push(...this.cleanItems.filter((x) => {
            let docTypeIncl = docTypes.indexOf(x.doc_type) > -1;
            
            let docStatusIncl = this.docStatusAllowed.indexOf(x.lifecycle_status_name ? x.lifecycle_status_name.toLowerCase() : x.doc_status.toLowerCase()) > -1;
            //let docStatusIncl = true;
            let matchesText = true;
            let addFile = false;

            if (x.doc_type === "External File") {
              //let externalFile = false;
              let isPrivate =
                x.file_data !== undefined && x.file_data.private === 1;
              let wasCreatedByUser = x.file_data.created_by === currentUser;
              if (!isPrivate || (isPrivate && wasCreatedByUser)) {
                addFile = true;
                //todo
              }
            } else {
              addFile = true;
            }

            if (this.databaseFilters.text !== "") {
              let searchText = this.databaseFilters.text
                .split(" ")
                .filter((x) => x.length)
                .map((x) => x.toLowerCase());

              matchesText = searchText.every(
                (s) => x.match.toLowerCase().indexOf(s, 0) >= 0
              );
            }

            if (docTypeIncl && docStatusIncl && matchesText && addFile) {
              return x;
            }
          }).map(x => {
            return {
              ...x,
              source: "Internal"
            }
          }));
        }
        
        if(this.databaseDocSource.includes("Upload") && !hasAdvFilter) {
          if(this.fileItems.length){
            this.databaseFilteredDocs.push(...this.fileItems.filter(f => {
              let matchesText = true;
              if (this.databaseFilters.text !== "") {
                matchesText = f.file_name.toLowerCase().indexOf(this.databaseFilters.text.toLowerCase()) > -1;
              }

              if (matchesText &&f.file_extension === "docx") {
                return f;
              }

            }).map(f => {
              return {
                ...f,
                doc_name: f.file_name,
                source: "Upload"
              }
            }));
          }
        }
      },
      async showDatabase() {
        //this.setupUploadData();
        this.databaseUploadDisabled = true;
        this.databaseUploadMulti = "false";
        this.$nextTick(() => {
          this.databaseUploadDisabled = false;
          this.databaseUploadMulti = "true";
        });
        this.loadDatabaseDocs();
        await this.initNlpOptions();
        //this.resetRightPanel();
        this.showDatabasePanel = true;
      },
      viewDatabaseDoc(item){
        this.tempNlpText = "";
        this.tempNlpHtml = "";
        if(item.source === "Upload"){
          this.compareFile(item.file_id,"External File",null);
        } else if (item.source === "Internal") {
          this.compareFile(item.system_number, item.doc_type);
        } else if(item.source === "External") {
          this.getExternalContentDetail(item.id);
        }
      },      
      getExternalContentDetail(id) {
        this.databaseLoading = true;
        axios
          .get("externalContent/detail/" + id)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.documentSource = "External";
              this.originalDocText = resp.data.Data.data;
              this.unmodifiedDocText = resp.data.Data.data;
              this.selectDoc = false; 
            }
            this.databaseLoading = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
            this.databaseLoading = false;
          });
      },      
      async compareFile(id, type, row_id) {
        this.databaseLoading = true;
        if (type === "External File") {
          await nlpUtils.getDocText(id, row_id).then((response) => {
            if (response.status == 200) {
              let nlpOptions = nlpUtils.getNlpActions(
                this.$loginState.user.settings
              );

              this.nlpOptions = [...new Set(nlpOptions)];
              let sourceText = response.data.rawText.match(/<[a-z][\s\S]*>/i)?.length > 0 ?  response.data.rawText :  response.data.rawData;
              this.originalDocText = sourceText
                .replaceAll("<em>", "")
                .replaceAll("</em>", "");
              this.unmodifiedDocText = sourceText
                .replaceAll("<em>", "")
                .replaceAll("</em>", "");
            } else {
              this.triggerNotification(response.statusText, "error");
            }

            this.documentSource = "Internal";
            this.selectDoc = false;
            this.databaseLoading = false;
            this.isLoading = false;
          });
        } else {
          let data = {
            doc: id,
          };

          axios
            .post("document/getDocHtml/", data)
            .then((resp) => {
              this.originalDocText = resp.data;
              this.unmodifiedDocText = resp.data;
              this.documentSource = "Internal";
              this.selectDoc = false;
              this.databaseLoading = false;
              this.isLoading = false;
            })
            .catch((err) => {
              if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                console.log(err);
                this.response = err.response
                  ? err.response.data
                  : { message: "Unexpected Error" };
              }
              this.isLoading = false;
              this.databaseLoading = false;
            });
        }
      },      
      getSourceColor(doc){
        switch (doc.source) {
          case "Internal":
            return "green";
          case "Upload":
            return "blue";
          case "External":
          default:
            return "orange";
        }
      },
      getChipColor(doc) {
        switch (doc.doc_status) {
          case "APPROVED":
            return "green";
          case "PENDING_APPROVAL":
            return "red";
          case "DRAFT":
          case "PRE-DRAFT":
            return "orange";
          case "ARCHIVED":
          case "TEMPLATE":
            return "blue";
          default:
            return "gray";
        }
      },   
      reloadSource() {
        this.compareFileInline(this.sourceDoc);
      },         
      compareFileInline(item) {
        this.tempNlpText = "";
        this.tempNlpHtml = "";
        if (item.doc_type === "External File") {
          this.compareFile(
            item.file_data.file_id,
            "External File",
            item.file_data.row_id
          );
        } else {
          this.compareFile(item.system_number, item.doc_type);
        }
      },      
      loadDatabaseDocs() {
        this.databaseFilters.text = this.multidoc ? "" : this.document.doc_name;
        this.generateJobTitle = this.multidoc ? "" : this.document.doc_name;
        this.generatedData.optimise = null;
        this.generatedData.description = null;
        this.generatedData.skills = null;

        if(this.databaseViewExternalDocs && !this.multidoc) {
          if (!this.databaseSourceItems.includes("External")) {
            this.databaseSourceItems.push("External");
            this.databaseDocSource.push("External");
          }
        }
        if (this.databaseDocTypeFilter.length === 0) {
          let docTypes = this.getDatabaseViewDocTypes();
          let databaseAvailableDocTypes = this.docTypeSummary.filter(
            (t) =>
              docTypes.indexOf(t.docType) > -1 || t.docType === "External File"
          );
          this.databaseAvailableDocTypes = JSON.parse(
            JSON.stringify(databaseAvailableDocTypes)
          );
          this.databaseDocTypeFilter = JSON.parse(
            JSON.stringify(databaseAvailableDocTypes)
          );
        }
        if (this.databaseDocStatusIncluded.length === 0) {
          let docTypes = this.databaseDocTypeFilter.map((dbDoc) => dbDoc.docType);
          let filteredItems = this.cleanItems
            .filter((t) => (docTypes.indexOf(t.doc_type) > -1) && t.doc_status === 'APPROVED')
            .map((t) => {
              return { status: t.doc_status_text };
            });
          this.loadExternalFileList();
          this.databaseAvailableStatuses = JSON.parse(
            JSON.stringify([...new Set(filteredItems)])
          );
          this.databaseDocStatusIncluded = JSON.parse(
            JSON.stringify(this.docStatusSummary)
          );
        }
        if(!this.selectDoc){
          this.reloadSource();
        }
        this.filterDatabaseDocs();
      },    

      loadExternalFileList() {
        let possibleError = false;
        this.extDocsLoading = true;
        this.isLoading = true;
        this.selectedFiles = [];
        axios
          .get("file/list/")
          .then((resp) => {
            possibleError = true;

            this.fileItems = resp.data;

            this.filteredFileItems = resp.data;
            if(!this.multidoc){
              this.setDocsLinked();
            }

            this.isLoading = false;
            this.extDocsLoading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
          });
      },    
      setDocsLinked() {
        this.document.linkedDocs.forEach((doc) => {
          if (doc.tmpl_name == "External File") {
            let tmpId = doc.file_data.file_id;
            let fileItem = this.fileItems.find((x) => x.file_id == tmpId);
            if (fileItem) {
              fileItem.selected = true;
              fileItem.disabled = true;
            }
          }
        });

        this.fileItems = JSON.parse(JSON.stringify(this.fileItems));
      },   
      async initNlpOptions() {
        this.displayDocMode = false;
        this.nlpOptions - [];
        let nlpOptions = nlpUtils.getNlpActions(this.$loginState.user.settings);

        this.nlpOptions = [...new Set(nlpOptions)];
      },       
      getSourceFilterText(item, index){
        if (item && index > 0) return "";
        if (this.databaseDocSource.length === this.databaseSourceItems.length)
          return this.databaseSourceItems.length > 0 ? "All Sources" : "None";

        if (this.databaseDocSource.length > 1)
          return `${this.databaseDocSource.length} Sources`;

        return this.databaseDocSource.map((i) => i).join(", ");
      },
      getDatabaseViewDocTypes() {
        let setting = this.$loginState.user.settings.find(
          (s) => s.setting === "document_database_view_doc_types"
        );
        if (setting !== undefined) {
          return setting.value.split("|");
        } else {
          return [];
        }
      },      
      getDocTypeFilterText(item, index) {
        if (item && index > 0) return "";
        if (this.databaseDocTypeFilter.length === this.docTypeSummary.length)
          return this.docTypeSummary.length > 0 ? "All" : "None";

        if (this.databaseDocTypeFilter.length > 2)
          return `${this.databaseDocTypeFilter.length} items`;

        return this.databaseDocTypeFilter.map((i) => i.docType).join(", ");
      },
      getSuggestionTypeFilterText(item, index) {
        if (item && index > 0) return "";
        if (this.suggestionPartFilter.length === this.suggestionTypes.length)
          return this.suggestionTypes.length > 0 ? "All" : "None";

        if (this.suggestionPartFilter.length > 2)
          return `${this.suggestionPartFilter.length} items`;

        return this.suggestionPartFilter.map((i) => i).join(", ");
      },
      makeSelection(event) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseMove = true;
          setTimeout(() => {
            if (
              !this.databaseSelectionState.dragStart &&
              this.databaseSelectionState.mouseDown
            ) {
              this.databaseSelectionState.data = "";
            }
          }, 50);

          break;
      }
    },
    registerClick(event) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseDown = true;
          this.databaseSelectionState.mouseUp = false;
          this.databaseSelectionState.dragStart = false;
          this.databaseSelectionState.dragEnd = false;
          break;
      }
    },
    registerNewSelection() {
      this.databaseSelectionState = {
        mouseDown: false,
        mouseUp: false,
        mouseMove: false,
        dragStart: false,
        dragEnd: false,
        data: this.tempNlpText,
      };
    },
    dragEnd() {
      this.databaseSelectionState.mouseDown = false;
      this.databaseSelectionState.dragStart = false;
      this.databaseSelectionState.dragEnd = true;
      this.isDataDrag = false;
      this.filteredSuggestions.forEach((x) => {
        x.selected = false;
      });
      this.filteredSuggestions = JSON.parse(
        JSON.stringify(this.filteredSuggestions)
      );
      
      this.$emit("dragEnd");
    },
    dragStart(event) {
      if (this.tempNlpText !== "") {
        this.databaseSelectionState.dragStart = true;
        this.databaseSelectionState.mouseMove = false;
        let data = JSON.stringify({
          text: this.tempNlpText,
          html: this.tempNlpHtml,
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
        this.$emit("dragStart");
      }
    },
    dragStartSuggestion(item, event) {
      let items = this.filteredSuggestions
        .filter((x) => x.selected)
        .map((x) => {
          return { varVal: x.varVal };
        });

      if (items.length) {
        let text = "";
        items.forEach((x) => {
          text += "<p>" + x.varVal + "</p>";
        });

        let data = JSON.stringify({
          text: item.varVal,
          html: text,
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
      } else {
        this.filteredSuggestions;
        let data = JSON.stringify({
          text: item.varVal,
          html: "",
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
      }
      //event.dataTransfer.dropEffect = "move";
    },  
    toggleDatabaseDisplay() {
      this.selectDoc = true;
      this.databaseUploadDisabled = true;
      this.databaseUploadMulti = "false";
      this.$nextTick(() => {
        this.databaseUploadDisabled = false;
        this.databaseUploadMulti = "true";
      });
    },  
    show(e) {
      if (nlpUtils.showContextMenu(this.$loginState.user.settings)) {
        if (this.tempNlpText !== "") {
          e.preventDefault();
          this.showMenu = false;
          this.x = e.clientX;
          this.y = e.clientY;
          this.$nextTick(() => {
            this.showMenu = true;
          });
        }
      }
    },    
    mouseUpEvent(event, isSourceSection) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseUp = true;
          this.databaseSelectionState.mouseDown = false;
          this.databaseSelectionState.mouseMove = false;
          this.databaseSelectionState.dragStart = false;
          this.databaseSelectionState.dragEnd = false;
          this.getSelection(isSourceSection);
          
          this.$emit("selectionUpdated", this.tempNlpHtml);
          break;
      }
    },
    showSource() {},
    getSelectionHtml() {
      var html = "";
      if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
        }
      } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
          html = document.selection.createRange().htmlText;
        }
      }
      return html;
    },
    getSelection(isSourceSection) {
      let selection = window.getSelection();
      let selectionText = selection.toString();
      if (selectionText !== "") {
        //const selectionStart = selection.anchorOffset;
        //const selectionEnd = selection.focusOffset;
        selection = window.getSelection();
        this.originalDocText = this.unmodifiedDocText;
        selectionText = selection.toString();
        this.showNlpOptions = isSourceSection;
        var range = document.createRange();
        range.setStart(selection.anchorNode, selection.anchorOffset);
        range.setEnd(selection.focusNode, selection.focusOffset);
        var backwards = range.collapsed;
        range.detach();

        var endNode = selection.focusNode,
          endOffset = selection.focusOffset;
        selection.collapse(selection.anchorNode, selection.anchorOffset);
        if (backwards) {
          selection.modify("move", "backward", "character");
          selection.modify("move", "forward", "word");
          selection.extend(endNode, endOffset);
          selection.modify("extend", "forward", "character");
          selection.modify("extend", "backward", "word");
        } else {
          selection.modify("move", "forward", "character");
          selection.modify("move", "backward", "word");
          selection.extend(endNode, endOffset);
          selection.modify("extend", "backward", "character");
          selection.modify("extend", "forward", "word");
        }
        selectionText = selection.toString();

        this.tempTextNode = selection.anchorNode;
        this.tempNlpText = selectionText;
        this.tempNlpHtml = this.getSelectionHtml();
        this.registerNewSelection();
      } else {
        this.databaseSelectionState.data = "";
        this.tempNlpText = "";
      }
    },   
    generateContent(contentType) {
      if (this.databaseLoading)
        return;

      if (contentType === "optimise" && this.generatedData.optimise && !this.generatedData.showOptimise){
        this.generatedData.showOptimise = true;
        return;
      }

      const data = {
        contentType: contentType,
        jobTitle: this.generateJobTitle,
        language: this.documentLanguage?.text,
        text: contentType === 'optimise' ? this.originalDocText : undefined
      }
      this.databaseLoading = true;
      this.generatedData[contentType] = null;
      axios
        .post("externalContent/generate", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.generatedData[contentType] = resp.data.Data;
            if (contentType === "optimise")
              this.generatedData.showOptimise = true;
          } else {
            this.response = { message: "Unexpected Error" };
          }
          this.databaseLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.databaseLoading = false;
        });
    },       
    },
};
</script>
<style scoped lang="scss">
</style>