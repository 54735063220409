<template>
  <div>
    <!-- <br />-->

    <v-tabs v-model="tab" class="primary-background">
      <v-tab> Configuration </v-tab>
      <v-tab> Types </v-tab>
      <!-- <v-tab> Playground </v-tab> -->
      <v-tab> Permission Groups</v-tab>
      <v-tab> User Permission Groups</v-tab>
      <v-tab> Document Template Workflows</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <v-row dense>
            <v-col cols="3">
              <v-select
                v-model="currentWorkflowID"
                :items="lifecycles"
                item-value="lifecycle_id"
                item-text="name"
                label="Pick a workflow"
                @change="pickWorkflow"
              ></v-select>
            </v-col>
            <v-col class="pt-5">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="addWorkflow" v-bind="attrs" v-on="on">
                    <v-icon>add_circle_outline</v-icon>
                  </v-btn>
                </template>
                <span>Add New Empty Workflow</span>
              </v-tooltip>
            </v-col>
            <!-- <v-col cols="1"></v-col>
            <v-col cols="1">KEY:</v-col>
            <v-col>
              <v-avatar left color="primary" size="20"
                ><span class="white--text text-h5">r</span></v-avatar
              >
              - Workflow Roles</v-col
            >
            <v-col
              ><v-icon color="teal">mdi-bell-outline</v-icon> - Task
              Types</v-col
            >
            <v-col
              ><v-icon color="purple">mdi-email</v-icon> - Notification
              Types</v-col
            >
            <v-col
              ><v-icon>mdi-tab-plus</v-icon> - Data Input</v-col
            >
            <v-col
              ><v-icon color="brown">mdi-webhook</v-icon> - Message Types</v-col
            > -->
          </v-row>
        </v-container>
        <v-container v-if="currentLifecycle"
          ><v-row
            ><v-col
              ><v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title
                    >{{ currentLifecycle.name }} Workflow</v-toolbar-title
                  >
                  <v-spacer />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="uploadWorkflow"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="white">mdi-upload</v-icon>
                      </v-btn>
                    </template>
                    <span>Restore Workflow from Uploaded Definition</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="downloadWorkflow"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="white">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download Workflow Definition</span>
                  </v-tooltip>
                </v-toolbar>
                <v-container>
                  <v-row dense>
                    <v-col cols="2">
                      <h3>
                        Roles:<v-icon @click="configRoleHandler('new')"
                          >mdi-plus-circle</v-icon
                        >
                      </h3>
                    </v-col>
                    <v-col>
                      <v-row dense>
                        <v-col
                          cols="2"
                          v-for="(r, ri) in currentLifecycle.roles"
                          :key="'wr' + ri"
                        >
                          <v-chip small @click="configRoleHandler('open', r)">
                            <v-avatar left color="primary" size="30"
                              ><span class="white--text text-h5">{{
                                r.abbreviation
                              }}</span></v-avatar
                            >{{ r.name }}
                            <v-icon right small v-if="r.review_group_assignable"
                              >group</v-icon
                            >
                          </v-chip></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense style="border-top: 2px solid grey">
                    <v-col cols="2"></v-col>
                    <v-col style="border-bottom: 1px solid grey">
                      <h3 style="text-align: center">
                        Valid Statuses
                        <v-icon @click="configStatusHandler('new')"
                          >mdi-plus-circle</v-icon
                        >
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row dense style="border-bottom: 2px solid grey">
                    <v-col cols="2"
                      ><h3>
                        Actions
                        <v-icon @click="configActionHandler('new')"
                          >mdi-plus-circle</v-icon
                        >
                      </h3></v-col
                    >
                    <v-col
                      v-for="(s, si) in currentLifecycle.statuses"
                      :key="'sh' + si.toString() + '_'"
                      style="text-align: center"
                    >
                      <v-chip
                        v-if="s.name"
                        dense
                        class="mx-2 status-chip"
                        label
                        outlined
                        @click="configStatusHandler('open', s)"
                        :color="s.colour"
                        >{{ s.name }}</v-chip
                      >
                      <span v-else>Pre Existence</span>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-for="(a, ai) in currentLifecycle.actions"
                    :key="'a' + ai"
                    style="border-bottom: 1px lightgrey solid"
                  >
                    <v-col cols="2">
                      <v-tooltip
                        right
                        v-if="
                          a.data_capture_type_id || a.lifecycle_role_id_created
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            @click="configActionHandler('open', a)"
                          >
                            <v-icon v-if="a.isEditor" left small
                              >mdi-pencil</v-icon
                            ><v-icon v-if="a.isCreator" left small
                              >mdi-plus-box</v-icon
                            ><v-icon v-if="a.isCopier" left small
                              >mdi-content-copy</v-icon
                            ><v-icon v-if="a.isViewer" left small
                              >mdi-eye</v-icon
                            ><v-icon v-if="a.showScoring" left small
                              >mdi-scoreboard</v-icon
                            ><v-icon v-if="a.showDocumentComments" left small
                              >mdi-comment-eye-outline</v-icon
                            ><v-icon v-if="a.clipboard_format" left small
                              >mdi-clipboard</v-icon
                            ><v-icon v-if="a.download_format" left small
                              >mdi-download</v-icon
                            ><v-icon v-if="a.emailDoc" left small
                              >mdi-email</v-icon
                            ><v-icon v-if="a.isComment" left small
                              >mdi-comment-outline</v-icon
                            ><v-icon v-if="a.isDocumentComment" left small
                              >mdi-comment-text</v-icon
                            >{{ a.name }}
                            <v-icon v-if="a.isDataCaptureViewer" right small
                              >mdi-eye-circle</v-icon
                            ><v-icon v-else right small
                              >mdi-tab-plus</v-icon
                            ></v-btn
                          ></template
                        >
                        <span v-if="a.lifecycle_role_id_created"
                          >{{
                            a.lifecycle_role_auto
                              ? "Auto assigns "
                              : "Prompts for "
                          }}
                          {{ getRoleName(a.lifecycle_role_id_created) }}</span
                        >
                        <span v-if="a.data_capture_type_id">{{
                          (a.isDataCaptureViewer ? "View " : "Captures ") +
                          getDataCaptureName(a)
                        }}</span></v-tooltip
                      >
                      <v-btn
                        v-else
                        small
                        @click="configActionHandler('open', a)"
                        ><v-icon v-if="a.isEditor" left small>mdi-pencil</v-icon
                        ><v-icon v-if="a.isCreator" left small
                          >mdi-plus-box</v-icon
                        ><v-icon v-if="a.isCopier" left small
                          >mdi-content-copy</v-icon
                        ><v-icon v-if="a.isViewer" left small>mdi-eye</v-icon
                        ><v-icon v-if="a.showScoring" left small
                          >mdi-scoreboard</v-icon
                        ><v-icon v-if="a.showDocumentComments" left small
                          >mdi-comment-eye-outline</v-icon
                        ><v-icon v-if="a.isDataCaptureViewer" left small
                          >mdi-eye-circle</v-icon
                        ><v-icon v-if="a.clipboard_format" left small
                          >mdi-clipboard</v-icon
                        ><v-icon v-if="a.download_format" left small
                          >mdi-download</v-icon
                        ><v-icon v-if="a.emailDoc" left small>mdi-email</v-icon
                        ><v-icon v-if="a.isComment" left small
                          >mdi-comment-outline</v-icon
                        ><v-icon v-if="a.isDocumentComment" left small
                          >mdi-comment-text</v-icon
                        ><v-icon v-if="a.editPartLockedStatus" left small
                          >mdi-lock-outline</v-icon
                        >{{ a.name }}</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-row dense>
                        <v-col
                          v-for="(s, si) in currentLifecycle.statuses"
                          :key="'s' + si"
                          style="text-align: center"
                        >
                          <div
                            v-for="(sa, sai) in getStatusAction(s, a)"
                            :key="'s' + si + 'sa' + sai"
                          >
                            <div
                              style="border: 1px grey solid; min-height: 30px"
                              v-if="sa"
                              @click="
                                configStatusActionHandler('open', sa, s, a)
                              "
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-avatar
                                    v-bind="attrs"
                                    v-on="on"
                                    v-for="(
                                      r, ri
                                    ) in sa.permittedRolesRestricted"
                                    :key="'s' + si + 'sa' + sai + 'wr' + ri"
                                    color="blue lighten-2"
                                    size="20"
                                    ><span class="white--text">{{
                                      getRoleAbbr(r)
                                    }}</span></v-avatar
                                  >
                                </template>
                                <div>
                                  Action restricted to Document users in Roles:
                                </div>
                                <div>
                                  {{
                                    getRoleNames(sa.permittedRolesRestricted)
                                  }}
                                </div>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-avatar
                                    v-bind="attrs"
                                    v-on="on"
                                    v-for="(
                                      r, ri
                                    ) in sa.permittedRolesUnrestricted"
                                    :key="'s' + si + 'sa' + sai + 'wr' + ri"
                                    color="primary"
                                    size="20"
                                    ><span class="white--text">{{
                                      getRoleAbbr(r)
                                    }}</span></v-avatar
                                  >
                                </template>
                                <div>
                                  Action available for any users with
                                  permissions for Roles:
                                </div>
                                <div>
                                  {{
                                    getRoleNames(sa.permittedRolesUnrestricted)
                                  }}
                                </div>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                v-if="sa.lifecycle_status_id_new"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mx-2 status-chip"
                                    label
                                    outlined
                                    :color="
                                      getStatusColour(
                                        sa.lifecycle_status_id_new
                                      )
                                    "
                                  >
                                    <v-avatar left>
                                      <v-icon>mdi-arrow-right-bold</v-icon>
                                    </v-avatar>
                                    {{
                                      getStatusName(sa.lifecycle_status_id_new)
                                    }}
                                  </v-chip>
                                </template>
                                Changes document status to
                                {{ getStatusName(sa.lifecycle_status_id_new) }}
                              </v-tooltip>
                              <span
                                v-for="(tg, tgi) in sa.tasksGenerated"
                                :key="'s' + si + 'sa' + sai + 'tg' + tgi"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="teal"
                                      >mdi-bell-outline</v-icon
                                    >
                                  </template>
                                  <div>
                                    Creates "{{ getTaskName(tg) }}" TASK
                                  </div>
                                  <div>
                                    sent to: {{ getRoleNames(tg.roles) }}
                                    {{
                                      tg.due_days
                                        ? " (due " + tg.due_days + "days)"
                                        : ""
                                    }}
                                  </div>
                                </v-tooltip>
                              </span>
                              <span
                                v-for="(ng, ngi) in sa.notificationsGenerated"
                                :key="'s' + si + 'sa' + sai + 'ng' + ngi"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="purple"
                                      >mdi-email</v-icon
                                    >
                                  </template>
                                  <div>
                                    Sends "{{ getNotificationame(ng) }}"
                                    NOTIFICATION
                                  </div>
                                  <div>
                                    to{{
                                      ng.all_roles
                                        ? " all roles"
                                        : ": " + getRoleNames(ng.roles)
                                    }}
                                  </div>
                                </v-tooltip>
                              </span>
                              <span
                                v-for="(ng, ngi) in sa.messagesGenerated"
                                :key="'s' + si + 'sa' + sai + 'ng' + ngi"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="brown"
                                      >mdi-webhook</v-icon
                                    >
                                  </template>
                                  <div>
                                    Emits "{{ getMessageName(ng) }}" MESSAGE
                                  </div>
                                </v-tooltip>
                              </span>
                              <v-tooltip bottom v-if="sa.tasksCompleted.length">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" color="teal" v-on="on"
                                    >mdi-bell-check</v-icon
                                  >
                                </template>
                                <div>
                                  Completes "{{
                                    getTaskNames(sa.tasksCompleted)
                                  }}" Tasks
                                </div>
                              </v-tooltip>
                              <v-tooltip bottom v-if="sa.tasksCancelled.length">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" color="teal" v-on="on"
                                    >mdi-bell-cancel</v-icon
                                  >
                                </template>
                                <div>
                                  Cancels "{{
                                    getTaskNames(sa.tasksCancelled)
                                  }}" Tasks
                                </div>
                              </v-tooltip>
                              <v-tooltip bottom v-if="a.data_capture_type_id">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    color="brown"
                                    v-on="on"
                                    >{{
                                      a.isDataCaptureViewer
                                        ? "mdi-eye-circle"
                                        : "mdi-tab-plus"
                                    }}</v-icon
                                  >
                                </template>
                                <div>
                                  {{
                                    (a.isDataCaptureViewer
                                      ? "View "
                                      : "Captures ") + getDataCaptureName(a)
                                  }}
                                </div>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                v-if="a.lifecycle_role_id_created"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" color="blue" v-on="on"
                                    >mdi-tab-plus</v-icon
                                  >
                                </template>
                                <div>
                                  Captures "{{
                                    getRoleName(a.lifecycle_role_id_created)
                                  }}"
                                </div>
                              </v-tooltip>
                            </div>
                            <div
                              v-else-if="(!a.isCreator || a.isCopier) && s.name"
                            >
                              <v-icon
                                color="grey lighten-2"
                                @click="
                                  configStatusActionHandler('new', null, s, a)
                                "
                                >mdi-plus</v-icon
                              >
                            </div>
                            <div v-else-if="a.isCreator && !s.name">
                              <v-icon
                                color="grey lighten-2"
                                @click="
                                  configStatusActionHandler('new', null, s, a)
                                "
                                >mdi-plus-outline</v-icon
                              >
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container></v-card
              ></v-col
            ></v-row
          ></v-container
        >
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title>Task Types</v-toolbar-title
                  ><v-spacer></v-spacer
                  ><v-btn
                    icon
                    @click="configTaskNotHandler('new', null, 'task_type')"
                  >
                    <v-icon color="white">mdi-plus-outline</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col v-for="(r, ri) in taskTypes" :key="'tt' + ri">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="teal"
                            outlined
                            @click="
                              configTaskNotHandler('open', r, 'task_type')
                            "
                            ><v-icon>mdi-bell-outline</v-icon> {{ r.name }}
                          </v-chip>
                        </template>
                        <span>Edit {{ r.name }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title>Notification Types</v-toolbar-title
                  ><v-spacer></v-spacer
                  ><v-btn
                    icon
                    @click="
                      configTaskNotHandler('new', null, 'notification_type')
                    "
                  >
                    <v-icon color="white">mdi-plus-outline</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(r, ri) in notificationTypes"
                      :key="'nt' + ri"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="teal"
                            outlined
                            @click="
                              configTaskNotHandler(
                                'open',
                                r,
                                'notification_type'
                              )
                            "
                            ><v-icon>mdi-email</v-icon> {{ r.name }}
                          </v-chip>
                        </template>
                        <span>Edit {{ r.name }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title>Data Inputs</v-toolbar-title>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(r, ri) in dataCaptures.filter(
                        (x) => !x.questionnaire_id
                      )"
                      :key="'dc' + ri"
                      ><v-chip small color="teal" outlined>
                        <v-icon left>mdi-tab-plus</v-icon>
                        {{ r.name }}</v-chip
                      ></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title>Questionnaires</v-toolbar-title>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(r, ri) in dataCaptures.filter(
                        (x) => x.questionnaire_id
                      )"
                      :key="'dc' + ri"
                      ><v-chip
                        small
                        color="teal"
                        outlined
                        @click="showQuestionnaire(r)"
                      >
                        <v-icon left>mdi-file-question-outline</v-icon>
                        {{ r.name }}</v-chip
                      ></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title>Email Templates</v-toolbar-title
                  ><v-spacer></v-spacer
                  ><v-btn icon @click="configEmailTemplateHandler('new')">
                    <v-icon color="white">mdi-plus-outline</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col v-for="(r, ri) in emailTemplates" :key="'et' + ri">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="blue-grey"
                            outlined
                            @click="configEmailTemplateHandler('open', r)"
                            ><v-icon>mdi-webhook</v-icon> {{ r.name }}
                          </v-chip>
                        </template>
                        <span>Edit {{ r.name }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title>Message Types</v-toolbar-title
                  ><v-spacer></v-spacer
                  ><v-btn icon @click="configMessageHandler('new')">
                    <v-icon color="white">mdi-plus-outline</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col v-for="(r, ri) in messageTypes" :key="'mt' + ri">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="indigo"
                            outlined
                            @click="configMessageHandler('open', r)"
                            ><v-icon>mdi-webhook</v-icon> {{ r.name }}
                          </v-chip>
                        </template>
                        <span>Edit {{ r.name }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-toolbar flat dense color="blue-grey" dark>
                  <v-toolbar-title>Action Menus</v-toolbar-title
                  ><v-spacer></v-spacer
                  ><!-- <v-btn icon @click="configMessageHandler('new')">
                    <v-icon color="white">mdi-plus-outline</v-icon>
                  </v-btn> -->
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col v-for="(r, ri) in actionMenus" :key="'mt' + ri">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="indigo"
                            outlined
                            ><v-icon>mdi-webhook</v-icon> {{ r.name }}
                          </v-chip>
                          <!-- @click="configMessageHandler('openaction_menu_id', r)" -->
                        </template>
                        <span>Edit {{ r.name }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <!-- <v-tab-item>
        <v-container>
          <v-row dense>
            <v-col cols="1">
              <v-btn @click="initAddDocument">Create Document</v-btn>
            </v-col>
            <v-col cols="1"> </v-col>
            <v-col cols="8">
              <v-card elevation="4" class="mx-4" v-if="unassignedTasks.length">
                <v-toolbar dense>
                  <v-toolbar-title
                    >{{ unassignedTasks.length }} Unnassigned
                    Tasks:</v-toolbar-title
                  >
                <v-card-text>
                  <v-row dense>
                    <v-col>Due</v-col>
                    <v-col>Type</v-col>
                    <v-col>Desc</v-col>
                    <v-col>Status</v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row
                    dense
                    v-for="(t, ti) in unassignedTasks"
                    :key="'ti' + ti"
                  >
                    <v-col>{{ t.due }}</v-col>
                    <v-col>{{ t.name }}</v-col>
                    <v-col>{{ t.description }}</v-col>
                    <v-col
                      ><v-chip
                        dense
                        :color="
                          t.status === 'Completed'
                            ? 'green'
                            : t.status === 'Open'
                            ? 'amber'
                            : 'gray'
                        "
                        >{{ t.status }}</v-chip
                      ></v-col
                    >
                    <v-col
                      ><v-btn v-if="t.status === 'Open'" @click="assignTask(t)"
                        >assign</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="unassignedNotifications.length"
              >{{ unassignedNotifications.length }} Unnassigned Notifications:
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="currentDoc">
          <v-row>
            <v-col
              v-for="(r, ri) in getDocumentRoles()"
              :key="'r' + ri"
              :cols="
                currentDoc.roles.length <= 3
                  ? 4
                  : parseInt(12 / currentDoc.roles.length)
              "
            >
              <v-sheet color="grey lighten-1" class="pa-4">
                <h1>
                  For {{ r.user_name }} ({{ getRoleName(r.lifecycle_role_id) }})
                </h1>
                <v-card elevation="4">
                  <v-toolbar dense>
                    <v-toolbar-title>Document</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      v-for="(a, ai) in getActionsAvailable(
                        currentDoc.lifecycle_status_id,
                        r.lifecycle_role_id
                      )"
                      :key="'r' + ri + 'a' + ai"
                      @click="doAction(currentDoc, r, a.lifecycle_action_id)"
                      >{{ getActionName(a.lifecycle_action_id) }}</v-btn
                    >
                  </v-toolbar>
                  <v-card-title>
                    <v-row
                      ><v-col>{{ currentDoc.name }}</v-col
                      ><v-col cols="3"
                        ><v-chip
                          class="mx-2 status-chip"
                          label
                          outlined
                          :color="
                            getStatusColour(currentDoc.lifecycle_status_id)
                          "
                          >{{
                            getStatusName(currentDoc.lifecycle_status_id)
                          }}</v-chip
                        ></v-col
                      ></v-row
                    ></v-card-title
                  >
                  <v-card-text>
                    <v-card elevation="2">
                      <v-card-text
                        ><p>sample text 1</p>
                        <p>sample text 2</p></v-card-text
                      >
                    </v-card>
                  </v-card-text>
                </v-card>
                <br />
                <v-card elevation="4" v-if="r.tasksReceived.length">
                  <v-toolbar dense>
                    <v-toolbar-title>Assigned Tasks:</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text v-if="r.tasksReceived.length">
                    <v-row dense>
                      <v-col>Due</v-col>
                      <v-col>Type</v-col>
                      <v-col>Desc</v-col>
                      <v-col>Status</v-col>
                    </v-row>
                    <v-row
                      dense
                      v-for="(t, ti) in r.tasksReceived"
                      :key="'r' + ri + 'ti' + ti"
                    >
                      <v-col>{{ t.due }}</v-col>
                      <v-col>{{ t.name }}</v-col>
                      <v-col>{{ t.description }}</v-col>
                      <v-col
                        ><v-chip
                          dense
                          :color="
                            t.status === 'Completed'
                              ? 'green'
                              : t.status === 'Open'
                              ? 'amber'
                              : 'gray'
                          "
                          >{{ t.status }}</v-chip
                        ></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
                <br v-if="r.tasksReceived.length" />
                <v-card elevation="4" v-if="r.notificationsReceived.length">
                  <v-toolbar dense>
                    <v-toolbar-title
                      >Notifications Received (via Email and
                      screen):</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-card-text v-if="r.notificationsReceived.length">
                    <v-row
                      dense
                      v-for="(n, ni) in r.notificationsReceived"
                      :key="'r' + ri + 'n' + ni"
                    >
                      <v-col
                        >{{ n.created }} <v-chip dense>{{ n.name }}</v-chip> by
                        {{ n.createdBy }}
                        {{ n.description ? " - " + n.description : "" }}</v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
                <br v-if="r.notificationsReceived.length" />
                <v-card elevation="4" v-if="r.actionsCompleted.length">
                  <v-toolbar dense>
                    <v-toolbar-title>Activity History:</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text v-if="r.actionsCompleted.length">
                    <v-row
                      dense
                      v-for="(a, ai) in r.actionsCompleted"
                      :key="'r' + ri + 'ac' + ai"
                    >
                      <v-col
                        >{{ a.when }}
                        <v-chip dense>{{ a.action }}</v-chip> status:
                        {{ a.preStatus }}
                        {{
                          a.preStatus !== a.postStatus
                            ? " to " + a.postStatus
                            : ""
                        }}</v-col
                      ><v-col cols="1" v-if="a.statusChecks.length">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                              >mdi-information</v-icon
                            >
                          </template>
                          <div
                            v-for="(c, ci) in a.statusChecks"
                            :key="'r' + ri + 'ac' + ai + 'c' + ci"
                          >
                            <span>{{ c.check }}</span> -
                            <span>{{ c.response }}</span>
                          </div>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item> -->
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="2"
              ><h3>
                Permission
                <v-icon @click="configGeneralHandler('new', null, 'permission')"
                  >mdi-plus-outline</v-icon
                >
              </h3></v-col
            >
            <v-col
              ><h3>
                Permission Groups
                <v-icon
                  @click="configGeneralHandler('new', null, 'permission_group')"
                  >mdi-plus-outline</v-icon
                >
              </h3></v-col
            >
            <v-col>
              <v-spacer></v-spacer>
              <v-pagination
                v-if="permissionSetUp.pages > 1"
                v-model="permissionSetUp.page"
                :length="permissionSetUp.pages"
                :total-visible="7"
                dense
              ></v-pagination>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model="permissionSetUp.filter"
                placeholder="type to search"
                label="Filter by permission name"
                @input="filterPermissions"
                clearable
              ></v-text-field
            ></v-col>
            <v-col v-for="(p, pi) in permissionGroups" :key="'p' + pi">
              <v-chip
                @click="configGeneralHandler('open', p, 'permission_group')"
              >
                {{ p.name }} </v-chip
              ><v-icon @click="filterPG(p, 'permissionSetUp')">{{
                permissionSetUp.pgFilter.some(
                  (x) => x === p.permission_group_id
                )
                  ? "mdi-filter-remove"
                  : "mdi-filter-outline"
              }}</v-icon></v-col
            >
          </v-row>
          <v-row
            dense
            v-for="(pg, ui) in permissionSetUp.filtered.filter(
              (x) => x.page === permissionSetUp.page
            )"
            :key="'pg' + ui"
          >
            <v-col cols="2">
              <v-chip
                outlined
                label
                color="teal"
                @click="configGeneralHandler('open', pg, 'permission')"
                >{{ pg.name }}</v-chip
              >
            </v-col>
            <v-col
              v-for="(p, pi) in pg.permissionGroups"
              :key="'u' + ui + 'p' + pi"
            >
              <v-tooltip bottom v-if="p.permission_in_group_id">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="togglePIG(p)"
                    >mdi-check-circle-outline
                  </v-icon>
                </template>
                <span
                  >Remove {{ p.permission_name }} from
                  {{ p.permission_group_name }}</span
                >
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="togglePIG(p)"
                    color="grey lighten-2"
                    >mdi-plus-outline
                  </v-icon>
                </template>
                <span
                  >Add {{ p.permission_name }} for
                  {{ p.permission_group_name }}</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-simple-table dense class="">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="vertical-align: bottom">
                    <h2>
                      Users<v-icon @click="editUser()">mdi-plus-outline</v-icon>
                    </h2>
                  </th>
                  <th style="vertical-align: bottom" colspan="2">
                    <h2>
                      Permission Groups<v-icon
                        @click="
                          configGeneralHandler('new', null, 'permission_group')
                        "
                        >mdi-plus-outline</v-icon
                      >
                    </h2>
                  </th>
                  <th :colspan="permissionGroups.length - 2">
                    <v-spacer></v-spacer>
                    <v-pagination
                      v-if="userSetUp.pages > 1"
                      v-model="userSetUp.page"
                      :length="userSetUp.pages"
                      :total-visible="7"
                      dense
                    ></v-pagination>
                  </th>
                </tr>
                <tr>
                  <th style="vertical-align: bottom">
                    <v-text-field
                      v-model="userSetUp.filter"
                      placeholder="type to search"
                      label="Filter by user name"
                      @input="filterUsers"
                      clearable
                    ></v-text-field>
                  </th>
                  <!--   class="vertical-text" -->
                  <th v-for="(p, pi) in permissionGroups" :key="'p' + pi">
                    <v-chip
                      small
                      @click="
                        configGeneralHandler('open', p, 'permission_group')
                      "
                      >{{ p.name }}</v-chip
                    ><v-icon @click="filterPG(p, 'userSetUp')">{{
                      userSetUp.pgFilter.some(
                        (x) => x === p.permission_group_id
                      )
                        ? "mdi-filter-remove"
                        : "mdi-filter-outline"
                    }}</v-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="upg in userSetUp.filtered.filter(
                    (x) => x.page === userSetUp.page
                  )"
                  :key="'upg' + upg.user_id"
                >
                  <td>
                    <v-chip
                      small
                      outlined
                      label
                      color="teal"
                      @click="editUser(upg)"
                      >{{ upg.user_name }}</v-chip
                    >
                  </td>
                  <td
                    v-for="(p, pi) in upg.pglist"
                    :key="'upg' + upg.user_id + 'p' + pi"
                    class="data"
                  >
                    <v-tooltip bottom v-if="p.user_permission_group_id">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="toggleUPG(p)"
                          >mdi-check-circle-outline
                        </v-icon>
                      </template>
                      <span
                        >Remove {{ p.permission_group_name }} from
                        {{ p.user_name }}</span
                      >
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="toggleUPG(p)"
                          color="grey lighten-2"
                          >mdi-plus-outline
                        </v-icon>
                      </template>
                      <span
                        >Add {{ p.permission_group_name }} for
                        {{ p.user_name }}</span
                      >
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-simple-table dense class="">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="vertical-align: bottom"><h2>Workflows</h2></th>
                  <th style="vertical-align: bottom" colspan="4">
                    <h2>Document Templates</h2>
                  </th>
                </tr>
                <tr>
                  <th style="vertical-align: bottom"></th>
                  <th v-for="(t, ti) in templates" :key="'t' + ti">
                    <v-chip small outlined label color="teal">{{
                      t.tmpl_name
                    }}</v-chip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l, li) in lifecycles" :key="'l' + li">
                  <td>
                    <v-chip small outlined label color="blue darken-2">{{
                      l.name
                    }}</v-chip>
                  </td>
                  <td
                    v-for="(t, ti) in l.templates"
                    :key="'l' + li + 't' + ti"
                    class="data"
                  >
                    <v-tooltip bottom v-if="t.template_lifecycle_id">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="configTemplateLifecycleHandler('open', t, l)"
                          >mdi-check-circle-outline
                        </v-icon>
                      </template>
                      <span>Edit {{ t.tmpl_name }} / {{ l.name }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="configTemplateLifecycleHandler('new', t, l)"
                          color="grey lighten-2"
                          >mdi-plus-outline
                        </v-icon>
                      </template>
                      <span>Add {{ t.tmpl_name }} / {{ l.name }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="questionnaireDialogue.show">
      <QuestionnareDefinition
        v-if="questionnaireDialogue.questionnaire_id"
        :questionnaire_id="questionnaireDialogue.questionnaire_id"
        @close="questionnaireDialogue.show = false"
      ></QuestionnareDefinition>
    </v-dialog>
    <v-dialog v-model="assignUser.show" persistent max-width="390">
      <v-card>
        <v-card-title class="text-h5">
          Add {{ getRoleName(assignUser.lifecycle_role_id) }}
        </v-card-title>
        <v-card-text
          ><v-select
            v-model="assignUser.user_id"
            :items="assignUser.userList"
            item-text="user_name"
            item-value="user_id"
          ></v-select
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="assignUserHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!assignUser.user_id"
            @click="assignUserHandler(assignUser.saveAction)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addComment.show" persistent max-width="390">
      <v-card>
        <v-card-title class="text-h5"
          >Add {{ addComment.label || "Document Comment" }}</v-card-title
        >
        <v-card-text>
          <v-text-field
            :label="addComment.label || 'Comment'"
            v-model="addComment.text"
          ></v-text-field>
          ></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="addCommentHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!addComment.text"
            @click="addCommentHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newDoc.show" persistent max-width="390">
      <v-card>
        <v-card-title class="text-h5"> Create Document </v-card-title>
        <v-card-text>
          <v-text-field
            label="Document Title"
            v-model="newDoc.title"
          ></v-text-field>
          <v-select
            v-model="newDoc.user_id"
            :items="newDoc.userList"
            item-text="user_name"
            item-value="user_id"
            label="Author"
          ></v-select
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="newDoc.show = false">
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!newDoc.user_id || !newDoc.title"
            @click="addDocument()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="checkList.show" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> {{ checkList.title }} </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <span>{{ checkList.description }}</span>
            </v-col>
          </v-row>
          <v-row dense v-for="(c, ci) in checkList.checks" :key="'cl' + ci">
            <v-col>
              <label>{{ c.title }}</label>
            </v-col>
            <v-col>
              <v-select
                v-if="c.type === 'select'"
                v-model="c.value"
                :items="c.validValues"
              ></v-select>
              <v-checkbox
                v-if="c.type === 'boolean'"
                v-model="c.value"
              ></v-checkbox>
              <v-text-field
                v-if="c.type === 'text'"
                v-model="c.value"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="checkListHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="checkList.checks.length && !checkList.checks[0].value"
            @click="checkListHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="configStatusAction.show" persistent max-width="890">
      <v-card v-if="configStatusAction.show">
        <v-card-title>
          <v-sheet
            elevation="4"
            rounded
            class="px-3 mb-4"
            v-if="getStatusName(configStatusAction.status.lifecycle_status_id)"
          >
            <span class="mr-3">For document status</span>
            <v-chip
              class="mx-2 status-chip"
              label
              outlined
              :color="configStatusAction.status.colour"
              >{{
                getStatusName(configStatusAction.status.lifecycle_status_id)
              }}</v-chip
            ><span class="mx-3">and action</span>
            <v-btn>{{ getActionName(configStatusAction.action) }}</v-btn>
          </v-sheet>
          <v-sheet elevation="4" rounded class="px-3 mb-4" v-else>
            <span class="mr-3">When a document is added using</span>
            <v-btn>{{ getActionName(configStatusAction.action) }}</v-btn>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configStatusActionHandler('cancel')"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row dense v-if="configStatusAction.action.isCreator">
            <v-col
              >Permit new nodes to be added for following hierarchy
              types:</v-col
            >
            <v-col>
              <v-select
                dense
                v-model="configStatusAction.cache.hierarchyTypes_hierarchy_type"
                :items="hierarchy_types"
                chips
                deletable-chips
                item-text="ht_name"
                item-value="ht_id"
                clearable
                multiple
              ></v-select> </v-col></v-row
          ><v-row><v-col></v-col></v-row>
          <v-row
            dense
            v-if="
              configStatusAction.action.isEditor ||
              configStatusAction.action.isViewer
            "
          >
            <v-col cols="12"
              ><v-alert dense type="info"
                >This action allows
                {{ configStatusAction.action.isEditor ? "editing" : "viewing" }}
                of the following components within the document
                <v-row
                  ><v-col v-if="configStatusAction.action.isEditor"
                    ><v-switch
                      dense
                      label="parts"
                      v-model="configStatusAction.cache.include_parts"
                    ></v-switch></v-col
                  ><v-col
                    ><v-switch
                      dense
                      label="hierarchies"
                      v-model="configStatusAction.cache.include_hierarchies"
                    ></v-switch></v-col
                  ><v-col
                    ><v-switch
                      dense
                      label="recruiter"
                      v-model="configStatusAction.cache.include_recruiter"
                    ></v-switch></v-col
                  ><v-col
                    ><v-switch
                      dense
                      label="quality"
                      v-model="configStatusAction.cache.include_quality"
                    ></v-switch
                  ></v-col>
                  <!-- <v-col><v-switch dense label="classifiers"
                ></v-switch
              ></v-col> -->
                </v-row>
                <v-row
                  dense
                  v-if="
                    configStatusAction.action.isEditor &&
                    configStatusAction.cache.include_hierarchies
                  "
                >
                  <v-col
                    >Permit new nodes to be added for following hierarchy
                    types:</v-col
                  >
                  <v-col>
                    <v-select
                      dense
                      v-model="
                        configStatusAction.cache.hierarchyTypes_hierarchy_type
                      "
                      :items="hierarchy_types"
                      chips
                      deletable-chips
                      item-text="ht_name"
                      item-value="ht_id"
                      clearable
                      multiple
                    ></v-select> </v-col
                ></v-row> </v-alert
            ></v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.action.isCopier">
            <v-col>
              <v-alert dense type="info"
                >This action copies the document
                {{
                  configStatusAction.action.tmpl_id_copy_destination
                    ? " into a new " +
                      templates.find(
                        (x) =>
                          x.tmpl_id ===
                          configStatusAction.action.tmpl_id_copy_destination
                      ).tmpl_name
                    : ""
                }}
                <v-row dense class="mt-2"
                  ><v-col>
                    <v-select
                      dense
                      v-model="
                        configStatusAction.cache
                          .dataCapturesCopied_data_capture_type
                      "
                      :items="currentLifecycle.dataCapturesUsed"
                      item-text="name"
                      item-value="data_capture_type_id"
                      label="Data Captures copied to the new document:"
                      clearable
                      multiple
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip small>
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template></v-select
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.action.isDocumentComment">
            <v-col
              ><v-alert dense type="info"
                >This action adds a document level comment</v-alert
              ></v-col
            >
          </v-row>
          <v-row dense v-if="configStatusAction.action.isComment">
            <v-col
              ><v-alert dense type="info"
                >This action adds a document part level comment</v-alert
              ></v-col
            >
          </v-row>
          <v-row dense v-if="configStatusAction.action.clipboard_format">
            <v-col
              ><v-alert dense type="info"
                >This action copies data to the clipboard</v-alert
              ></v-col
            >
          </v-row>
          <v-row dense v-if="configStatusAction.action.download_format">
            <v-col
              ><v-alert dense type="info"
                >This action downloads the document as a file</v-alert
              ></v-col
            >
          </v-row>
          <v-row dense v-if="configStatusAction.action.emailDoc">
            <v-col
              ><v-alert dense type="info"
                >This action sends the document via email</v-alert
              ></v-col
            >
          </v-row>
          <v-row dense v-if="configStatusAction.action.showScoring">
            <v-col
              ><v-alert dense type="info"
                >This action makes the scoring panel visible</v-alert
              ></v-col
            >
          </v-row>
          <v-row dense v-if="configStatusAction.action.showDocumentComments">
            <v-col
              ><v-alert dense type="info"
                >This action makes the document comments panel visible</v-alert
              ></v-col
            >
          </v-row>
          <v-row dense v-if="!configStatusAction.action.isCreator">
            <v-col>
              <v-select
                dense
                v-model="
                  configStatusAction.cache
                    .permittedRolesRestricted_lifecycle_role
                "
                :items="currentLifecycle.roles"
                item-text="name"
                item-value="lifecycle_role_id"
                label="Action restricted to users on Document in Roles of:"
                clearable
                multiple
              >
                <template v-slot:selection="{ item }">
                  <v-chip small>
                    <v-avatar left class="primary"
                      ><span class="white--text text-h5">{{
                        item.abbreviation
                      }}</span>
                    </v-avatar>
                    <span>{{ item.name }}</span>
                  </v-chip>
                </template></v-select
              >
            </v-col>
            <!-- <v-col cols="4" v-if="!configStatusAction.action.isCreator"
              ><v-switch
                v-model="configStatusAction.cache.restrict_to_document_role"
                inset
                label="Restrict action to document role holders only?"
              ></v-switch
            ></v-col> -->
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                dense
                v-model="
                  configStatusAction.cache
                    .permittedRolesUnrestricted_lifecycle_role
                "
                :items="currentLifecycle.roles"
                item-text="name"
                item-value="lifecycle_role_id"
                label="Action is available for any user with permissions linked to:"
                clearable
                multiple
              >
                <template v-slot:selection="{ item }">
                  <v-chip small>
                    <v-avatar left class="primary"
                      ><span class="white--text text-h5">{{
                        item.abbreviation
                      }}</span>
                    </v-avatar>
                    <span>{{ item.name }}</span>
                  </v-chip>
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configStatusAction.action.isCreator &&
              !configStatusAction.action.isCopier &&
              !configStatusAction.action.isViewer &&
              !configStatusAction.action.showScoring &&
              !configStatusAction.action.showDocumentComments
            "
          >
            <v-col
              ><v-switch
                v-model="configStatusAction.ruleUpdateStatus"
                inset
                label="Does the action update the document status?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-select
                v-if="configStatusAction.ruleUpdateStatus"
                v-model="configStatusAction.cache.lifecycle_status_id_new"
                :items="currentLifecycle.statuses"
                item-text="name"
                item-value="lifecycle_status_id"
                label="new status - leave empty if status unchanged"
                clearable
                chips
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    class="mx-2 status-chip"
                    label
                    outlined
                    :color="item.colour"
                  >
                    {{ item.name }}
                  </v-chip>
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.ruleUpdateStatus">
			<v-col cols="4"></v-col>
            <v-col><v-switch
                v-model="configStatusAction.cache.multi_role_status_action"
                inset
                label="Only Change status only when every assigned role holder has actioned?"
              ></v-switch
            ></v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.action.isCreator">
            <v-col>
              <v-select
                v-model="configStatusAction.cache.lifecycle_status_id_new"
                :items="currentLifecycle.statuses"
                item-text="name"
                item-value="lifecycle_status_id"
                label="new document status"
                clearable
                chips
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    class="mx-2 status-chip"
                    label
                    outlined
                    :color="item.colour"
                  >
                    {{ item.name }}
                  </v-chip>
                </template></v-select
              ></v-col
            >
            <v-col> </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configStatusAction.action.showScoring &&
              !configStatusAction.action.showDocumentComments
            "
          >
            <v-col
              ><v-switch
                v-model="configStatusAction.ruleTriggerTask"
                inset
                label="Does the action initiate any tasks?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-btn
                small
                v-if="configStatusAction.ruleTriggerTask"
                @click="pickerHandler('init', 'TASK')"
                >Add Task</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.ruleTriggerTask">
            <v-col>
              <v-row
                dense
                v-for="(t, ti) in configStatusAction.cache.tasksGenerated"
                :key="'t' + ti"
              >
                <v-col cols="1"></v-col>
                <v-col cols="3"
                  ><v-chip small color="teal" outlined>
                    <v-icon left>mdi-bell-outline</v-icon>
                    {{ getTaskName(t) }}</v-chip
                  ><span v-if="t.isDeleted" class="ml-2 red"
                    >To be deleted</span
                  ></v-col
                >
                <v-col cols="2">
                  <v-slider
                    dense
                    v-model="t.due_days"
                    :thumb-size="24"
                    thumb-label="always"
                    min="0"
                    max="10"
                    label="Due"
                    hint="(days)"
                    persistent-hint
                  ></v-slider>
                </v-col>
                <v-col cols="5">
                  <v-select
                    v-model="t.roles_lifecycle_role"
                    :items="currentLifecycle.roles"
                    item-text="name"
                    item-value="lifecycle_role_id"
                    label="assign to users on Document in roles of:"
                    multiple
                    clearable
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small>
                        <v-avatar left class="primary"
                          ><span class="white--text text-h5">{{
                            item.abbreviation
                          }}</span>
                        </v-avatar>
                        <span>{{ item.name }}</span>
                      </v-chip>
                    </template></v-select
                  ></v-col
                >
                <v-col cols="1"
                  ><v-icon v-if="t.isDeleted" @click="t.isDeleted = false"
                    >mdi-delete-off</v-icon
                  ><v-icon
                    v-else
                    @click="configStatusActionHandler('removeTaskGenerated', t)"
                    >mdi-delete</v-icon
                  ></v-col
                ><v-col cols="6"></v-col
                ><v-col cols="6"
                  ><v-switch
                    v-model="t.notify_unassigned"
                    inset
                    label="Send unassigned task notification to all users in role?"
                  ></v-switch
                ></v-col><v-col cols="6"></v-col
                ><v-col cols="6"
                  ><v-switch
                    v-model="t.no_email"
                    inset
                    label="Do not send task emails?"
                  ></v-switch
                ></v-col>
                <!-- <v-col cols="6"
                  ></v-col
                >
                <v-col cols="5">
                  <v-select
                    v-model="t.roles_lifecycle_role"
                    :items="currentLifecycle.roles"
                    item-text="name"
                    item-value="lifecycle_role_id"
                    label="send to any user with permissions linked to:"
                    multiple
                    clearable
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small>
                        <v-avatar left class="primary"
                          ><span class="white--text text-h5">{{
                            item.abbreviation
                          }}</span>
                        </v-avatar>
                        <span>{{ item.name }}</span>
                      </v-chip>
                    </template></v-select
                  ></v-col
                > -->
              </v-row>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configStatusAction.action.showScoring &&
              !configStatusAction.action.showDocumentComments
            "
          >
            <v-col
              ><v-switch
                v-model="configStatusAction.ruleTriggerNotification"
                inset
                label="Does the action send any notifications?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-btn
                small
                v-if="configStatusAction.ruleTriggerNotification"
                @click="pickerHandler('init', 'NOTIFICATION')"
                >Add Notification</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.ruleTriggerNotification">
            <v-col>
              <v-row
                dense
                v-for="(t, ti) in configStatusAction.cache
                  .notificationsGenerated"
                :key="'t' + ti"
              >
                <v-col cols="1"></v-col>
                <v-col cols="3"
                  ><v-chip small color="teal" outlined>
                    <v-icon left>mdi-email</v-icon>
                    {{ getNotificationame(t) }}</v-chip
                  ><span v-if="t.isDeleted" class="ml-2 red"
                    >To be deleted</span
                  ></v-col
                >
                <v-col cols="2">
                  <!-- <v-checkbox
                    label="All roles?"
                    v-model="t.all_roles"
                  ></v-checkbox> -->
                  <v-text-field
                    type="number"
                    label="Delay minutes"
                    v-model="t.delay_minutes"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-if="!t.all_roles"
                    v-model="t.roles_lifecycle_role"
                    :items="currentLifecycle.roles"
                    item-text="name"
                    item-value="lifecycle_role_id"
                    label="send to roles:"
                    multiple
                    clearable
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small>
                        <v-avatar left class="primary"
                          ><span class="white--text text-h5">{{
                            item.abbreviation
                          }}</span>
                        </v-avatar>
                        <span>{{ item.name }}</span>
                      </v-chip>
                    </template></v-select
                  ></v-col
                >
                <v-col cols="1"
                  ><v-icon v-if="t.isDeleted" @click="t.isDeleted = false"
                    >mdi-delete-off</v-icon
                  ><v-icon
                    v-else
                    @click="
                      configStatusActionHandler(
                        'removeNotificationGenerated',
                        t
                      )
                    "
                    >mdi-delete</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configStatusAction.action.showScoring &&
              !configStatusAction.action.showDocumentComments
            "
          >
            <v-col
              ><v-switch
                v-model="configStatusAction.ruleTriggerMessage"
                inset
                label="Does the action initiate any messages?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-btn
                small
                v-if="configStatusAction.ruleTriggerMessage"
                @click="pickerHandler('init', 'MESSAGE')"
                >Add Message</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.ruleTriggerMessage">
            <v-col>
              <v-row
                dense
                v-for="(m, mi) in configStatusAction.cache.messagesGenerated"
                :key="'m' + mi"
              >
                <v-col cols="6"></v-col>
                <v-col
                  ><v-chip small color="teal" outlined>
                    <v-icon left>mdi-webhook</v-icon>
                    {{ getMessageName(m) }}</v-chip
                  ><span v-if="m.isDeleted" class="ml-2 red">
                    Marked for Delete</span
                  ></v-col
                >
                <v-col cols="1"
                  ><v-icon v-if="m.isDeleted" @click="m.isDeleted = false"
                    >mdi-delete-off</v-icon
                  ><v-icon
                    v-else
                    @click="
                      configStatusActionHandler('removeMessageGenerated', m)
                    "
                    >mdi-delete</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configStatusAction.action.showScoring &&
              !configStatusAction.action.showDocumentComments
            "
          >
            <v-col
              ><v-switch
                v-model="configStatusAction.ruleCompleteTasks"
                inset
                label="Does the action complete any open tasks?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-select
                v-if="configStatusAction.ruleCompleteTasks"
                v-model="configStatusAction.cache.tasksCompleted_task_type"
                :items="taskTypes"
                item-text="name"
                item-value="task_type_id"
                label="Task Types auto completed"
                multiple
                clearable
              >
                <template v-slot:selection="{ item }">
                  <v-chip small color="teal" outlined>
                    <v-icon left>mdi-bell-outline</v-icon>
                    {{ item.name }}</v-chip
                  >
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configStatusAction.action.showScoring &&
              !configStatusAction.action.showDocumentComments
            "
          >
            <v-col
              ><v-switch
                v-model="configStatusAction.ruleCancelTasks"
                inset
                label="Does the action cancel any open tasks?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-select
                v-if="configStatusAction.ruleCancelTasks"
                v-model="configStatusAction.cache.tasksCancelled_task_type"
                :items="taskTypes"
                item-text="name"
                item-value="task_type_id"
                label="Task Types auto cancelled"
                multiple
                clearable
              >
                <template v-slot:selection="{ item }">
                  <v-chip small color="teal" outlined>
                    <v-icon left>mdi-bell-outline</v-icon>
                    {{ item.name }}</v-chip
                  >
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configStatusAction.action.showScoring &&
              !configStatusAction.action.showDocumentComments
            "
          >
            <v-col
              ><v-text-field
                v-model="configStatusAction.cache.confirmation"
                inset
                label="Confirmation message that appears after user takes this action"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-text-field
                v-model="configStatusAction.cache.action_message"
                inset
                label="Snackbar message that includes this action"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configStatusAction.cache.allow_warnings"
                inset
                label="Can this action be performed if there are document warnings?"
              ></v-switch
            ></v-col>
            <v-col
              ><v-switch
                v-model="configStatusAction.cache.allow_errors"
                inset
                label="Can this action be performed if there are document errors?"
              ></v-switch
            ></v-col>
          </v-row>
          <v-row dense v-if="configStatusAction.action.data_capture_type_id">
            <v-col
              >NOTE: This action
              {{
                isDataCaptureReadOnly(configStatusAction.action)
                  ? "displays"
                  : "captures"
              }}
              {{ getDataCaptureName(configStatusAction.action) }}
            </v-col>
            <v-col v-if="!isDataCaptureReadOnly(configStatusAction.action)">
              <v-switch
                v-model="configStatusAction.cache.data_capture_required"
                inset
                label="Data input required to complete action?"
              ></v-switch
              ><v-switch
                v-model="configStatusAction.cache.data_capture_mandatory"
                inset
                label="Data input required to edit document?"
              ></v-switch
              ><v-switch
                v-model="configStatusAction.cache.data_capture_multi"
                inset
                label="Separate Data input for each role holder?"
              ></v-switch
            ></v-col>
            <!-- <v-col
              ><v-switch v-model="configStatusAction.ruleUseRequiredData" inset>
                <template v-slot:label>
                  <span class="mr-3">Use</span>
                  <v-chip small label outlined color="brown"
                    ><v-icon left>mdi-tab-plus</v-icon
                    >{{ configStatusAction.action.data_capture_type_id }}</v-chip
                  >
                  <span class="ml-3">prompt?</span>
                </template></v-switch
              ></v-col
            > -->
          </v-row>
          <v-row
            dense
            v-if="configStatusAction.action.lifecycle_role_id_created"
          >
            <v-col
              >NOTE: This action prompts for input of
              <v-chip small label outlined color="blue"
                ><v-icon left>mdi-tab-plus</v-icon
                >{{
                  getRoleName(
                    configStatusAction.action.lifecycle_role_id_created
                  )
                }}</v-chip
              >
              Role
            </v-col>
            <v-col>
              <v-switch
                v-model="configStatusAction.cache.role_required"
                inset
                label="Always prompt for role if missing?"
              ></v-switch
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="configStatusActionHandler('delete')"
          >
            Delete
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configStatusActionHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configStatusActionHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="configAction.show" persistent max-width="890">
      <v-card v-if="configAction.show">
        <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3">Definition for action</span>
            <v-btn
              v-if="!configAction.editName"
              @click="configAction.editName = true"
              >{{ configAction.cache.name }}</v-btn
            >
            <v-text-field
              v-else
              v-model="configAction.cache.name"
              label="Action name"
              clearable
            ></v-text-field>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configAction.show = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.isEditor"
                inset
                label="Is this a document edit action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.isCreator"
                inset
                label="Is this a document creation action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"
              ><v-switch
                v-model="configAction.cache.isCopier"
                inset
                label="Is this a document copy action?"
              ></v-switch
            ></v-col>
            <v-col cols="6" v-show="configAction.cache.isCopier">
              <v-row>
                <v-select
                  v-model="configAction.cache.tmpl_id_copy_destination"
                  :items="templates"
                  item-text="tmpl_name"
                  item-value="tmpl_id"
                  label="Document Type created:"
                  clearable
                ></v-select>
              </v-row>
              <v-row>
                <v-switch
                  v-model="configAction.cache.copy_manual_roles"
                  inset
                  label="Copy manually assigned roles">
                </v-switch>
              </v-row>
              <v-row v-show="lifecycles.some(l => l.lifecycle_id === currentLifecycle.lifecycle_id && l.templates.some(t => t.tmpl_id === configAction.cache.tmpl_id_copy_destination && t.template_lifecycle_id !== null))">
                <v-switch
                  v-model="configAction.cache.copy_reviewgroup_roles"
                  inset
                  label="Copy review group assignment and corresponding roles">
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.isViewer"
                inset
                label="Is this a document view action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.showScoring"
                inset
                label="Is this a view scoring panel action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.isDocumentComment"
                inset
                label="Is this a document comment action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.showDocumentComments"
                inset
                label="Is this a view document comments action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.isComment"
                inset
                label="Is this a document part comment action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.editPartLockedStatus"
                inset
                label="Is this a document part lock action?"
              ></v-switch
            ></v-col>
            <v-col> </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              !configAction.cache.isCreator &&
              !configAction.cache.isCopier &&
              !configAction.cache.isViewer
            "
          >
            <v-col cols="6"
              ><v-switch
                v-model="configAction.ruleAddRole"
                inset
                label="Does the action add a document role?"
              ></v-switch
            ></v-col>
            <v-col cols="6">
              <v-select
                v-if="configAction.ruleAddRole"
                v-model="configAction.cache.lifecycle_role_id_created"
                :items="currentLifecycle.roles"
                item-text="name"
                item-value="lifecycle_role_id"
                label="Role Type added:"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="6" v-if="configAction.ruleAddRole"> </v-col>
            <v-col cols="6" v-if="configAction.ruleAddRole">
              <v-radio-group v-model="rtOption" @change="rtSetVal(true)">
                <v-radio
                  label="Prompt for user to add in role?"
                  value="1"
                ></v-radio>
                <v-row v-if="rtOption === '1'"
                  ><v-col>
                    <v-row dense>
                      <v-col cols="2"></v-col
                      ><v-col
                        ><v-switch
                          v-model="configAction.ruleAddRole"
                          inset
                          disabled
                          label="Individual Users?"
                        ></v-switch
                      ></v-col>
                    </v-row>
                    <v-row dense
                      ><v-col cols="2"></v-col
                      ><v-col
                        ><v-switch
                          v-model="configAction.cache.lifecycle_role_multi"
                          inset
                          label="Multiple individual Users?"
                        ></v-switch
                      ></v-col>
                    </v-row>
                    <v-row dense
                      ><v-col cols="2"></v-col
                      ><v-col
                        ><v-switch
                          v-model="
                            configAction.cache.lifecycle_role_review_group
                          "
                          inset
                          label="Using Review Groups?"
                        ></v-switch
                      ></v-col>
                    </v-row> </v-col
                ></v-row>
                <v-radio
                  label="Add all users with permissions for that role?"
                  value="2"
                ></v-radio>
                <v-radio
                  disabled
                  label="Match user based on hierarchy?"
                  value="3"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-col cols="2" v-if="configAction.ruleAddRole"
              ><v-switch
                v-model="configAction.cache.lifecycle_role_multi"
                inset
                :disabled="rtOption !== '1'"
                label="Multiple?"
              ></v-switch>
            </v-col> -->
          </v-row>
          <v-row dense v-if="!configAction.ruleAddRole">
            <v-col cols="3"
              ><v-switch
                v-model="configAction.ruleUseRequiredData"
                inset
                label="Uses data capture?"
              ></v-switch
            ></v-col>
            <v-col cols="9">
              <v-select
                v-if="configAction.ruleUseRequiredData"
                v-model="configAction.cache.data_capture_type_id"
                :items="dataCaptures"
                item-text="name"
                item-value="data_capture_type_id"
                label="Type:"
                clearable
              >
                <template v-slot:selection="{ item }">
                  <v-chip small label outlined color="brown"
                    ><v-icon left>mdi-tab-plus</v-icon>{{ item.name }}</v-chip
                  >
                </template></v-select
              >
            </v-col></v-row
          >
          <v-row v-if="configAction.ruleUseRequiredData">
            <v-col cols="3"> </v-col>
            <v-col cols="3">
              <v-radio-group v-model="dcOption" @change="dcSetVal(true)">
                <v-radio label="Show in Header?" value="1"></v-radio>
                <v-radio label="Show in Popup?" value="2"></v-radio>
                <v-radio label="Show  In-Line?" value="3"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6"
              ><v-switch
                v-model="configAction.cache.isDataCaptureViewer"
                inset
                label="Read Only view?"
              ></v-switch></v-col
          ></v-row>
          <v-row v-if="configAction.ruleUseRequiredData">
            <v-col cols="1"> </v-col>
            <v-col
              cols="11"
              v-if="
                questionnaireQuestions.some(
                  (x) =>
                    x.data_capture_type_id ===
                    configAction.cache.data_capture_type_id
                )
              "
              ><v-row
                ><v-col
                  ><h3>
                    Question Template Destinations ({{
                      configAction.cache.questionDestinations.length
                    }})
                    <v-icon
                      @click="
                        showQuestionDestination = !showQuestionDestination
                      "
                      title="Add a question from the selected questionnaire"
                      >{{
                        showQuestionDestination
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon
                    >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <div style="max-width: 200px">
                        Use this feature to specify which data should be copied
                        from the completed questionnaire to the specified
                        template parts and attributes. NOTE: if there are
                        multiple questionnaires for one document only the latest
                        will be used.
                      </div>
                    </v-tooltip>
                    <v-icon
                      @click="pickerHandler('init', 'QUESTION')"
                      title="Add a question from the selected questionnaire"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </h3></v-col
                ></v-row
              ><template v-if="showQuestionDestination">
                <v-row
                  v-for="(qd, qdi) in configAction.cache.questionDestinations"
                  :key="'qd' + qdi"
                >
                  <v-col
                    style="border: solid 1px lightgray; border-radius: 10px"
                  >
                    <v-row dense>
                      <v-col cols="11">
                        <v-chip label>{{
                          questionnaireQuestions.find(
                            (x) =>
                              x.questionnaire_question_id ===
                              qd.questionnaire_question_id
                          ).question_number
                        }}</v-chip>
                      </v-col>
                      <v-col cols="1"
                        ><v-icon
                          v-if="qd.isDeleted"
                          @click="qd.isDeleted = false"
                          >mdi-delete-off</v-icon
                        ><v-icon
                          v-else
                          @click="
                            configStatusActionHandler('removeQuestion', qd)
                          "
                          >mdi-delete</v-icon
                        ></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="qd.pt_id"
                          hide-details
                          dense
                          :items="partTypes"
                          item-text="pt_name"
                          item-value="pt_id"
                          label="Part Type"
                        ></v-select
                      ></v-col>
                      <v-col>
                        <v-chip
                          label
                          v-if="
                            questionnaireQuestions.find(
                              (x) =>
                                x.questionnaire_question_id ===
                                qd.questionnaire_question_id
                            ).question_category
                          "
                          >{{
                            questionnaireQuestions.find(
                              (x) =>
                                x.questionnaire_question_id ===
                                qd.questionnaire_question_id
                            ).question_category
                          }}</v-chip
                        ></v-col
                      >
                      <v-col>
                        <v-chip
                          label
                          outlined
                          v-if="
                            questionnaireQuestions.find(
                              (x) =>
                                x.questionnaire_question_id ===
                                qd.questionnaire_question_id
                            ).scoring_question
                          "
                          >Scoring Question</v-chip
                        ></v-col
                      >
                      <v-col cols="1"></v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-row dense>
                          <v-col
                            cols="6"
                            v-if="
                              questionnaireQuestions.find(
                                (x) =>
                                  x.questionnaire_question_id ===
                                  qd.questionnaire_question_id
                              ).question_category
                            "
                          >
                            <v-select
                              v-model="qd.attribute_title_category"
                              :items="
                                partTypeAttibutes.filter(
                                  (x) => x.pt_id === qd.pt_id
                                )
                              "
                              item-text="attribute"
                              item-value="attribute"
                              label="Category Attribute"
                              hide-details
                              dense
                              clearable
                            ></v-select
                          ></v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="qd.attribute_title_question"
                              :items="
                                partTypeAttibutes.filter(
                                  (x) => x.pt_id === qd.pt_id
                                )
                              "
                              item-text="attribute"
                              item-value="attribute"
                              label="Question Attribute"
                              hide-details
                              dense
                              clearable
                            ></v-select
                          ></v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="qd.attribute_title_answer"
                              :items="
                                partTypeAttibutes.filter(
                                  (x) => x.pt_id === qd.pt_id
                                )
                              "
                              item-text="attribute"
                              item-value="attribute"
                              label="Answer Attribute"
                              hide-details
                              dense
                              clearable
                            ></v-select
                          ></v-col>
                          <v-col
                            cols="6"
                            v-if="
                              questionnaireQuestions.find(
                                (x) =>
                                  x.questionnaire_question_id ===
                                  qd.questionnaire_question_id
                              ).scoring_question
                            "
                          >
                            <v-select
                              v-model="qd.attribute_title_score"
                              :items="
                                partTypeAttibutes.filter(
                                  (x) => x.pt_id === qd.pt_id
                                )
                              "
                              item-text="attribute"
                              item-value="attribute"
                              label="Score Attribute"
                              hide-details
                              dense
                              clearable
                            ></v-select
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                  </v-col> </v-row
              ></template>
              <v-row
                ><v-col
                  ><h3>
                    Overall Score Destination
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <div style="max-width: 200px">
                        Use this feature to specify the template part and
                        attribute where the completed questionnaire overall
                        score should be copied to.
                      </div>
                    </v-tooltip>
                  </h3></v-col
                ><v-col>
                  <v-select
                    v-model="
                      configAction.cache
                        .questionnaire_destination_pt_id_total_score
                    "
                    hide-details
                    dense
                    :items="partTypes"
                    item-text="pt_name"
                    item-value="pt_id"
                    label="Part Type" clearable
                  ></v-select>
                  <v-select
                    v-model="
                      configAction.cache
                        .questionnaire_destination_attribute_title_total_score
                    "
                    :items="
                      partTypeAttibutes.filter(
                        (x) =>
                          x.pt_id ===
                          configAction.cache
                            .questionnaire_destination_pt_id_total_score
                      )
                    "
                    item-text="attribute"
                    item-value="attribute"
                    label="Total Score Attribute"
                    hide-details
                    dense
                    clearable
                  ></v-select></v-col
              ></v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"
              ><v-switch
                v-model="configAction.ruleDataCaptureTriggeredACtion"
                inset
                label="Can action be triggered from a data input?"
              ></v-switch
            ></v-col>
            <v-col cols="3"> </v-col>
            <v-col cols="9">
              <v-select
                v-if="configAction.ruleDataCaptureTriggeredACtion"
                v-model="configAction.cache.data_capture_type_id_triggered_from"
                :items="dataCaptures"
                item-text="name"
                item-value="data_capture_type_id"
                label="Prompt type:"
                clearable
              >
                <template v-slot:selection="{ item }">
                  <v-chip small label outlined color="brown"
                    ><v-icon left>mdi-tab-plus</v-icon>{{ item.name }}</v-chip
                  >
                </template></v-select
              >
            </v-col>
            <v-col cols="3"> </v-col>
            <v-col cols="9">
              <v-select
                v-if="configAction.ruleDataCaptureTriggeredACtion"
                v-model="configAction.cache.questionnaire_answer_id_trigger"
                :items="
                  questionnaireEndAnswers.filter(
                    (x) =>
                      x.data_capture_type_id ===
                      configAction.cache.data_capture_type_id_triggered_from
                  )
                "
                item-text="title"
                item-value="questionnaire_answer_id"
                label="Specific Trigger question/answer (leave blank if available on any completion):"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.ruleClipboard"
                inset
                label="Copy data to clipboard?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-text-field
                v-if="configAction.ruleClipboard"
                v-model="configAction.cache.clipboard_format"
                label="Clipboard data template:"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.ruleDownload"
                inset
                label="Download document as file?"
              ></v-switch
            ></v-col>
            <v-col>
              <v-select
                v-if="configAction.ruleDownload"
                v-model="configAction.cache.download_format"
                label="Download format"
                :items="downloadFormats"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              ><v-switch
                v-model="configAction.cache.emailDoc"
                inset
                label="Email document?"
              ></v-switch
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="configAction.cache.action_menu_id"
                :items="actionMenus"
                item-text="name"
                item-value="action_menu_id"
                label="Document Action Menu:"
                clearable
              >
                <template v-slot:selection="{ item }">
                  <v-chip small label outlined color="teal"
                    ><v-icon left>mdi-tab-plus</v-icon>{{ item.name }}</v-chip
                  >
                </template></v-select
              >
            </v-col>
            <v-col
              ><v-switch
                v-if="
                  !configAction.cache.isEditor &&
                  !configAction.cache.isCreator &&
                  !configAction.cache.isViewer &&
                  !configAction.cache.showScoring &&
                  !configAction.cache.showDocumentComments &&
                  !configAction.cache.isDataCaptureEditInPage &&
                  !configAction.cache.isDataCaptureEditInHeader &&
                  !configAction.cache.isComment &&
                  !configAction.cache.isDocumentComment
                "
                v-model="configAction.cache.isListAction"
                inset
                label="Make action available in list views?"
              ></v-switch
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"
              ><v-text-field
                v-model="configAction.cache.tooltip"
                label="Tooltip"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4"
              ><v-text-field
                v-model="configAction.cache.position"
                type="number"
                label="Position"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="configActionHandler('delete')"
          >
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="configActionHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configActionHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="configStatus.show" persistent max-width="450">
      <v-card v-if="configStatus.show">
        <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3">Definition for status</span>
            <v-chip
              class="mx-2 status-chip"
              label
              outlined
              v-if="!configStatus.editName"
              @click="configStatus.editName = true"
              :color="configStatus.cache.colour"
              >{{ configStatus.cache.name }}</v-chip
            >
            <v-text-field
              v-else
              v-model="configStatus.cache.name"
              label="Status name"
              clearable
            ></v-text-field>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configStatus.show = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <!-- <v-row dense>
            <v-col>
              <v-select
                v-model="configStatus.stage"
                :items="currentLifecycle.stages"
                item-text="name"
                item-value="name"
                return-object
                label="Stage:"
                clearable
              ></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col class="d-flex justify-end">
              <v-switch
                v-model="configStatus.cache.listview_default_hidden"
                label="Hide by default in list views">
              </v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                v-model="configStatus.cache.colour"
                :items="['orange', 'gray', 'red', 'blue', 'green']"
                label="Status Colour:"
                clearable
              ></v-select
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                v-model="configStatus.cache.ds_id"
                :items="docStatuses"
                item-text="doc_status"
                item-value="ds_id"
                label="Legacy Status:"
                clearable
              ></v-select
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="configStatus.cache.info_message"
                label="Information Message (appears when doc opened)"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                v-model="configStatus.cache.position"
                label="Position"
                type="number"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="configStatusHandler('delete')"
          >
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="configStatusHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configStatusHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="configRole.show" persistent max-width="450">
      <v-card v-if="configRole.show">
        <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3">Definition for role</span>
            <v-chip
              class="mx-2 status-chip"
              outlined
              v-if="!configRole.editName"
              @click="configRole.editName = true"
              >{{ configRole.cache.name }}</v-chip
            >
            <v-text-field
              v-else
              v-model="configRole.cache.name"
              label="Role name"
              clearable
            ></v-text-field>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configRole.show = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <!-- v-model="configRole.cache.permissions_permission" -->
              <v-select
                dense
                v-model="configRole.cache.permissions_permission"
                :items="permissions"
                item-text="name"
                item-value="permission_id"
                label="Role available to users with any of these permissions:"
                clearable
                multiple
              >
                <template v-slot:selection="{ item }">
                  <v-chip small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="configRole.cache.input_label"
                label="Role input label"
                clearable
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="configRole.cache.abbreviation"
                label="Role abbreviation"
                clearable
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-switch
                v-model="configRole.cache.review_group_assignable"
                label="Assignable via Review Group"
              ></v-switch
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="configRoleHandler('delete')">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="configRoleHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="configRoleHandler('save')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="configGeneral.show" persistent max-width="450">
      <v-card v-if="configGeneral.show">
        <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3"
              >{{ configGeneral.isNew ? "New " : "Edit "
              }}{{ configGeneral.title }} Details</span
            >
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configGeneralHandler('cancel')">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="configGeneral.textValue"
                :label="configGeneral.label"
                clearable
              ></v-text-field>
              <template v-if="configGeneral.type && configGeneral.type.cols">
                <template v-for="col in configGeneral.type.cols">
                  <v-select
                    v-if="col.name === 'upt_id'"
                    :key="col.name"
                    v-model="configGeneral.item[col.name]"
                    :items="profileTypes"
                    item-text="profile_type"
                    item-value="upt_id"
                    :label="col.label || col.name"
                    clearable
                  ></v-select>
                  <v-switch
                    v-else-if="col.type === 'boolean'"
                    :key="col.name"
                    v-model="configGeneral.item[col.name]"
                    :label="col.label || col.name"
                  ></v-switch>
                  <v-text-field
                    v-else-if="col.type === 'number'"
                    :key="col.name"
                    v-model="configGeneral.item[col.name]"
                    :label="col.label || col.name"
                    type="number"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :key="col.name"
                    v-model="configGeneral.item[col.name]"
                    :label="col.label || col.name"
                    clearable
                  ></v-text-field>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!configGeneral.isNew"
            color="red darken-1"
            text
            @click="configGeneralHandler('delete')"
          >
            Delete
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configGeneralHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configGeneralHandler('save')"
            :disabled="!configGeneral.textValue"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="configTemplateLifecycle.show" persistent max-width="450">
      <v-card v-if="configTemplateLifecycle.show">
        <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3">{{ configTemplateLifecycle.title }}</span>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configTemplateLifecycleHandler('cancel')"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <v-switch
                v-model="configTemplateLifecycle.isDefault"
                label="Is this the default workflow?"
                clearable
              ></v-switch
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="configTemplateLifecycle.lifecycle_selection_text"
                label="Phrase for Document Creation"
                clearable
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                v-model="configTemplateLifecycle.ht_id_empty_trigger"
                label="Triggered By empty Hierarchy Type? (phrase above will appear in list)"
                :items="hierarchy_types"
                item-text="ht_name"
                item-value="ht_id"
                clearable
              ></v-select
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!configTemplateLifecycle.isNew"
            color="red darken-1"
            text
            @click="configTemplateLifecycleHandler('delete')"
          >
            Delete
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configTemplateLifecycleHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configTemplateLifecycleHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="configStatusAction.picker.show"
      persistent
      max-width="390"
    >
      <v-card>
        <v-card-title class="text-h5"
          >Pick {{ configStatusAction.picker.type }} Type</v-card-title
        >
        <v-card-text
          ><v-select
            v-model="configStatusAction.picker.key"
            :items="configStatusAction.picker.list"
            :item-text="configStatusAction.picker.itemText"
            :item-value="configStatusAction.picker.itemValue"
          ></v-select
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="pickerHandler('cancel')">
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!configStatusAction.picker.key"
            @click="pickerHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="configMessage.show" persistent max-width="850">
      <v-card v-if="configMessage.show">
        <v-toolbar flat dense color="blue-grey" dark>
          <v-toolbar-title>{{ configMessage.title }}</v-toolbar-title
          ><v-spacer></v-spacer
          ><v-btn icon @click="configMessageHandler('cancel')">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3">{{ configMessage.title }}</span>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configMessageHandler('cancel')">mdi-close</v-icon>
        </v-card-title> -->
        <v-card-text
          ><v-container>
            <v-row dense>
              <v-col>
                <v-text-field
                  dense
                  v-model="configMessage.name"
                  label="Name"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  dense
                  v-model="configMessage.description"
                  label="Description"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  dense
                  v-model="configMessage.method"
                  label="Method:"
                  :items="configMessage.methods"
                  @input="configMessage.isDirty = true"
                ></v-select
              ></v-col>
            </v-row>
            <v-row dense v-if="configMessageColumnVisible('email_template_id')">
              <v-col>
                <v-select
                  dense
                  v-model="configMessage.email_template_id"
                  label="Email Template"
                  :items="emailTemplates"
                  item-text="title"
                  item-value="et_id"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-select
              ></v-col>
              <v-col>
                <v-text-field
                  dense
                  v-model="configMessage.email_from"
                  label="Email From"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row dense v-if="configMessageColumnVisible('email_template_id')">
              <v-col>
                <v-text-field
                  dense
                  v-model="configMessage.email_to"
                  label="Emal To"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row dense v-if="configMessageColumnVisible('email_template_id')">
              <v-col>
                <v-text-field
                  dense
                  v-model="configMessage.email_subject"
                  label="Email Subject"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row dense v-if="configMessageColumnVisible('destination_url')">
              <v-col>
                <v-text-field
                  dense
                  class="roundish"
                  v-model="configMessage.destination_url"
                  label="Destination URL"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row dense v-if="configMessageColumnVisible('data_template')">
              <v-col>
                <v-textarea
                  class="roundish"
                  dense
                  v-model="configMessage.data_template"
                  label="Data Template (use <%= xxxxx %> tags for replacements): "
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row
              dense
              v-if="configMessageColumnVisible('destination_auth_token')"
            >
              <v-col>
                <v-text-field
                  dense
                  v-model="configMessage.destination_auth_token"
                  label="Auth Token"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  dense
                  v-model="configMessage.destination_authentication"
                  label="Authentication"
                  clearable
                  @input="configMessage.isDirty = true"
                ></v-text-field
              ></v-col> </v-row
          ></v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!configMessage.isNew"
            color="red darken-1"
            text
            @click="configMessageHandler('delete')"
          >
            Delete
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configMessageHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!configMessage.isDirty"
            @click="configMessageHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="configTaskNot.show" persistent max-width="850">
      <v-card v-if="configTaskNot.show">
        <v-toolbar flat dense color="blue-grey" dark>
          <v-toolbar-title>{{ configTaskNot.title }}</v-toolbar-title
          ><v-spacer></v-spacer
          ><v-btn icon @click="configTaskNotHandler('cancel')">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3">{{ configTaskNot.title }}</span>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configTaskNotHandler('cancel')">mdi-close</v-icon>
        </v-card-title> -->
        <v-card-text
          ><v-container>
            <v-row dense>
              <v-col>
                <v-text-field
                  dense
                  v-model="configTaskNot.name"
                  label="Name"
                  clearable
                  @input="configTaskNot.isDirty = true"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  dense
                  v-model="configTaskNot.description"
                  label="Description"
                  clearable
                  @input="configTaskNot.isDirty = true"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  dense
                  v-model="configTaskNot.email_template_id"
                  label="Email Template"
                  :items="emailTemplates"
                  item-text="title"
                  item-value="et_id"
                  clearable
                  @input="configTaskNot.isDirty = true"
                ></v-select
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  dense
                  v-model="configTaskNot.email_from"
                  label="Email From"
                  clearable
                  @input="configTaskNot.isDirty = true"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  dense
                  v-model="configTaskNot.email_subject"
                  label="Email Subject"
                  clearable
                  @input="configTaskNot.isDirty = true"
                ></v-text-field
              ></v-col> </v-row
          ></v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!configTaskNot.isNew"
            color="red darken-1"
            text
            @click="configTaskNotHandler('delete')"
          >
            Delete
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configTaskNotHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!configTaskNot.isDirty"
            @click="configTaskNotHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="configEmailTemplate.show" persistent max-width="850">
      <v-card v-if="configEmailTemplate.show">
        <v-toolbar flat dense color="blue-grey" dark>
          <v-toolbar-title>{{ configEmailTemplate.title }}</v-toolbar-title
          ><v-spacer></v-spacer
          ><v-btn icon @click="configEmailTemplateHandler('cancel')">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-card-title>
          <v-sheet elevation="4" rounded class="px-3 mb-4">
            <span class="mr-3">{{ configEmailTemplate.title }}</span>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-icon @click="configEmailTemplateHandler('cancel')"
            >mdi-close</v-icon
          >
        </v-card-title> -->
        <v-card-text
          ><v-container>
            <v-row dense>
              <v-col>
                <v-text-field
                  dense
                  cols="4"
                  v-model="configEmailTemplate.name"
                  label="Template Name"
                  clearable
                  @input="configEmailTemplate.isDirty = true"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-select
                  dense
                  v-model="configEmailTemplate.template_type"
                  label="Template Type:"
                  :items="configEmailTemplate.template_types"
                  @input="configEmailTemplate.isDirty = true"
                ></v-select
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  dense
                  v-model="configEmailTemplate.subject"
                  label="Email Subject"
                  clearable
                  @input="configEmailTemplate.isDirty = true"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  dense
                  v-model="configEmailTemplate.from"
                  label="Email From"
                  clearable
                  @input="configEmailTemplate.isDirty = true"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col>
                Email Body
                <v-textarea
                  class="roundish"
                  v-model="configEmailTemplate.body"
                  label="Body (use <%= xxxxx %> tags for replacements): "
                  clearable
                  @input="configEmailTemplate.isDirty = true"
                ></v-textarea
              ></v-col>
              <v-col>
                Paste mjml into
                <a href="https://mjml.io/try-it-live" target="_blank"
                  >https://mjml.io/try-it-live</a
                >
                to generate email html to paste into body
                <v-textarea
                  class="roundish"
                  v-model="configEmailTemplate.mjml"
                  label="mjml (use <%= xxxxx %> tags for replacements): "
                  clearable
                  @input="configEmailTemplate.isDirty = true"
                ></v-textarea
              ></v-col>
            </v-row>
            <v-row dense
              ><v-col
                >Click tags to copy tag to clipboard for pasting into template
              </v-col>
            </v-row>
            <v-row dense
              ><v-col>
                Standard Tags:<br />
                <v-chip dense @click="insertTag('user_name')">user_name</v-chip>
                <v-chip dense @click="insertTag('client_name')"
                  >client_name</v-chip
                >
                <v-chip dense @click="insertTag('user_email')"
                  >user_email</v-chip
                >
                <v-chip dense @click="insertTag('user_id')"
                  >user_id</v-chip
                ></v-col
              ><v-col
                v-if="
                  ['documentEmail', 'notification', 'task'].some(
                    (x) => x === configEmailTemplate.template_type
                  )
                "
              >
                Document Tags:<br />
                <v-chip dense @click="insertTag('document.document_name')"
                  >document.document_name</v-chip
                >
                <v-chip dense @click="insertTag('document.document_type')"
                  >document.document_type</v-chip
                >
                <v-chip dense @click="insertTag('document.doc_id')"
                  >document.doc_id</v-chip
                >
                <v-chip dense @click="insertTag('document.document_reference')"
                  >document.document_reference</v-chip
                >
                <v-chip dense @click="insertTag('document.document_status')"
                  >document.document_status</v-chip
                >
                <v-chip dense @click="insertTag('link')">link</v-chip> </v-col
              ><v-col
                v-if="
                  ['task'].some((x) => x === configEmailTemplate.template_type)
                "
              >
                Task Tags:<br />
                <v-chip dense @click="insertTag('task.task_name')"
                  >task.task_name</v-chip
                >
                <v-chip dense @click="insertTag('task.task_description')"
                  >task.task_description</v-chip
                >
                <v-chip dense @click="insertTag('task.due_date')"
                  >task.due_date</v-chip
                >
                <v-chip dense @click="insertTag('task.assignee_name')"
                  >task.assignee_name</v-chip
                >
                <v-chip dense @click="insertTag('task.isUnassigned')"
                  >task.isUnassigned</v-chip
                >
                <v-chip dense @click="insertTag('activity_created_datetime')"
                  >activity_created_datetime</v-chip
                >
                <v-chip dense @click="insertTag('role_name')"
                  >role_name</v-chip
                > </v-col
              ><v-col
                v-if="
                  ['notification'].some(
                    (x) => x === configEmailTemplate.template_type
                  )
                "
              >
                Notification Tags:<br />
                <v-chip
                  dense
                  @click="insertTag('notification.notification_name ')"
                  >notification.notification_name
                </v-chip>
                <v-chip
                  dense
                  @click="insertTag('notification.notification_description')"
                  >notification.notification_description</v-chip
                >
                <v-chip dense @click="insertTag('activity_created_datetime')"
                  >activity_created_datetime</v-chip
                >
                <v-chip dense @click="insertTag('activity_user_name')"
                  >activity_user_name</v-chip
                >
                <v-chip dense @click="insertTag('role_name')">role_name</v-chip>
              </v-col>
              <v-col
                v-if="
                  ['reset password'].some(
                    (x) => x === configEmailTemplate.template_type
                  )
                "
              >
                Password Reset Tags:<br />
                <v-chip dense @click="insertTag('resetPasswordUrl')"
                  >resetPasswordUrl</v-chip
                >
              </v-col>
              <v-col
                v-if="
                  ['set password'].some(
                    (x) => x === configEmailTemplate.template_type
                  )
                "
              >
                Password Set Tags:<br />
                <v-chip dense @click="insertTag('setPasswordUrl')"
                  >setPasswordUrl</v-chip
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!configEmailTemplate.isNew"
            color="red darken-1"
            text
            @click="configEmailTemplateHandler('delete')"
          >
            Delete
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="configEmailTemplateHandler('cancel')"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="!configEmailTemplate.isDirty"
            @click="configEmailTemplateHandler('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UserDetail
      :user_id="currentUser.user_id"
      :showDialogue="currentUser.show"
      @saved="userSaved"
      @closed="closeUserDialogue"
      @deactivated="userDeactivated"
    ></UserDetail>
    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <v-file-input
      v-show="false"
      accept=".json"
      ref="inputFile"
      dense
      @change="uploadWorkflowFromFile"
      label="Select File"
    ></v-file-input>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import ResponseHandler from "@/components/ResponseHandler";
import UserDetail from "@/components/cUserDetail";
import utils from "@/common/utils.js";
import QuestionnareDefinition from "@/components/admin/Questionnaire";

export default {
  name: "WorkflowEngine",
  components: {
    ResponseHandler,
    UserDetail,
    QuestionnareDefinition,
  },
  data: function () {
    return {
      tab: 0,
      isLoading: false,
      parentQuestion: {},
      allQuestionList: [],
      questionnaireEndAnswers: [],
      questionnaireQuestions: [],
      partTypes: [],
      partTypeAttibutes: [],
      selectedDataCaptureId: 0,
      selectedDataCapture: {},
      questionnaireDialogue: { show: false, questionnaire_id: null },
      checkLists: [],
      documents: [],
      currentDoc: null,
      currentWorkflowID: null,
      unassignedTasks: [],
      unassignedNotifications: [],
      currentLifecycle: null,
      actionMenus: [],

      users: [],
      docStatuses: [],
      permissionGroups: [],
      profileTypes: [],
      permissions: [],
      lifecycles: [],
      templates: [],
      hierarchy_types: [],
      taskTypes: [],
      notificationTypes: [],
      messageTypes: [],
      dataCaptures: [],
      emailTemplates: [],
      isDirty: false,
      metadata: null,
      response: null,

      assignUser: {
        show: false,
        role: "",
        userList: [],
        user_id: null,
        saveAction: "save",
      },
      addComment: {
        show: false,
        text: "",
        label: "",
      },
      checkList: {
        show: false,
        name: null,
        title: null,
        description: null,
        checks: [],
      },
      newDoc: {
        show: false,
        title: "",
        userList: [],
        user_id: null,
      },
      configStatusAction: {
        show: false,
        isNew: false,
        status: null,
        action: null,
        actionAvailable: null,
        cache: null,
        ruleUpdateStatus: false,
        ruleAddRole: false,
        ruleTriggerTask: false,
        ruleTriggerNotification: false,
        ruleTriggerMessage: false,
        ruleCompleteTasks: false,
        ruleCancelTasks: false,
        ruleUseRequiredData: false,
        picker: { show: false, type: null, list: [], key: null },
      },
      configAction: {
        show: false,
        isNew: false,
        cache: null,
        editName: false,
        ruleAddRole: false,
        ruleClipboard: false,
        ruleDownload: false,
        ruleUseRequiredData: false,
        ruleDataCaptureTriggeredACtion: false,
        picker: { show: false, type: null, list: [], key: null },
      },
      configStatus: {
        show: false,
        isNew: false,
        cache: null,
        editName: false,
        //stage: null,
        picker: { show: false, type: null, list: [], key: null },
      },
      configRole: {
        show: false,
        isNew: false,
        cache: null,
        editName: false,
        picker: { show: false, type: null, list: [], key: null },
      },
      configGeneral: {
        show: false,
        isNew: false,
        keyColumn: null,
        keyValue: null,
        title: null,
        label: null,
        textColumn: null,
        textValue: null,
        item: null,
        type: null,
        picker: { show: false, type: null, list: [], key: null },
      },
      configTemplateLifecycle: {
        show: false,
        isNew: false,
        isDeleted: false,
        lifecycle_selection_text: null,
        ht_id_empty_trigger: null,
        isDefault: null,
        title: null,
        item: null,
      },
      configMessage: {
        show: false,
        isNew: false,
        isDeleted: false,
        isDirty: false,
        entity: "message_type",
        entityLabel: "Message Type",
        saveRoute: "workflow/saveMessageType/",
        name: null,
        description: null,
        method: null,
        email_subject: null,
        email_to: null,
        email_from: null,
        destination_url: null,
        destination_authentication: null,
        destination_auth_token: null,
        data_template: null,
        email_template_id: null,
        methods: ["POST", "GET", "EMAIL", "REDIRECT"],
        item: null,
      },
      configTaskNot: {
        show: false,
        isNew: false,
        isDeleted: false,
        isDirty: false,
        entity: "",
        entityLabel: "",
        saveRoute: "",
        name: null,
        description: null,
        email_subject: null,
        email_from: null,
        email_template_id: null,
        item: null,
      },
      configEmailTemplate: {
        show: false,
        isNew: false,
        isDeleted: false,
        isDirty: false,
        entity: "email_template",
        entityLabel: "Email Template",
        saveRoute: "workflow/saveEmailTemplate/",
        et_id: null,
        name: null,
        subject: null,
        from: null,
        body: null,
        mjml: null,
        template_type: null,
        template_types: [
          "general email",
          "reset password",
          "set password",
          "documentEmail",
          "notification",
          "task",
        ],
        item: null,
      },
      pSearch: "",
      dcOption: "2",
      rtOption: "1",
      downloadFormats: [
        { name: "PDF", value: "PDF" },
        { name: "RTF", value: "RTF" },
        { name: "Word", value: "WORD" },
        { name: "Word with Notes", value: "WORD_NOTES" },
        { name: "Interview Template", value: "INTERVIEW_TMPL" },
        { name: "Intake Flow", value: "INTAKE_FLOW" },
        { name: "JobShare Template", value: "JOBSHARE_TMPL" },
        { name: "Screening Template", value: "SCREENING_TMPL" },
      ],
      currentUser: { user_id: null, show: false },
      userSetUp: {
        filter: "",
        filtered: [],
        page: 1,
        pages: 1,
        pageSize: 20,
        pgFilter: [],
      },
      permissionSetUp: {
        filter: "",
        filtered: [],
        page: 1,
        pages: 1,
        pageSize: 20,
        pgFilter: [],
      },
      showQuestionDestination: true,
    };
  },
  computed: {
    isDebugMode() {
      return window.location.host.indexOf("localhost") >= 0;
    },
  },
  created() {
    this.getRefData();
  },
  methods: {
    insertTag(tag) {
      let linkedText = `<%= ${tag} %>`;
      this.copyToClipboard(linkedText);
      //   const viewFragment = _ckeditor.data.processor.toView(linkedText);
      //   const modelFragment = _ckeditor.data.toModel(viewFragment);
      //   _ckeditor.model.insertContent(modelFragment);
    },
    copyToClipboard(text) {
      let container;
      let dialog = document.getElementsByClassName(
        "v-dialog--active v-dialog--fullscreen"
      );
      if (dialog && dialog.length) container = dialog[0];
      else container = document.body;

      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.setAttribute("readonly", "");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      container.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      container.removeChild(textarea);
    },
    dcSetVal(updated) {
      if (!updated) {
        this.dcOption = this.configAction.cache.isDataCaptureEditInHeader
          ? "1"
          : this.configAction.cache.isDataCaptureEditPopup
          ? "2"
          : "3";
      } else {
        this.configAction.cache.isDataCaptureEditInHeader =
          this.dcOption === "1" ? 1 : 0;
        this.configAction.cache.isDataCaptureEditPopup =
          this.dcOption === "2" ? 1 : 0;
        this.configAction.cache.isDataCaptureEditInPage =
          this.dcOption === "3" ? 1 : 0;
      }
    },
    rtSetVal(updated) {
      if (!updated) {
        this.rtOption =
          this.configAction.cache.lifecycle_role_id_created &&
          !this.configAction.cache.lifecycle_role_auto
            ? "1"
            : this.configAction.cache.lifecycle_role_auto
            ? "2"
            : "3";
      } else {
        this.configAction.cache.lifecycle_role_auto =
          this.rtOption === "1" ? 0 : 1;
        this.configAction.cache.lifecycle_role_auto =
          this.rtOption === "2" ? 1 : 0;
        // this.configAction.cache.lifecycle_role_auto =
        //   this.rtOption === '3' ? 1 : 0;
      }
    },
    showQuestionnaire(item) {
      this.questionnaireDialogue.show = true;
      this.questionnaireDialogue.questionnaire_id = Number(
        item.questionnaire_id
      );
    },
    selectDataCapture() {
      let possibleError = false;

      axios
        .get("workflow/getDataCapture/" + this.selectedDataCaptureId)
        .then((resp) => {
          possibleError = true;
          if (resp.status === 200) {
            this.selectedDataCapture = resp.data.response;
          } else {
            this.response = resp.data;
          }
          //console.log(resp);
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    getRefData() {
      let possibleError = false;
      this.isLoading = true;

      axios
        .get("workflow/list/")
        .then((resp) => {
          this.isLoading = false;
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.lifecycles = resp.data.Data.lifecycles;
            this.taskTypes = resp.data.Data.taskTypes;
            this.notificationTypes = resp.data.Data.notificationTypes;
            this.messageTypes = resp.data.Data.messageTypes;
            this.emailTemplates = resp.data.Data.emailTemplates;
            this.dataCaptures = resp.data.Data.dataCaptures;
            this.questionnaireEndAnswers =
              resp.data.Data.questionnaireEndAnswers;
            this.questionnaireQuestions = resp.data.Data.questionnaireQuestions;
            this.partTypes = resp.data.Data.partTypes;
            this.partTypeAttibutes = resp.data.Data.partTypeAttibutes;
            this.docStatuses = resp.data.Data.docStatuses;
            this.permissions = resp.data.Data.permissions;
            this.filterPermissions();
            resp.data.Data.permissionGroups.forEach(
              (pg) => (pg.visible = true)
            );
            this.permissionGroups = resp.data.Data.permissionGroups;
            this.profileTypes = resp.data.Data.profileTypes;
            this.metadata = resp.data.Data.metadata;
            this.users = resp.data.Data.users;
            this.filterUsers();
            this.actionMenus = resp.data.Data.actionMenus;
            this.templates = resp.data.Data.templates;
            this.hierarchy_types = resp.data.Data.hierarchy_types;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    saveLifecycle(lifecycle) {
      let possibleError = false;

      axios
        .post("workflow/adminsave/", lifecycle || this.currentLifecycle)
        .then((resp) => {
          this.isLoading = false;
          possibleError = true;
          if (resp.data.Data.lifecycle && resp.data.Data.lifecycle.length) {
            this.currentLifecycle = null;
            this.$nextTick(() => {
              this.currentLifecycle = resp.data.Data.lifecycle[0];
              if (
                !this.lifecycles.some(
                  (l) => l.lifecycle_id === this.currentLifecycle.lifecycle_id
                )
              ) {
                this.lifecycles.push({
                  lifecycle_id: this.currentLifecycle.lifecycle_id,
                  name: this.currentLifecycle.name,
                });
                this.currentWorkflowID = this.currentLifecycle.lifecycle_id;
              }
            });
          }
          this.response = resp.data;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    getModel() {
      let possibleError = false;
      this.isLoading = true;

      this.currentLifecycle = null;
      axios
        .get(`workflow/admin/${this.currentWorkflowID}`)
        .then((resp) => {
          this.isLoading = false;
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.currentLifecycle = resp.data.Data.lifecycle[0];
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    pickWorkflow() {
      this.getModel();
    },
    downloadWorkflow() {
      const definitionJson = JSON.stringify({
        lifecycle: this.currentLifecycle,
        permissions: this.permissions,
        taskTypes: this.taskTypes,
        notificationTypes: this.notificationTypes,
        messageTypes: this.messageTypes,
        dataCaptures: this.dataCaptures,
      });
      const fileName = `${this.currentLifecycle.name.replaceAll(
        " ",
        "_"
      )}_Workflow.json`;
      utils.downloadFile(definitionJson, fileName, "text/json");
    },
    uploadWorkflow() {
      if (
        confirm(
          "Update workflow based on uploaded definition?\nAll configuration will be overwritten!"
        )
      )
        this.$refs.inputFile.$refs.input.click();
    },
    uploadWorkflowFromFile(file) {
      if (file) {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            const uploadedWorkflow = JSON.parse(reader.result);
            this.restoreWorkflowFromUpload(uploadedWorkflow);
            this.isLoading = true;
            this.saveLifecycle();
          },
          false
        );
        reader.readAsText(file, "UTF-8");
        this.$refs.inputFile.$refs.input.value = null;
      }
    },
    addWorkflow() {
      let name = prompt("Please enter new workflow name", "");
      if (name) {
        this.currentLifecycle = this.createInstance("lifecycle");
        this.currentLifecycle.name = name;
        this.saveLifecycle();
      }
    },
    nextKey(collection, key) {
      let minId = collection.filter((x) => x[key] < 0).sort((a, b) => a - b);
      return minId.length ? minId[0][key] - 1 : -1;
    },
    addChild(collection, entity, instance) {
      let def = this.metadata.entities.find((x) => x.entity === entity);
      instance[def.key] = this.nextKey(collection, def.key);
      collection.push(instance);
    },
    createInstance(entity, parents) {
      let def = this.metadata.entities.find((x) => x.entity === entity);
      let instance = { isDirty: true, isNew: true, isDeleted: false };
      instance[def.key] = -1;
      def.cols.forEach((c) => {
        instance[c.name] = typeof c.default === "undefined" ? null : c.default;
      });
      if (def.collections)
        def.collections.forEach((c) => (instance[c.name] = []));
      if (parents && parents.length) {
        parents.forEach((p) => {
          const parentDef = this.metadata.entities.find(
            (x) => x.entity === p.fkEntity
          );
          const fkDef = parentDef.collections.find((x) => x.name === p.fkName);
          if (fkDef) instance[fkDef.fkColumn] = p.fkInstance[parentDef.key];
        });
      }
      return instance;
    },
    restoreWorkflowFromUpload(workflow) {
      const refData = [
        {
          entity: "permission",
          uploadedData: workflow.permissions,
          localData: this.permissions,
        },
        {
          entity: "task_type",
          uploadedData: workflow.taskTypes,
          localData: this.taskTypes,
        },
        {
          entity: "notification_type",
          uploadedData: workflow.notificationTypes,
          localData: this.notificationTypes,
        },
        {
          entity: "message_type",
          uploadedData: workflow.messageTypes,
          localData: this.messageTypes,
        },
        {
          entity: "data_capture_type",
          uploadedData: workflow.dataCaptures,
          localData: this.dataCaptures,
        },
      ];

      const updateFkRef = (entity, ref) => {
        const data = refData.find((d) => d.entity === entity);
        const key =
          this.metadata.entities.find((m) => m.entity === entity)?.key ||
          `${entity}_id`;
        if (data) {
          if (data.localData.some((l) => l[key] === ref)) return ref;
          const name = data.uploadedData.find((u) => u[key] === ref)?.name;
          const localData = data.localData.find((l) => l.name === name);
          return localData ? localData[key] : null;
        } else {
          return ref;
        }
      };

      const findLocalInstance = (def, instanceFrom, collectionTo) => {
        let found = collectionTo.find(
          (x) => x[def.key] === instanceFrom[def.key]
        );
        if (!found && def.isNamedEntity) {
          found = collectionTo.find(
            (x) => x.name === instanceFrom.name && !x.isUpload
          );
        }
        return found;
      };

      const updateInstance = (def, instanceTo, instanceFrom) => {
        instanceTo.isUpload = true;
        if (instanceFrom[def.key] === null) {
          instanceTo[def.key] = null;
          instanceTo.isDirty = false;
          instanceTo.isNew = false;
        }
        instanceTo["__copied_" + def.key] = instanceFrom[def.key];
        def.cols.forEach((c) => {
          if (instanceTo[c.name] !== instanceFrom[c.name]) {
            instanceTo.isDirty = true;
            if (c.fkEntity)
              instanceTo[c.name] = updateFkRef(
                c.fkEntity,
                instanceFrom[c.name]
              );
            else instanceTo[c.name] = instanceFrom[c.name];
          }
        });
        if (def.collections) {
          def.collections.forEach((c) => {
            const childDef = this.metadata.entities.find(
              (x) => x.entity === c.entity
            );
            instanceFrom[c.name]?.forEach((x) => {
              let localInstance = findLocalInstance(
                childDef,
                x,
                instanceTo[c.name]
              );
              if (!localInstance) {
                localInstance = this.createInstance(childDef.entity);
                instanceTo[c.name].push(localInstance);
              }
              updateInstance(childDef, localInstance, x);
            });
            instanceTo[c.name]
              ?.filter((x) => !x.isUpload)
              .forEach((x) => (x.isDeleted = true));
          });
        }

        return instanceTo;
      };

      const name = this.currentLifecycle.name;
      updateInstance(
        this.metadata.entities.find((x) => x.entity === "lifecycle"),
        this.currentLifecycle,
        workflow.lifecycle
      );
      this.currentLifecycle.name = name;
    },
    getDocumentRoles() {
      return this.currentDoc.roles.map((dr) => {
        return {
          ...dr,
          ...this.getLifeCycleRole(dr),
          ...{ user_name: this.getUser(dr.user_id).user_name },
        };
      });
    },
    getLifeCycleRole(role) {
      return this.currentLifecycle.roles.find(
        (r) => r.lifecycle_role_id === role.lifecycle_role_id
      );
    },
    getUser(id) {
      return this.users.find((r) => r.user_id === id);
    },
    getActionsAvailable(lifecycle_status_id, lifecycle_role_id) {
      let lifecycle = this.currentLifecycle;
      let statusDef = lifecycle.statuses.find(
        (x) => x.lifecycle_status_id === lifecycle_status_id
      );
      return statusDef.actionsAvailable.filter((a) => {
        return (
          (a.permittedRolesRestricted &&
            a.permittedRolesRestricted.length &&
            a.permittedRolesRestricted.some(
              (x) => x.lifecycle_role_id === lifecycle_role_id
            )) ||
          (a.permittedRolesUnrestricted &&
            a.permittedRolesUnrestricted.length &&
            a.permittedRolesUnrestricted.some(
              (x) => x.lifecycle_role_id === lifecycle_role_id
            ))
        );
      });
    },
    doAction(document, docRole, lifecycle_action_id) {
      let lifecycle = this.currentLifecycle;
      let aaDef = this.getActionsAvailable(
        document.lifecycle_status_id,
        docRole.lifecycle_role_id
      ).find((a) => a.lifecycle_action_id === lifecycle_action_id);
      let actionDef = lifecycle.actions.find(
        (x) => x.lifecycle_action_id === lifecycle_action_id
      );
      let preProcessResponse;
      if (actionDef.data_capture_type_id) {
        let dataCapture = this.dataCaptures.find(
          (x) => x.data_capture_type_id === actionDef.data_capture_type_id
        );
        if (dataCapture) {
          preProcessResponse = this[dataCapture.sub_method + "Handler"](
            "process",
            dataCapture.parm,
            document,
            docRole,
            lifecycle_action_id
          );
          if (preProcessResponse.incomplete) return;
        }
      }
      if (actionDef.lifecycle_role_id_created) {
        preProcessResponse = this.assignUserHandler(
          "process",
          actionDef.lifecycle_role_id_created,
          document,
          docRole,
          lifecycle_action_id
        );
        if (preProcessResponse.incomplete) return;
      }
      let actionLog = {
        action: actionDef.name,
        when: dayjs().format("D MMM YY : HH:mm"),
        preStatus: this.getStatusName(document.lifecycle_status_id),
        postStatus: this.getStatusName(
          aaDef.lifecycle_status_id_new || document.lifecycle_status_id
        ),
        notificationsSent: 0,
        tasksCreated: 0,
        tasksCompleted: 0,
        tasksCancelled: 0,
        statusChecks:
          preProcessResponse && preProcessResponse.statusChecks
            ? preProcessResponse.statusChecks
            : [],
      };
      if (aaDef.lifecycle_status_id_new) {
        const stat = lifecycle.statuses.find(
          (x) => x.name === aaDef.lifecycle_status_id_new
        );
        document.lifecycle_status_id = aaDef.lifecycle_status_id_new;
        document.statusColour = stat ? stat.colour : "gray";
      }
      const completeTasks = (taskList, taskType) => {
        taskList
          .filter(
            (tr) =>
              tr.task_type_id === taskType.task_type_id && tr.status == "Open"
          )
          .forEach((tr) => {
            tr.completed = dayjs().format("D MMM YY : HH:mm");
            tr.completedBy = docRole.user_name;
            tr.status = "Completed";
            actionLog.tasksCompleted++;
          });
      };
      aaDef.tasksCompleted.forEach((t) => {
        document.roles.forEach((r) => {
          completeTasks(r.tasksReceived, t);
        });
        completeTasks(this.unassignedTasks, t);
      });
      const tasksCancelled = (taskList, taskType) => {
        taskList
          .filter((tr) => tr.name === taskType && tr.status == "Open")
          .forEach((tr) => {
            tr.cancelled = dayjs().format("D MMM YY : HH:mm");
            tr.cancelledBy = docRole.user_name;
            tr.status = "Cancelled";
            actionLog.tasksCancelled++;
          });
      };
      aaDef.tasksCancelled.forEach((t) => {
        document.roles.forEach((r) => {
          tasksCancelled(r.tasksReceived, t);
        });
        tasksCancelled(this.unassignedTasks, t);
      });
      if (aaDef.tasksGenerated.length) {
        aaDef.tasksGenerated.forEach((a) => {
          let actRoles = document.roles.filter((r) =>
            a.roles.some((x) => x.lifecycle_role_id === r.lifecycle_role_id)
          );
          let affectedRoleTypes = lifecycle.roles.filter((r) =>
            a.roles.some((x) => x.lifecycle_role_id === r.lifecycle_role_id)
          );
          let users = actRoles.filter((x) =>
            affectedRoleTypes.some(
              (rt) => rt.lifecycle_role_id === x.lifecycle_role_id
            )
          );
          let task = {
            name: this.getTaskName(a),
            task_type_id: a.task_type_id,
            description:
              preProcessResponse && preProcessResponse.data
                ? preProcessResponse.data
                : "",
            status: "Open",
            created: dayjs().format("D MMM YY : HH:mm"),
            createdBy: docRole.user_name,
            due: new dayjs().add(a.due_days, "d").format("D MMM YY"),
            assignee: null,
            assigneeId: null,
            completed: null,
            completedBy: null,
            cancelled: null,
            cancelledBy: null,
            validRoles: affectedRoleTypes.map((x) => x.lifecycle_role_id),
          };
          if (users.length) {
            users.forEach((u) => {
              task.assignee = u.user_name;
              task.assigneeId = u.user_id;
              u.tasksReceived.push({ ...task });
              actionLog.tasksCreated++;
            });
          } else {
            this.unassignedTasks.push(task);
            actionLog.tasksCreated++;
          }
        });
      }
      if (aaDef.notificationsGenerated.length) {
        aaDef.notificationsGenerated.forEach((a) => {
          let actRoles = a.all_roles
            ? document.roles
            : document.roles.filter((r) =>
                a.roles.some((x) => x.lifecycle_role_id === r.lifecycle_role_id)
              );
          let affectedRoleTypes = a.all_roles
            ? lifecycle.roles
            : lifecycle.roles.filter((r) =>
                a.roles.some((x) => x.lifecycle_role_id === r.lifecycle_role_id)
              );
          let users = actRoles.filter((x) =>
            affectedRoleTypes.some(
              (rt) => rt.lifecycle_role_id === x.lifecycle_role_id
            )
          );
          let notification = {
            name: this.getNotificationame(a),
            notification_type_id: a.notification_type_id,
            description:
              preProcessResponse && preProcessResponse.data
                ? preProcessResponse.data
                : "",
            created: dayjs().format("D MMM YY : HH:mm"),
            createdBy: docRole.user_name,
          };
          let notUsers = users.filter((x) => x.user_id !== docRole.user_id);
          if (notUsers.length) {
            notUsers.forEach((u) => {
              u.notificationsReceived.push({ ...notification });
              actionLog.notificationsSent++;
            });
          } else if (!users.length) {
            this.unassignedNotifications.push(notification);
            actionLog.notificationsSent++;
          }
        });
      }
      docRole.actionsCompleted.push(actionLog);
    },
    assignUserHandler(
      func,
      lifecycle_role_id_created,
      document,
      docRole,
      action
    ) {
      switch (func) {
        case "process":
          if (!this.assignUser.show) {
            let roleDef = this.currentLifecycle.roles.find(
              (x) => x.lifecycle_role_id === lifecycle_role_id_created
            );
            this.assignUser.show = true;
            this.assignUser.user_id = null;
            this.assignUser.lifecycle_role_id = lifecycle_role_id_created;
            // this.assignUser.userList = this.users.filter((u) =>
            //   roleDef.permissions.every((p) =>
            //     u.permissions.some((up) => up.permission_id === p.permission_id)
            //   )
            // );
            this.assignUser.userList = this.users.filter((u) =>
              roleDef.permissionGroups.some((p) =>
                u.permissionGroups.some(
                  (up) => up.permission_group_id === p.permission_group_id
                )
              )
            );
            this.assignUser.caller = {
              document: document,
              role: docRole,
              action: action,
            };
            return { incomplete: true };
          } else {
            return { incomplete: false };
          }
        case "cancel":
          this.assignUser.show = false;
          this.assignUser.user_id = null;
          this.assignUser.lifecycle_role_id = null;
          break;
        case "save":
          this.currentDoc.roles.push({
            lifecycle_role_id: this.assignUser.lifecycle_role_id,
            user_id: this.assignUser.user_id,
            tasksReceived: [],
            notificationsReceived: [],
            actionsCompleted: [],
          });
          this.doAction(
            this.assignUser.caller.document,
            this.assignUser.caller.role,
            this.assignUser.caller.action
          );
          this.assignUser.show = false;
          this.assignUser.user_id = null;
          this.assignUser.lifecycle_role_id = null;
          break;
        case "assignTask":
          this.currentDoc.roles.push({
            lifecycle_role_id: this.assignUser.lifecycle_role_id,
            user_id: this.assignUser.user_id,
            tasksReceived: [this.assignUser.task],
            notificationsReceived: [],
            actionsCompleted: [],
          });
          this.unassignedTasks = this.unassignedTasks.filter(
            (x) => x.name !== this.assignUser.task.name
          );
          this.assignUser.show = false;
          this.assignUser.user_id = null;
          this.assignUser.lifecycle_role_id = null;
          this.assignUser.task = null;
          this.assignUser.saveAction = "save";
          break;
      }
    },
    addCommentHandler(func, label, document, docRole, action) {
      switch (func) {
        case "process":
          if (!this.addComment.show) {
            this.addComment.show = true;
            this.addComment.text = null;
            this.addComment.label = label || "";
            this.addComment.caller = {
              document: document,
              role: docRole,
              action: action,
            };
            return { incomplete: true };
          } else {
            return {
              incomplete: false,
              data:
                (this.addComment.label ? this.addComment.label + ": " : "") +
                this.addComment.text,
            };
          }
        case "cancel":
          this.addComment.show = false;
          this.addComment.text = null;
          break;
        case "save":
          this.currentDoc.comments.push({
            role: this.addComment.caller.role,
            text: this.addComment.text,
          });
          this.doAction(
            this.addComment.caller.document,
            this.addComment.caller.role,
            this.addComment.caller.action
          );
          this.addComment.show = false;
          break;
      }
    },
    checkListHandler(func, parm, document, docRole, action) {
      switch (func) {
        case "process":
          if (!this.checkList.show) {
            let cl = this.checkLists.find((x) => x.name === parm);
            this.checkList = { ...this.checkList, ...cl };
            this.checkList.show = true;
            this.checkList.caller = {
              document: document,
              role: docRole,
              action: action,
            };
            return { incomplete: true };
          } else {
            return {
              incomplete: false,
              statusChecks: this.checkList.checks.map((x) => {
                return { check: x.title, response: x.value };
              }),
            };
          }
        case "cancel":
          this.checkList.show = false;
          break;
        case "save":
          // save checklist values...
          this.doAction(
            this.checkList.caller.document,
            this.checkList.caller.role,
            this.checkList.caller.action
          );
          this.checkList.show = false;
          break;
      }
    },
    assignTask(task) {
      this.assignUser.task = task;
      this.assignUser.saveAction = "assignTask";
      this.assignUserHandler(
        "process",
        task.validRoles[0],
        this.currentDoc,
        null,
        null
      );
    },
    initAddDocument() {
      this.newDoc.show = true;
      this.newDoc.user_id = null;
      const newDocState = this.currentLifecycle.statuses.find(
        (x) => !x.lifecycle_status_id
      );
      let role = newDocState.actionsAvailable[0].permittedRolesUnrestricted
        .length
        ? newDocState.actionsAvailable[0].permittedRolesUnrestricted[0]
        : newDocState.actionsAvailable[0].permittedRolesRestricted[0];
      let roleDef = this.currentLifecycle.roles.find(
        (x) => x.lifecycle_role_id === role.lifecycle_role_id
      );
      //   this.newDoc.userList = this.users.filter((u) =>
      //     roleDef.permissions.every((p) =>
      //       u.permissions.some((up) => up.permission_id === p.permission_id)
      //     )
      //   );
      this.newDoc.userList = this.users.filter((u) =>
        roleDef.permissionGroups.some((p) =>
          u.permissionGroups.some(
            (up) => up.permission_group_id === p.permission_group_id
          )
        )
      );
      this.newDoc.title = "";
    },
    addDocument() {
      const stat = this.currentLifecycle.statuses.find(
        (x) => !x.lifecycle_status_id
      );
      const createAction = this.currentLifecycle.actions.find(
        (x) => x.isCreator
      );
      const action = stat.actionsAvailable.find(
        (x) => x.lifecycle_action_id === createAction.lifecycle_action_id
      );
      const newStatus = this.currentLifecycle.statuses.find(
        (x) => x.lifecycle_status_id === action.lifecycle_status_id_new
      );
      let role = action.permittedRolesUnrestricted.length
        ? action.permittedRolesUnrestricted[0]
        : action.permittedRolesRestricted[0];

      this.documents.push({
        name: this.newDoc.title,
        lifecycle_status_id: action.lifecycle_status_id_new,
        statusColour: newStatus.colour,
        comments: [],
        roles: [
          {
            lifecycle_role_id: role.lifecycle_role_id,
            user_id: this.newDoc.user_id,
            tasksReceived: [],
            notificationsReceived: [],
            actionsCompleted: [],
          },
        ],
      });
      this.currentDoc = this.documents[this.documents.length - 1];
      this.newDoc.show = false;
    },

    getActionName(action) {
      return action.name
        ? action.name
        : this.currentLifecycle.actions.find(
            (x) => x.lifecycle_action_id === action
          )?.name;
    },
    getRoleName(roleId) {
      return !roleId
        ? ""
        : this.currentLifecycle.roles.find(
            (x) => x.lifecycle_role_id === roleId
          )?.name;
    },
    getRoleNames(roles) {
      return roles
        .map((r) => {
          if (r.name) {
            return r.name;
          } else {
            let role = this.currentLifecycle.roles.find(
              (x) => x.lifecycle_role_id === (r.lifecycle_role_id || r)
            );
            return role ? role.name : "UNKNOWN!!!";
          }
        })
        .join(" | ");
    },
    getRoleAbbr(role) {
      return role.abbreviation
        ? role.abbreviation
        : this.currentLifecycle.roles.find(
            (x) => x.lifecycle_role_id === (role.lifecycle_role_id || role)
          ).abbreviation;
    },
    getStatus(id) {
      return this.currentLifecycle.statuses.find(
        (x) => x.lifecycle_status_id === id
      );
    },
    getStatusName(id) {
      const status = this.getStatus(id);
      return status ? status.name : null;
    },
    getStatusColour(id) {
      const status = this.getStatus(id);
      return status ? status.colour : null;
    },
    getTaskName(task) {
      return task.name
        ? task.name
        : this.taskTypes.find((x) => x.task_type_id === task.task_type_id)
            ?.name;
    },
    getTaskNames(tasks) {
      return tasks
        ?.map((t) =>
          t.name
            ? t.name
            : this.taskTypes.find((x) => x.task_type_id === t.task_type_id)
                ?.name
        )
        .join(" | ");
    },
    getNotificationame(item) {
      return item.name
        ? item.name
        : this.notificationTypes.find(
            (x) => x.notification_type_id === item.notification_type_id
          )?.name;
    },
    getMessageName(item) {
      return item.name
        ? item.name
        : this.messageTypes.find(
            (x) => x.message_type_id === item.message_type_id
          )?.name;
    },
    getDataCaptureName(item) {
      return !item.data_capture_type_id
        ? ""
        : this.dataCaptures.find(
            (x) => x.data_capture_type_id === item.data_capture_type_id
          )?.name;
    },
    isDataCaptureReadOnly(item) {
      return !item.data_capture_type_id
        ? true
        : this.dataCaptures.find(
            (x) => x.data_capture_type_id === item.data_capture_type_id
          )?.isDataCaptureViewer;
    },
    getMenuActionName(item) {
      return !item.action_menu_id
        ? ""
        : this.actionMenus.find((x) => x.action_menu_id === item.action_menu_id)
            ?.name;
    },
    getStatusAction(status, action) {
      const aDef = status.actionsAvailable.find((x) =>
        x.name
          ? x.action === action.name
          : x.lifecycle_action_id === action.lifecycle_action_id
      );
      return aDef ? [aDef] : [false];
    },
    showConfig(config, actionAvailable) {
      config.show = true;
      config.status = config.parents.find(
        (x) => x.fkEntity === "lifecycle_status"
      ).fkInstance;
      config.action = config.parents.find(
        (x) => x.fkEntity === "lifecycle_action"
      ).fkInstance;
      config.ruleUpdateStatus = !!actionAvailable.lifecycle_status_id_new;
      config.lifecycle_status_id_new = actionAvailable.lifecycle_status_id_new;
      config.ruleAddRole = !!config.action.lifecycle_role_id_created;
      config.ruleTriggerTask = actionAvailable.tasksGenerated.length > 0;
      config.ruleTriggerNotification =
        actionAvailable.notificationsGenerated.length > 0;
      config.ruleTriggerMessage = actionAvailable.messagesGenerated.length > 0;
      config.ruleCompleteTasks = actionAvailable.tasksCompleted.length > 0;
      config.ruleCancelTasks = actionAvailable.tasksCancelled.length > 0;
      config.ruleUseRequiredData = !!config.action.data_capture_type_id;
      config.ruleDataCaptureTriggeredACtion =
        !!config.action.data_capture_type_id_triggered_from;
    },
    configStatusActionHandler(handlerAction, subject, status, action) {
      let removeChild = (subject, collection, key) => {
        subject.isDeleted = true;
        if (subject.isNew) {
          let index = collection.findIndex((x) => x[key] === subject[key]);
          collection.splice(index, 1);
        }
      };
      switch (handlerAction) {
        case "removeTaskGenerated":
          removeChild(
            subject,
            this.configStatusAction.cache.tasksGenerated,
            "task_type_generated_id"
          );
          return;
        case "removeNotificationGenerated":
          removeChild(
            subject,
            this.configStatusAction.cache.notificationsGenerated,
            "notification_type_generated_id"
          );
          return;
        case "removeMessageGenerated":
          removeChild(
            subject,
            this.configStatusAction.cache.messagesGenerated,
            "message_type_generated_id"
          );
          return;
        case "removeQuestion":
          removeChild(
            subject,
            this.configAction.cache.questionDestinations,
            "lifecycle_action_question_destination_id"
          );
          return;
      }
      const parents = [
        {
          fkEntity: "lifecycle_status",
          fkName: "actionsAvailable",
          fkInstance: status,
          primary: true,
        },
        {
          fkEntity: "lifecycle_action",
          fkName: "usedStatuses",
          fkInstance: action,
        },
      ];
      this.configHandler(
        handlerAction,
        this.configStatusAction,
        "action_available",
        subject,
        parents,
        this.showConfig
      );
    },
    configActionHandler(handlerAction, instance) {
      let prepEdit = (config, subject) => {
        config.ruleAddRole = !!subject.lifecycle_role_id_created;
        config.ruleUseRequiredData = !!subject.data_capture_type_id;
        config.ruleDataCaptureTriggeredACtion =
          !!subject.data_capture_type_id_triggered_from;
        config.ruleClipboard = !!subject.clipboard_format;
        config.ruleDownload = !!subject.download_format;
        this.dcSetVal();
        this.rtSetVal();
      };
      const parents = [
        {
          fkEntity: "lifecycle",
          fkName: "actions",
          fkInstance: this.currentLifecycle,
        },
      ];
      this.configHandler(
        handlerAction,
        this.configAction,
        "lifecycle_action",
        instance,
        parents,
        prepEdit
      );
    },
    configStatusHandler(handlerAction, instance) {
      const parents = [
        {
          fkEntity: "lifecycle",
          fkName: "statuses",
          fkInstance: this.currentLifecycle,
        },
      ];
      this.configHandler(
        handlerAction,
        this.configStatus,
        "lifecycle_status",
        instance,
        parents
      );
    },
    configRoleHandler(handlerAction, instance) {
      const parents = [
        {
          fkEntity: "lifecycle",
          fkName: "roles",
          fkInstance: this.currentLifecycle,
        },
      ];
      this.configHandler(
        handlerAction,
        this.configRole,
        "lifecycle_role",
        instance,
        parents
      );
    },
    configHandler(
      handlerAction,
      config,
      entityName,
      instance,
      parents,
      prepEditFunc
    ) {
      const entityDef = this.metadata.entities.find(
        (x) => x.entity === entityName
      );
      switch (handlerAction) {
        case "open":
          this.configPrep(config, entityDef, instance, false);
          config.parents = parents;
          if (prepEditFunc) prepEditFunc(config, instance);
          break;
        case "new":
          instance = this.createInstance(entityDef.entity, parents);
          this.configPrep(config, entityDef, instance, true);
          config.parents = parents;
          if (prepEditFunc) prepEditFunc(config, instance);
          break;
        case "save":
          this.configSave(config, entityDef);
          this.saveLifecycle();
          break;
        case "cancel":
          this.resetDialogue(config);
          break;
        case "delete":
          config.instance.isDirty = true;
          config.instance.isDeleted = true;
          this.saveLifecycle();
          this.resetDialogue(config);
          break;
      }
    },
    saveInstance(instance, cache, entityDef, forceLinkEntityUpdate) {
      entityDef.cols.forEach((c) => {
        if (instance[c.name] !== cache[c.name]) instance.isDirty = true;
        instance[c.name] = cache[c.name] === false ? 0 : cache[c.name];
      });
      entityDef.collections.forEach((x) => {
        let childEntity = this.metadata.entities.find(
          (d) => d.entity === x.entity
        );
        if (x.isLinkEntity) {
          let defLinks = childEntity.cols.filter(
            (l) => l.fkEntity && l.fkEntity !== entityDef.entity
          );
          defLinks.forEach((dl) => {
            if (forceLinkEntityUpdate) instance[x.name] = [];
            cache[x.name + "_" + dl.fkEntity].forEach((l) => {
              if (!instance[x.name].some((s) => s[dl.name] === l)) {
                let newIstance = this.createInstance(x.entity);
                newIstance[x.fkColumn] = instance[entityDef.key];
                newIstance[dl.name] = l;
                this.addChild(instance[x.name], x.entity, newIstance);
                if (x.columnDefaults && x.columnDefaults.length) {
                  x.columnDefaults.forEach(
                    (cd) => (newIstance[cd.name] = cd.value)
                  );
                }
                this.isDirty = true;
              }
            });
            instance[x.name].forEach((v) => {
              v.isDeleted = false;
              if (
                !cache[x.name + "_" + dl.fkEntity].some((l) => l === v[dl.name])
              ) {
                v.isDeleted = true;
                v.isDirty = true;
              }
            });
          });
        } else {
          cache[x.name].forEach((ci) => {
            let orig = instance[x.name].find(
              (i) => i[childEntity.key] === ci[childEntity.key]
            );
            if (orig) {
              this.saveInstance(orig, ci, childEntity);
              if (ci.isDeleted) orig.isDeleted = true;
            } else {
              if (x.columnDefaults && x.columnDefaults.length) {
                x.columnDefaults.forEach((cd) => (ci[cd.name] = cd.value));
              }
              this.saveInstance(ci, ci, childEntity, true);
              instance[x.name].push(ci);
            }
          });
        }
      });
      if (instance.isDirty) this.isDirty = true;
    },
    configSave(dialogue, entityDef) {
      this.saveInstance(dialogue.instance, dialogue.cache, entityDef);
      if (dialogue.isNew && dialogue.parents?.length) {
        this.addChild(
          dialogue.parents[0].fkInstance[dialogue.parents[0].fkName],
          entityDef.entity,
          dialogue.instance
        );
        this.isDirty = true;
      }
      this.resetDialogue(dialogue);
    },
    getNewCache() {
      return { isDirty: false, isDeleted: false };
    },
    configPrep(dialogue, entityDef, subject, isNew) {
      dialogue.show = true;
      dialogue.instance = subject;
      dialogue.editName = false;
      let cache = this.getNewCache();
      this.prepInstanceCache(cache, entityDef, subject);
      if (isNew) {
        dialogue.isNew = true;
        dialogue.editName = true;
      }
      dialogue.cache = cache;
    },
    prepInstanceCache(cache, entityDef, instance) {
      cache[entityDef.key] = instance ? instance[entityDef.key] : null;
      if (instance && instance.isDirty) cache.isDirty = true;
      if (instance && instance.isDeleted) cache.isDeleted = true;
      entityDef.cols.forEach((c) => {
        cache[c.name] = instance ? instance[c.name] : null;
        if (c.domain) {
          cache[`${c.name}s`] = c.domain;
        }
      });
      entityDef.collections.forEach((x) => {
        let childEntity = this.metadata.entities.find(
          (d) => d.entity === x.entity
        );

        cache[x.name] = [];
        if (instance && instance[x.name]) {
          instance[x.name].forEach((i) => {
            let child = this.getNewCache();
            this.prepInstanceCache(child, childEntity, i);
            cache[x.name].push(child);
          });
        }
        if (x.isLinkEntity) {
          // used for multivalue selects
          let defLinks = childEntity.cols.filter(
            (l) => l.fkEntity && l.fkEntity !== entityDef.entity
          );
          defLinks.forEach((dl) => {
            cache[x.name + "_" + dl.fkEntity] = cache[x.name]
              .filter((e) => !e.isDeleted)
              .map((row) => row[dl.name]);
          });
        }
      });
    },
    resetDialogue(dialogue) {
      dialogue.show = false;
      dialogue.instance = null;
      dialogue.cache = null;
      dialogue.isNew = false;
    },
    pickerHandler(pickerAction, type) {
      switch (pickerAction) {
        case "init":
          if (type === "TASK") {
            this.configStatusAction.picker.list = this.taskTypes;
            this.configStatusAction.picker.itemText = "name";
            this.configStatusAction.picker.itemValue = "task_type_id";
          } else if (type === "NOTIFICATION") {
            this.configStatusAction.picker.list = this.notificationTypes;
            this.configStatusAction.picker.itemText = "name";
            this.configStatusAction.picker.itemValue = "notification_type_id";
          } else if (type === "MESSAGE") {
            this.configStatusAction.picker.list = this.messageTypes;
            this.configStatusAction.picker.itemText = "name";
            this.configStatusAction.picker.itemValue = "message_type_id";
          } else if (type === "QUESTION") {
            this.configStatusAction.picker.list =
              this.questionnaireQuestions.filter(
                (x) =>
                  x.data_capture_type_id ===
                  this.configAction.cache.data_capture_type_id
              );
            this.configStatusAction.picker.itemText = "question_number";
            this.configStatusAction.picker.itemValue =
              "questionnaire_question_id";
          }
          this.configStatusAction.picker.show = true;
          this.configStatusAction.picker.type = type;
          this.configStatusAction.picker.key = null;
          break;
        case "cancel":
          this.configStatusAction.picker.show = false;
          break;
        case "save":
          if (this.configStatusAction.picker.type === "TASK") {
            const parents = [
              {
                fkEntity: "action_available",
                fkName: "tasksGenerated",
                fkInstance: this.configStatusAction.instance,
              },
            ];
            let instance = this.createInstance("task_type_generated", parents);
            instance.task_type_id = this.configStatusAction.picker.key;
            let def = this.metadata.entities.find(
              (x) => x.entity === "task_type_generated"
            );
            this.prepInstanceCache(instance, def, instance);
            this.addChild(
              this.configStatusAction.cache.tasksGenerated,
              "task_type_generated",
              instance
            );
          } else if (this.configStatusAction.picker.type === "NOTIFICATION") {
            const parents = [
              {
                fkEntity: "action_available",
                fkName: "notificationsGenerated",
                fkInstance: this.configStatusAction.instance,
              },
            ];
            let instance = this.createInstance(
              "notification_type_generated",
              parents
            );
            instance.notification_type_id = this.configStatusAction.picker.key;
            let def = this.metadata.entities.find(
              (x) => x.entity === "notification_type_generated"
            );
            this.prepInstanceCache(instance, def, instance);
            this.addChild(
              this.configStatusAction.cache.notificationsGenerated,
              "notification_type_generated",
              instance
            );
          } else if (this.configStatusAction.picker.type === "MESSAGE") {
            const parents = [
              {
                fkEntity: "action_available",
                fkName: "messagesGenerated",
                fkInstance: this.configStatusAction.instance,
              },
            ];
            let instance = this.createInstance(
              "message_type_generated",
              parents
            );
            instance.message_type_id = this.configStatusAction.picker.key;
            let def = this.metadata.entities.find(
              (x) => x.entity === "message_type_generated"
            );
            this.prepInstanceCache(instance, def, instance);
            this.addChild(
              this.configStatusAction.cache.messagesGenerated,
              "message_type_generated",
              instance
            );
          } else if (this.configStatusAction.picker.type === "QUESTION") {
            const parents = [
              {
                fkEntity: "lifecycle_action",
                fkName: "questionDestinations",
                fkInstance: this.configAction.instance,
              },
            ];
            let instance = this.createInstance(
              "lifecycle_action_question_destination",
              parents
            );
            instance.questionnaire_question_id =
              this.configStatusAction.picker.key;
            let def = this.metadata.entities.find(
              (x) => x.entity === "lifecycle_action_question_destination"
            );
            this.prepInstanceCache(instance, def, instance);
            this.addChild(
              this.configAction.cache.questionDestinations,
              "lifecycle_action_question_destination",
              instance
            );
          }
          this.configStatusAction.picker.show = false;
          break;
      }
    },
    togglePIG(pig) {
      let possibleError = false;

      axios
        .post("workflow/togglePermissionInGroup/", pig)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            pig.permission_in_group_id = resp.data.Data.permission_in_group_id;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    toggleUPG(upg) {
      let possibleError = false;

      axios
        .post("workflow/toggleUserPermissionGroup/", upg)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            upg.user_permission_group_id =
              resp.data.Data.user_permission_group_id;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    configGeneralHandler(handlerAction, instance, type) {
      let types = [
        {
          entity: "permission_group",
          keycol: "permission_group_id",
          textcol: "name",
          title: "Permission Group",
          label: "Name",
          cols: [
            { name: "position", label: "Position", type: "number", default: "0" },
            { name: "upt_id", label: "Legacy Profile Type" },
            { name: "internal", label: "Internal (hide from customer)", type: "boolean", default: 0 },
          ],
        },
        {
          entity: "permission",
          keycol: "permission_id",
          textcol: "name",
          title: "Permission",
          label: "Name",
        },
      ];
      let entity = null;
      let possibleError = false;
      switch (handlerAction) {
        case "new":
        case "open":
          entity = types.find((t) => t.entity === type);
          if (!entity) return;
          if (!instance) {
            instance = {};
            instance[type.keycol] = null;
            instance[type.textcol] = "";
            if (entity.cols) {
              entity.cols.forEach(t => {
                instance[t.name] = t.default === undefined ? null : t.default;
              })
            }
          }
          this.configGeneral.show = true;
          this.configGeneral.isNew = handlerAction === "new";
          this.configGeneral.keyColumn = entity.keycol;
          this.configGeneral.keyValue = instance[entity.keycol];
          this.configGeneral.title = entity.title;
          this.configGeneral.label = entity.label;
          this.configGeneral.textColumn = entity.textcol;
          this.configGeneral.textValue = instance[entity.textcol];
          this.configGeneral.item = instance;
          this.configGeneral.type = entity;
          break;
        case "save":
        case "delete":
          if (handlerAction === "delete") {
            this.configGeneral.item.isDeleted = true;
          } else {
            this.configGeneral.item[this.configGeneral.textColumn] =
              this.configGeneral.textValue;
          }
          axios
            .post(
              "workflow/save" + this.configGeneral.type.entity + "/",
              this.configGeneral.item
            )
            .then((resp) => {
              possibleError = true;
              if (resp.data.Status === "OK") {
                if (resp.data.Data && resp.data.Data.permissionGroups) {
                  this.permissionGroups = resp.data.Data.permissionGroups;
                  this.permissions = resp.data.Data.permissions;
                  this.users = resp.data.Data.users;
                  switch (this.configGeneral.type.entity) {
                    case "permission":
                      this.filterPermissions();
                      break;
                    case "user":
                    default:
                      this.filterUsers();
                      break;
                  }
                }
              }
              this.configGeneral.show = false;
              this.response = resp.data;
              //console.log(resp);
            })
            .catch((err) => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.configGeneral.show = false;
            });
          break;
        case "cancel":
          this.configGeneral.show = false;
          break;
      }
    },
    configTemplateLifecycleHandler(handlerAction, template, lifecycle) {
      let possibleError = false;
      switch (handlerAction) {
        case "new":
        case "open":
          this.configTemplateLifecycle.show = true;
          this.configTemplateLifecycle.isNew = handlerAction === "new";
          this.configTemplateLifecycle.lifecycle_selection_text =
            template.lifecycle_selection_text;
          this.configTemplateLifecycle.ht_id_empty_trigger =
            template.ht_id_empty_trigger;
          if (!template.template_lifecycle_id) {
            this.configTemplateLifecycle.isDefault = !this.lifecycles.some(
              (l) =>
                !l.templates.some(
                  (t) =>
                    t.tmpl_id === template.tmpl_id &&
                    t.template_lifecycle_id !== null
                )
            );
          } else {
            this.configTemplateLifecycle.isDefault = template.isDefault;
          }
          this.configTemplateLifecycle.title = `${template.tmpl_name} Template - ${lifecycle.name} Workflow`;
          this.configTemplateLifecycle.item = template;
          break;
        case "save":
        case "delete":
          if (handlerAction === "delete") {
            this.configTemplateLifecycle.item.isDeleted = true;
          } else {
            this.configTemplateLifecycle.item.isDefault = this
              .configTemplateLifecycle.isDefault
              ? 1
              : 0;
            this.configTemplateLifecycle.item.lifecycle_selection_text =
              this.configTemplateLifecycle.lifecycle_selection_text;
            this.configTemplateLifecycle.item.ht_id_empty_trigger =
              this.configTemplateLifecycle.ht_id_empty_trigger;
          }
          axios
            .post(
              "workflow/saveTemplateLifecycle/",
              this.configTemplateLifecycle.item
            )
            .then((resp) => {
              possibleError = true;
              if (resp.data.Status === "OK") {
                this.configTemplateLifecycle.item.template_lifecycle_id =
                  resp.data.Data.template_lifecycle_id;
                this.configTemplateLifecycle.item.isDeleted = false;
              }
              this.configTemplateLifecycle.show = false;
              this.response = resp.data;
              //console.log(resp);
            })
            .catch((err) => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.configGeneral.show = false;
            });
          break;
        case "cancel":
          this.configTemplateLifecycle.show = false;
          break;
      }
    },
    configMessageColumnVisible(column) {
      switch (column) {
        case "email_subject":
        case "email_to":
        case "email_from":
        case "email_template_id":
          return this.configMessage.method === "EMAIL";
        case "destination_url":
        case "data_template":
          return ["POST", "GET", "REDIRECT"].some(
            (x) => x === this.configMessage.method
          );
        case "destination_authentication":
        case "destination_auth_token":
          return ["POST", "GET"].some((x) => x === this.configMessage.method);
      }
    },
    configTypeHandler(handlerAction, subject, config, instances) {
      let possibleError = false;
      let def = this.metadata.entities.find((x) => x.entity === config.entity);
      let deletedKey = null;
      let data = null;
      switch (handlerAction) {
        case "new":
        case "open":
          config.show = true;
          config.isDirty = false;
          config.isNew = handlerAction === "new";
          this.prepInstanceCache(config, def, subject);
          config.title = subject
            ? `Edit ${config.entityLabel}: ${subject.name}`
            : `Add New ${config.entityLabel}`;
          config.item = subject ? subject : { ...config };
          break;
        case "save":
        case "delete":
          if (handlerAction === "delete") {
            config.item.isDeleted = true;
            deletedKey = config.item[def.key];
          } else {
            this.prepInstanceCache(config.item, def, config);
          }
          data = { ...config.item };
          delete data.instances;
          axios
            .post(config.saveRoute, data)
            .then((resp) => {
              possibleError = true;
              if (resp.data.Status === "OK") {
                config.item[def.key] = resp.data.Data[def.key];
                if (deletedKey) {
                  instances.splice(
                    instances.findIndex((x) => x[def.key] === deletedKey),
                    1
                  );
                } else if (config.isNew) {
                  instances.push({ ...config.item });
                }
                config.item = null;
                config.isDirty = false;
              }
              config.show = false;
              this.response = resp.data;
              //console.log(resp);
            })
            .catch((err) => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              config.show = false;
            });
          break;
        case "cancel":
          config.show = false;
          break;
      }
    },
    configMessageHandler(handlerAction, message) {
      this.configTypeHandler(
        handlerAction,
        message,
        this.configMessage,
        this.messageTypes
      );
    },
    configTaskNotHandler(handlerAction, instance, type) {
      if (type) {
        let parts = type.split("_");
        let list =
          parts
            .map((x, i) =>
              i === 0 ? x : x.substr(0, 1).toUpperCase() + x.substr(1)
            )
            .join("") + "s";
        this.configTaskNot.entity = type;
        this.configTaskNot.entityLabel = parts
          .map((x) => x.substr(0, 1).toUpperCase() + x.substr(1))
          .join(" ");
        this.configTaskNot.saveRoute = `workflow/save${parts.join("")}/`;
        this.configTaskNot.instances = this[list];
      }
      this.configTypeHandler(
        handlerAction,
        instance,
        this.configTaskNot,
        this.configTaskNot.instances
      );
    },
    configEmailTemplateHandler(handlerAction, message) {
      if (handlerAction === "save") {
        if (this.configEmailTemplate.isNew)
          this.configEmailTemplate.title = this.configEmailTemplate.name;
        // unescape ejs tags
        this.configEmailTemplate.body = this.configEmailTemplate.body
          .replace(/&lt;%=/g, "<%=")
          .replace(/%&gt;/g, "%>")
          .replace(/&nbsp;/g, " ");
      }
      this.configTypeHandler(
        handlerAction,
        message,
        this.configEmailTemplate,
        this.emailTemplates,
        "workflow/saveEmailTemplate/"
      );
    },
    doFilter(
      source,
      sourceProperty,
      destination,
      pgListSource,
      pgInstanceSource
    ) {
      let raw = JSON.parse(JSON.stringify(source));
      let filterWords = (this[destination].filter || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      let result = raw;
      if (filterWords.length) {
        result = result.filter((x) =>
          filterWords.every(
            (w) => x[sourceProperty].toLowerCase().indexOf(w) >= 0
          )
        );
      }
      if (this[destination].pgFilter.length) {
        result = result.filter((x) =>
          this[destination].pgFilter.every((pg) =>
            x[pgListSource].some(
              (p) => p.permission_group_id === pg && p[pgInstanceSource]
            )
          )
        );
      }
      this[destination].filtered = result;
      // set up paging
      this[destination].filtered.forEach((u, ui) => {
        u.page = parseInt(ui / this[destination].pageSize) + 1;
      });
      this[destination].page = 1;
      this[destination].pages =
        parseInt(
          this[destination].filtered.length / this[destination].pageSize
        ) + 1;
    },
    filterUsers() {
      this.doFilter(
        this.users,
        "user_name",
        "userSetUp",
        "pglist",
        "user_permission_group_id"
      );
    },
    filterPermissions() {
      this.doFilter(
        this.permissions,
        "name",
        "permissionSetUp",
        "permissionGroups",
        "permission_in_group_id"
      );
    },
    filterPG(pg, destination) {
      let pgi = this[destination].pgFilter.findIndex(
        (x) => x === pg.permission_group_id
      );
      if (pgi >= 0) {
        this[destination].pgFilter.splice(pgi, 1);
      } else {
        this[destination].pgFilter.push(pg.permission_group_id);
      }
      if (destination === "userSetUp") this.filterUsers();
      if (destination === "permissionSetUp") this.filterPermissions();
    },
    editUser(user) {
      this.currentUser.user_id = user ? user.user_id : null;
      this.currentUser.show = true;
    },
    userSaved(user) {
      let cachedUser = this.users.find((x) => x.user_id === user.user_id);
      if (cachedUser) {
        cachedUser.user_name = user.name;
        this.closeUserDialogue();
      } else {
        user.user_name = user.name;
        user.pglist = this.users[0].pglist.map((pg) => {
          return {
            permission_group_id: pg.permission_group_id,
            user_name: user.user_name,
            user_id: user.user_id,
            permission_group_name: pg.permission_group_name,
            user_permission_group_id: null,
          };
        });
        let ins = this.users.findIndex(
          (x) => x.user_name.toLowerCase() > user.user_name.toLowerCase()
        );
        this.users.splice(ins, 0, user);
      }
    },
    userDeactivated(user) {
      let cachedUser = this.users.findIndex((x) => x.user_id === user.user_id);
      if (cachedUser >= 0) {
        this.users.splice(cachedUser, 1);
      }
      this.closeUserDialogue();
      //   this.doFilter();
    },
    closeUserDialogue() {
      this.currentUser.user_id = null;
      this.currentUser.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.status-chip {
  font-size: 0.7rem;
  margin-left: -8px;
  height: 25px;
}
th.vertical-text {
  writing-mode: vertical-rl;
  height: 150px !important;
}

.document-editor {
  border: 1px solid lightgray;
}
</style>