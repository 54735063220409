<template>
  <div>
    <v-tabs v-model="tab" class="primary-background">
      <v-tab v-for="item in tabItems" :key="item.name">
        {{ item.name }}
      </v-tab>
    </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabItems" :key="item.name">
          <v-card v-if="item.name === 'Users'" flat> 
              <UserAdmin :qaActions="qaActions" flat />
          </v-card>
          <v-card v-if="item.name === 'Text Check'" flat> 
              <AdminText/>
          </v-card>
          <v-card v-if="item.name === 'Hierarchy'" flat> 
              <AdminHierarchy />
          </v-card>     
          <v-card v-if="item.name === 'Settings'" flat> 
              <SettingsAdmin />
          </v-card> 
          <v-card v-if="item.name === 'System' && !isDebugMode" flat> 
              <span>Feature only available in developer mode</span>
          </v-card>
          <v-card v-if="item.name === 'System' && isDebugMode" flat> 
              <AdminSystem />
          </v-card>
          </v-tab-item>      
    </v-tabs-items>
    

  </div>
  

</template>

<script>
import AdminText from './cAdminText.vue';
import AdminHierarchy from '../hierarchy/cAdminHierarchy.vue';
import UserAdmin from '../cUserList.vue';
import SettingsAdmin from './cAdminSettings.vue';
import AdminSystem from "./cAdminSystem";

export default {
  name: 'adminHolder',
  props: {
    qaActions: { type: Array },
  },
  data: function() {
    return {
        tab: 0,
        tabItems: [
            {name:'Users', text: ''},
            {name:'Text Check', text: 'Usage'},
            {name:'Hierarchy', text: ''},
            {name:'Settings', text: ''},
            {name:'System', text: ''},
        ],
    }
  },
  components: {
    AdminText, UserAdmin, AdminHierarchy, SettingsAdmin, AdminSystem
  },
  computed: {
    isDebugMode() {
      const hash = window.location.hash.split('?');
      const query = hash.length > 1 ? hash[1] : "";
      const params = new Proxy(new URLSearchParams(query), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      return window.location.host.indexOf('localhost') >= 0 || window.location.host.indexOf(process.env.VUE_APP_LOCAL_HOST) >= 0 || params.debug;
    },
  },
  methods: { }
}
</script>