<template>
  <div>
    <treeselect
      v-if="!addLeafNode.show"
      :value="pickerValue"
      :clearable="clearable"
      :disabled="readonly"
      :show-count="false"
      :disableBranchNodes="!fulldetail"
      :options="localOptions"
      :placeholder="placeholder"
      :label="label"
      :searchNested="true"
      :startSearchLength="5"
      :waitSearchFinishTime="500"
      :disableFuzzyMatching="true"
      :multiple="multiple"
      :class="{
        dense: dense,
        'has-focus': hasFocus,
        'validation-error': error,
        'borderless': borderless
      }"
      @input="updateValue($event)"
      @open="hasFocus = true"
      @close="lostFocus"
      :autoFocus="autofocus"
      :openOnFocus="autofocus"
      appendToBody
    >
      <label
        slot="option-label"
        slot-scope="{
          node,
          shouldShowCount,
          count,
          labelClassName,
          countClassName,
        }"
        :class="labelClassName"
        :title="node.label"
      >
        <v-icon v-if="!node.isBranch && node.raw.isAddNode"
          >mdi-plus-circle</v-icon
        >
        {{ node.raw.isAddNode ? "Add new title" : node.label }}
        <span v-if="shouldShowCount" :class="countClassName"
          >({{ count }})</span
        >
      </label>
    </treeselect>
    <div v-show="error" class="vue-treeselect-hint">
      {{ hint }}
    </div>
    <div v-if="addLeafNode.show" class="hierarchypicker-container">
      <div class="hierarchypicker-container-heading">
        {{ hierarchyType.label }} - {{ addLeafNode.label }}
      </div>
      <div
        v-for="(p, pi) in addLeafNode.parents"
        :key="'p' + pi"
        :style="{
          marginLeft: pi * 10 + '%',
          width: 100 - addLeafNode.parents.length * 10 + '%',
        }"
        class="hierarchypicker-hierarchy-level"
        :title="p"
      >
        {{ p }}
      </div>
      <div
        :style="{
          marginLeft: addLeafNode.parents.length * 10 + '%',
          width: 100 - addLeafNode.parents.length * 10 + '%',
        }"
      >
        <v-text-field
          outlined
          hide-details
          dense
          :placeholder="addLeafNode.label"
          v-model="addLeafNode.value"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelAddLeafNode()"
          @change="updateValueAppend"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "HierarchyTreePicker",
  components: {
    Treeselect,
  },
  props: {
    value: { },
    placeholder: { type: String },
    label: { type: String },
    hint: { type: String },
    dense: { type: Boolean },
    error: { type: Boolean },
    options: [],
    fulldetail: { type: Boolean },
    textValue: { type: String },
    readonly: { type: Boolean },
    clearable: { type: Boolean },
    hierarchyType: { type: Object },
    init: { type: Boolean },
    multiple: { type: Boolean },
    autofocus: { type: Boolean },
    borderless: { type: Boolean }
  },
  data: function () {
    return {
      pickerValue: this.value,
      originalValue: this.value,
      hasFocus: false,
      localOptions: [],
      initialised: false,
      addLeafNode: {
        show: false,
        label: null,
        parents: [],
        value: null,
      },
    };
  },
  created() {
    this.localOptions = this.options;
    this.initialiseAddNode();
  },
  updated() {
    if (!this.hierarchyType || !this.hierarchyType.allowAddLeafNode) {
      this.addLeafNode.show = false;
      this.addLeafNode.value = null;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.initialised) {
          this.pickerValue = val;
        }
      },
    },
    options(val) {
      this.localOptions = val;
    },
    "hierarchyType.allowAddLeafNode"() {
      this.initialiseAddNode();
    },
    init() {
      this.initialiseAddNode();
    }
  },
  methods: {
    updateValue(value) {
      let newValue = value;
      if (this.hierarchyType.allowAddLeafNode) {
        this.addLeafNode.show = false;
        if (value < 0) {
          this.addLeafNode.show = true;
          let selNode = this.getNode("id", value);
          this.addLeafNode.parents = selNode?.parents || [];
          this.addLeafNode.label = `New ${
            this.hierarchyType["level" + this.hierarchyType.linklevel + "_name"]
          }`;
          newValue = this.originalValue;
        }
      }
      if (this.fulldetail) {
        const selNodeIds = [];
        (this.multiple ? newValue : [ newValue ]).forEach(v => {
          const selNode = this.getTreeNode("id", v);
          if (selNode && selNode.node_id)
            selNodeIds.push(selNode.node_id)
        });
        this.$emit("changeNodeIds", selNodeIds);
      }
      
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    },
    checkForUnclassified(val) {
      let checkExists = function (options, id) {
        let exists = false;
        options.forEach((x) => {
          if (!exists) {
            if (x.children) {
              exists = checkExists(x.children, id);
            } else {
              exists = x.id === id;
            }
          }
        });
        return exists;
      };
      let insertUnclassified = function (options, v) {
        if (v && !checkExists(options, v)) {
          options.push({
            id: `Unclassified${v}`,
            label: "Unclassified",
            children: [
              { id: v, label: `${this.textValue || v} [Unclassified]` },
            ],
          });
        }
      }
      if (Array.isArray(val)) {
        val.forEach(v => insertUnclassified(this.localOptions, v));
      } else {
        insertUnclassified(this.localOptions, val);
      }
    },
    initialiseAddNode(checkPrevious) {
      if (checkPrevious && this.initialised) return;
      this.addLeafNode.show = false;
      this.addLeafNode.value = null;
      let hasAdd = !!this.getNode("isAddNode", true);
      if (this.hierarchyType.allowAddLeafNode && !hasAdd) {
        let count = 0;
        let addNode = (nodes, parent) => {
          let isEnd = false;
          nodes.forEach((n) => {
            if (n.children) {
              addNode(n.children, n);
            } else {
              isEnd = true;
            }
          });
          if (isEnd) {
            count--;
            nodes.unshift({
              id: count,
              label: " " + parent?.label,
              isAddNode: true,
            });
          }
        };
        addNode(this.localOptions);
      } else if (hasAdd) {
        let remAdd = (nodes) => {
          let index = nodes.findIndex((x) => x.isAddNode);
          if (index >= 0) {
            nodes.splice(index, 1);
          }
          nodes.forEach((n) => {
            if (n.children) {
              remAdd(n.children);
            }
          });
        };
        remAdd(this.localOptions);
      }
      if (!this.multiple)
        this.checkForUnclassified(this.pickerValue);
      this.initialised = true;
    },
    updateValueAppend() {
      let value = {
        ht_id: this.hierarchyType.ht_id,
        ht_name: this.hierarchyType.label.replace("Select ", ""),
        hr_id: this.addLeafNode.value,
        isNew: true,
        wasMissing: false,
      };
      [1, 2, 3, 4, 5, 6].forEach((n) => {
        value["level" + n] = null;
        if (this.addLeafNode.show && this.addLeafNode.value) {
          value.isNew = true;
          value["level" + n] = this.addLeafNode.parents[n - 1];
          if (n === this.addLeafNode.parents.length + 1) {
            value["level" + n] = this.addLeafNode.value;
            value.hr_id = this.addLeafNode.value;
          }
        }
      });
      this.$emit("input", null, value);
      this.$emit("change", null, value);
    },
    getNode(property, value) {
      let _getNode = function (options, property, value) {
        let exists = null;
        options.forEach((x) => {
          if (!exists) {
            if (x.children) {
              exists = _getNode(x.children, property, value);
              if (exists) exists.parents.unshift(x.name);
            } else {
              exists = x[property] === value ? x : null;
              if (exists) exists.parents = [];
            }
          } else {
            return;
          }
        });
        return exists;
      };
      return value ? _getNode(this.localOptions, property, value) : null;
    },
    getTreeNode(property, value) {
      let _getNode = function (options, property, value) {
        let exists = null;
        options.forEach((x) => {
          if (!exists) {
            exists = x[property] === value ? x : null;
            if (exists) exists.parents = [];
            else if (x.children) {
              exists = _getNode(x.children, property, value);
              if (exists) exists.parents.unshift(x.name);
            }
          } else {
            return;
          }
        });
        return exists;
      };
      return value ? _getNode(this.localOptions, property, value) : null;
    },
    cancelAddLeafNode() {
      this.addLeafNode.value = null;
      this.addLeafNode.parents = [];
      this.addLeafNode.show = false;
    //   this.updateValue(null);
    },
    lostFocus() {
      this.hasFocus = false;
      this.$emit("lostFocus");
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/vars";

.vue-treeselect-hint {
  font-size: 12px;
  opacity: 0.6;
  margin: 5px 12px;
}

.vue-treeselect {
  font-family: "Martel Sans", sans-serif;
  color: $input-text-color;
  margin-top: 3px;
  .vue-treeselect__menu-container {
    margin-top: 1px;
  }

  .vue-treeselect__control {
    border-radius: 14px;
    height: 56px;
    font-size: 1rem;
  }

  .vue-treeselect__single-value,
  .vue-treeselect__value-container,
  .vue-treeselect__placeholder {
    padding: 10px 14px;
  }

  &.dense {
    margin-top: 0;
    .vue-treeselect__control {
      height: 40px;
    }
    .vue-treeselect__single-value,
    .vue-treeselect__value-container,
    .vue-treeselect__placeholder {
      padding: 3px 8px;
    }
  }

  &.borderless {
    .vue-treeselect__control {
      border-width: 0 0 1px 0;
      border-radius: 0;
      height: 26px;
    }
    &.has-focus .vue-treeselect__control {
      border-width: 0 0 2px 0 !important;
    }
    .vue-treeselect__single-value,
    .vue-treeselect__value-container,
    .vue-treeselect__placeholder {
      padding: 0;
      font-size: 14px;
    }
  }

  &.validation-error {
    .vue-treeselect__control {
      border-color: #ff5252;
      caret-color: #ff5252;
      border-width: 2px;
    }
    .vue-treeselect__single-value,
    .vue-treeselect__value-container,
    .vue-treeselect__placeholder,
    .vue-treeselect__control-arrow {
      color: #ff5252;
    }
  }
}
.hierarchypicker-hierarchy-level {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 5px;
  //   font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hierarchypicker-container {
  border: rgb(221, 221, 221) solid 1px;
  border-radius: 14px;
  padding: 10px;
}
.hierarchypicker-container-heading {
  margin-top: -20px;
  font-size: 12px;
  margin-bottom: 7px;
  background-color: white;
  display: table;
}
</style>