<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :sort-by="sortColumn"
      sort-desc
      :dense="density == 'dense'"
      :class="['mt-2', density]"
      :page="page"
      hide-default-footer
      :items-per-page="itemsPerPage"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td
            v-for="(col, coli) in headers"
            :key="'col' + coli"
            @click="handleClick(item, col.value)"
          >
            <div
              v-if="col.value === 'doc_checkbox'"
              class="text-right"
            >
              <v-checkbox
                @click.stop
                value
                :input-value="selectedDocs.includes(item.doc_id)"
                @change="handleClick(item, col.value)">
              </v-checkbox>
            </div>
            <div v-else-if="col.value === 'export_menu'">
              <v-btn small icon @click.stop="openContextMenu($event, item)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </div>
            <div
              v-else-if="col.value === 'system_number'"
              class="d-flex align-center"
            >
              <span v-html="item.system_number"></span>
            </div>
            <div v-else-if="col.value === 'doc_status_text'">
				<DocStatus :document="item"></DocStatus>
            </div>
            <div v-else-if="col.value === 'overall_score'">
              <v-progress-circular
                v-if="item.overall_score"
                :rotate="90"
                :size="density == 'sparse' ? 40 : density == 'dense' ? 25 : 35"
                :width="density == 'sparse' ? 4 : density == 'dense' ? 2 : 3"
                :value="item.overall_score"
                :color="item.overall_score_color"
                class="ma-1"
                ><span :style="density == 'dense' ? 'font-size: 12px' : ''">{{
                  item.overall_score
                }}</span>
              </v-progress-circular>
            </div>
            <div
              v-else-if="
                col.value === 'listview3' ||
                col.value === 'recruiter' ||
                col.value === 'job_updated_at' ||
                col.value === 'job_created_at' ||
                col.value === 'created_by' ||
                col.value.startsWith('classifier_')
              "
            >
              <span class="small" v-html="item[col.value]"></span>
            </div>
            <div v-else-if="col.value === 'add_doc_button'" class="text-right">
              <v-btn icon>
                <v-icon>add_circle</v-icon>
              </v-btn>
            </div>
            <div v-else-if="col.value === 'docQuestionnaireStatus'">
              <v-chip
                dense
                small
                class="status-chip"
                label
                outlined
                :color="item[col.sourceColour]"
                >{{ item[col.source] }}</v-chip
              >
            </div>
            <div v-else-if="col.value === 'questionnaireStatuses'">
              <div
                v-if="
                  item[col.source] &&
                  item[col.source].length &&
                  item[col.source][0].status !== 'Unassigned'
                "
              >
                <v-tooltip bottom v-if="item[col.source].length">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="showQuestionnaireSummary(item, col)"
                    >
                      <div
                        v-for="(rs, rsi) in item[col.source]"
                        :key="'col' + coli + 'rs' + rsi"
                        class="responder-stat"
                        :style="{
                          width: (80 * rs.count) / rs.total + '%',
                          backgroundColor: rs.colour,
                        }"
                      >
                        {{ rs.count }}
                      </div>
                      <div style="display: inline-flex; padding-left: 10px">
                        <v-badge
                          :content="item[col.source][0].total"
                          color="blue lighten-3"
                        ></v-badge>
                      </div>
                    </div>
                  </template>
                  <div>{{ item[col.source][0].total }} in Total</div>
                  <div
                    v-for="(rs, rsi) in item[col.source]"
                    :key="'col' + coli + 'rs' + rsi"
                  >
                    <div
                      class="responder-stat"
                      :style="{
                        width: rs.count * 20 + 'px',
                        height: '20px',
                        fontSize: '14px',
                        backgroundColor: rs.colour,
                      }"
                    >
                      {{ rs.count }}
                    </div>
                    <div
                      style="
                        display: inline-flex;
                        padding-left: 6px;
                        padding-top: 1px;
                      "
                    >
                      {{ rs.status }}
                    </div>
                  </div>
                </v-tooltip>
              </div>
            </div>
            <div v-else-if="col.value === 'docQuestionnaireScore'">
              <v-chip v-if="item[col.source]"
                dense
                small
                class="status-chip"
                label
                outlined
                      @click.stop="showQuestionnaireSummary(item, col)"
                >{{ item[col.source] }}</v-chip
              >
            </div>
            <div
              v-else-if="col.value === 'remove_doc_button'"
              class="text-right"
            >
              <v-btn icon>
                <v-icon>remove_circle</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <span v-html="item[col.value]"></span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="questionnaireSummary.show">
      <v-card>
        <v-card-title
          ><v-chip large outlined label class="mr-4">{{
            questionnaireSummary.docTitle
          }}</v-chip>
          {{ questionnaireSummary.type }} <v-spacer></v-spacer
          ><v-btn @click="hideQuestionnaireSummary">Close</v-btn></v-card-title
        >
        <v-card-text v-if="questionnaireSummary.show">
          <v-divider></v-divider>
          <DataCaptureQuestionnaireSummary
            :identifier="questionnaireSummary.identifier"
            isWithinDialogue
          ></DataCaptureQuestionnaireSummary></v-card-text></v-card
    ></v-dialog>
  </div>
</template>


<script>
import utils from "@/common/utils.js";
import DataCaptureQuestionnaireSummary from "@/components/cDataCaptureQuestionnaireSummary.vue";
import DocStatus from "@/components/common/DocStatus.vue"

export default {
  name: "DocumentDataTable",
  props: {
    headers: { type: Array },
    items: { type: Array },
    page: { type: Number },
    itemsPerPage: { type: Number },
    selectedDocs: { type: Array },
    density: { type: String, required: false, default: "sparse" },
    sortColumn: { type: String, default: "system_number" },
  },
  data: function () {
    return {
      utils: utils,
      questionnaireSummary: {
        show: false,
        identifier: { doc_id: null, questionnaire_id: null },
        type: "",
        docTitle: "",
      },
    };
  },
  components: { DataCaptureQuestionnaireSummary, DocStatus },
  computed: {},
  created() {},
  updated() {},
  watch: {},
  methods: {
    handleClick(item, col) {
      switch (col) {
        case "add_doc_button":
          this.$emit("addDocument", item);
          break;
        case "remove_doc_button":
          this.$emit("removeDocument", item);
          break;
        case "doc_checkbox":
          this.$emit("toggleDocument", item);
          break;
        default:
          this.$emit("openDocument", item);
          break;
      }
    },
    showQuestionnaireSummary(item, column) {
      this.questionnaireSummary.identifier.doc_id = item.doc_id;
      this.questionnaireSummary.identifier.questionnaire_id =
        column.questionnaire_id;
      this.questionnaireSummary.docTitle = `${item.system_number} - ${item.doc_name}`;
      this.questionnaireSummary.type = column.text;
      this.questionnaireSummary.show = true;
    },
    hideQuestionnaireSummary() {
      this.questionnaireSummary.doc_id = null;
      this.questionnaireSummary.title = "";
      this.questionnaireSummary.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

::v-deep .v-data-table {
  tbody > tr,
  tbody > tr span {
    cursor: pointer;
  }
}

.responder-stat {
  display: inline-flex;
  text-align: center;
  height: 18px;
  font-size: 11px;
  padding-top: 1px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 70%;
}
</style>