<template>
  <div>
    <v-dialog v-model="dialog" persistent width="1200">
      <v-card v-if="editedItem">
        <v-card-title class="px-8">
          <span class="headline">{{ formTitle }}</span>
          <v-switch
            v-model="editedItem.hr_active"
            label="Active"
            class="ml-6"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-btn icon large class="btn-background" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-row dense v-if="!editedItem.hr_id">
                  <v-select
                    :items="hierarchies"
                    :required="true"
                    label="Hierarchy Type"
                    item-text="ht_name"
                    item-value="ht_id"
                    v-model="editedItem.ht_id"
                    dense
                    outlined
                    class="roundish"
                  ></v-select>
                </v-row>
                <v-row dense>
                  <v-text-field
                    v-model="editedItem.hr_code"
                    label="Hierarchy Code"
                    dense
                    outlined
                    class="roundish"
                  ></v-text-field>
                </v-row>
                <v-row dense>
                  <v-select
                    :items="spellCheckLanguages"
                    clearable
                    :required="false"
                    label="Spell Check (other than default)"
                    v-model="editedItem.spell_check_language_code"
                    dense
                    outlined
                    class="roundish"
                  ></v-select>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row dense v-for="level in levels" :key="level.level">
                  <v-combobox
                    v-model="editedItem['level' + level.level]"
                    :items="level.distinctValues"
                    :label="`Level ${level.level} (${level.label})`"
                    outlined
                    class="roundish"
                    dense
                    @update:search-input="setValue(level.level, $event)"
                  ></v-combobox>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6 px-8">
          <v-btn color="error" outlined @click="deleteItem"> Delete </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn color="primary" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogDelete" max-width="900px">
      <v-card v-if="editedItem">
        <v-card-title class="justify-center">
          <div class="justify-center">
            Are you sure you want to delete this item?
          </div>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <div align="center" class="justify-center">
                {{ editedItem.tc_word }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "adminHierarchy",
  props: {
    hr_id: Number,
    show: Boolean,
  },
  data: function () {
    return {
      dtItemsPerPage: 20,
      typeRuleOptions: [],
      spellCheckLanguages: ["us"],
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      editedItem: null,
      defaultItem: {
        hr_id: 0,
        ht_id: 0,
        ht_name: "",
        ht_code: "",
        level1: "",
        level2: "",
        level3: "",
        level4: "",
        level5: "",
        level6: "",
        spell_check_language_code: "en",
        uploadedDocs: [],
        hr_active: true,
      },
      dialog: false,
      dialogDelete: null,
    };
  },
  components: {},
  watch: {
    show(val) {
      this.dialog = val;
      if (val) this.loadItem();
    },
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchies: (state) => state.hierarchies.hierarchies,
    }),
    formTitle() {
      return this.editedItem.hr_id ? "Edit Hierarchy" : "Add Hierarchy";
    },
    hierarchType() {
      if (!this.editedItem) return null;

      return this.hierarchies.find((ht) => ht.ht_id === this.editedItem.ht_id);
    },
    levels() {
      let allLevels = [1, 2, 3, 4, 5, 6];
      let levels = [];
      if (!this.hierarchType) return levels;

      allLevels.forEach((l) => {
        if (this.hierarchType["use_h" + l])
          levels.push({
            level: l,
            distinctValues: Array.from(
              new Set(this.hierarchType.values.map((h) => h["level" + l]))
            ),
            label: this.hierarchType["level" + l + "_name"],
          });
      });

      return levels;
    },
  },
  created: function () {
    if (this.show) this.loadItem();
  },
  methods: {
    loadItem() {
      if (this.hr_id) {
        let possibleError = false;
        this.isLoading = true;
        axios
          .get("hierarchy/detail/" + this.hr_id.toString())
          .then((resp) => {
            possibleError = true;
            if (resp.data.Data && resp.data.Data.length)
              this.editedItem = resp.data.Data[0];

            this.dialog = true;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
          });
      } else {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        if (this.hierarchies.length)
          this.editedItem.ht_id = this.hierarchies[0].ht_id;
        return;
      }
    },
    setValue(level, newVal) {
      if (this.editedItem["level" + level] !== newVal) {
        this.editedItem["level" + level] = newVal;
      }
    },	
    deleteItem() {
      this.dialogDelete = true;
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    deleteItemConfirm() {
      let data = this.editedItem;
      axios
        .post("hierarchy/delete", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.triggerNotification("Hierarchy Deleted", "success");
            this.editedItem = null;
            this.dialogDelete = false;
            this.dialog = false;
            this.$emit("deleted", data);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.$emit("close");
    },
    save() {
      if (!this.hierarchType) return;

      let data = this.editedItem;

      axios
        .post("hierarchy/save", data)
        .then((resp) => {
          if (resp.data.Status === "OK" && resp.data.Data.length > 0) {
            this.editedItem = null;
            this.dialog = false;
            this.$emit("saved", resp.data.Data[0]);
            this.triggerNotification("Hierarchy Saved", "success");
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>