<template>
  <div
    :class="deriveClass()"
    @click="clicked($event)"
    @contextmenu="openContextMenu($event)"
    :title="deriveTitle()"
  >
    {{ deriveValue() }}
  </div>
</template>
	
<script>
export default {
  name: "cHierarchyViewMeasureCount",
  props: {
    item: Object,
    measure: Object,
    selectable: Boolean,
    backColour: String,
    textColour: String,
    isTitle: Boolean,
  },
  data: function () {
    return {};
  },
  components: {},
  watch: {},
  computed: {
    propName() {
      return (
        this.measure.type + (this.measure.aggregation === "mean" ? "_mean" : "")
      );
    },
  },
  created: function () {},
  methods: {
    deriveValue() {
      return this.isTitle
        ? this.measure.abbreviation
        : this.item[this.propName];
    },
    deriveTitle() {
      if (this.item && this.measure.aggregation === "mean") {
        let value = this.item[this.measure.type + "_std_dev"];
        let valueCount = this.item[this.measure.type + "_mean_value_count"];
        return valueCount
          ? `Mean ${this.measure.title} (standard deviation of ${value.toFixed(
              0
            )} based on ${valueCount} document${valueCount > 1 ? "s" : ""})`
          : null;
      } else {
        let title = this.selectable
          ? "Click to " +
            (this.item.selected ? "un" : "") +
            "select item. Right click to see linked documents."
          : this.measure
          ? this.measure.title
          : null;

        if (this.item && this.measure.type === "match_count_content") {
          title = "Click to view docments with similar content.";
        } else if (this.item && this.measure.type === "match_count_name") {
          title =
            "Click to view docments with same titles but different content.";
        }
        return title;
      }
    },
    deriveClass() {
      let value = this.deriveValue();
      value = value === 0 ? "0" : value || "";
      if (this.isTitle) return this.measure.type;
      else if (
        !value ||
        (value === "0" && this.measure.aggregation_exclude_zero)
      )
        return "_hidden";
      else {
        let data = "";
        let ret =
          this.measure.type +
          (value === "0" ? " _zero" : "") +
          (this.selectable && this.item[this.propName]
            ? this.item.selected
              ? " _selected"
              : " _selectable"
            : "") +
          (this.backColour && this.measure.aggregation !== "mean"
            ? " " + this.backColour
            : "") +
          (this.textColour && this.measure.aggregation !== "mean"
            ? " text--" + this.textColour
            : "") +
          (this.measure.alignRight ? " float-right" : "") +
          (this.measure.aggregation === "mean"
            ? " " + this.item[this.measure.type + "_category"]
            : "");
        data = ret;
        return ret || data;
      }
    },
    openContextMenu(e) {
      if (
        this.item &&
        ["job_count", "match_count_content", "match_count_name"].some(
          (x) => x === this.measure.type
        )
      )
        this.$emit("showDocList", e, this.item, this.measure);
    },
    clicked(e) {
      if (this.selectable) this.$emit("click");
      if (
        this.item &&
        ["match_count_content", "match_count_name"].some(
          (x) => x === this.measure.type
        )
      )
        this.$emit("showDocList", e, this.item, this.measure);
    },
  },
};
</script>
	
<style scoped lang="scss">
._hidden {
  display: none;
}
div {
  max-width: 100px;
}
.score,
.salary {
  text-align: right;
  border: solid grey 1px;
  border-radius: 8px;
  font-size: 12px;
  padding-right: 5px;
  /* padding: 4px 2px 3px 0; */
  overflow: hidden;
  margin-left: 5px;
  white-space: nowrap;
}
.employee_count,
.job_count,
.match_count_name,
.match_count_content,
.leaf_node_count {
  text-align: center;
  border-radius: 16px;
  font-size: 13px;
  color: black;
  max-width: 65px;
  min-width: 20px;
  padding: 0 8px;
  display: inline-block;
  line-height: 19px;
}
.employee_count {
  border: solid #c2c1c1 3px;
  background-color: rgb(231, 230, 230);
}
.employee_count._zero {
  border: solid red 3px;
  background-color: rgb(241, 158, 158);
}
.job_count._zero {
  background-color: rgb(241, 158, 158);
  border: solid rgb(241, 158, 158) 3px;
}
.job_count {
  background-color: #deebf7;
  border: solid #deebf7 3px;
}
._selected {
  border: dotted blue 3px;
}
._selectable,
._selected {
  cursor: grab;
}
._selectable:hover,
._selected:hover {
  background-color: #4c779e;
  border-color: #4c779e;
  color: white;
}
.leaf_node_count {
  border: solid gray 2px;
}

._low {
  background-color: #ff6161;
  color: white;
}
._mid {
  background-color: lightgreen;
}
._high {
  background-color: green;
  color: white;
}
.match_count_content {
  background-color: #009688;
  border: solid #73b6f5 3px;
  color: white;
  cursor: pointer;
}
.match_count_name, {
  background-color: #3F51B5;
  border: solid #73b6f5 3px;
  color: white;
  cursor: pointer;
}
.match_count_name:hover,
.match_count_content:hover {
  background-color: #5d748a;
  color: white;
}
</style>