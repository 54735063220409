<template>
  <v-menu
    offset-y
    max-width="750"
    min-width="750"
    v-model="recruitersMenu"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        outlined
        hide-details
        dense
        label="People"
        readonly
        :value="
          selectedCount === 0 ? '' : `${selectedCount} selected`
        "
        :rounded="rounded"
        :class="`recruiterSelect ${roundish ? 'roundish' : ''}`"
        @click:append="recruitersMenu = true"
      >
        <template slot="append">
          <v-icon v-if="selectedCount !== 0" @click="clearFilter"
            >mdi-close</v-icon
          >
          <v-icon @click="recruitersMenu = true">{{
            recruitersMenu ? "mdi-menu-up" : "mdi-menu-down"
          }}</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card class="recruitersMenu">
      <v-row dense>
        <v-col cols="4">
          <v-tabs vertical v-model="personTab">
            <v-tab v-for="t in peopleSummary" :key="t.column">
              {{ t.label }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="8" class="pa-4" v-if="selectedTabData">
          <v-chip-group class="mb-2" column>
            <v-chip
              v-for="(n, i) in selectedTabIncluded"
              :key="i"
              label
              small
              class="recruiterChip"
            >
              <span class="pr-2">
                {{ n }}
              </span>
              <v-icon
                small
                @click="
                  selectedTabIncluded.splice(i, 1);
                  $emit('doFilter');
                "
              >
                mdi-close-circle
              </v-icon>
            </v-chip>
          </v-chip-group>
          <v-autocomplete
            dense
            outlined
            multiple
            :return-object="false"
            :items="selectedTabData.values"
            :label="selectedTabData.label + 's'"
            item-text="text"
            item-value="text"
            @change="$emit('doFilter')"
            v-model="selectedTabIncluded"
            :filter="utils.comboFilterPicker"
            hide-details
            prepend-inner-icon="mdi-magnify"
            class="roundish"
          >
            <template v-slot:selection> </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import utils from "@/common/utils.js";

export default {
  name: "cPeopleFilter",
  components: {},
  props: {
    value: { type: Array },
    rounded: { type: Boolean },
    roundish: { type: Boolean }
  },
  data: function () {
    return {
      personTab: 0,
      utils: utils,
      recruitersMenu: false,
      recruiterAltLabel: "Recruiter",
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.docs.loading,
      peopleSummary: (state) => state.docs.peopleSummary
    }),
    selectedTabData() {
      return this.peopleSummary[this.personTab];
    },
    selectedTabIncluded: {
      get() {
        return this.value.find((r) => r.column === this.selectedTabData?.column)?.values || [];
      },
      set(newValue) {
        if (!this.selectedTabData)
          return;

        let included = this.value.find(
          (r) => r.column === this.selectedTabData.column
        );
        if (!included) {
          included = { column: this.selectedTabData.column, values: [] };
          const val = this.value;
          val.push(included);
          this.$emit('update:value', val);
        }

        included.values = newValue;
      },
    },
    selectedCount() {
      return this.value.reduce((p, c) => (p = p + c.values.length), 0);
    },
  },
  created() {},
  watch: {},
  methods: {
    clearFilter() {
      this.$emit('update:value', []);
      this.$emit("doFilter");
    },
  },
};
</script>

<style scoped lang="scss">
.recruiterSelect::v-deep {
  input {
    cursor: pointer;
  }
}

.recruiterChip.theme--light {
  background-color: #f4f9fd !important;
  .v-icon {
    color: #7d8592;
  }
}

.recruitersMenu {
  min-height: 150px;
  overflow: hidden;
  .v-chip-group {
    min-height: 30px;
  }
  ::v-deep .v-tabs-slider-wrapper {
    left: auto !important;
    right: 0;
  }
}
</style>