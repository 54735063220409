<template>
  <div>
    <div v-if="hierarchies.length > 0" class="no-print">
      <v-data-table
        :headers="headers"
        :items="hierarchiesFiltered"
        :page.sync="currentPage"
        dense
        item-key="hr_id"
        class="elevation-0 mt-6"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 50, -1] }"
        sort-by="hr_id"
        :items-per-page="dtItemsPerPage"
        @click:row="editItem"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="mx-3" dense>
            <v-col cols="2">
              <v-text-field
                v-model="search"
                label="Search"
                clearable
                outlined
                dense
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                :items="hierarchieTypes"
                :required="true"
                label="Type"
                item-text="ht_name"
                item-value="ht_id"
                v-model="searchHtId"
                dense
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col style="max-width: 150px">
              <v-switch v-model="activeOnly" label="Active Only" />
            </v-col>
            <v-col style="max-width: 200px">
              <v-switch
                v-model="filterUnclassified"
                label="Unclassified Only"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>

    <v-row class="my-2 mx-1">
      <v-col cols="3">
        <v-btn color="primary" outlined small @click="addNew">New Item</v-btn>
      </v-col>
      <v-col class="text-center">
        <Pagination
          :totalItems="hierarchiesFiltered.length"
          :pageSize="dtItemsPerPage"
          :currentPage="currentPage"
          @pageNavigation="currentPage = $event"
        />
      </v-col>
      <v-col cols="3" class="text-right">
        <v-menu offset-y z-index="301">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!$loginState.readOnly"
              outlined
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ itemsPerPageText }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-for="item in itemsPerPageOptions"
              :key="item.value"
              @click="setPageSize(item.value)"
            >
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="primary"
          class="ml-2"
          outlined
          small
          @click="exportToCSV()"
          >Export to CSV</v-btn
        >
      </v-col>
    </v-row>

    <AdminHierarchyDetail
      :hr_id="editedItem.hr_id"
      :show="dialog"
      @close="dialog = false"
      @saved="updateItem"
      @deleted="deleteItem"
    >
    </AdminHierarchyDetail>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Pagination from "@/components/cPagination";
import AdminHierarchyDetail from "@/components/hierarchy/cAdminHierarchyDetail";
import utils from "@/common/utils.js";

export default {
  name: "adminHierarchy",
  data: function () {
    return {
      dtItemsPerPage: 20,
      itemsPerPageOptions: [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
      ],
      currentPage: 1,
      typeRuleOptions: [],
      spellCheckLanguages: ["us"],
      hierarchies: [],
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      filterUnclassified: false,
      activeOnly: true,
      search: "",
      searchHtId: null,
      headers: [
        { text: "Hierarchy Type", value: "ht_name" },
        { text: "Hierarchy Code", value: "hr_code" },
        { text: "Hierarchy Data", value: "hierarchyData" },
        { text: "Active", value: "hr_active" },
      ],
      formTitle: "Hierarchy",
      editedIndex: -1,
      editedItem: {
        hr_id: 0,
        ht_id: 0,
        ht_name: "",
        ht_code: "",
        level1: "",
        level2: "",
        level3: "",
        level4: "",
        level5: "",
        level6: "",
        spell_check_language_code: "en",
        uploadedDocs: [],
        hr_active: true,
      },
      defaultItem: {
        hr_id: 0,
        ht_id: 0,
        ht_name: "",
        ht_code: "",
        level1: "",
        level2: "",
        level3: "",
        level4: "",
        level5: "",
        level6: "",
        spell_check_language_code: "en",
        uploadedDocs: [],
        hr_active: true,
      },
      dialog: null,
    };
  },
  components: {
    AdminHierarchyDetail,
    Pagination,
  },
  created: function () {
    this.loadTypeConfig();
    this.loadData();
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchieTypes: (state) => state.hierarchies.hierarchies,
    }),
    hierarchiesFiltered() {
      let filtered = this.hierarchies;

      if (this.activeOnly) filtered = filtered.filter((h) => h.hr_active);

      if (this.searchHtId)
        filtered = filtered.filter((h) => h.ht_id === this.searchHtId);

      if (this.filterUnclassified)
        filtered = filtered.filter((h) => h.isUnclassified);

      if (this.search)
        filtered = filtered.filter(
          (h) =>
            (h.hierarchyData &&
              h.hierarchyData
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (h.hr_code &&
              h.hr_code.toLowerCase().includes(this.search.toLowerCase()))
        );

      return filtered;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.dtItemsPerPage
      );
      return option ? option.text : `${this.dtItemsPerPage} Items`;
    },
  },
  methods: {
    loadTypeConfig() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("hierarchy/rule/list/")
        .then((resp) => {
          possibleError = true;
          this.typeRuleOptions = resp.data.Data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    loadData() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("hierarchy/list")
        .then((resp) => {
          possibleError = true;
          this.hierarchies = resp.data.Data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    addNew() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.hierarchies.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    deleteItem(item) {
      this.dialog = false;
      let idx = this.hierarchies.findIndex((h) => h.hr_id === item.hr_id);

      if (idx > -1) {
        this.hierarchies.splice(idx, 1);
      }

      this.editedItem = {};
    },
    updateItem(updateItem) {
      this.dialog = false;
      let idx = this.hierarchies.findIndex(
        (item) => item.hr_id === updateItem.hr_id
      );

      if (idx > -1) {
        this.hierarchies.splice(idx, 1, updateItem);
      } else {
        this.hierarchies.push(updateItem);
      }

      this.editedItem = {};
    },
    exportToCSV() {
      let data = this.headers.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.hierarchiesFiltered.forEach((d) => {
        data +=
          this.headers
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",")
            + "\n";
      });
      utils.downloadFile(data, "Hierarchies.csv", "text/csv;encoding:utf-8");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-data-table tbody td {
  cursor: pointer !important;
}
</style>