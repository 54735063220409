<template>
  <div>
    <div
      :class="{
        'flex-container': true,
        fixedFooter: fullScreen && !height.length,
      }"
    >
      <!-- v-show="!isLoading" -->
      <v-container
        fluid
        :class="{
          'right-col': true,
          hierarchiesCollapsed: false,
          fullscreen: fullScreen,
        }"
      >
        <div
          ref="mainPane"
          :class="{ mainPane: true, fixedHeader: height.length }"
          style="height: 100%"
        >
          <v-row
            v-if="showTitle"
            :class="{
              'py-4': true,
              'pl-4': fullScreen && $vuetify.breakpoint.smAndUp,
            }"
          >
            <v-col cols="6">
              <h1 v-if="title" class="title">
                {{ title }}
              </h1>
              <h1 v-else-if="currentFilterKey" class="title">
                {{ currentFilterKey }} Tasks
              </h1>
              <h1 v-else class="title">{{ $route.meta.text }}</h1>
            </v-col>
          </v-row>
          <v-card :flat="!showFilters">
            <div :class="{ filters: true, 'mx-5': showFilters }">
              <v-row class="pt-2 pb-4">
                <v-col
                  v-if="showFilters"
                  class="filter-col"
                  style="width: 200px; max-width: 200px"
                >
                  <v-menu
                    ref="menu"
                    v-model="taskDueDateRangeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="taskDueDateRange"
                    transition="slide-y-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- prepend-inner-icon="mdi-calendar" -->
                      <v-text-field
                        v-model="taskDueDateRangeText"
                        label="Due Between"
                        dense
                        hide-details
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :append-icon="taskDueDateRangeText ? 'mdi-close' : ''"
                        @click:append="clearDateFilter"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="taskDueDateRange"
                      no-title
                      scrollable
                      range
                    >
                      <v-btn text @click="clearDateFilter"> Clear </v-btn>
                      <v-btn text @click="taskDueDateRangeMenu = false">
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="doDateFilter">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="filter-col" style="width: 180px; max-width: 180px">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    hide-details
                    v-model="taskStatusFilter"
                    @change="doStatusFilter"
                    :items="statusFilterOptions"
                    item-text="text"
                    item-value="value">
                  </v-select>
                  <!-- <v-radio-group
                    v-model="taskStatusFilter"
                    @change="doStatusFilter"
                    row
                  >
                    <v-radio label="All Tasks?" value="1"></v-radio>
                    <v-radio label="My Tasks Only?" value="2"></v-radio>
                    <v-radio label="Unassigned Tasks Only?" value="3"></v-radio>
                  </v-radio-group> -->
                </v-col>
                <!-- <v-col v-if="showFilters" cols="6" md="4" lg="1" class="filter-col">
                <v-select
                  v-model="docStatusIncluded"
                  :items="docStatusSummary"
                  item-text="status"
                  item-value="status"
                  return-object
                  label="Job Statuses"
                  multiple
                  :menu-props="{
                    closeOnContentClick: false,
                    maxHeight: '400',
                    'offset-y': true,
                  }"
                  dense
                  hide-details
                  outlined
                  @change="doFilter()"
                >
                  <template v-slot:selection="{ item, index }">
                    {{ getStatusFilterText(item, index) }}
                  </template>
                </v-select>
              </v-col> -->
                <v-col cols="1" class="header-buttons">
                  <div v-if="showFilters" class="d-flex align-center">
                    <v-btn
                      title="Search"
                      large
                      icon
                      class="btn-background ml-2"
                      v-show="!showSearch"
                      @click="showFilterField"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>

                    <v-expand-x-transition>
                      <v-text-field
                        v-show="showSearch"
                        v-model="filterText"
                        ref="filterField"
                        hide-details
                        solo
                        flat
                        label="Filter tasks containing..."
                        prepend-inner-icon="mdi-magnify"
                        append-icon="mdi-close"
                        @input="changefilterText"
                        @keypress.enter="triggerFilter"
                        @click:prepend-inner="triggerFilter"
                        @keyup.esc="clearFilter"
                        @click:append="clearFilter"
                        class="btn-background ml-2 align-center"
                      ></v-text-field>
                    </v-expand-x-transition>
                  </div>
                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        title="Customise Table"
                        large
                        v-on="on"
                        icon
                        class="btn-background ml-3"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense class="pa-3">
                      <v-list-item>
                        <v-list-item-title class="subtitle-1"
                          >Customise Table</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-for="field in dtColumns.filter(
                          (c) => $loginState.canDemo || !c.demoOnly
                        )"
                        :key="field.value"
                      >
                        <v-switch
                          class="v-input--reverse v-input--expand"
                          style="width: 200px"
                          dense
                          v-model="dtColumnsEnabled"
                          :label="field.text"
                          :value="field.value"
                          @change="saveColumnSelection"
                        ></v-switch>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </div>

            <v-card-text v-if="showHeaderCount" class="headerCount">
              <v-divider /><span
                >{{ filteredItems.length }}
                {{ filteredItems.length !== 1 ? "Tasks" : "Task" }} match the
                current selection.</span
              ><v-divider />
            </v-card-text>
            <v-divider v-else-if="showFilters" />
            <div>
              <div
                v-if="!isLoading && filteredItems.length"
                :class="{ docList: true }"
              >
                <v-data-table
                  :headers="dtColumnsFiltered"
                  :items="filteredItems"
                  sort-by="due_date"
                  sort-desc
                  :dense="density == 'dense'"
                  :class="['mt-2', density]"
                  :page.sync="currentPage"
                  hide-default-footer
                  :items-per-page="itemsPerPage"
                  :fixed-header="!!tableHeight"
                  :height="tableHeight"
                >
                  <template v-slot:item="{ item }">
                    <tr
                      @click="selectRow($event, item)"
                      @contextmenu="openContextMenu($event, item)"
                    >
                      <td v-for="col in dtColumnsFiltered" :key="col.value">
                        <div v-if="col.value === 'export_menu'">
                          <v-btn
                            small
                            icon
                            @click.stop="openContextMenu($event, item)"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </div>
                        <div v-else-if="col.value === 'task_status'">
                          <v-chip
                            dense
                            small
                            class="status-chip"
                            label
                            :color="item.task_status_colour"
                            >{{ item[col.value] }}</v-chip
                          >
                        </div>
                        <div v-else-if="col.value === 'document_status'">
							<DocStatus :document="item"></DocStatus>
                        </div>
                        <div
                          v-else-if="
                            ['due_date', 'completed_date'].indexOf(col.value) >=
                            0
                          "
                        >
                          <span
                            class="small"
                            v-html="formatDate(item[col.value])"
                          ></span>
                        </div>
                        <div v-else>
                          <span v-html="item[col.value]"></span>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">Reset</v-btn>
                  </template>
                </v-data-table>
                <div
                  v-if="showFooter"
                  class="
                    d-flex
                    justify-space-between
                    footer-actions
                    primary-background
                  "
                >
                  <PageDescription
                    :totalItems="filteredItems.length"
                    :pageSize="itemsPerPage"
                    :currentPage="currentPage"
                  />
                  <Pagination
                    :totalItems="filteredItems.length"
                    :pageSize="itemsPerPage"
                    :currentPage="currentPage"
                    @pageNavigation="currentPage = $event"
                  />
                  <div>
                    <v-btn
                      color="primary"
                      small
                      outlined
                      class="mx-2"
                      @click="exportToCSV"
                    >
                      <span>Export</span>
                    </v-btn>
                    <v-menu offset-y v-if="pageSize === 0" z-index="301">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="!$loginState.readOnly"
                          outlined
                          small
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ itemsPerPageText }}
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item
                          v-for="item in itemsPerPageOptions"
                          :key="item.value"
                          @click="setPageSize(item.value)"
                        >
                          <v-list-item-title>
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </div>
            </div>
            <v-menu
              v-model="contextMenu"
              absolute
              :position-x="posX"
              :position-y="posY"
              offset-y
              :close-on-content-click="false"
              nudge-width="300"
            >
              <v-card>
                <v-list dense>
                  <v-list-item @click="openTask(currentTask)">
                    <v-list-item-icon>
                      <v-icon>mdi-calendar-clock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Task</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openDocument(currentTask)">
                    <v-list-item-icon>
                      <v-icon>mdi-book-open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Document</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-card>
        </div>
      </v-container>
    </div>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <!-- <Loading :isVisible="isLoading" /> -->
    <v-dialog
      v-model="taskDialogue.show"
      max-width="600px"
      style="max-height: 600px"
      scrollable
    >
      <v-card v-if="taskDialogue.show">
        <v-card-title class="d-flex align-center">
          <div class="subtitle-1">
            Task - {{ taskDialogue.document_task.task_name }} (for
            {{ taskDialogue.document_task.role_name }})
          </div>
          <v-spacer />
          <v-btn
            icon
            large
            class="btn-background"
            @click="taskDialogue.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span
                >{{ taskDialogue.document_task.document_type }}:
                {{ taskDialogue.document_task.document_reference }} -
                {{ taskDialogue.document_task.document_name }}</span
              >
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="taskDialogue.due_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    @click:prepend-inner="taskDialogue.due_date_menu = true"
                    @blur="
                      taskDialogue.due_date = parseDate(
                        taskDialogue.due_date_text
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                    label="Due"
                    placeholder="Enter Date Due"
                    required
                    v-model="taskDialogue.due_date_text"
                    class="roundish"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="taskDialogue.due_date"
                  @input="
                    taskDialogue.due_date_menu = false;
                    taskDialogue.due_date_text = formatDate(
                      taskDialogue.due_date
                    );
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="taskDialogue.user_id_assigned"
                label="Assigned To:"
                :items="taskDialogue.user_list"
                item-text="user_name"
                item-value="user_id"
                clearable
                class="roundish"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 py-4">
          <v-btn
            large
            color="primarylight"
            class="roundish"
            @click="saveTask(true)"
            :disabled="taskDialogue.busy"
            >Set task Cancelled</v-btn
          ><v-spacer></v-spacer>
          <v-btn
            large
            color="primarylight"
            class="roundish"
            @click="saveTask(false)"
            :disabled="taskDialogue.busy"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import DocStatus from "@/components/common/DocStatus";
import utils from "@/common/utils.js";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "DocumentsWithPartsNew",
  components: {
    ResponseHandler,
    Pagination,
    PageDescription,
    DocStatus,
  },
  props: {
    fullScreen: { type: Boolean, required: false, default: true },
    pageSize: { type: Number, required: false, default: 0 },
    height: { type: String, required: false, default: "" },
    showHeaderCount: { type: Boolean, required: false, default: false },
    showFooter: { type: Boolean, required: false, default: true },
    showFilters: { type: Boolean, required: false, default: true },
    showTitle: { type: Boolean, required: false, default: true },
    density: { type: String, required: false, default: "sparse" },
    filters: { type: Object, required: false },
    minmalColumns: { type: Boolean, default: false }
  },
  data: function () {
    return {
      tasks: [],
      users: [],
      taskDialogue: {
        show: false,
        busy: false,
        document_task: null,
        user_list: [],
        user_id_assigned: null,
        due_date: null,
        due_date_text: "",
        due_date_menu: false,
        saveAction: "save",
      },
      utils: utils,
      response: null,
      title: "Tsks",
      items: [],
      isDirty: false,
      dirtyCount: 0,
      taskDueDateRange: [],
      taskDueDateRangeMenu: false,
      docStatusIncluded: [],
      docTypeIncluded: [],
      itemStatusIncluded: [],
      statusFilterOptions: [
        { text: "All Tasks", value: "1" },
        { text: "My Tasks", value: "2" },
        { text: "Unassigned Tasks", value: "3" }
      ],
      tempFilterText: "",
      filterText: "",
      showSearch: false,
      selDoc: "",
      viewSelect: "",
      dtItemsPerPage: 20,
      dtColumns: [
        {
          text: "Due",
          value: "due_date",
          sort: (a, b) =>
            dayjs(a).isAfter(dayjs(b))
              ? 1
              : dayjs(a).isBefore(dayjs(b))
              ? -1
              : 0,
        },
        { text: "Task Description", value: "task_type" },
        { text: "Task Status", value: "task_status" },
        { text: "Comment", value: "comment" },
        { text: "Document Type", value: "doc_type" },
        { text: "Job Title", value: "doc_name" },
        { text: "System Number", value: "reference" },
        { text: "Doc Status", value: "document_status" },
        { text: "Assigned To", value: "user_name_assigned" },
        { text: "Completed By", value: "user_name_completed" },
        {
          text: "Completed When",
          value: "completed_date",
          sort: (a, b) =>
            dayjs(a).isAfter(dayjs(b))
              ? 1
              : dayjs(a).isBefore(dayjs(b))
              ? -1
              : 0,
        },
      ],
      dtColumnsEnabled: [
        "due_date",
        "task_type",
        "task_status",
        "doc_type",
        "reference",
        "doc_name",
        "document_status",
        "user_name_assigned",
      ],
      dtColumnsEnabledMinimal: [
        "due_date",
        "task_type",
        "task_status"
      ],
      contextMenu: false,
      csvHeaders: [],
      dialog: false,
      options: { itemsPerPage: 15 },
      defaultStatusFilterList: "approved,pre-draft,pending,draft",
      filteredItems: [],
      maxCards: 24,
      currentPage: 1,
      drawerHover: false,
      currentTask: {},
      searchTimeout: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      savedFilters: [],
      currentFilterKey: "",
      newFilterKey: "",
      isFavFilter: false,
      selectedDocument: null,
      tableHeight: null,
      tableHeightTimeout: null,
      posX: null,
      posY: null,
      taskStatusFilter: "2",
      hasTasks: false,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    isLoading(val) {
      if (!val) this.fetchData();
    },
    filters() {
      this.doFilter();
    },
  },
  computed: {
    rowClickAction() {
      return (
        this.$loginState.user.settings.find(
          (s) => s.setting === "tasks_leftclickaction"
        )?.value || "opendoc"
      );
    },
    taskDueDateRangeText() {
      const range = this.taskDueDateRange;
      if (!range.length) return "";
      if (range.length == 1) return dayjs(range[0]).format("DD MMM YYYY");

      return range
        .map((d) => {
          return dayjs(d).format("DD MMM");
        })
        .join(" - ");
    },
    dtColumnsFiltered() {
      const headersEnabled = this.dtColumnsEnabled;
      return [
        ...this.dtColumns.filter(
          (c) =>
            headersEnabled.indexOf(c.value) >= 0 &&
            (this.$loginState.canDemo || !c.demoOnly)
        ),
        { text: "", value: "export_menu", sortable: false, align: "end" },
      ];
    },
    itemsPerPageOptions() {
      return [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
        { text: "All", value: -1 },
      ];
    },
    itemsPerPage() {
      if (this.pageSize !== 0) return this.pageSize;
      else return this.dtItemsPerPage;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
    currentPageItems() {
      if (this.itemsPerPage < 0) return this.filteredItems;

      const pageFrom = this.itemsPerPage * (this.currentPage - 1);
      const pageTo = this.itemsPerPage * this.currentPage;
      return this.filteredItems.slice(pageFrom, pageTo);
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapState({
      isLoading: (state) => state.tasks.isLoading,
    }),
  },
  created() {
    if (this.minmalColumns) {
      this.dtColumnsEnabled = this.dtColumnsEnabledMinimal;
    } else {
		this.dtColumnsEnabled = this.$loginState.getSetting(
        "twpColumnSelection",
        this.dtColumnsEnabled
      );
    }
    
    this.savedFilters = this.$loginState.getSetting("twpSavedFilters", []);
    this.fetchData();
    this.viewSelect = localStorage.getItem("dlview") || "cards";
    this.dtItemsPerPage = Number(localStorage.getItem("dtRPP")) || 20;
    this.$nextTick(() => this.setTableHeight());
  },
  methods: {
    onPaneResize() {
      if (this.tableHeightTimeout) {
        clearTimeout(this.tableHeightTimeout);
        this.tableHeightTimeout = null;
      }
      this.tableHeightTimeout = setTimeout(() => this.setTableHeight(), 50);
    },
    setTableHeight() {
      const paneHeight = this.$refs.mainPane.clientHeight;
      if (!this.height || !paneHeight || isNaN(paneHeight)) {
        this.tableHeight = null;
      } else if (this.showHeaderCount) {
        this.tableHeight = `${paneHeight - 80}px`;
      } else {
        this.tableHeight = `${paneHeight - 40}px`;
      }
    },
    resolveHeaderStyle(item) {
      return utils.resolveHeaderStyle(item);
    },
    documentChanged(doc) {
      this.$store.dispatch("docs/updateDoc", doc);
      this.doFilter();
    },
    formatDate(value) {
      return value ? dayjs(value).format("D MMM YYYY") : "";
    },
    parseDate(date) {
      if (!date) return null;
      return dayjs(date).format("YYYY-MM-DD");
    },
    fetchData() {
      if (this.isLoading) return;
      this.title = this.$loginState.canQA ? "Open Tasks" : "My Tasks";
      this.tasks = this.$store.getters["tasks/list"]; //(this.getFilters());
      this.doFilter();
    },
    saveFilters() {
      if (!this.newFilterKey) return;

      const idx = this.savedFilters.findIndex(
        (f) => f.key === this.newFilterKey
      );
      if (idx > -1)
        this.savedFilters[idx] = {
          key: this.newFilterKey,
          ...this.getFilters(),
        };
      else
        this.savedFilters.push({
          key: this.newFilterKey,
          ...this.getFilters(),
        });

      this.$loginState.saveSetting("twpSavedFilters", this.savedFilters);
      this.newFilterKey = "";
    },
    deleteSavedFilter(f) {
      this.savedFilters.splice(this.savedFilters.indexOf(f), 1);
      this.$loginState.saveSetting("twpSavedFilters", this.savedFilters);
    },
    restoreFilters(savedFilters) {
      if (savedFilters) {
        const filters = JSON.parse(JSON.stringify(savedFilters));
        this.filterText = filters.filterText || "";
        // this.taskDueDateRange = filters.taskDueDateRange || [];
        // this.docStatusIncluded = filters.docStatusIncluded || [];
        // this.docTypeIncluded = filters.docTypeIncluded || [];
        // this.docScoresIncluded = filters.docScoresIncluded || [];
        // this.recruitersIncluded = filters.recruitersIncluded || [];
        // this.hiringManagersIncluded = filters.hiringManagersIncluded || [];
        // this.creatorsIncluded = filters.creatorsIncluded || [];
        // this.selectedLanguageFilter = this.translateOptions.find(o => o.value == filters.languageFilter) || this.selectedLanguageFilter;
        // this.doFilter();
        // this.currentFilterKey = filters.key;
        // this.isFavFilter = true;
        // this.$nextTick(() => {
        //   this.preSelectedHierarchyFilters = filters.hierarchyFilters || [];
        //   this.preSelectedClassifierFilters = filters.classifierFilters || [];
        // });
      }
    },
    getDocTypeFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docTypeIncluded.length === this.docTypeSummary.length)
        return this.docTypeSummary.length > 0 ? "All" : "None";

      if (this.docTypeIncluded.length > 2)
        return `${this.docTypeIncluded.length} items`;

      return this.docTypeIncluded.map((i) => i.docType).join(", ");
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docStatusIncluded.length === this.docStatusSummary.length) {
        return this.docStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.defaultStatusFilterList.split(",");
        if (
          defList.every((s) =>
            this.docStatusIncluded.some((si) => si.status.toLowerCase() === s)
          )
        ) {
          let text = "";
          text = "Default";
          if (defList.length < this.docStatusIncluded.length) {
            text += ` (+ ${this.docStatusIncluded.length - defList.length})`;
          }
          return text;
        } else {
          return this.docStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    changefilterText(val) {
      this.tempFilterText = val;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => this.triggerFilter(), 200);
    },
    showFilterField() {
      this.showSearch = true;
      this.$nextTick(() => {
        if (this.$refs.filterField) this.$refs.filterField.$refs.input.focus();
      });
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
      this.showSearch = false;
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    clearDateFilter() {
      this.taskDueDateRange.splice(0);
      this.taskDueDateRangeMenu = false;
      this.doFilter();
    },
    doDateFilter() {
      this.$refs.menu.save(this.taskDueDateRange);
      this.doFilter();
    },
    doStatusFilter() {
      //his.$refs.menu.save(this.taskDueDateRange);
      this.doFilter();
    },
    getFilters() {
      const filters = this.filters || {};

      const params = {
        filterText: (this.filterText || filters.filterText || "")
          .trim()
          .toLowerCase(),
        mine: filters.mine || false,
        taskDueDateRange: this.showFilters
          ? this.taskDueDateRange
          : filters.taskDueDateRange,
        docStatusIncluded: this.showFilters
          ? this.docStatusIncluded
          : filters.docStatusIncluded,
        docTypeIncluded:
          this.filters?.docTypeIncluded ||
          (this.showFilters ? this.docTypeIncluded : filters.docTypeIncluded),
      };

      return JSON.parse(JSON.stringify(params));
    },
    doFilter() {
      if (this.isLoading) return;

      if (this.isFavFilter) {
        this.currentFilterKey = this.$route.meta.text.replace("Tasks", "");
        this.isFavFilter = false;
      }

      //utils.setDefaultStatuses(this.$loginState, this.docStatusIncluded);

      this.filteredItems = this.filter(this.getFilters());

      if (this.hasTasks) this.$emit("tasksLoaded");

      this.currentPage = 1;
      this.$loginState.saveSetting("twpDocTypes", this.docTypeIncluded);
    },
    filter(params) {
      let tasks = JSON.parse(JSON.stringify(this.tasks));
      this.hasTasks = tasks.length > 0;

      if (this.taskStatusFilter === "2") {
        tasks = tasks.filter((t) => t.mine);
      } else if (this.taskStatusFilter === "3") {
        tasks = tasks.filter((t) => !t.user_id_assigned);
      }

      if (params.mine) tasks = tasks.filter((doc) => doc.mine);

      if (params.taskDueDateRange && params.taskDueDateRange.length) {
        const fromDate = dayjs(params.taskDueDateRange[0]);
        const toDate =
          params.taskDueDateRange.length > 1
            ? dayjs(params.taskDueDateRange[1])
            : undefined;

        tasks = tasks.filter((d) => {
          const due = dayjs(d.due_date);
          return due.isAfter(fromDate) && (!toDate || due.isBefore(toDate));
        });
      }

      if (params.docTypeIncluded && params.docTypeIncluded.length) {
        tasks = tasks.filter((d) =>
          params.docTypeIncluded.find((s) => s.docType === d.doc_type)
        );
      }

      if (params.filterText) {
        let search = params.filterText
          .split(" ")
          .filter((x) => x.length)
          .map((x) => x);

        tasks = tasks.filter((d) =>
          search.every((s) => d.match.indexOf(s, 0) >= 0)
        );
        tasks.forEach((d) => {
          search.forEach((s) => {
            [
              "doc_name",
              "doc_type",
              "reference",
              "user_name_assigned",
              "user_name_completed",
              "task_type",
            ].forEach((col) => {
              let val = d[col] || "",
                posMs = val.indexOf("<mark>"),
                posMe = val.indexOf("</mark>"),
                posPrev = 0,
                output = "";
              while (posMs >= 0) {
                if (posMs - posPrev > 0) {
                  output += val
                    .substring(posPrev, posMs)
                    .replace(
                      new RegExp(s, "gi"),
                      (match) => `<mark>${match}</mark>`
                    );
                }
                output += val.substring(posMs, posMe + 7);
                posPrev = posMe + 7;
                posMs = val.indexOf("<mark>", posMe);
                posMe = val.indexOf("</mark>", posMs);
              }
              if (posPrev < val.length) {
                output += val
                  .substring(posPrev, val.length)
                  .replace(
                    new RegExp(s, "gi"),
                    (match) => `<mark>${match}</mark>`
                  );
              }
              d[col] = output;
            });
          });
        });
      }
      tasks.forEach((doc, index) => (doc.index = index + 1));
      return tasks;
    },
    saveColumnSelection() {
      if (!this.minmalColumns)
        this.$loginState.saveSetting("twpColumnSelection", this.dtColumnsEnabled);
    },
    selectRow(event, item) {
      if (this.rowClickAction === "menu") {
        this.openContextMenu(event, item);
      } else if (this.rowClickAction === "openTask") {
        this.openTask(item);
      } else {
        this.openDocument(item);
      }
    },
    openDocument(item) {
      this.contextMenu = false;
      this.$emit("openDocument", {
        doc_id: item.doc_id,
        tmpl_id: item.tmpl_id,
        system_number: utils.removeTags(item.reference),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        viewAction: {
          action_available_id: item.action_available_id,
          action_name: item.action_name,
          document_role_id: item.document_role_id,
          document_role_name: item.document_role_name,
          task: {
            document_task_id: item.document_task_id,
            task_name: item.task_type,
            comment: item.comment,
          },
        },
      });
    },
    setPageSize(value) {
      if (this.pageSize === 0) {
        if (this.cardView) {
          localStorage.setItem("twpMaxCards", value);
          this.maxCards = value;
        } else {
          localStorage.setItem("dtRPP", value);
          this.dtItemsPerPage = value;
        }
      }
    },
    exportToCSV() {
      this.csvHeaders = JSON.parse(JSON.stringify(this.dtColumns));
      let data =
        this.csvHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.filteredItems.forEach((d) => {
        data +=
          this.csvHeaders
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Tasks.csv", "text/csv;encoding:utf-8");
    },
    openContextMenu(e, item) {
      this.currentTask = item;
      e.preventDefault();
      this.contextMenu = false;
      this.posX = e.clientX;
      this.posY = e.clientY;
      this.$nextTick(() => {
        this.contextMenu = true;
      });
    },
    openTask(task) {
      let possibleError = false;
      if (task.task_status === "Cancelled") {
        alert("Can't edit a Cancelled task");
        return;
      }
      this.currentTask = task;

      axios
        .get("workflow/task/" + task.document_task_id)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.taskDialogue.document_task = resp.data.Data.document_task;
            this.taskDialogue.user_list = resp.data.Data.user_list;
            this.taskDialogue.user_id_assigned =
              resp.data.Data.document_task.user_id_assigned;
            this.taskDialogue.due_date = resp.data.Data.document_task.due_date;
            this.taskDialogue.due_date_text =
              resp.data.Data.document_task.due_date_text;
            this.taskDialogue.show = true;
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    saveTask(cancelTask) {
      let possibleError = false;
      let userName = "";

      let data = { ...this.taskDialogue.document_task };
      data.reassigned = 0;
      data.isCancelled = !!cancelTask;
      data.isDirty = false;
      if (
        this.taskDialogue.document_task.user_id_assigned !==
        this.taskDialogue.user_id_assigned
      ) {
        data.user_id_assigned = this.taskDialogue.user_id_assigned;
        userName = data.user_id_assigned
          ? this.taskDialogue.user_list.find(
              (x) => x.user_id === data.user_id_assigned
            ).user_name
          : "UNASSIGNED";
        data.reassigned = 1;
        data.isDirty = true;
      }
      if (
        this.taskDialogue.document_task.due_date !== this.taskDialogue.due_date
      ) {
        data.due_date = this.taskDialogue.due_date;
        data.due_date_text = this.formatDate(this.taskDialogue.due_date);
        data.isDirty = true;
      }
      if (!data.isDirty && !cancelTask) {
        this.taskDialogue.show = false;
        return;
      }
      this.taskDialogue.busy = true;

      axios
        .post("workflow/updateTask/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (cancelTask) {
              this.currentTask.task_status = "Cancelled";
              this.currentTask.task_status_colour = "yellow";
              this.currentTask.user_name_assigned = "";
            } else {
              this.currentTask.due_date = data.due_date;
              if (data.reassigned) {
                this.currentTask.user_name_assigned = userName;
                this.currentTask.task_status = "Open";
                this.currentTask.task_status_colour = "amber";
              }
            }
            this.taskDialogue.show = false;
          }
          this.taskDialogue.busy = false;
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.taskDialogue.busy = false;
        });
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 48px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
    &.collapsed {
      left: 20px;
    }
  }

  .right-col {
    padding: 12px 0;
    transition: padding 200ms;
    &.fullscreen {
      padding: 12px;
    }
    &.hierarchiesCollapsed {
      padding-left: 30px;
    }
    &.hierarchiesPinned {
      padding-left: 410px;
    }
  }

  @media (max-width: 600px) {
    .right-col {
      padding-left: 0;
    }
  }
}

.filter-col {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 50%;
  @media (min-width: 960px) {
    max-width: 25%;
  }
  @media (min-width: 1264px) {
    max-width: 14%;
  }
}

.header-buttons {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
}

.btn-background {
  height: 44px;
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}

.headerCount {
  display: flex;
  align-items: center;
  padding: 0 60px 0 0;
  margin: -45px 0 15px 0;
  span {
    margin: 0 20px;
  }
}

.mainPane > .v-card {
  border-radius: 10px;
}

::v-deep mark {
  color: inherit;
  border-radius: 5px;
  background-color: rgba(#0372ee, 0.3);
}

::v-deep .v-select__selections {
  overflow: hidden;
  white-space: nowrap;
  flex-flow: nowrap;
}

.fixedFooter {
  @media (max-width: 600px) {
    .docList .footer-actions {
      padding-left: 15px;
    }
  }

  .left-col {
    height: calc(100vh - 103px) !important;
  }
  .mainPane {
    padding-bottom: 56px;
  }

  .docList {
    .footer-actions {
      width: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 4;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.multipane {
  .multipane-resizer {
    margin: 0;
    left: 0;
    position: relative;
    height: 20px;
    &:before {
      display: block;
      content: "";
      width: 40%;

      position: absolute;
      left: 30%;
      margin-top: 5px;
      margin-left: -20px;
      border: 2px solid #ccc;
      border-radius: 2px;
    }
    &:hover {
      &:before {
        border-color: #999;
      }
    }
  }

  .mainPane {
    padding-top: 12px;
    padding-right: 12px;
    min-height: 100px;
    max-height: 100%;
    &.fixedHeader {
      padding-right: 0;
      overflow: hidden;
    }
  }
  .previewPane {
    width: 100%;
    height: 100px;
    display: flex;
    flex-grow: 1;
    position: relative;
    .actions {
      position: absolute;
      top: 0;
      right: 25px;
      width: 44px;
      button {
        z-index: 1;
        opacity: 0.8;
      }
    }

    .docPreview {
      width: 100%;
      min-height: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 12px 85px 0 85px;
      //height: calc(50vh - 35px);
      //overflow-y: auto;
    }
  }
  &.hasPreview {
    height: calc(100vh - 105px);
    .mainPane {
      height: 100%;
      overflow-x: hidden;
      padding-bottom: 12px;
    }
  }
  // &:not(.hasPreview) {
  //   .mainPane {
  //     height: 100%;
  //     overflow: unset;
  //   }
  // }
}

.v-application.theme--light {
  .previewPane {
    ::v-deep .docPreview {
      background-color: $background-color-light;
      > .v-card {
        box-shadow: 0 0 4px 0 #b5b5b5;
      }
    }
  }
}

.v-application.theme--dark {
  .previewPane {
    ::v-deep .docPreview {
      > .v-card {
        border: 1px solid #b5b5b5;
      }
    }
  }
}

.docList {
  .footer-actions {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 55px;

    div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
    }
  }

  .v-card.doc-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .v-card.on-hover.theme--light .v-list {
    background-color: #f7f7f7;
  }

  .v-card.on-hover.theme--dark .v-list {
    background-color: #272727;
  }

  .job-description {
    font-size: 0.75rem;
    overflow: hidden;
    display: flow-root;
    position: relative;
    height: 12em;
    line-height: 1.8;
  }

  .job-description:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1) 50%);
  }

  .v-card.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#1e1e1e, 0),
        rgba(#1e1e1e, 1) 50%
      );
    }
  }

  .v-card.on-hover {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#f7f7f7, 0),
        rgba(#f7f7f7, 1) 50%
      );
    }
  }

  .v-card.on-hover.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#272727, 0),
        rgba(#272727, 1) 50%
      );
    }
  }

  .data-content {
    margin-top: -5px;
    padding-top: 0;
  }
  .data-content-2 {
    margin-top: -7px;
    min-height: auto !important;
    max-height: auto;
    padding-bottom: 0;
  }
  .data-label {
    font-size: 0.6rem;
    padding: 0 5px 0 16px;
    color: darkgray;
  }
  .data-value {
    font-size: 0.9rem;
  }
  .data-value-2 {
    font-size: 0.75rem;

    padding-bottom: 10px;
  }

  .doc-card .status-chip {
    background-color: transparent !important;
    :before {
      content: "\2022";
      margin-right: 0.5em;
    }
  }

  ::v-deep .v-data-table {
    tbody > tr,
    tbody > tr span {
      cursor: pointer;
    }
    tr td:last-child {
      padding: 0 10px 0 0;
    }
    .status-chip {
      height: 2.35em;
      min-width: 7.2em;
      display: inline-flex;
      justify-content: center;
      font-size: 0.5625rem;
    }
  }
}

::v-deep {
  .customFlag {
    transform: scale(0.35) !important;
    -ms-transform: scale(0.35) !important;
    -webkit-transform: scale(0.35) !important;
    -moz-transform: scale(0.35) !important;
  }
}

.recruiterSelect::v-deep {
  input {
    cursor: pointer;
  }
}

.recruiterChip.theme--light {
  background-color: #f4f9fd !important;
  .v-icon {
    color: #7d8592;
  }
}

.recruitersMenu {
  min-height: 150px;
  .v-chip-group {
    min-height: 30px;
  }
  ::v-deep .v-tabs-slider-wrapper {
    left: auto !important;
    right: 0;
  }
}
</style>
