import axios from "axios";

export const state = () => ({
	initialised: false,
	loading: false,
	config: {}
});

export const mutations = {
	initialised(state) {
		state.initialised = true;
		state.loading = true;
	},
	clear(state) {
		state.config = {};
	},
	unload(state) {
		state.initialised = false;
		state.loading = false;
		mutations.clear(state);
	},
	replace(state, data) {
		mutations.clear(state);
		state.config = data;
		state.loading = false;
	}
};

export const actions = {
	init({ commit, getters }) {
		if (!getters.isInitialised) {
			commit("initialised");
			actions.getConfig({ commit, getters });
		}
	},
	unload({ commit }) {
		commit("unload");
	},
	getConfig({ commit, getters }) {
		if (!getters.isInitialised) return;

		axios
			.get("client/config")
			.then((resp) => {
				if (resp.data.Status === "OK") {
					commit("replace", resp.data.Data);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					commit("unload");
				} else {
					//try again in 30 secs
					setTimeout(() => actions.getConfig({ commit, getters }), 300 * 1000);
				}
				console.log(err);
			});
	},
};

export const getters = {
	isInitialised: (state) => {
		return state.initialised;
	},
	config: (state) => {
		return {...state.config}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
