import axios from "axios";
export default {
    handleLandingLinkClick(context, ev, link) {
        if (window.location.hash.indexOf('landingconfig') >= 0)
            return;
        
        if (link.isCreateLink) {
            ev.stopPropagation();
            ev.preventDefault();
            context.$emit("addDocument", link.createDocType);
        } else if (link.url && link.sso) {
            // any sso links should be proxied to append sso token
            ev.stopPropagation();
            ev.preventDefault();

            // ask for sso link from url
            axios
                .post("auth/ssolink", { link: link })
                .then((resp) => {
                    if (!resp.data || !resp.data.url)
                    return alert("Error Creating SSO Link, cannot log you in to alternative resource");
                    window.open(resp.data.url, resp.data.target);
                })
                .catch(() => {
                    alert("Error Creating SSO Link, cannot log you in to alternative resource");
                });
        } else if (link.passedParams && link.url) {
            ev.stopPropagation();
            ev.preventDefault();
            let params;
            try {
                params = JSON.parse(link.passedParams);
                context.$passedParams.set(params);
            } catch (e) {
                console.log(e);
            }

            context.$router.push( { path: link.url });
        }
    },
    //update/validate definition
    prepLandingPageDefinition(definition, docTypes) {
        definition.documentType = definition.documentType || (docTypes.length ? docTypes[0].tmpl_name : "");
        definition.tabs = definition.tabs || [];

        if (definition.tabs.length === 0)
            definition.tabs.push({ title: "New Tab" });

        definition.tabs.forEach((t) => {
            t.cols = t.cols || [];
            if (t.cols.length === 0)
            t.cols.push({
                cols: 0,
                rows: []
            });
            
            //handle old definitions before I added multiple columns
            if (t.rows) {
                t.cols[0].rows = t.rows;
                delete t.rows;
            }
        });

        //handle old definitions before I added tab support
        if (definition.rows) {
            definition.tabs[0].cols[0].rows = definition.rows;
            delete definition.rows;
        }

        definition.tabs.forEach((t) => {
            t.cols.forEach((c) => {
                c.rows = c.rows || [];
                c.rows.forEach((r) => {
                    r.controlType = r.controlType || "LinkTiles";
                    r.style = r.style || "static";
                    r.collapsed = (r.style === "collapsible-collapsed");

                    r.cols = r.cols || 12;
                    r.links = r.links || [];
                    const maxId = Math.max(...r.links.map((l) => l.id || 0));
                    r.links.forEach((l, li) => {
                        l.id = l.id || maxId + li + 1;
                        l.type = l.type || "link";
                    });
                    r.docListColumns = r.docListColumns || [];

                    if (r.links.some(l => l.type === "metabase"))
                        r.controlType = "Metabase";

                    if (r.controlType === "Metabase") {
                        r.links.forEach(l => {
                            l.showBorder = l.showBorder === undefined || l.showBorder;
                        })
                    }
                })
            });
        });

        definition.defaultTabIndex = definition.defaultTabIndex || 0;
    }
}