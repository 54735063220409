<template>
    <div class="docContent">
            <div class="viewPartHolder" v-for="(item, idx) in visibleDocParts" :key="'new_' + idx" >
                <draggable
                    :list="item.parts"
                    :handle="'.dragHandle'"
                    ghost-class="ghost"
                    :move="canMove"
                    :group="{ name: 'pt' }"
                    :disabled="!item.permissions_list.canEdit"
                    @start="draggedParent = null"
                    @end="dragging = false"
                    @change="(e) => { return movePart(e, item);}"                           
                >
                    <div class="viewPartRow" 
                        :style="cssVars" 
                        v-for="(part, pKey) in item.parts" 
                        :key="idx + '_' + pKey"
                        :id="`container_${part.doc_part_id}`"
                        :ref="`container_${part.doc_part_id}`"
                    >
                        <div class="viewPartItem" 
                            :class="[ 'viewPartItem', item.tmpl_part_metadata.contentPart ? 'contentPart' : 'tmplPart', getHighlightStyle(item, part), highlightedDocParts.includes(part.doc_part_id) ? 'highlightedScorePart' : '' ]"
                            @dragover.prevent 
                            @dragenter="highlightDragTarget(item, part)" 
                            @drop="dropItem($event, item, part)"
                            @mouseover="part.hover = true;" @mouseout="part.hover = false;"
                        >
                            <div class="viewPartRowGutter">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"                                
                                        v-if="!item.tmpl_part_metadata.contentPart && item.tmpl_part_metadata.why_text && hintsVisible && pKey === 0"
                                        class="locked"
                                        color="grey lighten-2"
                                        @click="showHint(item, true)"
                                        small style="padding-left:3px"
                                    >mdi-lightbulb-outline</v-icon>
                                </template>
                                <span>View Insights</span>
                            </v-tooltip>
                            <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="!item.tmpl_part_metadata.contentPart && item.tmpl_part_metadata.how_to_text && hintsVisible && pKey === 0"
                                            class="locked"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="grey lighten-2"
                                            small style="padding-left:3px"
                                            @click="showHint(item, false)"
                                        >mdi-help-circle-outline </v-icon>
                                    </template>
                                    <span>Guidance</span>
                                </v-tooltip>
                            <v-tooltip right v-if="!item.tmpl_part_metadata.contentPart && (item.type === 'Working Pattern' || flexPartId === item.tmpl_part_id) && item.permissions_list.canEdit">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"     
                                        color="green lighten-2"
                                        small 
                                        @click="rFlex()"
                                        style="cursor: pointer; font-size: 1.5em;top: -4px;"
                                        >mdi-electron-framework</v-icon>
                                </template>
                                <span>Run Flex Assessment</span>
                            </v-tooltip>     
                            <v-tooltip right v-if="!item.tmpl_part_metadata.contentPart && data.document.state.canEditDocParts && item.hasDeletedParts && item.cardinality.maxError > 1 && pKey === 0">
                                <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    @click="showDeletedPartHistory(item)"
                                    color="grey lighten-2"
                                    small style="padding-left:3px"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-history</v-icon
                                >
                                </template>
                                <span>{{ "View deleted " + item.type + " rows" }}</span>
                            </v-tooltip>
                            </div>
                            <div class="viewPartRowContent" :class="[item.tmpl_part_metadata.contentPart ? 'contentPart' : 'tmplPart', getCssStyles(item.tmpl_part_metadata.layout)]" >
                                <v-icon v-if="item.tmpl_part_metadata.canMove" class="dragHandle">mdi-drag-variant</v-icon>
                                <v-row> 
                                    <v-col v-for="(attr, aIdx) in part.attributes" :key="idx + '_' + pKey + '_' + aIdx" :cols="attr.cols" style="">
                                        <TextInput 
                                            v-if="!item.tmpl_part_metadata.contentPart && (!attr.isLookup && !attr.isDependentLookup)"
                                            :value="attr"
                                            :ref="idx + '_' + pKey + '_' + aIdx"
                                            :metadata="item.tmpl_part_metadata"
                                            :multiRow="multiRowPart(item)"
                                            :wordChecks="item.wordChecks"
                                            @focus="focus(item,attr, part)"
                                            @blur="blur(item, attr, part)"
                                            :doubleClickEdit=false
                                            :edit="attr.editing"
                                            :disable="!item.permissions_list.canEdit || part.locked"
                                            :active="item.tmpl_part_metadata.active"
                                            :spellCheck="spellcheckEnabled && item.permissions_list.canEdit && !item.tmpl_part_metadata.exclusions.spell_check"
                                            :spellCheckLanguageCode="data.document.spell_check_language_code"
                                            :controlOffset="textControlOffset"
                                            :textClass="item.tmpl_part_metadata.layout"
                                            :displayMode="'doc'"
                                            :displayActions="aIdx === part.attributes.length-1"
                                            @updateEssential="(data) => updateEssential(data, part, item)"
                                            @confirm="(data) => updateDocPart(data, attr, item, part)"
                                            @languageIssue="(data) => openLanguageMenu(data, item, part, attr)"
                                        ></TextInput>
                                        <LookupInput v-else-if="attr.isLookup || attr.isDependentLookup"
                                            :value="attr"
                                            :ref="idx + '_' + pKey + '_' + aIdx"
                                            :metadata="item.tmpl_part_metadata"
                                            :disable="!item.permissions_list.canEdit || part.locked"
                                            :active="item.tmpl_part_metadata.active"
                                            :part="part"
                                            @update="(data) => updateDocPart(data, attr, item, part)"     
                                            @sync="(data, parts) => syncDocPartAttr(data, parts, attr, part, item)"
                                        ></LookupInput>
                                        <ViewControl v-else
                                        :value="attr" 
                                        :metadata="item.tmpl_part_metadata"
                                        :permissions="part.permissions_list" 
                                        :disabled="!item.permissions_list.canEdit || part.locked" 
                                        @update="(data) => updateContentPart(data, attr, item, part)" 
                                        />
                                        <!--<div v-else>
                                            <table id="table" width="100%" class="groupTable" cellspacing="0" cellpadding="0" :style="getTableCss(part.attributes[0].content_metadata.css_styles)">
                                                <tr v-for="(row,rIdx) in part.attributes[0].content_metadata.rows" :key="rIdx" :style="getTableCss(row.css_styles)">
                                                    <td v-for="(cell,cIdx) in row.cells" :key="cIdx" :colspan="cell.col_span" :class="cell.selected ? 'selected' : ''" :rowspan="cell.row_span">
                                                        <span v-if="cell.content_type === 'static'" v-html="cell.value"></span>
                                                        <div v-else-if="cell.content_type === 'tmplPart'">
                                                            <div v-if="!cell.cell_content.parts[0].attributes[0].isLookup && !cell.cell_content.parts[0].attributes[0].isDependentLookup">
                                                                <TextInput 
                                                                    :value="cell.cell_content.parts[0].attributes[0]"
                                                                    :ref="idx + '_' + pKey + '_' + aIdx"
                                                                    :disable="cell.cell_content.parts[0].attributes[0].locked"
                                                                    :metadata="cell.cell_content.tmpl_part_metadata"
                                                                    :active="true"
                                                                    :wordChecks="cell.cell_content.wordChecks || []"
                                                                ></TextInput>
                                                            </div>
                                                            <div v-else-if="cell.cell_content.parts[0].attributes[0].isLookup || cell.cell_content.parts[0].attributes[0].isDependentLookup">
                                                                <LookupInput
                                                                    :value="cell.cell_content.parts[0].attributes[0]"
                                                                    :ref="idx + '_' + pKey + '_' + aIdx"
                                                                    :metadata="cell.cell_content.tmpl_part_metadata"
                                                                    :disable="cell.cell_content.parts[0].attributes[0].locked"
                                                                    :active="true"
                                                                    :part="cell.cell_content.dependentParts"
                                                                ></LookupInput>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>-->
                                    </v-col>    
                                </v-row>
                            </div>
                            <div class="viewPartRowActions">
                                <!---HOVER ACTIONS--> 
                                <div style="display:inline-flex;position: absolute; right: 115px; top: -10px;" v-if="data.document.state.canEditDocParts && item.permissions_list.canEdit && useOpenAISuggestions && item.tmpl_part_metadata.suggestion_part && !part.locked">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attr }">
                                            
                                            <v-btn icon v-bind="attr" v-on="on"
                                            @click="doAiFunction(part, item, 'suggestions', $event)"
                                            :class="'userPilotSuggestions' + item.type.replace(/\s+/g, '')"
                                            :style="`${part.hover ? '' : 'visibility: hidden'}`"
                                            >
                                                <v-icon color="green">mdi-auto-fix</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generate AI Suggestions</span>
                                    </v-tooltip>
                                </div>     
                                <div style="display:inline-flex;position: absolute; right: 27px; top: 0px;">
                                    <v-tooltip v-if="!item.permissions_list.canEdit || part.locked" right>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                class="locked"
                                                color="grey lighten-2"
                                                :style="`padding-left:3px; ${part.hover ? '' : 'visibility: hidden'}`"
                                                small style=""
                                            >mdi-lock</v-icon>
                                        </template>
                                        <span>{{item.permissions_list.disableReason || "Content Locked"}}</span>
                                    </v-tooltip> 
                                </div>                  
                                <!-- END HOVER ACTIONS-->                 
                                <div style="display:inline-flex;position: absolute; right: 69px; top: -5px;" v-if="!part.contentPart && item.childPartTypes && item.childPartTypes.length && part.childParts">
                                        <v-badge
                                            color="grey lighten-1"
                                            overlap
                                            v-if="!part.expanded"
                                            :class="[part.childParts && part.childParts.some(x => highlightedDocParts.includes(x.doc_part_id)) ? 'highlightedScorePart' : '' ]"
                                            :value="part.childParts && part.childParts.length"
                                            :content="part.childParts && part.childParts.length"
                                            dense
                                        >
                                            <v-btn
                                                icon
                                                small
                                                style="float: right"
                                                title="Show sub-parts"
                                                @click="expandPart(part, item)"
                                                :color="!part.childParts.length ? 'red' : ''"
                                            >
                                                <v-icon small>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </v-badge>
                                        <div
                                            v-if="part.expanded"
                                            style="padding-right:6px"
                                        >
                                            <v-icon small @click="shrinkPart(part)">mdi-chevron-up</v-icon>
                                        </div>
                                </div>    
                                <div style="display:inline-flex;position: absolute; right: 40px; top: -5px;" v-if="!item.tmpl_part_metadata.contentPart">
                                    <v-menu bottom left attach>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                            ><v-btn
                                            v-if="(!readOnly && item.permissions_list.canEdit) && ((item.permissions_list.canEdit && !part.locked) || (data.document.state.canEditDocParts && part.canAdd))"
                                            icon
                                            small
                                            class="userPilotLCRowActions"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon color="grey lighten-2" >mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item v-if="data.document.state.canEditDocParts && part.canAdd && item.permissions_list.canEdit" @click="addPart(part, item)">
                                                <v-list-item-icon style="margin-right: 10px">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Add new</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="data.partsHistory.length && data.document.lifecycle_id && data.document.state.docPartCommentAction" @click="showPartHistory(part, item, true)">
                                                <v-list-item-icon style="margin-right: 10px">
                                                    <v-icon>mdi-comment-text-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{ data.document.state.docPartCommentAction.name }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                v-if="
                                                    allowSummarise &&
                                                    part.text &&
                                                    data.document.state.canEditDocParts &&
                                                    !(
                                                        item.tmpl_part_metadata.tp_locked ||
                                                        part.locked
                                                    )
                                                    "
                                                    :class="'userPilotSummarise' + item.type.replace(/\s+/g, '')"
                                                    @click="doAiFunction(part, item, 'summarise', $event)"
                                                ><v-list-item-icon
                                                style="margin-right: 10px"
                                                >
                                                <v-icon
                                                    >mdi-arrow-collapse-vertical</v-icon
                                                >
                                                </v-list-item-icon>
                                                <v-list-item-title>Summarise</v-list-item-title>
                                            </v-list-item>                                            
                                            <v-list-item
                                                v-if="data.partsHistory.length"
                                                @click="showPartHistory(part, item)"
                                            >
                                                <v-list-item-icon style="margin-right: 10px">
                                                    <v-icon>mdi-history</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>History</v-list-item-title>
                                            </v-list-item>  
                                            <v-list-item v-if="data.document.state.canEditDocParts && item.tmpl_part_metadata.allow_edit_dialog && !part.locked" @click="showNotes(item, part)">
                                                <v-list-item-icon style="margin-right: 10px">
                                                    <v-icon>mdi-square-edit-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Edit Notes</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="part.canLock">
                                                <v-list-item-icon style="margin-right: 10px">
                                                    <v-icon>{{ part.locked ? 'lock_open' :'lock' }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    {{ part.locked ? 'Un-Lock' : 'Lock' }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="data.document.state.canEditDocParts && part.canDelete && item.permissions_list.canEdit && !part.locked" @click="deleteRow(part, item, null)">
                                                <v-list-item-icon style="margin-right: 10px">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>                    
                                        </v-list>
                                    </v-menu>  
                                </div>  
                                <div style="display:inline-flex;position: absolute; right: 7px; top: 0px;">
                                    <v-tooltip
                                        bottom
                                        v-if="data.document.state.canEditDocParts &&
                                        part.notes && item.tmpl_part_metadata.allow_edit_dialog
                                        "
                                    >
                                        <template
                                        v-slot:activator="{
                                            on,
                                            attrs,
                                        }"
                                        >
                                        <v-icon
                                            class="notesIcon"
                                            v-bind="attrs"
                                            v-on="on"
                                            style="font-size:18px; color: #7d93b4 !important;"
                                            @click="showNotes(item, part)"
                                            >mdi-comment-text-outline</v-icon
                                        >
                                        </template>
                                        <div
                                        style="max-width: 500px"
                                        v-html="part.notes"
                                        ></div>
                                    </v-tooltip>
                                </div>                                   
                                <div style="display:inline-flex;position: absolute; right: 97px; top: -1px;">
                                    <v-tooltip
                                        bottom
                                        v-if="
                                            (part.hasComments || getPartHistory(part).length > 1) &&
                                            pKey === 0 &&
                                            data.document.state.docPartCommentAction
                                        "
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                            :class="{
                                                notesIcon: true,
                                                notesEssential:
                                                item.tmpl_part_metadata.uses_essential_flag,
                                            }" style="font-size:20px; color: #7d93b4 !important;"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="showPartHistory(part, item, true)"
                                            >mdi-timeline-text</v-icon
                                            >
                                        </template>
                                        <span style="max-width: 500px">
                                            <span v-if="part.hasComments && getPartHistory(part).length === 1">{{ item.type }} comments</span>
                                            <span v-else-if="!part.hasComments && getPartHistory(part).length > 1">{{ item.type }} activity</span>
                                            <span v-else>{{ item.type }} comments and activity</span>
                                        </span>
                                    </v-tooltip>
                                </div>      
                            </div>
                            <div class="viewPartRowAdminActions" v-if="adminMode">                                        
                                <v-tooltip v-if="adminMode && pKey === 0" right>
                                    <template
                                        v-slot:activator="{ on, attrs }"
                                    >
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            class="locked"
                                            :color="item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length > 0 || item.permissions_list.view_dependencies.length > 0 ? 'success' : '#94979d7d'"
                                            small style="padding-left:3px"
                                            @click="enableEditPartPermission(item,part)"
                                        >mdi-security</v-icon>
                                    </template>
                                    <span>{{item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length > 0 || item.permissions_list.view_dependencies.length > 0 ? 'Permission Controlled' : 'Add Permissions'}}</span>
                                </v-tooltip>              
                                <v-tooltip v-if="adminMode && pKey === 0" right>
                                    <template
                                        v-slot:activator="{ on, attrs }"
                                    >
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small style="cursor:pointer; padding-left:3px; position:relative;"
                                            @click="enableEditPart(item,part)"
                                            color="#94979d7d"
                                        >mdi-cog</v-icon>
                                    </template>
                                    <span>Configure</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <div class="viewPartChildren" v-if="!item.tmpl_part_metadata.contentPart && part.childPartTypes && part.childPartTypes.length > 0 && part.expanded">
                            <div class="childPartTypeHolder" v-for="(cpt,cptIdx) in part.childPartTypes" :key="'cpt' + cpt.tmpl_part_id + '_' + cptIdx" >
                                <div class="childPartRow" v-for="(cp,cpIdx) in cpt.parts" :key="'cp' + cp.doc_part_id + '_' + cpIdx" >
                                    <div class="childPartItem" :class="[cpt.tmpl_part_metadata.contentPart ? 'contentPart' : 'tmplPart', highlightedDocParts.includes(cp.doc_part_id) ? 'highlightedScorePart' : '']">
                                        <div class="childPartRowGutter">
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"                                
                                                        v-if="!item.tmpl_part_metadata.contentPart && item.tmpl_part_metadata.why_text && hintsVisible && cpIdx === 0"
                                                        class="locked"
                                                        color="grey lighten-2"
                                                        @click="showHint(cpt, true)"
                                                        small style="padding-left:3px"
                                                    >mdi-lightbulb-outline</v-icon>
                                                </template>
                                                <span>View Insights</span>
                                            </v-tooltip>
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        v-if="!item.tmpl_part_metadata.contentPart && item.tmpl_part_metadata.how_to_text && hintsVisible && cpIdx === 0"
                                                        class="locked"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="grey lighten-2"
                                                        small style="padding-left:3px"
                                                        @click="showHint(cpt, false)"
                                                    >mdi-help-circle-outline </v-icon>
                                                </template>
                                                <span>Guidance</span>
                                            </v-tooltip>  
                                        </div>
                                        <div class="childPartRowContent" :style="{ 'padding-left': cp.canMove ? '50px' : '0px' }" :class="[cpt.tmpl_part_metadata.contentPart ? 'contentPart' : 'tmplPart',getCssStyles(item.tmpl_part_metadata.layout)]" >
                                            <v-icon v-if="cpt.tmpl_part_metadata.canMove" style="display:inline-flex;" class="dragHandle">mdi-drag-variant</v-icon>
                                            <TextInput 
                                                v-if="!cp.contentPart"
                                                :value="cp"
                                                :ref="idx + '_' + pKey + '_' + cpIdx"
                                                :metadata="cpt.tmpl_part_metadata"
                                                :wordChecks="item.wordChecks"
                                                :multiRow="multiRowPart(cpt)"
                                                @focus="focus(cpt,cp, part)"
                                                @blur="blur(cpt, cp, part)"
                                                :doubleClickEdit=false
                                                :edit="cp.editing"
                                                :disable="!item.permissions_list.canEdit"
                                                :active=true
                                                :spellCheck="spellcheckEnabled && item.permissions_list.canEdit && !item.tmpl_part_metadata.exclusions.spell_check"
                                                :spellCheckLanguageCode="data.document.spell_check_language_code"
                                                :controlOffset="textControlOffset"
                                                :textClass="item.tmpl_part_metadata.layout"
                                                :displayMode="'doc'"
                                                :displayActions=true
                                                @updateEssential="(data) => updateEssential(data, cpt, item)"
                                                @confirm="(data) => updateDocPart(data, null, cpt, cp)"
                                                @languageIssue="(data) => openLanguageMenu(data, item, cpt, null)"
                                            ></TextInput>
                                            <ViewControl v-else 
                                                :value="cp" 
                                                :metadata="item.tmpl_part_metadata"
                                                :disabled="!item.permissions_list.canEdit" 
                                                @update="(data) => updateContentPart(data, null, item, part)" 
                                            />
                                        </div>
                                        <div class="childPartRowActions">  
                                            <div style="display:inline-flex;position: absolute; right: 40px; top: -5px;" v-if="!item.tmpl_part_metadata.contentPart">
                                                <v-menu bottom left attach>
                                                    <template
                                                        v-slot:activator="{ on, attrs }"
                                                        ><v-btn
                                                        icon
                                                        small
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        <v-icon color="grey lighten-2" >mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list dense>
                                                        <v-list-item v-if="data.document.state.canEditDocParts && cp.canAdd && item.permissions_list.canEdit" @click="addPart(cp, cpt, part)">
                                                            <v-list-item-icon style="margin-right: 10px">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-title>Add new</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item v-if="data.partsHistory.length && data.document.lifecycle_id && data.document.state.docPartCommentAction" @click="showPartHistory(cp, item, true)">
                                                            <v-list-item-icon style="margin-right: 10px">
                                                                <v-icon>mdi-comment-text-outline</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-title>{{ data.document.state.docPartCommentAction.name }}</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="data.partsHistory.length"
                                                            @click="showPartHistory(cp, item)"
                                                        >
                                                            <v-list-item-icon style="margin-right: 10px">
                                                                <v-icon>mdi-history</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-title>History</v-list-item-title>
                                                        </v-list-item>  
                                                        <v-list-item v-if="data.document.state.canEditDocParts && cpt.tmpl_part_metadata.allow_edit_dialog" @click="showNotes(item, cp)">
                                                            <v-list-item-icon style="margin-right: 10px">
                                                                <v-icon>mdi-square-edit-outline</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-title>Edit Notes</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item v-if="part.canLock">
                                                            <v-list-item-icon style="margin-right: 10px">
                                                                <v-icon>{{ part.locked ? 'lock_open' :'lock' }}</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-title>
                                                                {{ part.locked ? 'Un-Lock' : 'Lock' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item v-if="data.document.state.canEditDocParts && cp.canDelete && item.permissions_list.canEdit" @click="deleteRow(cp, cpt, part)">
                                                            <v-list-item-icon style="margin-right: 10px">
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-title>Delete</v-list-item-title>
                                                        </v-list-item>                    
                                                    </v-list>
                                                </v-menu>  
                                            </div>   
                                            <div style="display:inline-flex;position: absolute; right: 27px; top: 0px;">
                                                <v-tooltip v-if="!item.permissions_list.canEdit" right>
                                                    <template
                                                        v-slot:activator="{ on, attrs }"
                                                    >
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="locked"
                                                            color="grey lighten-2"
                                                            small style="padding-left:3px"
                                                        >mdi-lock</v-icon>
                                                    </template>
                                                    <span>{{item.permissions_list.disableReason}}</span>
                                                </v-tooltip> 
                                            </div>      
                                            <div style="display:inline-flex;position: absolute; right: 7px; top: -1px;">
                                                <v-tooltip
                                                    bottom
                                                    v-if="
                                                        (cp.hasComments || getPartHistory(cp).length > 1) &&
                                                        cpIdx === 0 &&
                                                        data.document.state.docPartCommentAction
                                                    "
                                                    >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                        :class="{
                                                            notesIcon: true,
                                                            notesEssential:
                                                            item.tmpl_part_metadata.uses_essential_flag,
                                                        }" style="font-size:20px; color: #7d93b4 !important;"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="showPartHistory(part, item, true)"
                                                        >mdi-timeline-text</v-icon
                                                        >
                                                    </template>
                                                    <span style="max-width: 500px">
                                                        <span v-if="cp.hasComments && getPartHistory(cp).length === 1">{{ item.type }} comments</span>
                                                        <span v-else-if="!cp.hasComments && getPartHistory(cp).length > 1">{{ item.type }} activity</span>
                                                        <span v-else>{{ item.type }} comments and activity</span>
                                                    </span>
                                                </v-tooltip>
                                            </div>   
                                        </div>
                                        <div class="childPartRowAdminActions" v-if="adminMode">    
                                            <v-tooltip v-if="adminMode" right>
                                                <template
                                                    v-slot:activator="{ on, attrs }"
                                                >
                                                    <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="locked"
                                                        :color="item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length || item.permissions_list.view_dependencies.length > 0 > 0 ? 'success' : '#94979d7d'"
                                                        small style="padding-left:3px"
                                                        @click="enableEditPartPermission(item,part)"
                                                    >mdi-security</v-icon>
                                                </template>
                                                <span>{{item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length > 0 || item.permissions_list.view_dependencies.length > 0 ? 'Permission Controlled' : 'Add Permissions'}}</span>
                                            </v-tooltip>              
                                            <v-tooltip v-if="adminMode" right>
                                                <template
                                                    v-slot:activator="{ on, attrs }"
                                                >
                                                    <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        small style="cursor:pointer; padding-left:3px; position:relative;"
                                                        @click="enableEditPart(item,part)"
                                                        color="#94979d7d"
                                                    >mdi-cog</v-icon>
                                                </template>
                                                <span>Configure</span>
                                            </v-tooltip>                                                  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                        <div class="viewPartLinked" v-if="part.linked_content.length > 0 && viewLinkedContent">
                            <div v-for="(content,cIdx) in part.linked_content" :key="cIdx" style="display: inline-flex;width: 100%;" :style="{ 'padding-left': item.tmpl_part_metadata.canMove ? '85px' : '50px', 'padding-right': adminMode ? '90px' : '0px'}">
                                <ViewControl :value="content.parts[0].attributes[0]" :disabled="!content.permissions_list.canEdit" :metadata="content.tmpl_part_metadata" @update="(data) => updateContentPart(data, content.parts[0].attributes[0], part)"/>
                            </div>
                            <div class="viewPartLinkedAdminActions" v-if="adminMode">
                                <v-tooltip v-if="adminMode" right>
                                    <template
                                        v-slot:activator="{ on, attrs }"
                                    >
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            class="locked"
                                            :color="item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length > 0 ? 'success' : '#94979d7d'"
                                            small style="padding-left:3px"
                                            @click="enableEditPartPermission(item,part)"
                                        >mdi-security</v-icon>
                                    </template>
                                    <span>{{item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length > 0 ? 'Permission Controlled' : 'Add Permissions'}}</span>
                                </v-tooltip> 
                                <v-tooltip v-if="adminMode" right>
                                    <template
                                        v-slot:activator="{ on, attrs }"
                                    >
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small style="cursor:pointer; padding-left:3px; position:relative;"
                                            @click="enableEditPart(item,part)"
                                            color="#94979d7d"
                                        >mdi-cog</v-icon>
                                    </template>
                                    <span>Configure</span>
                                </v-tooltip>                                
                            </div>
                        </div>   
                    </div>
                </draggable>
            </div>
            <v-menu bottom left attach
                v-model="externalContentMenu.show"      
                :value="externalContentMenu.show"
                :close-on-content-click="false"
                :nudge-width="200"
                :top="externalContentMenu.lookupTop"
                :position-y="externalContentMenu.lookupPosY"
                :position-x="externalContentMenu.lookupPosX"
                :max-width="externalContentMenu.lookupWidth"
                :min-width="externalContentMenu.lookupWidth"
                max-height="300px"
                offset-x
                content-class="elevation-2 userPilotSuggestionsMenu"
            >
                <AIMenu :data="aiMenuData" @setValue="setValue"></AIMenu>
            </v-menu>   
        <v-dialog v-model="hint.show" scrollable max-width="800px">
            <v-card>
                <v-card-title>
                <!-- <v-icon>{{ hint.icon }}</v-icon> -->
                <span class="subtitle-1">{{ hint.title }}</span>
                <v-spacer></v-spacer>
                <!-- {{ hint.subtitle }} -->
                <v-btn
                    icon
                    title="close"
                    class="btn-background"
                    @click="hint.show = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text style="max-height: 800px">
                <div v-html="hint.text"></div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="notesDialog.show" @keyup.esc="notesDialog.show = false" scrollable max-width="1000px">
            <v-card v-if="notesDialog.item && notesDialog.part">
                <v-card-title>
                <!-- <v-icon>{{ hint.icon }}</v-icon> -->
                <span class="subtitle-1">{{ notesDialog.item.type }}</span>
                <v-spacer></v-spacer>
                <!-- {{ hint.subtitle }} -->
                <v-btn
                    icon
                    title="close"
                    class="btn-background"
                    @click="notesDialog.show = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text style="max-height: 800px">
                <v-row dense>
                    <v-col>
                        <div v-html="notesDialog.part.text"></div>
                    </v-col>
                </v-row>
                <v-row dense style="padding-top:15px">
                    <v-col>
                        <v-textarea
                        ref="notes"
                        outlined
                        class="roundish"
                        label="Notes"
                        placeholder="Add your notes here..."
                        rows="1"
                        auto-grow
                        v-model="notesDialog.original.notes"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col class="d-flex align-center">
                        <v-select
                        v-if="$loginState.canQA"
                        outlined
                        class="roundish"
                        :menu-props="{
                            'offset-y': true,
                        }"
                        :return-object="false"
                        :items="utils.qualities"
                        label="Reusable Content Usage"
                        item-text="text"
                        item-value="value"
                        v-model="notesDialog.quality"
                        hide-details
                        ></v-select>
                    </v-col>
                    <v-col class="d-flex align-end justify-end">
                        <v-btn
                        ref="save"
                        large
                        class="roundish px-10"
                        color="primarylight"
                        @click="saveNotes"
                        >Save</v-btn
                        >
                    </v-col>
                </v-row>                
                </v-card-text>
            </v-card>
        </v-dialog>             
        <Notification :notification="notification"/>
        <RoleFlex
            v-if="data.document"
            :open="roleFlex.show"
            label="text"
            :documentId="roleFlex.documentId"
            :docPart="roleFlex.docPart"
            :fixedResults="data.document.flex_fixed_results"
            @closed="roleFlex.show = false"
            @workingPattern="newWorkingPattern"
            @flexupdated="saveFlex"
        ></RoleFlex>  
        <LanguageMenu
          :show="languageMenu.show"
          @close="closeLanguageMenu"
          :xCoord="languageMenu.x"
          :yCoord="languageMenu.y"
          :menu="languageMenu"
          @doSpellReplace="(data, source) => doSpellReplace(data)"
          @languageOptionsUpdate="(data) => languageOptionsUpdate(data)"
        ></LanguageMenu>      
    </div>
</template>

<script>
import TextInput from '@/components/common/TextInput';
import LookupInput from '@/components/common/LookupInput';
import ViewControl from '@/components/document/view/View_Content';
import RoleFlex from "@/components/cRoleFlex"; // @ is an alias to /src
import draggable from "vuedraggable";
import viewUtils from './utils';
import Notification from "@/components/common/SnackBar.vue";
import utils from "@/common/utils.js";
import LanguageMenu from '@/components/common/LanguageMenu';
import { mapState } from "vuex";
import AIMenu from "./AI_Function_Menu.vue";

export default {
    name: 'ViewParts',
    props: {
        parts: Array,
        data: null,
        disableEdit: Boolean,
        adminMode: Boolean,
        viewLinkedContent: Boolean,
        readOnly: Boolean,
        lockSection: Boolean,
        isDragging: Boolean,
        spellcheckEnabled: Boolean,
        nonSuggestionsList: Array,
        sectionPermission: Object,
        highlightedDocParts: Array
    },
    data: function() {
        return {
            docParts: [],
            disablePartEdit: false,
            essentialFlags: [
                {
                    value: true,
                    text: "Essential",
                },
                {
                    value: false,
                    text: "Non Essential",
                },
            ],  
            hintsVisible: true,   
            notification: {
                text:"",
                type:"success"
            }, 
            roleFlex: {
                documentId: "",
                show: false,
                docPart: {},
            },       
            flexPartId: null,  
            flexFixedResults: false,      
            dragging: false,
            draggedParent: null,   
            contentDrag: false,
            dragTargetPart: null,
            languageMenu: {
                show: false,
                x: 0,
                y: 0,
                textStatus: null,
                element: null,
                match: null,
            },
            hint: { show: false, icon: "", title: "", subtitle: "", text: "" }, 
            notesDialog: { show: false, original: null, item: null, part: null, quality: 0, dirty: false },
            utils: utils,
            triggerAutoAdd: false,
            lastAddedId: null,
            suggestionsLoading: false,
            lookupMenuShow: false,
            lookupMaxHeight: 300,
            lookupWidth: 500,
            lookupTop: false,
            lookupPosY: 0,
            lookupPosX: 0,
            lookupsToShow: 20,
            lookupsPageSize: 20,
            suggestionType: null,
            suggestionData: [],
            selectedSuggestionIndx: -1,
            externalContentMenu: {
                show: false,
                activeNode: false,
                loading: false,
                suggestionType: null,
                lookupMaxHeight: 300,
                lookupWidth: 500, 
                lookupTop: false,
                lookupPosY: 0,
                lookupPosX: 0,
                lookupsToShow: 20,
                lookupsPageSize: 20,
                lookupData: []
            },
            aiMenuData: null,
            partActionMenu: this.$loginState.user && this.$loginState.user.settings.some(
                (s) =>
                    s.setting === "document_part_action" &&
                    s.value.indexOf("menu") === 0
                ),
        }
    },
    components: {
        TextInput, ViewControl,draggable, Notification, RoleFlex, LanguageMenu, LookupInput,AIMenu
    },

    watch: {
        parts(val){
            if(val){
                this.init(val);
            }
        },
        isDragging(val){
            this.contentDrag = val;
        }
    },    
    computed: {
        ...mapState({
            config: (state) => state.settings.config,
        }),         
        textControlOffset(){
            return this.adminMode ? '40px' : '0px';
        },
        cssVars () {
            return {
                '--admin-actions-offset': this.textControlOffset,
            }
        },
        autoAddNewRow(){
            return !this.$loginState.user.settings.some(
                (s) => s.setting === 'document_suppress_autoaddnewrow' && s.value !== "false"
            );
        },
        allowSummarise() {
            return this.config.contentGenerationConfig?.enableSummarise || false;
        },
        useOpenAISuggestions() {
            return this.config.contentGenerationConfig?.enablePartSuggestions;
        },
        visibleDocParts(){
            return this.docParts.filter(x => (x.permissions_list.viewPermissionOverride === null || x.permissions_list.viewPermissionOverride === true) && (x.permissions_list.displayPermissionOverride === null || x.permissions_list.displayPermissionOverride === true));
        }  
    },
    mounted() {

    },
    created() {
        if(this.parts){
            this.init(this.parts);
        }
    },    
    methods: {
        newWorkingPattern(html, wordCount) {
            let newVal = utils.partToSubParts(html);
            let wpPartType = this.data.document.parttypes.find(
                (pt) => pt.type === "Working Pattern"
            );
            wpPartType.parts[0].subParts = newVal;
            wpPartType.parts[0].text = html;
            wpPartType.wordCount = wordCount;
            this.isLoading = true;
            this.refreshFlexScore();
        },   
        async saveFlex(flexModel){
            if (
                this.roleFlex.docPart &&
                this.roleFlex.docPart.parts &&
                this.roleFlex.docPart.parts.length !== 0
            ) {
                let flexPart = {
                    ...this.roleFlex.docPart.parts[0],
                    notes: flexModel.workingPatternNotes
                };

                await this.updateDocPart({text: flexModel.workingPatternHtml}, null, this.roleFlex.docPart, flexPart, true);
            }
        },    
        onLookupListScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (!this.suggestionType && scrollTop + clientHeight >= scrollHeight) {
                this.lookupsToShow += this.lookupsPageSize;
                this.filterLookup();
            }
        },        
        async setValue(value){
            this.externalContentMenu.show = false;
            let attr = null;
            if(this.externalContentMenu.item.tmpl_part_metadata.attributes.length > 1){
                attr = this.externalContentMenu.part.attributes[this.externalContentMenu.part.attributes.length - 1];
            }

            let resp = await this.updateDocPart({text: value}, attr, this.externalContentMenu.item, this.externalContentMenu.part, true);
            console.log(resp);
        },
        /*async setValue(part, item, value){
            let resp = await this.updateDocPart({text: value}, null, item, part, true);
            console.log(resp);
        },*/
    
        doAiFunction(part, partType, functionType){
            this.externalContentMenu.show = false;
            this.suggestionsLoading = true;
            let containerRect = this.$refs[`container_${part.doc_part_id}`][0].getBoundingClientRect();
            let container = this.$parent.$refs["docContent"];
            this.lookupWidth = containerRect.right - containerRect.left - 45;//containerRect.width - 150;
            this.lookupPosX = containerRect.right - containerRect.left - 45;
            //let offset = ev.clientY - containerRect.top;
            const vh = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );
            console.log(container);


            //show below textara unless textarea is at bottom of page
            if (containerRect.bottom + this.lookupMaxHeight + 2 < vh) {
                this.lookupPosY = containerRect.bottom - 125 + container.scrollTop;
                this.lookupTop = false;
            } else {
                this.lookupPosY = containerRect.top - 125 + container.scrollTop;
                this.lookupTop = true;
            }

            this.externalContentMenu = {
                show: true,
                lookupTop: this.lookupTop,
                lookupPosY: this.lookupPosY,
                lookupPosX: this.lookupPosX,
                lookupWidth: this.lookupWidth,
                part: part,
                item: partType
            }

            if(functionType === 'suggestions'){
                this.suggestionType = partType.type;
            }

            this.aiMenuData = {
                function: functionType,
                language: "English", //todo pass in lang
                jobTitle: this.data.document.doc_name,
                text: part.text,
                docLanguage: this.data.docLanguage,
                suggestionType: this.suggestionType
            }
        },
        getTableCss(css_styles){
            if(!css_styles) return;
            let returnObj = {};
            css_styles.forEach(c => {
                returnObj[c.key] = c.value;
            })
            return returnObj;
        },
        showDeletedPartHistory(pt) {
            this.$emit("showDeletedPartHistory", pt);
        },        
        multiRowPart(item){
            return (item.cardinality.maxError > 1 || item.cardinality.minError > 1 || item.cardinality.maxWarn > 1 || item.cardinality.minWarn > 1);
        },        
        async syncDocPartAttr(data, parts, attr, part, item){
            let relatedItemIdx = item.parts.findIndex(x => x.doc_part_id === part.doc_part_id);
            let relatedPartIdx = part.attributes.findIndex(x => x.dpa_id === data.dpa_id);
            if(relatedPartIdx > -1){
                part.attributes.splice(relatedPartIdx, 1, data);
                item.parts.splice(relatedItemIdx,1,parts);
                await this.updateDocPart(data, attr, item, parts);
            }
        },
        showNotes(pt, p){
            this.notesDialog = {
                original: JSON.parse(JSON.stringify(p)),
                item: pt,
                part: p,
                show: true,
                quality: parseInt(p.quality)
            };
        },
        async saveNotes(){
            await this.updateDocPart({text: this.notesDialog.original.text}, null, this.notesDialog.item, this.notesDialog.original, true);
            this.notesDialog = {
                item: null,
                part: null,
                show: false,
                quality: 0
            };            
        },
        showHint(pt, why, text) {
            this.hint.text =
                text ||
                (why
                ? pt.tmpl_part_metadata.why_text
                : pt.tmpl_part_metadata.how_to_text);
            this.hint.icon = why ? "mdi-lightbulb-outline" : "mdi-help";
            this.hint.title = why ? "Insights" : "Guidance";
            this.hint.subtitle = pt ? pt.type : "Document";
            this.hint.show = true;
        },
        openLanguageMenu(data, item, part, attr){
            let allIssues = [...data.status.errors, ...data.status.warnings];
            let issue = allIssues.find(x => x.short_description === data.element.title);
            this.languageMenu = {
                show: true,
                x: data.coords.x,
                y: data.coords.y,
                languageCode: this.data.document.spell_check_language_code,
                textStatus: data.status,
                element: issue,
                doc_id: this.data.document.doc_id,
                item: item,
                part: part,
                attr: attr,
                ...data
            }
        },
        closeLanguageMenu(){
            this.languageMenu.show = false;
        },        
        async languageOptionsUpdate(source){
            console.log(source);
        },        
        async doSpellReplace(data){
            this.closeLanguageMenu();
            await this.updateDocPart({text: data}, this.languageMenu.attr, this.languageMenu.item, this.languageMenu.part, true);
        },        
        getHighlightStyle(item, part){
            if(!this.isDragging || item.tmpl_part_metadata.contentPart || !this.data.document.state.canEditDocParts || !item.permissions_list.canEdit){
                return '';
            }

            let returnVal = '';

            if(!item.tmpl_part_metadata.exclusions.drag){
                returnVal = 'highlightDragEnd';

                if(this.dragTargetPart && (this.dragTargetPart.doc_part_id === part.doc_part_id)){
                    returnVal = 'highlightDragEndHover';
                }
            }

            return returnVal;
        },
        highlightDragTarget(item, part){
            this.dragTargetPart = {
                tmpl_part_id: item.tmpl_part_id,
                doc_part_id: part.doc_part_id,
                sequence: part.sequence
            }
        },
        async dropItem(event, item, part){
            if(!this.isDragging || item.tmpl_part_metadata.contentPart || !this.data.document.state.canEditDocParts || !item.permissions_list.canEdit){
                return;
            }

            if(!item.tmpl_part_metadata.contentPart && item.tmpl_part_metadata.exclusions.drag){
                return;
            }

            try {
                if(event.dataTransfer.types.indexOf("array") > -1){
                    let dataObj = JSON.parse(event.dataTransfer.getData("array"));
                    if (!dataObj) return;
                    await this.processDropAction(dataObj, item, part);
                } else if (event.dataTransfer.types.indexOf("object") > -1){
                    let dataObj = JSON.parse(event.dataTransfer.getData("object"));
                    if (!dataObj) return;
                    await this.processDropAction(dataObj, item, part);
                }
            } catch (ex) {
                // console.log(ex);
            }
        },
        checkLastAutoAdded(part){
            if(this.triggerAutoAdd && this.lastAddedId !== null){
                this.triggerAutoAdd = part.doc_part_id === this.lastAddedId;
                if(!this.triggerAutoAdd){
                    this.lastAddedId = null;
                    //check last added - if empty, remove?
                }
            }
        },
        cleanItems(values) {
            let replaceList = ["/r", "/n", "/r/n", "<br/>", "<br>", "• ", "· "];

            let config = this.$loginState.user.settings.find(
                (x) => x.setting === "database_drag_splitRules"
            );
            if (config) {
                let configData = JSON.parse(config.value);
                replaceList = configData.splitTags;
            }

            let cleansedItems = values
                .map((x) => {
                let item = this.stripHtml(x);
                replaceList.forEach((r) => {
                    item = item.replace(r, "");
                });
                return item.trim();
                })
                .filter((x) => x !== "");
            return cleansedItems;
        },  
        stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },             
        async processDropAction(data, item, part){

            this.checkLastAutoAdded(part);
            let multiPart = (item.cardinality.maxError > 1 || item.cardinality.minError > 1 || item.cardinality.maxWarn > 1 || item.cardinality.minWarn > 1);

            let isAttr = item.tmpl_part_metadata.attributes?.length >= 1;
            let attr = isAttr ? part.attributes[part.attributes.length-1] : null;

            if(Array.isArray(data)){
                await Promise.all(
                    data.reverse().map(async (d) => {
                        if(multiPart){
                            let empty = item.parts.find(x => x.text === "");
                            if(empty){
                                let result = await this.updateDocPart({text: d.varVal}, attr, item, empty, false);
                                return {
                                    part: empty,
                                    resp: result
                                };
                            } else {
                                return await this.addPart(part, item, undefined, d.varVal, false, false)
                            }
                        } else {
                            let result = await this.updateDocPart({text: d.varVal}, attr, item, part, false);
                            return {
                                part: part,
                                resp: result
                            };
                        }
                    })
                ).then((resps) => {
                    this.$emit("processResults", resps);
                    this.$emit("saveEnd");
                });
            } else {


                //drag & drop from DB view:
                if(multiPart){
                    let results = [];
                    let cleaned = this.cleanItems([data.text])[0];

                    if (data.html !== undefined) {
                        results = this.getSplitParts(data.html);
                    }

                    results = results.length > 1 ? results : [cleaned]

                    //let results = data.html !== undefined ? this.getSplitParts(data.html) : this.cleanItems([data.text])[0]

                    await Promise.all(
                        results.reverse().map(async (d) => {
                            if(multiPart){
                                let empty = item.parts.find(x => x.text === "");
                                if(empty){
                                    let result = await this.updateDocPart({text: d}, attr, item, empty, false);
                                    return {
                                        part: empty,
                                        resp: result
                                    };
                                } else {
                                    return await this.addPart(part, item, undefined, d, false, false)
                                }
                            } else {
                                let result = await this.updateDocPart({text: d}, attr, item, part, false);
                                return {
                                    part: part,
                                    resp: result
                                };
                            }
                        })
                    ).then((resps) => {
                        this.$emit("processResults", resps);
                        this.$emit("saveEnd");
                    });
                } else {
                    let cleaned = this.cleanItems([data.text])[0];
                    let result = await this.updateDocPart({text: cleaned}, attr, item, part, false);
                    this.$emit("processResults", [{
                        part: part,
                        resp: result
                    }]);
                    this.$emit("saveEnd");
                }

                
            }

        },
        getSplitParts(html) {
            let splitTags = ["<br/>", "<br>", "/r/n", "• ", "· "];

            let containsTags = [
                "<tr.*?><td.*?><p.*?>(.*?)</p></td></tr>",
                "<li.*?>(.*?)</li>",
                "<p.*?>(.*?)</p>",
                "<div.*?>(.*?)</div>",
            ];

            let config = this.$loginState.user.settings.find(
                (x) => x.setting === "database_drag_splitRules"
            );
            if (config) {
                let configData = JSON.parse(config.value);
                splitTags = configData.splitTags;
                containsTags = configData.containsTags;
            }

            let split = false;
            let splitResults = [];

            containsTags.forEach((c) => {
                if (!split) {
                let reg = new RegExp(c, "gim");
                let result = html.match(reg);
                if (result?.filter((x) => x !== "").length > 1) {
                    splitResults = result;
                    split = true;
                }
                }
            });

            splitTags.forEach((s) => {
                if (!split) {
                let results = html.split(s);
                if (results?.filter((x) => x !== "").length > 1) {
                    splitResults = results;
                    split = true;
                }
                }
            });

            if (splitResults.length > 0) {
                return this.cleanItems(splitResults);
            } else {
                return [];
            }
        },        
        getCssStyles(layout){
            return viewUtils.mapCssStyles(layout);
        },        
        getPartHistory(part) {
            let ph = this.data.partsHistory.find((pa) => pa.key === part.doc_part_id);
            return ph ? ph.reviewVersions : [];
        },        
        instantiateNewRow(partType, parent) {
            let currentParts = (parent ? parent.childParts : partType.parts).filter(
                (x) => x.tmpl_part_id === partType.tmpl_part_id
            );
            let sequence = currentParts.length
                ? currentParts.reduce(
                    (pval, cval) => (pval > cval.sequence ? pval : cval.sequence),
                    0
                ) + 1
                : 1;
            let ret = {
                Status: "",
                StatusList: [],
                WordCount: "",
                doc_part_id: 0,
                id: "",
                notes: "",
                isDirty: false,
                rowMessage: "",
                sequence: sequence,
                status: "",
                text: "",
                is_essential:
                partType.tmpl_part_metadata.uses_essential_flag === 2
                    ? this.tempEssentials
                    ? null
                    : 1
                    : 0,
                attributes: [],
                quality: 0,
                type: partType.type,
                tmpl_part_id: partType.tmpl_part_id,
                parent_dp_id: parent ? parent.doc_part_id : null,
                highlightSection: false,
                editing: false,
                hover: false,
                expanded: false,
                linked_content: []
            };
            if (partType.tmpl_part_metadata.attributes.length) {
                ret.attributes = partType.tmpl_part_metadata.attributes.map((a) => {
                return {
                    dpa_id: 0,
                    tpa_id: a.tpa_id,
                    text: "",
                    editing: false,
                    isDirty: true,
                    active: true,
                    cols: a.cols,
                    restrict_to_lookup: a.restrict_to_lookup,
                    placeholder: a.placeholder,
                    restrict_to_date: a.restrict_to_date,
                    locked: a.locked,
                    css_class_name: a.css_class_name,
                    allow_adding: a.allow_adding,
                    lookup_type: a.lookup_type,
                    lookup_key: a.lookup_key,
                    restrict_to_htid: a.restrict_to_htid,
                    restrict_to_ht_level: a.restrict_to_ht_level,
                    multi_select: a.multi_select,
                    multi_select_separator: a.multi_select_separator
                };
                });
            } else {
                ret.attributes.push({
                    active:true,
                    allow_adding:true,
                    cols:12,
                    css_class_name:"",
                    default_text: partType.tmpl_part_metadata.default_text ?? "",
                    editing: false,
                    isDirty: true,
                    include_in_part_val:true,
                    locked: partType.tmpl_part_metadata.tp_locked === 1,
                    lookup_dependency_tpa_source:null,
                    lookup_key:null,
                    lookup_type:null,
                    lookup_v2_tpa_destination:null,
                    lookup_v3_tpa_destination:null,
                    position:1,
                    prefix:null,
                    required:null,
                    restrict_to_date:null,
                    restrict_to_lookup:null,
                    separator:null,
                    suffix:null,
                    text: "",
                    title:null,
                    tpa_id:null,
                    restrict_to_htid: 0,
                    restrict_to_ht_level: 0
                });
            }

            ret.childParts = [];
            if (partType.childPartTypes && partType.childPartTypes.length) {
                utils.setupChildPartTypes(
                ret,
                partType,
                this.data.partsHistory,
                this.data.docHierarchies,
                this.data.wordChecks
                );
            }
            utils.setDocPartStatus(
                ret,
                partType,
                this.data.wordChecks,
                this.data.docStatus,
                this.data.document
            );

            if (parent !== undefined && parent.temp === true) {
                ret.temp = true;
            }

            return ret;
        },
        async addPart(currentPart, partType, parent, text = "", emit = true, autoAdd = true) {
            let part = this.instantiateNewRow(partType, parent);
            let curIndex = partType.parts.findIndex(
                (p) => p.doc_part_id === currentPart.doc_part_id
            );
            //let isLast = partType.parts.length === curIndex + 1;
            //partType.parts.splice(curIndex + 1, 0, part);
            //let callback = this.changeOrder;
            //console.log(isLast);
            part.is_essential =
                partType.tmpl_part_metadata.uses_essential_flag === 2
                ? null
                : part.is_essential;

            //let isAttr = partType.tmpl_part_metadata.attributes?.length >= 2;

            let obj = {
                ...part,
                text: part.text,
                doc_part_id: part.doc_part_id,
                doc_part_type: partType.type,
                doc_id: this.data.document.doc_id,
                system_number: this.data.document.system_number,
                tmpl_part_id: partType.tmpl_part_id,
                notes: null,
                parent_dp_id: parent ? parent.doc_part_id : null,
                is_essential: part.is_essential,
                quality: 0,
                sequence: part.sequence,
                insertOrder: curIndex + 1
                /*attributes: isAttr ? part.attributes
                    .filter((a) => a.isDirty)
                    .map((a) => {
                        return { tpa_id: a.tpa_id, dpa_id: a.dpa_id, text: a.text };
                    })
                : []*/
            };

            


            let resp = await this.updateDocPart({text: text}, null, partType, obj, emit);

            if(autoAdd && this.autoAddNewRow){
                this.triggerAutoAdd = true;
            }

            return {
                part: obj,
                resp: resp
            };

        },
        blur(item, attr){
            if(attr.text === "" && this.triggerAutoAdd){
                this.triggerAutoAdd = false;
                this.lastAddedId = null;
            }
        },
        rFlex() {
            this.roleFlex.show = true;
            this.roleFlex.documentId = this.data.document.doc_id.toString();
            if (this.isFlexTemplate || this.flexPartId) this.roleFlex.docPart = null;
            else
                this.roleFlex.docPart = this.docParts.find(
                (t) => t.type === "Working Pattern"
                );
        },        
        updateAttrID(attr, data, resp){
            if(attr.content_type === 'TABLE'){
                let target = attr.rows.find(x => x.sequence === data.sequence);
                target.doc_view_content_id = resp.doc_view_content_id;
            } else {
                attr.doc_view_content_id = resp.doc_view_content_id;
            }

            return attr;
        },
        async updateContentPart(data, attr, part){
            
            this.$emit("saveStart");

            let updateObj = {
                doc_id: this.data.document.doc_id,
                doc_view_content_id: data.doc_view_content_id,
                doc_part_id: data.dp_id,
                tmpl_view_section_part_id: data.tmpl_view_section_part_id,
                tmpl_view_section_id: attr.tmpl_view_section_id,
                sequence: data.sequence,
                value: data.value
            }

            if(attr.parent_id && !data.doc_part_id){
                updateObj.doc_part_id = part.doc_part_id;
            }

            await viewUtils.saveContentPart(updateObj).then((resp) => {
                if(data.doc_view_content_id === 0){
                    attr = this.updateAttrID(attr, updateObj, resp.data.data);
                }
                this.$emit("updateContentPart", resp, attr);
                this.$emit("saveEnd", resp.data.audit);
                this.triggerNotification("Content Updated","success");
            });
        },
        showPartHistory(part, type, collab){
            this.$emit("showPartHistory", part, type, !!collab);
        },
        triggerNotification(text,type){
            this.notification = {
                text:text,
                type:type
            }
        },
        async updateDocPart(data, attr, item, part, emit = true){
            this.$emit("saveStart");

            let isAttr = item.tmpl_part_metadata.attributes?.length >= 1;

            if(isAttr && attr){
                attr.isDirty = true;
                //attr.isDirty = data.text !== attr.text;
                attr.text = data.text;
                part.text = utils.attributesToText(
                    item.tmpl_part_metadata.attributes,
                    part.attributes
                );
                part.subParts = utils.partToSubParts(part.text);
            } else {
                part.text = data.text;
                part.subParts = utils.partToSubParts(part.text);
                if(part.attributes.length === 1){
                    part.attributes[0].text = data.text;
                }
            }

            let obj = {
                text: part.text,
                doc_part_id: part.doc_part_id,
                doc_part_type: item.type,
                doc_id: this.data.document.doc_id,
                system_number: this.data.document.system_number,
                tmpl_part_id: item.tmpl_part_id,
                notes: part.notes,
                parent_dp_id: part.parent_dp_id ?? null,
                is_essential: part.is_essential,
                quality: part.quality,
                sequence: part.sequence,
                attributes: isAttr ? part.attributes
                    .filter((a) => a.isDirty)
                    .map((a) => {
                        return { tpa_id: a.tpa_id, dpa_id: a.dpa_id, text: a.text };
                    })
                : []
            };
            
            let result = await viewUtils.updateDocPart(obj);

            if(this.triggerAutoAdd){
                await this.checkAutoAdd(item, part);
            }
            
            if(emit){
                this.triggerNotification(`${item.type} ${obj.doc_part_id === 0 ? 'Added':'Updated'}`, "success");
                this.$emit("updatePart", obj, part, result, item);
                this.$emit("saveEnd");
                return result;
            } else {
                return result;
            }
        },
        async checkAutoAdd(item, part){
            if(this.data.document.state.canEditDocParts && part.canAdd && item.permissions_list?.canEdit && part.text !== ""){
                let addedItem = await this.addPart(part, item);
                this.lastAddedId = Number(addedItem.resp.result.Data.newRow.doc_part_id);
            }
        },
        processResults(resp, obj) {

            //todo - push up + reset single attr + update value
            let source = this.data.document.parttypes.find(x => x.tmpl_part_id === obj.tmpl_part_id);
            let targetPart = source.parts.find(x => x.doc_part_id === obj.doc_part_id);
            let idx = source.parts.findIndex(x => x.doc_part_id === obj.doc_part_id);

            if(idx > -1){
                targetPart.text = resp.request.text;
                targetPart.subParts[0].text = resp.request.text;
            } else {
                source.parts.push(this.initialiseDocPart(obj));
            }

            source.parts = source.parts.sort((a, b) => (a.sequence > b.sequence && 1) || -1);
        },
        handleDelete(row, partType, parent, resp) {
            row.isDirty = false;
            let pts = (parent ? parent.childPartTypes : this.data.document.parttypes).find(
                (pt) => pt.type === partType.type
            );
            if (pts && pts.parts) {
                let pIndex = pts.parts.findIndex(
                (p) => p.doc_part_id === row.doc_part_id
                );
                if (pIndex >= 0) {
                // won't be present if moved
                pts.parts.splice(pIndex, 1);
                }
            }
            if (parent) {
                // clean up child list
                let cIndex = parent.childParts.findIndex(
                (p) => p.doc_part_id === row.doc_part_id
                );
                if (cIndex >= 0) parent.childParts.splice(cIndex, 1);
                if (!parent.childParts.length) {
                parent.expanded = false;
                }
            }
            partType.parts.forEach((p) => {
                utils.setDocPartStatus(
                p,
                partType,
                this.wordChecks,
                this.docStatus,
                this.document
                );
            });

            let phIndex = this.partsHistory.findIndex(
                (ph) => ph.key === row.doc_part_id
            );
            if (phIndex >= 0) {
                if (this.history && this.history.key === row.doc_part_id) {
                this.history = null;
                }
                this.partsHistory.splice(phIndex, 1);
            }
            if (resp && resp.data && resp.data.Data && resp.data.Data.audit) {
                this.partsHistory.push(resp.data.Data.audit);
                if (this.ptDeleteHistory) {
                this.ptDeleteHistory.push(resp.data.Data.audit);
                }
            }
            partType.hasDeletedParts = true;
            this.setCheckSummary();
        },        
        async deleteRow(row, partType, parent){
            this.$emit("saveStart");
            if (!partType) {
                this.data.document.parttypes.forEach((x) => {
                if (!partType && x.tmpl_part_id === row.tmpl_part_id) partType = x;
                if (!partType && x.childPartTypes) {
                    partType = x.childPartTypes.find(
                    (c) => c.tmpl_part_id === row.tmpl_part_id
                    );
                }
                });
                if (!partType) alert("partType.type missing");
            }
            let data = {
                doc_part_id: row.doc_part_id,
                doc_part_type: partType.type,
                tmpl_part_id: row.tmpl_part_id,
            };

            if (!data.doc_part_id) {
                //doc part not yet saved so just remove locally.
                this.handleDelete(row, partType, parent);
            } else {
                await viewUtils.deletePart(data).then((resp) => {
                    this.triggerNotification(`${partType.type} Deleted`, "success");
                    this.$emit("deletePart", row, partType, parent, resp);
                    this.$emit("saveEnd");
                })
            }
            /*if (!data.doc_part_id) {
                //doc part not yet saved so just remove locally.
                this.handleDelete(row, partType, parent);
            } else {
                this.isLoading = true;
                axios
                .post("document/removedocpart/", data)
                .then((resp) => {
                    if (resp.data.Status === "OK") {
                    this.handleDelete(row, partType, parent, resp);
                    } else {
                    row.rowMessage = resp.data.Message;
                    }
                    this.response = resp.data;
                    this.checkLoading();
                })
                .catch((err) => {
                    if (err.response && err.response.status === 401) {
                    this.$emit("sessionExpired", err);
                    } else {
                    console.log(err);
                    this.response = err.response
                        ? err.response.data
                        : { message: "Unexpected Error" };
                    }
                    this.checkLoading();
                });
            }*/
        },
        async updateEssential(data, part, item){

            this.$emit("saveStart");
            let obj = {
                text: part.text,
                doc_part_id: part.doc_part_id,
                doc_part_type: item.type,
                doc_id: this.data.document.doc_id,
                system_number: this.data.document.system_number,
                tmpl_part_id: item.tmpl_part_id,
                notes: null,
                parent_dp_id: null,
                is_essential: data ? 1 : 0,
                quality: 0,
                sequence: part.sequence
            };
            part.is_essential = data ? true : false;
            await viewUtils.updateDocPart(obj).then((resp) => {
                this.$emit("saveEnd", resp.result.Data.audit);
                this.triggerNotification("Essential Flag Updated","success");
                this.$emit("updatePart", obj, part, resp, item);
                //this.processResults(resp, obj);
            });
            //this.$emit("updateEssential",data, part, item);
        },
        expandPart(part) {
            part.expanded = true;
            part.childPartTypes.forEach((cpt) => {
                if (cpt.parts.length === 0) {
                   let newItem = this.instantiateNewRow(cpt, part)
                   cpt.parts.push(newItem);
                }
            });
        },
        shrinkPart(part) {
            part.expanded = false;
        },        
        canMove: function (evt) {
            // this should be the easy way but cuases endless flicker...
            //   if (
            //     evt.draggedContext.element.childParts.length > 0 &&
            //     evt.relatedContext.element &&
            //     evt.relatedContext.element.parent_dp_id
            //   )
            //     return false;
            return (
                !this.readOnly &&
                //!this.editInProgress.active &&
                evt.relatedContext.element &&
                evt.draggedContext.element.doc_part_id > 0 &&
                ((evt.draggedContext.element.canMove === true &&
                evt.draggedContext.element.tmpl_part_id ===
                    evt.relatedContext.element.tmpl_part_id) ||
                (evt.draggedContext.element.canDelete === true &&
                    evt.relatedContext.element.canAdd === true))
            );
        },   
        movePart: function (source, destParttype, parent) {
        this.ddCount = 0;
        if (this.readOnly) return;
        let destIndex = null;
        let destBlockType = "";
        let destBlockParent = "";

        if (
            source.removed &&
            source.removed.element.childParts &&
            source.removed.element.childParts.length
        ) {
            destBlockType = source.removed.element.childPartTypes[0].type;
            destBlockParent = destParttype.type;
            let parentset = this.data.document.parttypes.find(
            (x) => x.tmpl_part_id === source.removed.element.tmpl_part_id
            );
            if (parentset)
            parentset.parts.splice(
                source.removed.oldIndex,
                0,
                source.removed.element
            );
            // check removed not still in childset - will be if dropped from above but will have fired "added" first if below
            this.data.document.parttypes.forEach((pt) => {
            pt.parts.forEach((p) => {
                p.childPartTypes.forEach((cpt) => {
                let dIndex = cpt.parts.findIndex(
                    (x) => x.doc_part_id === source.removed.element.doc_part_id
                );
                if (dIndex >= 0) {
                    cpt.parts.splice(dIndex, 1);
                }
                });
            });
            });
        } else if (
            parent &&
            source.added &&
            source.added.element.childParts &&
            source.added.element.childParts.length
        ) {
            destBlockType = destParttype.type;
            let ptype = null;
            this.data.document.parttypes.forEach((x) => {
            if (
                !ptype &&
                x.parts.some((p) => p.doc_part_id === parent.doc_part_id)
            )
                ptype = x.type;
            if (
                !ptype &&
                x.parts.some((xp) =>
                xp.childPartTypes.some((xc) =>
                    xc.parts.some((xcp) => xcp.doc_part_id === parent.doc_part_id)
                )
                )
            )
                ptype = x.type;
            });
            let childset = parent.childPartTypes.find(
            (x) => x.tmpl_part_id === destParttype.tmpl_part_id
            );
            if (childset) {
            let csIndex = childset.parts.findIndex(
                (x) => x.doc_part_id === source.added.element.doc_part_id
            );
            if (csIndex >= 0) childset.parts.splice(csIndex, 1);
            }
        }
        if (destBlockType) {
            const childType = utils.pluralise(destBlockType);
            this.errorDialogue.message = `You cannot move this part to within a ${destBlockParent} as it contains ${childType}. Please remove it's ${childType} first.`;
            this.errorDialogue.show = true;
            return false;
        }

        if (source.removed) {
            // higher level dropped at lower level from "above" - need to find where dropped...
            let movedId = source.removed.element.doc_part_id;
            // find where dropped
            let dest = null;
            let destType = null;
            let destParent = null;
            this.data.document.parttypes.forEach((pt) => {
            if (!dest) {
                dest = pt.parts.find((p) => p.doc_part_id === movedId);
                if (dest) destType = pt;
            }
            if (!dest) {
                pt.parts.forEach((p) => {
                p.childPartTypes.forEach((cpt) => {
                    if (!dest) {
                    let dIndex = cpt.parts.findIndex(
                        (x) => x.doc_part_id === movedId
                    );
                    if (dIndex >= 0) {
                        dest = cpt.parts[dIndex];
                        destType = cpt;
                        destParent = p;
                        destIndex = dIndex;
                    }
                    }
                });
                });
            }
            });
            if (dest) {
            destParttype = destType;
            parent = destParent;
            } else {
            destParttype = null;
            }
            source.added = source.removed;
        }
        if (!destParttype) return; // remove fired after added in some cases

        if (source.added) {
            let newPart = JSON.parse(JSON.stringify(source.added.element));
            newPart.tmpl_part_id = destParttype.tmpl_part_id;
            newPart.type = destParttype.type;
            newPart.doc_part_id = 0;
            newPart.status = "";
            newPart.newIndex = source.added.newIndex || destIndex;
            newPart.is_essential =
            destParttype.tmpl_part_metadata.uses_essential_flag === 2 ? 1 : 0;
            newPart.quality = 0;
            newPart.parent_dp_id = parent ? parent.doc_part_id : null;
            if (destParttype.childPartTypes && destParttype.childPartTypes.length) {
            newPart.childParts = [];
            utils.setupChildPartTypes(
                newPart,
                destParttype,
                this.data.partsHistory,
                this.data.docHierarchies,
                this.data.wordChecks
            );
            }
            this.ddCount++;
            if (this.draggedParent) {
                this.deleteRow(
                    source.added.element,
                    this.draggedParent.partType,
                    this.draggedParent.part
                );
            } else {
                this.deleteRow(source.added.element);
            }
            destParttype.parts = destParttype.parts.filter(
            (x) => x.doc_part_id !== source.added.element.doc_part_id
            );
            let callback = this.changeOrder;
            this.ddCount += 2;
            this.saveRow(
            newPart,
            newPart.text,
            newPart.notes,
            null,
            newPart.is_essential,
            newPart.attributes,
            newPart.quality,
            destParttype,
            function () {
                callback(source, destParttype);
            }
            );
        } else if (source.moved) {
            this.ddCount++;
            this.$emit("changeOrder", destParttype, true);
            //this.changeOrder(source, destParttype);
        }
        },     
        focus(item,attr, part){
            this.checkLastAutoAdded(part);
            if(attr.text === "" && !this.nonSuggestionsList.some(v => item.type.toLowerCase().includes(v))){
                this.$emit("openSuggestions", item.type);
            } else {
                this.$emit("updateRecentPart", item.type);
            }
        },
        deletePart(part, parentType, parent){
            console.log(part);
            console.log(parentType);
            console.log(parent);
        },
        enableEditPart(item,part){
            this.$emit("editPart",item,part);
        },
        enableEditPartPermission(item,part){
            this.$emit("editPartPermission",item,part);
        },
        clone(item) {
            let newItem =  Object.assign({}, item);
            delete newItem.doc_part_id;
            //delete newItem.highlightedText;
            delete newItem.text;
            delete newItem.subParts;
            delete newItem.previousValue;

            newItem = {
                ...newItem,
                doc_part_id: 0,
                text: "",
                editing: false,
                highlightedText: "",
                previousValue: "",
                subParts: []
            };

            return newItem;
        },
        getPermissionInfo(x){
            x.permissions_list.canEdit = true;
            x.permissions_list.disableReason = "";

            if(this.disableEdit){
                x.permissions_list.disableReason = "Editing Disabled in Admin Mode";
                x.permissions_list.canEdit = false;
                return x;
            }

            if(x.tmpl_part_metadata.readonly === 1 || x.tmpl_part_metadata.locked || x.tmpl_part_metadata.tp_locked){
                x.permissions_list.disableReason = "Template Content Locked";
                x.permissions_list.canEdit = false;
                return x;
            }

            if(!x.tmpl_part_metadata.contentPart && !this.data.document.state.canEditDocParts || this.readOnly){
                x.permissions_list.disableReason = "No Edit Workflow Permissions";
                x.permissions_list.canEdit = false;
                return x;
            }

            if(x.tmpl_part_metadata.contentPart && x.tmpl_part_metadata.readonly === 1){
                x.permissions_list.disableReason = "Template Content Readonly";
                x.permissions_list.canEdit = false;
                return x;
            }

            let canEdit = true;

            if(this.sectionPermission){
                if(this.sectionPermission.editPermissionOverride !== null){
                    let partType = x.tmpl_part_metadata.contentPart ? "CONTENT" : "TMPL";
                    let scopeIncluded = this.sectionPermission.editScope.includes(partType);
                    let permType = this.sectionPermission.editPermissionOverride === false ? "RESTRICT" : "ALLOW";

                    if((permType === "RESTRICT" && scopeIncluded) ||(permType === "ALLOW" && !scopeIncluded)){
                        x.permissions_list.disableReason = "Section Permission Locked";
                        canEdit = false;
                    }
                }
            } else {
                if(this.lockSection && !x.tmpl_part_metadata.contentPart) {
                    x.permissions_list.disableReason = "Section Permission Locked";
                    canEdit = false;
                }
            }

            if(x.permissions_list.editPermissionOverride !== null){
                let partLocked = !x.permissions_list.editPermissionOverride;
                x.permissions_list.disableReason = partLocked ? "Part Permission Locked" : '';
                canEdit = !partLocked;
            }

            x.permissions_list.canEdit = canEdit;

            return x;
        },
        initialiseDocPart(x){
            const moveable = ["Responsibility", "Requirement", "Activity", "Indicator", "Question", "Assessment", "KPI Outcome", "Competency"];		
            let partChecks = this.getWordChecksForPart(x.tmpl_part_id, this.data.wordChecks, this.data.docHierarchies);

            x.parts.forEach(p => {
                if(p.subParts.length > 1){
                    p.text = utils.subpartsToPart(p.subParts);
                }
            })

            if(x.requireInit){
                x.parts.forEach(p => {
                    if(p.attributes.length === 0){
                        let attr = {
                            active:x.active === 1,
                            allow_adding:true,
                            cols:12,
                            css_class_name:"",
                            doc_part_id: p.doc_part_id,
                            default_text: x.tmpl_part_metadata?.default_text ?? "",
                            include_in_part_val:true,
                            locked: x.tmpl_part_metadata.tp_locked === 1,
                            lookup_dependency_tpa_source:null,
                            lookup_key:null,
                            lookup_type:null,
                            lookup_v2_tpa_destination:null,
                            lookup_v3_tpa_destination:null,
                            linked_parts: [],
                            position:1,
                            prefix:null,
                            required:null,
                            restrict_to_date:null,
                            restrict_to_lookup:null,
                            separator:null,
                            suffix:null,
                            title:null,
                            tpa_id:null,
                            tmpl_part_id:x.tmpl_part_id,
                            text: p.text,
                            is_essential: p.is_essential,
                            previousValue: p.text,
                            isLookup: false,
                            isDependentLookup: false,
                            view_dependencies:[]
                        };
        
                        p.attributes.push(attr);
                    }

                    p.childParts = p.childParts.map(cp => {
                        let part = x.childPartTypes.find(x => x.tmpl_part_id === cp.tmpl_part_id)
                        return {
                            ...cp,
                            tmpl_part_metadata: {
                                ...part.tmpl_part_metadata,
                                canMove: moveable.indexOf(part.type) >= 0 || this.multiRowPart(x),
                            },
                            active: true,
                            locked: x.tmpl_part_metadata.tp_locked === 1
                        }
                    })

                    p.linked_content = [];
                    p.canMove = moveable.indexOf(x.type) >= 0 || this.multiRowPart(x);
                })
            }

            x.tmpl_part_metadata = {
                ...x.tmpl_part_metadata,
                canMove: moveable.indexOf(x.type) >= 0 || this.multiRowPart(x),
                view_dependencies:[]
            }

            if(x.tmpl_part_metadata.attributes.length > 0){
                //attr parts
                x.parts = x.parts?.map(p => {
                    return {
                        ...p,
                        attributes: p.attributes?.map(a => {
                            let lookup = false;
                            let dlookup = false;
                            let sourceData = x.tmpl_part_metadata.attributes.find(ta => ta.tpa_id === a.tpa_id);
                            if(sourceData){
                                lookup = sourceData.isLookup;
                                dlookup = sourceData.isDependentLookup;
                            }
                            return {
                                ...a,
                                isLookup: lookup,
                                isDependentLookup: dlookup,
                                linked_parts: sourceData.linked_parts ?? []
                            }
                        })
                    }
                })
            }

            if(x.tmpl_part_metadata.linked_content.length > 0){
                x.tmpl_part_metadata.linked_content = x.tmpl_part_metadata.linked_content.map(lc => {
                    return {
                        ...this.getPermissionInfo(lc)
                    };
                })

                x.parts.forEach(p => {
                    p.hover = false;
                    p.linked_content = p.linked_content.map(lc => {
                        let lcType = x.tmpl_part_metadata.linked_content.find(pt => pt.tmpl_view_section_part_id === lc.tmpl_view_section_part_id);
                        return {
                            ...lc,
                            permissions_list: lcType.permissions_list
                        }
                    });


                 });

            }

            return {
                ...this.getPermissionInfo(x),
                wordChecks: partChecks
            };
        },
        initialiseContentPart(x){  
            
            if(x.tmpl_part_metadata.content_type === 'GROUP'){
                console.log("setup group");
                x.tmpl_part_metadata.content_metadata.rows.forEach(r => {
                    r.cells.forEach(c => {
                        if(c.cell_content){
                            c.cell_content = this.initialiseDocPart(c.cell_content);
                        }
                    })
                })
            }

            return {
                ...this.getPermissionInfo(x)
            };
        },
        initialisePart(x){
            switch(x.tmpl_part_metadata.part_type){
                case "docPart":
                    return this.initialiseDocPart(x);
                case "contentPart":
                    return this.initialiseContentPart(x);
            }
        },
        init(parts){
            this.disablePartEdit = this.disableEdit;
            this.docParts = [];
            if(parts.length){
                let initparts = parts.map(x => {
                    return this.initialisePart(x);
                })
                this.docParts = initparts;
            }
        },
        getWordChecksForPart(tpid, checks, hchys) {
            let tempChecks = checks.map(object => ({ ...object }))
            let filtered = tempChecks.filter((w) =>
                w.tmpl_part_types.some(
                (tp) =>
                    tp.tp_id === tpid &&
                    (!tp.exclusions.length ||
                    !hchys.some((h) =>
                        tp.exclusions.some((e) => e.hr_id === h.hr_id)
                    ))
                )
            );
            return utils.initialisePartChecks(filtered);
        },           
        doubleClickTrigger(item){
            if(item.editing){
                return;
            }

            //let partChecks = this.getWordChecksForPart(item.tmpl_part_id, null, doc.docHierarchies, doc.tmpl_id);
            //item.wordChecks = partChecks;
            
            //item.previousValue = item.text;
            item.editing = !item.editing;
            /*if(this.isComparing && item.compareHtml !== ""){
                item.compareHtml = "";
            }      
            this.refreshDocs();*/
        },
    }
}
</script>

<style scoped lang="scss">

    .viewPartHolder{

        * { 
            font-family: "Manrope", sans-serif !important;
        }
    
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;  
        color:#75838F;
        width:100%;

        .viewPartRow{
            height: auto;
            min-height: 25px;   
            margin-bottom: 5px;
            $gutterWidth: 65px;
            $contentWidth: 100px;
            $actionWidth: 130px;
            $adminActionWidth: var(--admin-actions-offset);
            $mainContentInnerPaddingRight: 10px;
            border: solid 1px transparent !important;
            border-radius: 4px;
            box-sizing: border-box;

            .viewPartItem{
                width: 100%;
                display:flex;
                border-radius: 4px;
                border: solid 1px transparent !important;
                padding:5px;

                &.tmplPart:hover{
                    border: solid 1px rgba(138, 210, 252, 0.5) !important;
                }                  
            }

            .highlightedScorePart {
                background-color: lightgray;
            }
            
            .viewPartRowGutter{
                width: $gutterWidth;
                position: absolute;
                left: 0px;
                display: flex;
                align-items: flex-start;
                padding-top: 3px;
            }

            .viewPartRowContent{
                //width: 100%;
                height: auto;
                min-height: 25px;   
                width: calc(100% - #{$gutterWidth} - #{$actionWidth} - #{$adminActionWidth} - #{$mainContentInnerPaddingRight});
                position: relative;
                margin-left: $gutterWidth;
                margin-right: calc(#{$actionWidth} + #{$adminActionWidth} + #{$mainContentInnerPaddingRight});
                display:inline-flex;

                &.contentPart{
                    width: calc(100% - #{$gutterWidth} - #{$adminActionWidth} - #{$mainContentInnerPaddingRight}) !important;
                    margin-right: calc(#{$adminActionWidth} + #{$mainContentInnerPaddingRight} + 40px) !important;
                }

                &.central{
                    width: 100%;
                    text-align:center;   
                    margin-left: 0px;
                    margin-right: 0px;
                    justify-content: center!important;
                }

                &.bold {
                    font-style: normal;
                    font-weight: bolder !important;
                    line-height: 24px !important;  
                    color:#3E3E3E !important;
                }

                &.header{
                    font-size: 22px;
                    line-height: 32px;
                }

                &.disabled{
                    text-decoration: line-through;
                    color: #00000026 !important;
                }                
            }

            .viewPartRowActions{
                width: $actionWidth;
                position: absolute;
                right: $adminActionWidth;
                display: flex;
                align-items: flex-start;
                margin-top: 4px;
                height: 25px;
            }

            .viewPartRowAdminActions{
                width: $adminActionWidth;
                position: absolute;
                right: 3px;
                display: flex;
                align-items: flex-start;
                margin-top: 4px;
                height: 25px;
            }

            .viewPartChildren{

                .childPartTypeHolder{

                    padding-top:15px;

                    .childPartRow{

                        height: auto;
                        min-height: 25px;   
                        margin-bottom: 5px;
                        $gutterWidth: 65px;
                        $contentWidth: 100px;
                        $actionWidth: 100px;
                        $adminActionWidth: var(--admin-actions-offset);
                        $mainContentInnerPaddingRight: 10px;
                        border: solid 1px transparent !important;
                        border-radius: 4px;
                        box-sizing: border-box;
                        padding: 0px;


                        .childPartItem{

                            display: flex;
                            width: 100%;
                            border-radius: 4px;
                            border: solid 1px transparent !important;
                            padding:5px;

                            &.tmplPart:hover{
                                border: solid 1px rgba(138, 210, 252, 0.5) !important;
                            }       

                            .childPartRowGutter{
                                width: $gutterWidth;
                                position: absolute;
                                left: 0px;
                                display: flex;
                                align-items: flex-start;
                                padding-top: 3px;
                            }

                            .childPartRowContent{
                                height: auto;
                                min-height: 25px;   
                                width: calc(100% - #{$gutterWidth} - #{$actionWidth} - #{$adminActionWidth} - #{$mainContentInnerPaddingRight});
                                position: relative;
                                margin-left: $gutterWidth;
                                margin-right: calc(#{$actionWidth} + #{$adminActionWidth} + #{$mainContentInnerPaddingRight});
                                display:inline-flex;
                                border: solid 1px transparent !important;
                                border-radius: 4px;
                            }

                            .childPartRowActions{
                                width: $actionWidth;
                                position: absolute;
                                right: $adminActionWidth;
                                display: flex;
                                align-items: flex-start;
                                margin-top: 4px;
                                height: 25px;
                            }

                            .childPartRowAdminActions {
                                width: $adminActionWidth;
                                position: absolute;
                                right: 3px;
                                display: flex;
                                align-items: flex-start;
                                margin-top: 4px;
                                height: 25px;
                            }
                        }
                    }
                }

            }

            .viewPartLinked{
                padding-top: 10px;

                .viewPartLinkedAdminActions {
                    width: $adminActionWidth;
                    position: absolute;
                    right: 3px;
                    display: inline-flex;
                    align-items: flex-start;
                    margin-top: 4px;
                    height: 25px;
                    padding-top: 10px;
                }
            }
        }
    }


    * {
        font-family: "Manrope" !important;
    }

    .dragHandle {
        font-size: 12px !important; 
        display:inline-flex; 
        padding-right:10px;
        cursor:move;
        top: 6px;
        align-items: flex-start !important;        
    }

    .docPartContainer {
        margin-bottom:10px;
    }

    .contentHolder{
        border: solid 1px transparent !important;
        border-radius: 4px;
        box-sizing: border-box;
        margin: 0px;
        height: auto;
        min-height: 25px;   
        display: inline-flex;
        width: 100%;  
    }

    .partHolder{
        border: solid 1px transparent !important;
        border-radius: 4px;
        box-sizing: border-box;
        margin: 0px;
        height: auto;
        min-height: 25px;   
        margin-bottom: 5px;   
        display: block;
        width: 100%;
    }

    .childPartTextHolder{
        width: 100%;
        display: inline-flex; 
        border: solid 1px transparent !important;
        border-radius: 4px;
        padding: 5px 0px 5px 0px;
        padding-left: 50px;

        &:hover {
            border: solid 1px rgba(151, 151, 151, 0.5) !important;
        }

        $gutterWidth: 50px;
        $actionsWidth: 50px;
        $adminActionsWidth: var(--admin-actions-offset);
        $contentWidth: calc(100% - #{$gutterWidth} - #{$actionsWidth});

        .childPartTextHolderGutter{
            width: $gutterWidth; 
            display:inline-flex;
        }

        .childPartTextHolderMain {
            display:inline-flex; 
            width: $contentWidth;
        }

        .childPartTextHolderActions {
            width: $actionsWidth;
            position: relative;
            right: var(--admin-actions-offset);
            top: 4px;
        }

        .childPartTextHolderAdminActions {
            width:20px; 
            position:relative;
            top: 4px;
        }        
    }

    .partTextHolder.tmplPart:hover{
        border: solid 1px rgba(151, 151, 151, 0.5) !important;
    }

    .partTextHolder {
        width: 100%;
        display: inline-flex; 
        border-radius: 4px;
        padding: 5px 0px 5px 0px;
        border: solid 1px transparent !important;

        $gutterWidth: 65px;
        $actionsWidth: 50px;
        $adminActionsWidth: var(--admin-actions-offset);
        $contentWidth: calc(100% - #{$gutterWidth} - #{$actionsWidth});

        &.contentPart{
            padding-top:10px;
        }

        .partTextHolderGutter{
            width: $gutterWidth; 
            display:inline-flex;
        }

        .partTextHolderMain {
            display:inline-flex; 
            width: $contentWidth;
        }

        .partTextHolderActions {
            width: $actionsWidth;
            position: relative;
            right: var(--admin-actions-offset);
            top: 4px;
        }

        .partTextHolderAdminActions {
            width:20px; 
            position:relative;
            top: 4px;
        }

    }

    .docContent { 
        padding-right: 3px;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
        border: 1px solid #4299e1;
    }   
    
    ::v-deep .lookupItem {
        cursor: pointer;
        &:hover,
        &.selected {
            &.theme--light {
            background-color: #eee;
            }
            &.theme--dark {
            background-color: #333;
            }
        }
        span {
            font-size: 14px;
            line-height: 1.5;
        }
        mark {
            color: inherit;
            border-radius: 5px;
            background-color: rgba(#0372ee, 0.3);
        }
    }  
    
    table {
    border:1px solid #ccc;
}
table td {
    padding:15px;
    border:1px solid #3a3838;
}
table td.selected {
    background-color:#aea8ff;
}





    .groupTable { 
        border: 1px solid;
        border-color: var(--table-border-color);
        border-collapse: collapse;
        border-left: 0;
        border-radius: 4px;
        border-spacing: 0px;

        thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
            border-collapse: collapse;
            background-color: var(--headrow-background-color);
        }
        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
            background-color: var(--row-background-color);
        }
        th, td {
            padding: 5px 4px 6px 4px; 
            text-align: left;
            vertical-align: top;
            border-left: 1px solid;
            border-color: var(--table-border-color);    
        }
        td {
            border-top: 1px solid;    
            border-color: var(--table-border-color);   
        }
        thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
            border-radius: 4px 0 0 0;
        }
        thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
            border-radius: 0 0 0 4px;
        }


    }



</style>