<template>
    <div>
        <div v-if="tab === 'list' && !displayView" class="pa-4">
            <v-btn small @click="openLookupAdmin" color="primary" outlined class="mr-5">
                Lookups
            </v-btn>            
            <v-row dense class="mt-3 pb-3">
                <v-col class="d-flex align-center">
                    <h1 class="title">Document View Configuration</h1>
                </v-col>
            </v-row>             
            <v-row v-if="viewList.length > 0" dense>
                <v-col cols="3">
                    <v-card width="100%" class="pa-4">
                    <v-simple-table dense>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="py-2"><h2>Document View</h2></th>
                                <th class="py-2">
                                    <h2>Actions</h2>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="t in viewList">
                                <tr :key="t.template_type">
                                    <td width="70%"><h3>{{ t.template_type }}</h3></td>
                                    <td width="30%">
                                        <v-btn
                                            icon
                                            :title="`Add New ${t.template_type} View`"
                                            @click="addNewView(t.tmpl_id)"
                                        >
                                            <v-icon>add_circle_outline</v-icon>
                                        </v-btn>
                                        <v-btn icon :title="`Edit ${t.template_type} View Permissions`" @click="editTemplatePermissions(t.tmpl_id)">
                                            <v-icon>settings</v-icon>
                                        </v-btn>                                          
                                    </td>
                                </tr>
                                <tr v-for="v in t.views" :key="v.tmpl_view_id">
                                    <td width="25%" style="padding-left:50px">{{ v.view_name }}</td>
                                    <td>
                                        <v-btn icon :title="`Configure View: ${v.view_name}`" @click="configureView(t.tmpl_id, v.tmpl_view_id)">
                                            <v-icon>edit</v-icon>
                                        </v-btn>                                      
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        </template>
                    </v-simple-table>
                    </v-card>
                </v-col>
                <v-col cols="9" v-if="workflowConfig">
                    <v-card width="100%" class="pa-4">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>                           
                                <tr>
                                    <th width="50%" class="py-2">
                                        <h2>Selected Workflow:</h2> 
                                    </th>
                                    <th width="50%" class="py-2">
                                        <v-select
                                            dense
                                            class="slimSelect"
                                            bg-color="transparent"
                                            item-text="name"
                                            item-value="id"
                                            v-model="editingWorkflow"
                                            :items="workflows"
                                            @change="changeWorkflow(workflowConfig.tmpl_id)"
                                        >
                                            <template slot="label">
                                                <span style="font-size:11px; background-color:transparent">Select Workflow</span>
                                            </template>
                                            <template slot="selection" slot-scope="data">
                                                <span style="font-size:11px; background-color:transparent">{{ data.item.name }}</span>
                                            </template>  
                                            <template slot="item" slot-scope="data">
                                                <span style="font-size:11px;">{{ data.item.name }}</span>
                                            </template> 
                                        </v-select>
                                    </th>                                
                                </tr>
                                <tr>
                                    <th width="50%" class="py-2"><h2>Default View:</h2></th>
                                    <th width="50%" class="py-2">
                                        <v-select 
                                            :items="workflowConfig.views"
                                            item-text="view_name"
                                            item-value="tmpl_view_id"
                                            clearable
                                            v-model="workflowConfig.default_view.tmpl_view_id"
                                            @change="setDefaultView"
                                        >
                                        </v-select>
                                    </th>
                                </tr>
                                <tr>
                                    <th width="100%" colspan="2" class="py-2"><h2>Detailed Permission View</h2></th>
                                </tr>  
                                                          
                            </thead>
                            </template>
                        </v-simple-table>
                        <v-simple-table dense style="width: 100%">
                            <thead>
                            <tr>
                                <th width="15%" style="border-right: 1px solid #dfdfdf;">Role Group:</th>
                                <th style="text-align: center;" :width="`${(100-15)/workflowConfig.statuses.length}%`" v-for="status in workflowConfig.statuses" :key="status.lifecycle_status_id">
                                    <v-chip
                                        class="mx-2 status-chip"
                                        label
                                        small
                                        outlined
                                        :color="status.colour"
                                    >
                                        {{status.name}}
                                    </v-chip>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="role in workflowConfig.roles"
                                :key="role.lifecycle_role_id"
                            >
                                <td width="15%"  style="border-right: 1px solid #dfdfdf;">
                                    <v-chip outlined label color="primary" small>{{role.name}}</v-chip>
                                </td>

                                <td style="text-align: center;" :width="`${(100-15)/workflowConfig.statuses.length}%`" v-for="status in workflowConfig.statuses" :key="status.lifecycle_status_id">
                                    <template>
                                    <v-btn
                                        icon
                                        v-if="permissionExists(status.lifecycle_status_id, role.lifecycle_role_id)"
                                        @click="editPermission(status.lifecycle_status_id, role.lifecycle_role_id)"
                                    >
                                        <v-icon>settings</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        style="opacity:0.2"
                                        @click="addPermission(status.lifecycle_status_id, role.lifecycle_role_id)"
                                        v-else
                                    >
                                        <v-icon>add_circle_outline</v-icon>
                                    </v-btn>
                                    </template>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
            
        </div>
        <div v-if="tab === 'config' && displayView" style="height:calc(100vh - 60px); max-height:calc(100vh - 60px)">
            <div style="height:60px; display:inline-flex" class="pa-3">
                <v-btn @click="closeEdit" color="primary" outlined class="mr-5">
                    <v-icon left>mdi-arrow-left</v-icon>Back
                </v-btn>
                <v-text-field class="ml-5 slimText" dense v-model="currentView.view_name" append-outer-icon="mdi-content-save" @click:append-outer="saveViewName"></v-text-field>
                <v-switch small class="ml-8 mr-4 slimText" label="Preview?" v-model="viewAsPreview" @change="togglePreviewMode"></v-switch>
                <div style="display:inline-flex" v-if="viewAsPreview">
                    <v-select dense style="width:200px" clearable solo class="ml-2 slimSelect" bg-color="transparent" item-text="name" item-value="id" :items="tmplDetails"  @change="loadWorkflows" v-model="previewConfig.lifecycle_id">
                        <template slot="label">
                            <span style="font-size:11px; background-color:transparent">Select Workflow</span>
                        </template>
                    </v-select>
                    <v-select dense style="width:200px" clearable solo v-if="previewConfig.lifecycle_id > 0 && workflowConfig" class="ml-2 slimSelect" bg-color="transparent" item-value="lifecycle_status_id" :items="workflowConfig.statuses" v-model="previewConfig.lifecycle_status_id">
                        <template slot="label">
                            <span style="font-size:11px; background-color:transparent">Select Status</span>
                        </template>
                        <template slot="selection" slot-scope="data">
                            <span style="font-size:11px;">
                                <v-chip
                                    class="status-chip"
                                    label
                                    small
                                    outlined
                                    :color="data.item.colour"
                                >
                                    {{data.item.name}}
                                </v-chip>    
                            </span>
                        </template>  
                        <template slot="item" slot-scope="data">
                            <span style="font-size:11px;">
                                <v-chip
                                    class="status-chip"
                                    label
                                    small
                                    outlined
                                    :color="data.item.colour"
                                >
                                    {{data.item.name}}
                                </v-chip>    
                            </span>
                        </template> 
                    </v-select>
                    <v-select dense style="width:200px" clearable solo v-if="previewConfig.lifecycle_status_id > 0 && workflowConfig" class="ml-2 slimSelect" bg-color="transparent" item-value="lifecycle_role_id" :items="workflowConfig.roles" v-model="previewConfig.lifecycle_role_id">
                        <template slot="label">
                            <span style="font-size:11px; background-color:transparent">Select Role</span>
                        </template>
                        <template slot="selection" slot-scope="data">
                            <span style="font-size:11px;">
                                <v-chip
                                    class="status-chip"
                                    label
                                    small
                                    outlined
                                    color="primary"
                                >
                                    {{data.item.name}}
                                </v-chip>    
                            </span>
                        </template>  
                        <template slot="item" slot-scope="data">
                            <span style="font-size:11px;">
                                <v-chip
                                    class="status-chip"
                                    label
                                    small
                                    outlined
                                    color="primary"
                                >
                                    {{data.item.name}}
                                </v-chip>    
                            </span>
                        </template> 
                    </v-select>
                    <v-select dense style="width:200px" clearable solo v-if="previewConfig.lifecycle_status_id > 0 && workflowConfig && !docList.length > 0" class="ml-2 slimSelect" bg-color="transparent"  item-text="system_number" item-value="system_number" :items="docItemsByType" v-model="previewConfig.previewDoc">
                        <template slot="label">
                            <span style="font-size:11px; background-color:transparent">Select Preview Doc:</span>
                        </template>
                    </v-select>
                    <v-btn color="primary" @click="testPreview" v-if="previewConfig.lifecycle_id &&previewConfig.lifecycle_status_id && previewConfig.lifecycle_role_id" class="ml-5">Load Preview</v-btn>
                </div>
                
            </div>
            <DocumentHolder 
                v-if="displayView"
                ref="docHolder"
                :displayMethod="'full'"
                :data="docViewTemplate"
                :disableEdit=true
                :rightPanelContent="'permissions'"
                :paddingOffset="'60px'"
                :adminMode=true
                :adminConfig="tmplDetails"
                @editSection="editSectionContent"
                @addSection="addSection"
                @addSubSection="addSubSection"
                @editSectionOrder="editSectionOrder"
            ></DocumentHolder>
        </div>
        <v-dialog v-model="editViewDialog.show" max-width="900px">
            <v-card>
                <v-card-title class="d-flex align-center">
                    Add New View
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="6">Name:</v-col>
                        <v-col cols="6">
                            <v-text-field v-model="editViewDialog.view.view_name" dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn @click="editViewDialog.show = false">
                        Cancel
                    </v-btn>
                    <v-btn @click="saveView" color="primarylight">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addSectionDialog.show" max-width="900px">
            <v-card>
                <v-card-title class="d-flex align-center">
                    Add New {{ addSectionDialog.section.parent_section ? 'Sub ' : '' }} Section
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="6">Section Name:</v-col>
                        <v-col cols="6">
                            <v-text-field v-model="addSectionDialog.section.section" dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn @click="addSectionDialog.show = false">
                        Cancel
                    </v-btn>
                    <v-btn @click="addSectionToView" color="primarylight">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>        
        <v-dialog v-model="editPermissionDialog.show" max-width="1200px">
            <v-card>
                <v-card-title class="d-flex align-center">
                    {{ editPermissionDialog.permission.tmpl_view_permissions_id === 0 ? 'Add' : 'Edit' }} Permission
                </v-card-title>
                <v-card-text>
                    <v-row dense style="padding-top:15px">
                        <v-chip
                            class="mx-2 status-chip"
                            label
                            small
                            outlined
                            :color="editPermissionDialog.permission.role.colour"
                        >
                            Role Group: {{ editPermissionDialog.permission.role.name }}
                        </v-chip> 
                        <v-icon>mdi-chevron-double-right</v-icon>
                        <v-chip
                            class="mx-2 status-chip"
                            label
                            small
                            outlined
                            :color="editPermissionDialog.permission.status.colour"
                        >
                            Doc Status: {{editPermissionDialog.permission.status.name}}
                        </v-chip>
                    </v-row>
                    <br/>
                    <v-row dense>
                        <v-col cols="12"><i>Default Document View:</i></v-col>
                        <v-col>
                            <v-select 
                                :items="editPermissionDialog.permission.views"
                                dense
                                item-text="view_name"
                                item-value="tmpl_view_id"
                                v-model="editPermissionDialog.permission.tmpl_view_id"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense style="padding-left:0px">
                        <v-col>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Hierarchy Type: <v-icon @click="addHierarchyPermissionRow" class="ml-4" style="cursor:pointer" small color="primary">mdi-plus-circle</v-icon></th>
                                        <th>Level 1</th>
                                        <th>Level 2</th>
                                        <th>Level 3</th>
                                        <th>Level 4</th>
                                        <th>Level 5</th>
                                        <th>Level 6</th>
                                        <th>View</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item,idx) in editPermissionDialog.permission.hierarchyPermissions"
                                        :key="idx"
                                    >
                                        <td>
                                            <v-select 
                                                :items="hierarchies" 
                                                dense
                                                item-text="ht_name"
                                                item-value="ht_id"
                                                v-model="item.ht_id"
                                            >
                                            </v-select>
                                        </td>
                                        <td><v-text-field dense v-model="item.level1"></v-text-field></td>
                                        <td><v-text-field dense v-model="item.level2"></v-text-field></td>
                                        <td><v-text-field dense v-model="item.level3"></v-text-field></td>
                                        <td><v-text-field dense v-model="item.level4"></v-text-field></td>
                                        <td><v-text-field dense v-model="item.level5"></v-text-field></td>
                                        <td><v-text-field dense v-model="item.level6"></v-text-field></td>
                                        <td>
                                            <v-select 
                                                :items="workflowConfig.views"
                                                dense
                                                item-text="view_name"
                                                item-value="tmpl_view_id"
                                                v-model="item.tmpl_view_id"
                                            ></v-select>    
                                        </td>
                                        <td>
                                            <v-icon>mdi-delete</v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>                        
                    </v-row>                    
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn @click="editPermissionDialog.show = false">Cancel</v-btn>                    
                    <v-btn color="primarylight" @click="savePermission">Save</v-btn>                    
                    <v-btn color="error" @click="deletePermission">Delete</v-btn>
                </v-card-actions>
            </v-card>
      </v-dialog>
      <v-dialog v-model="lookups.show" max-width="1200px" max-height="600px">
            <v-card>
                <v-card-title class="d-flex align-center">
                    Edit Lookups
                </v-card-title>
                <v-card-text>
                    <LookupAdmin />
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn @click="lookups.show = false">Close</v-btn>  
                </v-card-actions>
            </v-card>
      </v-dialog>      
      <Notification :notification="notification"/>
      <Loading :isVisible="viewLoading" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import viewUtils from "./utils";
import utils from "@/common/utils.js";
import DocumentHolder from "@/components/document/Document_Holder.vue";
import moment from "moment";
import Notification from "@/components/common/SnackBar.vue";
import LookupAdmin from '@/components/common/LookupAdmin';

export default {
    name: 'ViewAdmin',
    props: {
        leftPanelContent: String,
        rightPanelContent: String,
    },  
    data: function() {
        return {
            document: null,
            docStatus: this.statuses,
            docHierarchies: null,
            partsHistory: null,
            wordChecks: null,
            previewDoc: false,
            tab: "list",
            viewList: [],
            editingTemplate: null,
            workflows: [],
            editingWorkflow: 0,
            workflowConfig: null,
            currentView: {
                tmpl_view_id:0,
                tmpl_id: 0,
                view_name: ""
            },
            editViewDialog:{
                show:false,
                view: {
                    tmpl_view_id:0,
                    tmpl_id: 0,
                    view_name: ""
                }
            },
            lookups: {
                show:false
            },
            editPermissionDialog: {
                show:false,
                permission: {
                    status: {
                        name: ""
                    },
                    role: {
                        name: ""
                    },
                    lifecycle_id: 0,
                    lifecycle_role_id: 0,
                    lifecycle_status_id: 0,
                    permission_active: 1,
                    priority_order: 1,
                    tmpl_view_id: 0,
                    tmpl_view_permissions_id: 0
                }
            },
            notification: {
                text:"",
                type:"success"
            },
            editSection: null,
            docViewTemplate: {},
            addSectionDialog: {
                show:false,
                section: {
                    data:[],
                    footer_type: null,
                    header_type: null,
                    hover: false,
                    parent_section: null,
                    parent_section_name: null,
                    part_data: [],
                    section:"",
                    sub_sections:[],
                    tmpl_id:0,
                    tmpl_name:"",
                    tmpl_view_id: 0,
                    tmpl_view_section_id: 0,
                    view_name: ""
                }
            },
            tmplDetails: null,
            viewAsPreview: false,
            previewConfig: {
                lifecycle_id: 0,
                lifecycle_status_id:0,
                lifecycle_status_role_id:0,
                doc_id:""
            },
            displayView: false,
            viewLoading: true
        }
    },
    components: {
        DocumentHolder, Notification, LookupAdmin
    },
    watch: {

    },    
    computed: {
        ...mapState({
            newUi: (state) => state.theme.newUi,
            docStatusSummary: (state) => state.docs.docStatusSummary,
            docTypeSummary: (state) => state.docs.docTypeSummary,
            hierarchiesLoading: (state) => state.hierarchies.loading,
            docTypes: (state) => state.hierarchies.docTypes,
            docListColumns: (state) => state.settings.config.docListColumns,
            lifecycleStatuses: (state) => state.hierarchies.lifecycleStatuses,
            statuses: (state) => state.hierarchies.statuses,
            docItems: (state) => state.docs.docsList,
            hierarchies: (state) => state.hierarchies.hierarchies,
        }),
        docItemsByType(){
            return this.docItems.filter(x => x.tmpl_id === this.currentView.tmpl_id);
        }
    },
    mounted() {

    },
    created() {
        this.init();
    },    
    methods: { 
        openLookupAdmin(){
            this.lookups.show = true;
        },
        getRecentDocs(){
            this.recentDocs = utils.getRecentDocs(this.$loginState);
        },
        updateWorkflowConfig(data){
            this.workflowConfig = {
                ...this.workflowConfig,
                default_view: data ?? {
                    tmpl_view_id: null
                }
            };
            this.reload();
            this.triggerNotification(data === null ? "Default View Deleted" : "Default View Saved", "success");
        },
        async setDefaultView(tmpl_view_id){
            if(tmpl_view_id){
                let obj = {
                    lifecycle_id: this.workflowConfig.lifecycle_id,
                    tmpl_id: this.workflowConfig.tmpl_id,
                    tmpl_default: 1,
                    lifecycle_status_id: 0,
                    lifecycle_role_id: 0,
                    permission_active: 1,
                    priority_order: 1,
                    tmpl_view_id: tmpl_view_id,
                    tmpl_view_permissions_id: this.workflowConfig.default_view?.tmpl_view_permissions_id ?? null
                }
                await viewUtils.saveDefaultView(obj).then((resp) => {
                    this.updateWorkflowConfig(resp.data);
                })
            } else {
                let obj = {
                    lifecycle_id: this.workflowConfig.lifecycle_id,
                    tmpl_id: this.workflowConfig.tmpl_id,
                    tmpl_view_permissions_id: this.workflowConfig.default_view?.tmpl_view_permissions_id ?? null
                }
                await viewUtils.deleteDefaultView(obj).then(() => {
                    this.updateWorkflowConfig(null);
                })
            }

        },
        addHierarchyPermissionRow(){
            this.editPermissionDialog.permission.hierarchyPermissions.push({})
        },        
        async togglePreviewMode(){
            if(!this.viewAsPreview){
                this.docViewTemplate = null;

                let req = {
                    tmpl_id: this.currentView.tmpl_id,
                    tmpl_view_id: this.currentView.tmpl_view_id,
                    previewDoc: this.previewConfig.previewDoc,
                    status_id: 0,
                    role_id: 0
                }

                await viewUtils.loadSettings(req).then((resp) => {
                    this.setupDocumentDisplay(resp.data);
                })
            }
        },
        async testPreview(){

            this.docViewTemplate = null;

            let req = {
                tmpl_id: this.currentView.tmpl_id,
                tmpl_view_id: this.currentView.tmpl_view_id,
                previewDoc: this.previewConfig.previewDoc,
                status_id: this.previewConfig.lifecycle_status_id,
                role_id: this.previewConfig.lifecycle_role_id
            }

            await viewUtils.loadSettings(req).then((resp) => {
                this.setupDocumentDisplay(resp.data);
            })
        },
        async loadWorkflows(id){
            await this.loadWorkflowData(this.currentView.tmpl_id, id);
        },
        sectionObj(){
            return {
                data:[],
                footer_type: null,
                header_type: null,
                hover: false,
                parent_section: null,
                parent_section_name: null,
                part_data: [],
                section:"",
                sub_sections:[],
                tmpl_id:0,
                tmpl_name:"",
                tmpl_view_id: 0,
                tmpl_view_section_id: 0,
                view_name: "",
                enable_complete_subsections: false,
                complete_all_target_section_id: null,
                view_mode: null,
                order:1,
                complete_all_label: null, 
                view_linked_content: false, 
                submit_action_id: null,
                lhs_expanded: 1,
                lhs_expandable: 1,
                rhs_expanded: 1,
                rhs_expandable: 1,
                permissions_list: {
                    editPermissionOverride: false,
					viewPermissionOverride: false,					
					restrictSection: false,
                    displayPermissionOverride: null,
					permissions: [],
					hierarchyPermissions: [],
					permissionControlled: false,
					hrPermissionControlled: false,
                    valueControlled: false,
                    view_dependencies: []
                }
            }
        },
        cloneSection(item) {
            let newItem =  Object.assign({}, item);

            let currentConfig = this.document;

            newItem = {
                ...newItem,
                section:"",
                sub_sections:[],
                tmpl_id: currentConfig.tmpl_id,
                tmpl_name: currentConfig.doc_type,
                tmpl_view_id: currentConfig.doc_view_config.tmpl_view_id,
                tmpl_view_section_id: 0,
                view_name: currentConfig.doc_view_config.view_name,
                part_data: []             
            };

            return newItem;
        },        
        saveViewName(){
            this.triggerNotification("View Updated", "success");
        },
        addNewView(tmpl){
            this.editViewDialog.view.tmpl_id = tmpl;
            this.editViewDialog.show = true;
        },
        addViewToList(){
            let type = this.viewList.find(x => x.tmpl_id === this.editViewDialog.view.tmpl_id);
            let newView = {
                sections: [],
                tmpl_view_id: this.editViewDialog.view.tmpl_view_id,
                view_name: this.editViewDialog.view.view_name,
                tmpl_id: this.editViewDialog.view.tmpl_id
            }
            type.views.push(newView);
            this.triggerNotification("View Created", "success");
            this.editViewDialog.show = false;
        },
        /*addNewSection(parentId){
            let newSection = this.sectionObj();
            let flattenedSections = viewUtils.getFlatSections(this.document.doc_view_config.sections);
            let order = 0;
            if(parentId){
                let subSections = flattenedSections.filter(x => x.parent_section === parentId);
                order = subSections.length ? Math.max(...subSections.map(x => x.order)) : 0;
                newSection.parent_section = parentId;
            } else {
                order = Math.max(...flattenedSections.filter(x => !x.parent_section).map(x => x.order))
            }
            newSection.order = order + 1;
            this.addSectionDialog.show = true;
            this.addSectionDialog.section = newSection;
        },*/
        addSection(){
            let newSection = this.sectionObj();
            let flattenedSections = viewUtils.getFlatSections(this.document.doc_view_config.sections);
            let order = Math.max(...flattenedSections.filter(x => !x.parent_section).map(x => x.order));
            newSection.order = order + 1;
            this.addSectionDialog.show = true;
            this.addSectionDialog.section = newSection;
        },
        addSubSection(parentId){
            let newSection = this.sectionObj();
            let flattenedSections = viewUtils.getFlatSections(this.document.doc_view_config.sections);
            let subSections = flattenedSections.filter(x => x.parent_section === parentId);
            let order = subSections.length ? Math.max(...subSections.map(x => x.order)) : 0;
            newSection.parent_section = parentId;
            newSection.order = order + 1;
            this.addSectionDialog.show = true;
            this.addSectionDialog.section = newSection;
        },
        editSectionOrder(){

        },
        async addSectionToView(){
            let data = {
                ...this.addSectionDialog.section,
                tmpl_view_id: this.currentView.tmpl_view_id
            }
            await viewUtils.docViewSaveSection(data).then((resp) => {
                let newSection = resp.data;
                if(newSection.parent_section){
                    let parent = this.document.doc_view_config.sections.find(x => x.tmpl_view_section_id === newSection.parent_section);
                    if(parent){
                        parent.sub_sections.push(newSection);
                        this.triggerNotification("Sub Section Created", "success");
                    }
                } else {
                    this.document.doc_view_config.sections.push(newSection); 
                    this.triggerNotification("Section Created", "success");
                }
                this.docViewTemplate = {
                    document: this.document,
                    docStatus: this.docStatus,
                    docHierarchies: this.docHierarchies,
                    partsHistory: this.document.DPHistory,
                    wordChecks: this.wordChecks,
                    template: this.templateDef
                }
                this.addSectionDialog.show = false;
            })
        },
        async saveView(){
            let data = {
                name: this.editViewDialog.view.view_name,
                tmpl_id: this.editViewDialog.view.tmpl_id
            }

            await viewUtils.saveView(data).then((resp) => {
                this.editViewDialog.view.tmpl_view_id = resp.data.Data.tmpl_view_id;
                this.addViewToList();
                //this.workflowConfig.permissions.push(this.editPermissionDialog.permission);
            })
        },
        triggerNotification(text,type){
            this.notification = {
                text:text,
                type:type
            }
        }, 
        removePermFromView(){
            let idx = this.workflowConfig.permissions.findIndex(x => x.tmpl_view_permissions_id === this.editPermissionDialog.permission.tmpl_view_permissions_id);
            if(idx > -1){
                this.workflowConfig.permissions.splice(idx,1);
                this.triggerNotification("Permission Deleted", "success");
                this.editPermissionDialog.show = false;
            }
        },
        updatePermInView(){
            let idx = this.workflowConfig.permissions.findIndex(x => x.tmpl_view_permissions_id === this.editPermissionDialog.permission.tmpl_view_permissions_id);
            if(idx > -1){
                this.workflowConfig.permissions[idx] = this.editPermissionDialog.permission;
                this.triggerNotification("Permission Updated", "success");
                this.editPermissionDialog.show = false;
            }
        },
        async deletePermission() {
            if(this.editPermissionDialog.permission.tmpl_view_permissions_id > 0){
                await viewUtils.deletePermission(this.editPermissionDialog.permission.tmpl_view_permissions_id).then(() => {
                    this.removePermFromView();
                })
            }
        },     
        addPermission(status_id,role_id){
            let status = this.workflowConfig.statuses.find(x => x.lifecycle_status_id === status_id);
            let role = this.workflowConfig.roles.find(x => x.lifecycle_role_id === role_id);
            let views = this.workflowConfig.views;
            this.editPermissionDialog.permission = {
                status: status,
                role: role,
                permission_active:1,
                lifecycle_role_id: role_id,
                lifecycle_status_id: status_id,
                views: views ?? [],
                tmpl_view_permissions_id:0,
                tmpl_id: this.workflowConfig.tmpl_id,
                hierarchyPermissions: []
            }
            this.editPermissionDialog.show = true;
        },
        async savePermission(){
            this.editPermissionDialog.show = false

            let hrPerms = [];

            if(this.editPermissionDialog.permission.hierarchyPermissions.length > 0){
                this.editPermissionDialog.permission.hierarchyPermissions.forEach(p => {
                    let newObj = {
                        tmpl_view_id: p.tmpl_view_id,
                        ht_id: p.ht_id,
                        permission_type: "VIEW",
                        permission_value: "ALLOW",
                        permission_active: true,       
                        hierarchy_node_id: null,
                        tmpl_view_permissions_id: this.editPermissionDialog.permission.tmpl_view_permissions_id           
                    };

                    [1, 2, 3, 4, 5, 6].forEach((l) => {
                        newObj["level" + l] = p["level" + l] ?? null;
                    });

                    hrPerms.push(newObj);
                })
            }

            let data = {
                lifecycle_id: this.workflowConfig.lifecycle_id, 
                lifecycle_status_id: this.editPermissionDialog.permission.lifecycle_status_id, 
                lifecycle_role_id: this.editPermissionDialog.permission.lifecycle_role_id, 
                tmpl_view_id: this.editPermissionDialog.permission.tmpl_view_id,
                tmpl_view_permissions_id: this.editPermissionDialog.permission.tmpl_view_permissions_id,
                tmpl_id: this.editPermissionDialog.permission.tmpl_id,
                hierarchyPermissions: hrPerms
            }

            await viewUtils.savePermission(data).then((resp) => {
                if(this.editPermissionDialog.permission.tmpl_view_permissions_id){
                    this.updatePermInView();
                } else {
                    this.editPermissionDialog.permission.tmpl_view_permissions_id = resp.data.Data.tmpl_view_permissions_id;
                    this.workflowConfig.permissions.push(this.editPermissionDialog.permission);
                    this.triggerNotification("Permission Added", "success");
                }

            })

        },
        reload(){
            this.workflowConfig = JSON.parse(JSON.stringify(this.workflowConfig));
        },
        changeWorkflow(tmpl_id){
            this.loadWorkflowData(tmpl_id, this.editingWorkflow);
        },
        editPermission(status, role){
            let perm = this.workflowConfig.permissions.find(x => x.lifecycle_role_id === role && x.lifecycle_status_id === status && x.permission_active);
            if(perm){
                let status = this.workflowConfig.statuses.find(x => x.lifecycle_status_id === perm.lifecycle_status_id);
                let role = this.workflowConfig.roles.find(x => x.lifecycle_role_id === perm.lifecycle_role_id);
                let views = this.workflowConfig.views;
                let hrPerms = this.workflowConfig.hierarchyPermissions.filter(x => x.tmpl_view_permissions_id === perm.tmpl_view_permissions_id)
                this.editPermissionDialog.permission = {
                    ...perm,
                    status: status,
                    role: role,
                    views: views ?? [],
                    hierarchyPermissions: hrPerms
                };
                this.editPermissionDialog.show = true;
            }
        },
        permissionExists(status, role){
            let perm = this.workflowConfig.permissions.find(x => x.lifecycle_role_id === role && x.lifecycle_status_id === status && x.permission_active);
            return perm ? true : false;
        },
        async loadWorkflowData(tmpl, id){
            await viewUtils.loadWorkflowData(id).then((resp) => {
                this.editingWorkflow = id;
                let statuses = resp.data.Data.lifecycle[0].statuses.filter(x => x.lifecycle_status_id).map(x => {
                    return {
                        name: x.name,
                        colour: x.colour,
                        lifecycle_status_id: x.lifecycle_status_id
                    }
                });
                let roles = resp.data.Data.lifecycle[0].roles;
                let permissions = this.configData.permissions.find(x => x.lifecycle_id === resp.data.Data.lifecycle[0].lifecycle_id);
                let default_view = permissions?.default_view;
                let views = this.viewList.find(x => x.tmpl_id === tmpl);
                this.workflowConfig = {
                    statuses: statuses,
                    roles: roles,
                    permissions: permissions?.permissions.filter(p => p.tmpl_id === tmpl) ?? [],
                    views: views?.views ?? [],
                    lifecycle_id: resp.data.Data.lifecycle[0].lifecycle_id,
                    tmpl_id: tmpl,
                    hierarchyPermissions: views?.hierarchyPermissions,
                    default_view: default_view ?? {
                        tmpl_view_id: null
                    }
                }
            });
        },
        async editTemplatePermissions(tmpl){
            let workFlowDoc = this.$loginState.user.createDocumentOptions.find(x => x.usesWorkflow === 1 && x.tmpl_id === tmpl);
            if(workFlowDoc){
                //this.editingTemplate = id.view_name;
                let workFlowList = [
                    ...workFlowDoc.lifecycles?.map(x => {
                        return {
                            name: x.lifecycle_name,
                            id: x.lifecycle_id
                        }
                    }),
                    ...workFlowDoc.lifecyclesHT?.map(x => {
                        return {
                            name: x.lifecycle_name,
                            id: x.lifecycle_id
                        }
                    })
                ];

                this.workflows = workFlowList;
                if(workFlowList.length){
                    this.loadWorkflowData(tmpl, workFlowList[0].id);
                }
            }
        },
        editSectionContent(target){
            var sections = target.split("|").filter(x => x !== '');
            let targetSection = this.currentView.sections.find(x => x.tmpl_view_section_id === Number(sections[0]));
            if(sections[1]){
                targetSection = targetSection.sub_sections.find(x => x.tmpl_view_section_id ===  Number(sections[1]));
            }
            
            this.editSection = targetSection
        },
        configureView(tmpl, id){
            this.viewLoading = true;
            this.initDoc(this.configData,tmpl, id, this.recentDocs[0].id);
        },
        closeEdit(){
            this.tab = "list";
            this.displayView = false;
        },
        initDocItem(item){
            return {
                ...item,
                pinned: false,
                locked: false,
                lockedByPermission: !item.state.canEditDocParts,
                visible: true,
                isUpdating: false,
                previousValue: null,
                created_date: moment(item.created_date).format("DD MMM YYYY"),
            }
        },      
        setupTemplateDisplay(resp){
            const data = resp.templates;
            //const header = data.header;
            let parts = data.parts;
            const partTypes = data.partTypes;
            //const lookupTypes = data.lookupTypes;
            parts.forEach((p) => {
              p.partType = partTypes.find((pt) => pt.pt_id === p.pt_id);
              const layoutOpts = p.layout.split("|");
              p.showLabel = layoutOpts[0] === "InnerGutter";
              p.format = layoutOpts.length > 1 ? layoutOpts[1] : "";
              p.otherLayoutFlags = layoutOpts.length > 2 ? layoutOpts.slice(2, layoutOpts.length) : [];
            });
            this.document = {
                parts,
                doc_view_config: {...resp.views}
            };
            this.docHierarchies = [];
            this.wordChecks = [];  
            this.document = JSON.parse(JSON.stringify(this.document));
        },      
        setupDocumentDisplay(resp){

            let data = resp;
            let checks = data.checks.map(object => ({ ...object }));
            let setup = utils.setupDocument([data.document], checks, this.statuses, true);
            //
                this.document = {
                    ...setup.document,
                    doc_view_config: {...resp.views}
                };
                this.docHierarchies = setup.docHierarchies;
                this.wordChecks = checks;  
                this.document = JSON.parse(JSON.stringify(this.document));      
                this.templateDef = resp.template;          
            //
            this.currentView = resp.views;
            this.docViewTemplate = {
                document: this.document,
                docStatus: this.docStatus,
                docHierarchies: this.docHierarchies,
                partsHistory: this.document.DPHistory,
                wordChecks: this.wordChecks,
                template: this.templateDef
            }

            this.displayView = true;
            this.tab = "config";
            this.viewLoading = false;
            
            //this.setConfigMode();
        },
        async initDoc(data, tmpl, targetViewId, previewDoc){
  
            this.configData = data;
            if(targetViewId){
                let tmplType =  data.views.find(x => x.tmpl_id === tmpl);
                let id = tmplType.views.find(x => x.tmpl_view_id === targetViewId);
                this.currentView = id;

                //get template
                let req = {
                    tmpl_id: tmpl,
                    tmpl_view_id: targetViewId,
                    previewDoc: previewDoc
                }

                let workFlowDoc = this.$loginState.user.createDocumentOptions.find(x => x.usesWorkflow === 1 && x.tmpl_id === tmpl);
                if(workFlowDoc){
                    //this.editingTemplate = id.view_name;
                    let workFlowList = [
                        ...workFlowDoc.lifecycles?.map(x => {
                            return {
                                name: x.lifecycle_name,
                                id: x.lifecycle_id
                            }
                        }),
                        ...workFlowDoc.lifecyclesHT?.map(x => {
                            return {
                                name: x.lifecycle_name,
                                id: x.lifecycle_id
                            }
                        })
                    ];

                    this.tmplDetails = workFlowList;
                }
                

                await viewUtils.loadSettings(req).then((resp) => {
                    previewDoc ? this.setupDocumentDisplay(resp.data) : this.setupTemplateDisplay(resp.data);
                })
            }
        },
        setupAllDocTypes(data){
            let docTypes = data.views;
            let ignore = ["Interview Template", "Flex Apply"];
            this.$loginState.user.createDocumentOptions.filter(x => x.usesWorkflow && !ignore.includes(x.tmpl_name)).forEach(d => {
                if(!docTypes.some(x => x.template_type === d.tmpl_name)){
                    let workFlowList = [...d.lifecycles, ...d.lifecyclesHT].map(x => {
                        return {
                            name: x.lifecycle_name,
                            id: x.lifecycle_id
                        }
                    });                    
                    docTypes.push({
                        template_type: d.tmpl_name,
                        tmpl_id: d.tmpl_id,
                        views: [],
                        workflows: workFlowList
                    })
                }
            })
            this.viewList = docTypes.sort((a, b) => a.template_type - b.template_type);
        },
        async init(tmpl, targetViewId, previewDoc){

            this.viewLoading = true;
            let data = {
                tmpl_view_id: targetViewId ?? 0,
                tmpl_id: tmpl ?? 0,
                previewDoc: previewDoc ?? null
            }
            this.getRecentDocs();

            await viewUtils.loadSettings(data).then(async (resp) => {
                //this.currentView = resp.data.views[0];
                this.setupAllDocTypes(resp.data);
                await this.initDoc(resp.data, tmpl, targetViewId);
                this.viewLoading = false;
            });
        }
    }
}
</script>
<style scoped lang="scss">


.slimText{

    font-family: "Manrope" !important;
    font-size: 11px !important;
    line-height: 22px !important;
    min-height: 35px !important;
    max-height: 35px !important;
}

.slimSelect{
    font-family: "Manrope" !important;
    padding-left: 5px;
    font-size:11px; 
    min-height: 30px !important;
    max-height: 30px !important;

    .v-input__control .v-input__slot {
        min-height:30px;    
    }     
}
</style>