<template>
  <v-app :class="'uitheme-' + uiTheme">
    <HeaderOld
      v-if="$loginState.isAuthenticated && !newUi"
      :recentDocs="recentDocs"
      :qaActions="myQaActions"
      @addDocument="addDocument"
      @openDocument="openDocument"
      @openUserSettings="userSettings = true"
      @showHints="showHints"
      @logout="logout"
    />
    <HeaderNew
      v-if="$loginState.isAuthenticated && newUi"
      :recentDocs="recentDocs"
      :qaActions="myQaActions"
      @addDocument="addDocument"
      @openDocument="openDocument"
      @openUserSettings="userSettings = true"
      @showHints="showHints"
      @impersonate="impersonate"
      @impersonateRevert="impersonateRevert"
      @sessionExpired="sessionExpired"
      @logout="logout"
      @handleGatewayEvent="handleGatewayEvent"
    />
    <v-main class="mainContent">
      <v-alert
        v-model="dataPermissionNeeded"
        color="pink"
        elevation="2"
        type="warning"
      >
        <b style="font-size: 20px"
          >We require your permission to hold your user data (email and name
          only) and to use cookies.</b
        ><br />

        To the extent that our processing of your User Personal Information is
        subject to certain international laws, RoleMapper is required to notify
        you about the legal basis on which we process User Personal Information.
        <span class="ml-4"
          >If you continue using this website or dismiss this notice you will be
          providing your consent to our use of your data and cookies. Otherwise,
          please browse away from the website.</span
        >
        <v-btn style="float: right" class="ml-2 mt-2" @click="updateUser"
          >Accept</v-btn
        >
        <v-btn style="float: right" class="ml-2 mt-2" @click="showCookieDialog"
          >More Information</v-btn
        >
      </v-alert>
      <router-view
        v-if="$loginState.user && !permissionsReloading"
        :qaActions="myQaActions"
        @sessionExpired="sessionExpired"
        @openDocument="openDocument"
        @copyDocument="copyDocument"
        @documentCreated="documentCreated"
        @addDocument="addDocument"
        @documentChangedHandler="documentChangedHandler"
        @impersonate="impersonate"
      />
    </v-main>
    <Login
      :doLogin="doLogin"
      :reEnterPassword="$loginState.reEnterPassword"
      :setPasswordToken="setPasswordToken"
      :setPasswordType="setPasswordType"
      @LoggedIn="processLogin"
      @Cancelled="cancelLogin"
    />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <v-dialog
      v-model="showDocument"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      no-click-animation
    >
      <DocumentFullNEW
        v-if="
          $loginState.user &&
          $loginState.user.settings.some(
            (s) =>
              s.setting === 'document_viewer' &&
              s.value.indexOf('docFull') === 0
          )
        "
        :documentId="selectedDocumentId"
        :viewAction="selectedDocumentViewAction"
        :isCopied="docCopied"
        :isCopiedLink="docCopiedLink"
        :isNew="isNewDocument"
        :useInline="
          $loginState.user.settings.some(
            (s) => s.setting === 'document_viewer' && s.value === 'docFull'
          )
        "
        :viewSource="viewSourceDoc"
        :openFlex="openFlex"
        @copyDocument="copyDocument"
        @openDocument="openDocument"
        @close="closeDocument"
        @closeDocument="closeDocument"
        @documentHeaderSaved="documentHeaderSaved"
        @sessionExpired="sessionExpired"
        @documentLoadError="documentLoadError"
      ></DocumentFullNEW>
      <DocumentFull
        v-else
        :documentId="selectedDocumentId"
        :viewAction="selectedDocumentViewAction"
        :isCopied="docCopied"
        @copyDocument="copyDocument"
        @openDocument="openDocument"
        @close="closeDocument"
        @closeDocument="closeDocument"
        @documentHeaderSaved="documentHeaderSaved"
        @sessionExpired="sessionExpired"
      ></DocumentFull>
    </v-dialog>
    <v-dialog
      v-model="showExternalDocument"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <DocumentExternal
        :documentId="selectedDocumentId"
        :viewAction="selectedDocumentViewAction"
        :isCopied="docCopied"
        @copyDocument="copyDocument"
        @openDocument="openDocument"
        @close="closeExternalDocument"
        @closeExternalDocument="closeExternalDocument"
        @documentHeaderSaved="documentHeaderSaved"
        @sessionExpired="sessionExpired"
      ></DocumentExternal>
    </v-dialog>
    <v-dialog
      v-model="showCookies"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <Cookies
        @closeCookies="closeCookies"
        @sessionExpired="sessionExpired"
      ></Cookies>
    </v-dialog>
    <v-dialog
      v-model="showAddDocument"
      :fullscreen="!newUi"
      transition="dialog-bottom-transition"
    >
      <DocumentAdd
        v-if="!newUi"
        :open="showAddDocument"
        @closed="showAddDocument = false"
        @created="documentCreated"
        @sessionExpired="sessionExpired"
        @openDocument="openDocument"
      ></DocumentAdd>
      <DocumentAddNew
        v-else
        :targetDoc="defaultDoc"
        :open="showAddDocument"
        @closed="showAddDocument = false"
        @created="documentCreated"
        @sessionExpired="sessionExpired"
        @openDocument="openDocument"
        @copyDocument="copyDocument"
      ></DocumentAddNew>
    </v-dialog>

    <Loading :isVisible="isLoading" />
    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showErrorSnack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      v-model="userSettings"
      right
      temporary
      fixed
      hide-overlay
      width="360"
    >
      <UserSettings
        v-if="userSettings"
        :qaActions="myQaActions"
        @closed="userSettings = false"
        @themeChange="applyTheme"
      />
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import Login from "@/components/cLogin"; // @ is an alias to /src
import axios from "axios";
import utils from "@/common/utils.js";
import HeaderOld from "@/components/cHeaderOld.vue";
import HeaderNew from "@/components/cHeaderNew.vue";
import DocumentFullNEW from "@/components/cDocumentFullNEW";
import DocumentFull from "@/components/cDocumentFull";
import DocumentAdd from "@/components/cDocumentAdd";
import DocumentAddNew from "@/components/cDocumentAddNew";
import DocumentExternal from "@/components/cDocumentExternal";
import UserSettings from "@/components/cUserSettings";
import ResponseHandler from "@/components/ResponseHandler";
import Cookies from "@/components/cCookies";
import EnjoyHint from "xbs-enjoyhint/src/enjoyhint";
import { mapState } from "vuex";

export default {
  props: {
    source: String,
  },
  components: {
    Login,
    ResponseHandler,
    HeaderOld,
    HeaderNew,
    DocumentFullNEW,
    DocumentFull,
    DocumentAdd,
    DocumentAddNew,
    UserSettings,
    Cookies,
    DocumentExternal,
  },
  data: () => ({
    response: null,
    stateRefreshTimeout: null,
    refreshInProgress: false,
    selectedUserIdx: 1,
    doLogin: false,
    setPasswordToken: null,
    setPasswordType: null,
    isLoading: false,
    dialog: false,
    drawer: null,
    showUserSettings: false,
    userSettings: null,
    qaActions: [],
    selectedDocumentId: null,
    selectedDocumentViewAction: null,
    showDocument: false,
    openFlex: false,
    showExternalDocument: false,
    docCopied: false,
    docCopiedLink: false,
    showAddDocument: false,
    isNewDocument: false,
    refDocument: null,
    recentDocs: [],
    showCookies: false,
    dataPermissionNeeded: false,
    user_data: {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      business_area: "",
      data_permission: 1,
    },
    errorText: "",
    showErrorSnack: false,
    snackColor: "error",
    snackTimeout: 4000,
    keyDownHandler: null,
    defaultDoc: "",
    permissionsReloading: false,
    getTasksTimeout: null,
    viewSourceDoc: false,
  }),
  created() {
    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = process.env.VUE_APP_API_BASE_URL;
        if (this.$loginState.rmapiAccessToken && this.$loginState.rmapiAccessToken !== "undefined") {
          config.headers["Authorization"] = "Bearer " + this.$loginState.rmapiAccessToken;
        }
        const tenant = localStorage.getItem("rmtenant");
        if (tenant && tenant !== "undefined") {
          config.headers["RMTenant"] = tenant;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        if (response.data && response.data.access_token) {
          this.$loginState.rmapiAccessToken = response.data.access_token;
          document.title =
            (response.data.user && response.data.user.client
              ? response.data.user.client_display_name + " "
              : "") + "Role Mapper";
          this.setStateRefreshTimer(response.data);
        }
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 400:
              //do something
              break;

            case 401:
              if (
                originalRequest._retry ||
                originalRequest.url.includes("auth/loginstate")
              ) {
                this.reloadRoot();
              } else if (originalRequest.url !== "auth/login/") {
                //refresh access token and retry
                console.log("refreshing token before retry");
                await this.refreshLoginState();
                originalRequest._retry = true;
                return axios(originalRequest);
              }
              break;
            case 403:
              console.log(`${originalRequest.url} - forbidden`);
              break;
            case 404:
              alert("page not exist");
              break;
            case 500:
              if (error.response.config?.url?.includes("auth/loginstate")) {
                console.log(error);
              } else {
                alert(error.response.statusText);
              }
              break;
            case 502:
              setTimeout(() => {
                this.$router.replace({
                  name: "default",
                  query: {
                    redirect: this.$router.currentRoute.fullPath,
                  },
                });
              }, 1000);
          }
        }
        return Promise.reject({ ...error });
      }
    );
  },
  mounted: function () {
    this.getAppState();
  },
  watch: {
    showDocument(open) {
      this.handleFullScreenDialog(open);
    },
    docsInitialised(val) {
      if (!val && this.$loginState.isAuthenticated) this.logout();
    },
    "$route.params.user"(user) {
      if (user != this.selectedUserIdx) this.refreshLoginState();
    },
  },
  computed: {
    myQaActions: function () {
      return this.qaActions
        .filter((a) => (a.meta && this.newUi ? a.meta.newUi : a.meta.oldUi))
        .sort((a, b) => {
          const asec = a.meta.section || "Unknown",
            bsec = b.meta.section || "Unknown";
          return asec === bsec ? 0 : asec < bsec ? 1 : -1;
        });
    },
    ...mapState({
      screenName: (state) => state.header.screenName,
      uiTheme: (state) => state.theme.name,
      newUi: (state) => state.theme.newUi,
      docsInitialised: (state) => state.docs.initialised,
    }),
  },
  methods: {
    registerGatewayListeners() {
      //this.$eventGateway.$on("document-translate-create", this.translateComplete);
      //this.$eventGateway.$on("document-translate-update", this.translateUpdate);
    },
    connectionEvent() {
      //alert(this.$eventGateway.status());
    },
    handleGatewayEvent(event) {
      switch (event) {
        case "connect":
          this.connectToGateway();
          break;
      }
    },
    connectToGateway() {
      this.useGateway = this.$loginState.user.settings.some(
        (s) => s.setting === "use_event_gateway" && s.value === "true"
      );
      if (this.useGateway) {
        this.$eventGateway.$on("connected", () => {
          this.registerGatewayListeners();
        });
        //alert(this.$eventGateway.connectionState);
        //this.$eventGateway.send("join",{ "roomName":"BulkDocCreationRoom" });
        this.$eventGateway.connect(this.$loginState.user.name);
      }
    },
    reloadRoot() {
      if (
        this.$router.currentRoute &&
        this.$router.currentRoute.fullPath !== "/" &&
        this.$router.currentRoute.fullPath !== "/sso/"
      ) {
        this.$router.replace({
          path: localStorage.getItem("rmsso") === "1" ? "/sso/" : "/",
        });
        location.reload();
      }
    },
    handleFullScreenDialog(hide) {
      //ensure we don't end up with double scrollbars when fullscreen dialog is open
      if (hide) {
        document.documentElement.classList.add("noscroll");
        window.addEventListener("keydown", this.handlePageUpDown);
      } else {
        document.documentElement.classList.remove("noscroll");
        window.removeEventListener("keydown", this.handlePageUpDown);
      }
    },
    handlePageUpDown(e) {
      if (
        document.activeElement &&
        (document.activeElement.tagName === "BODY" ||
          document.activeElement.classList.contains(
            "v-dialog__content--active"
          ))
      ) {
        switch (e.code) {
          case "PageDown":
            this.scrollDialog(600);
            break;
          case "PageUp":
            this.scrollDialog(-600);
            break;
          case "ArrowDown":
            this.scrollDialog(60);
            break;
          case "ArrowUp":
            this.scrollDialog(-60);
            break;
        }
      }
    },
    scrollDialog(y) {
      const dialog = document.getElementsByClassName(
        "v-dialog v-dialog--active"
      );
      if (dialog.length) {
        dialog[0].scrollBy({
          top: y,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    documentChangedHandler(f) {
      this.notifyDocumentChange = f;
    },
    sessionExpired(err) {
      console.log("sessionExpired: " + err);
      this.$loginState.reEnterPassword = true;
      this.showLogin();
    },
    cancelLogin() {
      this.$loginState.isAuthenticated = false;
      this.$loginState.user = null;
      this.setAvailableActions();
    },
    setStateRefreshTimer(data) {
      if (data.token_expiry) {
        if (this.stateRefreshTimeout) {
          clearTimeout(this.stateRefreshTimeout);
          this.stateRefreshTimeout = undefined;
        }
        //refresh token 10 secs before it expires
        const expTimeFromNow =
          new Date(data.token_expiry) - Date.now() - 10 * 1000;
        if (expTimeFromNow > 0) {
          this.stateRefreshTimeout = setTimeout(
            () => this.refreshLoginState(),
            expTimeFromNow
          );
        }
      }
    },
    async refreshLoginState() {
      if (!this.refreshInProgress) {
        this.refreshInProgress = true;
        let possibleError = false;
        const path = location.hash.split("/").filter((p) => p && p !== "#");
        let impersonatingUserId;
        if (path.length > 1 && path[0] === "u" && path[1] && !isNaN(path[1])) {
          const selectedUserIdx = Number(path[1]);
          const impersonateUsers =
            JSON.parse(localStorage.getItem("rmimpusers")) || [];
          if (
            Array.isArray(impersonateUsers) &&
            impersonateUsers.length >= selectedUserIdx
          ) {
            impersonatingUserId = impersonateUsers[selectedUserIdx - 1];
            this.selectedUserIdx = selectedUserIdx;
          } else {
            this.selectedUserIdx = 1;
          }
        }

        await axios
          .post("auth/loginstate/", {
            impersonatingUserId: impersonatingUserId,
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              if (
                !this.$loginState.isAuthenticated ||
                !this.$loginState.user ||
                this.$loginState.user.user_id !== resp.data.user.user_id ||
                this.qaActions.length === 0
              ) {
                this.permissionsReloading = true;
                this.unloadClient();
                this.processLogin(resp.data);
                setTimeout(() => {
                  this.permissionsReloading = false;
                }, 50);
              } else {
                this.validateCurrentRoute();
              }
            } else {
              this.permissionsReloading = false;
              this.errMessage = resp.data.message;
              this.logout();
              this.showLogin();
            }
            this.isLoading = false;
            this.refreshInProgress = false;
          })
          .catch((err) => {
            this.refreshInProgress = false;
            this.$loginState.isAuthenticated = false;
            this.isLoading = false;
            this.errMessage = err;
            if (possibleError) {
              alert("JS Error: " + err);
            } else if (err.response && err.response.status === 401) {
              this.logout();
              this.showLogin();
            }
          });
      } else {
        while (this.refreshInProgress) {
          await new Promise((resolve) => setTimeout(resolve, 50));
        }
      }
    },
    unloadClient() {
      this.$store.dispatch("docs/unload");
      this.$store.dispatch("hierarchies/unload");
      this.$store.dispatch("tasks/unload");
      this.$store.dispatch("settings/unload");
      this.closeDocument();
      this.$router.clearClientRoutes();
    },
    processLogin(data) {
      const user = data.user;
      this.setPasswordToken = "";
      this.$loginState.isAuthenticated = true;
      this.$loginState.setUser(user);

      let impersonateUsers =
        JSON.parse(localStorage.getItem("rmimpusers")) || [];
      if (!Array.isArray(impersonateUsers)) impersonateUsers = [];
      if (!impersonateUsers.includes(user.user_id))
        impersonateUsers.push(user.user_id);
      this.selectedUserIdx = impersonateUsers.indexOf(user.user_id) + 1;
      localStorage.setItem("rmimpusers", JSON.stringify(impersonateUsers));

      this.$support.identify();
      this.applyTheme(this.$loginState.getUserSetting("ui_theme"));

      if (
        !this.$loginState.user.data_permission &&
        !this.$loginState.impersonating
      ) {
        this.dataPermissionNeeded = true;
      }

      localStorage.setItem("rmsso", user.sso);
      this.$loginState.sso = user.sso === "1";
      this.errMessage = "";
      this.doLogin = false;
      this.setAvailableActions();
      this.recentDocs = utils.getRecentDocs(this.$loginState);
      this.$store.dispatch("docs/init", this.$loginState);
      this.$store.dispatch("hierarchies/init");
      this.$store.dispatch("tasks/init");
      this.$store.dispatch("settings/init");
      if (this.$loginState.isInternal) this.$store.dispatch("customers/init");
      this.checkForRedirectLink();
    },
    updateUser() {
      this.$loginState.user.data_permission = 1;
      this.user_data = {
        first_name: this.$loginState.user.first_name,
        last_name: this.$loginState.user.last_name,
        email: this.$loginState.user.email,
        username: this.$loginState.user.username,
        password: this.$loginState.user.password,
        business_area: this.$loginState.user.business_area,
        data_permission: this.$loginState.user.data_permission,
        client_id: this.$loginState.user.client_id,
        user_id: this.$loginState.user.permissions[0].user_id,
      };
      axios
        .post("user/update/", this.user_data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.dataPermissionNeeded = false;
            this.$forceUpdate();
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    extractFromUrl() {
      let hashUrl = false;
      let path = location.pathname.split("/").filter((p) => p);
      if (path.length === 0) {
        //prob hash
        hashUrl = true;
        path = location.hash.split("#/").filter((p) => p);
      }
      let path1 = path[0];

      let searchParams = location.search.split("=");

      if (hashUrl && path1 !== undefined) {
        let tempSearch = path1.split("?");
        if (tempSearch.length == 2) {
          searchParams = tempSearch[1].split("=");
        }
        path[0] = tempSearch[0];
        path1 = path[0];
      }

      if (
        path.length === 1 &&
        ["viewnotification", "viewtask"].indexOf(path1) >= 0
      ) {
        if (searchParams.length === 2) {
          return Number(searchParams[1]);
        }
      }

      if (path.length === 1 && ["viewdoc"].indexOf(path1.toLowerCase()) >= 0) {
        if (searchParams.length === 2) {
          return searchParams[1];
        }
      }
    },
    impersonate(request) {
      this.isLoading = true;
      this.permissionsReloading = true;
      let possibleError = false;

      axios
        .post("auth/impersonate/", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.unloadClient();
            this.processLogin(resp.data);
          }
          this.isLoading = false;
          this.permissionsReloading = false;
        })
        .catch((err) => {
          this.errMessage = err;
          this.isLoading = false;
          this.permissionsReloading = false;
          if (possibleError) {
            alert("JS Error: " + err);
          } else {
            alert("Server Error: " + err.response?.statusText);
            console.log(err);
          }
        });
    },
    impersonateRevert() {
      this.isLoading = true;
      this.permissionsReloading = true;
      let possibleError = false;

      axios
        .post("auth/impersonateRevert/")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.unloadClient();
            if (
              resp.data.user.client_type === "INTERNAL" &&
              this.$route.name !== "default"
            )
              this.$router.replace({ name: "default" });
            this.processLogin(resp.data);
          }
          this.isLoading = false;
          this.permissionsReloading = false;
        })
        .catch((err) => {
          this.errMessage = err;
          this.isLoading = false;
          this.permissionsReloading = false;
          if (possibleError) {
            alert("JS Error: " + err);
          } else {
            alert("Server Error: " + err.response?.statusText);
            console.log(err);
          }
        });
    },
    getAppState() {
      //check for password reset
      this.setPasswordToken = null;
      this.setPasswordType = null;
      let hashUrl = false;
      let path = location.pathname.split("/").filter((p) => p);
      if (path.length === 0) {
        //prob hash
        hashUrl = true;
        path = location.hash.split("/").filter((p) => p && p !== "#");
      }
      let path1 = path[0];
      if (path.length === 2 && (path1 === "reset" || path1 === "set")) {
        this.setPasswordToken = path[1];
        this.setPasswordType = path1;
        this.showLogin();
        return;
      }

      if (path.length === 1 && path1 === "disabled") {
        this.showErrorSnack = true;
        this.errorText = "Login for this user is currently disabled";

        this.showLogin();
        return;
      }

      let searchParams = location.search.split("=");

      if (hashUrl && path1 !== undefined) {
        let tempSearch = path1.split("?");
        if (tempSearch.length == 2) {
          searchParams = tempSearch[1].split("=");
        }
        path[0] = tempSearch[0];
        path1 = path[0];
      }

      if (
        path.length === 1 &&
        ["viewnotification", "viewtask"].indexOf(path1) >= 0
      ) {
        if (searchParams.length === 2) {
          if (!this.$loginState.isAuthenticated) {
            let id = Number(searchParams[1]);
            localStorage.setItem(path1, id);
          }
        }
      }

      if (path.length === 1 && ["viewdoc"].indexOf(path1.toLowerCase()) >= 0) {
        if (searchParams.length === 2) {
          if (!this.$loginState.isAuthenticated) {
            let id = searchParams[1];
            localStorage.setItem(path1, id);
          }
        }
      }

      this.isLoading = true;
      this.refreshLoginState();
    },
    async checkForRedirectLink() {
      if (!this.checkForLink("viewnotification")) {
        if (!this.checkForLink("viewtask")) {
          this.checkForDocLink();
        }
      }
    },
    checkForDocLink() {
      let id = "";
      let linkType = "viewdoc";
      if (localStorage.getItem(linkType)) {
        id = localStorage.getItem(linkType);
      } else {
        id = this.extractFromUrl();
      }
      if (id !== undefined && id !== "") {
        axios
          .get("document/docswithparts/" + id, {})
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.openDocument(resp.data.Data.documents[0], false);
              localStorage.removeItem(linkType);
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });
      }
    },
    checkForLink(linkType) {
      let id = 0;

      if (localStorage.getItem(linkType)) {
        id = Number(localStorage.getItem(linkType));
      } else {
        id = this.extractFromUrl();
      }

      if (id > 0) {
        axios
          .get(`workflow/${linkType}/${id}`)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              if (resp.data.Data && resp.data.Data.viewAction) {
                this.openDocument(resp.data.Data);
              } else {
                this.triggerNotification(
                  "You are not permitted to view this document",
                  "error"
                );
              }
              if (localStorage.getItem(linkType)) {
                localStorage.removeItem(linkType);
              }
            } else {
              this.errMessage = resp.data.message;
            }
          })
          .catch((err) => {
            alert("Server Error: " + err);
          });
        return true;
      } else {
        return false;
      }
    },
    logout() {
      axios.get("auth/logout/");
      this.$loginState.isAuthenticated = false;
      this.dataPermissionNeeded = false;
      this.unloadClient();
      this.$store.dispatch("wordChecks/unload");
      this.$store.dispatch("customers/unload");
      localStorage.removeItem("rmimpusers");
      localStorage.removeItem("rmapit");
      localStorage.removeItem("rmsso");
      this.qaActions.length = 0;
      if (this.$route.name !== "default")
        this.$router.replace({ name: "default" });

      this.setPasswordToken = "";
      this.showLogin();
    },
    showLogin() {
      this.doLogin = true;
      //this.$loginState.doLogin = true;
    },
    addDashboardActions(dashboards) {
      if (!dashboards) return;

      const routes = this.$router.addDashboardRoutes(dashboards);
      routes.forEach((route) => {
        this.qaActions.push(route);
      });
    },
    setAvailableActions() {
      this.qaActions.length = 0;
      if (this.$loginState.isAuthenticated) {
        if (this.$loginState.user.clientDashboards)
          this.$router.addDashboardRoutes(
            this.$loginState.user.clientDashboards
          );

        if (this.$loginState.user.landingPageConfigs)
          this.$router.addLandingPages(
            this.$loginState.user.landingPageConfigs
          );

        this.qaActions.push(...this.$router
          .getRoutes()
          .map((r, ri) => {
            r.position = r.meta?.position || 100 + ri;
            return r;
          })
          .filter(
            (r) =>
              r.meta?.newUi &&
              (this.$loginState.user.userPermittedRoutes.some(
                (pr) => pr === r.name
              ) ||
                this.$loginState.user.landingPageConfigs.some(
                  (lp) => lp.page_route === r.name
                ))
          )
          .sort((a, b) => a.position - b.position));

        if (this.myQaActions.length > 0) {
          this.$loginState.permittedRoutes = this.qaActions.map((r) => r.name);
          let defaultPage = this.myQaActions.find(
            (a) => a.name === this.$loginState.getUserSetting("default_page")
          );
          if (!defaultPage) defaultPage = this.myQaActions[0];
          this.$loginState.defaultRoute = defaultPage.name;
          this.validateCurrentRoute();
        }
      } else if (this.$route.name !== "default" ) {
        this.$router.push({ name: "default" });
      }
    },
    validateCurrentRoute() {
      const currentPath = this.$route.name;
      const currentRoute = this.myQaActions.find((a) => a.name === currentPath);
      if (!currentRoute) {
        this.$router.replace({
          name: this.$loginState.defaultRoute,
          params: { user: this.selectedUserIdx },
        });
      } else if (this.$route.params.user != this.selectedUserIdx) {
        this.$router.replace({
          name: this.$route.name,
          params: { user: this.selectedUserIdx },
        });
      }
    },
    applyTheme(themeName) {
      this.$store.commit(
        "theme/setAvailableThemes",
        this.$loginState.user.settings.find(
          (s) => s.setting == "allowed_themes"
        )
      );
      this.$store.commit("theme/setTheme", themeName);
      this.$vuetify.theme.dark = this.$store.state.theme.dark;
      this.$vuetify.theme.themes.dark.primary =
        this.$store.state.theme.primaryColor;
      this.$vuetify.theme.themes.light.primary =
        this.$store.state.theme.primaryColor;
      this.$vuetify.theme.themes.dark.secondary =
        this.$store.state.theme.secondaryColor;
      this.$vuetify.theme.themes.light.secondary =
        this.$store.state.theme.secondaryColor;
    },
    documentCreated(item, viewSource = false) {
      this.selectedDocumentId = item.system_number;
      this.selectedDocumentViewAction = item.viewAction;
      this.showAddDocument = false;
      this.isNewDocument = true;
      this.viewSourceDoc = viewSource;
      if (item.doc_type == "External File") {
        this.showExternalDocument = true;
      } else {
        this.showDocument = true;
      }
      this.docCopied = false;
      this.docCopiedLink = false;
      this.documentOpened({
        system_number: item.system_number,
        doc_name: item.doc_name,
        doc_type: item.doc_type
      });
      if (this.notifyDocumentChange) {
        this.notifyDocumentChange(item);
      }
    },
    documentLoadError(resp) {
      this.response = resp;
      this.closeDocument();
    },
    closeDocument() {
      this.selectedDocumentId = null;
      this.selectedDocumentViewAction = null;
      this.showDocument = false;
      this.docCopied = false;
      this.docCopiedLink = false;
    },
    closeExternalDocument() {
      this.selectedDocumentId = null;
      this.selectedDocumentViewAction = null;
      this.showExternalDocument = false;
      this.docCopied = false;
      this.docCopiedLink = false;
    },
    documentHeaderSaved(item) {
      if (this.notifyDocumentChange) {
        this.notifyDocumentChange(item);
      }
    },
    addDocument(defaultDoc) {
      this.$support.hide();
      this.showAddDocument = true;
      this.defaultDoc = defaultDoc;
    },
    closeAddDocument() {
      this.showAddDocument = false;
    },
    documentOpened(item) {
      this.recentDocs = utils.addRecentDoc(this.$loginState, item);
    },
    openDocument(doc, isNew, isNewLink) {
      if (doc.id && !doc.doc_id) {
        // from recent list
        doc = this.$store.getters["docs/docsList"].find(
          (x) => x.system_number === doc.id
        );
        if (!doc) {
          alert("You no longer have permission to view this document");
          return;
        }
      }
      this.selectedDocumentViewAction = doc.viewAction;
      let docId =
        doc.reference || doc.document_reference || doc.system_number || doc.id;
      let docType = doc.doc_type || doc.document_type || doc.tmpl_name;
      let docName = doc.document_name || doc.doc_name || doc.title;
      this.selectedDocumentId = docId;
      this.refDocument = null;
      this.isNewDocument = false;
      this.viewSource = false;
      if (docType == "External File" || doc.tmpl_name == "External File") {
        this.showExternalDocument = true;
        this.showDocument = false;
      } else {
        this.showDocument = true;
        this.showExternalDocument = false;
      }
      this.openFlex = doc.openFlex;
      this.docCopied = isNew;
      this.docCopiedLink = !!isNewLink;
      this.documentOpened({
        system_number: docId,
        doc_name: docName,
        doc_type: docType,
      });
    },
    copyDocument(item, tmpl_id, uploaded, action_available_id_copy) {
      tmpl_id = tmpl_id || item.tmpl_id;

      const createOption = this.$loginState.user.createDocumentOptions.find(
        (a) => a.tmpl_id === tmpl_id
      );
      const isNewLink =
        tmpl_id && item.tmpl_id ? tmpl_id !== item.tmpl_id : false;

      if (!createOption) {
        this.response = { message: "No permission to create document" };
        return;
      }

      let action;
      if (createOption.lifecycles && createOption.lifecycles.length === 1) {
        action = createOption.lifecycles[0];
      } else if (
        createOption.lifecycles &&
        createOption.lifecycles.length > 1
      ) {
        action = createOption.lifecycles.find((x) => x.isDefault);
      }

      let data = {
        source_doc_id: item.doc_id,
        tmpl_id: tmpl_id || item.tmpl_id,
        action: action,
        action_available_id_copy: action_available_id_copy,
      };

      this.isLoading = true;
      axios
        .post("document/copyDocument/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            let doc = resp.data.Data;
            //let doc = resp.data.Data[0][0];
            //doc.source_doc_id = item.doc_id;
            this.$store.dispatch("docs/addCopy", doc);
            // if (uploaded) {
            //   resp.data.Data[0].file_data = uploaded[0];
            //   resp.data.Data[0].doc_id = doc.doc_id;
            //   this.linkFileToDoc(resp.data.Data[0]);
            // }
            if (uploaded) {
              resp.data.Data.file_data = uploaded[0];
              //resp.data.Data.doc_id = doc.doc_id;
              this.linkFileToDoc(resp.data.Data);
            }
            // this.openDocument(doc, !Number(doc.groupMemberAdded));
            this.openDocument(doc, true, isNewLink);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.sessionExpired(err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    closeCookies() {
      this.showCookies = false;
    },
    showCookieDialog() {
      this.showCookies = true;
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    linkFileToDoc(docData) {
      this.isLoading = true;
      let data = {
        doc_id: docData.doc_id,
        file_id: docData.file_data.file_id,
      };

      axios
        .post("file/linkFileToDoc/", data)
        .then((resp) => {
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    showHints() {
      //initialize instance
      var enjoyhint_instance = new EnjoyHint({});

      var enjoyhint_script_steps = [
        {
          "next .histBtn":
            "You can see the last 10 documents that you viewed here",
        },
        {
          "next .createBtn":
            'Click the "Create" button to start creating a new document',
        },
        {
          "next .menuBtn": "Your menu options are shown here",
        },
        {
          "next .treeview0":
            "You can filter by selecting any level within the job hierarchy",
        },
        {
          "next .treeview1":
            "Or within the geography (and company) hierarchies",
        },
        {
          "next .filter0":
            "You can filter any of the hierarchies by typing<br/>text in the relevant filter text box",
        },
        {
          "next .viewType":
            "You can switch between card and list view here. In card view you can select how many cards are displayed.",
        },
        {
          "next .docFilter":
            "You can filter the documents here - the search<br/>is across all document fields displayed",
        },
        {
          "next .statusSelect":
            "You can filter the documents by document status",
        },
        {
          "next .exportDocs":
            "Click on this icon to download a list of documents<br/>in CSV (comma separated values) format = Excel/Numbers list",
        },
        {
          "next .docList":
            "Click on a card or line (in list view) to view/edit the document",
        },
      ];

      if (this.$loginState.readOnly) {
        enjoyhint_script_steps = [
          {
            "next .histBtn":
              "You can see the last 10 documents that you viewed here",
          },
          {
            "next .treeview0":
              "You can filter by selecting any level within the job hierarchy",
          },
          {
            "next .treeview1":
              "Or within the geography (and company) hierarchies",
          },
          {
            "next .filter0":
              "You can filter any of the hierarchies by typing<br/>text in the relevant filter text box",
          },
          {
            "next .viewType":
              "You can switch between card and list view here. In card view you can select how many cards are displayed.",
          },
          {
            "next .docFilter":
              "You can filter the documents here - the search<br/>is across all document fields displayed",
          },
          {
            "next .statusSelect":
              "You can filter the documents by document status",
          },
          {
            "next .exportDocs":
              "Click on this icon to download a list of documents<br/>in CSV (comma separated values) format = Excel/Numbers list",
          },
          {
            "next .docList":
              "Click on a card or line (in list view) to view the document",
          },
        ];
      }

      //set script config
      enjoyhint_instance.set(enjoyhint_script_steps);

      //run Enjoyhint script
      enjoyhint_instance.run();
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/theme.scss";
@import "./assets/styles/custom.scss";
@import "./assets/styles/enjoyhint.css";
</style>