import { render, staticRenderFns } from "./cContentManager.vue?vue&type=template&id=20e76602&scoped=true&"
import script from "./cContentManager.vue?vue&type=script&lang=js&"
export * from "./cContentManager.vue?vue&type=script&lang=js&"
import style0 from "./cContentManager.vue?vue&type=style&index=0&id=20e76602&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e76602",
  null
  
)

export default component.exports