var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"justify-center mb-6 link-container"},[_c('draggable',{staticClass:"row px-3 justify-center",attrs:{"disabled":!_vm.editMode},model:{value:(_vm.links),callback:function ($$v) {_vm.links=$$v},expression:"links"}},_vm._l((_vm.links),function(l,li){return _c('v-col',{key:li,attrs:{"cols":l.tile_cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{
            'help-card link-container': true,
            'help-disabled': !l.url,
            'hover': !_vm.editMode && hover && l.url,
            draggable: _vm.editMode,
            'mt-8': l.showBorder || _vm.editMode
          },attrs:{"elevation":l.showBorder ? 2 : 0,"ripple":!!(l.url && !_vm.editMode)}},[_c('v-card-text',{style:(l.height ? `height: ${l.height}` : 'height: 300px')},[(_vm.editMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"edit-btn"},[_c('v-btn',{attrs:{"icon":"","small":"","title":"Delete"},on:{"click":function($event){return _vm.deleteTile($event, l)}}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"icon":"","small":"","title":"Copy"},on:{"click":function($event){return _vm.copyTile($event, l)}}},[_c('v-icon',[_vm._v("content_copy")])],1),_c('v-btn',{attrs:{"icon":"","small":"","title":"Settings"},on:{"click":function($event){return _vm.editTile($event, l)}}},[_c('v-icon',[_vm._v("settings")])],1)],1):_vm._e(),_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(_vm.iframeUrls[l.id] && _vm.iframeUrls[l.id].url),expression:"iframeUrls[l.id] && iframeUrls[l.id].url"}],attrs:{"src":_vm.iframeUrls[l.id] ? _vm.iframeUrls[l.id].url : '',"frameborder":"0","width":"100%","height":"100%","allowtransparency":""}})])],1)]}}],null,true)})],1)}),1),(_vm.editMode)?_c('v-btn',{staticClass:"add-btn",attrs:{"large":"","icon":"","title":"Add Tile"},on:{"click":function($event){return _vm.addTile($event)}}},[_c('v-icon',[_vm._v("add_circle_outline")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }