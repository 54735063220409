<template>
  <v-dialog v-model="show" persistent max-width="800px" @keydown.esc="close()">
    <v-card @keyup.esc="close" ref="form" class="px-1 py-2">
      <v-card-title class="d-flex align-start">
        <div>
          <span class="subtitle-1">
            {{
              definition.title +
              (definition.rules.tp_locked > 0 ? " (Locked)" : "")
            }}
          </span>
          <br />
          <span class="caption">{{ wordCountText }}</span>
          <span class="caption">&nbsp; {{ charCountText }}</span>
        </div>
        <v-spacer />
        <v-btn icon large class="btn-background" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row
          dense
          v-if="definition.rules.uses_essential_flag && definition.showNotes"
          class="essentialRow"
        >
          <v-col class="d-flex">
            <v-spacer></v-spacer>
            <v-checkbox
              v-if="
                definition.rules.uses_essential_flag && definition.showNotes
              "
              dense
              hide-details
              v-model="activeIsEssential"
              label="Essential?"
            ></v-checkbox>
          </v-col>
        </v-row>
        <div
          v-if="
            definition.rules.restrict_to_lookup && !definition.rules.tp_locked
          "
        >
          <v-autocomplete
            class="inLineEdit mb-6"
            autofocus
            v-model="activeText"
            clearable
            label="Pick from list"
            :search-input.sync="comboText"
            :return-object="false"
            :items="definition.lookup"
            item-text="varVal"
            item-value="varVal"
            @change="updateCombo"
            @keyup="isDirty = true"
            @keydown.esc.stop.prevent="close"
            @keydown.ctrl.83.prevent.stop="save"
            :filter="filterPicker"
            hide-details
          >
            <template v-slot:item="{ item }">
              <p style="max-width: 800px" v-html="item.varVal"></p>
            </template>
          </v-autocomplete>
        </div>
        <div v-else-if="!activeSubParts || activeSubParts.length === 0">
          <v-row dense>
            <v-col style="padding-top: 20px; padding-bottom: 0px" class="part">
              <div
                v-if="definition.rules.tp_locked || hasAttributes"
                v-html="activeTextReadOnly"
                style="padding: 5px"
              ></div>
              <TextHighlight
                v-else
                class="texthighlight"
                padding="20"
                :value="activeText"
                :refresh="refresh"
                :words="wordChecks"
                :tmpl_part_metadata="definition.rules"
                :placeholder="definition.rules.placeholder"
                :restrictedChars="getRestrictedCharsForPart(settings.partType)"
                hideLookup
                @changed="textChanged"
                @initialised="textStatusChanged"
                @save="save"
                @escape="close"
              ></TextHighlight>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row
            v-for="(sp, spi) in activeSubParts"
            :key="'sp' + spi"
            dense
            :class="{
              shortText:
                definition.title === 'Working Pattern' ||
                definition.title === 'For You',
            }"
          >
            <v-col
              cols="1"
              v-if="sp.type === 'listitem'"
              style="text-align: right"
            >
              <v-icon x-small>mdi-circle</v-icon>
            </v-col>
            <v-col :cols="sp.type === 'listitem' ? '10' : '11'" class="part">
              <div
                v-if="definition.rules.tp_locked || hasAttributes"
                v-html="sp.highlightedText"
                style="padding: 5px"
              ></div>
              <TextHighlight
                v-else
                class="texthighlight"
                :padding="10"
                :value="sp.text"
                :refresh="refresh"
                :words="wordChecks"
                :tmpl_part_metadata="definition.rules"
                :placeholder="definition.rules.placeholder"
                :restrictedChars="getRestrictedCharsForPart(settings.partType)"
                hideLookup
                @changed="textChanged($event, sp)"
                @initialised="textStatusChanged"
                @save="save"
                @escape="close"
              ></TextHighlight>
            </v-col>
            <v-col
              class="colIcons"
              cols="1"
              v-if="!definition.rules.tp_locked && !hasAttributes"
            >
              <v-btn
                x-small
                icon
                class="btn-action-icon"
                title="clear text"
                @click="sp.text = ''"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                x-small
                icon
                class="btn-action-icon"
                v-if="activeSubParts && activeSubParts.length > 1"
                title="delete"
                @click="deleteSubPart(spi)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <!-- Removed Add Bullet point in Edit Notes dialog; OZ -->
              <!-- <v-btn 
                x-small
                icon
                class="btn-action-icon"
                title="add bullet point"
                @click="addBullet(spi)"
              >
              <v-icon>mdi-playlist-plus</v-icon>
              </v-btn> -->
              <!--Removed the following condition in the below button: v-if="sp.type === 'listitem'" 
                to always show Add Text button in Edit Notes dialog; OZ-->
              <v-btn
                x-small
                icon
                class="btn-action-icon"
                title="add text"
                @click="addText(spi)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row dense v-if="textStatus && textStatus.errors.length">
          <v-col
            cols="6"
            v-for="(w, wi) in textStatus.errors"
            :key="'e_' + wi"
            style="font-size: 12px"
          >
            <v-row dense>
              <v-col cols="1">
                <v-icon color="red" small style="float: left">mdi-alert</v-icon>
              </v-col>
              <v-col cols="11">
                <div
                  :style="{
                    float: 'left',
                    'padding-left': '5px',
                    opacity: '0.6',
                    'background-color': w.colour,
                  }"
                  v-html="w.description"
                ></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense v-if="checkSummary && checkSummary.warnings.length">
          <v-col>
            <!-- :cols="checkSummary.warnings.length >= 3 ? '4' : ''" -->

            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><v-badge
                    color="orange"
                    :content="cw.count"
                    inline
                    overlap
                    v-for="(cw, cwi) in checkSummary.warnings"
                    :key="'e_' + cwi"
                  >
                    <span>{{ cw.title }}</span></v-badge
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col
                      cols="12"
                      v-for="(cw, cwi) in checkSummary.warnings"
                      :key="'e_' + cwi"
                    >
                      <div v-for="(w, wi) in cw.items" :key="'e_' + wi">
                        <div
                          :style="{
                            float: 'left',
                            'min-width': '20px',
                            height: w.effect === 'highlight' ? '20px' : '13px',
                            'margin-right': '10px',
                            'background-color':
                              w.effect === 'highlight'
                                ? w.colour
                                : 'transparent',
                            'border-bottom':
                              w.effect.indexOf('dotted') >= 0
                                ? 'dotted 3px ' + w.colour
                                : w.effect.indexOf('solid') >= 0
                                ? 'solid 2px ' + w.colour
                                : '',
                          }"
                        ></div>
                        <v-badge
                          color="orange"
                          :content="w.count"
                          inline
                          overlap
                          style="color: slategray; font-size: 12px"
                        >
                          <div
                            :style="{
                              float: 'left',
                              width: '650px',
                              'padding-left': '5px',
                            }"
                            v-html="w.description"
                          ></div>
                        </v-badge>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!-- <v-row dense v-if="textStatus && textStatus.warnings.length">
          <v-col>
            <v-row
              dense
              v-for="(w, wi) in textStatus.warnings"
              :key="'w_' + wi"
              style="color: slategray; font-size: 12px"
            >
              <v-icon color="orange" small style="float: left"
                >mdi-alert</v-icon
              >
              <div
                :style="{
                  float: 'left',
                  'padding-left': '5px',
                  'background-color': w.colour,
                }"
                v-html="w.description"
              ></div>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row
          dense
          v-if="
            !definition.rules.tp_locked &&
            !definition.rules.restrict_to_lookup &&
            !hasAttributes
          "
          class="mt-2"
        >
          <v-col>
            <v-combobox
              outlined
              class="roundish"
              v-model="pickerText"
              prepend-inner-icon="mdi-magnify"
              clearable
              label="Search existing content, type here to filter"
              :value="valueText"
              ref="combo"
              :search-input.sync="comboText"
              :return-object="false"
              :items="definition.lookup"
              item-text="varVal"
              item-value="varVal"
              @keyup.enter="pickValue"
              @change="updateCombo"
              @keydown.ctrl.83.prevent.stop="save"
              :filter="filterPicker"
              hide-details
            >
              <template v-slot:item="{ item }">
                <p style="max-width: 800px" v-html="item.varVal"></p>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>No matching results.</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row dense v-if="definition.showNotes">
          <v-col>
            <v-textarea
              ref="notes"
              outlined
              class="roundish"
              label="Notes"
              placeholder="Add your notes here..."
              rows="1"
              auto-grow
              v-model="activeNotes"
              @change="makeDirty()"
              @keydown="makeDirty()"
              @keydown.ctrl.83.prevent.stop="save"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="d-flex align-center">
            <v-select
              v-if="$loginState.canQA"
              outlined
              class="roundish"
              :menu-props="{
                'offset-y': true,
              }"
              :return-object="false"
              :items="utils.qualities"
              label="Reusable Content Usage"
              item-text="text"
              item-value="value"
              v-model="activeQuality"
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex align-end justify-end">
            <v-btn
              v-if="definition.canDelete"
              text
              color="primary"
              @click="deleteItem()"
              >Delete</v-btn
            >
            <v-btn
              ref="save"
              :disabled="
                (textStatus &&
                  textStatus.errors.length > 0 &&
                  !definition.allow_errors) ||
                !isDirty
              "
              large
              class="roundish px-10"
              color="primarylight"
              @click="save()"
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import utils from "@/common/utils.js";
import TextHighlight from "@/components/cTextHighlight";
export default {
  props: {
    watchedObject: {}, //{ text: '', closed: false } // this is an option from pages with high volumes of data
    open: Boolean,
    settings: {},
  },
  components: {
    TextHighlight,
  },
  data: function () {
    return {
      activeText: "",
      activeTextReadOnly: "",
      activeNotes: "",
      activeSubParts: [],
      activeIsEssential: false,
      activeQuality: 0,
      comboText: "",
      valueText: "",
      show: false,
      isDirty: false,
      cleartext: false,
      pickerText: "",
      wordCountText: "",
      charCountText: "",
      refresh: false,
      definition: {
        showWordCount: true,
        showCharCount: true,
        showNotes: false,
        canDelete: false,
        title: "",
        label: "",
        text: "",
        notes: "",
        is_essential: false,
        quality: 0,
        subParts: [],
        rules: {},
        lookup: [],
      },
      wordChecks: [],
      textStatus: null,
      loading: false,
      checkSummary: null,
      utils: utils,
      hasAttributes: false,
    };
  },
  watch: {
    open() {
      this.show = !!this.open;
      if (this.show) {
        this.loading = true;
        this.refresh = true;
        if (this.$refs.ip0 && this.$refs.ip0.length > 0)
          this.$refs.ip0[0].$el.focus();
        let tpid = this.settings.partType
          ? this.settings.partType.tmpl_part_id
          : this.settings.row.tmpl_part_id;
        this.wordChecks = this.settings.wordChecks.filter((w) =>
          w.tmpl_part_types.some((tp) => tp.tp_id === tpid)
        );
        this.wordChecks = utils.initialisePartChecks(this.wordChecks);
        Object.assign(this.definition, this.settings);
        this.activeText =
          this.watchedObject && this.watchedObject.text
            ? this.watchedObject.text
            : this.definition.text;
        this.activeNotes =
          this.watchedObject && this.watchedObject.notes
            ? this.watchedObject.notes
            : this.definition.notes;
        this.activeSubParts = JSON.parse(
          JSON.stringify(this.definition.subParts)
        );
        //if (this.definition.rules.tp_locked) {
        if (!this.activeSubParts || this.activeSubParts.length === 0) {
          this.textStatus = utils.applyHighlights(
            this.activeText,
            this.wordChecks,
            true
          );
          if (this.definition.rules.tp_locked)
            this.activeTextReadOnly = this.textStatus.highlightedText;
        } else {
          this.textStatus = null;
          this.activeSubParts.forEach((sp) => {
            let textStatus = utils.applyHighlights(
              sp.text,
              this.wordChecks,
              true
            );
            sp.highlightedText = textStatus.highlightedText;
            if (!this.textStatus) {
              this.textStatus = textStatus;
            } else {
              if (textStatus.warnings.length) {
                textStatus.warnings.forEach((w) => {
                  utils.pushWordAlert(this.textStatus.warnings, w);
                });
              }
              if (textStatus.errors.length) {
                textStatus.errors.forEach((w) => {
                  utils.pushWordAlert(this.textStatus.errors, w);
                });
              }
            }
          });
        }
        //}
        this.hasAttributes =
          this.definition.rules.attributes &&
          this.definition.rules.attributes.length > 0;
        this.activeIsEssential = this.definition.is_essential;
        this.activeQuality = parseInt(this.definition.quality);
        this.isDirty = false;
        this.pickerText = "";
        this.showStatus();
        //   } else {
        //     this.textStatus = {};
      }
    },
    pickerText(newval) {
      if (newval) {
        newval = newval.trim();
        if (this.activeSubParts && this.activeSubParts.length) {
          this.activeSubParts = utils.partToSubParts(newval);
        } else {
          this.activeText = newval;
        }
        this.pickerText = "";
        this.cleartext = true;
        this.comboText = "";
        newval = "";
      }
    },
    activeIsEssential() {
      this.makeDirty();
    },
    activeQuality() {
      this.makeDirty();
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.cleartext) this.$refs.combo.internalSearch = "";
      this.cleartext = false;
      this.loading = false;
      this.refresh = false;
    });
  },
  methods: {
    getRestrictedCharsForPart(pt){
      return pt?.tmpl_part_metadata.exclusions ? pt.tmpl_part_metadata.exclusions.restrict_chars : [];
    },    
    save() {
      if (
        (this.textStatus.errors.length > 0 && !this.definition.allow_errors) ||
        !this.isDirty
      )
        return;
      this.show = false;
      if (this.watchedObject) {
        const updated = this.watchedObject;
        updated.text = this.activeText;
        updated.notes = this.activeNotes;
        updated.closed = true;
        this.$emit("updated:watchedObject", updated);
      } else {
        if (this.definition.rules.restrict_to_lookup) {
          this.activeSubParts = utils.partToSubParts(this.activeText);
        }
        let partQuality = Number(this.activeQuality);
        let hasIssues =
          this.textStatus.errors.length > 0 ||
          this.textStatus.warnings.length > 0;
        if (hasIssues && partQuality === 0) this.activeQuality = -1;
        if (!hasIssues && partQuality === -1) this.activeQuality = 0;
        this.$emit("saved", {
          text: this.activeText,
          notes: this.activeNotes,
          subParts: this.activeSubParts,
          is_essential: this.activeIsEssential,
          quality: this.activeQuality,
        });
      }
    },
    deleteItem() {
      this.show = false;
      if (this.watchedObject) {
        const updated = this.watchedObject;
        updated.deleted = true;
        this.$emit("updated:watchedObject", updated);
      } else {
        this.$emit("deleted");
      }
    },
    close() {
      this.show = false;
      if (this.watchedObject) {
        const updated = this.watchedObject;
        updated.deleted = true;
        this.$emit("updated:watchedObject", updated);
      } else {
        this.$emit("closed");
      }
    },
    textChanged(newval, subpart) {
      this.textStatus = newval;
      if (subpart) subpart.text = newval.text;
      else this.activeText = newval.text;
      this.makeDirty();
    },
    textStatusChanged(newval) {
      this.textStatus = newval;
      this.showStatus();
    },
    inputCombo(newval) {
      this.makeDirty(newval);
    },
    keyupCombo(newval) {
      this.makeDirty(newval);
    },
    updateCombo(newval) {
      this.makeDirty(newval);
      this.comboText = "";
    },
    makeDirty() {
      if (this.loading) return;
      if (!this.isDirty) {
        this.isDirty = true;
      }
      this.showStatus();
    },
    deleteSubPart(spi) {
      this.activeSubParts.splice(spi, 1);
      this.makeDirty();
    },
    addBullet(spi) {
      this.activeSubParts.splice(spi + 1, 0, { text: "", type: "listitem" });
    },
    addText(spi) {
      this.activeSubParts.splice(spi + 1, 0, { text: "", type: "text" });
    },
    filterPicker(item, queryText, itemText) {
      if (item.header) return false;

      const text = (itemText || "").toLowerCase();

      let search = (queryText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x.length)
        .map((x) => x);
      return (
        !queryText ||
        queryText.length === 0 ||
        search.every((s) => text.indexOf(s) >= 0)
      );
    },
    pickValue() {
      this.$refs.combo.isMenuActive = false;
    },
    showStatus() {
      let data =
        this.activeSubParts && this.activeSubParts.length
          ? this.activeSubParts.map((sp) => sp.text).join(" ")
          : this.activeText;
      if (this.definition.showWordCount) {
        this.wordCountText = utils.getWordCountStatus(
          data,
          this.wordChecks
        ).headline;
      }
      if (this.definition.showCharCount) {
        this.charCountText = utils.getCharCountStatus(
          data,
          this.wordChecks
        ).headline;
      }
      if (this.textStatus)
        this.checkSummary = utils.summarisePartChecks(this.textStatus);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

.shortText .v-text-field__details {
  display: none;
}
.v-input--selection-controls {
  margin-top: 5px;
}
.v-expansion-panel-header {
  min-height: 30px;
}
button.v-expansion-panel-header--active {
  min-height: 30px;
}
div.v-expansion-panel-content__wrap {
  padding: 0 14px 6px;
}

.essentialRow {
  margin-top: -20px;
}

.part {
  > div {
    border: solid 1px lightgray;
    border-radius: 14px;
  }
  > ::v-deep .texthighlight {
    border: solid $selectedfieldborder 1px;
    background-color: $selectedfieldbackground;
    border-radius: 0;
    .backdrop,
    textarea {
      border: 0;
      &::placeholder {
        opacity: 0.4;
      }
    }
  }
}

.col.part {
  max-width: 100%;
  flex-grow: 1;
}

.colIcons {
  max-width: 40px;
  padding: 10px !important;
}
</style>