import axios from "axios";
import sanitizeHtml from 'sanitize-html';
export default {
	removeTags: function (raw) {
		if (!raw) return '';
		if (typeof raw === "number" || typeof raw === "boolean") return `${raw}`;
		let noBreaksText = sanitizeHtml(raw.toString(), {
			allowedTags: [],
			allowedAttributes: {}
		});
		noBreaksText = noBreaksText.replace(/[\r\n]+/gm, '\n');

		return noBreaksText;
	},
	partToSubParts(text) {
		let subParts = [], bulletChar = '';
		if (text && text.indexOf('●') >= 0) {
			bulletChar = '●';
		} else if (text && text.indexOf('·') >= 0) {
			bulletChar = '·';
		}
		if (text && text.indexOf('<ul>') >= 0) {
			text = text.replace('<div>', '').replace('</div>', '');
			let el = text.split("<ul>").map(p => p.trim()).filter(p => !!p);
			el.forEach(e => {
				let li = e.split("<li>").map(p => p.trim()).filter(p => !!p);
				li.forEach(l => {
					subParts.push({ text: this.removeTags(l.replace("</li>", "").replace("</ul>", "")), type: l.indexOf("</li>") >= 0 || l.indexOf("</ul>") >= 0 ? "listitem" : "text", editing: false });
				});
			});
		} else if (bulletChar) {
			let el = text.split(bulletChar).map(p => p.trim()); //.filter(p => !!p);
			el.forEach((e, i) => {
				if (e) {
					let li = e.split("\n").map(p => p.trim()).filter(p => !!p);
					li.forEach((l, il) => {
						subParts.push({ text: l, type: i === 0 || il > 0 ? "text" : "listitem", editing: false });
					});
				}
			});
		}
		if (subParts.length === 0) subParts.push({ text: text || "", type: "text", editing: false });
		return subParts;
	},
	subpartsToPart(subParts) {
		let text = '';
		subParts.filter(sp => sp.text).forEach((sp, i) => {
			text += (i > 0 ? '\n' : '') + (sp.type === 'listitem' && !sp.text.startsWith('● ') ? '● ' : '') + sp.text;
		});
		return text;
	},
	attributesToText(attMeta, attributes) {
		let text = '';
		attMeta.filter(am => am.include_in_part_val).forEach((am) => {
			let at = attributes.find(a => a.tpa_id === am.tpa_id);
			text +=  (text.length > 0 ? am.separator : "") + (am.prefix || "") + (at ? at.text : "") + (am.suffix || "");
		});
		return text;
	},
	getWordCountStatus(text, checks) {
		let wordcount = this.countWords(text);
		let countText = wordcount + " words";
		let maxError = checks.find(
			(c) =>
				c.avoid &&
				c.is_error &&
				c.check_function === "word_count" &&
				c.check_value > 0
		);
		let maxWarn = checks.find(
			(c) =>
				c.avoid &&
				!c.is_error &&
				c.check_function === "word_count" &&
				c.check_value > 0
		);
		if (maxError || maxWarn) {
			countText += " (";
			if (maxError && maxError.check_value > 0) {
				countText += maxError.check_value + " max" + (maxWarn ? ", " : "");
			} else {
				countText += "max of ";
			}
			if (maxWarn) {
				countText += maxWarn.check_value + " recommended";
			}
			countText += ")";
		}
		this.wordCountText = countText;
		return { headline: countText, wordCountText: wordcount + " words", wordCount: wordcount };
	},
	getCharCountStatus(text, checks) {
		let charCount = this.countChars(text);
		let countText = charCount + " characters";
		let maxError = checks.find(
			(c) =>
				c.avoid &&
				c.is_error &&
				c.check_function === "char_count" &&
				c.check_value > 0
		);
		let maxWarn = checks.find(
			(c) =>
				c.avoid &&
				!c.is_error &&
				c.check_function === "char_count" &&
				c.check_value > 0
		);
		if (maxError || maxWarn) {
			countText += " (";
			if (maxError && maxError.check_value > 0) {
				countText += maxError.check_value + " max" + (maxWarn ? ", " : "");
			} else {
				countText += "max of ";
			}
			if (maxWarn) {
				countText += maxWarn.check_value + " recommended";
			}
			countText += ")";
		}
		this.charCountText = countText;
		return { headline: countText, charCountText: charCount + " characters", wordCount: charCount };
	},
	setDocPartStatus(part, tmpl_part, checks, docStatus, doc, persistHtml = false) {
		part.subParts = this.partToSubParts(part.text);
		let text = persistHtml ? part.text : this.removeTags(part.text),
			wordcount = this.countWords(text);
		part.text = text;
		part.word_count = wordcount;
		part.WordCount = wordcount + " words";
		let statusList = [];
		if (tmpl_part) {
			let tpChecks = checks.filter(w =>
				w.tmpl_part_types.some(
					tp => tp.tp_id === tmpl_part.tmpl_part_id
				)
			);
			let validation = this.applyHighlights(text, tpChecks, true, false, doc);
			part.possibleValidationExclusion = tpChecks.some(c => c.exclusions && c.exclusions.length);
			part.highlightedText = validation.highlightedText;
			validation.errors.forEach(v => {
				if (statusList.indexOf(v.short_description) < 0) statusList.push(v.short_description);
			});
			validation.warnings.forEach(v => {
				if (statusList.indexOf(v.short_description) < 0) statusList.push(v.short_description);
			});
			part.canMove = tmpl_part.cardinality && tmpl_part.cardinality.maxError > 1 && !tmpl_part.tmpl_part_metadata.tp_locked && !tmpl_part.tmpl_part_metadata.hide_add_delete;
			part.canAdd = part.canMove;
			if (part.canAdd && tmpl_part.parts && !part.locked) {
				part.canDelete = tmpl_part.cardinality.minError > 0 ? tmpl_part.parts.length > 1 : true;
				if (docStatus && !docStatus.allow_errors) {
					if (part.canDelete) {
						part.canDelete = tmpl_part.parts.length > tmpl_part.cardinality.minError;
					}
					part.canAdd = tmpl_part.parts.length < tmpl_part.cardinality.maxError;
				}
			} else {
				part.canDelete = false;
			}
			part.canLock = doc?.state.canLockDocParts && !tmpl_part.tmpl_part_metadata.tp_locked;
		} else {
			part.canAdd = false;
			part.canDelete = false;
			part.canMove = false;
			part.canLock = false;
		}
		if (statusList.length === 0) statusList.push("OK");
		part.Status = statusList.join("; ");
		part.StatusList = statusList;
		return statusList;
	},
	countWordsText(data) {
		return this.countWords(data) + " words";
	},
	countWords(data) {
		return data?.split(" ").filter(x => x.length).length ?? 0;
	},
	countChars(data) {
		return data?.replace(/● /g," ").replace(/(\r\n|\n|\r)/g,"").length ?? 0;
	},
	firstWordOK(val) {
		this.ingCheck(val) ? "OK" : "Not OK";
		this.infCheck(val) ? "OK" : "Not OK";
	},
	ingCheckText(val) {
		return this.ingCheck(val) ? "" : "Not gerund (..ing)";
	},
	infCheckText(val) {
		return this.infCheck(val) ? "" : "Correct verb required";
	},
	checkWordDegree(val, abbreviate) {
		return this.containsWord(val, 'degree') ? (abbreviate ? "Degree?" : "The word <b>Degree</b> could mean this requirement will exclude good candidates. Is a degree really essential for this role?") : "";
	},
	checkWordYears(val, abbreviate) {
		return this.containsWord(val, 'years') ? (abbreviate ? "Years?" : "The word <b>Years</b> could mean this requirement will exclude good candidates. Are you asking for unnecessarily qualified experience?") : "";
	},
	checkWordQualification(val, abbreviate) {
		return this.containsWord(val, 'qualification') ? (abbreviate ? "Qualification?" : "The word <b>Qualification</b> could mean this requirement will exclude good candidates. Is it really essential from day one in this role?") : "";
	},
	checkWordQualified(val, abbreviate) {
		return this.containsWord(val, 'qualified') ? (abbreviate ? "Qualified?" : "The word <b>Qualified</b> could mean this requirement will exclude good candidates. Is it really essential from day one in this role?") : "";
	},
	checkWordRecent(val, abbreviate) {
		return this.containsWord(val, 'recent') ? (abbreviate ? "Recent?" : "The word <b>Recent</b> could mean this requirement will exclude good candidates. Are you asking for unnecessarily qualified experience?") : "";
	},
	checkGenderWords(val, abbreviate) {
		// create a regular expression from searchwords using join and |. Add "gi".
		// gi means GLOBALLY and CASEINSENSITIVE

		let genderWords =
			["\\bActive",
				"\\bAggressive",
				"\\bAmbitious",
				"\\bAssertive",
				"\\bAutonomous",
				"\\bBlack-Belt",
				"\\bBoast",
				"\\bChallenging",
				"\\bCompetitive",
				"\\bConfident",
				"\\bCourage",
				"\\bDecisive",
				"\\bDemanding",
				"\\bDetermined",
				"\\bDirect",
				"\\bDominant",
				"\\bDriving",
				"\\bFight",
				"\\bForceful",
				"\\bIndependent",
				"\\bIntellect",
				"\\bNinja",
				"\\bOpinionated",
				"\\bOutspoken",
				"\\bPersistent",
				"\\bRock-Star",
				"\\bSelf-reliant",
				"\\bStrong",
				"\\bSuperior"
			];

		var searchExp = new RegExp(genderWords.join("\\b|"), "gi");
		return (searchExp.test(val)) ? (abbreviate ? "Biased language?" : "This text contains biased wording that could deter candidates, please review and replace.") : "";
	},
	containsWord(val, word) {
		return this.getWordArray(val).indexOf(word.toLowerCase()) >= 0;
	},
	getWordArray(val) {
		return val.toLowerCase().replace(/,|\.|\(|\)|-|"|'|!|:|;|\//g, ' ').split(' ').filter(x => x.length);
	},
	ingCheck(val) {
		var punctuation = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]/g;
		var spaceRE = /\s+/g;
		val = val.replace(punctuation, '').replace(spaceRE, ' ');
		return (
			val
				.split(" ")[0]
				.split("")
				.reverse()
				.join("")
				.indexOf("gni") === 0
		);
	},
	infCheck(val, words) {
		var punctuation = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]/g;
		var spaceRE = /\s+/g;
		val = val.replace(punctuation, '').replace(spaceRE, ' ');
		let validWords = [
				"bachelor''s",
				'able',
				'be',
				'build',
				'communicate',
				'demonstrate',
				'develop',
				'display',
				'enjoy',
				'experience',
				'follow',
				'have',
				'maintain',
				'manage',
				'motivate',
				'need',
				'possess',
				'prioritise',
				'prioritize',
				'provide',
				'recognise',
				'recognize',
				'see',
				'set',
				'show',
				'think',
				'thrive',
				'understand',
				'use'
		];
		if (words) validWords = words;
		let radford = val.split(" ")[1] ? (val.split(" ")[1].substr(0, 4) === 'year') : false
			|| ((val.split(" ")[2] ? (val.split(" ")[2].substr(0, 4) === 'year') : false)
				&& (val.split(" ")[0] ? (val.split(" ")[0] === 'Minimum') : false));
		return (
			validWords.find(wd => wd === val.split(" ")[0].split("").join("").toLowerCase()) || radford)
	},
	downloadFile(content, fileName, mimeType) {
		var a = document.createElement("a");
		mimeType = mimeType || "application/octet-stream";

		if (navigator.msSaveBlob) {
			// IE10
			navigator.msSaveBlob(
				new Blob([content], {
					type: mimeType
				}),
				fileName
			);
		} else if (URL && "download" in a) {
			//html5 A[download]
			a.href = URL.createObjectURL(
				new Blob([content], {
					type: mimeType
				})
			);
			a.setAttribute("download", fileName);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} else {
			location.href =
				"data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
		}
	},
	addRecentDoc(login, item) {
		let id = this.removeTags(item.system_number || item.id),
			title = this.removeTags(item.doc_name || item.title),
			type = item.doc_type,
			recent = login.getSetting("rec_ids",[]).filter(r => r.id !== id);
		recent.splice(0, 0, { id: id, title: title, type: type });
		recent = recent.filter((r, i) => i < 10);
		login.saveSetting("rec_ids", recent);
		return recent;
	},
	getRecentDocs(login) {
		return login.getSetting("rec_ids",[]);
	},
	setDefaultStatuses(login, item) {
		login.saveSetting("def_status", item);
		return item;
	},
	getDefaultStatuses(login) {
		return login.getSetting("def_status",[]);
	},
	comboFilterPicker(item, queryText, itemText) {
		if (item.header) return false;
		const text = (itemText || "").toLowerCase();
		let search = (queryText || "")
			.toLowerCase()
			.replace(/,/g, " ")
			.split(" ")
			.filter(x => x.length)
			.map(x => x);
		return !queryText || queryText.length === 0 || search.every(s => text.indexOf(s) >= 0);

		//     output += val
		//       .substring(posPrev, val.length)
		//       .replace(new RegExp(s, "gi"), match => `<mark>${match}</mark>`);
		//   }
	},
	getPartIcon(part) {
		switch (part.type) {
			case "Advert Job Title":
				return "../assets/aj-icon.png";
			case "About Team Hdr":
				return "../assets/ab-icon.png";
			case "About the Team":
				return "../assets/at-icon.png";
			case "Job USP Hdr":
				return "../assets/jo-icon.png";
			case "Job USP":
				return "../assets/ju-icon.png";
			case "Responsibility":
				return "../assets/rs-icon.png";
			case "Requirement":
				return "../assets/rq-icon.png";
			case "Req Hdr":
				return "../assets/rt-icon.png";
			case "Call To Action":
				return "../assets/ct-icon.png";
			default:
				return "";
		}
	},
	makeid(length) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	},
	initialisePartChecks(checks) {
		if (!checks)
			return [];
		else if (checks.length && checks[0].sortkey)
			return checks;
		else
			checks.forEach(w => {
				let rx;
				if (w.check_type === "exact") {
					rx = RegExp(`\\b${w.word}\\b`, "ig");
				} else if (w.check_type === "postfix") {
					rx = RegExp(`\\w+${w.word}\\b`, "ig");
				} else if (w.check_type === "prefix") {
					rx = RegExp(`\\b${w.word}\\w+`, "ig");
				}
				w.regex = rx;
				if (w.alternatives) {
					w.alternatives = w.alternatives
						.split("|")
						.filter(x => x)
						.map(x => x.trim())
				}
				if (w.whitelist_phrases) {
					w.whitelist_phrases = w.whitelist_phrases
						.split("|")
						.map(x => x.trim())
						.filter(x => x)
						.map(x => {
							return {
								phrase: x,
								regex: RegExp(`\\b${x.replace(/\s+/g, '\\s+')}\\b`, "ig")
							}
						});
				}
				if (w.check_function === 'word_count')
					w.sortkey = 0.1;
				else if (w.check_function === 'sentence_length')
					w.sortkey = 0.2;
				else if (w.check_function === 'word_list')
					w.sortkey = w.word.length;
				else
					w.sortkey = w.check_function.length;
				if (!w.is_error) w.sortkey += 0.01
				w.check_value = parseFloat(w.check_value);
			});
		return checks
			.sort((a, b) => a.sortkey - b.sortkey);
	},
	pushAlert(target, item, isError, docPartID, score) {
		if (!target.errors) target.errors = [];
		if (!target.warnings) target.warnings = [];
		let msg = target.errors.find(m => m.category === item.category && m.title === item.title && m.description === item.description && (!docPartID || isError));
		if (!msg) {
			if (!isError) {
				msg = target.warnings.find(m => m.category === item.category && m.title === item.title && m.description === item.description);
				if (!msg) {
					target.warnings.push(item);
					msg = item;
				}
			} else {
				target.errors.push(item);
				msg = item;
				msg.count = 0;
			}
		} else if (!isError && !docPartID) { //warning already present as error and not doing summary
			return false;
		}
		msg.count++;
		if (score) msg.score = score;
		let instance = msg.instances.find(i => i.subject === item.subject);
		if (!instance) {
			instance = { subject: item.subject, count: 0, partsAffected: [] };
			msg.instances.push(instance);
		}
		if (docPartID && docPartID.length) docPartID.forEach(id => instance.partsAffected.push(id));
		instance.count++;
		return true;
	},
	pushWordAlert(target, alert) {
		let msg = { check: alert.check_function, category: alert.category, category_weighting: alert.category_weighting, title: alert.title, subject: "", description: alert.description.replace(/{{word}}/g, alert.word), short_description: alert.short_description.replace(/{{word}}/g, alert.word), colour: alert.colour, effect: alert.effect, count: 0, weighting: alert.weighting, instances: [] };
		return this.pushAlert(target, msg, alert.is_error);
	},
	summarisePartChecks(status) {
		let response = { errors: [], warnings: [], overallScore: 1 };
		let reducer = (p, c) => {
			let item = p.find(x => x.title === c.short_description);
			if (!item) {
				item = { title: c.short_description, count: 0, items: [] };
				p.push(item);
			}
			item.count += c.count;
			item.items.push(c);
			return p;
		};
		response.errors = status.errors.reduce(reducer, []);
		response.warnings = status.warnings.reduce(reducer, []);
		return response;
	},
	applyHighlights(text, checks, readonly, isIE, doc) {
		return this.doChecks(text, checks, readonly, isIE, doc);
	},
	getMarkStyle(check, readonly, title, subject, includeIdx = false) {
		let style = `<mark style="`;
		//${!readonly ? 'color: transparent; ' : 'color: inherit; '}background-color: ${check.effect === "highlight" ? check.colour : "transparent"}; 
		if (check.effect === "highlight")
			style += `background-color: ${check.colour}; color: black;`;
		else
			style += `background-color: transparent; ${!readonly ? 'color: transparent; ' : 'color: inherit; '}`;

		if (check.effect.indexOf("underline") === 0) {
			style += `text-decoration-line: underline; text-decoration-color: ${check.colour}; `;
			if (check.effect === "underline_solid")
				style += `text-decoration-style: solid; text-decoration-thickness: 2px; `;
			else if (check.effect === "underline_dotted")
				style += `text-decoration-style: dotted; text-decoration-thickness: 3px; `;
			style += `" `;
		} else if (check.effect === "highlight") {
			style += `opacity: ${readonly ? '0.6' : '1'};" `;
		}
		let idxRef = `data-idx="0"`;
		style += `${readonly ? 'title="' + title + '"' : ''} data-title='${title}' ${includeIdx ? idxRef : ''} data="${check.check_function}" >${subject}</mark>`;
		return style;
	},
	spellCheckDataExists(tp_id, data){
		if(data.length > 0){
		
		let spellChecks = data.find(x => x.type === "spell_check" && x.tp_id === tp_id);
			if(spellChecks !== undefined && spellChecks.data.length > 0){
				return spellChecks;
			}
		}

		return null;
	},
	replaceAt(text, word, idx, spellItem, spellIdx, tmpl_part, doc_part){
		let replacement = `<mark class='nlpData' data-correction-type='spellcheck' data-language-reference='${tmpl_part}_${doc_part}_${spellIdx}'>${word}</mark>`;
		let length = word.length;
		let newText = text.substring(0, idx) + replacement + text.substring((idx+length));
		return newText;
	},
	escapeRegExpString(str){
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	},
	applyLanguageHighlights(text,data,tmpl_part_id, doc_part_id, dictionary){
		if(data !== null){
			let tmpl_part = data.find(x => x.tmpl_part_id === tmpl_part_id);
			if(tmpl_part){
				if(tmpl_part.parts.length === 0){
					return text;
				}
				let dp = tmpl_part.parts.find(x => x.doc_part_id === doc_part_id);
				if(dp){

					let dictionaryExceptions = dictionary.filter(x => x.type === "spellcheck" && x.active).map(x => { return x.word });
					let issueList = dp.issues.filter(function(x) { 
						return dictionaryExceptions.indexOf(x.word) < 0;
					});

					issueList.forEach(i => { 
						i.variations.forEach((v) => {
							let reg =  new RegExp(this.escapeRegExpString(i.word), 'gim');
							let result = text.match(reg);
							if(result){
								if(result.length === 1){
									text = text.replace(new RegExp(reg, 'gim'), `<mark class='nlpData' data-correction-type='spellcheck' data-language-reference='${tmpl_part_id}_${doc_part_id}_${v.idx}'>${result[0]}</mark>`)
								} else {
									let contextRegex = new RegExp(`(?:${this.escapeRegExpString(i.word)})`, 'gim');
									var matched = false;
									var matchResult;
									while ((matchResult = contextRegex.exec(text)) && !matched) {
										let test = text.substr(0,matchResult.index).replace(/<\/?[^>]+(>|$)/g, "").length === v.offset;
										if(test){
											matched = true;
											text = this.replaceAt(text, matchResult[0], matchResult.index, v, v.idx,tmpl_part_id, doc_part_id);
										}
									}
								}
							}

						})
					});	
				}
			}
		
		}

		return text;
	},
	applySpellCheckHighlight(text, data){
		if(data !== null){
			data.forEach(s => { 
				let reg =  new RegExp(s.word, 'gm');
				let result = text.match(reg);
				if (result){
					text = text.replace(new RegExp(reg, 'gm'), `<mark class='nlpData'>${result[0]}</mark>`)
				}
			});			
		}

		return text;
	},

	applyGrammarCheckHighlight(text) {
		return `<mark class="nlpDataGrammar">${text}</mark>`;
	},

	/// *********************************************************************************
	/// Please ensure any changes here are also made to the utils.js in the portal project.
	/// *********************************************************************************
	doChecks(text, checks, readonly, isIE, doc) {
		text = text || "";
		let raw = text;
		text = this.sanitize(text);
		let response = { highlightedText: text, warnings: [], errors: [], text: raw };
		let self = this;
		text = text.replace(/\n$/g, "\n\n");
		let firstWord = text.split(" ")[0];

		let sentenceLengthChecks = checks.filter(x => x.check_function === "sentence_length" && x.check_value);
		if (sentenceLengthChecks.length) {
			let sentences = [...text.matchAll(/[!?.•]{1}(?=$|\s)/g)].map(x => { return { text: x[0], index: x.index }; });
			if (!sentences.length) {
				sentences.push({ text: "", index: text.length + 1 });
			} else if (sentences[sentences.length - 1].index < text.length - 1) {
				sentences.push({ text: "", index: text.length + 1 });
			}
			let processedSentences = "";
			let prev = 0;
			sentences.forEach(s => {
				let subject = text.substring(prev, s.index + s.text.length - 1) + s.text;
				let len = subject.split(" ").filter(x => x).length;
				let index = 0;
				let result;
				while (index >= 0 && index < sentenceLengthChecks.length) {
					let check = sentenceLengthChecks[index];
					if ((len > check.check_value && check.avoid) || (len < check.check_value && !check.avoid)) {
						let desc = `${check.avoid ? "Long" : "Short"} Sentence`;
						let msg = { check: check.check_function, category: check.category, category_weighting: check.category_weighting, title: `${check.title} - ${desc}`, subject: "", description: `Sentence ${check.avoid ? "more" : "less"} than ${check.check_value} words`, short_description: desc, colour: check.colour, effect: check.effect, count: 0, weighting: check.weighting, instances: [] };
						this.pushAlert(response, msg, check.is_error);
						result = this.getMarkStyle(check, readonly, msg.short_description, subject);
						index = -1;
					} else {
						result = subject;
						index++;
					}
				}
				prev = s.index + s.text.length;
				processedSentences += result;
			});
			response.highlightedText = processedSentences;
		}
		let lengthChecks = checks.filter(x => x.check_function === "word_count" && x.check_value);
		if (lengthChecks.length) {
			let wordcount = self.countWords(raw);
			let index = 0;
			let result;
			while (index >= 0 && index < lengthChecks.length) {
				let check = lengthChecks[index];
				if ((wordcount > check.check_value && check.avoid) || (wordcount < check.check_value && !check.avoid)) {
					let desc = `${check.avoid ? "Long" : "Short"} Paragraph`;
					let msg = { check: check.check_function, category: check.category, category_weighting: check.category_weighting, title: `${check.title} - ${desc}`, subject: raw, description: `${check.avoid ? "Maximum" : "Minimum"} of ${check.check_value.toFixed(0)} words ${check.is_error ? "permitted" : "recommended"}`, short_description: desc, colour: check.colour, effect: check.effect, count: 0, weighting: check.weighting, instances: [] };
					this.pushAlert(response, msg, check.is_error);
					result = this.getMarkStyle(check, readonly, msg.short_description, response.highlightedText);
					index = -1;
				} else {
					result = response.highlightedText;
					index++;
				}
			}
			response.highlightedText = result;
		}
		let charCheck = checks.filter(x => x.check_function === "char_count" && x.check_value);
		if (charCheck.length) {
			let charCount = self.countChars(raw);
			let index = 0;
			let result;
			while (index >= 0 && index < charCheck.length) {
				let check = charCheck[index];
				if ((charCount > check.check_value && check.avoid) || (charCount < check.check_value && !check.avoid)) {
					let desc = `${check.avoid ? "Too many" : "Not enough"} Characters`;
					let msg = { check: check.check_function, category: check.category, category_weighting: check.category_weighting, title: `${check.title} - ${desc}`, subject: raw, description: `${check.avoid ? "Maximum" : "Minimum"} of ${check.check_value.toFixed(0)} characters ${check.is_error ? check.avoid ? "allowed" : "needed" : "recommended"} - current ` + charCount, short_description: desc, colour: check.colour, effect: check.effect, count: 0, weighting: check.weighting, instances: [] };
					this.pushAlert(response, msg, check.is_error);
					result = this.getMarkStyle(check, readonly, msg.short_description, response.highlightedText);
					index = -1;
				} else {
					result = response.highlightedText;
					index++;
				}
			}
			response.highlightedText = result;
		}
		let flexChecks = checks.filter(x => x.check_function === "flex" && x.check_value);
		if (flexChecks.length && doc && doc.flexscore !== null && doc.flexscore !== undefined) {
			let failedCheck = null;
			if (doc.flexCategories.length) {
				doc.flexCategories
					.forEach(c => {
						let processed = false;
						flexChecks.forEach(check => {
							if (!processed && (check.title === c.section_name || !doc.flexCategories.some(c => check.title === c.section_name))) {
								if ((Number(c.score) > check.check_value && check.avoid) || (Number(c.score) < check.check_value && !check.avoid)) {
									const desc = `${c.section_name} - ${c.outcome}`;
									let msg = { check: check.check_function, category: check.category, category_weighting: check.category_weighting, title: desc, subject: raw, description: `${check.avoid ? "Maximum" : "Minimum"} score of ${check.check_value.toFixed(0)} ${check.is_error ? "required" : "recommended"}`, short_description: desc, colour: check.colour, effect: check.effect, count: 0, weighting: check.weighting, instances: [] };
									this.pushAlert(response, msg, check.is_error, null, doc.flexscore);
									processed = true;
									failedCheck = failedCheck || check;
								}
							}
						});
					});				
			// } else {
			// 	const desc = "Flex Algorithm Not Completed";
			// 	const check = flexChecks[0];
			// 	let msg = { check: check.check_function, category: check.category, category_weighting: check.category_weighting, title: desc, subject: raw, description: `${check.avoid ? "Maximum" : "Minimum"} score of ${check.check_value.toFixed(0)} ${check.is_error ? "required" : "recommended"}`, short_description: desc, colour: check.colour, effect: check.effect, count: 0, weighting: check.weighting, instances: [] };
			// 	this.pushAlert(response, msg, check.is_error, null, doc.flexscore);
			}
			if (failedCheck) {
				response.highlightedText = this.getMarkStyle(failedCheck, readonly, `${failedCheck.avoid ? "High Score" : "Low Score"}`, response.highlightedText);
			}
		}
		checks.filter(x => x.check_function !== "word_count" && x.check_function !== "sentence_length" && x.check_function !== "flex" && x.check_function !== "flag_count").forEach(w => {
			let before = response.highlightedText.replace("● ","");
			let hasIssue = false;
			let testText = w.first_word_check ? firstWord : response.highlightedText.replace("● ","");
			if (w.check_function === "word_list") {
				if (w.avoid) {
					let whitelistReplacements = [];
					//remove whitelist phrases
					w.whitelist_phrases?.forEach((p) => {
						testText.match(p.regex)?.map(r => {
								return {
									phrase: r,
									replacement: `{${this.makeid(8)}}`
								}
							})?.forEach(e => {
								testText = testText.replace(e.phrase, e.replacement);
								whitelistReplacements.push(e);
							});
					});

					let match = testText.match(w.regex);
					if(match?.length > 1){
						let newMark = this.getMarkStyle(w, readonly, w.short_description, "$&", true);
						testText = testText.replace(
							w.regex,
							newMark
						);						
						match?.forEach((m,idx) => {
							testText = testText.replace('data-idx="0"',`data-idx="${idx+1}"`);
						})
					} else {
						testText = testText.replace(
							w.regex,
							this.getMarkStyle(w, readonly, w.short_description, "$&")
						);
					}

					//reinstate whitelist phrases
					whitelistReplacements.forEach((e) => {
						testText = testText.replace(e.replacement, e.phrase);
					});
				} else if (w.include && testText) {
					if (!testText.match(w.regex)) {
						if (w.first_word_check) {
							testText = this.getMarkStyle(w, readonly, w.short_description, testText);
						} else {
							hasIssue = true;
						}
					}
				}
				if (w.first_word_check) {
					let parts = before.split(" ");
					let windex = parts.findIndex(x => x.indexOf(">" + firstWord) >= 0);
					let yindex = parts.findIndex(x => x.indexOf(firstWord + "<mark") >= 0);

					if (windex >= 0)
						parts[windex] = ">" + testText;
					else if (yindex >= 0)
						parts[yindex] = testText + "<mark";
					else
						parts[0] = testText;
					
					response.highlightedText = parts.join(" ");
				} else {
					response.highlightedText = testText;
				}
				if (!hasIssue) hasIssue = before !== response.highlightedText;
			} else if (w.check_function === "infCheck") {
				let issue = self.infCheck(raw, w.alternatives);
				hasIssue = (issue && w.avoid) || (raw && !issue && w.include);
				if (hasIssue) {
					let repl = raw.split(" ")[0];
					let repf = this.getMarkStyle(w, readonly, w.short_description, repl);
					response.highlightedText = response.highlightedText.replace(repl, repf);
				}
			}
			if (hasIssue) {
				self.pushWordAlert(response, w);
			}
		});
		if (isIE) {
			response.highlightedText = response.highlightedText.replace(/ /g, " <wbr>");
		}
		return response;
	},
	/// *********************************************************************************
	/// Please ensure any changes here are also made to the utils.js in the portal project.
	/// *********************************************************************************
	pluralise(text, revert) {
		const plural = {
			'(quiz)$': "$1zes",
			'^(ox)$': "$1en",
			'([m|l])ouse$': "$1ice",
			'(matr|vert|ind)ix|ex$': "$1ices",
			'(x|ch|ss|sh)$': "$1es",
			'([^aeiouy]|qu)y$': "$1ies",
			'(hive)$': "$1s",
			'(?:([^f])fe|([lr])f)$': "$1$2ves",
			'(shea|lea|loa|thie)f$': "$1ves",
			'sis$': "ses",
			'([ti])um$': "$1a",
			'(tomat|potat|ech|her|vet)o$': "$1oes",
			'(bu)s$': "$1ses",
			'(alias)$': "$1es",
			'(octop)us$': "$1i",
			'(ax|test)is$': "$1es",
			'(us)$': "$1es",
			'([^s]+)$': "$1s"
		};

		const singular = {
			'(quiz)zes$': "$1",
			'(matr)ices$': "$1ix",
			'(vert|ind)ices$': "$1ex",
			'^(ox)en$': "$1",
			'(alias)es$': "$1",
			'(octop|vir)i$': "$1us",
			'(cris|ax|test)es$': "$1is",
			'(shoe)s$': "$1",
			'(o)es$': "$1",
			'(bus)es$': "$1",
			'([m|l])ice$': "$1ouse",
			'(x|ch|ss|sh)es$': "$1",
			'(m)ovies$': "$1ovie",
			'(s)eries$': "$1eries",
			'([^aeiouy]|qu)ies$': "$1y",
			'([lr])ves$': "$1f",
			'(tive)s$': "$1",
			'(hive)s$': "$1",
			'(li|wi|kni)ves$': "$1fe",
			'(shea|loa|lea|thie)ves$': "$1f",
			'(^analy)ses$': "$1sis",
			'((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$': "$1$2sis",
			'([ti])a$': "$1um",
			'(n)ews$': "$1ews",
			'(h|bl)ouses$': "$1ouse",
			'(corpse)s$': "$1",
			'(us)es$': "$1",
			's$': ""
		};

		const irregular = {
			'move': 'moves',
			'foot': 'feet',
			'goose': 'geese',
			'sex': 'sexes',
			'child': 'children',
			'man': 'men',
			'tooth': 'teeth',
			'person': 'people'
		};

		const uncountable = [
			'sheep',
			'fish',
			'deer',
			'moose',
			'series',
			'species',
			'money',
			'rice',
			'information',
			'equipment'
		];

		// save some time in the case that singular and plural are the same
		if (uncountable.indexOf(text.toLowerCase()) >= 0)
			return text;

		// check for irregular forms
		let word;
		for (word in irregular) {
			let pattern = revert ? new RegExp(irregular[word] + '$', 'i') : new RegExp(word + '$', 'i');
			let replace = revert ? word : irregular[word];
			if (pattern.test(text))
				return text.replace(pattern, replace);
		}

		let array = revert ? singular : plural;

		// check for matches using regular expressions
		let reg;
		for (reg in array) {
			let pattern = new RegExp(reg, 'i');
			if (pattern.test(text))
				return text.replace(pattern, array[reg]);
		}
		return text;
	},
	/// *********************************************************************************
	/// Please ensure any changes here are also made to the utils.js in the portal project.
	/// *********************************************************************************
	getFlagCounter(partTypeChecks, partType) {
		let checks = partTypeChecks.filter(x => x.check_function === "flag_count");
		let ret = { minWarn: null, maxWarn: null, minError: null, maxError: null, warningText: "", errorText: "", checks: checks, required: 0, current: 0, instances: [] };
		if(checks !== undefined && checks.length > 0){
			checks.forEach(c => {
				let currentCheck = c;
				ret.minError = currentCheck.check_value;
		
				let allPartsValues = partType.parts.filter(x => { return x.text !== '' }).map(pt => {
					let value = pt.is_essential;
					return {
						dp_id: pt.doc_part_id || pt.dp_id,
						value: value
					};
				})
				let dataMismatches = allPartsValues.filter(x => {
					let value = null
					return x.value !== value;
				});
				
				let dataMatches = allPartsValues.filter(x => {
					let value = null
					return x.value === value;
				});
	
				let currentValue = dataMismatches.length;	
				let affectedParts = dataMatches.map(pt => {
					return pt.dp_id;
				})
				
				let self = this;
				if(currentCheck.check_value === 0){
					//all must match
					if(currentValue !== allPartsValues.length){
						ret.required = allPartsValues.length;
						ret.current = currentValue;
						ret.errorText = `${allPartsValues.length} valid ${self.pluralise(partType.type)} required - current ${currentValue}`;	
						ret.instances.push({
							count: affectedParts.length,
							partsAffected: affectedParts,
							subject: ""
						});
					}
				} else {
					if(currentValue < currentCheck.check_value){
						ret.required = currentCheck.check_value;
						ret.current = currentValue;
						ret.errorText = `${currentCheck.check_value} valid ${self.pluralise(partType.type)} required - current ${currentValue}`;		
						ret.instances.push({
							count: affectedParts.length,
							partsAffected: affectedParts,
							subject: ""
						});
					}
				}
			})
		}

		return ret;
	},
	/// *********************************************************************************
	/// Please ensure any changes here are also made to the utils.js in the portal project.
	/// *********************************************************************************
	getPartTypeMinMax(partTypeChecks, rowCount, partType) {
		let checks = partTypeChecks.filter(x => x.check_function === "row_count" && x.check_value);
		let ret = { minWarn: null, maxWarn: null, minError: null, maxError: null, warningText: "", errorText: "", checks: checks };
		ret.minWarn = checks.find(c => c.include && c.is_error === 0) || 0;
		if (ret.minWarn) ret.minWarn = ret.minWarn.check_value;
		ret.minError = checks.find(c => c.include && c.is_error === 1) || 0;
		if (ret.minError) ret.minError = ret.minError.check_value;
		ret.maxWarn = checks.find(c => c.avoid && c.is_error === 0) || 0;
		if (ret.maxWarn) ret.maxWarn = ret.maxWarn.check_value;
		ret.maxError = checks.find(c => c.avoid && c.is_error === 1) || 0;
		if (ret.maxError) ret.maxError = ret.maxError.check_value;
		let current = rowCount >= 0 ? ` (${rowCount})` : "";

		if (ret.maxError === 0 && partType.tmpl_part_metadata && partType.tmpl_part_metadata.parent_tp_id) ret.maxError = 99;

		if (ret.minWarn > 1 || ret.maxWarn > 1) {
			if (ret.minWarn && ret.maxWarn) {
				ret.warningText = `${current} - recommended ${ret.minWarn} to ${ret.maxWarn}`;
			} else if (ret.minWarn) {
				ret.warningText = `${current} - recommended ${ret.minWarn}`;
				if (ret.maxError) ret.warningText += `, maximum ${ret.maxError}`;
			} else if (ret.maxWarn) {
				ret.warningText = `${current} - recommended ${ret.maxWarn}`;
				if (ret.maxError) ret.warningText += `, maximum ${ret.maxError}`;
			}
			//if (rowCount >= 0) ret.warningText = `${ret.warningText} (currently ${rowCount})`;
		}
		if (ret.minError > 1 || ret.maxError > 1) {
			if (ret.minError && ret.maxError) {
				ret.errorText = `${current} - ${ret.minError} to ${ret.maxError} permitted`;
			} else if (ret.minError) {
				ret.errorText = `${current} - minimum ${ret.minError}`;
				if (ret.maxWarn) ret.errorText += `, recommended ${ret.maxWarn}`;
			} else if (ret.maxError) {
				ret.errorText = `${current} - maximum ${ret.maxError} `;
				if (ret.maxWarn) ret.errorText += `, recommended ${ret.maxWarn}`;
			}
			//if (rowCount >= 0) ret.errorText = `${ret.errorText} (currently ${rowCount})`;
		}
		return ret;
	},
	/// *********************************************************************************
	/// Please ensure any changes here are also made to the utils.js in the portal project.
	/// *********************************************************************************
	doPartTypeCheck(partType, checks, doc) {
		let result = [];
		let flagRules = [];
		checks.sort((a, b) => a.is_error > b.is_error ? 1 : a.is_error > b.is_error ? -1 : 0);
		let populatedRows = partType.parts.filter(x => x.text && (!x.attributes || x.attributes.every(a => !a.required || a.text))).length;
		let minMaxRule = this.getPartTypeMinMax(checks, populatedRows, partType);
		
		let flagRule = this.getFlagCounter(checks, partType);
		let self = this;
		flagRule.checks.forEach(check => {
			const diff = flagRule.current - flagRule.required;
			if ((diff > 0 && check.avoid) || (diff < 0 && !check.avoid)) {
				let desc = "";
				let shortdesc = "";
				if (check.include) {
					shortdesc = `Missing ${partType.type} Essential Flag`;
					desc = `Missing ${partType.type} Essential Flag - ${check.is_error ? flagRule.errorText : flagRule.warningText}`;
				} else {
					shortdesc = `Too many ${self.pluralise(partType.type)}`;
					desc = `Too many ${self.pluralise(partType.type)}${check.is_error ? flagRule.errorText : flagRule.warningText}`;
				}
				let weighting = Number(check.weighting);
				if (check.excess_count_impact) {
					weighting = weighting + (diff * check.excess_count_impact);
				}				
				if (!flagRules.some(r => r.short_description === shortdesc && r.category === check.category)) {
					flagRules.push({
						category: check.category,
						category_weighting: check.category_weighting,
						title: "Essential Flag",
						check: check.check_function,
						short_description: shortdesc,
						description: desc,
						effect: check.effect,
						colour: check.colour,
						count: 1,
						subject: "",
						instances: flagRule.instances,
						sort: "ZZZZ",
						isError: check.is_error,
						weighting: weighting
					});
				}				
			}
		});

		minMaxRule.checks.sort((a, b) => a.is_error < b.is_error ? 1 : 0).forEach(check => {
			const diff = populatedRows - check.check_value;
			if ((diff > 0 && check.avoid) || (diff < 0 && !check.avoid)) {
				let desc = "";
				let shortdesc = "";
				if (check.include) {
					shortdesc = `Missing ${partType.type}`;
					desc = `Missing ${partType.type}${check.is_error ? minMaxRule.errorText : minMaxRule.warningText}`;
				} else {
					shortdesc = `Too many ${self.pluralise(partType.type)}`;
					desc = `Too many ${self.pluralise(partType.type)}${check.is_error ? minMaxRule.errorText : minMaxRule.warningText}`;
				}
				let weighting = Number(check.weighting);
				if (check.excess_count_impact) {
					weighting = weighting + (diff * check.excess_count_impact);
				}
				if (!result.some(r => r.short_description === shortdesc && r.category === check.category)) {
					result.push({
						category: check.category,
						category_weighting: check.category_weighting,
						title: check.title,
						check: check.check_function,
						short_description: shortdesc,
						description: desc,
						effect: check.effect,
						colour: check.colour,
						count: 1,
						subject: "",
						instances: [],
						sort: "ZZZZ",
						isError: check.is_error,
						weighting: weighting
					});
				}
			}
		});
		let flexCompleted = checks.find(x => x.check_function === "flex_completion");
		if (flexCompleted && doc && ((doc.flexIncomplete && flexCompleted.include) || (!doc.flexIncomplete && flexCompleted.avoid))) {
			const desc = `Flex Algorithm ${flexCompleted.avoid ? "" : "Not"} Completed`;
			result.push({
				category: flexCompleted.category,
				category_weighting: flexCompleted.category_weighting,
				title: flexCompleted.title,
				check: flexCompleted.check_function,
				short_description: desc,
				description: desc,
				effect: flexCompleted.effect,
				colour: flexCompleted.colour,
				count: 1,
				subject: "",
				instances: [],
				sort: "ZZZZ",
				isError: flexCompleted.is_error,
				weighting: flexCompleted.weighting
			});
		}
		return { issues: result, minMaxRule: minMaxRule, flagRules: flagRules, flexCompletedRule: flexCompleted };
	},
	/// *********************************************************************************
	/// Please ensure any changes here are also made to the utils.js in the portal project.
	/// *********************************************************************************
	documentCheckSummary(document, checks, view, tmpl_parts = undefined) {
		let mergeOption = view;
		let status = { parts: [], errors: [], warnings: [], categories: [], maxIssues: 0, issueCount: 0, issuePartsCount: 0, score: 0, scoreColour: "", impactScore: 0 };
		status.categories = checks.reduce((prev, cur) => {
			if (!prev.some(x => x.category === cur.category))
				prev.push({ category: cur.category, category_weighting: cur.category_weighting, rep_sort: cur.category_report_order, panel_sort: cur.category_panel_order, errorCount: 0, warningCount: 0, titles: [] });
			return prev;
		}, []);
		let mergeIssue1 = function (item, isError) {
			let category = status.categories.find(x => x.category === item.category);
			if (!category) {
				category = { category: item.category, errorCount: 0, warningCount: 0, titles: [] };
				status.categories.push(category);
			}

			let title = category.titles.find(x => x.title === item.title);
			if (!title) {
				title = { title: item.title, errorCount: 0, warningCount: 0, issues: [] };
				category.titles.push(title);
			}
			title.issues.push(item);

			if (isError) { category.errorCount++; title.errorCount++; }
			else { category.warningCount++; title.warningCount++; }

		};
		let mergeIssue2 = function (item, isError) {
			let category = status.categories.find(x => x.category === item.category);
			if (!category) {
				category = { category: item.category, errorCount: 0, warningCount: 0, titles: [], sort: item.sort || item.category };
				status.categories.push(category);
			}

			let title = category.titles.find(x => x.title === item.title && x.effect === item.effect && x.colour === item.colour);
			if (!title) {
				title = { title: item.title, errorCount: 0, warningCount: 0, issues: [], effect: item.effect, colour: item.colour };
				category.titles.push(title);
			}

			let issue = title.issues.find(x => x.description === item.description);
			if (!issue) {
				issue = { description: item.description, errorCount: 0, warningCount: 0, items: [] };
				title.issues.push(issue);
			}
			
			let issueCount = 0;
			item.instances.forEach(x => {
				issueCount += (item.check !== "row_count" && x.partsAffected && x.partsAffected.length) ? x.partsAffected.length : 1;
			});
			item.errorCount = isError ? issueCount : 0;
			item.warningCount = isError ? 0 : issueCount;	
			category.errorCount += item.errorCount;
			title.errorCount += item.errorCount;
			issue.errorCount += item.errorCount;		
			category.warningCount += item.warningCount;
			title.warningCount += item.warningCount;
			issue.warningCount += item.warningCount;
			
			let existingItem = issue.items.find(x => x.subject === item.subject);
			if (!existingItem) {
				issue.items.push(item);
			} else {
				existingItem.errorCount += item.errorCount;
				existingItem.warningCount += item.warningCount;
			}
			
			if (item.score) {
				category.score = item.score;
			}

			status.maxIssues = title.errorCount + title.warningCount > status.maxIssues ? title.errorCount + title.warningCount : status.maxIssues;

		};
		let mergeIssue = mergeOption === 1 ? mergeIssue1 : mergeIssue2;
		let doPartStatus = (pt, p, pi, minMaxRule) => {
			let pstatus = null;
			let pChecks = checks.filter(w =>
				w.tmpl_part_types.some(
					(tp) => tp.tp_id === pt.tmpl_part_id &&
						(!tp.exclusions.length || !document.hierarchies.some(h => tp.exclusions.some(e => e.hr_id === h.hr_id)))
				)
			);
			if (p.attributes && p.attributes.length) {
				p.attributes.forEach(a => {

					pChecks = checks.filter(w =>
						w.tmpl_part_types.some(
							(tp) => tp.tp_id === pt.tmpl_part_id &&
								(!tp.exclusions.length || !document.hierarchies.some(h => tp.exclusions.some(e => e.hr_id === h.hr_id)))
						)
					);
					let sourceAttr = pt.tmpl_part_metadata.attributes.find(x => x.tpa_id === a.tpa_id);
					if(sourceAttr && sourceAttr.skip_checks.length > 0){
						pChecks = pChecks.filter(x => !sourceAttr.skip_checks.includes(x.check_type_label))
					}			
					let astat = this.doChecks(a.text, pChecks, true, false, document);
					if (!pstatus) {
						pstatus = astat;
					} else {
						pstatus.errors = [...pstatus.errors, ...astat.errors];
						pstatus.warnings = [...pstatus.warnings, ...astat.warnings];
						pstatus.text += " - " + astat.text;
					}
				});
			} else {
				pstatus =this.doChecks(p.text, pChecks, true, false, document);
			}
			status.parts.push({
				part: pt.type + (minMaxRule && minMaxRule.maxError > 1 ? ' - ' + (pi + 1) : ''),
				doc_part_id: p.doc_part_id || p.dp_id,
				status: JSON.parse(JSON.stringify(pstatus))
			});
			pstatus.errors.forEach(e => {
				e.errorCount = 0; 
				e.warningCount = 0;
				e.impactScore = 0;
				e.count--;
				this.pushAlert(status, e, true, [p.doc_part_id || p.dp_id], e.score);
				mergeIssue(e, true);
			});
			pstatus.warnings.forEach(e => {
				e.errorCount = 0;
				e.warningCount = 0; 
				e.impactScore = 0;
				e.count--;
				this.pushAlert(status, e, false, [p.doc_part_id || p.dp_id], e.score);
				mergeIssue(e, false);
			});
		};
		document.parttypes.forEach(pt => {
			if(tmpl_parts == undefined || tmpl_parts.includes(pt.tmpl_part_id)){
				let ptCheck = this.doPartTypeCheck(pt, checks.filter(x => x.tmpl_part_types.some(
					(tp) => tp.tp_id === pt.tmpl_part_id &&
						(!tp.exclusions.length || !document.hierarchies.some(h => tp.exclusions.some(e => e.hr_id === h.hr_id)))
				)), document);
				ptCheck.issues.forEach(pti => {
					pti.errorCount = 0;
					pti.warningCount = 0;
					pti.count--;
					this.pushAlert(status, pti, pti.isError, pt.parts.map(p => p.doc_part_id || p.dp_id));
					mergeIssue(pti, pti.isError);
				});

				ptCheck.flagRules.forEach(pti => {
					pti.errorCount = 0;
					pti.warningCount = 0;
					pti.count--;
					this.pushAlert(status, pti, pti.isError, null);
					mergeIssue(pti, pti.isError);
				});

				pt.parts.forEach((p, pi) => {
					doPartStatus(pt, p, pi, ptCheck.minMaxRule);
					if (pt.childPartTypes.length) {
						pt.childPartTypes.forEach(cpt => {
							p.childParts
								.filter(x => x.tmpl_part_id === cpt.tmpl_part_id)
								.forEach((c, ci) => {

									let partList = p.childParts.filter(cp => cp.text);
									let childPartTypeChecks = this.doPartTypeCheck({
										...cpt,
										parts: partList
									}, checks.filter(x => x.tmpl_part_types.some(
										(tp) => tp.tp_id === cpt.tmpl_part_id &&
											(!tp.exclusions.length || !document.hierarchies.some(h => tp.exclusions.some(e => e.hr_id === h.hr_id)))
									)), document);

									childPartTypeChecks.issues.forEach(pti => {
										pti.errorCount = 0;
										pti.warningCount = 0;
										pti.count--;
										this.pushAlert(status, pti, pti.isError, partList.map(p => p.doc_part_id || p.dp_id));
										mergeIssue(pti, pti.isError);
									});
									
									doPartStatus(cpt, c, ci, null);
								})
						})
					}
				});
			}

		});
		status.categories = status.categories.sort((a, b) => a.panel_sort > b.panel_sort ? 1 : a.panel_sort < b.panel_sort ? -1 : 0);

		let getScore = (impact) => {
			let ret = { score: 0, scoreColour: "red" };
			let effectBands = [
				{ impact: 4, minPct: 75, maxPct: 100, fromImpact: 0 },
				{ impact: 9, minPct: 50, maxPct: 75, fromImpact: 4 },
				{ impact: 15, minPct: 30, maxPct: 50, fromImpact: 9 },
				{ impact: 30, minPct: 10, maxPct: 30, fromImpact: 15 },
				{ impact: 40, minPct: 7, maxPct: 10, fromImpact: 30 },
				{ impact: 100, minPct: 0, maxPct: 7, fromImpact: 40 }
			];
			let band = effectBands.find(e => e.impact >= impact);
			if (band) {
				ret.score = band.minPct + (band.maxPct - band.minPct) * (band.impact - impact) / (band.impact - band.fromImpact);
			}
			ret.score = Math.round(ret.score);
			ret.scoreColour = getScoreColour(ret.score);
			return ret;
		};
		let getScoreColour = (score) => {
			let colourBands = [{ from: 80, to: 101, colour: "green" }, { from: 50, to: 80, colour: "amber" }, { from: 0, to: 50, colour: "red" }];
			let colBand = colourBands.find(b => score >= b.from && score < b.to);
			return colBand ? colBand.colour : "red";
		};
		let totalInstances = 0;
		let totalPartsAffected = 0;
		let impact = 0;
		status.categories.forEach(c => {
			let imp = 0; //(c.warningCount + (c.errorCount * 3)) * c.category_weighting;
			c.titles.forEach(t => {
				t.issues.forEach(i => {
					i.items.forEach(it => {
						it.impactScore = (it.warningCount + (it.errorCount * 3)) * it.weighting;
						imp += (it.warningCount + (it.errorCount * 3)) * it.weighting;
						it.instances.forEach(ins => {
							totalPartsAffected += ins.partsAffected.length;
							totalInstances += ins.count;
						});
					});
				});
			});
			if (!c.score) {
				let cScore = getScore(imp);
				c.score = cScore.score;
				c.scoreColour = cScore.scoreColour;
			} else {
				c.scoreColour = getScoreColour(c.score);
			}
			c.impactScore = imp;
			impact += (imp * c.category_weighting);
		});
		status.issueCount = totalInstances;
		status.issuePartsCount = totalPartsAffected;
		let overall = getScore(impact);
		status.score = overall.score;
		status.scoreColour = overall.scoreColour;
		return status;
	},
	qualities: [
		{ text: 'Always Exclude', value: -2 },
		{ text: 'Auto Excluded', value: -1 },
		{ text: 'Always Include', value: 10 },
		{ text: 'Auto Included', value: 0 }
	],
	translateOptions: [
		{ text: "Afrikaans", value: "af", flagCode: "na" },
		{ text: "Albanian", value: "sq", flagCode: "al" },
		{ text: "Arabic", value: "ar", flagCode: "eg" },
		{ text: "Chinese (Simplified)", value: "zh", flagCode: "cn" },
		{ text: "Chinese (Traditional)", value: "zh-TW", flagCode: "cn" },
		{ text: "Croatian", value: "hr", flagCode: "hr" },
		{ text: "Czech", value: "cs", flagCode: "cz" },
		{ text: "Danish", value: "da", flagCode: "dk" },
		{ text: "Dutch", value: "nl", flagCode: "nl" },
		{ text: "English", value: "en", flagCode: "gb" },
		{ text: "French", value: "fr", flagCode: "fr" },
		{ text: "German", value: "de", flagCode: "de" },
		{ text: "Greek", value: "el", flagCode: "gr" },
		{ text: "Hindi", value: "hi", flagCode: "in" },
		{ text: "Hungarian", value: "hu", flagCode: "hu" },
		{ text: "Indonesian", value: "id", flagCode: "id" },
		{ text: "Italian", value: "it", flagCode: "it" },
		{ text: "Japanese", value: "ja", flagCode: "jp" },
		{ text: "Korean", value: "ko", flagCode: "kr" },
		{ text: "Malay", value: "ms", flagCode: "my" },
		{ text: "Polish", value: "pl", flagCode: "pl" },
		{ text: "Portuguese (Brazil)", value: "pt", flagCode: "pt" },
		{ text: "Portuguese (Portugal)", value: "pt-PT", flagCode: "pt" },
		{ text: "Romanian", value: "ro", flagCode: "ro" },
		{ text: "Russian", value: "ru", flagCode: "ru" },
		{ text: "Spanish", value: "es", flagCode: "es" },
		{ text: "Spanish (Mexico)", value: "es-MX", flagCode: "mx" },
		{ text: "Swedish", value: "sv", flagCode: "se" },
		{ text: "Thai", value: "th", flagCode: "th" },
		{ text: "Turkish", value: "tr", flagCode: "tr" },
		{ text: "Vietnamese", value: "vi", flagCode: "vn" },
		{ text: "Welsh", value: "cy", flagCode: "gb-wls" },
	],
	getLanguageFromCode(code) {
		return this.translateOptions.find((x) => x.value === code)
			|| { text: "English", value: "en", flagCode: "gb" }
	},
	async fetchDocumentData(documentId, statuses, targetLang, selectedTranslateLang, viewAction, store){
		let response = {};
		await axios
			.post("document/getDetail", { reference: documentId, viewAction: viewAction })
			.then(async (resp) => {
			if (!resp.data.Data.documents || resp.data.Data.documents.length === 0) {
				response.Message = `Error loading document data: ${resp.data.Message}`;
				response.Status = "Error";
			} else if (resp.data.Status === "OK") {
				response.Status = "OK";
				if (targetLang && selectedTranslateLang !== "en") {
					response.documentLanguage = this.getLanguageFromCode(selectedTranslateLang);
				} else {
					response.documentLanguage = this.getLanguageFromCode(resp.data.Data.documents[0].language_code);
				}

				response.hierarchyTypes = resp.data.Data.hierarchyTypes;
				response.wizardSettings = resp.data.Data.wizardSettings;
				response.openTasks = resp.data.Data.openTasks;
				response.partsHistory = resp.data.Data.documents[0].DPHistory || [];
				if (store && resp.data.Data.documents && resp.data.Data.documents.length && (resp.data.Data.documents[0].language_code === "" || resp.data.Data.documents[0].language_code === "en"))
					await store.dispatch("wordChecks/getTemplateWordChecks", resp.data.Data.documents[0].tmpl_id)
						.then((resp) => {
							response.wordChecks = resp;
						});
				else
					response.wordChecks = [];

				// response.wordChecks = this.initialisePartChecks(
				// 	resp.data.Data.documents[0].wordChecks
				// );
				response.documentIssueDisplay = resp.data.Data.settings.find(
					(x) => x.setting === "document_issue_display"
				);

				if (response.documentIssueDisplay)
					response.documentIssueDisplay = response.documentIssueDisplay.value;

				response = {
					...response,
					...this.setupDocument(resp.data.Data.documents, response.wordChecks, statuses)
				}

				let translateSetting = resp.data.Data.settings.find(
					(x) => x.setting === "UseTranslation"
				);
				if (translateSetting) {
					response.useTranslation = translateSetting.value == "true" ? true : false;
				} else {
					response.useTranslation = false;
				}

				response.nlpFunctionExclusions = resp.data.Data.documents[0].nlpFunctionExclusions;
				response.uploadedDocLink = resp.data.Data.documents[0].uploadedDocLink;
				response.spell_check_language_code = resp.data.Data.documents[0].spell_check_language_code;
				response.exportSections = resp.data.Data.documents[0].exportSections;
				response.exportConfig = resp.data.Data.documents[0].exportConfig;
				response.doc_view_config = resp.data.Data.documents[0].doc_view_config;

				if (resp.data.Data.documents[0].alternateLanguageDocs) {
					response.alternateLanguageDocuments = JSON.parse(
						JSON.stringify(
						resp.data.Data.documents[0].alternateLanguageDocs
						)
					);
				}
			}
		});
		return Promise.resolve(response);
	},
	setupDocument(doc, wordChecks, statuses, persistHtml = false) {
		const response = {};
		let usesTaleoFeed = false;
		response.headerHistory =
			doc[0].DHHistory && doc[0].DHHistory.headerHistory
			? doc[0].DHHistory.headerHistory
			: [];
		response.docHierarchies = doc[0].hierarchies;
		response.html_export_sections = [];
		response.document = doc.map((x) => {
			let n = JSON.parse(JSON.stringify(x));
			const docStatus = statuses ? statuses.find((x) => x.status === n.doc_status) : {};

			n.parttypes.forEach((pt) => {
				pt.cardinality = this.getPartTypeMinMax(
					this.getWordChecksForPart(pt.tmpl_part_id, response.docHierarchies, wordChecks),
					null,
					pt
				);
				pt.parts.forEach((p) => {
					p.rowDirty = false;
					p.rowMessage = "";
					p.editing = false;
					p.hover = false;
					p.isDirty = false;
					p.highlightSection = false;
					this.setupChildPartTypes(p, pt, n.DPHistory, response.docHierarchies, wordChecks, docStatus);
					this.setDocPartStatus(p, pt, wordChecks, docStatus, n, persistHtml);
					if (p.attributes) {
						p.attributes.forEach(a => {
							a.editing = false;
							a.isDirty = false;
						});
					}
				});
				let sections = (pt.tmpl_part_metadata.html_export_section || "")
					.split("|")
					.filter((x) => x);
				if (sections) {
					if (
					sections.some(
						(x) => x.startsWith("internal") || x.startsWith("external")
					)
					)
					usesTaleoFeed = true;
					sections
					.filter(
						(x) => !x.startsWith("internal") && !x.startsWith("external") // just for taleo push
					)
					.forEach((sec) => {
						if (response.html_export_sections.indexOf(sec) < 0) {
							response.html_export_sections.push(sec);
						}
					});
				}
				pt.hasDeletedParts =
					pt.cardinality.maxError > 1 &&
					n.DPHistory &&
					n.DPHistory.length &&
					n.DPHistory.some((pa) => pa.isDeleted && pa.name === pt.type);

				pt.layoutTags = pt.tmpl_part_metadata?.layout ? pt.tmpl_part_metadata.layout.split('|') : [];
			});
			
			return n;
		})[0];
		response.usesTaleoFeed = usesTaleoFeed;
		response.isInterviewTemplate = response.document.doc_type === "Interview Template";
		return response;
	},
	setupChildPartTypes(part, partType, docPartHistory, docHierarchies, wordChecks, docStatus) {
		part.childPartTypes = partType.childPartTypes.map((pt) => {
			let cpt = JSON.parse(JSON.stringify(pt));
			cpt.parts = part.childParts.filter(
				(x) => x.tmpl_part_id === cpt.tmpl_part_id
			);
			cpt.cardinality = this.getPartTypeMinMax(
				this.getWordChecksForPart(cpt.tmpl_part_id, docHierarchies, wordChecks),
				null,
				cpt
			);
			cpt.parts.forEach((cp) => {
				cp.highlightSection = false;
				cp.editing = false;
				cp.hover = false;
				cp.isDirty = false;
				this.setDocPartStatus(cp, cpt, wordChecks, docStatus);
				if (cp.attributes) {
					cp.attributes.forEach(a => {
						a.editing = false;
						a.isDirty = false;
					});
				}
			});
			cpt.hasDeletedParts =
			cpt.cardinality.maxError > 1 &&
			docPartHistory > 1 &&
			docPartHistory.some((pa) => pa.isDeleted && pa.name === cpt.type);
			cpt.layoutTags = cpt.tmpl_part_metadata?.layout ? cpt.tmpl_part_metadata.layout.split('|') : [];
			return cpt;
		});
	},
	getWordChecksForPart(tpid, docHierarchies, wordChecks) {
		return wordChecks.filter((w) =>
			w.tmpl_part_types.some(
			(tp) =>
				tp.tp_id === tpid &&
				(!tp.exclusions.length ||
				!docHierarchies.some((h) =>
					tp.exclusions.some((e) => e.hr_id === h.hr_id)
				))
			)
		);
	},
	resolveHeaderStyle(doc) {
		if (doc.lifecycle_status_colour) return doc.lifecycle_status_colour;
		const color = doc.doc_status_color || doc.colour;
		const status = doc.doc_status || doc.status;
		if (doc.doc_type_abbrev == "Ef") {
			switch (doc.file_data?.file_extension) {
			case "xlsx":
			case "xls":
				return "#3cc542ad";
			case "doc":
			case "docx":
				return "#327bb5c7";
			case "txt":
			case "log":
				return "#ff23138f";
			case "pdf":
				return "#ff23138f";
			default:
				return "#327bb5c7";
			}
		} else if (color) {
			return color;
		} else {
			switch (status) {
			case "APPROVED":
				return "green";
			case "PENDING_APPROVAL":
				return "red";
			case "DRAFT":
			case "PRE-DRAFT":
				return "orange";
			case "ARCHIVED":
			case "TEMPLATE":
				return "blue";
			default:
				return "gray";
			}
		}
	},
	resolveStatusChipText(doc) {
		// if (doc.lifecycle_status_name) return doc.lifecycle_status_name;
		if (doc.doc_type_abbrev == "Ef")
			return doc.file_data?.file_extension.toUpperCase() || "FILE";
		else return doc.doc_status_text;
	},
	sanitize(dirty) {
		return sanitizeHtml(dirty, {
			allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
			allowedAttributes: {
				a: [ 'href', 'name', 'target' ],
				img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ],
				p: [ 'style' ],
				span: [ 'style' ],
				div: [ 'style' ],
				table: [ 'style', 'width', 'height' ],
				td: [ 'align' ]
			},
			allowedStyles: {
				'*': {
					// Match HEX and RGB
					'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
					'text-align': [/^left$/, /^right$/, /^center$/],
					// Match any number with px, em, or %
					'width': [/^\d+(?:px|em|rem|%)$/],
					'height': [/^\d+(?:px|em|rem|%)$/],
					'font-size': [/^\d+(?:px|em|rem|%)$/]
				}
			}
		});
	},
	csvEscape(raw) {
		if (!raw) return "";
		if (typeof raw === "number" || typeof raw === "boolean") return `${raw}`;

		//To prevent CSV injection
		const prefix = raw.startsWith('=') || raw.startsWith('+') || raw.startsWith('-') || raw.startsWith('@') ? "'" : "";

		return `"${prefix}${raw.replace(new RegExp('"', 'g'),'""')}"`;
	}
};
