<template>
    <div class="docViewHolder" :style="cssVars">
        <div class="docSectionHolderLeft">
            <div class="docSectionHolderLeftActions" v-if="!admin && show_lhs_expand">
                <v-icon title="Minimise" color="#a1a4a9" v-if="leftPanel === 'sections'" @click="toggleLHS('gutter')">mdi-chevron-left-circle-outline</v-icon>
                <v-icon title="Expand" color="#a1a4a9" v-else @click="toggleLHS('sections')">mdi-chevron-right-circle-outline</v-icon>
            </div>
            <ViewSection 
                v-show="leftPanel === 'sections'"
                ref="viewDisplay" 
                :templateView="currentView" 
                @changeSection="loadSectionContent" 
                @addSubSection="addSubSection" 
                @editSection="editSectionDefinition" 
                :adminMode="admin" 
                @addSection="addSection" 
                @editSectionOrder="editSectionOrder"
                @editPermissions="editPermissions"
            />
        </div>
        <div class="docHolder">
            <div class="docHolderActionsHeader">
                <div v-if="saveInProgress" class="doc-view-font small" style="position:absolute; right:20px; top:5px" justify-center align-content-center >
                    <v-icon small color="#377DFF">mdi-autorenew mdi-spin</v-icon>
                    <span style="color:#377DFF; padding-left: 5px; vertical-align: text-bottom;">Saving...</span>
                </div>
            </div>
            <div class="docHolderTop" v-if="displayHeader">
                <div class="docBanner" v-html="bannerText"></div>
            </div>
            <div class="docHolderCenter" ref="docContent">
                <div v-if="activeSection && activeSection.view_mode === 'REVIEW'" class="ml-3 mr-4 mt-5 mb-6">
                    <v-expansion-panels multiple flat>
                            <v-expansion-panel
                                v-for="(item,i) in currentReviewSection"
                                :key="i"
                            >
                            <v-expansion-panel-header style="min-height:75px;border-top:1px solid #E3E7F3; border-bottom:1px solid #E3E7F3; font-family: 'Manrope';  font-style: normal; font-weight: 700; font-size: 19px; line-height: 28px;"
                            :style="{
                                'color': item.complete ? '#00B515' : '#3E3E3E',
                            }"
                            :disable-icon-rotate=item.complete
                            >
                                {{item.section}}

                                <template v-slot:actions>
                                    <v-icon v-if="!item.complete" color="primary">
                                        $expand
                                    </v-icon>
                                    <v-icon v-if="item.complete" color="#00B515">
                                        mdi-check
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="padding-top: 30px;">
                                <ViewParts v-if="item.view_mode === null" 
                                :highlightedDocParts="highlightedDocParts" 
                                :data="data" 
                                :sectionPermission="item.permissions_list" 
                                :lockSection="item.permissions_list.editPermissionOverride !== null && !item.permissions_list.editPermissionOverride" 
                                :viewLinkedContent=displayLinkedContent 
                                :parts="item.part_data" 
                                :disableEdit="disablePartEdit" 
                                :adminMode="admin" 
                                @editPart="editPart" 
                                @editPartPermission="editPartPermission"
                                />
                                <div v-else-if="item.view_mode === 'DATACAPTURE'">
                                
                                    <div
                        v-for="(dca, dci) in data.document.state.dataCaptureActionsMain"
                        :key="'dca' + dci"
                    >
                        <DataCaptureQuestionnaire
                        v-if="dca.data_capture_method === 'questionnaire'"
                        :definition="dca.definition"
                        :action="dca"
                        @saveAnswer="updateQuestionnaireAnswer"
                        @actionTriggered="doStateAction"
                        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
                        />
                        <DataCaptureRequisitions
                        v-else-if="
                            dca.data_capture_method === 'requisition' ||
                            dca.data_capture_method === 'requisition_new'
                        "
                        :action="dca"
                        :isBusy="isLoading"
                        @save="saveDataCapture"
                        @requestEdit="doStateAction"
                        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
                        />
                        <DataCaptureForm
                        v-else-if="dca.data_capture_method === 'form'"
                        :action="dca"
                        :isBusy="isLoading"
                        @save="saveDataCapture"
                        @requestEdit="doStateAction"
                        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
                        />
                        <DataCaptureDocumentRole
                        v-else-if="dca.data_capture_method === 'document_role'"
                        :action="dca"
                        :isBusy="isLoading"
                        @save="saveDataCapture"
                        @requestEdit="doStateAction"
                        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
                        />
                        <AssignReviewGroup
                        v-else-if="dca.data_capture_method === 'review_group'"
                        :docId="document.doc_id"
                        :action="dca"
                        @save="saveDataCapture">
                        </AssignReviewGroup>
                    </div>
                                
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row style="padding-top:30px">
                        <v-col cols="10"></v-col>
                        <v-col cols="2">
                            <v-tooltip top v-if="submitAction">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn 
                                    :disabled="currentReviewSection.some(x => !x.complete) || isActionDisabled()" 
                                    style="width:100%" @click="doSubmitAction" color="primary"
                                >{{submitAction.name}}</v-btn>
                                </div>
                            </template>
                            <span>{{isActionDisabled(true)}}</span>
                            </v-tooltip>

                        </v-col>
                    </v-row>
                </div>
                <div v-else-if="activeSection && activeSection.view_mode === 'DATACAPTURE'" class="ml-3 mr-4 mt-5 mb-6">
                    <!--<div
                        v-for="(dca, dci) in data.document.state.dataCaptureActionsMain"
                        :key="'dca' + dci"
                    >
                        <DataCaptureQuestionnaire
                        v-if="dca.data_capture_method === 'questionnaire'"
                        :definition="dca.definition"
                        :action="dca"
                        @saveAnswer="updateQuestionnaireAnswer"
                        @actionTriggered="doStateAction"
                        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
                        />
                        <DataCaptureRequisitions
                        v-else-if="
                            dca.data_capture_method === 'requisition' ||
                            dca.data_capture_method === 'requisition_new'
                        "
                        :action="dca"
                        :isBusy="isLoading"
                        @save="saveDataCapture"
                        @requestEdit="doStateAction"
                        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
                        />
                        <DataCaptureForm
                        v-else-if="dca.data_capture_method === 'form'"
                        :action="dca"
                        :isBusy="isLoading"
                        @save="saveDataCapture"
                        @requestEdit="doStateAction"
                        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
                        />
                        <AssignReviewGroup
                        v-else-if="dca.data_capture_method === 'review_group'"
                        :docId="document.doc_id"
                        :action="dca"
                        @save="saveDataCapture">
                        </AssignReviewGroup>
                    </div>-->
                </div>
                <ViewParts v-else 
                    :readOnly="readOnly" 
                    :data="data"
                    :viewLinkedContent=displayLinkedContent 
                    :parts="sectionParts" 
                    :lockSection="activeSection && activeSection.permissions_list.editPermissionOverride !== null && !activeSection.permissions_list.editPermissionOverride"
                    :sectionPermission="sectionPermissionList"
                    :disableEdit="disablePartEdit" 
                    :adminMode="admin" 
                    :isDragging="dragging"
                    :spellcheckEnabled="userSpellCheckEnabled"
                    :nonSuggestionsList="nonSuggestionsList"
                    @editPart="editPart" 
                    @editPartPermission="editPartPermission"
                    @saveStart="saveStart"
                    @saveEnd="saveEnd"
                    @showPartHistory="(part, type, collab) => showPartHistory(part, type, collab)"
                    @showDeletedPartHistory="(type) => showDeletedPartHistory(type)"
                    @updatePart="(obj, part, resp, item) => updatePart(obj, part, resp, item)"
                    @processResults="processResults"
                    @changeOrder="reorderParts"
                    @deletePart="(obj, partType, parent, resp) => deletePart(obj, partType, parent, resp)"
                    @updateContentPart="(resp, part) => updateContentPart(resp, part)"
                    @openSuggestions="openSuggestions"
                    @updateRecentPart="updateRecentPart"
                    @resyncItem="resyncItem"
                    :highlightedDocParts="highlightedDocParts"
                 />
            </div>
            <div class="docHolderFooter">
                <div>
                    <v-btn v-if="!navBackHide" :disabled="navBackDisabled" color="primary" class="mt-1 mr-5 userPilotLCBack" @click="navSections('Back')">
                        <v-icon left>mdi-arrow-left</v-icon>Previous
                    </v-btn>
                </div>
                <div style="width:100%">
                    <div class="docBanner" v-if="footerText" v-html="footerText"></div>
                </div>
                <div style="display:inline-flex">
                    <v-btn v-if="sectionCompleteSub" color="success" :class="['mt-1', 'ml-5', 'userPilotLC'+sectionCompleteLabel?.replace(/\s+/g, '')]" @click="navSections('CompleteAll')">
                        {{sectionCompleteLabel}}
                    </v-btn>                    
                    <v-btn v-if="!navChangesHide" :disabled="navNextDisabled" color="success" class="mt-1 ml-5 userPilotLCNoChangesRequired" @click="navSections('Complete')">
                        No Changes Required <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>                    
                    <v-btn v-if="!navNextHide" :disabled="navNextDisabled" color="primary" class="mt-1 ml-5 userPilotLCNext" @click="navSections('Next')">
                        Next <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                </div>                
            </div>
        </div>
        <div class="docSectionHolderRight">
            <div class="rightHolderNavActions" v-if="!admin && show_rhs_expand">
                    <v-icon title="Expand" color="#a1a4a9" v-if="rightPanel === 'hidden'" @click="toggleRHS('score')">mdi-chevron-left-circle-outline</v-icon>
                    <v-icon title="Minimise" color="#a1a4a9" v-else @click="toggleRHS('hidden')">mdi-chevron-right-circle-outline</v-icon>
                </div>
            <div class="rightHolderNav" v-if="rightPanel !=='hidden'">

                <v-btn v-if="showScoring" icon title="Score" class="userPilotLCScore" @click="toggleRHS('score')"><v-icon :color="rightPanel==='score' ? 'success' : ''" >mdi-text-box-check-outline</v-icon></v-btn>
                <v-btn icon title="Suggestions" class="userPilotLCSuggestions" @click="toggleRHS('suggestions')"><v-icon :color="rightPanel==='suggestions' ? 'success' : ''">mdi-format-list-checkbox</v-icon></v-btn>
                <v-btn icon title="Database" class="userPilotLCDatabase" @click="toggleRHS('database')"><v-icon :color="rightPanel==='database' ? 'success' : ''">mdi-database</v-icon></v-btn>
                <!--<v-badge
                    color="success"
                    dot
                    :value="allHistory.length"
                    :content="allHistory.length"
                    offset-x="11px"
                    offset-y="13px"
                    dense
                >
                    <v-btn icon title="History" class="userPilotLCHistory" @click="toggleRHS('allHistory')"><v-icon :color="rightPanel==='allHistory'||rightPanel==='history' ? 'success' : ''">mdi-history</v-icon></v-btn>
                </v-badge>-->
                <!--<v-btn icon title="Collaboration" class="userPilotLCCollaboration" @click="toggleRHS('collaboration')"><v-icon :color="rightPanel==='collaboration' ? 'success' : ''">mdi-account-group-outline</v-icon></v-btn>
                <v-btn icon title="Dynamic Content Engine" class="userPilotLCDCE" @click="toggleRHS('DCE')"><v-icon :color="rightPanel==='DCE' ? 'success' : ''">memory</v-icon></v-btn>-->
            </div>
            <div class="rightHolderContent" v-if="rightPanel !=='hidden'">
                <div justify-center class="doc-view-font small rightHolderContentLabel"> {{rightPanelHeader}} </div>
                <div class="doc-view-font small rightHolderContentMain">
                    <div v-if="rightPanel==='history'" style=" padding-right: 15px; width:100%; height:100%; max-height:100%; overflow: hidden scroll">
                        <v-card-text v-if="collabHistory">
                            <v-timeline dense>
                                <v-timeline-item
                                v-if="data.document.state.docPartCommentAction"
                                >
                                <template v-slot:icon>
                                    <v-avatar color="primary" size="30">
                                    <span class="white--text">{{ userInitials() }}</span>
                                    </v-avatar>
                                </template>
                                <v-row dense
                                    ><v-col>
                                    <v-textarea
                                        v-model="comment.text"
                                        auto-grow
                                        hide-details
                                        outlined
                                        rows="1"
                                        placeholder="Add your comment..."
                                        @input="comment.isDirty = true"
                                    ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row dense v-show="comment.isDirty">
                                    <v-col cols="3">
                                    <v-btn
                                        small
                                        color="primary"
                                        @click="commentHandler('save_new')"
                                        >Save</v-btn
                                    >
                                    </v-col>
                                    <v-col cols="3">
                                    <v-btn small @click="commentHandler('cancel_new')"
                                        >Cancel</v-btn
                                    >
                                    </v-col>
                                </v-row>
                                </v-timeline-item>
                                <v-timeline-item
                                small
                                v-for="(h, hi) in history.collabSummary"
                                :key="'ch' + hi"
                                >
                                <template v-slot:icon>
                                    <v-avatar
                                    :color="h.type === 'comment' ? 'primary' : 'teal'"
                                    size="30"
                                    >
                                    <span class="white--text">{{ h.user_initials }}</span>
                                    </v-avatar>
                                </template>
                                <v-card v-if="h.type === 'comment'" class="elevation-2">
                                    <v-card-text>
                                    <v-icon size="22" class="mr-2">
                                        mdi-comment-text-outline
                                    </v-icon>
                                    <strong
                                        >{{ h.data.created_date }}: {{ h.user_name }}: </strong
                                    ><br />
                                    <q>{{ h.data.comment }}</q></v-card-text
                                    >
                                </v-card>
                                <v-sheet
                                    v-else
                                    rounded
                                    elevation="2"
                                    style="padding: 2px 12px"
                                >
                                    <p class="font-weight-normal overflow-hidden">
                                    <v-icon size="22" class="mr-2">
                                        mdi-pencil-outline
                                    </v-icon>
                                    <strong>{{ h.data.version_collab_desc }}</strong>
                                    <v-chip
                                        small
                                        v-if="h.data.latest"
                                        class="float-right"
                                        outlined
                                        label
                                    >
                                        Latest Version</v-chip
                                    >
                                    <v-btn
                                        v-else
                                        class="float-right mb-1 mr-1"
                                        small
                                        @click="
                                        doReviewRevert({
                                            rc: h.data,
                                            processed: false,
                                            part: comment.part,
                                            partType: comment.partType,
                                        })"
                                        >Revert To</v-btn
                                    >
                                    </p>
                                    <p class="overflow-hidden">
                                    {{ h.data.previous }}
                                    <v-chip
                                        class="float-right"
                                        small
                                        color="primary"
                                        v-if="h.data.previous_is_essential !== null"
                                    >
                                        {{
                                        h.data.previous_is_essential
                                            ? "Essential"
                                            : "Not Essential"
                                        }}
                                    </v-chip>
                                    </p>
                                </v-sheet>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card-text>
                        <v-card-text v-else-if="ptDeleteHistory && ptDeleteHistory.length">
                            <v-timeline dense>
                                <v-timeline-item
                                small
                                v-for="(dh, hi) in ptDeleteHistory"
                                :key="'dh' + hi"
                                >
                                <v-row dense>
                                    <v-col>
                                    <h5 class="subtitle-2">
                                        {{ dh.items[0].version_desc }}
                                    </h5>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" class="d-flex justify-space-between">
                                    <span class="histText" v-html="dh.items[0].text"></span>
                                    <v-btn
                                        icon
                                        v-if="
                                        dh.items[0].canRevert &&
                                        document.state.canEditDocParts &&
                                        (document.state.allow_errors ||
                                            ptDeleteHistoryPT.parts.length <
                                            ptDeleteHistoryPT.cardinality.maxError)
                                        "
                                        x-small
                                        title="Undo"
                                        @click="revertDelete(dh)"
                                        ><v-icon>mdi-undo-variant</v-icon></v-btn
                                    >
                                    </v-col>
                                </v-row>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card-text>                 
                        <v-card-text v-else>      
                        <v-timeline dense>
                            <v-timeline-item
                            small
                            v-for="(h, hi) in history?.items"
                            :key="'h' + hi"
                            >
                            <v-row dense>
                                <v-col class="d-flex justify-space-between">
                                <h5 class="subtitle-2">
                                    {{ h.version_desc }}
                                </h5>
                                <v-btn
                                    v-if="h.canRevert && document.state.canEditDocParts"
                                    x-small
                                    icon
                                    title="Revert"
                                    @click="revertChange(h)"
                                    ><v-icon>mdi-undo-variant</v-icon></v-btn
                                >
                                </v-col>
                            </v-row>
                            <v-row
                                dense
                                v-for="(c, ci) in h.changes"
                                :key="'h' + hi + 'c' + ci"
                            >
                                <v-col cols="3">
                                <strong class="histText">{{ c.title }}</strong>
                                </v-col>
                                <v-col>
                                <span class="histText" v-html="c.desc"></span>
                                </v-col>
                            </v-row>
                            </v-timeline-item>
                        </v-timeline>
                        </v-card-text>
                    </div>
                    <div v-if="rightPanel==='allHistory'" style=" padding-right: 15px; width:100%; height:100%; max-height:100%; overflow: hidden scroll; padding-left: 20px;">
                        <div v-if="activeSection.section_history.length === 0">No History!</div>
                        <v-timeline
                            v-else
                            align-top
                            dense
                        >
                            <v-timeline-item
                                v-for="(item, i) in activeSection.section_history"
                                :key="i"
                                :color="item.items[0].type === 'Section' ? '#4caf50bf !important' : '#2196f38c'"
                                :icon="item.items[0].type === 'Section' ? 'mdi-check' : 'mdi-book-variant'"
                                class="doc-view-font small"
                            >
                            <v-card
                                dark
                                class="white"
                                :color="item.items[0].type === 'Section' ? '#4caf50bf !important' : '#2196f38c !important'"
                            >
                                <v-card-title class="doc-view-font small light" style="padding:5px 5px 5px 15px;">
                                {{item.items[0].type === 'Section' ? 'Section Update' : 'Content Update'}}
                                </v-card-title>
                                <v-card-text class="white text--primary" style="padding:5px 5px 5px 15px;">
                                    {{  `${moment(item.timestamp).format("DD MMM YYYY HH:mm:ss")}` }}:<br/>
                                    <div v-for="change in item.items" :key="change.doc_view_audit_id">
                                        <div v-if="change.content_type === 'TABLE'">
                                            {{ `${item.user}` }}<br/>
                                            <span v-html="change.message"></span>
                                        </div>
                                        <div v-else>
                                            <div v-for="(value, index) in Object.entries(change.data)" :key="index">
                                                <div v-if="change.type === 'Section'">
                                                    {{ `${item.user}` }}<br/>
                                                    {{ `Section Complete` }}
                                                </div>
                                                <div v-else>
                                                    <span>{{ `${item.user} Updated: ${value[0]} to: `}}</span><br/>
                                                    <span>{{ `${value[1] === "" ? '-' : value[1]}` }}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </div>
                    <div v-if="rightPanel === 'score' && !admin && showScoring" style="padding-top: 5px; padding-right:0px; width: 100%; height:100%; max-height:100%;">
                        <v-card
                            flat solo tile
                            color="transparent"
                            class="scorecard"
                        >
                            <v-card-title class="scoretitle d-flex align-center">
                            <v-progress-circular
                                v-if="
                                documentIssueDisplay === 'score' ||
                                documentIssueDisplay === 'score_header_only'
                                "
                                :rotate="90"
                                :size="45"
                                :width="4"
                                :value="checkSummary.score"
                                :color="checkSummary.scoreColour"
                                style="position:absolute; left:10px; margin-right:20px; top: 0px;"
                                ><span>{{ checkSummary.score.toFixed(0) }}</span>
                            </v-progress-circular>
                            <div class="inclusionHeading doc-view-font small">{{ document.score_title }}</div>
                            <v-spacer></v-spacer
                            ><v-btn
                                icon
                                small
                                @click="allCheckSummaryPanels"
                                v-if="
                                checkSummaryPanels.length < checkSummary.categories.length
                                "
                                style="position:absolute; right: 5px"
                            >
                                <v-icon>mdi-chevron-double-down</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                small
                                @click="closeCheckSummaryPanels"
                                v-if="
                                checkSummaryPanels.length === checkSummary.categories.length
                                "
                                style="position:absolute; right: 5px"
                            >
                                <v-icon>mdi-chevron-double-up</v-icon>
                            </v-btn></v-card-title
                            >
                            <v-card-text color="transparent" dense style="padding: 0">
                                <v-expansion-panels
                                accordion
                                multiple
                                flat tile
                                v-model="checkSummaryPanels"
                            >
                                <v-expansion-panel style="background-color:transparent !important; border-bottom: 1px solid #e3e3e7"
                                v-for="(i, ii) in checkSummary.categories"
                                :key="'i' + ii"
                                >
                                <v-expansion-panel-header
                                    :disable-icon-rotate="i.score == '100'"
                                >
                                    <span class="sectionText doc-view-font small">
                                    <v-badge
                                        v-if="
                                        (documentIssueDisplay === 'score_header_only' ||
                                            documentIssueDisplay === 'basic') &&
                                        i.errorCount
                                        "
                                        :content="i.errorCount"
                                        color="red"
                                        inline
                                        overlap
                                        style="float: right"
                                    >
                                    </v-badge
                                    ><v-badge
                                        v-if="
                                        (documentIssueDisplay === 'score_header_only' ||
                                            documentIssueDisplay === 'basic') &&
                                        i.warningCount
                                        "
                                        :content="i.warningCount"
                                        color="amber"
                                        inline
                                        overlap
                                        style="float: right"
                                    >
                                    </v-badge
                                    >{{ i.category.trim() + " " }}
                                    <v-progress-circular
                                        v-if="documentIssueDisplay === 'score'"
                                        :rotate="90"
                                        :size="30"
                                        :width="4"
                                        :value="i.score"
                                        :color="i.scoreColour"
                                        class="float-right"
                                        ><span style="font-size: 11px"> {{ i.score }}</span>
                                    </v-progress-circular> </span
                                    ><template v-if="i.score == '100'" v-slot:actions>
                                    <v-icon> mdi-check </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content v-if="checkSummaryView === 1">
                                    <v-row
                                    dense
                                    v-for="(c, ci) in i.titles"
                                    :key="'i' + ii + 'ci' + ci"
                                    >
                                    <v-col>
                                        <v-row dense class="scoreDetail doc-view-font small">
                                        <v-col cols="1"></v-col>
                                        <v-col cols="11"
                                            ><span style="font-size: 13px">{{
                                            c.title
                                            }}</span></v-col
                                        >
                                        </v-row>
                                        <v-row
                                        dense
                                        class="scoreDetail doc-view-font small"
                                        v-for="(e, ei) in checkSummary.errors.filter(
                                            (x) =>
                                            x.category === i.category && x.title === c.title
                                        )"
                                        :key="'e_' + ii + '_' + ei"
                                        >
                                        <v-col cols="1"></v-col>
                                        <v-col
                                            cols="11"
                                            @mouseenter="highlightSection(e)"
                                            @mouseleave="unhighlightSection"
                                            @click="scrollToSection(e)"
                                            class="sectionItem"
                                        >
                                            <v-badge
                                            color="red"
                                            :content="e.count"
                                            inline
                                            overlap
                                            style="color: slategray; font-size: 12px"
                                            >
                                            <div
                                                :style="{
                                                'min-width': '22px',
                                                height:
                                                    e.effect === 'highlight' ? '22px' : '',
                                                'margin-right': '10px',
                                                'background-color':
                                                    e.effect === 'highlight'
                                                    ? e.colour
                                                    : 'transparent',
                                                'border-bottom':
                                                    e.effect.indexOf('dotted') >= 0
                                                    ? 'dotted 3px ' + e.colour
                                                    : e.effect.indexOf('solid') >= 0
                                                    ? 'solid 3px ' + e.colour
                                                    : '',
                                                }"
                                            ></div>
                                            <div v-html="e.description"></div>
                                            </v-badge>
                                        </v-col>
                                        </v-row>
                                        <v-row
                                        dense
                                        class="scoreDetail doc-view-font small"
                                        v-for="(w, wi) in checkSummary.warnings.filter(
                                            (x) =>
                                            x.category === i.category && x.title === c.title
                                        )"
                                        :key="'w_' + ii + '_' + wi"
                                        >
                                        <v-col cols="1"></v-col>
                                        <v-col
                                            cols="11"
                                            @mouseenter="highlightSection(w)"
                                            @mouseleave="unhighlightSection"
                                            @click="scrollToSection(w)"
                                            class="sectionItem doc-view-font small"
                                        >
                                            <v-badge
                                            color="amber"
                                            :content="w.count"
                                            inline
                                            overlap
                                            style="color: slategray; font-size: 12px"
                                            >
                                            <div
                                                :style="{
                                                'min-width': '22px',
                                                height:
                                                    w.effect === 'highlight' ? '22px' : '',
                                                'margin-right': '10px',
                                                'background-color':
                                                    w.effect === 'highlight'
                                                    ? w.colour
                                                    : 'transparent',
                                                'border-bottom':
                                                    w.effect.indexOf('dotted') >= 0
                                                    ? 'dotted 3px ' + w.colour
                                                    : w.effect.indexOf('solid') >= 0
                                                    ? 'solid 3px ' + w.colour
                                                    : '',
                                                }"
                                            ></div>
                                            <div v-html="w.description"></div>
                                            </v-badge>
                                        </v-col>
                                        </v-row>
                                    </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                                <v-expansion-panel-content
                                    v-if="checkSummaryView === 2"
                                    style="margin-top: -12px"
                                >
                                    <v-row
                                    dense
                                    class="scoreDetail doc-view-font small"
                                    v-for="(t, ti) in i.titles"
                                    :key="'i' + ii + 'ti' + ti"
                                    >
                                    <v-col cols="1">
                                        <div
                                        :style="{
                                            'margin-top': '3px',
                                            height:
                                            t.effect === 'highlight' || t.effect === 'border'
                                                ? '18px'
                                                : '12px',
                                            'background-color':
                                            t.effect === 'highlight'
                                                ? t.colour
                                                : 'transparent',
                                            'border-bottom':
                                            t.effect.indexOf('dotted') >= 0
                                                ? 'dotted 3px ' + t.colour
                                                : t.effect.indexOf('solid') >= 0
                                                ? 'solid 3px ' + t.colour
                                                : t.effect === 'border'
                                                ? 'solid 1px ' + t.colour
                                                : '',
                                            'border-top':
                                            t.effect === 'border'
                                                ? 'solid 1px ' + t.colour
                                                : '',
                                            'border-left':
                                            t.effect === 'border'
                                                ? 'solid 1px ' + t.colour
                                                : '',
                                            'border-right':
                                            t.effect === 'border'
                                                ? 'solid 1px ' + t.colour
                                                : '',
                                            'border-radius':
                                            t.effect === 'border' ? '4px ' : '',
                                        }"
                                        >
                                        <v-icon v-if="t.effect.substr(0, 5) === 'icon-'">{{
                                            t.effect.substr(5)
                                        }}</v-icon>
                                        </div>
                                    </v-col>
                                    <v-col cols="11">
                                        <span style="font-size: 13px; margin-right: 10px">{{
                                        t.title
                                        }}</span>
                                        <span
                                        v-for="(d, di) in t.issues"
                                        :key="'i' + ii + 'ti' + ti + 'di' + di"
                                        >
                                        <v-tooltip bottom v-if="d.errorCount > 0">
                                            <template v-slot:activator="{ on, attrs }"
                                            ><v-btn
                                                x-small
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                @mouseenter="highlightSection(d, true)"
                                                @mouseleave="unhighlightSection"
                                                @click="scrollToSection(d)"
                                                class="sectionItem doc-view-font small"
                                            >
                                                <v-icon color="red" style="font-size: 18px"
                                                >mdi-alert</v-icon
                                                >
                                                <span
                                                v-if="d.errorCount > 1"
                                                style="
                                                    margin-top: -12px;
                                                    margin-left: -5px;
                                                    font-size: 9px;
                                                    text-transform: none;
                                                "
                                                >x{{ d.errorCount }}</span
                                                ></v-btn
                                            >
                                            </template>
                                            <div style="max-width: 300px">
                                            <span v-html="d.description"></span>
                                            </div>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="d.warningCount > 0">
                                            <template v-slot:activator="{ on, attrs }"
                                            ><v-btn
                                                x-small
                                                text
                                                v-bind="attrs"
                                                v-on="on"
                                                @mouseenter="highlightSection(d)"
                                                @mouseleave="unhighlightSection"
                                                @click="scrollToSection(d)"
                                                class="sectionItem"
                                            >
                                                <v-icon color="grey" style="font-size: 18px"
                                                >mdi-alert</v-icon
                                                >
                                                <span
                                                v-if="d.warningCount > 1"
                                                style="
                                                    margin-top: -12px;
                                                    margin-left: -5px;
                                                    font-size: 9px;
                                                    text-transform: none;
                                                "
                                                >x{{ d.warningCount }}</span
                                                ></v-btn
                                            >
                                            </template>
                                            <div style="max-width: 300px">
                                            <span v-html="d.description"></span>
                                            </div>
                                        </v-tooltip>
                                        </span>
                                    </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                                <v-expansion-panel-content
                                    v-if="checkSummaryView === 3"
                                    style="margin-top: -12px"
                                >
                                    <v-row
                                    dense
                                    class="scoreDetail"
                                    v-for="(t, ti) in i.titles"
                                    :key="'i' + ii + 'ti' + ti"
                                    >
                                    <v-col cols="1">
                                        <div
                                        :style="{
                                            'margin-top': '3px',
                                            height: t.effect === 'highlight' ? '18px' : '12px',
                                            'background-color':
                                            t.effect === 'highlight'
                                                ? t.colour
                                                : 'transparent',
                                            'border-bottom':
                                            t.effect.indexOf('dotted') >= 0
                                                ? 'dotted 3px ' + t.colour
                                                : t.effect.indexOf('solid') >= 0
                                                ? 'solid 3px ' + t.colour
                                                : '',
                                        }"
                                        ></div>
                                    </v-col>
                                    <v-col cols="8">
                                        <span style="font-size: 13px; margin-right: 10px">{{
                                        t.title
                                        }}</span>
                                    </v-col>
                                    <v-col cols="3">
                                        <div style="width: 100%; border: solid #f0e8e8 1px; height: 19px;">
                                        <div
                                            v-for="n in t.errorCount"
                                            :key="'i' + ii + 'ti' + ti + 'n' + n"
                                            :style="{
                                            width: 100 / checkSummary.maxIssues - 2 + '%',
                                            'background-color': 'red',
                                            margin: '1%',
                                            float: 'left',
                                            height: '15px',
                                            }"
                                        ></div>
                                        <div
                                            v-for="n in t.warningCount"
                                            :key="'i' + ii + 'ti' + ti + 'n' + n"
                                            :style="{
                                            width: 100 / checkSummary.maxIssues - 2 + '%',
                                            'background-color': 'orange',
                                            margin: '1%',
                                            float: 'left',
                                            height: '15px',
                                            }"
                                        ></div>
                                        </div>
                                    </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-if="rightPanel === 'suggestions'" style="padding-top: 5px; padding-right:15px; width: 100%; height:100%; max-height:100%;">
                        <SuggestionsPanel ref="suggestionsPanel" :partsList="suggestionsPart" :docList="[docTitle]"  @dragEnd="dragEnd" @dragStart="initDrag"></SuggestionsPanel>
                    </div>
                    <div v-if="rightPanel === 'database'"  style="padding-top: 5px; padding-right:15px; width: 100%; height:100%; max-height:100%;">
                        <ContentDatabase :data="data" :isNew="false" @dragEnd="dragEnd" @dragStart="initDrag" @closeDatabasePanel="toggleRHS('score')"></ContentDatabase>
                    </div>
                    <ViewSectionAdmin 
                        :display="'inline'" 
                        v-if="(rightPanel==='partPermissions' || rightPanel==='permissions') && admin && editingSection" 
                        @changeRHS="changePanel" 
                        :workflowConfig="adminConfig" 
                        :value="editingSection" 
                        :sectionList="allSectionList"
                        @preview="applySectionUpdate" 
                        @cancel="cancelEditUpdate" 
                        @configParts="loadPartsForSection"
                        @saveSection="saveSection"
                        >
                    </ViewSectionAdmin>                       
                </div>
             
            </div>

        </div>
        <Loading :isVisible="isLoading" />
        <v-dialog v-model="editPartDialog.show" max-width="1200px" scrollable>
            <v-card>
                <v-card-title class="d-flex align-center">
                    Edit Parts in Section
                </v-card-title>
                <v-card-text style=" display:inline-flex">
                    <!--<v-row class="dataTableView" v-for="(item,idx) in editPartDialog.data" :key="idx" dense>
                        <v-col cols="1">
                            <v-checkbox dense v-model="item.exists"></v-checkbox>
                        </v-col>
                        {{ item.pt_name }}
                    </v-row>-->
                    <div style="width:25%">
                        Available Parts:<br/>
                        <div style="height:700px; max-height:700px; overflow: hidden scroll; padding-top:10px">
                            <v-row class="dataTableView" v-for="(item,idx) in editPartDialog.available" :key="idx" dense>
                                <v-col cols="11">
                                    {{ item.tp_name }}
                                </v-col>
                                <v-col cols="1">
                                    <v-icon color="success" @click="addPartToSection(item)" right style="right:25px">
                                        mdi-chevron-double-right
                                    </v-icon>
                                   <!-- <v-icon color="error" @click="removePartFromSection(item)" v-if="item.exists" right style="right:25px">
                                        mdi-chevron-double-left
                                    </v-icon>-->
                                </v-col>
                            </v-row> 
                        </div>
                    </div>
                    <div style="width:75%;">
                        <div v-if="editPartDialog.editMode === 'section'">
                            <span style="padding-left:25px">
                                Active Parts: 
                                <v-icon title="Add Related Content Part" style="display:inline-flex; font-size:20px !important; padding-left:5px; padding-right:5px"  @click="addContentPart()">mdi-plus-circle</v-icon>
                                <v-icon title="Add Part Group" style="display:inline-flex; font-size:20px !important; padding-left:5px; padding-right:5px"  @click="addPartGroup()" >mdi-group</v-icon> 
                            </span><br/>
                            <div style="height:700px; max-height:700px; overflow: hidden scroll; padding-top:10px">
                                <draggable tag="ul" @change="onChange" :list="editPartDialog.data" class="list-group" handle=".dragHandle">
                                    <div v-for="(element, idx) in editPartDialog.data"  class="dataTableView"  :key="idx" >
                                        <v-row v-if="element.tmpl_part_metadata.contentPart && element.tmpl_part_metadata.content_type === 'GROUP'"  dense align="center" justify="center">
                                            <v-col cols="1" justify-center align="center">
                                                <v-icon style="display:inline-flex;" class="dragHandle">mdi-drag-variant</v-icon>
                                            </v-col>
                                            <v-col cols="9" >GROUP!</v-col>
                                            <v-col cols="2" style="display:inline-flex;" align="right">
                                                <v-switch dense v-model="element.tmpl_part_metadata.active"></v-switch>
                                                <v-icon title="Delete Part" style="padding-left:5px; display:inline-flex; font-size:20px !important" @click="removeAt(idx)">mdi-delete</v-icon>
                                                <v-icon v-if="element.tmpl_part_id === null" title="Edit" style="padding-left:5px; display:inline-flex; font-size:20px !important" @click="editContentGroup(element)">mdi-cog</v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else dense align="center" justify="center">
                                            <v-col cols="1" justify-center align="center">
                                                <v-icon style="display:inline-flex;" class="dragHandle">mdi-drag-variant</v-icon>
                                            </v-col>
                                            <v-col cols="9" style="padding-top:10px" >
                                                <div v-if="element.tmpl_part_id !== null">
                                                {{element.type}} <span v-if="element.toDelete" style="color: red; font-weight: bold; font-style: italic;"> - To Delete</span>
                                                </div>
                                                <div v-if="!element.tmpl_part_id || element.tmpl_part_id === null">
                                                {{element.tmpl_part_metadata.content_name}} <span v-if="element.toDelete" style="color: red; font-weight: bold; font-style: italic;"> - To Delete</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="2" style="display:inline-flex;" align="right">
                                                <v-switch dense v-model="element.tmpl_part_metadata.active"></v-switch>
                                                <v-icon title="Delete Part" style="padding-left:5px; display:inline-flex; font-size:20px !important" @click="removeAt(idx)">mdi-delete</v-icon>
                                                <v-icon title="Add Related Content Part" style="padding-left:5px; display:inline-flex; font-size:20px !important"  @click="addContentPart(element)">mdi-plus-circle</v-icon>
                                                <v-icon v-if="element.tmpl_part_id === null" title="Edit" style="padding-left:5px; display:inline-flex; font-size:20px !important" @click="editContentPart(element)">mdi-cog</v-icon>
                                            </v-col>
                                            <v-col cols="12" v-if="element.tmpl_part_metadata.linked_content.length > 0" >
                                                <v-row v-for="(sub,sidx) in element.tmpl_part_metadata.linked_content" :key="sidx" style="padding-left: 125px; padding-right:10px"  align="center" justify="center">
                                                    <v-col cols="10">
                                                        {{sub.tmpl_part_metadata.content_name}} <span v-if="sub.toDelete" style="color: red; font-weight: bold; font-style: italic;"> - To Delete</span>
                                                    </v-col>
                                                    <v-col cols="2" style="display:inline-flex;" align="right">
                                                        <v-switch dense v-model="sub.tmpl_part_metadata.active"></v-switch>
                                                        <v-icon title="Delete Part" style="padding-left:5px; display:inline-flex; font-size:20px !important" @click="removeRelatedAt(sidx,idx)">mdi-delete</v-icon>
                                                        <v-icon title="Edit" style="padding-left:5px; display:inline-flex; font-size:20px !important" @click="editContentPart(sub, element)">mdi-cog</v-icon>
                                                    </v-col>
                                                </v-row>
                    
                                            </v-col>
                                        </v-row> 
                                    </div>
                                </draggable>
                            </div>
                        </div>
                        <div v-if="editPartDialog.editMode === 'group'">
                            <div style="padding:25px">
                                EDIT GROUP:
                                <div style="display:inline-flex; vertical-align:middle">
                                    <v-text-field
                                        v-model="newTableConfig.row_count"
                                        style="width:150px"
                                        prepend-icon="mdi-view-agenda"
                                        dense
                                        type="number"
                                    />  
                                    <v-text-field
                                        v-model="newTableConfig.col_count"
                                        style="width:150px"
                                        prepend-icon="mdi-view-column"
                                        dense
                                        type="number"
                                    />   
                                    <v-btn small @click="generateTable">Create</v-btn> 
                                </div>
                                <div v-if="newTableConfig.rows.length > 0">
                                    <table id="table" width="100%" class="contentTable"  cellspacing="0" cellpadding="0" :style="cssVars">
                                        <tr v-for="(row,rIdx) in newTableConfig.rows" :key="rIdx">
                                            <td v-for="(cell,cIdx) in row.cells" :key="cIdx" :colspan="cell.col_span" :class="cell.selected ? 'selected' : ''" :rowspan="cell.row_span" @mousedown="newDragStart(row, cell, $event)" @mouseover="newDragOver(row, cell, $event)" @mouseup="dragStop(rIdx,cIdx, cell)">
                                                <span v-html="cell.value"></span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>                    
                                <br/>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn @click="editPartDialog.show = false">Cancel</v-btn>   
                    <v-btn color="primary" @click="saveSectionParts">Save</v-btn>   
                </v-card-actions>
            </v-card>
      </v-dialog>
      <v-dialog v-model="editContentDialog.show" v-if="editContentDialog.show" max-width="1200px" scrollable>
            <v-card>
                <v-card-title class="d-flex align-center">
                </v-card-title>
                <v-card-text style=" display:inline-flex">
                    <div style="width:100%">
                        <ViewSectionAdmin @preview="updateModel" :display="'modal'" v-if="admin && editingSection" @changeRHS="changePanel" :workflowConfig="adminConfig" :value="editContentDialog.part"></ViewSectionAdmin>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn @click="editContentDialog.show = false">Cancel</v-btn>   
                    <v-btn color="primary" @click="saveContentPart">Save</v-btn>   
                </v-card-actions>
            </v-card>
      </v-dialog>
      <Notification :notification="notification"/>
    </div>
</template>

<script>
import { mapState } from "vuex";
import viewUtils from "./view/utils";
import ViewSection from "./view/View_Section.vue";
import ViewParts from "./view/View_Parts.vue";
import ViewSectionAdmin from "./view/View_Section_Admin.vue";
import utils from "@/common/utils.js";
import nlpUtils from "@/common/nlpUtils.js";
import draggable from "vuedraggable";
import Notification from "@/components/common/SnackBar.vue";
import SuggestionsPanel from '@/components/common/SuggestionsPanel';
import moment from "moment";
import DataCaptureQuestionnaire from "@/components/cDataCaptureQuestionnaire.vue";
import DataCaptureForm from "@/components/cDataCaptureForm.vue";
import DataCaptureRequisitions from "@/components/cDataCaptureRequisitions.vue";
import AssignReviewGroup from "@/components/cAssignReviewGroup.vue";
import ContentDatabase from '@/components/common/ContentDatabase';
import DataCaptureDocumentRole from "@/components/cDataCaptureDocumentRole.vue";
import ScrollTo from "vue-scrollto";

export default {
    name: 'ViewAdmin',
    props: {
        displayMethod: String,
        leftPanelContent: String,
        rightPanelContent: String,
        data: null,
        paddingOffset: String,
        disableEdit: Boolean,
        adminMode: Boolean,
        adminConfig: Array,
        readOnly: Boolean,
    },  
    data: function() {
        return {
            moment: moment,
            isLoading: true,
            currentView: null,
            leftPanel: this.leftPanelContent ?? "sections",
            rightPanel: this.rightPanelContent ?? "score",
            rightPanelHeader: "",
            showLeft: true,
            isWarning: true,
            sectionParts: [],
            bannerText: "",
            footerText: "",
            display: this.displayMethod ?? "full",
            admin: this.adminMode ?? false,
            document: null,
            docStatus: null,
            docHierarchies: null,
            ptDeleteHistory: null,
            partsHistory: null,
            wordChecks: null,
            padOffset: this.paddingOffset ?? "0px",
            disablePartEdit: this.disableEdit ?? false,
            displayFooter: true,
            displayHeader: false,
            navBackDisabled: false,
            navNextDisabled: false,
            navBackHide: false,
            navNextHide: false,
            navChangesHide: false,
            editingSection: null,
            allSectionList: [],
            currentViewSectionId: 0,
            currentViewSubSectionId: 0,
            sectionCompleteSub: false,
            sectionCompleteLabel: "Complete Sections",
            activeSection: null,
            currentReviewSection: [],
            dpScrolled: [],
            templateDefinition: null,
            editPartDialog: {
                show: false,
                section_id: 0,
                data: [],
                available: [],
                editMode: "section"
            },
            editContentDialog: {
                show: false,
                part: {}
            },
            dragging: false,
            displayLinkedContent: true,
            saveInProgress: false,
            notification: {
                text:"",
                type:"success"
            },
            allHistory: [],
            history: null,
            checkSummaryPanels: [],
            checkSummaryView: 2,
            checkSummary: [],
            submitAction: null,
            allHistoryData: [],
            documentIssueDisplay: 'score_header_only',
            hightLightIssue: null,
            docTitle: null,
            collabHistory: null,
            comment: {
                dp_id: null,
                part: null,
                partType: null,
                text: "",
                isDirty: false,
                isNew: false,
            },      
            show_lhs_expand: true,
            show_rhs_expand: true,
            highlightedDocParts: [],
            collaboration: false,   
            userSpellCheckEnabled: false,
            nonSuggestionsList: ["hdr","header","flex","pattern","logo","bar","intro","social"],
            suggestionType: "",  
            tableDragStart: null,
            tableDragEnd: null,
            tableDragging: false,
            tableDragStartRow: null,
            tableDragStartCell: null,
            tableDragEndRow: null,
            tableDragEndCell: null,
            tableCellRange: {
                rowMin: 0,
                rowMax: 0,
                cellMin: 0,
                cellMax: 0,
                selected: []
            },
            tableDragSelectedCells: [],
            tableCellHighlightClass: "selectedItem",
            newTableConfig: {
                row_count: 5,
                col_count: 5,
                rows: [
                    {
                        row_id: 0,
                        row_position: 0,
                        cells: [
                            {
                                cell_position: 0,
                                row_span: 1,
                                col_span: 3,
                                selected: false,
                                content_type: "static",
                                tmpl_part_id: null,
                                tpa_id: null,
                                tmpl_view_section_content_id: null,
                                layout: [],
                                display: "text",
                                value: "Here is my Table Header"
                            }
                        ]
                    },
                    {
                        row_id: 1,
                        row_position: 1,
                        cells: [
                            {
                                cell_position: 0,
                                row_span: 1,
                                col_span: 1,
                                selected: false,
                                content_type: "tmplPart",
                                tmpl_part_id: 105004,
                                tpa_id: 2442,
                                tmpl_view_section_content_id: null,
                                layout: [],
                                display: "label"
                            },
                            {
                                cell_position: 1,
                                row_span: 1,
                                col_span: 2,
                                selected: false,
                                content_type: "tmplPart",
                                tmpl_part_id: 105004,
                                tpa_id: 2443,
                                tmpl_view_section_content_id: null,
                                layout: [],
                                display: "switch"
                            }
                        ]
                    },
                    {
                        row_id: 2,
                        row_position: 2,
                        cells: [
                            {
                                cell_position: 0,
                                row_span: 1,
                                col_span: 1,
                                selected: false,
                                content_type: "static",
                                tmpl_part_id: null,
                                tpa_id: null,
                                tmpl_view_section_content_id: null,
                                layout: [],
                                display: "text"
                            },
                            {
                                cell_position: 1,
                                row_span: 1,
                                col_span: 1,
                                selected: false,
                                content_type: "static",
                                tmpl_part_id: null,
                                tpa_id: null,
                                tmpl_view_section_content_id: null,
                                layout: [],
                                display: "text"
                            },
                            {
                                cell_position: 2,
                                row_span: 1,
                                col_span: 1,
                                selected: false,
                                content_type: "static",
                                tmpl_part_id: null,
                                tpa_id: null,
                                tmpl_view_section_content_id: null,
                                layout: [],
                                display: "text"
                            }
                        ]
                    },
                ]
            }
        }
    },
    components: {
        ViewSection, ViewParts, ViewSectionAdmin, draggable,Notification,SuggestionsPanel,
        DataCaptureQuestionnaire, DataCaptureForm, DataCaptureRequisitions, AssignReviewGroup,
        ContentDatabase, DataCaptureDocumentRole,
    },
    watch: {
        data(val){
            if(val){
                this.init();
            } 
        },
        hierarchiesLoading(val) {
            if (!val) this.init();
        },
        leftPanelContent(val){
            if(val === "gutter"){
                this.showLeft = false;
            }
            this.leftPanel = val;
        },
        rightPanelContent(val){
            this.rightPanel = val;
        },
    },    
    computed: {
        ...mapState({
            newUi: (state) => state.theme.newUi,
            docsLoading: (state) => state.docs.loading,
            docStatusSummary: (state) => state.docs.docStatusSummary,
            docTypeSummary: (state) => state.docs.docTypeSummary,
            hierarchiesLoading: (state) => state.hierarchies.loading,
            docTypes: (state) => state.hierarchies.docTypes,
            docListColumns: (state) => state.settings.config.docListColumns,
            lifecycleStatuses: (state) => state.hierarchies.lifecycleStatuses,
        }),
        leftPanelWidth(){
            switch(this.leftPanel){
                case "gutter":
                    return "42px";
                case "sections":
                default:
                    return "232px";
            }
        },   
        rightPanelWidth(){
            switch(this.rightPanel){
                case "permissions":
                    return "350px";
                case "partPermissions":
                    return "850px";
                case "hidden":
                    return "30px";
                case "suggestions":
                    return "375px";
                case "database":
                case "DCE":
                    return "750px";
                case "history":
                case "allHistory":
                case "score":
                    return "350px";
                case "collaboration":
                default:
                    return "245px";                    
            }
        },    
        containerHeight(){
            return this.display === "modal" ? "0px" : "48px";
        },    
        headerHeight(){
            return this.display === "modal" ? "0px" : "48px";
        },   
        containerPaddingTop(){
            return this.displayHeader ? "0px" : "64px";
        },  
        docContentBannerHeight(){
            return this.displayHeader ? "60px" : "30px";
        },  
        docContentFooterHeight(){
            return this.displayFooter ? "60px" : "10px";
        },  
        topPadOffset(){
            return this.padOffset;
        },   
        displayModeTopPad(){
            return this.display === "modal" ? "64px" : "0px";
        },  
        cssVars () {
            return {
                //width of left panel content
                '--left-panel-width': this.leftPanelWidth,
                //width of right panel content
                '--right-panel-width': this.rightPanelWidth,

                '--container-height': this.containerHeight,
                //height of app bar header in full view/doc modal
                '--header-height': this.headerHeight,
                //padding to top of content based on header
                '--padding-top': this.containerPaddingTop,
                '--doc-banner-height': this.docContentBannerHeight,
                '--doc-footer-height': this.docContentFooterHeight,
                '--container-top-offset': this.topPadOffset,
                '--display-mode-topPadding': this.displayModeTopPad
            }
        },
        partOrderedList(){
            let itemsClone = [...this.editPartDialog.data];
            let data = itemsClone.filter(x => x.order).sort((a, b) => a.order - b.order);
            return data;
        },
        docTitleList(){
            if(this.data.document){
                return [{
                    doc_name: this.data.document.doc_name,
                    doc_id: this.data.document.doc_id
                }]
            }

            return [];

        },
        suggestionsPart(){
            if(this.sectionParts){
                let allParts = this.sectionParts.filter(x => x.tmpl_part_id && !this.nonSuggestionsList.some(v => x.type.toLowerCase().includes(v))).map(x => {
                    return { 
                        tmpl_part_id: x.tmpl_part_id, 
                        type: x.type, 
                        multiple: (x.cardinality.maxError > 1 || x.cardinality.minError > 1 || x.cardinality.maxWarn > 1 || x.cardinality.minWarn > 1)
                    }
                });

                return this.suggestionType === "" ? allParts : allParts.filter(x => x.type === this.suggestionType);
                
            } else {
                return []
            }
        }, 
        sectionPermissionList(){
            return this.activeSection ? this.activeSection.permissions_list : null;
        },
        showScoring(){
            return !!this.data.document.state.showActions?.some((x) => x.showScoring);
        }  
    },
    mounted() {
    },
    updated() {
    },    
    created() {
        this.init();
    },    
    methods: {          
        multiRowPart(item){
            return (item.cardinality.maxError > 1 || item.cardinality.minError > 1 || item.cardinality.maxWarn > 1 || item.cardinality.minWarn > 1);
        },  
        baseCell(){
            return {
                cell_position: 0,
                row_span: 1,
                col_span: 1,
                selected: false,
                content_type: "static",
                tmpl_part_id: null,
                tpa_id: null,
                tmpl_view_section_content_id: null,
                layout: [],
                display: "text"
            }
        },
        generateTable(){
            this.newTableConfig.rows = [];
            for(var i = 0; i < this.newTableConfig.row_count; i++){
                let rowObj = {
                    row_id: 0,
                    row_position: i,
                    cells: []
                };
                for(var x = 0; x < this.newTableConfig.col_count; x++){
                    let cell = {
                        ...this.baseCell(),
                        cell_position: x
                    }
                    rowObj.cells.push(cell)
                }
                this.newTableConfig.rows.push(rowObj);
            }
        },
        newSelectTo(row, cell){
            let startRow = this.tableDragStartRow.row_position + (this.tableDragStartCell.row_span - 1);
            let startCell = this.tableDragStartCell.cell_position + (this.tableDragStartCell.col_span - 1);

            let endRow =  this.tableDragEndRow.row_position + (this.tableDragEndCell.row_span - 1);
            let endCell = this.tableDragEndCell.cell_position + (this.tableDragEndCell.col_span - 1);

            var rowStart, rowEnd, cellStart, cellEnd;     

            if(endRow <= startRow){
                rowStart = row;
                rowEnd = this.tableDragStartRow.row_position + (this.tableDragStartCell.row_span - 1);
            } else {
                rowStart = this.tableDragStartRow.row_position + (this.tableDragStartCell.row_span - 1);
                rowEnd = row;
            }

            if(endCell <= startCell){
                cellStart = cell;
                cellEnd = this.tableDragStartCell.cell_position + (this.tableDragStartCell.col_span - 1);
            } else {
                cellStart = this.tableDragStartCell.cell_position + (this.tableDragStartCell.col_span - 1);
                cellEnd = cell;
            }

            console.log(rowStart, rowEnd, cellStart, cellEnd);
            this.clearSelected();

            this.tableCellRange = {
                rowMin: rowStart,
                rowMax: rowEnd,
                cellMin: cellStart,
                cellMax: cellEnd,
                selected: []
            }

            this.newTableConfig.rows.forEach((r) => {
                r.cells.forEach((c) => {

                    let matchRow = r.row_position >= rowStart && r.row_position <= rowEnd;
                    let matchCell = c.cell_position >= cellStart && c.cell_position <= cellEnd;

                    if(matchRow && matchCell){
                        c.selected = true;
                        this.tableCellRange.selected.push(c);
                    }
                })
            });

            /*for (var i = startCellRowSpan; i <= endRow; i++) {
                for (var j = startCellColSpan; j <= endCell; j++) {
                    this.newTableConfig.rows[i].cells[j].selected = true;
                }  
            }*/

            /*var rowStart, rowEnd, cellStart, cellEnd;
    
            if (rowIdx < this.tableDragStartRow) {
                rowStart = rowIdx;
                rowEnd = this.tableDragStartRow;
            } else {
                rowStart = this.tableDragStartRow;
                rowEnd = rowIdx;
            }
            
            if (cellIdx < this.tableDragStartCell) {
                cellStart = cellIdx;
                cellEnd = this.tableDragStartCell;
            } else {
                cellStart = this.tableDragStartCell;
                cellEnd = cellIdx;
            }    */    

        },
        newDragOver(row, cell, ev){
            if(!this.tableDragStart) return;
            ev.preventDefault();
            ev.stopPropagation();
            this.tableDragEndRow = row;
            this.tableDragEndCell = cell;
            let endRow = row.row_position + (1 - cell.row_span);
            let endCell = cell.cell_position + (1 - cell.col_span);            
            this.newSelectTo(endRow, endCell);
            return false;
        },
        newDragStart(row, cell, ev){
            this.tableDragStart = true;
            this.tableDragStartCell = cell;
            this.tableDragStartRow = row;
            ev.preventDefault();
            ev.stopPropagation();
            this.clearSelected();
            return false;
        },
        dragStop(){
            this.tableDragStart = false;
        },
        selectTo(rowIdx, cellIdx){

            var rowStart, rowEnd, cellStart, cellEnd;
    
            if (rowIdx < this.tableDragStartRow) {
                rowStart = rowIdx;
                rowEnd = this.tableDragStartRow;
            } else {
                rowStart = this.tableDragStartRow;
                rowEnd = rowIdx;
            }
            
            if (cellIdx < this.tableDragStartCell) {
                cellStart = cellIdx;
                cellEnd = this.tableDragStartCell;
            } else {
                cellStart = this.tableDragStartCell;
                cellEnd = cellIdx;
            }        
            
            this.clearSelected();

            this.tableCellRange = {
                rowMin: rowStart,
                rowMax: rowEnd,
                cellMin: cellStart,
                cellMax: cellEnd
            }

            this.newTableConfig.rows.forEach((r,ridx) => {
                r.cells.forEach((c,cidx) => {

                    let rowspan = ridx + (c.row_span - 1);
                    let cellspan = cidx + (c.col_span - 1);

                    if(ridx >= rowStart && rowspan <= rowEnd && cellspan <= cellEnd && cidx >= cellStart){
                        c.selected = true;
                    }

                })
            });

            /*this.newTableConfig.rows.forEach((r,ridx) => {
                if((ridx+(r.row_span - 1)) <= rowEnd){
                    r.cells.forEach((c,cidx) => {
                        if((cidx+(c.col_span - 1)) <= cellEnd){
                            c.selected = true;
                        }
                    })
                }
            })*/

            /*for (var i = rowStart; i <= rowEnd; i++) {
                for (var j = cellStart; j <= cellEnd; j++) {
                    this.newTableConfig.rows[i].cells[j].selected = true;
                }  
            }   */ 

        },
        clearSelected(){
            this.newTableConfig.rows.forEach(r => {
                r.cells.forEach(c => {
                    c.selected = false;
                })
            })
        },
        dragOver(rowIdx, cellIdx, ev){
            if(!this.tableDragStart) return;
            ev.preventDefault();
            ev.stopPropagation();
            this.tableDragEndRow = rowIdx;
            this.tableDragEndCell = cellIdx;
            this.selectTo(rowIdx, cellIdx);
            return false;
        },
        setStartCell(rowIdx, cellIdx, ev){
            this.tableDragStart = true;
            this.tableDragStartCell = cellIdx;
            this.tableDragStartRow = rowIdx;
            /*if(this.newTableConfig.rows[rowIdx].cells[cellIdx].row_span > 1){
                this.tableDragStartRow = this.newTableConfig.rows[rowIdx].cells[cellIdx].row_span - 1;
            }

            if(this.newTableConfig.rows[rowIdx].cells[cellIdx].col_span > 1){
                this.tableDragStartCell = this.newTableConfig.rows[rowIdx].cells[cellIdx].col_span - 1;
            }*/
            ev.preventDefault();
            ev.stopPropagation();
            return false;
        },
        async doReviewRevert(data){
            if (!data) return;
            if (!data.processed) await this.reviewRevert(data.rc, data.part, data.partType);
            data.processed = true;
        },
        async reviewRevert(change, part) {
            this.revertAttributes(part.attributes, change.attributes);
            let changes = [
                {column: "text", oldValue: change.previous},
                {column: "is_essential", oldValue: change.previous_is_essential}
            ];
            let obj = {
                ...part,
                changes: changes
            }
            await this.revertChange(obj);
        },
        revertAttributes(original, updates) {
            original.forEach((a) => {
                let updated = updates.find((c) => c.tpa_id === a.tpa_id);
                if (updated && a.text !== updated.text) {
                    a.text = updated.text;
                    a.isDirty = true;
                }
            });
        },
        instantiateNewRow(partType, parent, text = "") {
            let currentParts = (parent ? parent.childParts : partType.parts).filter(
                (x) => x.tmpl_part_id === partType.tmpl_part_id
            );
            let sequence = currentParts.length
                ? currentParts.reduce(
                    (pval, cval) => (pval > cval.sequence ? pval : cval.sequence),
                    0
                ) + 1
                : 1;
            let ret = {
                Status: "",
                StatusList: [],
                WordCount: "",
                doc_part_id: 0,
                id: "",
                notes: "",
                isDirty: false,
                rowMessage: "",
                sequence: sequence,
                status: "",
                text: text,
                is_essential:
                partType.tmpl_part_metadata.uses_essential_flag === 2
                    ? this.tempEssentials
                    ? null
                    : 1
                    : 0,
                attributes: [],
                quality: 0,
                type: partType.type,
                tmpl_part_id: partType.tmpl_part_id,
                parent_dp_id: parent ? parent.doc_part_id : null,
                highlightSection: false,
                editing: false,
                hover: false,
                expanded: false,
                linked_content: []
            };
            if (partType.tmpl_part_metadata.attributes.length) {
                ret.attributes = partType.tmpl_part_metadata.attributes.map((a) => {
                return {
                    dpa_id: 0,
                    tpa_id: a.tpa_id,
                    text: text,
                    editing: false,
                    isDirty: true,
                    active: true,
                    cols: a.cols,
                    restrict_to_lookup: a.restrict_to_lookup,
                    placeholder: a.placeholder,
                    restrict_to_date: a.restrict_to_date,
                    locked: a.locked,
                    css_class_name: a.css_class_name,
                    allow_adding: a.allow_adding,
                    lookup_type: a.lookup_type,
                    lookup_key: a.lookup_key,
                    restrict_to_htid: a.restrict_to_htid,
                    restrict_to_ht_level: a.restrict_to_ht_level,
                };
                });
            } else {
                ret.attributes.push({
                    active:true,
                    allow_adding:true,
                    cols:12,
                    css_class_name:"",
                    default_text: partType.tmpl_part_metadata.default_text ?? "",
                    editing: false,
                    isDirty: true,
                    include_in_part_val:true,
                    locked: partType.tmpl_part_metadata.tp_locked === 1,
                    lookup_dependency_tpa_source:null,
                    lookup_key:null,
                    lookup_type:null,
                    lookup_v2_tpa_destination:null,
                    lookup_v3_tpa_destination:null,
                    position:1,
                    prefix:null,
                    required:null,
                    restrict_to_date:null,
                    restrict_to_lookup:null,
                    separator:null,
                    suffix:null,
                    text: text,
                    title:null,
                    tpa_id:null,
                    restrict_to_htid: 0,
                    restrict_to_ht_level: 0,
                });
            }

            if (partType.childPartTypes && partType.childPartTypes.length) {
                ret.childParts = [];
                utils.setupChildPartTypes(
                ret,
                partType,
                this.data.partsHistory,
                this.data.docHierarchies,
                this.data.wordChecks
                );
            }
            utils.setDocPartStatus(
                ret,
                partType,
                this.data.wordChecks,
                this.data.docStatus,
                this.data.document
            );

            if (parent !== undefined && parent.temp === true) {
                ret.temp = true;
            }

            return ret;
        },        
        async revertDelete(item) {
            this.saveStart();
            let partType = this.ptDeleteHistoryPT;
            let data = {
                doc_part_id: item.key,
                sequence: partType.parts.length + 1,
                doc_part_type: partType.type,
                tmpl_part_id: partType.tmpl_part_id
            };

            let result = await viewUtils.revertDeletePart(data);

            let row = this.instantiateNewRow(partType, undefined, result.data.Data.newRow.text);

            if(result.data.Data.newRowAttributes.length > 0){
                row.attributes = row.attributes.map(ra => {
                let match = result.data.Data.newRowAttributes.find(x => x.tpa_id === ra.tpa_id);
                return {
                  ...ra,
                  dpa_id: match?.dpa_id,
                  text: match?.value
                }
              })
            }

            let obj = {
                ...row,
                ...result.data.Data.newRow,
                doc_part_id: item.key,
                doc_part_type: partType.type,
                tmpl_part_id: partType.tmpl_part_id,
                parent_dp_id: parent ? parent.doc_part_id : null,
                sequence: row.sequence,
                insertOrder: row.sequence
            };

            this.ptDeleteHistory
            await this.updatePart(data, obj, { result: result.data });
            this.saveEnd();
            this.triggerNotification(`${partType.type} Reverted`, "success");

            let phIndex = this.partsHistory.findIndex(
              (ph) => ph.key === item.key
            );
            if (phIndex >= 0) {
              this.partsHistory.splice(phIndex, 1);
            }
            this.partsHistory.push(result.data.Data.audit);

            let dhIndex = this.ptDeleteHistory.findIndex(
              (ph) => ph.key === item.key
            );
            if (dhIndex >= 0) {
              this.ptDeleteHistory.splice(dhIndex, 1);
            }
            partType.hasDeletedParts = this.partsHistory.some(
              (pa) => pa.isDeleted && pa.name === partType.type
            );

        },             
        async revertChange(item){
            this.saveStart();
            let docpart = this.history.part;
            let obj = {
                text: null,
                is_essential: null,
                notes: null,
                quality: null,
                attributes: item.oldAttributes || [],
            };
            if (obj.attributes.length) {
                this.revertAttributes(docpart.attributes, obj.attributes);
            }
            item.changes?.forEach((c) => {
                obj[c.column] = c.oldValue;
            });

            let docPartType = this.data.document.parttypes.find(x => x.tmpl_part_id === docpart.tmpl_part_id);
            let isAttr = docPartType.tmpl_part_metadata.attributes?.length >= 2;

            let newObj = {
                text: obj.text === null ? docpart.text : obj.text,
                doc_part_id: docpart.doc_part_id,
                doc_part_type: docPartType.type,
                doc_id: this.data.document.doc_id,
                system_number: this.data.document.system_number,
                tmpl_part_id: docpart.tmpl_part_id,
                notes: obj.notes === null ? docpart.notes : obj.notes,
                parent_dp_id: null,
                is_essential: obj.is_essential === null ? docpart.is_essential : obj.is_essential,
                quality: docpart.quality,
                sequence: docpart.sequence,
                attributes: isAttr ? docpart.attributes : []
            };

            docpart = {
                ...docpart,
                text: newObj.text,
                notes: newObj.notes,
                is_essential: newObj.is_essential
            };

            docpart.subParts = isAttr ? utils.attributesToText(
                    docPartType.tmpl_part_metadata.attributes,
                    docpart.attributes
                ) : utils.partToSubParts(docpart.text);
                if(docpart.attributes.length === 1){
                    docpart.attributes[0].text = docpart.text;
                }
            
            let result = await viewUtils.updateDocPart(newObj);
            
            await this.updatePart(newObj, docpart, result);
            this.saveEnd();
            this.triggerNotification(`${docPartType.type} Reverted`, "success");
        },
        openSuggestions(type){
            this.toggleRHS("suggestions", type);
        },
        updateRecentPart(type){
            this.suggestionType = type ?? "";
        },
        initDrag(){
            this.dragging = true;
        },
        dragEnd(){
            this.dragging = false;
        },        
        commentHandler(action) {
            switch (action) {
                case "save_new":
                this.doStateActionComment(
                    this.data.document.state.docPartCommentAction,
                    this.comment
                );
                break;
                case "cancel_new":
                    this.comment.text = "";
                    this.comment.isDirty = false;
                break;
            }
        },
        async doStateActionComment(action, comment) {
            if (!this.data.document) return;
            this.isLoading = true;
            let saveRespHandler = (data) => {
                this.commentHandler("cancel_new");
                if (data) {
                if (this.comment.part) this.comment.part.hasComments = 1;
                let newComment = data.comments[0];
                this.history.collabSummary.unshift({
                    type: "comment",
                    sortBy: null,
                    user_name: newComment.user_name,
                    user_initials: newComment.user_initials,
                    data: data.comments[0],
                });
                }
            };
            let request = {
                doc_id: this.data.document.doc_id,
                action_available_id: action.action_available_id,
                lifecycle_role_id: action.lifecycle_role_id,
                document_role_id: action.document_role_id,
                preProcessResponse: null,
                comment: comment,
            };

            await viewUtils.processdocumentaction(request).then((resp) => {
                if (resp.data.Status === "OK") {
                    saveRespHandler(resp.data.Data);
                } else {
                    alert(resp.data.Message);
                }
                this.isLoading = false;
            })

        },
        handleDelete(row, partType, parent, resp, source) {
            row.isDirty = false;
            let pts = (parent ? parent.childPartTypes : source).find(
                (pt) => pt.type === partType.type
            );
            if (pts && pts.parts) {
                let pIndex = pts.parts.findIndex(
                (p) => p.doc_part_id === row.doc_part_id
                );
                if (pIndex >= 0) {
                // won't be present if moved
                pts.parts.splice(pIndex, 1);
                }
            }
            if (parent) {
                let cIndex = parent.childParts.findIndex(
                (p) => p.doc_part_id === row.doc_part_id
                );
                if (cIndex >= 0) parent.childParts.splice(cIndex, 1);
                if (!parent.childParts.length) {
                parent.expanded = false;
                }
            }
            partType.parts.forEach((p) => {
                utils.setDocPartStatus(
                p,
                partType,
                this.data.wordChecks,
                this.data.docStatus,
                this.data.document
                );
            });

            let phIndex = this.partsHistory.findIndex(
                (ph) => ph.key === row.doc_part_id
            );
            if (phIndex >= 0) {
                if (this.history && this.history.key === row.doc_part_id) {
                this.history = null;
                }
                this.partsHistory.splice(phIndex, 1);
            }
            if (resp && resp.data && resp.data.Data && resp.data.Data.audit) {
                this.partsHistory.push(resp.data.Data.audit);
                if (this.ptDeleteHistory) {
                this.ptDeleteHistory.push(resp.data.Data.audit);
                }
            }
            partType.hasDeletedParts = true;
        },         
        deletePart(row, partType, parent, resp){
            let isChildPart = row.parent_dp_id && row.parent_dp_id !== null;
            let flatParts = viewUtils.genericFlatten(this.document.parttypes, "childPartTypes");
            let source = flatParts.find(x => x.tmpl_part_id === partType.tmpl_part_id);
             
            //update sectioned parts with update
            if(isChildPart){
                let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_part_id === source.tmpl_part_metadata.parent_tp_id));
                all.forEach(async a => {
                    let sectionSource = a.part_data.find(x => x.tmpl_part_id === source.tmpl_part_metadata.parent_tp_id);
                    let parentPart = sectionSource.parts.find(x => x.doc_part_id === row.parent_dp_id);
                    if(parentPart){
                        let parentCpt = parentPart.childPartTypes?.find(x => x.tmpl_part_id === row.tmpl_part_id);
                        let idx = parentCpt.parts.findIndex(x => x.doc_part_id === row.doc_part_id);
                        if(idx > -1){
                            parentCpt.parts.splice(idx,1);
                        }
                        parentPart.childParts = parentCpt.parts;   
                        parentPart.expanded = parent.expanded;                        
                    }
                })
            } else {
                let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_part_id === source.tmpl_part_metadata.tmpl_part_id));
                all.forEach(async a => {
                    let sectionSource = a.part_data.find(x => x.tmpl_part_id === source.tmpl_part_metadata.tmpl_part_id);
                    let idx = sectionSource.parts.findIndex(x => x.doc_part_id === row.doc_part_id);
                    if(idx > -1){
                        sectionSource.parts.splice(idx,1);
                    }
                })                

            }

            if(isChildPart){
                source = flatParts.find(x => x.childPartTypes?.some(c => c.tmpl_part_id === source.tmpl_part_id));
                let parentPart = source.parts.find(x => x.doc_part_id === row.parent_dp_id);
                if(parentPart){
                    let parentCpt = parentPart.childPartTypes?.find(x => x.tmpl_part_id === row.tmpl_part_id);
                    let idx = parentCpt.parts.findIndex(x => x.doc_part_id === row.doc_part_id);
                    if(idx > -1){
                        parentCpt.parts.splice(idx,1);
                    }
                    parentPart.childParts = parentCpt.parts;   
                    parentPart.expanded = parent.expanded;                         
                }
            } else {
                let idx = source.parts.findIndex(x => x.doc_part_id === row.doc_part_id);
                if(idx > -1){
                    source.parts.splice(idx,1);
                }
            }

            let idx = this.partsHistory.findIndex(x => x.key === resp.data.Data.audit.key);
            if (idx >= 0) {
                if (this.history && this.history.key === row.doc_part_id) {
                    this.history = null;
                }
                this.partsHistory.splice(idx, 1);
            }
            if(resp.data.Data.audit){
                this.partsHistory.unshift(resp.data.Data.audit);
                if(this.ptDeleteHistory){
                idx = this.ptDeleteHistory.findIndex(x => x.key === resp.data.Data.audit.key);
                if (idx >= 0) {
                    this.ptDeleteHistory.splice(idx, 1);
                }      
                this.ptDeleteHistory.unshift(resp.data.Data.audit);
                    if(this.ptDeleteHistory.key === resp.data.Data.audit.key){
                        this.ptDeleteHistory = {
                            ...this.ptDeleteHistory,
                            ...resp.data.Data.audit
                        }
                    }
                }
                this.allHistory.push(resp.data.Data.audit);
            }

            partType.hasDeletedParts = true;
            source.parts.forEach((p) => {
                utils.setDocPartStatus(
                    p,
                    source,
                    this.data.wordChecks,
                    this.data.docStatus,
                    this.data.document, true
                );
            });
            //this.resyncItem(isChildPart ? parent : partType);

            let target = this.activeSection.parent_section ? `${this.activeSection.parent_section ?? ""}|${this.activeSection.tmpl_view_section_id ?? ""}` : `${this.activeSection.tmpl_view_section_id ?? ""}|`;
            this.loadSectionContent(target);
            this.setCheckSummary();
        },

        scrollToSection(issue) {
            let options = {
                container: ".docHolderCenter", //this.$refs.doccontainer, //"#container",
                easing: "ease-in",
                offset: -150,
                force: true,
                cancelable: true,
                // onStart: function (element) {
                //   // scrolling started
                // },
                // onDone: function (element) {
                //   // scrolling is done
                // },
                // onCancel: function () {
                //   // scrolling has been interrupted
                // },
                x: false,
                y: true,
            };
            let next;
            let thisSet = [];
            let processing = true;
            while (processing) {
                issue.items.forEach((t) => {
                t.instances.forEach((i) => {
                    i.partsAffected.forEach((pa) => {
                    if (this.dpScrolled.indexOf(pa) >= 0) {
                        thisSet.push(pa);
                    } else if (!next) {
                        next = pa;
                        thisSet.push(pa);
                    }
                    });
                });
                });
                if (
                this.dpScrolled.length === thisSet.length &&
                thisSet.length > 0 &&
                this.dpScrolled[thisSet.length - 1] === thisSet[thisSet.length - 1]
                ) {
                this.dpScrolled.splice(0);
                thisSet.splice(0);
                } else {
                processing = false;
                }
            }
            this.dpScrolled = thisSet;
            ScrollTo.scrollTo("#container_" + next, 500, options);
        },
        highlightSection(issue) {
            this.hightLightIssue = issue;
            this.highlightedDocParts = [];
            issue.items.forEach((t) => {
                t.instances.forEach((i) => {
                    this.highlightedDocParts.push(i.partsAffected.flat(1));
                });
            });

            this.highlightedDocParts = [...new Set(this.highlightedDocParts.flat(1))];
        },
        unhighlightSection() {
            this.hightLightIssue = null;
            this.highlightedDocParts = [];
        },        
        isActionDisabled(getMessage) {
            if(this.adminMode) return getMessage ? `Unavailable in Admin Mode` : true;
            if(this.currentReviewSection.some(x => !x.complete)) return getMessage ? `Complete All Sections` : true;
            if (!this.data.document || !this.checkSummary || (this.submitAction === null || this.submitAction === undefined)) return false;
            if (this.data.document.state.missing_data && !this.submitAction.allow_errors) {
                return getMessage
                ? `${this.submitAction.name} is not permitted while ${this.data.document.state.triggerDCAction.name} is missing`
                : true;
            } else if (this.checkSummary.errors.length && !this.submitAction.allow_errors) {
                return getMessage
                ? `${this.submitAction.name} is not permitted while the document has inclusivity & quality errors`
                : true;
            } else if (this.checkSummary.warnings.length && !this.submitAction.allow_warnings) {
                return getMessage
                ? `${this.submitAction.name} is not permitted while the document has inclusivity & quality warnings`
                : true;
            } else {
                return getMessage ? this.submitAction.name : false;
            }
        },        
        doSubmitAction(){
            let allSections = viewUtils.getFlatSections(this.currentView.sections);
            let currentSection = allSections.find(x => x.tmpl_view_section_id === this.currentViewSectionId);
            if(currentSection.submit_action_id){
                let action = this.data.document.state.actionsAvailable.find(x => x.lifecycle_action_id === currentSection.submit_action_id);
                this.$emit("doStateAction", action);
            }
        },
        setCheckSummary() {
            this.checkSummary = utils.documentCheckSummary(
                this.document,
                this.wordChecks,
                this.checkSummaryView
            );
            this.$emit("checkSummaryUpdated", this.checkSummary);
            if (this.checkSummary.categories && this.checkSummary.categories.length) {
                this.allCheckSummaryPanels();
            }
        },
        allCheckSummaryPanels() {
            this.checkSummaryPanels = this.checkSummary.categories.map((c, i) => i);
        },
        closeCheckSummaryPanels() {
            this.checkSummaryPanels = [];
        },
        closeCheckStatus() {
            this.showCheckStatus = false;
        },        
        initCheckSummary(){
            this.checkSummary = utils.documentCheckSummary(
                this.document,
                this.wordChecks,
                this.checkSummaryView,
                undefined
            );
            this.$emit("checkSummaryUpdated", this.checkSummary);
        },
        toggleRHS(val, data){
            this.rightPanel = val;
            switch(val){
                case "permissions":
                    this.rightPanelHeader = 'Permissions';
                    break;
                case "partPermissions":
                    this.rightPanelHeader = 'Permissions';
                    break;
                case "hidden":
                    this.rightPanelHeader = '';
                    break;
                case "suggestions":
                    this.docTitle = {
                        doc_name: this.data.document.doc_name,
                        doc_id: this.data.document.doc_id
                    }
                    this.suggestionType = data ?? "";
                    this.rightPanelHeader = 'Suggestions';
                    break;
                case "database":
                    this.rightPanelHeader = 'Database';
                    break;
                case "DCE":
                    this.rightPanelHeader = 'Dynamic Content Engine';
                    break;
                case "allHistory":
                    this.rightPanelHeader = 'All History';
                    this.showAllHistory();
                    break;
                case "score":
                    this.rightPanelHeader = 'Document Score';
                    this.initCheckSummary();
                    break;
                case "collaboration":
                    this.rightPanelHeader = 'Collaboration';
                    break;

            }
        },
        toggleLHS(content){
            this.leftPanel = content;

            if(content === 'sections'){
                let target = this.activeSection.parent_section ? `${this.activeSection.parent_section ?? ""}|${this.activeSection.tmpl_view_section_id ?? ""}` : `${this.activeSection.tmpl_view_section_id ?? ""}|`;
                this.loadSectionContent(target);
            }
            /*if(this.leftPanel === "gutter"){
                this.leftPanel = "sections";
            } else {
                this.leftPanel = "gutter";
            }*/
        },
        userInitials() {
        return this.$loginState.user.name
            .split(" ")
            .map((x) => x.substr(0, 1))
            .join("");
        },
        showDeletedPartHistory(pt) {
            this.history = null;
            this.headerHistoryShow = false;
            this.ptDeleteHistoryPT = pt;
            this.ptDeleteHistory = this.partsHistory
                ? this.partsHistory.filter((pa) => pa.isDeleted && pa.name === pt.type)
                : null;
            this.rightPanel = 'history';
            this.rightPanelHeader = `${pt.type} Delete History`;
        },
        showPartHistory(part, type, collab){

            this.collabHistory = !!collab;
            this.comment.dp_id = part.doc_part_id;
            this.comment.part = collab ? part : null;
            this.comment.partType = collab ? type : null;            
            this.history = this.partsHistory
                ? this.partsHistory.find((pa) => pa.key === part.doc_part_id)
                : null;
            if (this.history) {

                this.history.part = part;
                this.history.partType = type;

            }
            this.rightPanel = 'history';
            this.rightPanelHeader = `${this.history.name} ${this.collabHistory ? "Activity" : "History"}`;
        },
        showAllHistory(){
            let allSections = viewUtils.getFlatSections(this.currentView.sections);
            let currentSection = allSections.find(x => x.tmpl_view_section_id === this.currentViewSectionId);
            this.allHistoryData = currentSection.section_history;
        },
        triggerNotification(text,type){
            this.notification = {
                text:text,
                type:type
            }
        },  
        resyncItem(item){
            let flatParts = viewUtils.genericFlatten(this.document.parttypes, "childPartTypes");
            let source = flatParts.find(x => x.tmpl_part_id === item.tmpl_part_id);
            if(source){
                item.parts.forEach(part => {
                    let idx = source.parts.findIndex(x => x.doc_part_id === part.doc_part_id);
                    if(idx > -1){
                        source.parts[idx] = part;
                    }
                });
                source.parts = source.parts.sort((a, b) => (a.sequence > b.sequence && 1) || -1);
            }

            let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_part_id === source.tmpl_part_id));
            all.forEach(async a => { 
                let sectionSource = a.part_data.find(x => x.tmpl_part_id === item.tmpl_part_id);
                item.parts.forEach(part => {
                    let idx = sectionSource.parts.findIndex(x => x.doc_part_id === part.doc_part_id);
                    if(idx > -1){
                        sectionSource.parts[idx] = part;
                    }
                });
                sectionSource.parts = sectionSource.parts.sort((a, b) => (a.sequence > b.sequence && 1) || -1);
            })
        },
        async reorderParts(item){
            let data = item.parts.map((r, i) => {
                let ret = {};
                ret.sequence = i + 1;
                ret.type = item.type;
                ret.doc_part_id = r.doc_part_id;
                return ret;
            });
            item.parts = item.parts.map(sp => {
                let newOrder = data.find(x => x.doc_part_id === sp.doc_part_id);
                return {
                    ...sp,
                    sequence: newOrder.sequence
                }
            });
            item.parts = item.parts.sort((a, b) => (a.sequence > b.sequence && 1) || -1);
            await viewUtils.saveItemOrder(data);
            this.resyncItem(item);
            this.triggerNotification("Part Order Updated","success");            
        },
        reorderPartList(item, order){
            item.parts = item.parts.map(sp => {
                let newOrder = order.find(x => x.doc_part_id === sp.doc_part_id);
                return {
                    ...sp,
                    sequence: newOrder.sequence
                }
            });
            item.parts = item.parts.sort((a, b) => (a.sequence > b.sequence && 1) || -1);
            return item;
        }, 
        async changeOrder(item, save){
            let data = item.parts.map((r, i) => {
                let ret = {};
                ret.sequence = i + 1;
                ret.type = item.type;
                ret.doc_part_id = r.doc_part_id;
                return ret;
            });

            if(save){
                await viewUtils.saveItemOrder(data);
                this.triggerNotification("Part Order Updated","success");
            }
                
            return this.reorderPartList(item, data);
        },
        async processResults(list){
            this.dragEnd();
            for (const r of list) {
                await this.updatePart(r.resp.request, r.part, r.resp);
            }
            this.triggerNotification("Content Updated","success");
        },
        async updateContentPart(resp, part){
            let updatedItem = resp.data.data;
            if(part.parent_id === null){
                let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_view_section_part_id === updatedItem.tmpl_view_section_part_id));
                all.forEach(a => { 
                    let sectionSource = a.part_data.find(x => x.tmpl_view_section_part_id === updatedItem.tmpl_view_section_part_id);
                    if(sectionSource.tmpl_part_metadata.content_type === 'TABLE'){
                        updatedItem = {
                            ...updatedItem,
                            rows: part.rows
                        }
                    } 
                    
                    sectionSource.parts[0].attributes[0] = updatedItem;
                });
            } else {
                let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_view_section_part_id === part.parent_id));
                all.forEach(a => { 
                    let sectionSource = a.part_data.find(x => x.tmpl_view_section_part_id === part.parent_id);
                    let sectionDocPart = sectionSource.parts.find(x => x.doc_part_id === updatedItem.doc_part_id);
                    let linkedContent = sectionDocPart.linked_content.find(x => x.tmpl_view_section_part_id === updatedItem.tmpl_view_section_part_id);
                    linkedContent.parts[0].attributes[0] = {
                        ...part,
                        value: updatedItem.value
                    };
                });
            }

        },
        togglePartDisplay(part, item){
            item.tmpl_part_metadata.linked_parts.forEach(lp => {
                
                let sourcePart = part;
                if(lp.tpa_id){
                    sourcePart = sourcePart.attributes.find(cpa => cpa.tpa_id === lp.tpa_id);
                }

                if(lp.tmpl_view_section_part_id){
                    let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_view_section_part_id === lp.tmpl_view_section_part_id));
                    all.forEach(async a => {
                        let targetPart = a.part_data.find(x => x.tmpl_view_section_part_id === lp.tmpl_view_section_part_id);
                        if(targetPart){
                            let permissionViewAllowed = targetPart.permissions_list.viewPermissionOverride === null || targetPart.permissions_list.viewPermissionOverride === true;
                            if(permissionViewAllowed){
                                let viewPermission = lp.display_type === "SHOW" && sourcePart.text === lp.display_values ? true : false;
                                targetPart.permissions_list.displayPermissionOverride = viewPermission;
                            }
                        }
                    });
                } else {            
                    let target = this.allSectionList.find(x => x.tmpl_view_section_id === lp.tmpl_view_section_id);     
                    let viewPermission = lp.display_type === "SHOW" && sourcePart.text === lp.display_values ? true : false;

                    /*if(!viewPermission){
                        let dependentSections = target.part_data.filter(x => x.tmpl_part_metadata.linked_parts.length > 0 && x.tmpl_part_metadata.linked_parts.some(p => p.tmpl_view_section_part_id === null)).map(x => {
                            return x.tmpl_part_metadata.linked_parts.filter(p => p.tmpl_view_section_part_id === null).map(s => {
                                return s.tmpl_view_section_id
                            }).flat(1)
                        }).flat(1)

                        let dependentSections = target.part_data.filter(x => x.tmpl_part_metadata.linked_parts.length > 0 && x.tmpl_part_metadata.linked_parts.some(p => p.tmpl_view_section_part_id === null)).flat(1);

                        if(dependentSections){
                            dependentSections.forEach(s => {
                                this.togglePartDisplay(null, s);
                                //let sub = this.allSectionList.find(x => x.tmpl_view_section_id === s); 
                                //console.log(sub);
                                //sub.permissions_list.displayPermissionOverride = viewPermission;  
                            })
                        }
                    }*/

                    target.permissions_list.displayPermissionOverride = viewPermission;  

                    let newNavPath = this.allSectionList.filter(x => x.permissions_list.displayPermissionOverride === null || x.permissions_list.displayPermissionOverride === true).map(x => { 
                        if(x.parent_section){
                            return `${x.parent_section ?? ""}|${x.tmpl_view_section_id ?? ""}` 
                        } else {
                            return `${x.tmpl_view_section_id ?? ""}|` 
                        }
                    });

                    this.currentView.navigationPath = newNavPath;

                }
            })
        },
        async updatePart(obj, part, resp, item){
            let isChildPart = part.parent_dp_id && part.parent_dp_id !== null;
            let flatParts = viewUtils.genericFlatten(this.document.parttypes, "childPartTypes");
            let source = flatParts.find(x => x.tmpl_part_id === part.tmpl_part_id);
            if(resp){

                let action = resp.result.Data.newRow !== null ? 'INSERT':'UPDATE';
                if(action === 'INSERT'){
                    part.doc_part_id = Number(resp.result.Data.newRow.doc_part_id);
                }

                //update sectioned parts with update
                if(isChildPart){
                    let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_part_id === source.tmpl_part_metadata.parent_tp_id));
                    all.forEach(async a => {

                        let sectionSource = a.part_data.find(x => x.tmpl_part_id === source.tmpl_part_metadata.parent_tp_id);
                        let parentPart = sectionSource.parts.find(x => x.doc_part_id === part.parent_dp_id);
                        if(parentPart){
                            let parentCpt = parentPart.childPartTypes?.find(x => x.tmpl_part_id === part.tmpl_part_id);
                            let idx = parentCpt.parts.findIndex(x => x.doc_part_id === part.doc_part_id);

                            if(action === 'INSERT' && this.multiRowPart(sectionSource)){
                                part.insertOrder ? parentCpt.parts.splice(part.insertOrder, 0, part) : parentCpt.parts.push(part);
                                parentCpt = await this.changeOrder(parentCpt, false);
                            } else {
                                if(idx > -1){
                                    parentCpt.parts[idx] = part;
                                }
                            }
                            parentPart.childParts = parentCpt.parts;                            
                            parentPart.expanded = true;
                            sectionSource.parts.forEach((p) => {
                                utils.setDocPartStatus(
                                    p,
                                    sectionSource,
                                    this.data.wordChecks,
                                    this.data.docStatus,
                                    this.data.document, true
                                );
                            });

                            sectionSource.parts = sectionSource.parts.map(object => ({ ...object }));                            
                        }
                     });
                } else {
                    let all = this.allSectionList.filter(x => x.part_data.some(p => p.tmpl_part_id === part.tmpl_part_id));
                    all.forEach(async a => {

                        let sectionSource = a.part_data.find(x => x.tmpl_part_id === part.tmpl_part_id);
                        let idx = sectionSource.parts.findIndex(x => x.doc_part_id === part.doc_part_id);
                        if(action === 'INSERT' && this.multiRowPart(sectionSource)){
                            if(sectionSource.tmpl_part_metadata.linked_content.length > 0){
                                part.linked_content = sectionSource.tmpl_part_metadata.linked_content.map(object => ({ ...object }));
                            }
                            part.insertOrder ? sectionSource.parts.splice(part.insertOrder, 0, part) : sectionSource.parts.push(part);
                            sectionSource = await this.changeOrder(sectionSource, true);                
                        } else {
                            if(idx > -1){
                                sectionSource.parts[idx] = part;
                            }
                        }

                        sectionSource.parts.forEach((p) => {
                            utils.setDocPartStatus(
                                p,
                                sectionSource,
                                this.data.wordChecks,
                                this.data.docStatus,
                                this.data.document, true
                            );
                        });

                        sectionSource.parts = sectionSource.parts.map(object => ({ ...object }));
                    })
                }

                //update main doc part list with update
                if(isChildPart){
                    source = flatParts.find(x => x.childPartTypes?.some(c => c.tmpl_part_id === source.tmpl_part_id));
                    let parentPart = source.parts.find(x => x.doc_part_id === part.parent_dp_id);
                    if(parentPart){
                        let parentCpt = parentPart.childPartTypes?.find(x => x.tmpl_part_id === part.tmpl_part_id);
                        let idx = parentCpt.parts.findIndex(x => x.doc_part_id === part.doc_part_id);

                        if(action === 'INSERT' && this.multiRowPart(source)){
                            part.insertOrder ? parentCpt.parts.splice(part.insertOrder, 0, part) : parentCpt.parts.push(part);
                            parentCpt = await this.changeOrder(parentCpt, false);
                        } else {
                            if(idx > -1){
                                parentCpt.parts[idx] = part;
                            }
                        }
                        parentPart.childParts = parentCpt.parts;                  
                        parentPart.expanded = true;
                    }
                } else {
                    let idx = source.parts.findIndex(x => x.doc_part_id === part.doc_part_id);
                    if(action === 'INSERT' && this.multiRowPart(source)){
                        part.insertOrder ? source.parts.splice(part.insertOrder, 0, part) : source.parts.push(part);
                        source = await this.changeOrder(source, true);
                    } else {
                        if(idx > -1){
                            source.parts[idx] = part;
                        }
                    }
                }

                source.parts.forEach((p) => {
                    utils.setDocPartStatus(
                        p,
                        source,
                        this.data.wordChecks,
                        this.data.docStatus,
                        this.data.document, true
                    );
                });

                //do history
                if(resp.result.Data.audit){
                    let idx = this.partsHistory.findIndex(x => x.key === resp.result.Data.audit.key);
                    if (idx >= 0) {
                        this.partsHistory.splice(idx, 1);
                    }
                    this.partsHistory.push(resp.result.Data.audit);
                    if(this.history){
                        if(this.history.key === resp.result.Data.audit.key){
                            this.history = {
                                ...this.history,
                                ...resp.result.Data.audit
                            }
                        }
                    }
                }
                this.allHistory.push(resp);



            } //else {
            //    source = JSON.parse(JSON.stringify(source));        
            //}

            //this.resyncItem(item);
            
            if(item && item.tmpl_part_metadata.linked_parts?.length > 0){
                this.togglePartDisplay(part, item)
            }

            let target = this.activeSection.parent_section ? `${this.activeSection.parent_section ?? ""}|${this.activeSection.tmpl_view_section_id ?? ""}` : `${this.activeSection.tmpl_view_section_id ?? ""}|`;
            this.loadSectionContent(target);
            this.setCheckSummary();

        },
        saveStart(){
            this.saveInProgress = true;
        },
        saveEnd(){
            this.saveInProgress = false;

        },
        saveContentPart(){
            let isParent = this.editContentDialog.part.source;

            let partData = this.editContentDialog.part.part;

            let action = partData.tmpl_view_section_content_id && partData.tmpl_view_section_part_id ? 'UPDATE' : 'INSERT';

            if(action === 'INSERT'){
                let newObj = {
                    ...this.editContentDialog.part.item,
                    tmpl_part_metadata: {
                        ...this.editContentDialog.part.item.tmpl_part_metadata,
                        content_metadata: partData.content_metadata,
                        content_name: partData.content_name,
                        content_placeholder: partData.content_placeholder,
                        content_text: partData.content_text,
                        content_type: partData.content_type,
                        layout: partData.layout,
                        order: partData.order
                    }
                }


                if(isParent){
                    let parent = this.editPartDialog.data.find(x => x.tmpl_part_id === this.editContentDialog.part.source.tmpl_part_id);
                    newObj.tmpl_part_metadata.parent_id = this.editContentDialog.part.source.tmpl_view_section_part_id;
                    parent.tmpl_part_metadata.linked_content.push(newObj);
                } else {
                    this.editPartDialog.data.push(newObj);
                }
            } else {
                if(isParent){
                    let parent = this.editPartDialog.data.find(x => x.tmpl_part_id === this.editContentDialog.part.source.tmpl_part_id);
                    let source = parent.tmpl_part_metadata.linked_content.find(x => x.tmpl_view_section_part_id === partData.tmpl_view_section_part_id);
                    source.tmpl_part_metadata = {
                        ...source.tmpl_part_metadata,
                        ...partData
                    };
                    source.parts[0].attributes[0] = {
                        ...source.parts[0].attributes[0],
                        ...partData
                    };                    
                } else {
                    let source = this.editPartDialog.data.find(x => x.tmpl_view_section_part_id === partData.tmpl_view_section_part_id);
                    source.tmpl_part_metadata = {
                        ...source.tmpl_part_metadata,
                        ...partData
                    };
                    source.parts[0].attributes[0] = {
                        ...source.parts[0].attributes[0],
                        ...partData
                    };
                }
            }

            this.editContentDialog.show = false;  
        },
        updateModel(item){
            this.editContentDialog.part = item;
        },
        editContentPart(item, parent){

            item.parts[0].attributes[0] = {
                ...item.parts[0].attributes[0],
                layout: item.parts[0].attributes[0].layout.split('|') ?? []
            }

            this.editContentDialog.part = {
                source: parent ? parent : null,
                item: item,
                part: item.parts[0].attributes[0],
                edit_type: "part",
                tmpl_part_id: null
            };
            this.editContentDialog.show = true;
        },
        newContentPartObj(order){
            let metadata = {
                order: order ?? 1,
                active: true,
                content_text: null,
                content_placeholder: null,
                content_metadata: null,
                tmpl_view_section_part_id: 0,
                tmpl_view_section_id: this.editPartDialog.section_id,
                contentPart: true,
                readonly: false,
                layout: [],
                linked_content: [],
                part_type: "contentPart"
            };

            let attr= {
                active: true,
                content_text: null,
                content_placeholder: null,
                content_metadata: null,
                content_type: null,
                doc_view_content_id:0,
                layout:[],
                order: order ?? 1,
                parent_id: null,
                readonly: false,
                sequence:1,
                tmpl_part_id: null,
                tmpl_view_id: this.currentView.tmpl_view_id,
                tmpl_view_section_content_id:0,
                tmpl_view_section_id: this.editPartDialog.section_id,
                tmpl_view_section_part_id: 0,
                value: ""
            }

            let part = {
                attributes: [attr],
                linked_content: [],
                notes: "",
                sequence: 1,
                text: ""
            }

            let partItem = {
                childPartTypes: [],
                parts: [part],
                permissions_list: {
                    editPermissionOverride: null,
					viewPermissionOverride: null,					
					restrictSection: false,
					permissions: [],
					hierarchyPermissions: [],
					permissionControlled: false,
					hrPermissionControlled: false
                },
                positionRow: 1,
                tmpl_part_id: null,
                tmpl_part_metadata : metadata,
                tmpl_view_section_part_id: 0,
                type: "",
                visible: true,
                requireInit: true
            }

            return partItem;
        },
        addPartGroup(item){
            console.log(item);
        },
        editContentGroup(item){
            console.log(item);
            this.editPartDialog.editMode = 'group';
        },
        addContentPart(item){
            let position = item ? (item.tmpl_part_metadata.linked_content.length + 1) : (this.editPartDialog.data.length + 1);
            
            let newContentPart = this.newContentPartObj(position);

            this.editContentDialog.part = {
                source: item ? item : null,
                item: newContentPart,
                part: newContentPart.parts[0].attributes[0],
                edit_type: "part",
                tmpl_part_id: null
            };
            this.editContentDialog.show = true;
        },
        addPartToSection(item){
            let items = this.editPartDialog.data.map(x => x.order);
            let order = items.length > 0 ? Math.max.apply(null, items) : 0;
            let flatParts = viewUtils.genericFlatten(this.document.parttypes,"childPartTypes");
            let sourcePart = flatParts.find((p) => p.tmpl_part_id === item.tp_id);

            sourcePart.tmpl_part_metadata = {
                ...sourcePart.tmpl_part_metadata,               
                order: order,
                linked_content: [],
                active: true,
                part_type: "docPart"
            }

            sourcePart = {
                ...sourcePart,    
                permissions_list: {
                    editPermissionOverride: null,
					viewPermissionOverride: null,	
                    displayPermissionOverride: null,				
					restrictSection: false,
					permissions: [],
					hierarchyPermissions: [],
					permissionControlled: false,
					hrPermissionControlled: false,
                    view_dependencies: []
                },   
                order: order+1,
                active: true,
                requireInit: true,
                tmpl_view_section_part_id: 0,
                tmpl_view_section_id: this.editPartDialog.section_id,
            }
            this.editPartDialog.data.push(sourcePart);
            let avail = this.editPartDialog.available.find(x => x.tp_id === item.tp_id);
            avail.exists = true;
        }, 
        async saveSection(id){            
            this.saveStart();
            let flattened = this.getFlatSections();
            let targetSection = flattened.find(x => x.tmpl_view_section_id === id);
            await viewUtils.docViewSaveSection(targetSection).then(() => {
                this.triggerNotification("Section Updated Succesfully","success");
                this.saveEnd();
            })            
        },
        async saveSectionParts(){       
            this.saveStart();
            let flattened = this.getFlatSections();
            let targetSection = flattened.find(x => x.tmpl_view_section_id === this.editPartDialog.section_id);
            targetSection.part_data = this.editPartDialog.data;
            this.editPartDialog.show = false;
            let target = targetSection.parent_section ? `${targetSection.parent_section ?? ""}|${targetSection.tmpl_view_section_id ?? ""}` : `${targetSection.tmpl_view_section_id ?? ""}|`;
            await viewUtils.docViewSaveSectionParts(targetSection.part_data).then((resp) => {
                targetSection.part_data =resp.data;
                this.loadSectionContent(target);
                this.triggerNotification("Section Parts Updated Succesfully","success");
                this.saveEnd();
            })
        },
        removePartFromSection(item){
            console.log(item);
        },
        onChange() {
            this.reorder();
        },
        reorder() {
            this.editPartDialog.data.forEach((item, index) => (item.tmpl_part_metadata.order = index+1))
        },      
        removeRelatedAt(subIdx,idx){
            let child = this.editPartDialog.data[idx].tmpl_part_metadata.linked_content[subIdx];
            child.toDelete = true;
            this.editPartDialog.data = JSON.parse(JSON.stringify(this.editPartDialog.data));
        },  
        removeAt(idx) {
            let item = this.editPartDialog.data[idx];
            item.toDelete = true;
            if(item.tmpl_part_metadata.linked_content.length > 0){
                item.tmpl_part_metadata.linked_content.forEach(s => {
                    s.toDelete = true;
                })
            }
        },    
        getFlatSections(){
            const getFlat = (data) => data.map(d => [d, ...getFlat(d.sub_sections)]).flat();
            let flattened = getFlat(this.currentView.sections);
            return flattened.filter(x => x.permissions_list.displayPermissionOverride === null || x.permissions_list.displayPermissionOverride === true);
        },
        loadPartsForSection(id){
            let flattened = this.getFlatSections();
            let targetSection = flattened.find(x => x.tmpl_view_section_id === id);
            this.editPartDialog.available = this.templateDefinition.parts.filter(x => x.tp_active && x.parent_tp_id === null).map(p => {
                let match = targetSection.part_data.find(sp => sp.tmpl_part_id === p.tp_id);
                return {
                    ...p,
                    ...match,
                    exists: match ? true : false 
                }
            });

            let parts = targetSection.part_data.map(p => {
                let match = this.templateDefinition.parts.find(sp => sp.tp_id === p.tmpl_part_id);
                return {
                    ...p,
                    data: match ?? {
                        tp_name: p.content_name
                    },
                    toDelete: false
                }                
            })
            this.editPartDialog.section_id = id;
            this.editPartDialog.data = parts;
            this.reorder();
            this.editPartDialog.show = true;
        },
        getSectionFromID(id){
            let currentSection = id.split("|");
            let targetSection = this.currentView.sections.find(x => x.tmpl_view_section_id === Number(currentSection[0]));
            if(currentSection[1]){
                targetSection = targetSection.sub_sections.find(x => x.tmpl_view_section_id ===  Number(currentSection[1]));
            }

            return targetSection;
        },
        async saveSectionContent(saveReq){
            await viewUtils.auditSectionChange(saveReq).then((resp) => {
                console.log(resp);
            })
        },
        async navSections(dir){
            let current = `${this.currentViewSectionId ?? ""}|${this.currentViewSubSectionId === 0 ? "" : this.currentViewSubSectionId}`;
            let nav = this.currentView.navigationPath.indexOf(current);
            let nextIdx = 0;
            switch(dir){
                case "Next":
                    nextIdx = nav+1;
                    break;
                case "Complete":
                    nextIdx = nav+1;
                    break;
                case "Back":
                    nextIdx = nav-1;
                    break;
                case "CompleteAll": {
                        let allSecs= [];
                        let currentSection = this.allSectionList.find(x => x.tmpl_view_section_id === this.currentViewSectionId);
                        let currentIdx = this.allSectionList.indexOf(currentSection);
                        let target = this.allSectionList.find(x => x.tmpl_view_section_id === currentSection.complete_all_target_section_id);
                        let targetIdx = this.allSectionList.indexOf(target);
                        for(let i = currentIdx; i < targetIdx; i++){
                            let sec = this.allSectionList[i];
                            if(!sec.complete){
                                sec.complete = true;
                                allSecs.push({
                                    doc_id: this.data.document.doc_id,
                                    tmpl_view_section_id: sec.tmpl_view_section_id,
                                    audit_data: {
                                        complete: sec.complete
                                    }
                                })   
                            }                     
                            nextIdx = i;
                        }
                        
                        this.saveStart();
                        await Promise.all(
                            allSecs.map(async (update) => {
                                await this.saveSectionContent(update);
                            })
                            ).then((resps) => {
                                this.saveEnd(resps);
                            });

                        nextIdx = nextIdx+1;
                    }
                    break;
            }

            if(nav === 0){
                let targetSection = this.getSectionFromID(current);
                if(!targetSection.complete){
                    targetSection.complete = true;
                    
                    this.saveStart();
                    await this.saveSectionContent({
                        doc_id: this.data.document.doc_id,
                        tmpl_view_section_id: targetSection.tmpl_view_section_id,
                        audit_data: {
                            complete: targetSection.complete
                        }
                    }).then((resp) => {
                        this.saveEnd(resp);
                    });
                }
            }

            if(dir === "Complete"){                
                let targetSection = this.getSectionFromID(current);
                if(!targetSection.complete){
                    targetSection.complete = true;
                    
                    this.saveStart();
                    await this.saveSectionContent({
                        doc_id: this.data.document.doc_id,
                        tmpl_view_section_id: targetSection.tmpl_view_section_id,
                        audit_data: {
                            complete: targetSection.complete
                        }
                    }).then((resp) => {
                        this.saveEnd(resp);
                    });
                }
            }
            
            let next = this.currentView.navigationPath[nextIdx];
            let parts = next.split("|");
            this.$refs["viewDisplay"].activateSection(parts[0], parts[1]);

        },
        addSubSection(parentId){
            this.$emit("addSubSection", parentId);
        },
        changePanel(val){
            this.rightPanel = val;
        },
        editPart(item,part){
            if(this.admin){

                this.$nextTick(() => {
                    this.editingSection = {
                        item: {...item},
                        part: {...part},
                        edit_type: "part"
                    };
                    this.editingSection = JSON.parse(JSON.stringify(this.editingSection));                    
                    this.rightPanel = "permissions";                 
                    this.rightPanelHeader = `Editing Part: ${item.type}`;
                });
            }
        },
        editPartPermission(item,part){
            if(this.admin){
                this.rightPanel = "permissions";
                this.$nextTick(() => {
                    this.editingSection = {
                        item: {...item},
                        part: {...part},
                        tmpl_id: this.document.doc_view_config.tmpl_id,
                        edit_type: "partPermissions",
                        sectionId: this.currentViewSubSectionId > 0 ? this.currentViewSubSectionId : this.currentViewSectionId
                    };                 
                });
            }
        },
        addSection(){
            this.$emit("addSection");
        },
        editSectionOrder(){
            this.$emit("editSectionOrder");
        },
        editSectionDefinition(target){
            if(this.admin){
                this.$emit("editSection", target);
                let targetSection = this.getSectionFromID(target);
                this.rightPanelHeader = `Editing Section: ${targetSection.section}`;
                this.$nextTick(() => {
                    this.editingSection = {
                        item: targetSection,
                        edit_type: "section",
                        tmpl_id: this.document.doc_view_config.tmpl_id
                    };
                    this.rightPanel = "permissions";
                });

            }
        },
        editPermissions(target){
            if(this.admin){

                this.rightPanel = "permissions";
                this.$nextTick(() => {
                    let targetSection = this.getSectionFromID(target);
                    this.editingSection = {
                        item: targetSection,
                        edit_type: "sectionPermissions",
                        tmpl_id: this.document.doc_view_config.tmpl_id
                    };                 
                });
            }            
        },
        init(){
            if(this.data && this.data?.document){
                this.document = this.data.document ?? null;
                this.docStatus = this.data.docStatus ?? null;
                this.docHierarchies = this.data.docHierarchies ?? null;
                this.partsHistory = this.data.partsHistory ?? null;
                this.wordChecks = this.data.wordChecks ?? null;
                this.templateDefinition = this.data.template ?? null;
                this.currentView = this.data.document.doc_view_config;
                this.allSectionList = viewUtils.getFlatSections(this.currentView.sections);
                this.loadSectionContent(this.currentView.navigationPath[0]);
                this.initCheckSummary();
                this.collaboration = this.data.document.state.show_collaboration && this.data.document.state.canEditDocParts;
                this.isLoading = false;
                this.toggleRHS(this.rightPanel);
                this.loadSettings();
                if(!this.showScoring && !this.data.document.state.canEditDocParts){
                    this.toggleRHS('hidden');
                }
                if(this.data.sourceFile){
                    this.toggleRHS('database');
                }
            }
        },
        loadSettings(){
            let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);
            this.userSpellCheckEnabled = languageOpts.spellCheck.enabled;
        },
        flattenArray(){
            const getFlat = (data) => data.map(d => [d, ...getFlat(d.sub_sections)]).flat()
            let flattened = getFlat(this.currentView.sections);
            let filteredList = flattened.filter(x => x.view_mode === 'DATACAPTURE' || x.part_data.length > 0 && x.tmpl_view_section_id !== this.activeSection.tmpl_view_section_id);
            let processedList = filteredList.map(p => {
                return {
                    ...p,
                    part_data: this.processPartList(p.part_data)
                }
            })
            this.$nextTick(() => {
                this.currentReviewSection = processedList.map(object => ({ ...object }));
            })
        },
        loadSectionDetails(targetSection){
            this.bannerText = targetSection.header_type ?? "";
            this.footerText = targetSection.footer_type ?? ""; 
            
            this.displayHeader = this.bannerText !== "";
            
            let current = `${this.currentViewSectionId ?? ""}|${this.currentViewSubSectionId === 0 ? "" : this.currentViewSubSectionId}`;
            this.navBackDisabled = false;
            this.navNextDisabled = false;
            this.navChangesHide = false;

            let navIdx = this.currentView.navigationPath.findIndex(x => x === current);

            this.navBackDisabled = false;
            this.navNextDisabled = false;
            this.navBackHide = false;
            this.navNextHide = false;
            this.navChangesHide = false;

            if(!this.admin){
                this.show_lhs_expand = targetSection.lhs_expandable === 1;
                this.show_rhs_expand = targetSection.rhs_expandable === 1;

                this.leftPanel = targetSection.lhs_expanded === 0 ? 'gutter' : 'sections';
                this.rightPanel = targetSection.rhs_expanded === 0 ? 'hidden' : 'score';
            }

            this.displayLHS = true;
            this.displayRHS = true;

            if(navIdx >= this.currentView.navigationPath.length-1){
                navIdx = this.currentView.navigationPath.length-1;
            }
            if(navIdx === this.currentView.navigationPath.length-1){
                this.navNextDisabled = true;
                this.navNextHide = true;
                this.navChangesHide = true;                
            }
            if(navIdx === 0){
                this.navBackDisabled = true;
                this.navBackHide = true;
            }
            
            if(targetSection.sub_sections.length > 0){
                this.navChangesHide = true;
            }

            this.sectionCompleteSub = targetSection.enable_complete_subsections;
            this.sectionCompleteLabel = targetSection.complete_all_label;

            this.displayLinkedContent = targetSection.sub_sections.length > 0 ? targetSection.view_linked_content : true;

            if(this.activeSection.view_mode === 'REVIEW'){
                this.flattenArray();
                if(this.activeSection.submit_action_id && this.activeSection.submit_action_id > 0){
                    this.submitAction = this.data.document.state.actionsAvailable.find(x => x.lifecycle_action_id === this.activeSection.submit_action_id);
                }
            }
        },
        applyPartUpdate(data){
            let idx = this.sectionParts.findIndex(x => x.tmpl_view_section_part_id === data.item.tmpl_view_section_part_id);
            if(idx > -1){
                this.sectionParts[idx] = data.part;
                this.updateList();
            }
        },
        cancelEditUpdate(data){
            if(data.edit_type === 'section'){
                if(data.item.tmpl_view_section_id === (data.item.parent_section ? this.currentViewSubSectionId : this.currentViewSectionId)){
                    this.loadSectionDetails(data);
                }
            } else if (data.edit_type === 'part'){
                this.applyPartUpdate(data);
            }
            this.editingSection = null;
            this.rightPanel = 'score';
        },
        applySectionUpdate(data){
            if(data.edit_type === 'section'){
                if(data.item.tmpl_view_section_id === (data.item.parent_section ? this.currentViewSubSectionId : this.currentViewSectionId)){
                    let target = data.item.parent_section ? `${data.item.parent_section ?? ""}|${data.item.tmpl_view_section_id ?? ""}` : `${data.item.tmpl_view_section_id ?? ""}|`;
                    this.loadSectionContent(target)
                    
                }
            } else if (data.edit_type === 'part'){
                this.applyPartUpdate(data);
            }
        },
        processPartList(partList){
            return partList.map(object => ({ ...object }));       
        },
        loadSectionContent(section){
            if(!section) return;
            var sections = section.split("|").filter(x => x !== '' && x !== null);
            if(sections.length > 0){
                let targetSection = this.currentView.sections.find(x => x.tmpl_view_section_id === Number(sections[0]));
                let newPartList = [];
                this.currentViewSubSectionId = 0;
                this.currentViewSectionId = Number(sections[0]);
                this.sectionParts = JSON.parse(JSON.stringify(newPartList));  
                if(sections[1]){
                    targetSection = targetSection.sub_sections.find(x => x.tmpl_view_section_id ===  Number(sections[1]));
                    this.currentViewSubSectionId = Number(sections[1]);
                    newPartList = targetSection.part_data;
                } else {
                    let viewLinked = targetSection.view_linked_content;
                    let sectionList = [
                        targetSection.part_data,
                        targetSection.sub_sections.filter(x => x.permissions_list.displayPermissionOverride === null || x.permissions_list.displayPermissionOverride === true).map(x => {
                            return viewLinked ? x.part_data : x.part_data.filter(x => x.tmpl_part_id);
                        }).flat(1)
                    ].flat(1);

                    let idx = 1;
                    sectionList.forEach(x => {
                        newPartList.push({
                            ...x,
                            order: idx
                        });
                        idx++;
                    })
                }

                if(this.activeSection === null || this.activeSection.tmpl_view_section_id !== targetSection.tmpl_view_section_id){
                    this.activeSection = targetSection;
                    this.loadSectionDetails(targetSection);

                    this.toggleRHS(this.rightPanel);
                    if(this.admin && this.rightPanel === 'permissions'){
                        this.editSectionDefinition(section);
                    }                    
                }


                this.sectionParts = null;
                this.sectionParts = JSON.parse(JSON.stringify(this.processPartList(newPartList)));
                this.updateList();
                
            }
        },
        getWordChecksForPart(tpid, checks, hchys) {
            let tempChecks = checks.map(object => ({ ...object }))
            let filtered = tempChecks.filter((w) =>
                w.tmpl_part_types.some(
                (tp) =>
                    tp.tp_id === tpid &&
                    (!tp.exclusions.length ||
                    !hchys.some((h) =>
                        tp.exclusions.some((e) => e.hr_id === h.hr_id)
                    ))
                )
            );
            return utils.initialisePartChecks(filtered);
        },            
        updateList(){
            this.$nextTick(() => {
                this.sectionParts = this.sectionParts.map(object => ({ ...object }));
            })    
        }
    }
}
</script>

<style scoped lang="scss">

.dragHandle {
    font-size: 12px !important; 
    display:inline-flex; 
    padding-right:10px;
    cursor:move;
    top: 3px;
    align-items: flex-start !important;        
}

.dataTableView:nth-child(even) {
    background: #f5f5f5;
}

.dataTableView:nth-child(odd) {
    background: #FFF
}

.docBanner {
    text-align: center;
    width: 100%;
    height: 36px;
    color: #FFFFFF;
    background: #54616C;
    border-radius: 4px;
    margin-top: 5px;
    padding-top: 8px;
    padding-right:50px;
}

.docViewHolder {
        
    $actionHeaderHeight: 30px;
    $bannerTopOffset: calc(#{var(--header-height)} +  #{var(--doc-banner-height)} + #{$actionHeaderHeight});
    $headerHeight: var(--header-height);
    $mainPanelHeight: calc(100vh - #{var(--container-height)} - #{var(--container-top-offset)});
    $childPanelHeight: calc(100vh - #{var(--header-height)} - #{var(--container-top-offset)});
    $docContentHeight: calc(100vh - #{var(--header-height)} - #{var(--doc-banner-height)} - #{var(--doc-footer-height)} - #{var(--container-top-offset)} - #{$actionHeaderHeight});

    width:100vw;
    height: $mainPanelHeight;
    max-height: $mainPanelHeight;
    overflow: hidden scroll;
        /* padding-top: var(--display-mode-topPadding); */
    position: relative;
    top: var(--display-mode-topPadding);

    $scrollbarOffset: 10px;

    .docSectionHolderLeft {
        width: var(--left-panel-width);
        height: $childPanelHeight;
        max-height: $childPanelHeight;
        overflow-y: hidden; 
        position: absolute;

        .docSectionHolderLeftActions{
            width: 30px;
            display:inline;
            float:right;
            top: 10px;
            position: relative;
        }
    }

    .docHolder {
        height: $childPanelHeight;
        max-height: $childPanelHeight;
        width: calc(100vw - #{var(--left-panel-width)} - #{var(--right-panel-width)} - #{$scrollbarOffset});

        @media only screen and (max-width: 1264px) {
            width: calc(100vw - #{var(--left-panel-width)} - #{$scrollbarOffset});
        }

        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%); 
        padding: 0px 0px 0px 5px;
        left: var(--left-panel-width); 
        right: var(--right-panel-width);   
        position: absolute;

        $contentWidth: calc(100vw - #{var(--left-panel-width)} - #{var(--right-panel-width)} - #{$scrollbarOffset} - 5px);


        .docHolderActionsHeader{
            position: fixed;
            float:right;
            height: $actionHeaderHeight;
            width: $contentWidth;
            z-index: 1;     
            overflow: hidden;   
            padding-right: 15px;
        }

        .docHolderTop{
            position: fixed;
            height: var(--doc-banner-height);
            width: $contentWidth;
            z-index: 1;     
            overflow: hidden;   
            padding-right: 15px;
            top: $bannerTopOffset;
        }
        .docHolderCenter{
            width: $contentWidth;
            @media only screen and (max-width: 1264px) {
                width: calc(100vw - #{var(--left-panel-width)} - #{$scrollbarOffset} - 25px);
            }
            overflow-y: scroll;   
            overflow-x: hidden;       
            height: $docContentHeight;
            max-height: $docContentHeight; 
            position: relative;
            top: calc(#{var(--doc-banner-height)} + #{$actionHeaderHeight});
        }
        .docHolderFooter{
            width: $contentWidth;
            overflow-y: scroll;      
            height: var(--doc-footer-height);
            max-height: var(--doc-footer-height); 
            position: fixed;
            bottom: 0px;
            overflow: hidden;
            padding-right: 15px;
            padding-top: 5px;
            display: inline-flex;
        }

    }

    .scorecard{
        width:100%;
        font-size: 14px;

        .scoretitle{
            display:inline-flex;
            padding-top: 10px;
            flex-direction: column;
            height: 60px;
            border-bottom: 1px solid #e3e3e7;
        }

        .inclusionHeading {
            padding-left: 5px;
            word-break: break-word;
        }
    }

    .docSectionHolderRight{
        width: var(--right-panel-width);
        height: $childPanelHeight;
        max-height: $childPanelHeight;
        right: var(--right-panel-width);        
        float: right;
        padding: 5px 0px 15px 0px;
        @media only screen and (max-width: 1264px) {
            display: none;
        }

        .rightHolderNavActions{
            width: 30px;
            display: inline-flex;
            float: left;
            top: 18px;
            position: relative;
            height: 0px;
            left: 5px;
        }

        .rightHolderNav{
            width: 100%;
            max-width:100%;
            display:flex;
            justify-content: center;
            padding-bottom: 5px;
            border-bottom: 1px solid #e3e3e7;
        }

        .rightHolderContent{
            height: calc(100vh - 158px);
            width:var(--right-panel-width);
            padding: 5px 2px 3px 3px;
            top: 0px;
            position: relative;

            .rightHolderContentLabel{
                position:fixed;
                height:30px;
                width:var(--right-panel-width);
                text-align:center;
                padding-top: 5px;
            }

            .rightHolderContentMain{
                width:var(--right-panel-width);
                height: calc(100vh - 150px - #{var(--container-height)}); 
                top: 32px; 
                position: relative;
                padding-right: 3px;
            }
        }


    }
}

table {
    border:1px solid #ccc;
}
table td {
    padding:15px;
    border:1px solid #3a3838;
}
table td.selected {
    background-color:#aea8ff;
}

</style>