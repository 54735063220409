<template>
  <div class="pt-2">
    <v-container fluid v-if="!groupDetails">
      <v-row>
        <v-col>
          <h1 class="title">Manage Review Groups</h1>
        </v-col>
      </v-row>
      <div v-if="!isLoading && templates.length === 0" class="d-flex justify-center">
        <v-alert type="warning" color="orange" icon="warning" max-width="500">
          Review Group Management disabled on all workflows!
        </v-alert>
      </div>
      <v-card class="mainPanel mt-6">
        <v-card-title class="pb-0">
          <v-row>
            <v-col cols="12" md="4" lg="3">
              <v-autocomplete
                v-model="filterUsers"
                :items="userGroupMap"
                item-text="name"
                item-value="user_id"
                multiple
                small-chips
                label="Users"
                outlined
                dense
                hide-details
                prepend-inner-icon="person"
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-autocomplete
                v-model="filterDocs"
                :items="docGroupMap"
                item-text="doc_name"
                item-value="doc_id"
                multiple
                small-chips
                label="Docs"
                outlined
                dense
                hide-details
                prepend-inner-icon="article"
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-text-field
                v-model="filterText"
                label="Type text to filter"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
                clearable>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="groupHeaders"
            :items="filteredGroups"
            :items-per-page="groupsItemsPerPage"
            sort-by="name"
            :class="['mt-2']"
            :page.sync="groupsCurrentPage"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="(col, coli) in groupHeaders" :key="'col' + coli" @click="loadGroupDetails(item, col.value)">
                  <div v-if="col.value === 'created_date'">
                    {{ moment(item.created_date).format("DD MMM YYYY") }}
                  </div>
                  <div v-else-if="col.value === 'users'">
                    <v-chip small class="pt-1">{{userGroupMap.filter(m => m.review_group_id === item.review_group_id).length}}</v-chip>
                  </div>
                  <div v-else-if="col.value === 'documents'">
                    <v-chip small class="pt-1">{{docGroupMap.filter(m => m.review_group_id === item.review_group_id).length}}</v-chip>
                  </div>
                  <div v-else>
                    <span v-text="item[col.value]"></span>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="px-2 mt-4 d-flex justify-space-between">
            <PageDescription
              :totalItems="filteredGroups.length"
              :pageSize="groupsItemsPerPage"
              :currentPage="groupsCurrentPage"
            />
            <Pagination
              :totalItems="filteredGroups.length"
              :pageSize="groupsItemsPerPage"
              :currentPage="groupsCurrentPage"
              @pageNavigation="groupsCurrentPage = $event"
            />
            <div>
              <v-btn class="primary" :disabled="templates.length === 0" @click="addNewGroup">New Group</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <template v-else>
      <v-row class="px-3">
        <v-col cols="4" class="pt-6">
          <v-btn
            @click="groupDetails = null"
            color="primary"
            outlined
            ><v-icon left>arrow_back</v-icon>Back</v-btn
          >
        </v-col>
        <v-col cols="4">
          <v-tabs v-model="tab" background-color="transparent" centered>
            <v-tab>Group Details</v-tab>
            <v-tab>Job Assignment</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="4" class="text-right pt-6">
          <v-btn color="primary" outlined @click="showNotificationDialogue"><v-icon left>email</v-icon>Share</v-btn>
          <v-btn color="primary" class="ml-2" outlined @click="showActionDialogue"><v-icon left>play_arrow</v-icon>Perform Action</v-btn>
          <v-btn color="error" outlined class="ml-2" @click="deleteGroup" v-if="groupDetails.review_group_id">Delete</v-btn>
          <v-btn color="primary" class="ml-2" @click="saveGroup">Save</v-btn>
        </v-col>
      </v-row>
      <v-tabs-items class="mt-3" v-model="tab">
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="4">
                <v-card class="mainPanel pa-4">
                  <v-row>
                    <v-col>
                      <h2 class="ml-4">All Users</h2>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Filter by Name"
                        v-model="userFilter"
                        outlined
                        dense
                        style="width: 300px"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-simple-table dense style="width: 100%">
                    <tbody>
                      <tr v-for="u in pageOfFilteredUsers" :key="u.user_id">
                        <td>
                          <v-chip outlined label color="primary" small>{{
                            u.name
                          }}</v-chip>
                        </td>
                        <td class="text-right">
                          <v-btn
                            icon
                            title="Add To Group"
                            @click="addUserToGroup(u)"
                          >
                            <v-icon>add_circle</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <div class="text-center">
                    <Pagination
                      :totalItems="filteredUsers.length"
                      :pageSize="itemsPerPage"
                      :currentPage="usersCurrentPage"
                      @pageNavigation="usersCurrentPage = $event"
                    />
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mainPanel pa-4">
                  <v-card-text>
                    <v-form ref="form">
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="groupDetails.name"
                            label="Name"
                            outlined
                            dense
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col>
                          <v-select
                            label="Document Type"
                            v-if="templates.length > 1"
                            v-model="groupDetails.tmpl_id"
                            :items="templates"
                            item-text="tmpl_name"
                            item-value="tmpl_id"
                            outlined
                            dense
                            :rules="[rules.required]"
                            :disabled="groupDocs.length !== 0"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            <h2 class="ml-4 mb-4">Assigned Users / Roles</h2>
                          </v-col>
                          <v-col class="d-flex justify-end">
                            <v-switch v-model="showInactiveUsers"
                              class="mb-4 mt-0"
                              hide-details
                              label="Show Inactive">
                            </v-switch>
                          </v-col>
                        </v-row>
                        <v-simple-table dense style="width: 100%">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th v-for="l in workflowRoles" :key="'th_' + l">
                                <v-chip small>{{ l }}</v-chip>
                                <v-btn icon @click="toggleRoleFilter(l)"><v-icon>{{ userRoleFilter === l ? 'filter_alt_off' : 'filter_alt' }}</v-icon></v-btn>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="u in groupUsers"
                              :key="u.user_id"
                            >
                              <td>
                                <v-chip outlined label color="primary" small>{{
                                  u.name
                                }}</v-chip>
                                <v-tooltip bottom v-if="!u.active">
                                  <template v-slot:activator="{ on: tooltip }">
                                    <v-icon class="ml-2" v-on="{ ...tooltip }">warning</v-icon>
                                  </template>
                                  User Inactive
                                </v-tooltip>
                                <v-icon></v-icon>
                              </td>
                              <td
                                v-for="l in workflowRoles"
                                :key="`td_${u.user_id}_${l}`"
                              >
                                <template v-if="u.availableRoles.includes(l)">
                                  <v-btn
                                    icon
                                    title="Disable"
                                    v-if="u.roles.includes(l)"
                                    @click="toggleUserRole(u, l)"
                                  >
                                    <v-icon>check_circle_outline</v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-else
                                    icon
                                    title="Enable"
                                    style="opacity: 0.2"
                                    @click="toggleUserRole(u, l)"
                                  >
                                    <v-icon>add_circle_outline</v-icon>
                                  </v-btn>
                                </template>
                              </td>
                              <td class="text-right">
                                <v-btn
                                  icon
                                  title="Remove From Group"
                                  @click="removeUserFromGroup(u)"
                                >
                                  <v-icon>remove_circle</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end pr-4">
                    <v-btn color="primary" outlined @click="addNewUser"
                      >Add New User</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <v-card class="mainPanel">
                  <v-card-text>
                    <h2 class="ml-1 mb-4">
                      <v-btn @click="showHierarchies = true" icon
                        ><v-icon>filter_alt</v-icon></v-btn
                      >
                      All Jobs
                    </h2>
                    <v-row>
                      <v-col class="d-flex align-center">
                        <v-select
                          v-model="docStatusIncluded"
                          :items="docStatusSummary"
                          item-text="status"
                          item-value="status"
                          return-object
                          label="Job Statuses"
                          multiple
                          :menu-props="{
                            closeOnContentClick: false,
                            maxHeight: '400',
                            'offset-y': true,
                          }"
                          dense
                          hide-details
                          outlined
                        >
                          <template v-slot:selection="{ item, index }">
                            {{ getStatusFilterText(item, index) }}
                          </template>
                        </v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Search Jobs"
                          outlined
                          dense
                          hide-details
                          v-model="docSearchTextTmp"
                          clearable
                          append-icon="search"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" lg="3" xl="2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-switch
                                v-model="excludeGrouped"
                                label="Exclude grouped"
                                >
                              </v-switch>
                            </div>
                          </template>
                          Exclude documents in other review groups
                        </v-tooltip>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-end">
                        <v-menu offset-y :close-on-content-click="false">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              title="Customise Table"
                              large
                              v-on="on"
                              icon
                              class="btn-background ml-3"
                            >
                              <v-icon>mdi-cog</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense class="pa-3" style="max-height: calc(100vh - 250px); overflow: auto;">
                            <v-list-item>
                              <v-list-item-title class="subtitle-1"
                                >Customise Table</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item
                              v-for="field in docListColumns.filter(
                                (c) => c.showInListView
                              )"
                              :key="field.value"
                            >
                              <v-switch
                                class="v-input--reverse v-input--expand"
                                style="width: 200px"
                                dense
                                v-model="allDocsColumnsEnabled"
                                :label="field.text"
                                :title="field.text"
                                :value="field.value"
                              ></v-switch>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <DocumentDataTable
                      :headers="allDocsColumns"
                      :items="allDocsFiltered"
                      :page.sync="allDocsCurrentPage"
                      :items-per-page="itemsPerPage"
                      :sortColumn="sortColumn"
                      density="normal"
                      @openDocument="openDocument"
                      @addDocument="addDocumentToGroup"
                    >
                    </DocumentDataTable>
                    <div class="mt-4 d-flex justify-space-between align-center">
                      <PageDescription
                        :totalItems="allDocsFiltered.length"
                        :pageSize="itemsPerPage"
                        :currentPage="allDocsCurrentPage"
                      />
                      <Pagination
                        :totalItems="allDocsFiltered.length"
                        :pageSize="itemsPerPage"
                        :currentPage="allDocsCurrentPage"
                        @pageNavigation="allDocsCurrentPage = $event"
                      />
                      <div>
                        <v-btn
                          @click="addAllDocumentsToGroup"
                          color="primary"
                          outlined
                          >Add All</v-btn
                        >
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card class="mainPanel">
                  <v-card-text>
                    <v-row>
                      <v-col><h2 class="ml-1 mb-4">Assigned Jobs</h2></v-col>
                      <v-col cols="1" class="d-flex justify-end">
                        <v-menu offset-y :close-on-content-click="false">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              title="Customise Table"
                              large
                              v-on="on"
                              icon
                              class="btn-background ml-3"
                            >
                              <v-icon>mdi-cog</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense class="pa-3" style="max-height: calc(100vh - 250px); overflow: auto;">
                            <v-list-item>
                              <v-list-item-title class="subtitle-1"
                                >Customise Table</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item
                              v-for="field in docListColumns.filter(
                                (c) => c.showInListView
                              )"
                              :key="field.value"
                            >
                              <v-switch
                                class="v-input--reverse v-input--expand"
                                style="width: 200px"
                                dense
                                v-model="groupDocsColumnsEnabled"
                                :label="field.text"
                                :title="field.text"
                                :value="field.value"
                              ></v-switch>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <DocumentDataTable
                      :headers="groupDocsColumns"
                      :items="groupDocs"
                      :page.sync="groupDocsCurrentPage"
                      :items-per-page="itemsPerPage"
                      density="normal"
                      @openDocument="openDocument"
                      @removeDocument="removeDocumentFromGroup"
                    >
                    </DocumentDataTable>
                    <div class="mt-4 d-flex justify-space-between align-center">
                      <PageDescription
                        :totalItems="groupDocs.length"
                        :pageSize="itemsPerPage"
                        :currentPage="groupDocsCurrentPage"
                      />
                      <Pagination
                        :totalItems="groupDocs.length"
                        :pageSize="itemsPerPage"
                        :currentPage="groupDocsCurrentPage"
                        @pageNavigation="groupDocsCurrentPage = $event"
                      />
                      <div>
                        <v-btn
                          @click="removeAllDocumentsToGroup"
                          color="primary"
                          outlined
                          >Remove All</v-btn
                        >
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-dialog v-model="notificationDialogue.show" width="400">
      <v-card>
        <v-card-title class="text-h5">
          Notify Group
          <v-spacer></v-spacer>
          <v-btn
            large
            icon
            class="btn-background ml-2"
            @click="notificationDialogue.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="ml-6">
            <v-checkbox
              v-for="r in workflowRoles" :key="'wf' + r"
              v-model="notificationDialogue.roles"
              :label="r + 's'"
              :value="r"
            ></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="sendNotifications">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="actionDialogue.show" width="1200">
      <v-card>
        <v-card-title class="text-h5">
          Perform Action
          <v-spacer></v-spacer>
          <v-select
            label="Action"
            v-model="actionDialogue.bulkActionToRun"
            :items="actionDialogue.bulkActions"
            item-value="action_ids"
            item-text="name"
            outlined
            dense
            hide-details
            class="mr-4"
            style="max-width: 300px;">
          </v-select>
          <v-btn
            large
            icon
            class="btn-background ml-2"
            @click="actionDialogue.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="actionDialogue.loading" class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="primary"
              size="50"
            ></v-progress-circular>
          </div>
          <div v-else-if="actionDialogue.processing">
            <v-progress-linear
              color="primary"
              height="25"
              :value="100 * actionDialogue.processingIndex / actionDialogue.selectedDocsForAction.length"
            >
              <strong>Processing {{ actionDialogue.processingIndex + 1 }} of {{ actionDialogue.selectedDocsForAction.length }}</strong>
            </v-progress-linear>
          </div>
          <DocumentDataTable
            v-else
            :headers="actionDocColumns"
            :items="actionDialogue.docsForAction"
            :page.sync="actionDocsCurrentPage"
            :items-per-page="itemsPerPage"
            :selected-docs="actionDialogue.selectedDocsForAction"
            density="normal"
            @toggleDocument="toggleSelectActionDoc"
            @openDocument="openDocument"
            @removeDocument="removeDocumentFromGroup"
          >
          </DocumentDataTable>
        </v-card-text>
        <v-card-actions class="pb-8 px-8">
          <v-row v-if="actionDialogue.processing" class="d-flex justify-end">
            <v-btn color="error" outlined @click="actionDialogue.processing = false; actionDialogue.loading = true">Cancel</v-btn>
          </v-row>
          <v-row v-else-if="!actionDialogue.loading" class="d-flex justify-space-between align-center">
            <PageDescription
              :totalItems="actionDialogue.docsForAction.length"
              :pageSize="itemsPerPage"
              :currentPage="actionDocsCurrentPage"
            />
            <Pagination
              :totalItems="actionDialogue.docsForAction.length"
              :pageSize="itemsPerPage"
              :currentPage="actionDocsCurrentPage"
              @pageNavigation="actionDocsCurrentPage = $event"
            />
            <div>
              <v-btn color="primary" outlined @click="actionDocsToggleSelectAll">{{ actionDialogue.selectedDocsForAction.length < actionDialogue.docsForAction.length ? 'Select All' : 'Clear Selection' }}</v-btn>
              <v-btn color="primary" class="ml-2" @click="runAction" :disabled="actionDialogue.selectedDocsForAction.length === 0">Run ({{ actionDialogue.selectedDocsForAction.length }})</v-btn>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="showHierarchies"
      temporary
      absolute
      width="400"
    >
      <v-toolbar-title class="d-flex align-center pl-1 pt-5">
        <span class="ml-2">Advanced Filters</span>
        <v-spacer />
        <v-btn icon @click="showHierarchies = false" class="mr-3"
          ><v-icon>close</v-icon></v-btn
        >
      </v-toolbar-title>

      <HierarchySearch
        v-if="showHierarchies"
        :items="allDocs"
        :preSelected="preSelectedHierarchyFilters"
        :preSelectedClassifiers="preSelectedClassifierFilters"
        :hierarchyTypes="hierarchyTypes"
        :classifierTypes="classifierTypes"
        @filterChanged="doHierarchyFilter"
      ></HierarchySearch>
    </v-navigation-drawer>

    <UserDetail
      :user_id="currentUser.user_id"
      :showDialogue="currentUser.show"
      @saved="userSaved"
      @closed="currentUser.show = false"
      @deactivated="currentUser.show = false"
    ></UserDetail>

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
import utils from "@/common/utils.js";
import ResponseHandler from "@/components/ResponseHandler";
import DocumentDataTable from "@/components/cDocumentDataTable.vue";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import HierarchySearch from "@/components/cHierarchySearch";
import UserDetail from "@/components/cUserDetail";

export default {
  name: "vReviewGroups",
  components: {
    ResponseHandler,
    DocumentDataTable,
    Pagination,
    PageDescription,
    HierarchySearch,
    UserDetail
  },
  props: {},
  data: function () {
    return {
      moment: moment,
      isLoading: false,
      response: null,
      groups: [],
      groupHeaders: [
        { text: "Name", value: "name" },
        { text: "Document Type", value: "tmpl_name" },
        { text: "Users", value: "users" },
        { text: "Jobs", value: "documents" },
        { text: "Creator", value: "created_by" },
        {
          text: "Created",
          value: "created_date",
          sort: (a, b) =>
            moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
              ? 1
              : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
              ? -1
              : 0,
        },
      ],
      groupsCurrentPage: 1,
      groupsItemsPerPage: 20,
      userGroupMap: [],
      docGroupMap: [],
      filterText: "",
      filterUsers: [],
      filterDocs: [],
      groupDetails: null,
      showInactiveUsers: false,
      users: [],
      usersCurrentPage: 1,
      templates: [],
      userFilter: "",
      userRoleFilter: "",
      tab: 0,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      notificationDialogue: {
        show: false,
        loading: false,
        roles: []
      },
      actionDialogue: {
        show: false,
        loading: false,
        bulkActions: [],
        bulkActionToRun: null,
        docsForAction: [],
        docActionsAvailable: [],
        selectedDocsForAction: [],
        processing: false,
        processingIndex: 0
      },
      showHierarchies: false,
      preSelectedHierarchyFilters: [],
      preSelectedClassifierFilters: [],
      itemsPerPage: 10,
      allDocsColumnsEnabled: [ "system_number", "doc_name", "doc_status_text", "overall_score" ],
      groupDocsColumnsEnabled: [ "system_number", "doc_name", "doc_status_text", "overall_score" ],
      allDocs: [],
      allDocsFiltered: [],
      allDocsCurrentPage: 1,
      groupDocsCurrentPage: 1,
      actionDocsCurrentPage: 1,
      groupDocs: [],
      docSearchTextTmp: "",
      docSearchText: "",
      sortColumn: "system_number",
      prevSort: null,
      searchTimeout: null,
      docStatusIncluded: [],
      excludeGrouped: true,
      currentUser: { user_id: null, show: false },
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  watch: {
    docSearchTextTmp() {
      this.changefilterText();
    },
    filters() {
      this.runDocumentSearch();
    },
    "actionDialogue.bulkActionToRun"() {
      this.findGroupDocsForAction();
    }
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
      docsLoading: (state) => state.docs.loading,
      docStatusSummary: (state) =>
        state.docs.docStatusSummary.filter(
          (s) =>
            s.status.toLowerCase() !== "deleted" &&
            s.status.toLowerCase() !== "archived"
        ),
      docTypeSummary: (state) => state.docs.docTypeSummary,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      classifierTypes: (state) => state.docs.classifierTypes,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      docTypes: (state) => state.hierarchies.docTypes,
      docListColumns: (state) => state.settings.config.docListColumns || [],
    }),
    allDocsColumns() {
      return [...this.docListColumns.filter(c => this.allDocsColumnsEnabled.includes(c.value)), { text: "", value: "add_doc_button" }];
    },
    groupDocsColumns() {
      return [...this.docListColumns.filter(c => this.groupDocsColumnsEnabled.includes(c.value)), { text: "", value: "remove_doc_button" }];
    },
    actionDocColumns() {
      return [{ text: "", value: "doc_checkbox" }, ...this.docListColumns.filter(c => this.groupDocsColumnsEnabled.includes(c.value))];
    },
    filteredGroups() {
      let groups = this.groups;

      if (this.filterText) {
        let search = this.filterText
          .toLowerCase()
          .split(" ")
          .filter((x) => x.length)
          .map((x) => x);

        groups = groups.filter((g) =>
          search.every((s) => g.name.toLowerCase().indexOf(s, 0) >= 0)
        );
      }

      if (this.filterDocs.length) {
        const groupIds = this.docGroupMap.filter(m => this.filterDocs.includes(m.doc_id)).map(m => m.review_group_id)
        groups = groups.filter(g => groupIds.includes(g.review_group_id));
      }

      if (this.filterUsers.length) {
        const groupIds = this.userGroupMap.filter(m => this.filterUsers.includes(m.user_id)).map(m => m.review_group_id)
        groups = groups.filter(g => groupIds.includes(g.review_group_id));
      }

      return groups;
    },
    template() {
      return this.templates.find(
        (l) => l.tmpl_id === this.groupDetails?.tmpl_id
      );
    },
    workflowRoles() {
      return this.template ? this.template.roles : [];
    },
    docTypeIncluded() {
      const docType = this.docTypeSummary.find((dt) => dt.tmpl_id === this.groupDetails?.tmpl_id)
      return docType ? [ docType ] : [ { docType: undefined } ];
    },
    lifecycleIdsIncluded() {
      return this.template ? this.template.lifecycles.map(l => l.lifecycle_id) : [];
    },
    filters() {
      const excludeDocIds = this.groupDocs.map((d) => d.doc_id);
      if (this.excludeGrouped && this.groupDetails) {
        excludeDocIds.push(...this.docGroupMap
          .filter(m => m.review_group_id !== this.groupDetails.review_group_id)
          .map(m => m.doc_id));
      }
      return {
        filterText: (this.docSearchText || "").trim().toLowerCase(),
        docStatusIncluded: this.docStatusIncluded.length
          ? this.docStatusIncluded
          : this.docStatusSummary,
        docTypeIncluded: this.docTypeIncluded,
        lifecycleIdsIncluded: this.lifecycleIdsIncluded,
        docIdsExcluded: excludeDocIds
      }
    },
    filteredUsers() {
      return this.users.filter(
        (u) =>
          !this.userFilter ||
          u.name.toLowerCase().indexOf(this.userFilter.toLowerCase()) >= 0
      );
    },
    pageOfFilteredUsers() {
      if (this.itemsPerPage < 0) return this.filteredUsers;

      const pageFrom = this.itemsPerPage * (this.usersCurrentPage - 1);
      const pageTo = this.itemsPerPage * this.usersCurrentPage;
      return this.filteredUsers.slice(pageFrom, pageTo);
    },
    groupUsers() {
      if (!this.groupDetails) return [];
      let users = this.groupDetails.users;

      if (!this.showInactiveUsers)
        users = users.filter(u => u.active);

      if (!this.userRoleFilter)
        return users;
      else
        return users.filter(u => u.roles.includes(this.userRoleFilter));
    },
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("reviewGroups/reviewGroupsList")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.groups = resp.data.Data.groups;
            this.users = resp.data.Data.users;
            this.templates = resp.data.Data.templates;
            this.userGroupMap = resp.data.Data.userGroupMap;
            this.docGroupMap = resp.data.Data.docGroupMap;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          console.log(err);
        });
    },
    loadGroupDetails(group, col) {
      if (!group) return;

      let possibleError = false;
      this.isLoading = true;
      axios
        .get("reviewGroups/reviewGroupDetails/" + group.review_group_id)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.groupDetails = resp.data.Data;
            this.tab = col === "documents" ? 1 : 0;
            this.userRoleFilter = null;
            this.findGroupDocuments();
            this.runDocumentSearch();
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          console.log(err);
        });
    },
    saveGroup() {
      if (this.$refs.form && !this.$refs.form.validate()) {
        this.tab = 0;
        return;
      }

      if (!this.groupDetails || !this.groupDetails.name || !this.groupDetails.tmpl_id) {
        return;
      }

      const groupDocIds = this.groupDocs.map((d) => d.doc_id);
      const request = {
        ...this.groupDetails,
        docIds: groupDocIds,
      };

      let possibleError = false;
      this.isLoading = true;
      axios
        .post("reviewGroups/updateReviewGroup", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            this.groupDetails.review_group_id = data.review_group_id;
            let groupIdx = this.groups.findIndex(
              (g) => g.review_group_id === data.review_group_id
            );
            if (groupIdx >= 0) {
              this.groups.splice(groupIdx, 1, data);
            } else {
              this.groups.push(data);
            }
            this.userGroupMap = [
              ...this.userGroupMap.filter(m => m.review_group_id !== data.review_group_id),
              ...this.groupDetails.users
                .filter(u => u.active)
                .map(u => {
                  return {
                    user_id: u.user_id,
                    name: u.name,
                    review_group_id: data.review_group_id
                  }
                })
            ];
            this.docGroupMap = [
              ...this.docGroupMap.filter(m => m.review_group_id !== data.review_group_id && !groupDocIds.includes(m.doc_id)),
              ...this.groupDocs
                .map(d => {
                  return {
                    doc_id: d.doc_id,
                    doc_name: `${d.reference}: ${d.doc_name}`,
                    review_group_id: data.review_group_id
                  }
                })
            ]
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          console.log(err);
        });
    },
    deleteGroup() {
      if (!this.groupDetails.review_group_id)
        return;

      if (!confirm(`Permanently delete this review group?`))
        return;

      const request = {
        review_group_id: this.groupDetails.review_group_id
      };

      let possibleError = false;
      this.isLoading = true;
      axios
        .post("reviewGroups/deleteReviewGroup", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            let groupIdx = this.groups.findIndex(
              (g) => g.review_group_id === this.groupDetails.review_group_id
            );
            if (groupIdx >= 0) {
              this.groups.splice(groupIdx, 1);
            }
            this.groupDetails = null;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          console.log(err);
        });
    },
    showNotificationDialogue() {
      this.notificationDialogue.roles = [...this.workflowRoles];
      this.notificationDialogue.show = true;
    },
    sendNotifications() {
      const request = {
        review_group_id: this.groupDetails.review_group_id,
        roles: this.notificationDialogue.roles
      }
      let possibleError = false;
      this.notificationDialogue.loading = true;
      axios
        .post("reviewGroups/sendNotifications", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            console.log("sent");
          }
          this.notificationDialogue.loading = false;
          this.notificationDialogue.show = false;
        })
        .catch((err) => {
          this.notificationDialogue.loading = false;
          this.notificationDialogue.show = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          console.log(err);
        });
    },
    addNewUser() {
      this.currentUser.user_id = null;
      this.currentUser.show = true;
    },
    userSaved(user) {
      this.users.push(user);
      this.addUserToGroup(user);
      this.currentUser.show = false;
    },
    addUserToGroup(user) {
      if (this.groupDetails.users.some((u) => u.user_id === user.user_id))
        return;

      let possibleError = false;
      this.isLoading = true;
      axios
        .get(`reviewGroups/userAvailableRoles/${this.groupDetails.tmpl_id}/${user.user_id}`)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.groupDetails.users.push({
              ...user,
              roles: [],
              availableRoles: resp.data.Data.map(ar => ar.lifecycle_role)
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          console.log(err);
        });
    },
    removeUserFromGroup(user) {
      const userIdx = this.groupDetails.users.indexOf(user);
      if (userIdx >= 0) this.groupDetails.users.splice(userIdx, 1);
    },
    toggleUserRole(user, lifecycle_role) {
      const userRoleIdx = user.roles.indexOf(lifecycle_role);
      if (userRoleIdx >= 0) {
        user.roles.splice(userRoleIdx, 1);
      } else {
        user.roles.push(lifecycle_role);
      }
    },
    toggleRoleFilter(role) {
      if (this.userRoleFilter === role)
        this.userRoleFilter = null;
      else
        this.userRoleFilter = role;
    },
    addNewGroup() {
      this.tab = 0;
      const template =
        this.templates.find((t) => t.tmpl_name.indexOf("Job Desc") >= 0) ||
        this.templates[0];
      this.groupDetails = {
        review_group_id: null,
        name: "",
        active: 1,
        tmpl_id: template?.tmpl_id,
        users: [],
        documents: [],
      };
      this.groupDocs.splice(0);
    },
    changefilterText() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => this.docSearchText = this.docSearchTextTmp, 500);
    },
    runDocumentSearch() {
      if (this.docTypeIncluded.length === 0) return;

      this.allDocsCurrentPage = 1;
      let allDocs = this.$store.getters["docs/filter"](this.filters);
      this.allDocs = allDocs;

      if (this.filters.filterText && this.sortColumn !== "matchRating") {
        //override sort by matchrating
        this.prevSort = {
          sortColumn: this.sortColumn,
          sortDesc: this.sortDesc,
        };
        this.sortColumn = "matchRating";
        this.sortDesc = true;
      } else if (
        !this.filters.filterText &&
        this.sortColumn === "matchRating" &&
        this.prevSort
      ) {
        this.sortColumn = this.prevSort.sortColumn;
        this.sortDesc = this.prevSort.sortDesc;
        this.prevSort = null;
      }

      this.doHierarchyFilter(this.preSelectedHierarchyFilters, this.preSelectedClassifierFilters);
    },
    doHierarchyFilter(hierarchyFilters, classifierFilters) {
      if (hierarchyFilters)
        hierarchyFilters = JSON.parse(
          JSON.stringify(
            hierarchyFilters.map((f) => {
              return { ht_id: f.ht_id, selected: f.selected };
            })
          )
        );
      if (classifierFilters)
        classifierFilters = JSON.parse(
          JSON.stringify(
            classifierFilters.map((f) => {
              return { ct_id: f.ct_id, selected: f.selected };
            })
          )
        );

      let docs = [...this.allDocs];

      if (hierarchyFilters) {
        hierarchyFilters.forEach((hf) => {
          if (hf && hf.selected.length) {
            docs = docs.filter((d) =>
              hf.selected.find((s) => {
                let ht = d.hierarchies.find((ht) => ht.ht_id === hf.ht_id);
                return ht && s.id.split("_")[0] == ht.hr_id; // && !s.children
              })
            );
          }
        });
      }

      if (classifierFilters) {
        classifierFilters.forEach((cf) => {
          if (cf && cf.selected.length) {
            const cvs = cf.selected.map((c) => c.cv_id);
            docs = docs.filter(
              (d) =>
                d.classifiers.some((c) => cvs.includes(c.cv_id)) ||
                (cvs.includes(0) &&
                  !d.classifiers.some((c) => c.ct_id === cf.ct_id))
            );
          }
        });
      }

      this.preSelectedHierarchyFilters = hierarchyFilters;
      this.preSelectedClassifierFilters = classifierFilters;

      this.allDocsFiltered = Object.freeze(docs);
    },
    findGroupDocuments() {
      if (
        !this.groupDetails ||
        !this.groupDetails.docIds ||
        !this.groupDetails.docIds.length
      ) {
        this.groupDocs = [];
        return;
      }

      this.groupDocsCurrentPage = 1;
      const filters = {
        docIdsIncluded: this.groupDetails.docIds,
      };
      this.groupDocs = this.$store.getters["docs/filter"](filters);
      if (this.actionDialogue.show) {
        this.findGroupDocsForAction();
      }
    },
    openDocument(item) {
      this.$emit("openDocument", this.docDetails(item));
    },
    docDetails(item) {
      return {
        doc_id: item.doc_id,
        tmpl_id: item.tmpl_id,
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
      };
    },
    addDocumentToGroup(doc) {
      if (this.docGroupMap.some(m => m.doc_id === doc.doc_id && m.review_group_id !== this.groupDetails.review_group_id) &&
        !confirm("Document is already in a review group. Move to this review group?"))
        return;

      if (!this.groupDocs.some((d) => d.doc_id === doc.doc_id))
        this.groupDocs.push(doc);
    },
    addAllDocumentsToGroup() {
      const groupDocIds = this.groupDocs.map((d) => d.doc_id);
      const newDocs = this.allDocsFiltered.filter(
        (d) => !groupDocIds.includes(d.doc_id)
      );
      const newDocIds = newDocs.map(d => d.doc_id);

      if (this.docGroupMap.some(m => newDocIds.includes(m.doc_id) && m.review_group_id !== this.groupDetails.review_group_id)) {
        if (!confirm("One or more documents is already in another review group. Move documents to this review group?"))
          return;
      } else {
        if (!confirm(`Add all ${this.allDocsFiltered.length} documents to group?`))
          return;
      }
        
      this.groupDocs.push(...newDocs);
    },
    removeDocumentFromGroup(doc) {
      const idx = this.groupDocs.indexOf(doc);
      if (idx >= 0) this.groupDocs.splice(idx, 1);
    },
    removeAllDocumentsToGroup() {
      if (
        confirm(`Remove all ${this.groupDocs.length} documents from group?`)
      ) {
        this.groupDocs.splice(0);
      }
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      let included = this.docStatusSummary.filter((s) =>
        this.docStatusIncluded.some((si) => s.status === si.status)
      );
      if (included.length === this.docStatusSummary.length)
        return included.length > 0 ? "All" : "None";

      if (included.length > 2) return `${included.length} items`;

      return included.reduce((prev, curr) => {
        return prev + (prev ? ", " : "") + curr.status;
      }, "");
    },
    showActionDialogue() {
      this.actionDialogue.show = true;

      if (this.actionDialogue.bulkActions.length === 0) {
        let possibleError = false;
        axios
        .get("workflow/getBulkActions")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.actionDialogue.bulkActions = resp.data.Data;
          }
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          }
          console.log(err);
        });
      }
      
      this.findGroupDocsForAction();
    },
    findGroupDocsForAction() {
      this.actionDialogue.docsForAction.splice(0);
      this.actionDialogue.selectedDocsForAction.splice(0);
      
      if (this.actionDialogue.bulkActionToRun) {
        let possibleError = false;
        this.actionDialogue.loading = true;
        axios
          .get("workflow/getDocumentsForAction/" + this.actionDialogue.bulkActionToRun)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              this.actionDialogue.docActionsAvailable = resp.data.Data;
              const docIds = resp.data.Data.map(d => d.doc_id);
              this.actionDialogue.docsForAction = this.groupDocs.filter(d => docIds.includes(d.doc_id));
            }
            this.actionDialogue.loading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            }
            this.actionDialogue.loading = false;
            console.log(err);
          });
      }
    },
    actionDocsToggleSelectAll() {
      if (this.actionDialogue.selectedDocsForAction.length < this.actionDialogue.docsForAction.length)
        this.actionDialogue.selectedDocsForAction = this.actionDialogue.docsForAction.map(d => d.doc_id);
      else
        this.actionDialogue.selectedDocsForAction.splice(0);
    },
    toggleSelectActionDoc(doc) {
      const idx = this.actionDialogue.selectedDocsForAction.indexOf(doc.doc_id);
      if (idx === -1)
        this.actionDialogue.selectedDocsForAction.push(doc.doc_id);
      else
        this.actionDialogue.selectedDocsForAction.splice(idx, 1);
    },
    async runAction() {
      this.actionDialogue.processing = true;
      for (let index = 0; index < this.actionDialogue.selectedDocsForAction.length; index++) {
        if (this.actionDialogue.processing) {
          this.actionDialogue.processingIndex = index;
          const doc_id = this.actionDialogue.selectedDocsForAction[index];
          const action = this.actionDialogue.docActionsAvailable.find(a => a.doc_id === doc_id);
          if (doc_id && action)
            await this.doWorkflowAction(doc_id, action);
        }
      }

      axios
        .post("document/notifySubscribers/", {
          docIds: this.actionDialogue.selectedDocsForAction,
          eventType: "doc_header_updated"
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          return false;
        });

      this.actionDialogue.selectedDocsForAction.splice(0);
      this.actionDialogue.processing = false;
      this.$store.dispatch("tasks/refresh");

      this.findGroupDocuments();
      this.runDocumentSearch();
    },
    async doWorkflowAction(doc_id, action) {
      let request = {
        doc_id: doc_id,
        action_available_id: action.action_available_id,
        lifecycle_role_id: action.lifecycle_role_id,
        document_role_id: action.document_role_id,
        preProcessResponse: null,
        returnHeaderOnly: true,
        delayNotirySubscribers: true
      };

      return axios
        .post("document/processdocumentaction/", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (resp.data.Data.documents && resp.data.Data.documents.length) {
              this.$store.dispatch("docs/updateDoc", resp.data.Data.documents[0]);
            }
          } else {
            console.log(resp.data.Message);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          return false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

tr.inactive {
  background-color: #eee;
}

.v-tabs-items {
  background-color: transparent;
}

.mainPanel {
  border-radius: 10px;
}

.theme--light .bggradient {
  border-top: 1px solid #eee;
  background: linear-gradient(180deg, #ffffff 0%, $background-color-light 100%);
  min-height: 50vh;
}
::v-deep .v-data-table {
  tbody > tr,
  tbody > tr span {
    cursor: pointer;
  }
}
</style>