var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.view)?_c('v-container',[(!_vm.view.respondents || _vm.view.respondents.length === 0)?_c('v-row',[_c('v-col',[_c('h3',[_vm._v("No Evaluators assigned")])])],1):_c('v-row',[_c('v-col',{attrs:{"cols":_vm.readOnly.qCols}},[_c('h3',[_vm._v("Status Summary")])]),_c('v-col',[_c('v-row',_vm._l((_vm.view.documentQuestionnaireStatus
            .questionnaireStatuses),function(rs,rsi){return _c('v-col',{key:'rs_' + rsi},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"responder-stat",style:({
                    width: (70 * rs.count) / rs.total + '%',
                    backgroundColor: rs.colour,
                    color: rs.colour,
                    cursor:
                      _vm.view.documentQuestionnaireStatus.questionnaireStatuses
                        .length > 1
                        ? 'pointer'
                        : '',
                    borderRadius: '5px',
                  }),on:{"click":function($event){return _vm.applyStatusFilter(rs)}}},[_c('span',[_vm._v(_vm._s(rs.count))])]),_c('div',{staticClass:"pl-3 responder-stat",style:({
                    cursor:
                      _vm.view.documentQuestionnaireStatus.questionnaireStatuses
                        .length > 1
                        ? 'pointer'
                        : '',
                  }),on:{"click":function($event){return _vm.applyStatusFilter(rs)}}},[_vm._v(" "+_vm._s(rs.count)+" "+_vm._s(rs.status)+" "),(
                      _vm.view.documentQuestionnaireStatus.questionnaireStatuses
                        .length > 1
                    )?_c('v-icon',{staticClass:"pt-0"},[_vm._v(_vm._s(rs.filter ? "mdi-filter-remove" : "mdi-filter-outline"))]):_vm._e()],1)])]}}],null,true)},[_c('div',[_vm._v(_vm._s(rs.count)+" "+_vm._s(rs.status)+":")]),_vm._l((_vm.view.respondents.filter(
                (x) => x.status === rs.status
              )),function(rp,rpi){return _c('div',{key:'rs_' + rsi + 'rp' + rpi},[_c('div',{staticClass:"responder-stat",style:({
                  width: '16px',
                  height: '16px',
                  backgroundColor: rs.colour,
                  paddingRight: '10px',
                  color: rs.colour,
                  borderRadius: '7px',
                })}),_c('span',{staticStyle:{"padding-left":"10px"}},[_vm._v(_vm._s(rp.userName))])])})],2)],1)}),1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"float-right",attrs:{"title":"Download Evaluations"},on:{"click":_vm.exportToCSV}},[_vm._v("mdi-cloud")])],1)],1),(_vm.view.respondents.length)?_c('v-row',[_c('v-col',{attrs:{"cols":_vm.readOnly.qCols}},[(_vm.view.respondents.length > 1)?_c('h3',[_vm._v(" Evaluators"+_vm._s(_vm.readOnlyResponderTitle)+": "),(_vm.readOnly.showPageSize)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical-circle-outline ")])]}}],null,false,1695278158)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Responders per page:")])],1),_vm._l(([1, 2, 3, 4, 5]),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setReadOnlyPageSize(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)})],2)],1):_vm._e()],1):_c('h3',[_vm._v("Response:")])]),_vm._l((_vm.view.respondents.filter(
        (x) => x.page === _vm.readOnly.page
      )),function(r,ri){return _c('v-col',{key:'r' + ri,attrs:{"cols":_vm.readOnly.aCols}},[(_vm.readOnly.pages > 1 && ri === 0)?_c('v-btn',{staticClass:"stepper-left",attrs:{"x-small":"","disabled":_vm.readOnly.page === 1,"outlined":""},on:{"click":function($event){return _vm.readOnlyScroll(-1)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),(_vm.readOnly.pages > 1 && ri === _vm.readOnly.pageSize - 1)?_c('v-btn',{staticClass:"stepper-right",attrs:{"x-small":"","disabled":_vm.readOnly.page === _vm.readOnly.pages,"outlined":""},on:{"click":function($event){return _vm.readOnlyScroll(1)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),_c('div',{staticClass:"response"},[_c('div',{staticClass:"responder",class:r.status === 'Completed'
              ? 'responder-completed'
              : r.status === 'Not Started'
              ? 'responder-not-started'
              : 'responder-in-progress',attrs:{"title":r.user_role + ': ' + r.userName}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-circle-outline")]),_vm._v(_vm._s(r.userName)+" ")],1),_c('div',{staticClass:"status-label"},[_vm._v(" "+_vm._s(r.status_detail)+" "),(r.score_calculation !== 'NONE')?_c('v-chip',{staticClass:"float-right",attrs:{"label":"","small":""}},[_vm._v("Overall Score: "+_vm._s(r.overall_score))]):_vm._e()],1)])],1)})],2):_vm._e(),_vm._l((_vm.view.sections),function(s){return _c('v-row',{key:'vs' + s.section_id},[_c('v-col',[_c('v-row',{staticStyle:{"background-color":"#f5f5f5"},on:{"click":function($event){return _vm.showSection(s)}}},[_c('v-col',[_c('h4',[_vm._v(_vm._s(s.title))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"float-right"},[_vm._v(_vm._s(s.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_vm._l((s.questions.filter((x) => x && s.show)),function(q){return _c('v-row',{key:'vs' + s.section_id + 'q' + q.questionIndex},[_c('v-col',{attrs:{"cols":_vm.readOnly.qCols}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"1"}},[(_vm.isWithinDialogue)?_c('svg',{attrs:{"width":"22","height":"22"}},[_c('circle',{attrs:{"cx":"11","cy":"11","r":"11","fill":"gray"}}),_c('circle',{attrs:{"cx":"11","cy":"11","r":"9","fill":"white"}}),_c('text',{attrs:{"x":"11","y":"14","text-anchor":"middle","fill":"gray","font-size":"13","font-family":"'Martel Sans', sans-serif","font-weight":"bold"}},[_vm._v(" "+_vm._s(q.questionIndex)+" ")])]):_vm._e()]),_c('v-col',[_c('div',{staticStyle:{"padding-left":"10px"}},[_vm._v(" "+_vm._s(q.question)+" ")])])],1)],1),_vm._l((q.answers.filter((x, xi) =>
            _vm.isRespondentVisisble(xi)
          )),function(a,ai){return _c('v-col',{key:'vs' + s.section_id + 'q' + q.questionIndex + 'a' + ai,attrs:{"cols":_vm.readOnly.aCols}},[(a.value)?_c('div',{staticClass:"answer",class:{ answers_same: q.allMatch }},[(q.control_type === 'MULTI')?_vm._l((a.value),function(v,vi){return _c('v-chip',{key:'vs' +
                  s.section_id +
                  'q' +
                  q.questionIndex +
                  'a' +
                  ai +
                  'v' +
                  vi,attrs:{"small":""}},[_vm._v(_vm._s(v))])}):_vm._e(),_c('div',{staticStyle:{"display":"inline-block","width":"100%"}},[(q.control_type !== 'MULTI')?_c('span',{style:({
                  width: a.score ? 'calc(100% - 40px)' : '',
                  display: 'inline-block',
                })},[_vm._v(_vm._s(a.value))]):_vm._e(),(a.score)?_c('div',{staticClass:"answer-score"},[_vm._v(_vm._s(a.score))]):_vm._e(),(a.notes)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"float-right pr-2",staticStyle:{"padding-top":"3px"},attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-message-text")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(a.notes))])]):_vm._e()],1)],2):_vm._e()])})],2)})],2)],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }