<template>
  <div>
    <v-container
      style="max-width:100% !important;"
      class="no-print"
    >
      <v-card>
        <v-card-text>
          <div v-if="items.length" class="no-print">
            <v-data-table
              :headers="dtHeaders"
              dense
              :items="items"
              sort-by="client_name"
              elevation="0"
              :footer-props="{'items-per-page-options':[10, 20, 30, 50, -1]}"
              :items-per-page="dtItemsPerPage"
              :item-class="row_classes"
              @click:row="editDetails"
            >
              <template #[`item.active`]="{ item }">
                <v-icon v-if="item.active" color="success">check_circle_outline</v-icon>
                <v-icon v-else>not_interested</v-icon>
              </template>
              <template #no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" outlined @click="createNewClient" style="margin-left: 20px; margin-top: -100px">Create New</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <Loading :isVisible="isLoading" />

    <v-dialog v-model="newClientDialog" persistent width="800">
      <v-card class="pt-5">
        <v-card-text>
          <v-row dense>
            <h2 class="pb-3">Create New Client</h2>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="client_data.clientName" label="Client Name*" required></v-text-field>
              <v-text-field label="Short Name (for browser tab)"
                v-model="client_data.display_name"
                :placeholder="client_data.clientName ? client_data.clientName.substring(0, 3) : ''"
                />
            </v-col>
            <v-col>
              <v-menu
                ref="goLiveDatePicker"
                v-model="goLiveDatePicker"
                :close-on-content-click="false"
                :return-value.sync="client_data.go_live_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedGoLiveDate"
                    label="Go Live Date (for reporting)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="client_data.go_live_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="client_data.go_live_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="goLiveDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.goLiveDatePicker.save(client_data.go_live_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-select v-model="client_data.copyFromId"
                :items="items"
                item-text="client_name"
                item-value="client_id"
                label="Copy from" required></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="cancelNewClient">Cancel</v-btn>
          <v-btn color="primary" :disabled="client_data.clientName.length < 3" @click="saveNewClient">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editClientDialog" persistent width="800">
      <v-card class="pt-5">
        <v-card-text>
          <v-row dense>
            <h2 class="pb-3">Edit Client</h2>
          </v-row>
          <v-row dense class="mb-2">
            <v-col class="d-flex align-center">
              <v-switch label="Active" v-model="client_data.active" />
            </v-col>
            <v-col>
              <v-menu
                ref="goLiveEditDatePicker"
                v-model="goLiveEditDatePicker"
                :close-on-content-click="false"
                :return-value.sync="client_data.go_live_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedGoLiveDate"
                    label="Go Live Date (for reporting)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="client_data.go_live_date"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="client_data.go_live_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="goLiveEditDatePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.goLiveEditDatePicker.save(client_data.go_live_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="client_data.clientName" label="Client Name*" required></v-text-field>
              <v-text-field label="Short Name (for browser tab)"
                v-model="client_data.display_name"
                :placeholder="client_data.clientName ? client_data.clientName.substring(0, 3) : ''"
                />
            </v-col>
            <v-col>
              <v-text-field label="Host" v-model="client_data.host" />
              <v-select label="Internal User"
                v-model="client_data.internal_user"
                :items="adminUsers"
                item-text="name"
                item-value="user_id" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="cancelEditClient">Cancel</v-btn>
          <v-btn color="primary" :disabled="client_data.clientName.length < 3" @click="saveEditClient">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="hierarchyDialog" persistent width="400">
      <v-card class="pt-5">
        <v-card-text>
          <v-row dense>
            <v-col>{{ 'Enter New Hierarchy for client:' }}</v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="newHierarchy" label="Hierarchy*" required></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelHierarchy">Cancel</v-btn>
          <v-btn color="primary" text @click="saveHierarchy">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";
import moment from "moment";

export default {
  name: "ClientList",
  components: {
    ResponseHandler
    // draggable
  },
  props: {},
  data: function() {
    return {
      response: null,
      items: [],
      cleanItems: [],
      isDirty: false,
      newClientDialog: false,
      editClientDialog: false,
      hierarchyDialog: false,
      permissionDialogue: false,
      permissions: [],
      newPermission: null,
      newHierarchy: "",
      currentUID: "",
      currentUName: "",
      isLoading: false,
      tempFilterText: "",
      filterText: "",
      dtItemsPerPage: 20,
      dtHeaders: [
        { text: "Id", align: "start", value: "client_id" },
        { text: "Name", align: "start", value: "client_name" },
        { text: "Active", align: "start", value: "active" },
        { text: "Created", align: "start", value: "created_date" },
        { text: "Go Live", align: "start", value: "go_live_date" }
      ],
      dialog: false,
      options: { itemsPerPage: 15 },
      client_data: {
        clientName: '',
        clientId: 0,
        copyFromId: null,
        active: true,
        display_name: null,
        internal_user: null,
        go_live_date: moment().format("YYYY-MM-DD")
      },
      adminUsers: [],
      goLiveDatePicker: false,
      goLiveEditDatePicker: false
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  computed: {
    formattedGoLiveDate() {
      return this.client_data.go_live_date ? moment(this.client_data.go_live_date, 'YYYY-MM-DD').format('DD MMM YYYY') : '';
    }
  },
  created() {
    this.fetchData();
    this.dtItemsPerPage = Number(localStorage.getItem("dtUL")) || 20;
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let possibleError = false;
      axios
        .get("client/list/")
        .then(resp => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.items = resp.data.Data;
            this.isLoading = false;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch(err => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    row_classes(item) {
      if (!item.active)
        return "inactive";
    },
    setHierarchy(item) {
      this.currentUID = item.user_id;
      this.currentUName = item.name;
      this.newHierarchy = "";
      this.hierarchyDialog = true;
    },
    cancelHierarchy() {
      this.currentUID = "";
      this.newHierarchy = "";
      this.hierarchyDialog = false;
    },
    createNewClient() {
      this.client_data.copyFromId = this.items.find(i => i.client_name.startsWith("Base"))?.client_id || 0;
      this.client_data.clientName = "";
      this.client_data.display_name = "";
      this.client_data.go_live_date = moment().format("YYYY-MM-DD");
      this.newClientDialog = true;
    },
    saveNewClient() {
      this.client_data.clientName = this.client_data.clientName.trim();
      axios
        .post("client/createNew/", this.client_data)
        .then(resp => {
          if (resp.data.Status === "OK") {
            this.items.push( {
              client_id: resp.data.Data.client_id,
              client_name: this.client_data.clientName,
              active: true,
              created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
              go_live_date: this.client_data.go_live_date
            });
            this.client_data.clientName = "";
            this.newClientDialog = false;
          }
          this.response = resp.data;
          this.isLoading = false;
          this.$store.dispatch("customers/getCustomers");
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    cancelNewClient() {
      this.client_data.clientName = "";
      this.newClientDialog = false;
    },
    saveEditClient() {
      axios
        .post("client/update/", this.client_data)
        .then(resp => {
          if (resp.data.Status === "OK") {
            const theClient = this.items.find(it => it.client_id === this.client_data.clientId);
            theClient.client_name = this.client_data.clientName;
            theClient.active = this.client_data.active;
            theClient.go_live_date = this.client_data.go_live_date;
            this.client_data.clientName = "";
            this.client_data.clientId = 0;
            this.client_data.go_live_date = moment().format("YYYY-MM-DD");
            this.newClientDialog = false;
          }
          this.response = resp.data;
          this.editClientDialog = false;
          this.isLoading = false;
          this.$store.dispatch("customers/getCustomers");
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    cancelEditClient() {
      this.client_data.clientName = "";
      this.editClientDialog = false;
    },
    saveHierarchy() {
      if (!this.newHierarchy || !this.currentUID) return;
      let data = { user_id: this.currentUID, password: this.newHierarchy };
      axios
        .post("user/updateHierarchy/", data)
        .then(resp => {
          if (resp.data.Status === "OK") {
            this.currentUID = "";
            this.newHierarchy = "";
            this.hierarchyDialog = false;
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    editDetails(item) {
      this.editClientDialog = true;
      this.isLoading = true;
      let possibleError = false;
      axios
        .get("client/client/" + item.client_id)
        .then(resp => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            this.isLoading = false;
            this.client_data.clientId = item.client_id;
            this.client_data.clientName = data.client_name;
            this.client_data.active = data.active;
            this.client_data.host = data.host;
            this.client_data.display_name = data.display_name;
            this.client_data.internal_user = data.internal_user;
            this.client_data.go_live_date = data.go_live_date;
            this.adminUsers = data.adminUsers
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch(err => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    exportToCSV() {
      let data = this.dtHeaders.map(h => '"' + h.text + '"').join(",") + "\n";
      this.items.forEach(d => {
        data +=
          this.dtHeaders
            .map(h => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Users.csv", "text/csv;encoding:utf-8");
    }
  }
};
</script>
<style scoped lang="scss">
  ::v-deep .v-data-table {
    &.theme--light tr.inactive {
      background-color: #efefef;
    }
    &.theme--dark tr.inactive {
      background-color: rgb(47, 47, 47);
    }
    tr {
      cursor: pointer;
    }
  }
</style>
