<template>
  <v-card>
    <v-card-title v-if="!hideHeader">
      <v-icon style="padding-right: 20px">mdi-comment-outline</v-icon>
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="handler('cancel_dialogue')" small icon
        ><v-icon>mdi-close-thick</v-icon></v-btn
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="!isLoading">
      <Comments
        :document="document"
        :commentList="comments"
        :doc_part_id="doc_part_id"
        @saved="saved"
      ></Comments>
    </v-card-text>
  </v-card>
  <!-- <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" /> -->
  <!-- </v-dialog> -->
</template>

<script>
import axios from "axios";
// import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";
import Comments from "@/components/comments/Comments";

export default {
  name: "CommentsContainer",
  components: {
    // ResponseHandler,
    Comments,
  },
  props: {
    document: null,
    partType: null,
    doc_part_id: null,
    hideHeader: Boolean,
  },
  data: function () {
    return {
      title: "",
      comments: [],
      response: null,
      isLoading: true,
      utils: utils,
      dp_id: null,
    };
  },
  updated() {},
  created() {
    if (this.document) {
      this.handler("show");
    }
  },
  watch: {
    doc_part_id(newval) {
      if (newval !== this.dp_id) this.handler("show");
    },
  },
  methods: {
    decorateComments(data) {
      data.forEach((x) => {
        x.reply = {
          show: false,
          isDirty: false,
          text: "",
          document_comment_id_parent: x.document_comment_id,
          dp_id: this.doc_part_id,
        };
      });
      let stack = (parent) => {
        let nodes = data.filter((x) => x.document_comment_id_parent === parent);
        nodes.forEach((n) => {
          n.replies = stack(n.document_comment_id);
        });
        return nodes;
      };
      let newVal = stack(null);
      this.comments.splice(0, this.comments.length, ...newVal);
    },
    saved(data) {
      this.decorateComments(data.comments);
    //   if (this.part) this.part.hasComments = data.comments.length > 0;
      this.$emit("saved", data.comments);
    },
    handler(action) {
      switch (action) {
        case "show":
          this.title = `${this.partType || "Document"} Comments`;
          this.handler("cancel_new");
          this.isLoading = true;
          this.dp_id = this.doc_part_id;
          axios
            .get(
              this.doc_part_id
                ? `workflow/getDocPartComments/${this.doc_part_id}`
                : `workflow/getDocLevelComments/${this.document.doc_id}`
            )
            .then((resp) => {
              if (resp.data.Status === "OK") {
                this.decorateComments(resp.data.Data);
              }
              this.isLoading = false;
            })
            .catch((err) => {
              if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                console.log(err);
                this.response = err.response
                  ? err.response.data
                  : { message: "Unexpected Error" };
              }
              this.isLoading = false;
            });
          break;
        case "cancel_dialogue":
          this.$emit("closed");
          break;
      }
    },
  },
};
</script>
<style scoped>
</style>
