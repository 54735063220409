<template>
  <div v-if="doc" style="display: inline-table;">
    <v-tooltip bottom v-if="doc.pending_status_addendum">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          :content="doc.pending_status_addendum"
          :value="doc.pending_status_addendum"
          color="blue"
          overlap
          bordered
          :offset-x="sideAddendum ? 40 : null"
          :offset-y="sideAddendum ? 20 : null"
        >
          <v-chip
            v-bind="attrs"
            v-on="on"
            dense
            :small="!xSmall"
            :x-small="xSmall"
            class="status-chip"
            label
            @click="$emit('click')"
            :color="utils.resolveHeaderStyle(doc)"
            >{{ utils.resolveStatusChipText(doc) }}</v-chip
          >
        </v-badge></template
      >
      <v-container
        :style="{
          minWidth:
            doc.lifecycle_status_non_responders &&
            doc.lifecycle_status_responders
              ? '400px'
              : '200px',
        }"
      >
        <v-row>
          <v-col v-if="doc.lifecycle_status_non_responders"
            ><h3>Waiting for:</h3></v-col
          >
          <v-col v-if="doc.lifecycle_status_responders"
            ><h3>Completed by:</h3></v-col
          >
        </v-row>
        <v-row>
          <v-col v-if="doc.lifecycle_status_non_responders">
            <v-row
              dense
              v-for="r in doc.lifecycle_status_non_responders.split(',')"
              :key="r"
            >
              <v-col>{{ r }}</v-col>
            </v-row>
          </v-col>
          <v-col v-if="doc.lifecycle_status_responders">
            <v-row
              dense
              v-for="r in doc.lifecycle_status_responders.split(',')"
              :key="r"
            >
              <v-col>{{ r }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-tooltip>
    <v-chip
      v-else
      dense
      :small="!xSmall"
      :x-small="xSmall"
      class="status-chip"
      label
      :title="utils.resolveStatusChipText(doc)"
      @click="$emit('click')"
      :color="utils.resolveHeaderStyle(doc)"
      >{{ utils.resolveStatusChipText(doc) }}</v-chip
    >
  </div>
</template>

<script>
import utils from "@/common/utils.js";

export default {
  name: "DocStatus",
  props: {
    document: Object,
    sideAddendum: Boolean,
    xSmall: Boolean,
    doc_id: Number,
  },
  data: function () {
    return {
      utils: utils,
      doc: null,
    };
  },
  components: {},
  watch: {
    document: function (newVal) {
      if (newVal) this.init();
    },
    doc_id: function (newVal) {
      if (newVal) this.init();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
  computed: {},
  methods: {
    init() {
      if (this.document) this.doc = this.document;
      else if (this.doc_id) {
        this.doc = this.$store.getters["docs/docsList"].find(
          (x) => x.doc_id === this.doc_id
        );
      }
    },
  },
};
</script>
<style>
</style>