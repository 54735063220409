<template>
  <div>
    <v-container v-if="questionnaires.length">
      <v-row
        ><v-col><h1 class="title">Job Evaluation Report</h1></v-col></v-row
      >
      <v-row>
        <v-col
          ><v-select
            outlined
            v-model="filter.data_capture_type_id"
            hide-details
            dense
            label="Questiionnaire"
            :items="questionnaires"
            item-text="data_capture_name"
            item-value="data_capture_type_id"
          ></v-select
        ></v-col>
        <v-col
          v-for="(f, fi) in filterS.filters.filter(
            (x) =>
              (x.items && x.items.length > 1) || (x.columns && x.columns.length)
          )"
          :key="'f' + fi"
          ><v-select
            v-if="f.items"
            outlined
            v-model="f.values"
            hide-details
            clearable
            dense
            :label="f.label"
            :items="questionnaire[f.items]"
            multiple
            :item-value="f.itemValue"
            :item-text="f.itemText"
            @change="applyFilter"
          ></v-select
          ><v-text-field
            v-else
            v-model="f.value"
            :label="f.label"
            outlined
            clearable
            hide-details
            dense
            @input="applyFilter"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet elevation="3"
            ><v-container>
              <v-row
                ><v-col cols="8">
                  <v-tabs v-model="viewTab">
                    <v-tabs-slider color="blue"></v-tabs-slider>
                    <v-tab>Summary</v-tab>
                    <v-tab @change="viewNextPage(documentView, 0)"
                      >All Documents ({{
                        (documentView.page - 1) * documentView.pageSize + 1
                      }}
                      to
                      {{
                        documentView.page === documentView.pageCount
                          ? documentView.docCount
                          : documentView.page * documentView.pageSize
                      }}
                      of {{ documentView.docCount }})</v-tab
                    >
                    <v-tab @change="viewNextPage(responseView, 0)"
                      >Expected Responses ({{
                        (responseView.page - 1) * responseView.pageSize + 1
                      }}
                      to
                      {{
                        responseView.page === responseView.pageCount
                          ? responseView.respCount
                          : responseView.page * responseView.pageSize
                      }}
                      of {{ responseView.respCount }})</v-tab
                    >
                  </v-tabs></v-col
                >
                <v-col>
                  {{
                    filterS.active && viewTab === 0
                      ? "(FILTER NOT APPLIED)"
                      : ""
                  }}
                  <v-row v-if="viewTab > 0"
                    ><v-col> {{ filterS.active ? "(FILTERED)" : "" }}</v-col>
                    <v-col
                      ><v-pagination
                        v-show="viewTab === 1"
                        v-model="documentView.page"
                        :length="documentView.pageCount"
                        :total-visible="7"
                        @input="viewNextPage(documentView, 0)"
                      ></v-pagination
                      ><v-pagination
                        v-show="viewTab === 2"
                        v-model="responseView.page"
                        :length="responseView.pageCount"
                        :total-visible="7"
                        @input="viewNextPage(responseView, 0)"
                      ></v-pagination></v-col
                    ><v-col cols="1">
                      <v-menu
                        v-model="sort.menu"
                        :close-on-content-click="true"
                        :nudge-width="200"
                        offset-x
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon dark v-bind="attrs" v-on="on">
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Sort Documents By:</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>
                                <v-radio-group
                                  v-model="sort.by"
                                  @change="setUpPaging"
                                >
                                  <v-radio
                                    label="Job Title"
                                    value="title"
                                  ></v-radio>
                                  <v-radio
                                    label="Reference"
                                    value="reference"
                                  ></v-radio> </v-radio-group
                              ></v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu> </v-col></v-row></v-col
              ></v-row>
              <v-row>
                <v-col>
                  <v-tabs-items v-model="viewTab">
                    <v-tab-item class="pl-4">
                      <v-row>
                        <v-col cols="3" class="column-heading"
                          >Document Type / Statuses</v-col
                        >
                        <v-col cols="5"
                          ><div class="total-heading-top">Documents</div></v-col
                        >
                        <v-col cols="4"
                          ><div class="total-heading-top">
                            Evaluations
                          </div></v-col
                        >
                      </v-row>
                      <v-row dense>
                        <v-col cols="3" class="column-heading"></v-col>
                        <v-col cols="1" class="total-heading">Total</v-col>
                        <v-col cols="1" class="total-heading">Unassigned</v-col>
                        <v-col cols="1" class="total-heading"
                          >Not Started</v-col
                        >
                        <v-col cols="1" class="total-heading"
                          >In Progress</v-col
                        >
                        <v-col cols="1" class="total-heading">Completed</v-col>
                        <v-col cols="1" class="total-heading">Total</v-col>
                        <v-col cols="1" class="total-heading"
                          >Not Started</v-col
                        >
                        <v-col cols="1" class="total-heading"
                          >In Progress</v-col
                        >
                        <v-col cols="1" class="total-heading">Completed</v-col>
                      </v-row>
                      <v-row
                        dense
                        v-for="(dt, dti) in questionnaire.docTypes"
                        :key="'dt' + dti"
                      >
                        <v-col>
                          <v-row dense>
                            <v-col cols="3" class="row-heading"
                              >{{ dt.doc_type }} ({{
                                dt.lifecycle_name
                              }}
                              Workflow)</v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">{{ dt.docs }}</div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_unassigned }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_completed }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments_completed }}
                              </div></v-col
                            >
                          </v-row>
                          <v-row
                            dense
                            v-for="(s, si) in dt.statuses"
                            :key="'dt' + dti + 's' + si"
                          >
                            <v-col cols="3"
                              ><div class="row-heading-indent">
                                {{ s.doc_status }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">{{ s.docs }}</div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_unassigned }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_completed }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments_completed }}
                              </div></v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row><v-col></v-col></v-row>
                      <v-row>
                        <v-col cols="3" class="column-heading"
                          >Review Groups / Statuses</v-col
                        >
                        <v-col cols="5"
                          ><div class="total-heading-top">Documents</div></v-col
                        >
                        <v-col cols="4"
                          ><div class="total-heading-top">
                            Evaluations
                          </div></v-col
                        >
                      </v-row>
                      <v-row dense>
                        <v-col cols="3" class="column-heading"></v-col>
                        <v-col cols="1" class="total-heading">Total</v-col>
                        <v-col cols="1" class="total-heading">Unassigned</v-col>
                        <v-col cols="1" class="total-heading"
                          >Not Started</v-col
                        >
                        <v-col cols="1" class="total-heading"
                          >In Progress</v-col
                        >
                        <v-col cols="1" class="total-heading">Completed</v-col>
                        <v-col cols="1" class="total-heading">Total</v-col>
                        <v-col cols="1" class="total-heading"
                          >Not Started</v-col
                        >
                        <v-col cols="1" class="total-heading"
                          >In Progress</v-col
                        >
                        <v-col cols="1" class="total-heading">Completed</v-col>
                      </v-row>
                      <v-row
                        dense
                        v-for="(dt, dti) in questionnaire.reviewGroups"
                        :key="'rg' + dti"
                      >
                        <v-col>
                          <v-row dense>
                            <v-col cols="3" class="row-heading">{{
                              dt.review_group_name
                            }}</v-col>
                            <v-col cols="1"
                              ><div class="totals">{{ dt.docs }}</div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_unassigned }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.docs_completed }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ dt.assignments_completed }}
                              </div></v-col
                            >
                          </v-row>
                          <v-row
                            dense
                            v-for="(s, si) in dt.statuses"
                            :key="'rg' + dti + 's' + si"
                          >
                            <v-col cols="3"
                              ><div class="row-heading-indent">
                                {{ s.doc_status }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">{{ s.docs }}</div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_unassigned }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.docs_completed }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments_not_started }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments_in_progress }}
                              </div></v-col
                            >
                            <v-col cols="1"
                              ><div class="totals">
                                {{ s.assignments_completed }}
                              </div></v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item>
                      <v-row v-if="documentView.page === 0"
                        ><v-col>No documents found</v-col></v-row
                      >
                      <v-row v-else
                        ><v-col
                          v-for="(d, di) in questionnaire.documents.filter(
                            (x) =>
                              x.expected_responses.some(
                                (r) => r.docPage === documentView.page
                              )
                          )"
                          :key="'udns' + di"
                          cols="2"
                          ><div class="document">
                            <v-row
                              ><v-col
                                ><div
                                  class="doc-reference"
                                  @click="openDocument(d)"
                                  :style="{
                                    cursor: d.viewAction ? 'pointer' : 'none',
                                  }"
                                >
                                  {{ d.reference }}
                                  <v-chip
                                    x-small
                                    outlined
                                    label
                                    :color="d.doc_status_colour"
                                    class="float-right"
                                    >{{ d.doc_status }}</v-chip
                                  >
                                  <div class="doc-title">{{ d.doc_name }}</div>
                                </div>
                                <div class="expected-responses">
                                  <v-icon small v-if="d.isUnassigned"
                                    >mdi-alert</v-icon
                                  >
                                  {{
                                    d.isUnassigned
                                      ? "Unnasigned"
                                      : d.expected_response_text
                                  }}
                                  <v-tooltip bottom v-if="!d.isUnassigned">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div
                                        v-bind="attrs"
                                        v-on="on"
                                        style="display: inline-flex"
                                      >
                                        <div
                                          v-for="(
                                            rs, rsi
                                          ) in d.expected_response_stats"
                                          :key="'d' + di + 'rs' + rsi"
                                          :class="rs.class"
                                          class="responder-stat"
                                          :style="{
                                            width: rs.count * 10 + 'px',
                                          }"
                                        ></div>
                                      </div>
                                    </template>
                                    <div
                                      v-for="(
                                        rs, rsi
                                      ) in d.expected_response_stats"
                                      :key="'d' + di + 'rs' + rsi"
                                    >
                                      <div
                                        :class="rs.class"
                                        class="responder-stat"
                                        :style="{
                                          width: rs.count * 20 + 'px',
                                          height: '20px',
                                          fontSize: '14px',
                                        }"
                                      >
                                        {{ rs.count }}
                                      </div>
                                      <div
                                        style="
                                          display: inline-flex;
                                          padding-left: 6px;
                                          padding-top: 1px;
                                        "
                                      >
                                        {{ rs.status }}
                                      </div>
                                    </div>
                                  </v-tooltip>
                                </div></v-col
                              ></v-row
                            >
                            <v-row dense v-if="di === -1"
                              ><v-col
                                v-for="(r, ri) in d.expected_responses.filter(
                                  (x) => x.docPage === documentView.page
                                )"
                                :key="'d' + di + 'r' + ri"
                                :title="r.requirement"
                              >
                                <div class="response">
                                  <v-row
                                    dense
                                    v-for="(rs, rsi) in r.responders"
                                    :key="'d' + di + 'r' + ri + 'rs' + rsi"
                                  >
                                    <v-col cols="12">
                                      <div
                                        class="responder"
                                        :class="
                                          r.status === 'Completed'
                                            ? 'responder-completed'
                                            : r.status === 'Not Started'
                                            ? 'responder-not-started'
                                            : 'responder-in-progress'
                                        "
                                        :title="
                                          rs.user_role + ': ' + rs.user_name
                                        "
                                      >
                                        <v-icon small
                                          >mdi-account-circle-outline</v-icon
                                        >{{ rs.user_name }}
                                      </div></v-col
                                    > </v-row
                                  ><v-row dense
                                    ><v-col class="status-label">
                                      {{ r.status_detail }}
                                    </v-col></v-row
                                  >
                                </div></v-col
                              ></v-row
                            >
                          </div>
                        </v-col></v-row
                      >
                    </v-tab-item>
                    <v-tab-item>
                      <v-row v-if="responseView.page === 0"
                        ><v-col>No expected responses found</v-col></v-row
                      >
                      <v-row v-else
                        ><v-col>
                          <v-row>
                            <v-col cols="3"></v-col>
                            <v-col
                              ><v-row>
                                <v-col
                                  v-for="(
                                    d, di
                                  ) in questionnaire.documents.filter((x) =>
                                    x.expected_responses.some(
                                      (r) => r.respPage === responseView.page
                                    )
                                  )"
                                  :key="'d' + di"
                                  :cols="d.cols"
                                  ><div class="document">
                                    <v-row
                                      ><v-col
                                        ><div
                                          class="doc-reference"
                                          @click="openDocument(d)"
                                          :style="{
                                            cursor: d.viewAction
                                              ? 'pointer'
                                              : 'none',
                                          }"
                                        >
                                          {{ d.reference }}
                                          <v-chip
                                            x-small
                                            outlined
                                            label
                                            style="margin-left: 20px"
                                            :color="d.doc_status_colour"
                                            >{{ d.doc_status }}</v-chip
                                          >
                                          <div class="doc-title">
                                            {{ d.doc_name }}
                                          </div>
                                        </div>
                                        <div class="expected-responses">
                                          {{ d.expected_response_text }}
                                          <v-tooltip bottom>
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <div
                                                v-bind="attrs"
                                                v-on="on"
                                                style="display: inline-flex"
                                              >
                                                <div
                                                  v-for="(
                                                    rs, rsi
                                                  ) in d.expected_response_stats"
                                                  :key="'d' + di + 'rs' + rsi"
                                                  :class="rs.class"
                                                  class="responder-stat"
                                                  :style="{
                                                    width: rs.count * 10 + 'px',
                                                  }"
                                                ></div>
                                              </div>
                                            </template>
                                            <div
                                              v-for="(
                                                rs, rsi
                                              ) in d.expected_response_stats"
                                              :key="'d' + di + 'rs' + rsi"
                                            >
                                              <div
                                                :class="rs.class"
                                                class="responder-stat"
                                                :style="{
                                                  width: rs.count * 20 + 'px',
                                                  height: '20px',
                                                  fontSize: '14px',
                                                }"
                                              >
                                                {{ rs.count }}
                                              </div>
                                              <div
                                                style="
                                                  display: inline-flex;
                                                  padding-left: 6px;
                                                  padding-top: 1px;
                                                "
                                              >
                                                {{ rs.status }}
                                              </div>
                                            </div>
                                          </v-tooltip>
                                        </div></v-col
                                      ></v-row
                                    >
                                    <v-row dense class="flex-nowrap"
                                      ><v-col
                                        v-for="(
                                          r, ri
                                        ) in d.expected_responses.filter(
                                          (x) =>
                                            x.respPage === responseView.page
                                        )"
                                        :key="'d' + di + 'r' + ri"
                                      >
                                        <div class="response">
                                          <v-row
                                            dense
                                            v-for="(rs, rsi) in r.responders"
                                            :key="di + 'r' + ri + 'rs' + rsi"
                                          >
                                            <v-col cols="12">
                                              <div
                                                class="responder"
                                                :class="
                                                  r.status === 'Completed'
                                                    ? 'responder-completed'
                                                    : r.status === 'Not Started'
                                                    ? 'responder-not-started'
                                                    : 'responder-in-progress'
                                                "
                                                :title="
                                                  rs.user_role +
                                                  ': ' +
                                                  rs.user_name
                                                "
                                              >
                                                <v-icon small
                                                  >mdi-account-circle-outline</v-icon
                                                >{{ rs.user_name }}
                                              </div>
                                            </v-col> </v-row
                                          ><v-row dense
                                            ><v-col
                                              class="status-label"
                                              cols="12"
                                            >
                                              {{ r.status_detail }}
                                            </v-col></v-row
                                          >
                                        </div>
                                      </v-col></v-row
                                    >
                                  </div>
                                </v-col>
                              </v-row></v-col
                            >
                          </v-row>
                          <v-row
                            v-for="(vs, vsi) in questionnaire.viewSections"
                            :key="'vs' + vsi"
                            ><v-col>
                              <v-row
                                class="section-heading"
                                @click="vs.show = !vs.show"
                                ><v-col
                                  ><h4>{{ vs.title }}</h4></v-col
                                ><v-col cols="1"
                                  ><v-icon class="float-right">{{
                                    vs.show
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  }}</v-icon></v-col
                                ></v-row
                              >
                              <v-row
                                v-for="(vq, vqi) in vs.questions.filter(
                                  (x) => x && vs.show
                                )"
                                :key="'vs' + vsi + 'vq' + vqi"
                              >
                                <v-col cols="3">
                                  <svg width="22" height="22">
                                    <circle
                                      cx="11"
                                      cy="11"
                                      r="11"
                                      fill="gray"
                                    />
                                    <circle
                                      cx="11"
                                      cy="11"
                                      r="9"
                                      fill="white"
                                    />
                                    <text
                                      x="11"
                                      y="14"
                                      text-anchor="middle"
                                      fill="gray"
                                      font-size="13"
                                      font-family="'Martel Sans', sans-serif"
                                      font-weight="bold"
                                    >
                                      {{ vq.questionIndex }}
                                    </text>
                                  </svg>
                                  <div
                                    style="
                                      width: calc(100% - 35px);
                                      float: right;
                                    "
                                  >
                                    {{ vq.question }}
                                  </div></v-col
                                ><v-col
                                  ><v-row>
                                    <v-col
                                      v-for="(a, ai) in vq.pageAnswers.filter(
                                        (x) => x.respPage === responseView.page
                                      )"
                                      :key="'vs' + vsi + 'a' + ai"
                                      cols="2"
                                      ><div v-if="a.value" class="answer">
                                        {{ a.value }}
                                      </div>
                                    </v-col>
                                  </v-row></v-col
                                >
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col></v-row
                      >
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-container></v-sheet
          >
        </v-col>
      </v-row>
    </v-container>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import Pagination from "@/components/cPagination";
// import PageDescription from "@/components/cPageDescription";
import utils from "@/common/utils.js";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "QuestionnaireSummary",
  components: {
    ResponseHandler,
    // Pagination,
    // PageDescription,
  },
  props: {
    fullScreen: { type: Boolean, required: false, default: true },
    pageSize: { type: Number, required: false, default: 0 },
    height: { type: String, required: false, default: "" },
    showHeaderCount: { type: Boolean, required: false, default: false },
    showFooter: { type: Boolean, required: false, default: true },
    showFilters: { type: Boolean, required: false, default: true },
    showTitle: { type: Boolean, required: false, default: true },
    density: { type: String, required: false, default: "sparse" },
    filters: { type: Object, required: false },
    minmalColumns: { type: Boolean, default: false },
  },
  data: function () {
    return {
      tasks: [],
      users: [],
      utils: utils,
      response: null,
      title: "Tsks",
      items: [],
      isDirty: false,
      dirtyCount: 0,
      taskDueDateRange: [],
      taskDueDateRangeMenu: false,
      docStatusIncluded: [],
      docTypeIncluded: [],
      itemStatusIncluded: [],
      tempFilterText: "",
      filterText: "",
      showSearch: false,
      selDoc: "",
      viewSelect: "",
      dtItemsPerPage: 20,
      contextMenu: false,
      csvHeaders: [],
      dialog: false,
      options: { itemsPerPage: 15 },
      defaultStatusFilterList: "approved,pre-draft,pending,draft",
      filteredItems: [],
      maxCards: 24,
      currentPage: 1,
      drawerHover: false,
      currentTask: {},
      searchTimeout: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      savedFilters: [],
      currentFilterKey: "",
      newFilterKey: "",
      isFavFilter: false,
      selectedDocument: null,
      tableHeight: null,
      tableHeightTimeout: null,
      posX: null,
      posY: null,
      taskStatusFilter: "2",
      hasTasks: false,
      questionnaires: [],
      questionnaire: null,
      view: { startIndex: 0, endIndex: 0, pageSize: 6 },
      documentView: {
        pageCount: 0,
        page: 0,
        pageSize: 18,
        docCount: 0,
        pageColumn: "docPage",
      },
      responseView: {
        pageCount: 0,
        page: 0,
        pageSize: 6,
        docCount: 0,
        respCount: 0,
        pageColumn: "respPage",
      },
      sort: { menu: false, by: "title" },
      viewTab: null,
      filterS: {
        data_capture_type_id: null,
        active: false,
        filters: [
          {
            label: "Document Types",
            level: "document",
            items: "docTypes",
            itemText: "doc_type",
            itemValue: "doc_type",
            values: [],
            value: null,
          },
          {
            label: "Document Status",
            level: "document",
            items: "docStatuses",
            itemText: "doc_status",
            itemValue: "doc_status",
            values: [],
            value: null,
          },
          {
            label: "Response Status",
            level: "response",
            items: "responseStatuses",
            itemText: "status",
            itemValue: "status",
            values: [],
            value: null,
          },
          {
            label: "Responder",
            level: "responder",
            items: "responders",
            itemText: "user_name",
            itemValue: "user_id",
            values: [],
            value: null,
          },
          {
            label: "Review Groups",
            level: "responder",
            items: "reviewGroups",
            itemText: "review_group_name",
            itemValue: "review_group_id",
            values: [],
            value: null,
          },
          {
            label: "Job Search",
            id: "JobSearch",
            level: "document",
            columns: ["doc_name", "reference"],
            value: null,
            values: [],
          },
        ],
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    isLoading(val) {
      if (!val) this.fetchData();
    },
    filters() {
      this.doFilter();
    },
  },
  computed: {
    rowClickAction() {
      return (
        this.$loginState.user.settings.find(
          (s) => s.setting === "tasks_leftclickaction"
        )?.value || "opendoc"
      );
    },
    taskDueDateRangeText() {
      const range = this.taskDueDateRange;
      if (!range.length) return "";
      if (range.length == 1) return dayjs(range[0]).format("DD MMM YYYY");

      return range
        .map((d) => {
          return dayjs(d).format("DD MMM");
        })
        .join(" - ");
    },
    itemsPerPage() {
      if (this.pageSize !== 0) return this.pageSize;
      else return this.dtItemsPerPage;
    },
    currentPageItems() {
      if (this.itemsPerPage < 0) return this.filteredItems;

      const pageFrom = this.itemsPerPage * (this.currentPage - 1);
      const pageTo = this.itemsPerPage * this.currentPage;
      return this.filteredItems.slice(pageFrom, pageTo);
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapState({
      isLoading: (state) => state.tasks.isLoading,
    }),
  },
  created() {
    this.savedFilters = this.$loginState.getSetting("twpSavedFilters", []);
    this.fetchData();
    this.viewSelect = localStorage.getItem("dlview") || "cards";
    this.dtItemsPerPage = Number(localStorage.getItem("dtRPP")) || 20;
  },
  methods: {
    setTableHeight() {
      const paneHeight = this.$refs.mainPane.clientHeight;
      if (!this.height || !paneHeight || isNaN(paneHeight)) {
        this.tableHeight = null;
      } else if (this.showHeaderCount) {
        this.tableHeight = `${paneHeight - 80}px`;
      } else {
        this.tableHeight = `${paneHeight - 40}px`;
      }
    },
    documentChanged(doc) {
      this.$store.dispatch("docs/updateDoc", doc);
      this.doFilter();
    },
    formatDate(value) {
      return value ? dayjs(value).format("D MMM YYYY") : "";
    },
    parseDate(date) {
      if (!date) return null;
      return dayjs(date).format("YYYY-MM-DD");
    },
    viewNextPage(view, direction) {
      view.page += direction;

      this.questionnaire.documents
        .filter((x) =>
          x.expected_responses.some((r) => r[view.pageColumn] === view.page)
        )
        .forEach((d) => {
          let cols = 0;
          d.expected_responses.forEach((er) => {
            if (er[view.pageColumn] === view.page) cols++;
          });
          d.cols = cols * 2;
        });
    },
    fetchData() {
      if (this.isLoading) return;
      let possibleError = false;
      let stats = {
        docs: 0,
        docs_unassigned: 0,
        docs_not_started: 0,
        docs_in_progress: 0,
        docs_completed: 0,
        assignments: 0,
        assignments_not_started: 0,
        assignments_in_progress: 0,
        assignments_completed: 0,
      };

      axios
        .get("workflow/getQuestionnaireSummary/")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            let dTextFilters = this.filterS.filters.filter(
              (x) => x.columns?.length && x.level === "document"
            );
            let incrementCount = (subjects, column) => {
              subjects.forEach((x) => x[column]++);
            };
            resp.data.Data.forEach((q) => {
              q.docTypes = [];
              q.docStatuses = [];
              q.responders = [];
              q.reviewGroups = [];
              q.responseStatuses = [];
              q.viewSections.forEach((s) => {
                s.show = true;
              });
              q.documents.forEach((d) => {
                d.visible = true;
                d.cols = 0;
                dTextFilters.forEach((tf) => {
                  d["tf_" + tf.id] = [];
                  tf.columns.forEach((c) => {
                    d["tf_" + tf.id].push(
                      ...d[c]
                        .toLowerCase()
                        .split(" ")
                        .filter((x) => x)
                    );
                  });
                });
                let er_stats = [];
                d.expected_responses.forEach((er) => {
                  er.visible = true;
                  let stat = er_stats.find((x) => x.status === er.status);
                  if (!stat) {
                    stat = {
                      status: er.status,
                      count: 0,
                      class:
                        er.status === "Completed"
                          ? "responder-completed"
                          : er.status === "Not Started"
                          ? "responder-not-started"
                          : "responder-in-progress",
                    };
                    er_stats.push(stat);
                  }
                  stat.count++;
                });
                d.isUnassigned =
                  d.expected_responses[0].status === "Unassigned";
                d.expected_response_text = `${
                  d.isUnassigned ? 0 : d.expected_responses.length
                } Evaluation${
                  d.expected_responses.length > 1 || d.isUnassigned ? "s" : ""
                }`;
                d.expected_response_stats = er_stats;

                let rg = q.reviewGroups.find(
                  (x) => x.review_group_id === d.review_group_id
                );
                if (!rg) {
                  rg = JSON.parse(JSON.stringify(stats));
                  rg.review_group_id = d.review_group_id;
                  rg.review_group_name = d.review_group_name || "Not in group";
                  rg.statuses = [];
                  q.reviewGroups.push(rg);
                }

                let dt = q.docTypes.find(
                  (x) =>
                    x.doc_type === d.doc_type &&
                    x.lifecycle_name === d.lifecycle_name
                );
                if (!dt) {
                  dt = JSON.parse(JSON.stringify(stats));
                  dt.doc_type = d.doc_type;
                  dt.lifecycle_name = d.lifecycle_name;
                  dt.statuses = [];
                  q.docTypes.push(dt);
                }
                let stat = dt.statuses.find(
                  (x) => x.doc_status === d.doc_status
                );
                if (!stat) {
                  stat = JSON.parse(JSON.stringify(stats));
                  stat.doc_status = d.doc_status;
                  dt.statuses.push(stat);
                  if (
                    !q.docStatuses.find((x) => x.doc_status === d.doc_status)
                  ) {
                    q.docStatuses.push(stat);
                  }
                }
                let rgStat = rg.statuses.find(
                  (x) => x.doc_status === d.doc_status
                );
                if (!rgStat) {
                  rgStat = JSON.parse(JSON.stringify(stats));
                  rgStat.doc_status = d.doc_status;
                  rg.statuses.push(rgStat);
                }
                d.expected_responses.forEach((er) => {
                  er.responders.forEach((r) => {
                    let responder = q.responders.find(
                      (x) => x.user_id === r.user_id
                    );
                    if (!responder)
                      q.responders.push(JSON.parse(JSON.stringify(r)));
                    let rg = q.reviewGroups.find(
                      (x) => x.review_group_id === r.review_group_id
                    );
                    if (!rg)
                      q.reviewGroups.push({
                        review_group_id: r.review_group_id,
                        review_group_name:
                          r.review_group_name || "Not in group",
                      });
                  });
                  let respStatus = q.responseStatuses.find(
                    (x) => x.status === er.status
                  );
                  if (!respStatus)
                    q.responseStatuses.push({ status: er.status });
                });
                let subjects = [dt, stat, rg, rgStat];
                incrementCount(subjects, "docs");
                if (d.expected_responses[0].responders.length === 0) {
                  incrementCount(subjects, "docs_unassigned");
                } else {
                  if (!d.expected_responses.some((x) => x.response)) {
                    incrementCount(subjects, "docs_not_started");
                  } else {
                    if (
                      d.expected_responses.every(
                        (x) => x.response && x.response.isCompleted
                      )
                    ) {
                      incrementCount(subjects, "docs_completed");
                    } else {
                      incrementCount(subjects, "docs_in_progress");
                    }
                    d.expected_responses.forEach((er) => {
                      incrementCount(subjects, "assignments");
                      if (!er.response) {
                        incrementCount(subjects, "assignments_not_started");
                      } else if (er.response.isCompleted) {
                        incrementCount(subjects, "assignments_completed");
                      } else {
                        incrementCount(subjects, "assignments_in_progress");
                      }
                    });
                  }
                }
              });
            });
            this.questionnaires = resp.data.Data;
            this.filter.data_capture_type_id =
              this.questionnaires[0].data_capture_type_id;
            this.applyFilter();
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    applyFilter() {
      this.questionnaire = this.questionnaires.find(
        (x) => x.data_capture_type_id === this.filter.data_capture_type_id
      );
      let dListFilters = this.filterS.filters.filter(
        (x) => x.values.length && x.level === "document"
      );
      let dTextFilters = this.filterS.filters
        .filter((x) => x.value && x.columns?.length && x.level === "document")
        .map((x) => {
          x.textValues = x.value
            .toLowerCase()
            .split(" ")
            .filter((v) => v);
          return x;
        });
      let rListFilters = this.filterS.filters.filter(
        (x) => x.values.length && x.level === "response"
      );
      let pListFilters = this.filterS.filters.filter(
        (x) => x.values.length && x.level === "responder"
      );
      this.filterS.active =
        dListFilters.length ||
        dTextFilters.length ||
        rListFilters.length ||
        pListFilters.length;
      this.questionnaire.documents.forEach((d) => {
        d.visible =
          (dListFilters.length === 0 ||
            dListFilters.every((f) =>
              f.values.some((x) => x === d[f.itemValue])
            )) &&
          (dTextFilters.length === 0 ||
            dTextFilters.every((f) =>
              f.textValues.every((fv) =>
                d["tf_" + f.id].some((v) => v.indexOf(fv) >= 0)
              )
            ));
        if (d.visible) {
          d.expected_responses.forEach((er) => {
            er.visible =
              rListFilters.length === 0 ||
              rListFilters.every((f) =>
                f.values.some((x) => x === er[f.itemValue])
              );
            if (er.visible && pListFilters.length) {
              er.visible = pListFilters.every((f) =>
                f.values.some((x) =>
                  er.responders.some((r) => r[f.itemValue] === x)
                )
              );
            }
          });
          if (d.expected_responses.every((er) => !er.visible))
            d.visible = false;
        }
      });
      this.setUpPaging();
    },
    setUpPaging() {
      let dCount = 0;
      let rCount = 0;
      this.documentView.pageCount = 0;
      this.documentView.page = 0;
      this.documentView.docCount = 0;
      this.responseView.pageCount = 0;
      this.responseView.page = 0;
      this.responseView.docCount = 0;
      this.responseView.respCount = 0;
      this.questionnaire.viewSections.forEach((s) => {
        s.questions.forEach((qu) => {
          qu.answers.forEach((a) => (a.respPage = 0));
          qu.pageAnswers = [];
        });
      });
      let sort =
        this.sort.by === "title"
          ? (a, b) => {
              let aName = a.doc_name.toLowerCase();
              let bName = b.doc_name.toLowerCase();
              return aName > bName ? 1 : bName > aName ? -1 : 0;
            }
          : (a, b) =>
              a.reference > b.reference
                ? 1
                : b.reference > a.reference
                ? -1
                : 0;
      this.questionnaire.documents.sort(sort);
      this.questionnaire.documents.forEach((d) => {
        let docDone = false;
        d.expected_responses.forEach((er) => {
          er.respPage = 0;
          if (!er.visible || !d.visible) {
            er.docPage = 0;
          } else {
            dCount++;
            er.docPage =
              parseInt((dCount - 1) / this.documentView.pageSize) + 1;
            this.documentView.pageCount = er.docPage;
            this.documentView.page = 1;
            if (!docDone) this.documentView.docCount++;
            if (!d.expected_responses.every((r) => r.responders.length === 0)) {
              rCount++;
              er.respPage =
                parseInt((rCount - 1) / this.responseView.pageSize) + 1;
              this.responseView.pageCount = er.respPage;
              this.responseView.page = 1;
              if (!docDone) {
                this.responseView.docCount++;
              }
              this.responseView.respCount++;
              this.questionnaire.viewSections.forEach((s) => {
                s.questions.forEach((qu) => {
                  let answer = qu.answers.find(
                    (x) => x.response_id === er.response_id
                  );
                  if (answer) answer.respPage = er.respPage;
                  qu.pageAnswers.push(answer);
                });
              });
            }
            docDone = true;
          }
        });
      });
      this.viewNextPage(this.documentView, 0);
      this.viewNextPage(this.responseView, 0);
    },
    saveFilters() {
      if (!this.newFilterKey) return;

      const idx = this.savedFilters.findIndex(
        (f) => f.key === this.newFilterKey
      );
      if (idx > -1)
        this.savedFilters[idx] = {
          key: this.newFilterKey,
          ...this.getFilters(),
        };
      else
        this.savedFilters.push({
          key: this.newFilterKey,
          ...this.getFilters(),
        });

      this.$loginState.saveSetting("twpSavedFilters", this.savedFilters);
      this.newFilterKey = "";
    },
    deleteSavedFilter(f) {
      this.savedFilters.splice(this.savedFilters.indexOf(f), 1);
      this.$loginState.saveSetting("twpSavedFilters", this.savedFilters);
    },
    restoreFilters(savedFilters) {
      if (savedFilters) {
        const filters = JSON.parse(JSON.stringify(savedFilters));
        this.filterText = filters.filterText || "";
        // this.taskDueDateRange = filters.taskDueDateRange || [];
        // this.docStatusIncluded = filters.docStatusIncluded || [];
        // this.docTypeIncluded = filters.docTypeIncluded || [];
        // this.docScoresIncluded = filters.docScoresIncluded || [];
        // this.recruitersIncluded = filters.recruitersIncluded || [];
        // this.hiringManagersIncluded = filters.hiringManagersIncluded || [];
        // this.creatorsIncluded = filters.creatorsIncluded || [];
        // this.selectedLanguageFilter = this.translateOptions.find(o => o.value == filters.languageFilter) || this.selectedLanguageFilter;
        // this.doFilter();
        // this.currentFilterKey = filters.key;
        // this.isFavFilter = true;
        // this.$nextTick(() => {
        //   this.preSelectedHierarchyFilters = filters.hierarchyFilters || [];
        //   this.preSelectedClassifierFilters = filters.classifierFilters || [];
        // });
      }
    },
    changefilterText(val) {
      this.tempFilterText = val;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => this.triggerFilter(), 200);
    },
    showFilterField() {
      this.showSearch = true;
      this.$nextTick(() => {
        if (this.$refs.filterField) this.$refs.filterField.$refs.input.focus();
      });
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
      this.showSearch = false;
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    clearDateFilter() {
      this.taskDueDateRange.splice(0);
      this.taskDueDateRangeMenu = false;
      this.doFilter();
    },
    doDateFilter() {
      this.$refs.menu.save(this.taskDueDateRange);
      this.doFilter();
    },
    doStatusFilter() {
      //his.$refs.menu.save(this.taskDueDateRange);
      this.doFilter();
    },
    getFilters() {
      const filters = this.filters || {};

      const params = {
        filterText: (this.filterText || filters.filterText || "")
          .trim()
          .toLowerCase(),
        mine: filters.mine || false,
        taskDueDateRange: this.showFilters
          ? this.taskDueDateRange
          : filters.taskDueDateRange,
        docStatusIncluded: this.showFilters
          ? this.docStatusIncluded
          : filters.docStatusIncluded,
        docTypeIncluded:
          this.filters?.docTypeIncluded ||
          (this.showFilters ? this.docTypeIncluded : filters.docTypeIncluded),
      };

      return JSON.parse(JSON.stringify(params));
    },
    doFilter() {
      if (this.isLoading) return;

      if (this.isFavFilter) {
        this.currentFilterKey = this.$route.meta.text.replace("Tasks", "");
        this.isFavFilter = false;
      }

      //utils.setDefaultStatuses(this.$loginState, this.docStatusIncluded);

      this.filteredItems = this.filter(this.getFilters());

      if (this.hasTasks) this.$emit("tasksLoaded");

      this.currentPage = 1;
      this.$loginState.saveSetting("twpDocTypes", this.docTypeIncluded);
    },
    filter(params) {
      let tasks = JSON.parse(JSON.stringify(this.tasks));
      this.hasTasks = tasks.length > 0;

      if (this.taskStatusFilter === "2") {
        tasks = tasks.filter((t) => t.mine);
      } else if (this.taskStatusFilter === "3") {
        tasks = tasks.filter((t) => !t.user_id_assigned);
      }

      if (params.mine) tasks = tasks.filter((doc) => doc.mine);

      if (params.taskDueDateRange && params.taskDueDateRange.length) {
        const fromDate = dayjs(params.taskDueDateRange[0]);
        const toDate =
          params.taskDueDateRange.length > 1
            ? dayjs(params.taskDueDateRange[1])
            : undefined;

        tasks = tasks.filter((d) => {
          const due = dayjs(d.due_date);
          return due.isAfter(fromDate) && (!toDate || due.isBefore(toDate));
        });
      }

      if (params.docTypeIncluded && params.docTypeIncluded.length) {
        tasks = tasks.filter((d) =>
          params.docTypeIncluded.find((s) => s.docType === d.doc_type)
        );
      }

      if (params.filterText) {
        let search = params.filterText
          .split(" ")
          .filter((x) => x.length)
          .map((x) => x);

        tasks = tasks.filter((d) =>
          search.every((s) => d.match.indexOf(s, 0) >= 0)
        );
        tasks.forEach((d) => {
          search.forEach((s) => {
            [
              "doc_name",
              "doc_type",
              "reference",
              "user_name_assigned",
              "user_name_completed",
              "task_type",
            ].forEach((col) => {
              let val = d[col] || "",
                posMs = val.indexOf("<mark>"),
                posMe = val.indexOf("</mark>"),
                posPrev = 0,
                output = "";
              while (posMs >= 0) {
                if (posMs - posPrev > 0) {
                  output += val
                    .substring(posPrev, posMs)
                    .replace(
                      new RegExp(s, "gi"),
                      (match) => `<mark>${match}</mark>`
                    );
                }
                output += val.substring(posMs, posMe + 7);
                posPrev = posMe + 7;
                posMs = val.indexOf("<mark>", posMe);
                posMe = val.indexOf("</mark>", posMs);
              }
              if (posPrev < val.length) {
                output += val
                  .substring(posPrev, val.length)
                  .replace(
                    new RegExp(s, "gi"),
                    (match) => `<mark>${match}</mark>`
                  );
              }
              d[col] = output;
            });
          });
        });
      }
      tasks.forEach((doc, index) => (doc.index = index + 1));
      return tasks;
    },
    selectRow(event, item) {
      if (this.rowClickAction === "menu") {
        this.openContextMenu(event, item);
      } else if (this.rowClickAction === "openTask") {
        this.openTask(item);
      } else {
        this.openDocument(item);
      }
    },
    openDocument(item) {
      this.contextMenu = false;
      this.$emit("openDocument", {
        doc_id: item.doc_id,
        tmpl_id: item.tmpl_id,
        system_number: utils.removeTags(item.reference),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        viewAction: item.viewAction,
      });
    },
    setPageSize(value) {
      if (this.pageSize === 0) {
        if (this.cardView) {
          localStorage.setItem("twpMaxCards", value);
          this.maxCards = value;
        } else {
          localStorage.setItem("dtRPP", value);
          this.dtItemsPerPage = value;
        }
      }
    },
    exportToCSV() {
      this.csvHeaders = [""];
      let data =
        this.csvHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.filteredItems.forEach((d) => {
        data +=
          this.csvHeaders
            .map((h) => '"' + utils.removeTags(d[h.value]) + '"')
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Tasks.csv", "text/csv;encoding:utf-8");
    },
    openContextMenu(e, item) {
      this.currentTask = item;
      e.preventDefault();
      this.contextMenu = false;
      this.posX = e.clientX;
      this.posY = e.clientY;
      this.$nextTick(() => {
        this.contextMenu = true;
      });
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 48px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
    &.collapsed {
      left: 20px;
    }
  }

  .right-col {
    padding: 12px 0;
    transition: padding 200ms;
    &.fullscreen {
      padding: 12px;
    }
    &.hierarchiesCollapsed {
      padding-left: 30px;
    }
    &.hierarchiesPinned {
      padding-left: 410px;
    }
  }

  @media (max-width: 600px) {
    .right-col {
      padding-left: 0;
    }
  }
}

.filter-col {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 50%;
  @media (min-width: 960px) {
    max-width: 25%;
  }
  @media (min-width: 1264px) {
    max-width: 14%;
  }
}

.header-buttons {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
}

.btn-background {
  height: 44px;
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}

.headerCount {
  display: flex;
  align-items: center;
  padding: 0 60px 0 0;
  margin: -45px 0 15px 0;
  span {
    margin: 0 20px;
  }
}

.mainPane > .v-card {
  border-radius: 10px;
}

::v-deep mark {
  color: inherit;
  border-radius: 5px;
  background-color: rgba(#0372ee, 0.3);
}

::v-deep .v-select__selections {
  overflow: hidden;
  white-space: nowrap;
  flex-flow: nowrap;
}

.fixedFooter {
  @media (max-width: 600px) {
    .docList .footer-actions {
      padding-left: 15px;
    }
  }

  .left-col {
    height: calc(100vh - 103px) !important;
  }
  .mainPane {
    padding-bottom: 56px;
  }

  .docList {
    .footer-actions {
      width: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 4;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.multipane {
  .multipane-resizer {
    margin: 0;
    left: 0;
    position: relative;
    height: 20px;
    &:before {
      display: block;
      content: "";
      width: 40%;

      position: absolute;
      left: 30%;
      margin-top: 5px;
      margin-left: -20px;
      border: 2px solid #ccc;
      border-radius: 2px;
    }
    &:hover {
      &:before {
        border-color: #999;
      }
    }
  }

  .mainPane {
    padding-top: 12px;
    padding-right: 12px;
    min-height: 100px;
    max-height: 100%;
    &.fixedHeader {
      padding-right: 0;
      overflow: hidden;
    }
  }
  .previewPane {
    width: 100%;
    height: 100px;
    display: flex;
    flex-grow: 1;
    position: relative;
    .actions {
      position: absolute;
      top: 0;
      right: 25px;
      width: 44px;
      button {
        z-index: 1;
        opacity: 0.8;
      }
    }

    .docPreview {
      width: 100%;
      min-height: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 12px 85px 0 85px;
      //height: calc(50vh - 35px);
      //overflow-y: auto;
    }
  }
  &.hasPreview {
    height: calc(100vh - 105px);
    .mainPane {
      height: 100%;
      overflow-x: hidden;
      padding-bottom: 12px;
    }
  }
  // &:not(.hasPreview) {
  //   .mainPane {
  //     height: 100%;
  //     overflow: unset;
  //   }
  // }
}

.v-application.theme--light {
  .previewPane {
    ::v-deep .docPreview {
      background-color: $background-color-light;
      > .v-card {
        box-shadow: 0 0 4px 0 #b5b5b5;
      }
    }
  }
}

.v-application.theme--dark {
  .previewPane {
    ::v-deep .docPreview {
      > .v-card {
        border: 1px solid #b5b5b5;
      }
    }
  }
}

.docList {
  .footer-actions {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 55px;

    div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
    }
  }

  .v-card.doc-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .v-card.on-hover.theme--light .v-list {
    background-color: #f7f7f7;
  }

  .v-card.on-hover.theme--dark .v-list {
    background-color: #272727;
  }

  .job-description {
    font-size: 0.75rem;
    overflow: hidden;
    display: flow-root;
    position: relative;
    height: 12em;
    line-height: 1.8;
  }

  .job-description:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1) 50%);
  }

  .v-card.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#1e1e1e, 0),
        rgba(#1e1e1e, 1) 50%
      );
    }
  }

  .v-card.on-hover {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#f7f7f7, 0),
        rgba(#f7f7f7, 1) 50%
      );
    }
  }

  .v-card.on-hover.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#272727, 0),
        rgba(#272727, 1) 50%
      );
    }
  }

  .data-content {
    margin-top: -5px;
    padding-top: 0;
  }
  .data-content-2 {
    margin-top: -7px;
    min-height: auto !important;
    max-height: auto;
    padding-bottom: 0;
  }
  .data-label {
    font-size: 0.6rem;
    padding: 0 5px 0 16px;
    color: darkgray;
  }
  .data-value {
    font-size: 0.9rem;
  }
  .data-value-2 {
    font-size: 0.75rem;

    padding-bottom: 10px;
  }

  .doc-card .status-chip {
    background-color: transparent !important;
    :before {
      content: "\2022";
      margin-right: 0.5em;
    }
  }

  ::v-deep .v-data-table {
    tbody > tr,
    tbody > tr span {
      cursor: pointer;
    }
    tr td:last-child {
      padding: 0 10px 0 0;
    }
    .status-chip {
      height: 2.35em;
      min-width: 7.2em;
      display: inline-flex;
      justify-content: center;
      font-size: 0.5625rem;
    }
  }
}

::v-deep {
  .customFlag {
    transform: scale(0.35) !important;
    -ms-transform: scale(0.35) !important;
    -webkit-transform: scale(0.35) !important;
    -moz-transform: scale(0.35) !important;
  }
}

.recruiterSelect::v-deep {
  input {
    cursor: pointer;
  }
}

.recruiterChip.theme--light {
  background-color: #f4f9fd !important;
  .v-icon {
    color: #7d8592;
  }
}

.recruitersMenu {
  min-height: 150px;
  .v-chip-group {
    min-height: 30px;
  }
  ::v-deep .v-tabs-slider-wrapper {
    left: auto !important;
    right: 0;
  }
}

.totals {
  text-align: right;
  padding-right: 45%;
}
.total-heading,
.total-heading-top {
  text-align: center;
}
.total-heading-top {
  border-bottom: solid gray 1px;
}
.total-heading,
.total-heading-top,
.column-heading {
  font-weight: 600;
}
.row-heading,
.row-heading-indent {
  //   padding-left: 5%;
  text-align: left;
  font-weight: 500;
}
.row-heading-indent {
  margin-left: 15%;
  display: list-item;
  list-style: disc;
}
.section-heading {
  background-color: #f5f5f5;
  cursor: pointer;
}
.document {
  //   min-width: 200px;
  border: solid 1px gray;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  width: 100%;
}
.response {
  border: solid 1px gray;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}
.responder {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-size: 11px;
  padding-top: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.responder i {
  padding-right: 6px;
}
.responder-not-started {
  background-color: #f44336; /* red */
  color: white;
}
.responder-completed {
  background-color: #7fc182; /* green */
  color: white;
}
.responder-in-progress {
  background-color: #ffc107; /* amber */
  color: black;
}
.responder-not-started i,
.responder-completed i {
  color: white;
}
.responder-stat {
  //   border: solid 1px white;
  //   border-radius: 5px;
  display: inline-flex;
  text-align: center;
  height: 10px;
  font-size: 11px;
  padding-top: 1px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 70%;
}
.expected-responses,
.status-label {
  font-size: 12px;
  color: gray;
  font-weight: bold;
}
.expected-responses {
  border-top: 1px solid lightgray;
  padding-top: 10px;
}
.answer {
  border: 1px solid green;
  border-radius: 10px;
  text-align: center;
  min-height: 24px;
}
.doc-reference {
  font-size: 12px;
  font-weight: normal;
}
.doc-title {
  font-size: 14px;
  font-weight: bold;
  min-height: 50px;
}
.doc-status {
  font-size: 11px;
  border: 1px solid green;
  border-radius: 5px;
  text-align: center;
  min-height: 20px;
  float: right;
  padding: 0px 5px;
}
</style>
