<template>
  <div>
    <v-menu
      v-model="show"
      absolute
      :position-x="context.posX"
      :position-y="context.posY"
      offset-y
      :close-on-content-click="false"
      nudge-width="600"
    >
      <v-card max-height="700">
        <v-card-text v-if="busy">
          <v-row>
            <v-col cols="5"></v-col>
            <v-col>
              <v-progress-circular indeterminate size="50" color="grey">
              </v-progress-circular>
            </v-col>
            <v-col cols="5"></v-col>
          </v-row>
        </v-card-text>
        <v-list dense v-else-if="documents.length">
          <v-list-item>
            <v-list-item-title>{{ title }} </v-list-item-title>
            <v-list-item-action v-if="context.showSuggestions"
              ><v-chip
                label
                dark
                color="green darken-2"
                @click="compareDocDetailsFullSuggestions(documents)"
                ><v-icon title="Job Family Categorisation"
                  >mdi-lightbulb-on</v-icon
                ></v-chip
              ></v-list-item-action
            >
            <v-list-item-action style="margin-left: 4px"
              ><v-chip
                label
                dark
                color="blue darken-2"
                @click="compareDocDetailsFull(documents)"
                ><v-icon title="Compare documents in full view"
                  >mdi-content-duplicate</v-icon
                ></v-chip
              ></v-list-item-action
            >
            <v-list-item-action
              style="margin-left: 4px"
              v-if="showAttributeCompare"
              ><v-chip
                label
                color="blue darken-2"
                dark
                @click="$emit('editDocs', documents)"
              >
                <v-icon title="Show Details">mdi-view-week</v-icon></v-chip
              ></v-list-item-action
            ><v-list-item-action style="margin-left: 4px"
              ><v-chip
                label
                dark
                color="blue darken-2"
                :disabled="documents.length > 15"
                @click="compareDocDetails(documents)"
                ><v-icon title="Compare documents in Side-by-side view"
                  >mdi-compare</v-icon
                ></v-chip
              ></v-list-item-action
            >
          </v-list-item>
          <v-list-item v-for="(d, di) in documents" :key="'doc' + di">
            <v-list-item-title
              @click="$emit('openDocument', d)"
              style="cursor: pointer"
              ><v-chip label outlined small
                ><v-icon left small>mdi-file-document-edit-outline</v-icon
                >{{ d.system_number }}</v-chip
              >
              {{ d.doc_name
              }}<DocStatus
                :doc_id="d.doc_id"
                sideAddendum
                xSmall
                style="margin-left: 10px"
              ></DocStatus></v-list-item-title
            ><v-list-item-action
              ><v-badge
                color="red"
                overlap
                v-if="d.docMatchesContent.length"
                :content="d.docMatchesContent.length"
              >
                <v-icon
                  color="teal"
                  title="click to compare docments with similar content"
                  @click="displayMatches(d, 'docMatchesContent')"
                  >mdi-content-duplicate</v-icon
                >
              </v-badge> </v-list-item-action
            ><v-list-item-action
              ><v-badge
                color="red"
                overlap
                v-if="d.docMatchesName.length"
                :content="d.docMatchesName.length"
              >
                <v-icon
                  color="indigo"
                  title="click to compare docments with same title but different content"
                  @click="displayMatches(d, 'docMatchesName')"
                  >mdi-content-duplicate</v-icon
                >
              </v-badge>
            </v-list-item-action>
            <!-- <v-list-item-action v-if="context.showSuggestions"
              ><v-icon
                @click="suggestJobFamily"
                title="Click to see job family suggestions"
                >mdi-lightbulb-on</v-icon
              > </v-list-item-action
            > -->
            <v-list-item-action
              ><v-icon @click="openDocActionsMenuContext($event, d)"
                >mdi-dots-vertical</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-else-if="matchSets.length">
          <v-list v-if="context.title"
            ><v-list-item
              ><h4>{{ context.title }}</h4></v-list-item
            ></v-list
          >
          <v-list v-for="(s, si) in matchSets" :key="'set' + si">
            <v-list-item
              ><v-list-item-title>{{ s.title }}</v-list-item-title>
              <v-list-item-action
                v-if="context.showSuggestions"
                style="margin-left: 4px"
                ><v-chip
                  label
                  dark
                  color="green darken-2"
                  @click="compareDocDetailsFullSuggestions(s.documents)"
                  ><v-icon title="Job Family Categorisation"
                    >mdi-lightbulb-on</v-icon
                  ></v-chip
                ></v-list-item-action
              >
              <v-list-item-action style="margin-left: 4px"
                ><v-chip
                  label
                  dark
                  color="blue darken-2"
                  @click="compareDocDetailsFull(s.documents)"
                  ><v-icon title="Compare documents in full view"
                    >mdi-content-duplicate</v-icon
                  ></v-chip
                ></v-list-item-action
              >
              <v-list-item-action
                style="margin-left: 4px"
                v-if="showAttributeCompare"
                ><v-chip
                  label
                  color="blue darken-2"
                  dark
                  @click="$emit('editDocs', s.documents)"
                >
                  <v-icon title="Show Details">mdi-view-week</v-icon></v-chip
                ></v-list-item-action
              ><v-list-item-action style="margin-left: 4px"
                ><v-chip
                  label
                  dark
                  color="blue darken-2"
                  :disabled="s.documents.length > 15"
                  ><v-icon
                    @click="compareDocDetails(s.documents)"
                    title="Compare documents in Side-by-side view"
                    >mdi-compare</v-icon
                  ></v-chip
                ></v-list-item-action
              ></v-list-item
            >
            <v-list-item
              v-for="(d, di) in s.documents"
              :key="'set' + si + 'doc' + di"
            >
              <v-list-item-icon v-if="percentColumn !== 'match_pct'"
                ><v-avatar
                  v-if="d[percentColumn]"
                  :color="
                    percentColumn === 'match_pct_content' ? 'teal' : 'indigo'
                  "
                  size="30"
                >
                  <span class="white--text" style="font-size: 12px">{{
                    d[percentColumn] + "%"
                  }}</span>
                </v-avatar>
              </v-list-item-icon>
              <div v-else>
                <v-list-item-icon
                  ><v-avatar
                    v-if="d.match_pct_content"
                    color="teal"
                    size="30"
                    title="Similar content"
                  >
                    <span class="white--text" style="font-size: 12px">{{
                      d.match_pct_content + "%"
                    }}</span>
                  </v-avatar>
                  <v-avatar
                    v-else-if="d.match_pct_name"
                    color="indigo"
                    size="30"
                    title="Same title different content"
                  >
                    <span class="white--text" style="font-size: 12px">{{
                      d.match_pct_name + "%"
                    }}</span>
                  </v-avatar>
                </v-list-item-icon>
              </div>
              <v-list-item-title
                @click="$emit('openDocument', d)"
                style="cursor: pointer"
                ><v-chip label outlined small
                  ><v-icon left small>mdi-file-document-edit-outline</v-icon
                  >{{ d.system_number }}</v-chip
                >
                {{ d.doc_name
                }}<DocStatus
                  :doc_id="d.doc_id"
                  sideAddendum
                  xSmall
                  style="margin-left: 10px"
                ></DocStatus
              ></v-list-item-title>
              <!-- <v-list-item-action v-if="context.showSuggestions"
                ><v-icon
                  @click="suggestJobFamily"
                  title="Click to see job family suggestions"
                  >mdi-lightbulb-on</v-icon
                > </v-list-item-action
              > -->
              <v-list-item-action
                ><v-icon @click="openDocActionsMenuContext($event, d)"
                  >mdi-dots-vertical</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-menu>
    <v-dialog v-model="fullCompareView.show" style="background-color: white">
      <v-card>
        <v-card-title>
          Document Comparison
          <v-spacer></v-spacer>
          <v-switch
            v-model="fullCompareView.highlightChanges"
            label="Highlight Differences"
            class="mr-4"
          ></v-switch>
          <v-btn
            icon
            large
            class="btn-background"
            @click="fullCompareView.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DocumentVersionCompare
            class="pt-6 px-2"
            style="height: 100%"
            v-if="fullCompareView.show"
            :compareList="fullCompareView.items"
            :highlightChanges="fullCompareView.highlightChanges"
            :showHierarchySuggestions="fullCompareView.showHierarchySuggestions"
            hideCompareVersion
            multiDoc
            showWorkflowActions
            @openDocument="$emit('openDocument', $event)"
            @copyDocument="$emit('copyDocument', $event)"
          >
          </DocumentVersionCompare>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sideBySide.show">
      <DocumentSideBySide
        v-if="sideBySide.show"
        :items="sideBySide.items"
        :isDialogue="true"
        @close="sideBySide.show = false"
        @openContext="openDocActionsMenuContext"
      ></DocumentSideBySide>
    </v-dialog>
    <DocActionsMenu
      :context="docActionsMenuContext"
      @openDocument="$emit('openDocument', docActionsMenuContext.document)"
      @copyDocument="$emit('copyDocument', docActionsMenuContext.document)"
    ></DocActionsMenu>
  </div>
</template>

<script>
import utils from "@/common/utils.js";
import DocStatus from "@/components/common/DocStatus";
import DocumentVersionCompare from "@/components/audit/cDocumentVersionCompare";
import DocumentSideBySide from "@/components/cDocumentSideBySide.vue";
import DocActionsMenu from "@/components/common/DocActionsMenu";

export default {
  name: "DocListMenu",
  props: {
    context: Object,
    showAttributeCompare: Boolean,
  },
  data: function () {
    return {
      utils: utils,
      documents: [],
      matchSets: [],
      title: null,
      show: false,
      busy: false,
      percentColumn: "match_pct_content",
      fullCompareView: {
        show: false,
        highlightChanges: false,
        showHierarchySuggestions: false,
        items: [],
      },
      sideBySide: { show: false, items: [] },
      suggestions: { show: false, items: [] },
      docActionsMenuContext: {
        show: false,
        busy: false,
        document: null,
        posX: 0,
        posY: 0,
      },
    };
  },
  components: {
    DocStatus,
    DocumentVersionCompare,
    DocumentSideBySide,
    DocActionsMenu,
  },
  watch: {
    "context.documents": function (newVal) {
      if (newVal) this.init();
    },
    "context.show": function (newVal) {
      if (newVal) this.init();
    },
  },
  mounted() {},
  created() {},
  computed: {},
  methods: {
    init() {
      //   this.document = this.$store.getters["docs/docsList"].find(
      //     (x) => x.doc_id === this.context.docs[0].doc_id
      //   );
      let matchSets = [];
      if (this.context.matchColumn) {
        let matchColumn = this.context.matchColumn;
        if (matchColumn === "match_count_name") {
          matchColumn = "docMatchesName";
          this.percentColumn = "match_pct_name";
        } else if (matchColumn === "match_count_content") {
          matchColumn = "docMatchesContent";
          this.percentColumn = "match_pct_content";
        } else this.percentColumn = "match_pct";
        // group doc sets together
        this.context.documents
          .filter((d) => d[matchColumn].length)
          .forEach((d) => {
            let set = matchSets.find((s) =>
              s.docs.some((x) => x.doc_id === d.doc_id)
            );
            if (!set) {
              set = {
                setNo: matchSets.length + 1,
                docs: [
                  {
                    doc_id: d.doc_id,
                    doc_name: d.doc_name,
                    doc_status: d.doc_status,
                    system_number: d.system_number,
                  },
                ],
              };
              matchSets.push(set);
            }
            d[matchColumn]
              .filter((dm) => !set.docs.some((x) => x.doc_id === dm.doc_id))
              .forEach((dm) => set.docs.push(dm));
          });
        matchSets = matchSets.map((s) => {
          return {
            title: `Match Set ${matchSets.length > 1 ? s.setNo : ""} - ${
              s.docs.length
            } ${
              matchColumn === "docMatchesName" ? "same titles" : "similar documents"
            } identified`,
            documents: s.docs,
            doc_ids: s.docs,
          };
        });
      }
      this.matchSets = matchSets;
      this.documents = matchSets.length ? [] : this.context.documents;
      this.title = `${this.documents.length} ${this.context.documentType}${
        this.documents.length > 1 ? "s" : ""
      } : (click to open document)`;

      this.show = true;
    },
    compareDocDetailsFull(documents) {
      this.fullCompareView.show = true;
      this.fullCompareView.showHierarchySuggestions = false;
      this.fullCompareView.items = documents;
    },
    compareDocDetailsFullSuggestions(documents) {
      this.fullCompareView.show = true;
      this.fullCompareView.showHierarchySuggestions = true;
      this.fullCompareView.items = documents;
    },
    compareDocDetails(items) {
      this.sideBySide.show = true;
      this.sideBySide.items = items || this.compareItems;
    },
    getSuggestions(items) {
      this.suggestions.show = true;
      this.suggestions.items = items || this.compareItems;
    },
    openDocActionsMenuContext(event, document) {
      event.preventDefault();
      if (document.isDocument) {
        this.docActionsMenuContext.document = this.getDocByRef(document.value);
      } else {
        this.docActionsMenuContext.document = this.getDocById(document.doc_id);
      }
      this.docActionsMenuContext.posX = event.clientX;
      this.docActionsMenuContext.posY = event.clientY;
      this.docActionsMenuContext.show = false;
      this.$nextTick(() => {
        this.docActionsMenuContext.show = true;
      });
    },
    displayMatches(document, matchType) {
      let docs = document[matchType];
      docs.unshift({ doc_id: document.doc_id });
      this.compareDocDetailsFull(docs);
    },
    getDocByRef(reference) {
      return this.$store.getters["docs/docsList"].find(
        (x) => x.system_number === reference
      );
    },
    getDocById(doc_id) {
      return this.$store.getters["docs/docsList"].find(
        (x) => x.doc_id === doc_id
      );
    },
    suggestJobFamily() {
      alert(
        "Suggestions from Tim's service soon will appear here for selection"
      );
    },
  },
};
</script>
<style>
</style>