<template>
  <div>
    <v-dialog v-model="dialog" persistent width="800">
      <v-card>
        <v-card-title class="px-8">
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon large class="btn-background" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <Loading :isVisible="isLoading" />
          <v-container>
            <v-row dense v-if="currentNode.length">
              <v-col>
                <HierarchyTreeNode
                  :nodes="currentNode"
                  :viewLevel="viewLevel"
                ></HierarchyTreeNode>
              </v-col>
            </v-row>
            <v-row dense
              ><v-col> <v-divider></v-divider></v-col
            ></v-row>
            <v-row
              v-if="
                currentNode.length &&
                (mode === 'edit' || mode === 'add' || mode === 'rename')
              "
            >
              <v-col v-if="nodeDetails">
                <v-text-field
                  v-model="nodeDetails.new_name"
                  :label="nodeDetails.label"
                  dense
                  outlined
                  class="roundish"
                  :placeholder="mode === 'edit' ? 'UNCLASSIFIED' : ''"
                  :persistent-placeholder="mode === 'edit'"
                  clearable
                  :return-object="false"
                ></v-text-field> </v-col
              ><v-col cols="5"
                ><v-alert dense v-if="validatioError" color="warning">{{
                  validatioError
                }}</v-alert></v-col
              >
            </v-row>
            <v-row v-if="nodeDetails && showDescriptor">
              <v-col>
                <v-textarea
                  v-model="nodeDetails.new_description"
                  label="Description"
                  dense
                  outlined
                  class="roundish"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row
              v-if="
                mode === 'merge' && validatioError
              "
            >
              <v-col>
                <v-alert
                  border="top"
                  colored-border
                  type="error"
                  elevation="2"
                  class="pb-0 mb-0"
                >
                  A {{ nodeDetails.label }} cannot be merged into itself. Please
                  select a different {{ nodeDetails.label }} to merge with.
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="['move', 'merge', 'pick'].indexOf(mode) >= 0">
              <v-col v-if="mode === 'move'">
                <v-label dense outlined class="roundish">{{
                  pickText
                }}</v-label></v-col
              >
              <v-col v-if="mode === 'merge'">
                <v-label dense outlined class="roundish"
                  >Pick the {{ pickText }} to merge with</v-label
                >
              </v-col>
              <v-col v-if="showPicker"
                ><v-text-field
                  dense
                  hide-details
                  outlined
                  clearable
                  v-model="searchText"
                  @input="searchNodes"
                  placeholder="type to search..."
                ></v-text-field
              ></v-col>
            </v-row>

            <v-row
              style="max-height: 500px; overflow: auto"
              ref="scrollPicker"
              v-if="
                showPicker &&
                (mode === 'pick' ||
                  ((mode === 'move' || mode === 'merge') && currentNode.length))
              "
            >
              <v-col ref="scrollInner">
                <HierarchyTreeNode
                  :nodes="allNodes"
                  :viewLevel="viewLevel"
                  @pickNode="pickNode"
                ></HierarchyTreeNode>
              </v-col>
            </v-row>

            <v-row v-if="mode === 'delete'">
              <v-col>
                <v-alert
                  border="top"
                  colored-border
                  type="error"
                  elevation="2"
                  class="pb-0 mb-0"
                >
                  {{ alertMessage }}
                  <br />
                  <v-list style="max-width: 300px">
                    <v-list-item
                      v-for="node in affectedNodesSummary"
                      :key="'an' + node.description"
                    >
                      <v-list-item-content>
                        {{ node.description }}
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ node.affectedCount }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <div v-if="!invalid" class="pb-4">
                    Are you sure you want to continue?
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6 px-8" v-if="currentNode.length">
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn
            v-if="mode === 'delete'"
            color="error"
            outlined
            @click="save"
            :disabled="invalid || !!validatioError"
          >
            Delete
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="!isDirty || invalid || !!validatioError"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>

        <v-card-actions
          class="pb-6 px-8"
          v-else-if="mode === 'pick' && nodeDetails"
        >
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn
            color="primary"
            :disabled="!pickedNode || invalid || !!validatioError"
            @click="save"
          >
            Pick {{ nodeDetails.label }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import HierarchyTreeNode from "@/components/hierarchy/cHierarchyTreeNode";
import utils from "../../common/utils";

export default {
  name: "cAdminHierarchyNodeDetail",
  components: {
    HierarchyTreeNode,
  },
  props: {
    show: Boolean,
    node: Object,
    action: Object,
    mode: { type: String, default: "edit" },
    showAddNodeDescriptor: Boolean,
  },
  data: function () {
    return {
      spellCheckLanguages: ["us"],
      errorText: "",
      showErrorSnack: false,
      isLoading: false,
      snackColor: "error",
      snackTimeout: 4000,
      editedIds: [],
      editedName: "",
      nodeDetails: null,
      possibleParents: [],
      dialog: false,
      viewLevel: 999,
      allNodes: [],
      currentNode: [],
      affectedNodesSummary: [],
      pickedNode: null,
      pickText: null,
      searchText: "",
      showDescriptor: false,
      showPicker: false,
      moveData: null,
      invalid: false,
      alertMessage: false,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
      if (val) this.loadDetails();
    },
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchies: (state) => state.hierarchies.hierarchies,
    }),
    formTitle() {
      if (!this.nodeDetails) return "Edit Hierarchy";
      switch (this.mode) {
        case "merge":
          return `Merge ${this.nodeDetails.label}:`;
        case "add":
          return `Add ${this.nodeDetails.label}`;
        case "rename":
          return `Rename ${this.nodeDetails.label}:`;
        case "move":
          return this.moveData?.title;
        case "editDescriptor":
          return `Edit ${this.nodeDetails.label} Description:`;
        case "pick":
          return `${this.nodeDetails.label} Selector:`;
        case "delete":
          return `Delete ${this.nodeDetails.label}:`;
        case "edit":
        default:
          return `Edit ${this.nodeDetails.label}`;
      }
    },
    hierarchType() {
      if (!this.nodeDetails) return null;
      return this.hierarchies.find((ht) => ht.ht_id === this.nodeDetails.ht_id);
    },
    isDirty() {
      if (!this.nodeDetails) return false;
      if (this.mode === "move" || this.mode === "merge") return !this.invalid;

      return (
        this.nodeDetails.name !== this.nodeDetails.new_name ||
        (this.nodeDetails.description || "") !==
          (this.nodeDetails.new_description || "") ||
        this.nodeDetails.hierarchy_node_id !==
          this.nodeDetails.new_hierarchy_node_id // ||
        // (this.nodeDetails.parent &&
        //   this.nodeDetails.hierarchy_node_id_parent !==
        //     this.nodeDetails.parent.hierarchy_node_id)
      );
    },
    validatioError() {
      let node;
      let checkNodes;
      if (!this.nodeDetails) return false;
      switch (this.mode) {
        case "add":
          node = this.findNode(
            this.allNodes,
            this.nodeDetails.hierarchy_node_id
          );
          if (
            this.nodeDetails.new_name &&
            this.checkNameExists(node.nodes, this.nodeDetails.new_name)
          ) {
            return "This name already exists.";
          }
          return false;
        case "delete":
          break;
        case "move":
          return this.invalid ? "duplicate" : "";
        case "merge":
          return this.node.hierarchy_node_id === this.pickedNode.hierarchy_node_id;
        case "rename":
          if (!this.nodeDetails.new_name) return "Name required";
          node = this.findNode(
            this.allNodes,
            this.nodeDetails.hierarchy_node_id
          );
          if (node.hierarchy_node_id_parent)
            checkNodes = this.findNode(
              this.allNodes,
              node.hierarchy_node_id_parent
            ).nodes;
          else checkNodes = this.allNodes;
          if (this.checkNameExists(checkNodes, this.nodeDetails.new_name)) {
            return "This name already exists.";
          }
          break;
      }
      return "";
    },
  },
  created: function () {
    if (this.show) this.loadDetails();
  },
  updated() {
    this.$nextTick(function () {
      if (this.setScroll) {
        this.setPickerScroll();
      }
    });
  },
  methods: {
    loadDetails() {
      this.currentNode.splice(0, this.currentNode.length);
      if (this.node) {
        let possibleError = false;
        this.isLoading = true;
        axios
          .post(`hierarchy/HierarchyNodeStructure`, this.node)
          .then((resp) => {
            possibleError = true;
            let nodeDetails = JSON.parse(JSON.stringify(this.node));
            nodeDetails.new_hierarchy_node_id = nodeDetails.hierarchy_node_id;
            if (this.mode === "add") {
              nodeDetails.new_name = "";
              nodeDetails.new_description = "";
              nodeDetails.label = nodeDetails.nextLevel;
              this.showDescriptor = this.showAddNodeDescriptor;
            } else {
              nodeDetails.new_name = nodeDetails.name;
              this.showDescriptor =
                this.mode === "editDescriptor" && nodeDetails.descriptor;
              nodeDetails.new_description = nodeDetails.descriptor
                ? nodeDetails.descriptor.description
                : null;
            }
            this.nodeDetails = nodeDetails;
            this.invalid = false;

            const data = resp.data.Data;
            this.viewLevel = nodeDetails.level;
            this.allNodes = this.setUpTree(data.nodes);

            if (this.mode === "delete")
              this.affectedNodesSummary = this.buildAffectedNodesSummary(
                data.nodes,
                nodeDetails.hierarchy_node_id
              );

            this.dialog = true;
            this.isLoading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
            this.dialog = false;
          });
      } else {
        this.nodeDetails = null;
        return;
      }
    },
    setUpMove(nodes) {
      if (this.mode === "move") {
        this.invalid = false;
        let data = {
          title: `Move ${this.nodeDetails.label}:`,
          pickText: "",
          pickLevel: this.action.actionDef.newLevel - 1,
          newLevel: this.action.actionDef.newLevel,
          viewLevel: this.action.actionDef.newLevel,
          currentLevel: this.action.actionDef.currentLevel,
        };
        if (this.action?.actionDef) {
          let parent =
            this.action.actionDef.newLevel > 1
              ? this.hierarchType[
                  `level${this.action.actionDef.newLevel - 1}_name`
                ]
              : null;
          if (
            this.action.actionDef.currentLevel ===
            this.action.actionDef.newLevel
          ) {
            data.title = `Move ${this.action.actionDef.currentLevelName} to another ${parent}`;
            data.pickText = `Pick the ${parent} to move to`;
          } else if (
            this.action.actionDef.currentLevel > this.action.actionDef.newLevel
          ) {
            data.title = `Make ${this.action.actionDef.currentLevelName} into a new ${this.action.actionDef.newLevelName}`;
            data.pickText = parent
              ? `Pick the ${parent} to move to`
              : `Press Save to confirm creation of "${this.nodeDetails.name}" as a new ${this.action.actionDef.newLevelName}`;
            if (!parent && this.checkNameExists(nodes, this.nodeDetails.name)) {
              data.pickText = `A ${this.nodeDetails.previousLevel} called ${this.nodeDetails.name} already exists. Please rename before moving.`;
              this.invalid = true;
            }
          } else {
            data.title = `Make ${this.action.actionDef.currentLevelName} into a new ${this.action.actionDef.newLevelName}`;
            data.pickText = `Pick the ${parent} to move to`;
          }
        }
        this.moveData = data;
      }
    },
    checkNameExists(nodes, name) {
      name = (name || "").toLowerCase().trim();
      return nodes.find((n) => (n.name || "").toLowerCase().trim() === name);
    },
    setUpTree(nodes) {
      let getColFactor = (level) => {
        switch (this.hierarchType.linklevel - level + 1) {
          case 1:
            return 12;
          case 2:
            return 6;
          case 3:
            return 4;
          case 4:
            return 3;
          default:
            return 2;
        }
      };
      let nodeId = this.node.hierarchy_node_id;
      let nodeLevel = this.node.level;
      let pickLevel =
        this.mode === "move"
          ? nodeLevel - 1
          : this.mode === "merge" || this.mode === "pick"
          ? nodeLevel
          : 0;
      this.pickedNode = null;
      this.pickText = pickLevel
        ? this.hierarchType[`level${pickLevel}_name`]
        : "";
      if (this.mode === "move" && this.action?.actionDef) {
        this.setUpMove(nodes);
        pickLevel = this.moveData.pickLevel;
        this.pickText = this.moveData.pickText;
        this.viewLevel = this.moveData.viewLevel;
      }
      let processNodes = (nodes) => {
        let containsSelected = false;
        nodes.forEach((n) => {
          let colFactor = getColFactor(n.level);
          n.cols = colFactor;
          n.childCols = 12 - colFactor;
          n.pickable = n.level === pickLevel;
          n.picked = false;
          n.visible = true;
          n.filterParts = n.name
            .toLowerCase()
            .split(" ")
            .filter((x) => x);
          if (n.nodes) {
            n.expandable = true;
            n.expanded = processNodes(n.nodes);
            if (n.expanded) {
              n.selected = true;
              containsSelected = true;
              if (n.pickable && nodeLevel > pickLevel) {
                n.picked = true;
                this.pickedNode = n;
                n.expanded = false;
              }
            }
          } else {
            n.expandable = false;
          }
          if (n.hierarchy_node_id === nodeId) {
            containsSelected = true;
            n.selected = true;
            if (this.mode !== "move") this.viewLevel = n.level;
            if (n.pickable && nodeLevel === pickLevel) {
              n.picked = true;
              this.pickedNode = n;
            }
          }
        });
        return containsSelected;
      };

      if (processNodes(nodes)) {
        let getSels = (nodes) => {
          let sel = nodes.filter((x) => x.selected);
          if (sel.length) {
            let selNode = sel.map((x) => {
              return {
                description: x.description,
                hasDescription: x.hasDescription,
                hierarchy_node_id: x.hierarchy_node_id,
                // hierarchy_node_id_parent: x.hierarchy_node_id_parent,
                hr_id: x.hr_id,
                ht_id: x.ht_id,
                level: x.level,
                level_name: x.level_name,
                name: x.name,
                visible: x.visible,
                expandable: false,
                expanded: true,
                selected: !x.nodes || !x.nodes.some((n) => n.selected),
                hasDocs: x.hasDocs,
              };
            })[0];
            if (sel.length && sel[0].nodes && !selNode.selected)
              selNode.nodes = [getSels(sel[0].nodes)];
            return selNode;
          }
        };
        this.currentNode = [getSels(nodes)];
      }
      if (pickLevel) {
        this.setScroll = true;
        let hasLevelChildren = (nodes, level) => {
          return nodes.filter((n) => {
            if (n.nodes?.length && n.level < level) {
              hasLevelChildren(n.nodes, level);
            }
            if (n.level < level && !n.nodes?.length) {
              return false;
            } else {
              return true;
            }
          });
        };
        nodes = hasLevelChildren(nodes, pickLevel);
      }
      this.showPicker = !!pickLevel;
      return nodes;
    },
    buildAffectedNodesSummary(nodes, node_id) {
      const summary = [];
      const getSumm = (nodes) => {
        if (nodes && nodes.length) {
          nodes.forEach((n) => {
            let sum = summary.find((x) => x.name === n.level_name);
            if (!sum) {
              sum = {
                name: n.level_name,
                description: utils.pluralise(n.level_name),
                affectedCount: 0,
              };
              summary.push(sum);
            }
            sum.affectedCount++;
            getSumm(n.nodes);
          });
        }
      };
      let node = this.findNode(nodes, node_id);
      if (node) {
        if (node.hasDocs) {
          this.alertMessage = `Node cannot be deleted as it has ${
            node.hasDocs
          } linked document${node.hasDocs === 1 ? "" : "s"}`;
          this.invalid = true;
          return;
        } else {
          getSumm(node.nodes);
          this.invalid = false;
          this.alertMessage = summary.length
            ? "This action will also remove the following entities and is not reversible."
            : "This action will remove the " +
              node.level_name +
              " and is not easily reversible";
        }
      }
      return summary;
    },
    findNode(nodes, node_id) {
      let n = nodes.find((x) => x.hierarchy_node_id === node_id);
      if (n) return n;
      let found = null;
      nodes.forEach((n) => {
        if (!found && n.nodes) {
          found = this.findNode(n.nodes, node_id);
        }
        if (found) return;
      });
      return found;
    },
    pickNode(node) {
      if (this.pickedNode) this.pickedNode.picked = false;
      node.picked = true;
      this.pickedNode = node;
      //   this.checkNameExists(this.allNodes, node.name);
    },
    setPickerScroll() {
      if (this.pickText && this.setScroll) {
        let rowsVisible = 0;
        let lineSelected = 0;
        let countVisible = (nodes) => {
          nodes.forEach((n) => {
            rowsVisible++;
            if (n.expanded && n.nodes) {
              countVisible(n.nodes);
            }
            if (n.picked) lineSelected = rowsVisible;
          });
        };
        countVisible(this.allNodes);
        this.$refs.scrollPicker.scrollTop =
          this.$refs.scrollPicker.scrollHeight *
          ((lineSelected - 1) / rowsVisible);
        this.setScroll = false;
      }
    },
    searchNodes() {
      let searchText = this.searchText || "";
      if (!searchText) {
        let resetVisible = (nodes) => {
          nodes.forEach((n) => {
            n.visible = true;
            n.expanded = false;
            if (n.nodes) {
              resetVisible(n.nodes);
            }
          });
        };
        resetVisible(this.allNodes);
      }
      let text = searchText
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      if (searchText.trim().length < 4 && text.length < 2) return;
      //   let isVisible = (nodes, parentVisible) => {
      let isVisible = (nodes) => {
        let visible = false;
        nodes.forEach((n) => {
          //   if (parentVisible) {
          //     n.visible = true;
          //   } else {
          n.visible = text.every((t) =>
            n.filterParts.some((f) => f.indexOf(t) >= 0)
          );
          //   }
          //   let hasChildVisible = n.nodes && isVisible(n.nodes, n.visible);
          let hasChildVisible = n.nodes && isVisible(n.nodes);
          if (hasChildVisible) {
            n.visible = true;
            n.expanded = true;
          } else {
            n.expanded = false;
          }
          if (n.visible) visible = true;
        });
        return visible;
      };
      isVisible(this.allNodes);
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    close() {
      this.nodeDetails = null;
      this.dialog = false;
      this.$emit("close");
    },
    save() {
      if (!this.hierarchType) return;
      let getParentNode = (nodes, node) => {
        let parent = nodes.find(
          (n) => n.hierarchy_node_id === node.hierarchy_node_id_parent
        );
        if (parent) {
          if (!node.parents) node.parents = [];
          node.parents.push({
            hierarchy_node_id: parent.hierarchy_node_id,
            level: parent.level,
            name: parent.name,
            ht_id: parent.ht_id,
          });
          return node;
        } else {
          return nodes
            .filter((n) => n.nodes)
            .some((n) => {
              if (getParentNode(n.nodes, node)) {
                node.parents.push({
                  hierarchy_node_id: n.hierarchy_node_id,
                  level: n.level,
                  name: n.name,
                  ht_id: n.ht_id,
                });
                return true;
              } else {
                return false;
              }
            });
        }
      };
      if (this.pickedNode) {
        getParentNode(this.allNodes, this.pickedNode);
      }

      let data = this.nodeDetails;
      let getLeafNode = (node) => {
        if (node.nodes && node.nodes.length) {
          let n = getLeafNode(node.nodes[0]);
          if (n) {
            if (!n.parents) n.parents = [];
            n.parents.push({
              hierarchy_node_id: node.hierarchy_node_id,
              level: node.level,
              name: node.name,
            });
          }
          return n;
        } else return node;
      };
      let url = "hierarchy/savenodedetails";

      if (this.mode === "move") {
        data.move_to_node = this.pickedNode;
        data.move_from_level = this.moveData.currentLevel;
        data.move_to_level = this.moveData.newLevel;
      } else if (this.mode === "merge") {
        data.merge_with_node = this.pickedNode;
        data.merged_hr_id = getLeafNode(this.currentNode[0]).hr_id;
      } else if (this.mode === "pick") {
        data = {
          node: this.pickedNode,
          hr_id: getLeafNode(this.pickedNode).hr_id,
        };
        this.$emit("picked", {
          original: this.nodeDetails,
          updated: data,
          action: this.mode,
        });
        this.nodeDetails = null;
        this.dialog = false;
        return;
      } else if (this.mode === "add") {
        data.add_to_node = getLeafNode(this.currentNode[0]);
        data.level = data.add_to_node.level + 1;
      } else if (this.mode === "delete") {
        data.delete_node = true;
      } else if (this.mode === "editDescriptor") {
        url = "hierarchy/saveNodeDescription";
        data = {
          hierarchy_node_id: this.nodeDetails.hierarchy_node_id,
          ht_id: this.nodeDetails.ht_id,
          description: this.nodeDetails.new_description,
        };
      }
      this.isLoading = true;

      axios
        .post(url, data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.dialog = false;
            this.$store.dispatch("hierarchies/refresh");
            if (["merge", "rename", "move"].some(x => x === this.mode)) this.$store.dispatch("docs/refresh");
            this.$emit("saved", {
              original: this.nodeDetails,
              updated: { ...data, ...resp.data.Data },
              action: this.mode,
              newNode: this.mode === "add" ? resp.data.Data : null,
            });
            this.nodeDetails = null;
            this.triggerNotification("Hierarchy Saved", "success");
          } else {
            this.triggerNotification(resp.data.Message, "error");
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.dialog = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.nodeContainer {
  width: 50%;
  height: 24px;
  padding-left: 6px;
}
.nodeSeleted {
  background-color: rgb(205, 243, 205);
}
.nodeInfoIcon {
  float: right;
  padding-top: 2px;
}
.nodeInner {
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  float: left;
}
.node {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: right;
}
.nodePickable {
  width: 80%;
}
.nodeNonPickable {
  width: 90%;
}
.nodeExpandable {
  cursor: pointer;
}
.pickable {
  width: 10%;
  float: right;
  cursor: grab;
}
</style>