const themes = [
    { name: "oldui", display: "Old UI", newUi: false, dark: false, icon: "mdi-shape", primaryColor: "#1976D2", secondaryColor: "#424242" },
    { name: "newui", display: "New UI", newUi: true, dark: false, icon: "mdi-autorenew", primaryColor: "#4e6df3", secondaryColor: "#868686" },
    { name: "dark", display: "Dark", newUi: true, dark: true, icon: "mdi-weather-night", primaryColor: "#788eee", secondaryColor: "#424242" },
    { name: "highcontrast", display: "High Contrast", newUi: true, dark: true, icon: "mdi-contrast", primaryColor: "#FFEB3B", secondaryColor: "#424242" }
]

export const state = () => ({
    name: "default",
    newUi: false,
    dark: false,
    primaryColor: "#1976D2",
    allThemes: themes,
    clientThemes: themes
})


export const mutations = {
    setAvailableThemes(state, availableThemes) {
        let clientThemes;
        if (availableThemes) {
            const arr = availableThemes.value.split("|");
            clientThemes = [];
            arr.forEach(x => {
                clientThemes.push(themes.find(t => t.name == x));
            });
        }

        if (!clientThemes || !clientThemes.length)
            clientThemes = themes;

        state.clientThemes = clientThemes;
    },
    setTheme(state, themeName) {
        let theme = state.clientThemes.find(t => t.name == themeName);
        if (!theme && state.clientThemes.length) theme = state.clientThemes[0];
        if (theme) {
            state.name = theme.name;
            state.newUi = theme.newUi;
            state.dark = theme.dark;
            state.primaryColor = theme.primaryColor;
            state.secondaryColor = theme.secondaryColor;
        }
    },
}

export const actions = {

}

export const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}