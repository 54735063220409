<template>
  <v-row class="justify-center mb-6 link-container">
    <draggable
      class="row px-3 justify-center"
      v-model="links"
      :disabled="!editMode"
    >
      <v-col v-for="(l, li) in links" :key="li" :cols="l.tile_cols">
        <v-hover v-slot="{ hover }">
          <v-card
            :class="{
              'help-card link-container': true,
              'help-disabled': !l.url,
              'hover': !editMode && hover && l.url,
              draggable: editMode,
              'mt-8': l.showBorder || editMode
            }"
            :elevation="l.showBorder ? 2 : 0"
            :ripple="!!(l.url && !editMode)"
          >
            <v-card-text :style="l.height ? `height: ${l.height}` : 'height: 300px'">
              <div v-if="editMode" class="edit-btn" v-show="hover">
                <v-btn icon small title="Delete" @click="deleteTile($event, l)"
                  ><v-icon>delete</v-icon></v-btn
                >
                <v-btn icon small title="Copy" @click="copyTile($event, l)"
                  ><v-icon>content_copy</v-icon></v-btn
                >
                <v-btn icon small title="Settings" @click="editTile($event, l)"
                  ><v-icon>settings</v-icon></v-btn
                >
              </div>
              <iframe
                v-show="iframeUrls[l.id] && iframeUrls[l.id].url"
                :src="iframeUrls[l.id] ? iframeUrls[l.id].url : ''"
                frameborder="0"
                width="100%"
                height="100%"
                allowtransparency
              ></iframe>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </draggable>
    <v-btn
      v-if="editMode"
      class="add-btn"
      large
      icon
      title="Add Tile"
      @click="addTile($event)"
      ><v-icon>add_circle_outline</v-icon></v-btn
    >
  </v-row>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";

export default {
  name: "LandingMetabaseFrame",
  components: {
    draggable,
  },
  data: function () {
    return {
      iframeUrls: {},
      refreshTimeout: null
    };
  },
  props: {
    definition: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
  },
  mounted() {},
  watch: {
    definition: {
      deep: true,
      handler(val) {
        this.links = val.links;
        this.setMetabaseIframeURLs();
      }
    },
    links(val) {
      const definition = this.definition;
      definition.links = val;
      this.$emit("updated:definition", definition);
    }
  },
  computed: {},
  created() {
    this.links = this.definition.links;
    this.setMetabaseIframeURLs();
  },
  methods: {
    setMetabaseIframeURLs() {
      this.definition.links
        .forEach(l => {
          this.refreshMetabaseToken(l);
        });
    },
    refreshMetabaseToken(l, force) {
      const request = {
        link: {
          sso: "metabase",
          resourceType: l.metabaseType,
          resourceId: l.metabaseId,
          incTitle: l.incTitle,
          params: l.metabaseParams ? l.metabaseParams.filter(p => p && p.name) : []
        }
      };
      const requestJson = JSON.stringify(request);

      if (this.iframeUrls[l.id] === undefined)
        this.$set(this.iframeUrls, l.id, { request: null, url: null, refreshTimeout: null });

      //refresh if definition has changed or token expired (force = true)
      if (this.iframeUrls[l.id] && (force || this.iframeUrls[l.id].request !== requestJson)) {
        if (this.iframeUrls[l.id].refreshTimeout) {
          clearTimeout(this.iframeUrls[l.id].refreshTimeout);
          this.iframeUrls[l.id].refreshTimeout = null;
        }
        this.iframeUrls[l.id].request =  requestJson;
        axios
          .post("auth/ssolink", request)
          .then((resp) => {
            if (!resp.data || !resp.data.url)
              return alert(
                "Error Creating SSO Link, cannot log you in to alternative resource"
              );
            this.iframeUrls[l.id].url = resp.data.url;
            this.iframeUrls[l.id].refreshTimeout = setTimeout(() => this.refreshMetabaseToken(l, true), 1000 * (resp.data.expiresIn - 10));
          })
          .catch(() => {
            alert(
              "Error Creating SSO Link, cannot log you in to alternative resource"
            );
          });
      }
    },
    editTile(ev, t) {
      ev.preventDefault();
      this.$emit("editTile", { row: this.definition, link: t });
    },
    addTile(ev) {
      ev.preventDefault();
      this.$emit("addTile", { row: this.definition });
    },
    copyTile(ev, t) {
      ev.preventDefault();
      this.$emit("copyTile", { row: this.definition, link: t });
    },
    deleteTile(ev, t) {
      ev.preventDefault();
      this.$emit("deleteTile", { row: this.definition, link: t });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.link-container {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.steps .edit-btn {
  top: -20px;
  right: -20px;
}

.add-btn {
  position: absolute;
  bottom: 0;
  right: -35px;
  z-index: 1;
}

.draggable {
  cursor: move;
}

.help-card {
  border-radius: 10px !important;
  min-width: 200px;
  .titlerow {
    min-height: 100px !important;
    .col {
      display: flex;
      align-items: center;
    }
    h4 {
      font-size: 14px;
      line-height: normal;
      white-space: pre-wrap;
      word-break: normal;
    }
  }
  .v-icon {
    opacity: 0.8;
  }
  &.help-disabled:before {
    background: none;
    cursor: default;
  }
  &.theme--dark.hover {
    background-color: $secondary-background-dark;
  }
}
</style>