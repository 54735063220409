<template>
  <v-menu
    ref="datePicker"
    v-model="datePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :placeholder="placeholder"
        :label="label"
        :style="contentStyle"
        dense
        hide-details
        readonly
        :outlined="!hideBorder"
        v-bind="attrs"
        v-on="on"
        :class="css_class"
      >
      </v-text-field>
    </template>
    <v-date-picker v-model="dateValue" no-title scrollable>
      <v-btn small color="primary" @click="cancel">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn small color="primary" @click="clear">Clear</v-btn>
      <v-btn small color="primary" @click="today">Today</v-btn>
      <v-btn
        small
        color="primary"
        @click="save"
        :disabled="dateValue === dateValueSaved"
        >Pick</v-btn
      >
    </v-date-picker>
  </v-menu>
</template>

<script>
import dayJS from "dayjs";

export default {
  name: "DatePicker",
  props: {
    value: String,
    placeholder: String,
    label: String,
    css_class: String,
    contentStyle: Object,
    hideBorder: Boolean,
    open: Boolean,
  },
  data: function () {
    return {
      datePicker: this.open,
      dateValue: this.value ? dayJS(this.value).toISOString() : "",
      dateValueSaved: this.value ? dayJS(this.value).toISOString() : "",
    };
  },
  components: {},
  created() {
  },
  watch: {
    datePicker(newvalue) {
      if (!newvalue) {
        this.cancel();
      }
    },
  },
  computed: {
    formattedDate() {
      return this.dateValueSaved
        ? dayJS(this.dateValueSaved).format("D MMM YYYY")
        : "";
    },
  },
  methods: {
    today() {
      this.dateValue = dayJS().toISOString();
      this.save();
    },
    clear() {
      this.dateValue = "";
      this.save();
    },
    cancel() {
      this.datePicker = false;
      this.$emit("cancel");
    },
    save() {
      this.dateValueSaved = this.dateValue;
      this.$refs.datePicker.save(this.formattedDate);
      this.$emit("input", this.formattedDate);
    },
  },
};
</script>
<style scoped lang="scss">
</style>