<template>
  <div
    style="
      max-height: calc(100vh - 105x) !important;
      overflow: hidden;
      height: calc(100vh - 105px) !important;
    "
  >
    <v-card
      :class="{ expandedComparisonHolder: !displaySideMenu }"
      class="docComparisonHolder"
    >
      <v-card-text>
        <v-navigation-drawer
          mini-variant
          :expand-on-hover="false"
          permanent
          v-if="displaySideMenu"
          mini-variant-width="68px"
          :app= "!isDialogue" :absolute="isDialogue" 
          :clipped="false"
          fil-height :style="isDialogue ? '' : 'top: 150px;'"
        >
        <!-- <v-navigation-drawer app absolute 
         
          style="top: 150px; height: 100%; width: 68px; padding-left: 5px"
        > -->
          <v-list fill-height dense align-center justify-center>
            <v-list-item @click="showHierarchyFilter(true)" link v-if="!isDialogue">
              <v-list-item-icon>
                <v-icon>mdi-align-horizontal-left</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Hierarchy Filter</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="togglePanel('docPartFilter')" link>
              <v-list-item-icon>
                <v-icon>mdi-text-box-search</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Doc Parts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="compareFilter = !compareFilter"
              v-if="pinnedDoc"
              link
            >
              <v-list-item-icon>
                <v-icon>mdi-file-compare</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Comparison</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="viewFilter = !viewFilter" link>
              <v-list-item-icon>
                <v-badge
                  color="green"
                  :content="docDisplayItems.length"
                  :value="docDisplayItems.length"
                  offset-x="5"
                  offset-y="5"
                  dot
                >
                  <v-icon>mdi-eye</v-icon>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>View Options</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="historyFilter = !historyFilter"
              v-if="historyItems.length > 0"
              link
            >
              <v-list-item-icon>
                <v-badge
                  :content="historyItems.length"
                  :value="historyItems.length"
                  color="green"
                  overlap
                >
                  <v-icon>mdi-history</v-icon>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>History</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="deleteFilters = !deleteFilters"
              v-if="selectedDocs || multipleSelectedDocs"
              link
            >
              <v-list-item-icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Delete Options</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-row dense class="docComparisonFilterRowExpanded" :style="isDialogue ? 'margin-left: 60px;' : ''">
          <v-col cols="2">
            <v-select
              dense
              label="Document Part"
              outlined
              class="essentialFlag"
              :items="allParts"
              v-model="targetPart"
              @change="loadParts"
            ></v-select>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col
            cols="2"
            class="compareIconRow"
            style="padding-top: 12px; display: none"
          >
            <v-icon
              style="padding-right: 15px"
              @click="showHierarchyFilter(true)"
              >mdi-align-horizontal-left</v-icon
            >
            <v-icon
              style="padding-right: 15px"
              @click="compareFilter = !compareFilter"
              v-if="pinnedDoc"
              >mdi-file-compare</v-icon
            >
            <v-badge
              color="green"
              style="margin-right: 15px"
              :content="docDisplayItems.length"
              :value="docDisplayItems.length"
              offset-x="5"
              offset-y="5"
              dot
            >
              <v-icon @click="viewFilter = !viewFilter">mdi-eye</v-icon>
            </v-badge>
            <v-badge
              :content="historyItems.length"
              :value="historyItems.length"
              color="green"
              overlap
              style="margin-right: 15px"
            >
              <v-icon
                @click="historyFilter = !historyFilter"
                v-if="historyItems.length > 0"
                >mdi-history</v-icon
              >
            </v-badge>
            <v-icon
              @click="deleteFilters = !deleteFilters"
              v-if="selectedDocs || multipleSelectedDocs"
              color="error"
              >mdi-delete</v-icon
            >
          </v-col>
          <v-col class="items" cols="2" style="display: none">
            <v-switch
              v-if="useSpellCheck"
              small
              dense
              style="display: inline"
              v-model="useInlineEditor"
              label="Use Inline Editor?"
              @change="toggleInline"
            ></v-switch>
          </v-col>
          <template v-if="isDialogue">
            <v-col class="items" v-if="hiddenDocs.length > 0">
              <v-badge :content="hiddenDocs.length" overlapped
                ><v-menu
                  :close-on-content-click="false"
                  v-model="hiddenDocsMenu"
                  :nudge-width="200"
                  offset-x
                  ><template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      close
                      label
                      @click:close="clearHidden"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon left> mdi-menu-down </v-icon>Hidden</v-chip
                    ></template
                  ><v-card>
                    <v-list>
                      <v-list-item
                        v-for="(d, di) in hiddenDocs"
                        :key="'hd' + di"
                      >
                        <v-list-item-content>
                          <v-list-item-subtitle>{{
                            d.system_number
                          }}</v-list-item-subtitle>
                          <v-list-item-title>{{
                            d.doc_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          ><v-icon
                            title="Unhide"
                            @click="d.visible = !d.visible"
                            >mdi-eye</v-icon
                          ></v-list-item-action
                        >
                      </v-list-item>
                    </v-list></v-card
                  >
                </v-menu>
              </v-badge>
            </v-col>
            <v-col class="items" v-if="lockedDocs.length > 0">
              <v-badge :content="lockedDocs.length" overlapped
                ><v-menu
                  :close-on-content-click="false"
                  v-model="lockedDocsMenu"
                  :nudge-width="200"
                  offset-x
                  ><template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      close
                      label
                      @click:close="clearLocked"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon left> mdi-menu-down </v-icon>Locked</v-chip
                    ></template
                  ><v-card>
                    <v-list>
                      <v-list-item
                        v-for="(d, di) in lockedDocs"
                        :key="'ld' + di"
                      >
                        <v-list-item-content>
                          <v-list-item-subtitle>{{
                            d.system_number
                          }}</v-list-item-subtitle>
                          <v-list-item-title>{{
                            d.doc_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          ><v-icon
                            title="Unlock"
                            @click="d.locked = !d.locked"
                            >mdi-lock-open</v-icon
                          ></v-list-item-action
                        >
                      </v-list-item>
                    </v-list></v-card
                  >
                </v-menu>
              </v-badge>
            </v-col>
          </template>
          <template v-else>
            <v-col v-if="hiddenDocs.length > 0" class="items" cols="1">
              Hidden: {{ hiddenDocs.length }}
              <span
                style="cursor: pointer"
                v-if="hiddenDocs.length > 0"
                @click="clearHidden"
                class="links"
                >Clear</span
              >
            </v-col>
            <v-col v-if="lockedDocs.length > 0" class="items" cols="1">
              Locked: {{ lockedDocs.length }}
              <span
                style="cursor: pointer"
                v-if="lockedDocs.length > 0"
                @click="clearLocked"
                class="links"
                >Clear</span
              >
            </v-col></template
          >
          <v-col align="right"> </v-col>
          <v-col align="right">
            <span style="padding-right: 80px">
              <v-btn
                icon
                color="green"
                style="margin-right: 15px"
                @click="showSuggestionsPanel"
              >
                <v-icon color="green">mdi-format-list-checkbox</v-icon>
              </v-btn>
              <v-btn icon @click="showDatabasePanel" style="margin-right: 15px">
                <v-icon title="View Database">mdi-database-cog</v-icon>
              </v-btn>
              <v-btn icon style="margin-right: 15px; display: none">
                <v-icon title="Upload">mdi-cloud-upload</v-icon>
              </v-btn>
            </span>
            <v-btn
              v-if="!isDialogue"
              icon
              small
              @click="changeView('cards')"
              title="Card View"
              class=""
            >
              <v-icon>mdi-view-grid</v-icon>
            </v-btn>
            <v-btn
              v-if="!isDialogue"
              icon
              small
              @click="changeView('table')"
              title="Table View"
              class="ml-3"
            >
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
            <v-icon v-if="isDialogue" @click="close" class="float-right pt-3"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
        <v-row class="docComparisonContentExpanded" :style="isDialogue ? 'margin-left: 60px;' : ''">
          <v-col cols="12" style="display: flex">
            <div
              style="
                display: -webkit-inline-box;
                flex-direction: row;
                max-width: 500px;
                width: 500px;
                padding-left: 5px;
                max-height: 100%;
                height: 100%;
                margin-top: 0px;
                padding-bottom: 16px;
                margin-right: 0px !important;
              "
              v-if="pinnedDoc"
            >
              <div
                class="pinnedCard compareCard"
                @dragover.prevent
                @dragenter="highlightDragTarget(pinnedDoc.doc_id, $event)"
                @drop="dropItem(pinnedDoc.doc_id, $event)"
              >
                <div class="handle" style="padding: 0px 15px">
                  <div style="display: inline-flex; width: 100%">
                    <div align="left" style="width: 10%; margin-top: -8px">
                      <v-checkbox
                        dense
                        v-if="isMultiPart"
                        @click="toggleAllParts(pinnedDoc)"
                        :indeterminate="pinnedDoc.indeterminate"
                        v-model="pinnedDoc.allSelected"
                        class="comparePartCheckBox"
                      ></v-checkbox>
                    </div>
                    <div
                      align="left"
                      v-html="pinnedDoc.system_number"
                      style="width: 30%; font-size: 12px"
                    ></div>
                    <div
                      class="docLinks"
                      align="center"
                      style="display: flex; margin-left: 8px; padding-top: 2px"
                    >
                      <v-progress-circular
                        style="cursor: pointer"
                        @mousedown.stop
                        @click.stop="openSummaryMenu(pinnedDoc.doc_id, $event)"
                        :rotate="90"
                        :size="30"
                        :width="3"
                        :value="pinnedDoc.checkSummary.score"
                        :color="pinnedDoc.checkSummary.scoreColour"
                      >
                        <span style="font-size: 11px">{{
                          pinnedDoc.checkSummary.score
                        }}</span>
                      </v-progress-circular>
                      <DocStatus
                        :document="pinnedDoc"
                        xSmall
                        style="margin-top: 7px; margin-left: 7px"
                      ></DocStatus>
                      <!-- <v-chip
                              x-small
                              style="margin-top: 7px; margin-left: 7px;"
                              class="status-chip"
                              label
                              :color="resolveHeaderStyle(pinnedDoc)"
                              >{{ resolveStatusChipText(pinnedDoc) }}</v-chip
                            > -->
                    </div>
                    <div align="right" style="width: 50%; padding-right: 5px">
                      <v-icon
                          :class="{ disabledIcon: pinnedDoc.lockedByPermission }"
                          :color="pinnedDoc.locked ? '#ff8137' : ''"
                          @click="pinnedDoc.locked = !pinnedDoc.locked"
                          :title="
                            pinnedDoc.lockedByPermission
                              ? 'Cannot Edit - No Permission'
                              : pinnedDoc.locked
                              ? 'Make Editable'
                              : 'Lock From Editing'
                          "
                        class="compareIcons"
                      >
                        {{
                          pinnedDoc.locked || pinnedDoc.lockedByPermission
                            ? "mdi-lock-open"
                            : "mdi-lock"
                        }}</v-icon
                      >
                      <v-icon class="compareIcons"></v-icon>
                      <v-icon
                        color="#377DFF"
                        class="compareIcons"
                        @click="unPinItem(pinnedDoc)"
                        :title="pinnedDoc.pinned ? 'Unpin' : 'Pin'"
                      >
                        {{
                          pinnedDoc.pinned ? "mdi-pin-off" : "mdi-pin"
                        }}</v-icon
                      ><v-icon
                        class="compareIcons"
                        @click.stop="openContext($event, pinnedDoc)"
                        >mdi-dots-vertical</v-icon
                      >
                    </div>
                  </div>
                  <div
                    :title="pinnedDoc.doc_name"
                    class="compareHeader"
                    style="width: 100%"
                    v-html="pinnedDoc.doc_name"
                  ></div>
                </div>
                <div
                  v-if="pinnedDoc.isUpdating"
                  class="comparePartsContainer"
                  style="
                    background-color: rgb(193 193 193 / 47%);
                    padding-top: 45%;
                  "
                >
                  <div
                    style="width: 100%; height: 100%; text-align: center"
                    justify-center
                    align-center
                  >
                    Please Wait... Updating...
                    <br />
                    <v-progress-circular
                      :width="3"
                      color="green"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </div>
                <div
                  v-else
                  class="comparePartsContainer"
                  :class="{
                    highlightDrag: dragging,
                    highlightDragActive:
                      dragging && currentDragTarget === pinnedDoc.doc_id,
                  }"
                >
                  <draggable
                    :id="`${pinnedDoc.doc_id}`"
                    :move="checkMove"
                    style="height: 100%"
                    @start="start"
                    :disabled="
                      pinnedDoc.locked ||
                      pinnedDoc.lockedByPermission ||
                      pinnedDoc.activeParts.some((x) => x.editing)
                    "
                    :list="pinnedDoc.activeParts"
                    selected-class="sortableSelected"
                    :group="{ name: 'parts', pull: pullFunction }"
                    animation="150"
                    @end="checkEnd"
                    multi-drag
                  >
                    <div
                      v-for="(item, i) in pinnedDoc.activeParts"
                      :key="'p_' + i"
                      :id="pinnedDoc.doc_id + '|' + item.doc_part_id"
                      :ref="pinnedDoc.doc_id + '|' + item.doc_part_id"
                      :class="{
                        highlightDrag: dragging,
                        highlightDragActive:
                          dragging && currentDragTarget === pinnedDoc.doc_id,
                      }"
                      style="display: inline-flex; width: 100%"
                      @mouseover="item.hover = true"
                      @mouseout="item.hover = false"
                    >
                      <div
                        :ref="`menuTopHolder_${pinnedDoc.doc_id}|${item.doc_part_id}`"
                        style="height: 0px"
                      ></div>
                      <div style="width: 30px">
                        <v-checkbox
                          v-if="isMultiPart"
                          class="comparePartCheckBox"
                          @change="selectPart(pinnedDoc.doc_id, item)"
                          v-model="item.selected"
                          :value="item.selected"
                        ></v-checkbox>
                      </div>
                      <div
                        class="comparePart"
                        :class="{
                          editComparePart: item.editing,
                          highlightDrag: dragging,
                          highlightDragActive:
                            dragging && currentDragTarget === pinnedDoc.doc_id,
                        }"
                      >
                        <div
                          style="width: 100%"
                          v-if="
                            targetPartDetails.tmpl_part_metadata.attributes
                              .length > 0
                          "
                        >
                          <div v-for="(a, aidx) in item.attributes" :key="aidx">
                            <TextInput
                              v-if="
                                item.compareHtml === '' &&
                                !a.isLookup &&
                                !a.isDependentLookup
                              "
                              :value="a"
                              :metadata="item.tmpl_part_metadata"
                              :wordChecks="a.wordChecks"
                              :edit="item.editing"
                              :disable="a.locked || pinnedDoc.locked || pinnedDoc.lockedByPermission"
                              :spellCheck="useSpellCheck"
                              :displayMode="'sidebyside'"
                              :ref="`inline_${pinnedDoc.doc_id}_${item.doc_part_id}`"
                              @cancel="cancelEdit(j, item)"
                              @confirm="
                                (data) =>
                                  saveItemTextInline(data, pinnedDoc, item, a)
                              "
                              @languageIssue="
                                (data) =>
                                  openLanguageMenu(
                                    pinnedDoc,
                                    data,
                                    item,
                                    item,
                                    a
                                  )
                              "
                              @closeLanguageIssue="closeLanguageMenu"
                            ></TextInput>
                            <LookupInput
                              v-else-if="a.isLookup || a.isDependentLookup"
                              :value="a"
                              :displayMode="'sidebyside'"
                              :disable="pinnedDoc.locked || pinnedDoc.lockedByPermission"
                              :ref="`inline_lookup_${pinnedDoc.doc_id}_${item.doc_part_id}`"
                              :metadata="targetPartDetails.tmpl_part_metadata"
                              :part="item"
                              @update="
                                (data) =>
                                  saveItemTextInline(data, pinnedDoc, item, a)
                              "
                            ></LookupInput>
                          </div>
                        </div>
                        <div style="display: inline-flex" v-else>
                          <span
                            v-if="!item.editing && !useInlineEditor"
                            class="comparePartText"
                            v-html="item.highlightedText"
                          ></span>
                          <v-textarea
                            class="comparePartText"
                            auto-grow
                            filled
                            autofocus
                            rows="1"
                            row-height="15"
                            v-model="item.text"
                            v-if="item.editing && !useInlineEditor"
                            :value="item.text"
                          >
                            <template slot="append-outer">
                              <v-icon @click="saveItemText(pinnedDoc, item)"
                                >mdi-check</v-icon
                              ><br />
                              <v-icon @click="cancelEdit(pinnedDoc, item)"
                                >mdi-close</v-icon
                              >
                            </template>
                          </v-textarea>
                          <TextInput
                            v-if="useInlineEditor"
                            :value="item"
                            :metadata="item.tmpl_part_metadata"
                            :wordChecks="item.wordChecks"
                            :spellCheck="useSpellCheck"
                            :disable="pinnedDoc.locked || pinnedDoc.lockedByPermission"
                            :edit="item.editing"
                            :displayMode="'sidebyside'"
                            @cancel="cancelEdit(pinnedDoc, item)"
                            @confirm="
                              (data) =>
                                saveItemTextInline(data, pinnedDoc, item)
                            "
                            @languageIssue="
                              (data) => openLanguageMenu(pinnedDoc, data, item)
                            "
                            @closeLanguageIssue="closeLanguageMenu"
                          ></TextInput>
                        </div>
                        <div
                          class="comparePartEssential"
                          v-if="
                            targetPartDetails.tmpl_part_metadata
                              .uses_essential_flag && !item.editing
                          "
                          style="padding: 0px 10px 5px"
                        >
                          <hr />
                          <v-select
                            flat
                            solo
                            dense
                            class="essentialFlag"
                            @change="updateEssential(pinnedDoc, item)"
                            item-text="text"
                            item-value="value"
                            v-model="item.is_essential"
                            :disabled="pinnedDoc.locked || pinnedDoc.lockedByPermission"
                            :items="essentialFlags"
                            :background-color="
                              item.is_essential === null
                                ? 'rgb(225 93 8 / 9%)'
                                : ''
                            "
                          >
                          </v-select>
                        </div>
                      </div>
                      <div class="compareActionMenu">
                        <v-menu bottom right :nudge-width="250" offset-x>
                          <template v-slot:activator="{ on, attrs }"
                            ><v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-if="
                                ((!pinnedDoc.locked && !pinnedDoc.lockedByPermission) &&
                                  ((useOpenAISuggestions &&
                                    item.tmpl_part_metadata.suggestion_part) ||
                                  (allowSummarise && item.text !== '')))
                              "
                              v-on="on"
                              :style="{
                                visibility: item.hover ? '' : 'hidden',
                              }"
                            >
                              <v-icon>auto_awesome</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item
                              v-if="
                                (!pinnedDoc.locked ||
                                  !pinnedDoc.lockedByPermission) &&
                                useOpenAISuggestions &&
                                item.tmpl_part_metadata.suggestion_part
                              "
                              @click="
                                doAiFunction(
                                  pinnedDoc,
                                  item,
                                  'suggestions',
                                  $event
                                )
                              "
                            >
                              <v-list-item-icon style="margin-right: 10px">
                                <v-icon>mdi-auto-fix</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title
                                >Generate AI Suggestions</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                          <v-list dense>
                            <v-list-item
                              v-if="
                                (!pinnedDoc.locked ||
                                  !pinnedDoc.lockedByPermission) &&
                                allowSummarise &&
                                item.text !== ''
                              "
                              @click="
                                doAiFunction(
                                  pinnedDoc,
                                  item,
                                  'summarise',
                                  $event
                                )
                              "
                            >
                              <v-list-item-icon style="margin-right: 10px">
                                <v-icon>compress</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Summarise</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <div
                        :ref="`menuHolder_${pinnedDoc.doc_id}|${item.doc_part_id}`"
                        style="height: 0px"
                      ></div>
                    </div>
                  </draggable>
                </div>
                <div class="comparePartsActions">
                  <span class="alignLeft">
                    <v-btn
                      color="primary"
                      @click="addNewItem(pinnedDoc)"
                      :disabled="pinnedDoc.locked || pinnedDoc.lockedByPermission"
                      v-if="isMultiPart"
                      plain
                      small
                    >
                      <span class="primary--text">
                        <v-icon color="primary"> mdi-plus </v-icon> Add New
                      </span>
                    </v-btn>
                  </span>
                  <span
                    class="alignCenter"
                    v-if="
                      targetPartDetails.tmpl_part_metadata.uses_essential_flag
                    "
                  >
                    <v-select
                      flat
                      solo
                      dense
                      :ref="`essentials_` + pinnedDoc.doc_id"
                      class="essentialFlag"
                      item-text="text"
                      item-value="value"
                      :disabled="pinnedDoc.locked || pinnedDoc.lockedByPermission"
                      @click:append-outer="updateEssentials(pinnedDoc)"
                      :items="essentialFlags"
                    >
                      <template slot="label">
                        <span style="font-size: 11px">Essential?</span>
                      </template>
                      <template slot="selection" slot-scope="data">
                        <span style="font-size: 11px">{{
                          data.item.text
                        }}</span>
                      </template>
                      <template slot="item" slot-scope="data">
                        <span style="font-size: 11px">{{
                          data.item.text
                        }}</span>
                      </template>
                    </v-select>
                  </span>
                  <span class="alignRight">
                    <v-btn
                      @click="deleteItems(pinnedDoc)"
                      :disabled="pinnedDoc.locked || pinnedDoc.lockedByPermission"
                      v-if="
                        pinnedDoc.activeParts.some((x) => x.selected === true)
                      "
                      color="error"
                      plain
                      small
                    >
                      <span class="error--text">
                        <v-icon color="error"> mdi-delete </v-icon> Delete
                      </span>
                    </v-btn>
                  </span>
                </div>
                <div class="comparePartsActions">
                  Created: {{ pinnedDoc.created_date }}
                </div>
              </div>
              <div class="pinnedItemOperationsMenu">
                <v-btn small icon title="Update All" @click="doUpdateAll">
                  <v-icon> mdi-chevron-triple-right </v-icon>
                </v-btn>
                <br/>
                <br/>
                <v-btn v-if="isMultiPart" small icon title="Sync All To Pinned Doc" @click="mergeAll">
                  <v-icon> mdi-chevron-triple-left </v-icon>
                </v-btn>
              </div>
            </div>
            <div
              style="
                display: -webkit-inline-box;
                overflow-x: scroll;
                max-width: 100vw;
                flex-direction: row;
                width: 100%;
                padding-left: 5px;
                max-height: 100vw;
                height: 100%;
                margin-top: 0px;
                margin-left: 15px;
              "
              :class="{ sideBySidePinnedItem: pinnedDoc !== null }"
              v-if="!compareLoading"
            >
              <draggable
                tag="div"
                ghost-class="compareCardGhost"
                :group="{ name: 'row' }"
                style="display: -webkit-inline-box; height: 100%"
                handle=".handle"
                @change="onChange"
              >
                <div
                  v-for="j in activeDocs"
                  :key="j.doc_id"
                  class="compareCard"
                  @dragover.prevent
                  @dragenter="highlightDragTarget(j.doc_id, $event)"
                  @drop="dropItem(j.doc_id, $event)"
                >
                  <div class="handle" style="padding: 0px 15px">
                    <div style="display: inline-flex; width: 100%">
                      <div align="left" style="width: 10%; margin-top: -8px">
                        <v-checkbox
                          dense
                          v-if="isMultiPart"
                          @click="toggleAllParts(j)"
                          :indeterminate="j.indeterminate"
                          v-model="j.allSelected"
                          class="comparePartCheckBox"
                        ></v-checkbox>
                      </div>
                      <div
                        align="left"
                        v-html="j.system_number"
                        style="width: 30%; font-size: 12px"
                      ></div>
                      <div
                        class="docLinks"
                        align="center"
                        style="
                          display: flex;
                          margin-left: 8px;
                          padding-top: 2px;
                          cursor: pointer;
                        "
                      >
                        <v-progress-circular
                          style="cursor: pointer"
                          @mousedown.stop
                          @click.stop="openSummaryMenu(j.doc_id, $event)"
                          :rotate="90"
                          :size="30"
                          :width="3"
                          :value="j.checkSummary.score"
                          :color="j.checkSummary.scoreColour"
                        >
                          <span style="font-size: 11px">{{
                            j.checkSummary.score
                          }}</span>
                        </v-progress-circular>
                        <DocStatus
                          :document="j"
                          xSmall
                          style="margin-top: 7px; margin-left: 7px"
                        ></DocStatus>
                        <!-- <v-chip
                              x-small
                              style="margin-top: 7px; margin-left: 7px;"
                              class="status-chip"
                              label
                              :color="resolveHeaderStyle(j)"
                              >{{ resolveStatusChipText(j) }}</v-chip
                            > -->
                      </div>
                      <div align="right" style="width: 50%; padding-right: 5px">
                        <v-icon
                          class="compareIcons"
                          :class="{ disabledIcon: j.lockedByPermission }"
                          :color="j.locked ? '#ff8137' : ''"
                          @click="j.locked = !j.locked"
                          :title="
                            j.lockedByPermission
                              ? 'Cannot Edit - No Permission'
                              : j.locked
                              ? 'Make Editable'
                              : 'Lock From Editing'
                          "
                          >{{
                            j.locked || j.lockedByPermission
                              ? "mdi-lock-open"
                              : "mdi-lock"
                          }}</v-icon
                        >
                        <v-icon
                          class="compareIcons"
                          title="Hide Document"
                          @click="j.visible = !j.visible"
                          >mdi-eye-off</v-icon
                        >
                        <v-icon
                          class="compareIcons"
                          @click="pinItem(j)"
                          :title="j.pinned ? 'Unpin' : 'Pin'"
                        >
                          {{ j.pinned ? "mdi-pin-off" : "mdi-pin" }}</v-icon
                        >
                        <v-icon
                          class="compareIcons"
                          @click.stop="openContext($event, j)"
                          >mdi-dots-vertical</v-icon
                        >
                      </div>
                    </div>
                    <div
                      :title="j.doc_name"
                      class="compareHeader"
                      style="width: 100%; cursor: move"
                      v-html="j.doc_name"
                    ></div>
                  </div>
                  <div
                    v-if="j.isUpdating"
                    class="comparePartsContainer"
                    style="
                      background-color: rgb(193 193 193 / 47%);
                      padding-top: 45%;
                    "
                  >
                    <div
                      style="width: 100%; height: 100%; text-align: center"
                      justify-center
                      align-center
                    >
                      Please Wait... Updating...
                      <br />
                      <v-progress-circular
                        :width="3"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </div>
                  <div
                    v-else
                    class="comparePartsContainer"
                    :class="{
                      highlightDrag: dragging,
                      highlightDragActive:
                        dragging && currentDragTarget === j.doc_id,
                    }"
                  >
                    <draggable
                      :id="`${j.doc_id}`"
                      :move="checkMove"
                      style="height: 100%"
                      @start="start"
                      :disabled="
                        j.locked ||
                        j.lockedByPermission ||
                        j.activeParts.some((x) => x.editing)
                      "
                      :list="j.activeParts"
                      selected-class="sortableSelected"
                      :group="{ name: 'parts', pull: pullFunction }"
                      animation="150"
                      @end="checkEnd"
                    >
                      <div
                        v-for="(item, indx) in j.activeParts"
                        :key="j.doc_id + '|' + indx"
                        :id="j.doc_id + '|' + item.doc_part_id"
                        :ref="j.doc_id + '|' + item.doc_part_id"
                        style="display: inline-flex; width: 100%"
                        @mouseover="item.hover = true"
                        @mouseout="item.hover = false"
                      >
                        <div
                          :ref="`menuTopHolder_${j.doc_id}|${item.doc_part_id}`"
                          style="height: 0px"
                        ></div>
                        <div style="width: 30px">
                          <v-checkbox
                            v-if="isMultiPart"
                            class="comparePartCheckBox"
                            @change="selectPart(j.doc_id, item)"
                            v-model="item.selected"
                            :value="item.selected"
                          ></v-checkbox>
                        </div>
                        <div
                          class="comparePart"
                          :class="{
                            editComparePart: item.editing,
                            highlightDrag: dragging,
                            highlightDragActive:
                              dragging && currentDragTarget === j.doc_id,
                          }"
                        >
                          <div
                            style="width: 100%"
                            v-if="
                              targetPartDetails.tmpl_part_metadata.attributes
                                .length > 0
                            "
                          >
                            <div
                              v-for="(a, aidx) in item.attributes"
                              :key="aidx"
                            >
                              <TextInput
                                v-if="
                                  item.compareHtml === '' &&
                                  !a.isLookup &&
                                  !a.isDependentLookup
                                "
                                :value="a"
                                :metadata="item.tmpl_part_metadata"
                                :wordChecks="a.wordChecks"
                                :edit="item.editing"
                                :disable="a.locked || j.locked || j.lockedByPermission"
                                :spellCheck="useSpellCheck"
                                :displayMode="'sidebyside'"
                                :ref="`inline_${j.doc_id}_${item.doc_part_id}`"
                                @cancel="cancelEdit(j, item)"
                                @confirm="
                                  (data) => saveItemTextInline(data, j, item, a)
                                "
                                @languageIssue="
                                  (data) =>
                                    openLanguageMenu(j, data, item, item, a)
                                "
                                @closeLanguageIssue="closeLanguageMenu"
                              ></TextInput>
                              <LookupInput
                                v-else-if="a.isLookup || a.isDependentLookup"
                                :value="a"
                                :displayMode="'sidebyside'"
                                :disable="j.locked || j.lockedByPermission"
                                :ref="`inline_lookup_${j.doc_id}_${item.doc_part_id}`"
                                :metadata="targetPartDetails.tmpl_part_metadata"
                                :part="item"
                                @update="
                                  (data) => saveItemTextInline(data, j, item, a)
                                "
                              ></LookupInput>
                            </div>
                          </div>
                          <div style="display: inline-flex; width: 100%" v-else>
                            <span
                              v-if="!item.editing && !useInlineEditor"
                              class="comparePartText"
                              v-html="
                                item.compareHtml === ''
                                  ? item.highlightedText
                                  : item.compareHtml
                              "
                            ></span>
                            <v-textarea
                              class="comparePartText"
                              auto-grow
                              filled
                              autofocus
                              rows="1"
                              row-height="15"
                              v-model="item.text"
                              v-if="item.editing && !useInlineEditor"
                              :value="item.text"
                            >
                              <template slot="append-outer">
                                <v-icon @click="saveItemText(j, item)"
                                  >mdi-check</v-icon
                                ><br />
                                <v-icon @click="cancelEdit(j, item)"
                                  >mdi-close</v-icon
                                >
                              </template>
                            </v-textarea>
                            <span
                              v-if="useInlineEditor && item.compareHtml !== ''"
                              class="comparePartText"
                              v-html="item.compareHtml"
                            ></span>
                            <TextInput
                              v-if="useInlineEditor && item.compareHtml === ''"
                              :value="item"
                              :disable="j.locked || j.lockedByPermission"
                              :metadata="item.tmpl_part_metadata"
                              :wordChecks="item.wordChecks"
                              :edit="item.editing"
                              :spellCheck="useSpellCheck"
                              :displayMode="'sidebyside'"
                              :ref="`inline_${j.doc_id}_${item.doc_part_id}`"
                              @cancel="cancelEdit(j, item)"
                              @confirm="
                                (data) => saveItemTextInline(data, j, item)
                              "
                              @languageIssue="
                                (data) => openLanguageMenu(j, data, item)
                              "
                              @closeLanguageIssue="closeLanguageMenu"
                            ></TextInput>
                          </div>
                          <div
                            :ref="`menuHolder_${j.doc_id}|${item.doc_part_id}`"
                            style="height: 0px; width: 100%"
                          ></div>
                          <div
                            class="comparePartEssential"
                            v-if="
                              targetPartDetails.tmpl_part_metadata
                                .uses_essential_flag && !item.editing
                            "
                            style="padding: 0px 10px 5px"
                          >
                            <hr />
                            <v-select
                              flat
                              solo
                              dense
                              class="essentialFlag"
                              @change="updateEssential(j, item)"
                              item-text="text"
                              item-value="value"
                              v-model="item.is_essential"
                              :disabled="j.locked || j.lockedByPermission"
                              :items="essentialFlags"
                              :background-color="
                                item.is_essential === null
                                  ? 'rgb(225 93 8 / 9%)'
                                  : ''
                              "
                            >
                            </v-select>
                          </div>
                        </div>
                        <div class="compareActionMenu">
                          <v-menu bottom right :nudge-width="250" offset-x>
                            <template v-slot:activator="{ on, attrs }"
                              ><v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-if="
                                ((!j.locked && !j.lockedByPermission) &&
                                  ((useOpenAISuggestions &&
                                    item.tmpl_part_metadata.suggestion_part) ||
                                  (allowSummarise && item.text !== '')))
                                "
                                v-on="on"
                                :style="{
                                  visibility: item.hover ? '' : 'hidden',
                                }"
                              >
                                <v-icon>auto_awesome</v-icon>
                              </v-btn>
                            </template>
                            <v-list dense>
                              <v-list-item
                                v-if="
                                  (!j.locked && !j.lockedByPermission) &&
                                  useOpenAISuggestions &&
                                  item.tmpl_part_metadata.suggestion_part
                                "
                                @click="
                                  doAiFunction(j, item, 'suggestions', $event)
                                "
                              >
                                <v-list-item-icon style="margin-right: 10px">
                                  <v-icon>mdi-auto-fix</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Generate AI Suggestions</v-list-item-title
                                >
                              </v-list-item>
                            </v-list>
                            <v-list dense>
                              <v-list-item
                                v-if="
                                  (!j.locked && !j.lockedByPermission) &&
                                  allowSummarise &&
                                  item.text !== ''
                                "
                                @click="
                                  doAiFunction(j, item, 'summarise', $event)
                                "
                              >
                                <v-list-item-icon style="margin-right: 10px">
                                  <v-icon>compress</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Summarise</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div
                          :ref="`menuHolder_${j.doc_id}|${item.doc_part_id}`"
                          style="height: 0px"
                        ></div>
                      </div>
                    </draggable>
                  </div>
                  <div class="comparePartsActions">
                    <span class="alignLeft">
                      <v-btn
                        color="primary"
                        @click="addNewItem(j)"
                        :disabled="j.locked || j.lockedByPermission"
                        v-if="isMultiPart"
                        plain
                        small
                      >
                        <span class="primary--text">
                          <v-icon color="primary"> mdi-plus </v-icon> Add New
                        </span>
                      </v-btn>
                    </span>
                    <span class="alignCenter">
                      <v-select
                        v-if="
                          targetPartDetails.tmpl_part_metadata
                            .uses_essential_flag
                        "
                        flat
                        solo
                        dense
                        :ref="`essentials_` + j.doc_id"
                        class="essentialFlag"
                        item-text="text"
                        item-value="value"
                        :disabled="j.locked || j.lockedByPermission"
                        append-outer-icon="mdi-file-upload"
                        @click:append-outer="updateEssentials(j)"
                        :items="essentialFlags"
                      >
                        <template slot="label">
                          <span style="font-size: 11px">Essential?</span>
                        </template>
                        <template slot="selection" slot-scope="data">
                          <span style="font-size: 11px">{{
                            data.item.text
                          }}</span>
                        </template>
                        <template slot="item" slot-scope="data">
                          <span style="font-size: 11px">{{
                            data.item.text
                          }}</span>
                        </template>
                      </v-select>
                    </span>
                    <span class="alignRight">
                      <v-btn
                        @click="deleteItems(j)"
                        :disabled="j.locked || j.lockedByPermission"
                        v-if="j.activeParts.some((x) => x.selected === true)"
                        color="error"
                        plain
                        small
                      >
                        <span class="error--text">
                          <v-icon color="error"> mdi-delete </v-icon> Delete
                        </span>
                      </v-btn>
                    </span>
                  </div>
                  <div class="comparePartsActions">
                    Created: {{ j.created_date }}
                  </div>
                </div>
              </draggable>
            </div>
            <div
              style="
                display: -webkit-inline-box;
                overflow-x: scroll;
                max-width: 400px;
                flex-direction: row;
                width: 400px;
                min-width: 400px;
                padding-left: 5px;
                max-height: calc(100% - 25px);
                height: calc(100% - 25px);
                margin-top: 0px;
                overflow-x: hidden;
              "
              v-if="showSuggestions"
            >
              <div class="itemOperationsMenu">
                <v-btn
                  small
                  icon
                  @click="updateSuggestions"
                  v-if="suggestedItems.length > 0"
                >
                  <v-icon> mdi-chevron-triple-left </v-icon>
                </v-btn>
              </div>
              <div style="width: 345px; max-width: 345px">
                <SuggestionsPanel
                  ref="suggestionsPanel"
                  :part="suggestionsPart"
                  @close="closeSuggestions"
                  :docList="docTitleList"
                  @dragEnd="dragEnd"
                  @dragStart="initDrag"
                  @selectionUpdated="updateSuggestionSelection"
                ></SuggestionsPanel>
              </div>
            </div>
            <div
              style="
                display: -webkit-inline-box;
                overflow-x: scroll;
                max-width: 700px;
                flex-direction: row;
                width: 700px;
                min-width: 700px;
                padding-left: 5px;
                max-height: calc(100% - 25px);
                height: calc(100% - 25px);
                margin-top: 0px;
                overflow-x: hidden;
              "
              v-if="showDatabase"
            >
              <div class="itemOperationsMenu">
                <v-btn
                  small
                  icon
                  v-if="contentDatabaseData !== null"
                  @click="updateContentToAll"
                >
                  <v-icon> mdi-chevron-triple-left </v-icon>
                </v-btn>
              </div>
              <div style="width: 645px; max-width: 645px">
                <ContentDatabase
                  :data="{}"
                  :isNew="false"
                  :multidoc="true"
                  @dragEnd="dragEnd"
                  @closeDatabasePanel="closeDatabase"
                  @dragStart="initDrag"
                  @selectionUpdated="updateDatabaseSelection"
                ></ContentDatabase>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-navigation-drawer
          v-model="docPartFilter"
          absolute
          fixed
          temporary
          clipped
          style="width: 350px; z-index: 1; padding: 5px" :class="isDialogue ? 'dialogue-nav-draw' : ''"
        >
          <v-toolbar-title class="d-flex align-center pl-1 pt-5">
            <span class="ml-2">Filter By Doc Part</span>
          </v-toolbar-title>
          <v-select
            dense
            label="Document Parts"
            outlined
            :items="allParts"
            v-model="targetPart"
            @change="loadParts"
            class="pt-5"
          ></v-select>
        </v-navigation-drawer>
        <v-navigation-drawer
          v-model="compareFilter"
          absolute
          fixed
          temporary
          clipped
          style="width: 350px; z-index: 1; padding: 5px" :class="isDialogue ? 'dialogue-nav-draw' : ''"
        >
          <v-row dense class="pl-2 pt-5">
            <v-col cols="12">
              <span>Compare Document Parts</span>
            </v-col>
            <v-col cols="4" align="center">
              <v-select
                dense
                outlined
                :items="diffModes"
                item-text="text"
                item-value="value"
                v-model="diffType"
              ></v-select>
            </v-col>
            <v-col cols="4" align="right">
              <v-btn style="margin-top: 9px" small @click="compareTest"
                >Compare</v-btn
              >
            </v-col>
            <v-col cols="4" align="left">
              <span dense style="display: inline-flex; padding-top: 5px">
                <v-btn plain color="primary" @click="clearCompare">
                  <v-icon style="padding-right: 5px">mdi-close</v-icon> Clear
                </v-btn>
              </span>
            </v-col>
          </v-row>
          <v-row v-if="enableDocCompare" dense class="pl-2">
            <v-col cols="12">
              Compare Documents
              <v-chip class="ma-2" color="purple" label x-small>
                <span style="font-size: 10px; color: white">Beta</span>
              </v-chip>
            </v-col>
          </v-row>
        </v-navigation-drawer>
        <v-navigation-drawer
          v-model="viewFilter"
          absolute
          fixed
          temporary
          clipped
          style="width: 350px; z-index: 1; padding: 5px" :class="isDialogue ? 'dialogue-nav-draw' : ''"
        >
          <v-row dense class="pl-2 pt-5">
            <v-col cols="12" class="error--text">
              <b style="font-weight: bolder">Document List:</b>
            </v-col>
          </v-row>
          <v-row
            dense
            class="pl-2 pt-5"
            v-for="(doc, indx) in docDisplayItems"
            :key="indx"
          >
            <v-col dense cols="8">
              {{ doc.system_number }}
            </v-col>
            <v-col dense cols="4" align="right">
              <div style="width: 100%; display: flex">
                <div style="width: 25px; cursor: pointer" align="left">
                  <v-icon
                    @click="removeView('unlock', doc)"
                    title="Unlock Document"
                    dense
                    color="#ff8137"
                    v-if="doc.locked"
                    >mdi-lock</v-icon
                  >
                </div>
                <div style="width: 25px; cursor: pointer" align="center">
                  <v-icon
                    @click="removeView('view', doc)"
                    title="Restore View"
                    dense
                    color="green"
                    v-if="!doc.visible"
                    >mdi-eye-off</v-icon
                  >
                </div>
                <div style="width: 25px; cursor: not-allowed" align="right">
                  <v-icon
                    color="red"
                    title="Permissions locked"
                    v-if="doc.lockedByPermission"
                    dense
                    >mdi-cancel</v-icon
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-navigation-drawer>
        <v-navigation-drawer
          v-model="deleteFilters"
          absolute
          fixed
          temporary
          clipped
          style="width: 350px; z-index: 1; padding: 5px" :class="isDialogue ? 'dialogue-nav-draw' : ''"
        >
          <v-row dense class="pl-2 pt-5">
            <v-col cols="12"> </v-col>
          </v-row>
        </v-navigation-drawer>
        <v-navigation-drawer
          v-model="historyFilter"
          absolute
          fixed
          temporary
          clipped
          height="95vh"
          style="width: 450px; z-index: 1; padding: 5px; height: 95% !important" :class="isDialogue ? 'dialogue-nav-draw' : ''"
        >
          <!--<v-row dense class="pl-2 pt-5" >
              <v-col cols="3"><v-checkbox :indeterminate="allHistorySelected" @click="toggleAllHistory" v-model="historySelectAll"></v-checkbox></v-col>
              <v-col cols="9">
                <v-btn @click="undoHistory">
                  <v-icon icon>mdi-undo-variant</v-icon>
                </v-btn>
              </v-col>
            </v-row>-->
          <div>
            <v-row
              dense
              class="pl-2 pt-5"
              v-for="(history, indx) in historyItems"
              :key="indx"
            >
              <!--<v-col dense class="d-flex justify-center" cols="2" style="vertical-align:top">
                  <v-checkbox dense @click="toggleHistoryItem(history)" :value="history.selected"></v-checkbox>
                </v-col>-->
              <v-col cols="6" class="pt-2">
                {{ history.item.doc_part_type }}<br />{{ history.type }}
              </v-col>
              <v-col cols="4" class="pt-2">
                {{ history.doc.system_number }}
              </v-col>
              <v-col cols="2" class="pt-2">
                <v-icon @click="revertChange(history)">mdi-undo</v-icon>
              </v-col>
              <v-col v-if="history.type === 'Add'" cols="12" class="pt-2">
                <span v-html="history.item.text"></span>
              </v-col>
              <v-col v-if="history.type === 'Update'" cols="12" class="pt-2">
                <span v-html="history.diff.content"></span>
              </v-col>
            </v-row>
          </div>
        </v-navigation-drawer>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deleteConfirmDialog.show" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ deleteConfirmDialog.message }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteConfirmDialog.show = false"> Cancel </v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateDialog.show" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5" style="word-break: break-word">
          <span v-html="updateDialog.message"></span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="updateDialog.show = false"> Cancel </v-btn>
          <v-btn
            v-if="updateDialog.type === 'essentials'"
            color="green darken-1"
            text
            @click="confirmUpdateEssentials"
          >
            Update Essentials
          </v-btn>
          <v-btn
            color="red darken-1"
            v-if="updateDialog.type === 'data'"
            text
            @click="confirmUpdate"
          >
            Update
          </v-btn>
          <v-btn
            color="red darken-1"
            v-if="updateDialog.type === 'merge'"
            text
            @click="confirmMerge"
          >
            Merge
          </v-btn>
          <v-btn
            color="green darken-1"
            v-if="updateDialog.type === 'suggestions'"
            text
            @click="confirmUpdateSuggestions"
          >
            Add Suggestions
          </v-btn>
          <v-btn
            color="green darken-1"
            v-if="updateDialog.type === 'database'"
            text
            @click="confirmUpdateContent"
          >
            Add Content
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="historyUndoDialog.show" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ historyUndoDialog.message }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="historyUndoDialog.show = false"> Cancel </v-btn>
          <v-btn color="red darken-1" text @click="confirmUndo"> Undo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="compareLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-menu
      v-model="summaryMenu.show"
      :close-on-content-click="false"
      :close-on-click="false"
      offset-y
      offset-x
      :position-x="summaryMenu.x"
      :position-y="summaryMenu.y"
    >
      <v-card width="300px" height="420px" class="rm-context-menu">
        <v-card-title>
          Score Summary
          <v-spacer></v-spacer>
          <v-icon @click="summaryMenu.show = false" small right
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text style="padding-bottom: 0px; padding-left: 10px">
          <v-btn-toggle
            v-model="summaryMenu.display"
            tile
            color="deep-purple accent-3"
            group
          >
            <v-btn small value="category"> Categories </v-btn>
            <v-btn small value="part"> Parts </v-btn>
            <v-btn
              small
              value="detail"
              v-if="
                summaryMenu.partSummary !== null &&
                summaryMenu.partSummary.some((x) => x.type === this.targetPart)
              "
              @click="filterPartDetail"
            >
              Detail
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-card-text
          style="
            height: 330px;
            overflow: hidden;
            padding-top: 10px;
            padding-right: 5px;
          "
          scrollable
          v-if="summaryMenu.summaryMenuModel !== null"
        >
          <div
            style="height: 310px; overflow: hidden scroll; max-height: 310px"
          >
            <div v-if="summaryMenu.display === 'category'">
              <v-row dense>
                <v-col cols="10">Overall Score:</v-col>
                <v-col cols="2" align="right">{{
                  summaryMenu.summaryMenuModel.score
                }}</v-col>
              </v-row>
              <v-row
                dense
                v-for="(category, cIdx) in summaryMenu.summaryMenuModel
                  .categories"
                :key="cIdx"
              >
                <v-col cols="10">{{ category.category }}</v-col>
                <v-col cols="2" align="right">{{ category.score }}</v-col>
              </v-row>
            </div>
            <div v-if="summaryMenu.display === 'part'">
              <v-row
                dense
                v-for="(type, cIdx) in summaryMenu.partSummary"
                :key="cIdx"
              >
                <v-col cols="8">{{ type.type }}</v-col>
                <v-col cols="2">{{ type.count }}</v-col>
                <v-col cols="2" align="right">
                  <v-icon
                    v-if="type.type !== targetPart"
                    @click="setTargetPart(type.type)"
                    small
                    >mdi-text-box-search</v-icon
                  >
                </v-col>
              </v-row>
            </div>
            <div v-if="summaryMenu.display === 'detail'">
              Scoring Issues:<br />
              <v-row
                dense
                v-for="(issue, idx) in summaryMenu.partBreakdown.issues"
                :key="idx"
              >
                <v-col cols="8">{{ issue.issue }}</v-col>
                <v-col cols="4">{{ issue.count }}</v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <LanguageMenu
      :show="languageMenu.show"
      @close="closeLanguageMenu"
      :xCoord="languageMenu.x"
      :yCoord="languageMenu.y"
      :menu="languageMenu"
      @doSpellReplace="(data, source) => doSpellReplace(data, source)"
      @languageOptionsUpdate="(data) => languageOptionsUpdate(data)"
    ></LanguageMenu>
    <v-menu
      bottom
      left
      attach
      v-model="externalContentMenu.show"
      :value="externalContentMenu.show"
      :close-on-content-click="false"
      :nudge-width="200"
      :top="externalContentMenu.lookupTop"
      :position-y="externalContentMenu.lookupPosY"
      :position-x="externalContentMenu.lookupPosX"
      :max-width="externalContentMenu.lookupWidth"
      :min-width="externalContentMenu.lookupWidth"
      max-height="300px"
      height="300px"
      offset-x
      content-class="elevation-2 userPilotSuggestionsMenu"
    >
      <AIMenu :data="aiMenuData" @setValue="setValue"></AIMenu>
    </v-menu>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import moment from "moment";
import utils from "@/common/utils.js";
import LanguageMenu from "@/components/common/LanguageMenu";
import TextInput from "@/components/common/TextInput";
import LookupInput from "@/components/common/LookupInput";
import SuggestionsPanel from "@/components/common/SuggestionsPanel";
import nlpUtils from "@/common/nlpUtils.js";
import { mapState } from "vuex";
import AIMenu from "@/components/document/view/AI_Function_Menu.vue";
import DocStatus from "@/components/common/DocStatus";

import ContentDatabase from "@/components/common/ContentDatabase";
//import HierarchySearch from "@/components/cHierarchySearch";
var JsDiff = require("diff");

export default {
  name: "DocSideBySide",
  components: {
    LanguageMenu,
    TextInput,
    SuggestionsPanel,
    LookupInput,
    AIMenu,
    ContentDatabase,
    DocStatus,
    draggable, //,HierarchySearch
  },
  props: {
    items: Array,
    statuses: Array,
    isDialogue: Boolean,
  },
  data: function () {
    return {
      displaySideMenu: true,
      showSuggestions: false,
      showDatabase: false,
      testMenuValue: false,
      docCompareItems: [],
      targetPart: "Responsibility",
      targetPartDetails: {},
      allParts: [],
      pinnedItems: [],
      pinnedItem: null,
      diffModes: [
        { text: "Chars", value: "diffChars" },
        { text: "Words", value: "diffWords" },
      ],
      diffType: "diffWords",
      compareLoading: false,
      expandHover: false,
      drawer: true,
      docPartFilter: false,
      compareFilter: false,
      viewFilter: false,
      deleteFilters: false,
      selectedActiveParts: [],
      items2: [
        {
          title: "Hierarchies",
          icon: "mdi-align-horizontal-left",
          active: true,
        },
        {
          title: "Doc Parts",
          icon: "mdi-text-box-search",
          active: false,
        },
        {
          title: "Comparison",
          icon: "mdi-file-compare",
          active: false,
        },
      ],
      enableDocCompare: false,
      deleteConfirmDialog: {
        message: "",
        show: false,
        data: [],
      },
      updateDialog: {
        message: "",
        type: "data",
        show: false,
        data: [],
      },
      historyUndoDialog: {
        message: "",
        show: false,
        data: [],
      },
      historySelectAll: false,
      historyFilter: false,
      historyItems: [],
      allCompareData: [],
      allChecksData: [],
      isComparing: false,
      summaryMenu: {
        show: false,
        x: 0,
        y: 0,
        summaryDoc: null,
        summaryMenuModel: null,
        partSummary: null,
        display: "category",
      },
      essentialFlags: [
        {
          value: true,
          text: "Essential",
        },
        {
          value: false,
          text: "Non Essential",
        },
      ],
      languageMenu: {
        show: false,
        x: 0,
        y: 0,
        textStatus: null,
        element: null,
        match: null,
      },
      dragging: false,
      suggestedItems: [],
      currentDragTarget: null,
      useInlineEditor: true,
      useSpellCheck: false,
      externalContentMenu: {
        show: false,
        activeNode: false,
        loading: false,
        suggestionType: null,
        lookupMaxHeight: 300,
        lookupWidth: 500,
        lookupTop: false,
        lookupPosY: 0,
        lookupPosX: 0,
        lookupsToShow: 20,
        lookupsPageSize: 20,
        lookupData: [],
      },
      aiMenuData: null,
      contentDatabaseData: null,
      hiddenDocsMenu: false,
      lockedDocsMenu: false,
    };
  },
  watch: {
    items(val) {
      this.docCompareItems = val;
      this.initCompare();
    },
  },
  mounted() {
    if (this.items.length) {
      this.docCompareItems = this.items;
      this.initCompare();
    }
  },
  computed: {
    docTitleList() {
      return this.activeDocs.map((x) => {
        return {
          doc_name: x.doc_name,
          doc_id: x.doc_id,
        };
      });
    },
    suggestionsPart() {
      return {
        tmpl_part_id: this.targetPartDetails.tmpl_part_id,
        type: this.targetPartDetails.type,
        multiple: this.isMultiPart,
      };
    },
    allHistorySelected() {
      let selected = this.historyItems.filter((x) => x.selected);
      return (
        selected.length > 0 &&
        selected.length < this.historyItems.length &&
        selected.length !== this.historyItems.length
      );
    },
    docDisplayItems() {
      return this.docCompareItems
        .filter(
          (x) =>
            x.visible === false ||
            x.lockedByPermission === true ||
            x.locked === true
        )
        .map((x) => {
          return {
            doc_id: x.doc_id,
            system_number: x.system_number,
            visible: x.visible,
            locked: x.locked,
            lockedByPermission: x.lockedByPermission,
          };
        });
    },
    validDocumentRange() {
      return this.docCompareItems.filter(
        (x) =>
          !x.pinned &&
          x.visible === true &&
          x.lockedByPermission === false &&
          x.locked === false
      );
    },
    editableDocs() {
      return this.docCompareItems.filter(
        (x) => !x.locked && !x.lockedByPermission && x.visible
      );
    },
    activeDocs() {
      let list = this.docCompareItems.filter(
        (x) => x.visible === true && !x.pinned
      );
      return list;
    },
    hiddenDocs() {
      let list = this.docCompareItems.filter((x) => x.visible === false);
      return list;
    },
    lockedDocs() {
      let list = this.docCompareItems.filter((x) => x.locked === true);
      return list;
    },
    selectedDocs() {
      return this.selectedActiveParts.length > 0;
    },
    multipleSelectedDocs() {
      let list = [
        ...new Set(this.selectedActiveParts.map((item) => item.doc_id)),
      ];
      return list.length > 1;
    },
    isMultiPart() {
      if(this.compareLoading) return;
      return (
        this.targetPartDetails.cardinality.maxError > 1 ||
        this.targetPartDetails.cardinality.minError > 1 ||
        this.targetPartDetails.cardinality.maxWarn > 1 ||
        this.targetPartDetails.cardinality.minWarn > 1
      );
    },
    ...mapState({
      config: (state) => state.settings.config,
    }),
    pinnedDoc() {
      return this.docCompareItems?.find((x) => x.pinned) ?? null;
    },
    allowSummarise() {
      return this.config.contentGenerationConfig?.enableSummarise || false;
    },
    useOpenAISuggestions() {
      return this.config.contentGenerationConfig?.enablePartSuggestions;
    },
  },
  methods: {
    updateDatabaseSelection(item) {
      this.contentDatabaseData = item === "" ? null : item;
    },
    onChange() {
      this.reorderDocs();
    },
    reorderDocs() {
      this.docCompareItems.forEach(
        (item, index) => (item.position_order = index)
      );
    },
    async setValue(value) {
      this.externalContentMenu.show = false;

      let sourceDoc = this.docCompareItems.find(
        (x) => x.doc_id === this.externalContentMenu.doc
      );
      let sourceItem = sourceDoc.activeParts.find(
        (x) => x.doc_part_id === this.externalContentMenu.part.doc_part_id
      );
      if (sourceItem) {
        let attr = null;
        if (
          this.externalContentMenu.item.tmpl_part_metadata.attributes.length >=
          1
        ) {
          attr =
            this.externalContentMenu.part.attributes[
              this.externalContentMenu.part.attributes.length - 1
            ];
        }
        sourceItem.previousValue = sourceItem.text;
        await this.saveItemTextInline(
          { text: value, highlightedText: value },
          sourceDoc,
          sourceItem,
          attr
        );
      }

      //let resp = await this.updateDocPart({text: value}, attr, this.externalContentMenu.item, this.externalContentMenu.part, true);
    },
    doAiFunction(doc, part, functionType) {
      this.externalContentMenu.show = false;
      this.externalContentMenu.lookupTop = false;
      this.suggestionsLoading = true;
      //j.doc_id + '|' + item.doc_part_id
      let containerRect =
        this.$refs[
          `menuHolder_${doc.doc_id}|${part.doc_part_id}`
        ][0].getBoundingClientRect();
      //let container = containerRect;

      let xOffset = this.isDialogue ? 13 : doc.pinned ? 112 : 82;
      let yOffset = this.isDialogue ? -2 : doc.pinned ? -45 : 45;

      this.lookupWidth = containerRect.right - containerRect.left + 15;
      this.lookupPosX =
        containerRect.left +
        (containerRect.right - containerRect.left + 15) -
        xOffset;
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      //show below textara unless textarea is at bottom of page
      if (
        containerRect.bottom + this.externalContentMenu.lookupMaxHeight + 2 <
        vh
      ) {
        this.lookupPosY = containerRect.bottom - yOffset;
        this.lookupTop = false;
      } else {
        containerRect =
          this.$refs[
            `menuTopHolder_${doc.doc_id}|${part.doc_part_id}`
          ][0].getBoundingClientRect();
        this.lookupPosY = containerRect.top - yOffset;
        this.lookupTop = true;
      }

      this.externalContentMenu = {
        ...this.externalContentMenu,
        show: true,
        lookupTop: this.lookupTop,
        lookupPosY: this.lookupPosY,
        lookupPosX: this.lookupPosX,
        lookupWidth: 320,
        part: part,
        item: this.targetPartDetails,
        doc: doc.doc_id,
      };

      if (functionType === "suggestions") {
        this.suggestionType = this.targetPart;
      }

      this.aiMenuData = {
        function: functionType,
        language: "English", //todo pass in lang
        jobTitle: doc.doc_name,
        text: part.text,
        docLanguage: doc.docLanguage,
        suggestionType: this.suggestionType,
      };
    },
    async languageOptionsUpdate(source) {
      console.log(source);
      let ref =
        this.$refs["inline_" + source.doc_id + "_" + source.doc_part_id];
      if (ref) {
        ref[0].rehighlight();
      }
      //this.$loginState.user.spellCheck = data;
      /*let sourceDoc = this.docCompareItems.find(x => x.doc_id === source.doc_id);
      let sourceItem = sourceDoc.activeParts.find(x => x.doc_part_id === source.doc_part_id);
      if(sourceItem){
        sourceItem.forceRefresh = true;
        this.refreshDocs();
        await this.$nextTick();
        sourceItem.forceRefresh = false;
      }*/
    },
    toggleInline() {
      if (this.useInlineEditor) {
        this.refreshDocs();
      }
    },
    async doSpellReplace(data, source) {
      let sourceDoc = this.docCompareItems.find(
        (x) => x.doc_id === source.doc_id
      );
      let sourceItem = sourceDoc.activeParts.find(
        (x) => x.doc_part_id === source.doc_part_id
      );
      if (sourceItem) {
        this.closeLanguageMenu();
        let attr = null;
        if (source.dpa_id) {
          attr = sourceItem.attributes.find((x) => x.dpa_id === source.dpa_id);
        }
        sourceItem.previousValue = sourceItem.text;
        await this.saveItemTextInline(
          { text: data, highlightedText: data },
          sourceDoc,
          sourceItem,
          attr
        );
      }
    },
    updateContentToAll() {
      let dragItems = this.isMultiPart
        ? this.getSplitParts(this.contentDatabaseData)
        : [this.contentDatabaseData];

      let items = this.editableDocs.map((x) => {
        return {
          doc_id: x.doc_id,
          items: dragItems,
        };
      });
      this.updateDialog = {
        message: `Are you sure you want to add ${dragItems.length} items to ${
          this.editableDocs.length
        } document${this.editableDocs.length === 1 ? "" : "s"}?`,
        type: "database",
        show: true,
        data: items,
      };
    },
    updateSuggestions() {
      let items = this.editableDocs.map((x) => {
        return {
          doc_id: x.doc_id,
          items: this.suggestedItems,
        };
      });
      this.updateDialog = {
        message: `Are you sure you want to add ${
          this.suggestedItems.length
        } items to ${this.editableDocs.length} document${
          this.editableDocs.length === 1 ? "" : "s"
        }?`,
        type: "suggestions",
        show: true,
        data: items,
      };
    },
    async confirmUpdateContent() {
      let partType = this.targetPart;
      let processList = [];

      this.updateDialog.data.forEach((i) => {
        let sourceItem = this.docCompareItems.find(
          (x) => x.doc_id === i.doc_id
        );
        if (sourceItem) {
          sourceItem.isUpdating = true;

          i.items.forEach((s) => {
            let targetPart = sourceItem.activeParts[0];
            let type = "save";

            if (this.isMultiPart) {
              targetPart = this.addNewRow(
                sourceItem,
                false,
                s.trim(),
                "",
                "",
                0,
                false
              );
              type = "save";
            }
            targetPart.text = s.trim();

            let attr = null;
            if (
              this.targetPartDetails.tmpl_part_metadata.attributes.length >= 1
            ) {
              attr = targetPart.attributes[targetPart.attributes.length - 1];
              attr.text = s.trim();
              targetPart.text = utils.attributesToText(
                this.targetPartDetails.tmpl_part_metadata.attributes,
                targetPart.attributes
              );
            }

            processList.push({
              doc: sourceItem,
              item: targetPart,
              text: s.trim(),
              attr: attr,
              operationType: type,
            });
          });

          /*i.items.forEach(s => {
              if(!sourceItem.activeParts.some(x => x.text.trim() === s.trim()))
              {
                sourceItem.isUpdating = true;
                let newRow = this.addNewRow(sourceItem, false, s.trim(), "", "", 0, false);
                processList.push({
                  doc: sourceItem,
                  item: newRow,
                  operationType: "save"
                })  
              }
            })*/
        }
      });

      if (processList.length) {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
        await Promise.all(
          processList.map(async (update) => {
            switch (update.operationType) {
              case "save":
                return await this.updateDocPart(
                  update.doc,
                  update.item,
                  update.item.sequence,
                  partType,
                  true,
                  update.attr
                );
              /*case "update":
                return this.saveItemTextInline({ text: update.text, highlightedText: update.text }, update.doc, update.item, null, true);*/
            }
          })
        ).then((resps) => {
          this.processResults(resps);
          this.clearCompare();
          //this.clearSelected();
          /*let ref = this.$refs["suggestionsPanel"];
          if(ref){
            ref.clearSelection();
            this.dragEnd();
          }  */
        });
      } else {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
      }
    },
    async confirmUpdateSuggestions() {
      let partType = this.targetPart;
      let processList = [];
      if (!this.isMultiPart) {
        //todo
      } else {
        this.updateDialog.data.forEach((i) => {
          let sourceItem = this.docCompareItems.find(
            (x) => x.doc_id === i.doc_id
          );
          if (sourceItem) {
            i.items.forEach((s) => {
              if (
                !sourceItem.activeParts.some((x) => x.text.trim() === s.trim())
              ) {
                sourceItem.isUpdating = true;
                let newRow = this.addNewRow(
                  sourceItem,
                  false,
                  s.trim(),
                  "",
                  "",
                  0,
                  false
                );

                let attr = null;
                if (
                  this.targetPartDetails.tmpl_part_metadata.attributes.length >=
                  1
                ) {
                  attr = newRow.attributes[newRow.attributes.length - 1];
                  attr.text = s.trim();
                  newRow.text = utils.attributesToText(
                    this.targetPartDetails.tmpl_part_metadata.attributes,
                    newRow.attributes
                  );
                }

                processList.push({
                  doc: sourceItem,
                  item: newRow,
                  text: s.trim(),
                  attr: attr,
                  operationType: "save",
                });
              }
            });
          }
        });
      }

      if (processList.length) {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
        await Promise.all(
          processList.map(async (update) => {
            switch (update.operationType) {
              case "save":
                return await this.updateDocPart(
                  update.doc,
                  update.item,
                  update.item.sequence,
                  partType
                );
            }
          })
        ).then((resps) => {
          this.processResults(resps);
          this.clearCompare();
          this.clearSelected();
          let ref = this.$refs["suggestionsPanel"];
          if (ref) {
            ref.clearSelection();
            this.dragEnd();
          }
        });
      } else {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
      }
    },
    updateSuggestionSelection(data) {
      this.suggestedItems = data;
    },
    dragEnd() {
      this.dragging = false;
    },
    initDrag() {
      //console.log("dragging...");
      this.dragging = true;
    },
    highlightDragTarget(doc, event) {
      event.stopImmediatePropagation();
      this.currentDragTarget = doc;
      //console.log(event);
      //event.stopImmediatePropagation();
      //this.dragTargetPart = p.tmpl_part_id;
    },
    dragOverStart() {
      //console.log(event);
    },
    dropItem(id, event) {
      let sourceItem = this.docCompareItems.find((s) => s.doc_id === id);
      if (sourceItem) {
        if (event.dataTransfer.types.indexOf("array") > -1) {
          let dataObj = JSON.parse(event.dataTransfer.getData("array"));
          if (!dataObj) return;
          this.processDragEvent(sourceItem, dataObj);
        } else if (event.dataTransfer.types.indexOf("object") > -1) {
          let dataObj = JSON.parse(event.dataTransfer.getData("object"));
          if (!dataObj) return;
          this.processDatabaseDrag(sourceItem, dataObj);
        }

        this.docCompareItems.forEach(
          (item, index) => (item.position_order = index)
        );
      }
    },
    stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },
    getSplitParts(html) {
      let splitTags = ["<br/>", "<br>", "/r/n", "• ", "· "];

      let containsTags = [
        "<tr.*?><td.*?><p.*?>(.*?)</p></td></tr>",
        "<li.*?>(.*?)</li>",
        "<p.*?>(.*?)</p>",
        "<div.*?>(.*?)</div>",
      ];

      let config = this.$loginState.user.settings.find(
        (x) => x.setting === "database_drag_splitRules"
      );
      if (config) {
        let configData = JSON.parse(config.value);
        splitTags = configData.splitTags;
        containsTags = configData.containsTags;
      }

      let split = false;
      let splitResults = [];

      containsTags.forEach((c) => {
        if (!split) {
          let reg = new RegExp(c, "gim");
          let result = html.match(reg);
          if (result?.filter((x) => x !== "").length > 1) {
            splitResults = result;
            split = true;
          }
        }
      });

      splitTags.forEach((s) => {
        if (!split) {
          let results = html.split(s);
          if (results?.filter((x) => x !== "").length > 1) {
            splitResults = results;
            split = true;
          }
        }
      });

      if (splitResults.length > 0) {
        return this.cleanItems(splitResults);
      } else {
        return [];
      }
    },
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    cleanItems(values) {
      let replaceList = ["/r", "/n", "/r/n", "<br/>", "<br>", "• ", "· "];

      let config = this.$loginState.user.settings.find(
        (x) => x.setting === "database_drag_splitRules"
      );
      if (config) {
        let configData = JSON.parse(config.value);
        replaceList = configData.splitTags;
      }

      let cleansedItems = values
        .map((x) => {
          let item = this.stripHtml(x);
          replaceList.forEach((r) => {
            item = item.replace(r, "");
          });
          return item.trim();
        })
        .filter((x) => x !== "");
      return cleansedItems;
    },
    processDatabaseDrag(source, data) {
      let dragItems = this.isMultiPart
        ? this.getSplitParts(data.html)
        : [data.text];

      let items = [
        {
          doc_id: source.doc_id,
          items: dragItems,
        },
      ];

      this.updateDialog = {
        message: `Are you sure you want to add ${dragItems.length} item${
          dragItems.length === 1 ? "" : "s"
        } to ${source.system_number}?`,
        type: "database",
        show: true,
        data: items,
      };
    },
    processDragEvent(source, data) {
      let items = [
        {
          doc_id: source.doc_id,
          items: data.map((x) => {
            return x.varVal;
          }),
        },
      ];
      this.updateDialog = {
        message: `Are you sure you want to add ${data.length} item${
          data.length === 1 ? "" : "s"
        } to ${source.system_number}?`,
        type: "suggestions",
        show: true,
        data: items,
      };
    },
    closeSuggestions() {
      this.showSuggestions = false;
    },
    showSuggestionsPanel() {
      if (this.showDatabase) {
        this.closeDatabase();
      }
      this.showSuggestions = !this.showSuggestions;
    },
    closeDatabase() {
      this.showDatabase = false;
    },
    showDatabasePanel() {
      if (this.showSuggestions) {
        this.closeSuggestions();
      }
      this.showDatabase = !this.showDatabase;
    },
    async confirmUpdateEssentials() {
      let processList = [];

      this.updateDialog.data.forEach((x) => {
        let sourceItem = this.docCompareItems.find(
          (s) => s.doc_id === x.doc.doc_id
        );
        let value = x.value;
        if (sourceItem) {
          sourceItem.isUpdating = true;
          x.items.forEach((z) => {
            let item = sourceItem.activeParts.find((a) => a.doc_part_id === z);
            if (item) {
              item.is_essential = value;
              processList.push({
                doc: x.doc,
                item: item,
              });
            }
          });
        }
      });

      this.updateDialog = {
        message: "",
        type: "data",
        show: false,
        data: [],
      };

      await Promise.all(
        processList.map(async (x) => {
          return await this.updateDocPart(
            x.doc,
            x.item,
            x.item.sequence,
            this.targetPart
          );
        })
      ).then((resps) => {
        this.processResults(resps);
        this.clearSelected();
      });
    },
    updateEssentials(doc) {
      let refValue = this.$refs["essentials_" + doc.doc_id];
      let value = Array.isArray(refValue)
        ? this.$refs["essentials_" + doc.doc_id][0].lazyValue
        : refValue.lazyValue;
      if (value === undefined) {
        return;
      }

      let items = doc.activeParts.some((x) => x.selected)
        ? doc.activeParts.filter((x) => x.selected)
        : doc.activeParts;
      let ignored = [];

      ignored = items.filter((x) => x.is_essential === value);

      if (ignored.length > 0) {
        items = items.filter((x) => !ignored.includes(x));
      }

      if (items.length > 0) {
        let updateMessage = `Are you sure you want to set the Essential Flag on ${
          items.length
        } item${items.length === 1 ? "" : "s"} to ${
          value ? "Essential" : "Non Essential"
        }?`;
        if (ignored.length > 0) {
          updateMessage += `<br/><span style="font-size:14px;"><i>Ignoring ${
            ignored.length
          } item${
            ignored.length === 1 ? "" : "s"
          } as the Essential Flag is already set to ${
            value ? "Essential" : "Non Essential"
          }</i></span>`;
        }

        let dataItem = {
          doc: doc,
          value: value,
          items: items.map((x) => x.doc_part_id),
        };

        this.updateDialog = {
          message: updateMessage,
          type: "essentials",
          show: true,
          data: [dataItem],
        };
      } else {
        this.$emit("triggerNotification", "No items to update!", "warning");
      }
    },
    async updateEssential(doc, item) {
      doc.isUpdating = true;
      await this.updateDocPart(doc, item, item.sequence, this.targetPart).then(
        (resps) => {
          this.processResults([resps]);
        }
      );
    },
    openLanguageMenu(doc, data, item, part, attr) {
      //console.log(`openLanguageMenu: ${data.element.title} | ${data.coords.x} | ${data.coords.y} `)
      let allIssues = [...data.status.errors, ...data.status.warnings];
      let issue = allIssues.find(
        (x) => x.short_description === data.element.title
      );
      this.languageMenu = {
        show: true,
        x: data.coords.x,
        y: data.coords.y,
        textStatus: data.status,
        element: issue,
        doc_id: doc.doc_id,
        item: item,
        part: part,
        attr: attr,
        ...data,
      };
      //this.languageMenu = JSON.parse(JSON.stringify(this.languageMenu));
    },
    togglePanel(type) {
      //this.$nextTick(() => {
      this[type] = !this[type];
      //})

      //event.stopPropagation();
      //event.preventDefault();
    },
    closeLanguageMenu() {
      //this.$nextTick(() => {
      this.languageMenu.show = false;
      //})
    },
    menuTest() {
      this.$nextTick(() => {
        this.languageMenu.show = true;
      });
    },
    filterPartDetail() {
      let type = this.summaryMenu.partBreakdown.find(
        (x) => x.type === this.targetPart
      );
      this.summaryMenu.partBreakdown = type
        ? type
        : { type: this.targetPart, issues: [] };
    },
    setTargetPart(type) {
      this.targetPart = type;
      this.loadParts();
      let sourceItem = this.docCompareItems.find(
        (x) => x.doc_id === this.summaryMenu.summaryDoc
      );
      if (sourceItem) {
        this.setSummaryData(sourceItem);
      }
    },
    getIssuesByPart(partList, instance) {
      if (instance.check !== "row_count") {
        let parts = instance.instances.map((x) => x.partsAffected).flat();
        let results = parts.map((x) => {
          let source = partList.find((r) => r.parts.includes(x));
          return source
            ? {
                type: source.type,
                title: instance.title,
              }
            : null;
        });
        return results.filter((x) => x !== null).flat();
      } else {
        let initialPart = instance.instances[0].partsAffected[0];
        let source = partList.find((r) => r.parts.includes(initialPart));
        if (source) {
          return {
            type: source.type,
            title: instance.title,
          };
        }
      }
    },
    getPartSummary(parts, summary) {
      let partList = parts.map((x) => {
        return {
          type: x.type,
          parts: x.parts.map((z) => {
            return z.doc_part_id;
          }),
        };
      });
      let childPartList = parts
        .filter((x) => x.childPartTypes.length > 0)
        .map((x) => x.parts.filter((z) => z.childParts.length > 0))
        .flat()
        .map((c) => {
          return c.childParts;
        })
        .flat();
      let result = childPartList.reduce((arr, cp) => {
        const values = arr[cp.dp_name];
        arr[cp.dp_name] = values
          ? [...values, cp.doc_part_id]
          : [cp.doc_part_id];
        return arr;
      }, {});
      let childParts = Object.entries(result).map(([type, parts]) => ({
        type,
        parts,
      }));

      let allParts = [...partList, ...childParts];

      let partSummary = [
        ...summary.warnings.map((w) => {
          return this.getIssuesByPart(allParts, w);
        }),
        ...summary.errors.map((e) => {
          return this.getIssuesByPart(allParts, e);
        }),
      ];

      return Object.entries(
        partSummary.flat().reduce(function (acc, curr) {
          let key = acc[curr.type];
          if (!key) {
            return (
              (acc[curr.type] = [
                {
                  issue: curr.title,
                  count: 1,
                },
              ]),
              acc
            );
          } else {
            let exists = key.find((x) => x.issue === curr.title);
            return (
              exists
                ? { ...exists, count: exists.count + 1 }
                : key.push({
                    issue: curr.title,
                    count: 1,
                  }),
              acc
            );
          }
        }, {})
      ).map(([type, issues]) => ({ type, issues }));
    },
    setSummaryData(sourceItem) {
      let summaryDetails = this.getPartSummary(
        sourceItem.parttypes,
        sourceItem.checkSummary
      );
      let partOverview = summaryDetails.map((x) => {
        return {
          type: x.type,
          count: x.issues.length,
        };
      });
      this.summaryMenu.summaryMenuModel = sourceItem.checkSummary;
      this.summaryMenu.partSummary = partOverview;
      this.summaryMenu.partBreakdown = summaryDetails;
    },
    openSummaryMenu(doc_id, event) {
      let sourceItem = this.docCompareItems.find((x) => x.doc_id === doc_id);
      if (sourceItem) {
        this.summaryMenu.display = "category";
        this.setSummaryData(sourceItem);
        this.summaryMenu.summaryDoc = doc_id;

        this.summaryMenu.x = event.clientX;
        this.summaryMenu.y = event.clientY;
        this.summaryMenu.show = true;
      }
    },
    getAllChecks(tmpl_id) {
      let allchecks = this.allChecksData.find((x) => (x.tmpl_id = tmpl_id));
      let tempChecks = allchecks.checks.map((object) => ({ ...object }));
      return utils.initialisePartChecks(tempChecks);
    },
    getWordChecksForPart(tpid, checks, hchys, tmpl_id, attr) {
      let allchecks = this.allChecksData.find((x) => (x.tmpl_id = tmpl_id));
      let tempChecks = allchecks.checks.map((object) => ({ ...object }));
      let filtered = tempChecks.filter((w) =>
        w.tmpl_part_types.some(
          (tp) =>
            tp.tp_id === tpid &&
            (!tp.exclusions.length ||
              !hchys.some((h) =>
                tp.exclusions.some((e) => e.hr_id === h.hr_id)
              ))
        )
      );
      if (attr && attr.skip_checks.length > 0) {
        filtered = filtered.filter(
          (x) => !attr.skip_checks.includes(x.check_type_label)
        );
      }
      return utils.initialisePartChecks(filtered);
    },
    updateInlineText(doc, item, orig) {
      let checks = this.getWordChecksForPart(
        item.tmpl_part_id,
        doc.wordChecks,
        doc.docHierarchies,
        doc.tmpl_id
      );
      let textStatus = utils.applyHighlights(
        orig.srcElement.innerText,
        checks,
        true,
        false,
        doc
      );

      item.text = orig.srcElement.innerText;
      item.highlightedText = textStatus.highlightedText;
    },
    async revertChange(history) {
      let processList = [];
      let sourceItem = this.docCompareItems.find(
        (x) => x.doc_id === history.doc.doc_id
      );
      if (sourceItem) {
        sourceItem.isUpdating = true;
      }

      if (history.type === "Update") {
        let newItem = history.item;
        newItem.text = history.diff.old;
        processList.push({
          doc: history.doc,
          item: newItem,
          operationType: "save",
        });
      } else if (history.type === "Add") {
        processList.push({
          doc: history.doc,
          item: history.item,
          operationType: "remove",
        });
      } else if (history.type === "Delete") {
        let sourcePart = sourceItem.parttypes.find(
          (p) => p.type === history.item.doc_part_type
        );
        let seq =
          Math.max(
            ...sourcePart.parts.map((x) => {
              return x.sequence;
            })
          ) + 1;
        processList.push({
          doc: history.doc,
          item: history.item,
          operationType: "revert",
          sequence: seq,
        });
      }

      let idx = this.historyItems.indexOf(history);
      this.historyItems.splice(idx, 1);
      this.historyItems = JSON.parse(JSON.stringify(this.historyItems));

      if (this.historyItems.length === 0) {
        this.historyUndoDialog = {
          message: "",
          show: false,
          data: [],
        };
        this.historyFilter = false;
      }

      let partType = history.item.doc_part_type;

      await Promise.all(
        processList.map(async (update) => {
          switch (update.operationType) {
            case "save":
              return await this.updateDocPart(
                update.doc,
                update.item,
                update.item.sequence,
                partType,
                false
              );
            case "remove":
              return await this.removeDocPart(
                update.doc,
                update.item.doc_part_id,
                false
              );
            case "revert":
              return await this.undoRemoveDocPart(
                update.doc,
                update.item.doc_part_id,
                update.sequence
              );
          }
        })
      ).then((resps) => {
        this.processResults(resps);
      });
    },
    confirmUndo() {
      this.historyUndoDialog = {
        message: "",
        show: false,
        data: [],
      };
    },
    undoHistory() {
      let items = this.historyItems.filter((x) => x.selected);
      this.historyUndoDialog = {
        message: `Are you sure you want to undo ${items.length} action(s)`,
        show: true,
        data: items,
      };
    },
    toggleAllHistory() {
      this.historyItems = this.historyItems.map((x) => {
        return {
          ...x,
          selected: this.historySelectAll,
        };
      });
    },
    toggleHistoryItem(item) {
      item.selected = !item.selected;
      this.historyItems = JSON.parse(JSON.stringify(this.historyItems));
    },
    openContext($event, j) {
      this.$emit("openContext", $event, j);
    },
    async confirmMerge(){
      let source = this.pinnedDoc;
      let processList = [];
      this.updateDialog.data.items.forEach((i) => { 
        source.isUpdating = true;
        let newRow = this.addNewRow(
          source,
          false,
          i,
          this.targetPart,
          this.targetPartDetails.tmpl_part_id,
          0,
          true,
          null
        );
        processList.push({
          doc: source,
          item: newRow,
          operationType: "save",
        });
      })
      let partType = this.targetPart;

      if (processList.length) {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
        await Promise.all(
          processList.map(async (update) => {
            switch (update.operationType) {
              case "save":
                return await this.updateDocPart(
                  update.doc,
                  update.item,
                  update.item.sequence,
                  partType
                );
            }
          })
        ).then((resps) => {
          this.processResults(resps);
        });
      } else {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
      }

    },
    async confirmUpdate() {
      let source = this.pinnedDoc;
      let processList = [];
      if (!this.isMultiPart) {
        let newText = source.activeParts[0].text;
        let validList = this.updateDialog.data.items.filter(
          (x) => x.activeParts[0].text !== newText
        );
        validList.forEach((i) => {
          let sourceItem = this.docCompareItems.find(
            (x) => x.doc_id === i.doc_id
          );
          if (sourceItem) {
            sourceItem.activeParts[0].previousValue =
              sourceItem.activeParts[0].text;
            sourceItem.isUpdating = true;
          }
        });
        processList = validList.map((x) => {
          return {
            doc: x,
            item: { ...x.activeParts[0], text: newText },
            operationType: "save",
          };
        });
      } else {
        let activeParts = source.activeParts.filter((x) => x.selected);
        this.updateDialog.data.items.forEach((i) => {
          let sourceItem = this.docCompareItems.find(
            (x) => x.doc_id === i.doc_id
          );
          if (sourceItem) {
            activeParts.forEach((p) => {
              if (
                !sourceItem.activeParts.some(
                  (x) => x.text.trim() === p.text.trim()
                )
              ) {
                sourceItem.isUpdating = true;
                let newRow = this.addNewRow(
                  sourceItem,
                  false,
                  p.text,
                  this.targetPart,
                  p.tmpl_part_id,
                  0,
                  true,
                  p
                );
                //let cloned = this.addNewRow(sourceItem,false,source.text, newItem.dp_name, tmpl_part_id, Number(newItem.doc_part_id), false, source)
                processList.push({
                  doc: sourceItem,
                  item: newRow,
                  operationType: "save",
                });
              }
            });
          }
        });
      }
      let partType = this.targetPart;

      if (processList.length) {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
        await Promise.all(
          processList.map(async (update) => {
            switch (update.operationType) {
              case "save":
                return await this.updateDocPart(
                  update.doc,
                  update.item,
                  update.item.sequence,
                  partType
                );
            }
          })
        ).then((resps) => {
          this.processResults(resps);
          this.clearCompare();
          this.clearSelected();
        });
      } else {
        this.updateDialog = {
          message: "",
          type: "data",
          show: false,
          data: [],
        };
      }
    },
    mergeAll(){
      let items = [...new Set(this.validDocumentRange.map(r => {return r.activeParts.map(p => { return p.text}).flat(1)}).flat(1))];
      let pinnedItems = [...new Set(this.pinnedDoc.activeParts.map(p => { return p.text}))];
      let updateItems = items.filter(x => !pinnedItems.includes(x));
      let ignItems = items.filter(x => pinnedItems.includes(x));
      if(updateItems.length === 0) return;
      let msg = `Are you sure you want to merge ${updateItems.length} item${updateItems.length === 1 ? '' : 's'}?`;
      if(ignItems.length > 0){
        msg += `<br/>Ignoring ${ignItems.length} item${ignItems.length === 1 ? '' : 's'}`
      }
      this.updateDialog = {
        message: msg,
        type: "merge",
        show: true,
        data: {
          items: updateItems,
        },
      };
    },
    doUpdateAll() {
      let items = this.validDocumentRange;
      this.updateDialog = {
        message: `Are you sure you want to update ${items.length} items?`,
        type: "data",
        show: true,
        data: {
          items: items,
        },
      };
    },
    removeView(type, doc) {
      let sourceItem = this.docCompareItems.find(
        (x) => x.doc_id === doc.doc_id
      );
      if (sourceItem) {
        switch (type) {
          case "unlock":
            sourceItem.locked = false;
            break;
          case "view":
            sourceItem.visible = true;
            break;
        }
      }
      this.refreshDocs();
      if (this.docDisplayItems.length === 0) {
        this.viewFilter = false;
      }
    },
    toggleAllParts(doc) {
      let sourceItem = this.docCompareItems.find(
        (x) => x.doc_id === doc.doc_id
      );
      if (sourceItem) {
        let val = doc.allSelected ? true : false;
        sourceItem.activeParts.forEach((x) => {
          x.selected = val;
          this.selectPart(doc.doc_id, x, false);
        });
      }

      this.updateDocCheckStatus(doc.doc_id);
      this.refreshDocs();
    },
    addNewItem(doc, editOnAdd) {
      this.addNewRow(doc, editOnAdd);
      this.refreshDocs();
    },
    addNewRow(
      doc,
      editOnAdd = true,
      overrideText = "",
      partType = "",
      tmpl_part_id = "",
      doc_part_id = 0,
      addToDoc = true,
      fullObj = null
    ) {
      //let sourceItem = this.docCompareItems.find(x => x.doc_id === doc.doc_id);
      //if(sourceItem){
      let targetPart = partType === "" ? this.targetPart : partType;
      let sourceList = doc.parttypes.find((x) => x.type === targetPart);
      let copyItem = sourceList.parts[0];
      let newItem = this.clone(copyItem);
      newItem.tmpl_part_metadata = sourceList.tmpl_part_metadata;
      newItem.text = overrideText;
      if (newItem.subParts.length === 0) {
        newItem.subParts.push({
          text: overrideText,
          type: "text",
          editing: false,
        });
      } else {
        newItem.subParts[0].text = overrideText;
      }
      if (this.useInlineEditor) {
        editOnAdd = false;
      }
      newItem.compareHtml = "";
      newItem.editing = editOnAdd;

      if (doc_part_id > 0) {
        newItem.doc_part_id = doc_part_id;
      }

      let tmpl_part = copyItem ? copyItem.tmpl_part_id : tmpl_part_id;

      let checks = this.getWordChecksForPart(
        tmpl_part,
        doc.wordChecks,
        doc.docHierarchies,
        doc.tmpl_id
      );
      newItem.wordChecks = checks;

      if (newItem.tmpl_part_metadata.attributes.length >= 1) {
        newItem.attributes.forEach((a) => {
          let itemSource = fullObj
            ? fullObj.attributes.find((x) => x.tpa_id === a.tpa_id)
            : newItem.tmpl_part_metadata.attributes.find(
                (x) => x.tpa_id === a.tpa_id
              );
          a.text = fullObj ? itemSource.text : itemSource.default_text;
          let attrChecks = this.getWordChecksForPart(
            tmpl_part,
            doc.wordChecks,
            doc.docHierarchies,
            doc.tmpl_id,
            a
          );
          a.wordChecks = attrChecks;
        });
      }

      //console.log("delete: addNewRow - " + tmpl_part_id);
      if (overrideText !== "") {
        //let checks = this.getWordChecksForPart(tmpl_part,doc.wordChecks,doc.docHierarchies,doc.tmpl_id);
        newItem.textStatus = utils.applyHighlights(
          overrideText,
          checks,
          true,
          false
        );
        newItem.highlightedText = newItem.textStatus.highlightedText;
      }

      let sequence = doc.activeParts.length
        ? doc.activeParts.reduce(
            (pval, cval) => (pval > cval.sequence ? pval : cval.sequence),
            0
          ) + 1
        : 1;
      newItem.sequence = sequence;
      if (addToDoc) {
        doc.activeParts.push(newItem);
        //this.refreshDocs();
      }

      return newItem;
      //}
    },
    async confirmDelete() {
      this.deleteConfirmDialog.show = false;
      let sourceItem = this.docCompareItems.find(
        (x) => x.doc_id === this.deleteConfirmDialog.data.doc.doc_id
      );
      if (sourceItem) {
        sourceItem.isUpdating = true;
        let addEmpty =
          this.deleteConfirmDialog.data.items.length ===
          sourceItem.activeParts.length;
        if (addEmpty) {
          let tmpl_part_id = sourceItem.parttypes.find(
            (x) => x.type === this.targetPart
          ).tmpl_part_id;
          let newRow = this.addNewRow(
            this.deleteConfirmDialog.data.doc,
            false,
            "",
            this.targetPart,
            tmpl_part_id,
            0,
            false
          );
          this.deleteConfirmDialog.data.items.push({
            ...newRow,
            operationType: "save",
          });
        }
      }
      let partType = this.targetPart;

      await Promise.all(
        this.deleteConfirmDialog.data.items.map(async (item) => {
          switch (item.operationType) {
            case "remove":
              return await this.removeDocPart(
                this.deleteConfirmDialog.data.doc,
                item.doc_part_id
              );
            case "save":
              return await this.updateDocPart(
                this.deleteConfirmDialog.data.doc,
                item,
                item.sequence,
                partType
              );
          }
        })
      ).then((resps) => {
        this.processResults(resps);
        sourceItem.isUpdating = false;
        this.deleteConfirmDialog = {
          message: "",
          show: false,
          data: [],
        };
      });
    },
    deleteItems(doc) {
      if (this.selectedActiveParts.length) {
        let items = this.selectedActiveParts.filter(
          (x) => x.doc_id === doc.doc_id
        );

        this.deleteConfirmDialog = {
          message: `Are you sure you want to delete ${items.length} items?`,
          show: true,
          data: {
            doc: doc,
            items: items.map((x) => {
              return {
                ...x,
                operationType: "remove",
              };
            }),
          },
        };
      }
    },
    cancelEdit(doc, item) {
      item.text = item.previousValue;
      item.editing = !item.editing;

      if (item.doc_part_id === 0 || item.doc_part_id === undefined) {
        let idx = doc.activeParts.indexOf(item);
        if (idx > -1) {
          doc.activeParts.splice(idx, 1);
        }
      }

      this.refreshDocs();
    },
    async saveItemText(doc, item) {
      let partType = this.targetPart;
      item.editing = false;
      if (item.text !== item.previousValue) {
        doc.isUpdating = true;
        const updateRequest = this.updateDocPart(
          doc,
          item,
          item.sequence,
          partType
        );
        await updateRequest
          .then((resps) => {
            this.processResults([resps]);
          })
          .then(() => {
            if (this.isComparing) {
              this.compareTest();
            }
          });
      }
    },
    async saveItemTextInline(text, doc, item, attr = null, returnVal = false) {
      let partType = this.targetPart;
      item.editing = false;
      const previousValue = text.previousValue || item.previousValue;
      if (text.text !== previousValue) {
        doc.isUpdating = true;
        item.text = text.text;
        item.highlightedText = text.highlightedText;
        item.textStatus = text;
        if (!returnVal) {
          const updateRequest = this.updateDocPart(
            doc,
            item,
            item.sequence,
            partType,
            true,
            attr
          );
          await updateRequest
            .then((resps) => {
              this.processResults([resps]);
            })
            .then(() => {
              if (this.isComparing) {
                this.compareTest();
              }
            });
        } else {
          return this.updateDocPart(
            doc,
            item,
            item.sequence,
            partType,
            true,
            attr
          );
        }
      }
    },
    doubleClickTrigger(doc, item) {
      if (doc.locked || doc.lockedByPermission || item.editing) {
        return;
      }

      let partChecks = this.getWordChecksForPart(
        item.tmpl_part_id,
        null,
        doc.docHierarchies,
        doc.tmpl_id
      );
      item.wordChecks = partChecks;

      item.previousValue = item.text;
      item.editing = !item.editing;
      if (this.isComparing && item.compareHtml !== "") {
        item.compareHtml = "";
      }
      this.refreshDocs();
    },
    showHierarchyFilter(val) {
      this.$emit("showHierarchyFilter", val);
    },
    openDocument(item) {
      this.$emit("openDoc", item);
    },
    doHierarchyFilter(hierarchyFilters, classifierFilters) {
      this.$emit("doHierarchyFilter", hierarchyFilters, classifierFilters);
    },
    refreshDocs() {
      this.$nextTick(() => {
        this.docCompareItems = this.docCompareItems.map((object) => ({
          ...object,
        }));
      });
      //this.$nextTick(function(){
      //});

      //this.docCompareItems = JSON.parse(JSON.stringify(this.docCompareItems));
    },
    selectPart(doc_id, item, update = true) {
      let existing = this.selectedActiveParts.find(
        (x) => x.doc_id === doc_id && x.doc_part_id === item.doc_part_id
      );
      if (existing) {
        let idx = this.selectedActiveParts.indexOf(existing);
        this.selectedActiveParts.splice(idx, 1);
      }

      if (item.selected) {
        let attrs = [];

        if (item.tmpl_part_metadata.attributes.length >= 1) {
          attrs = item.attributes.map((a) => {
            return {
              ...a,
            };
          });
        }

        let obj = {
          doc_id: doc_id,
          doc_part_id: item.doc_part_id,
          text: item.text,
          attributes: attrs,
        };

        this.selectedActiveParts.push(obj);
      }

      if (update) {
        this.updateDocCheckStatus(doc_id);
        this.refreshDocs();
      }
    },
    updateDocCheckStatus(doc_id) {
      let source = this.docCompareItems.find((x) => x.doc_id === doc_id);
      let selected = this.selectedActiveParts.filter(
        (x) => x.doc_id === doc_id
      );
      source.indeterminate = false;
      if (selected.length === 0) {
        source.allSelected = false;
        return;
      }

      if (selected.length === source.activeParts.length) {
        source.allSelected = true;
        return;
      }
      if (selected.length > 0 && selected.length < source.activeParts.length) {
        source.indeterminate = true;
      }
    },
    checkMove(evt) {
      let target = this.docCompareItems.find(
        (x) => x.doc_id === Number(evt.to.id)
      );
      if (
        target.state.canEditDocParts &&
        !target.locked &&
        !target.lockedByPermission
      ) {
        return true;
      }
      return false;
    },
    saveItemOrder(data){
      let processPromise = new Promise((resolve, reject) => {
        axios
          .post("document/savedocpartsequence/", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            reject(error);
          });
      });

      return processPromise;    
    }, 
    async changeOrder(item, save){
        let data = item.activeParts.map((r, i) => {
            let ret = {};
            ret.sequence = i + 1;
            ret.type = this.targetPart;
            ret.doc_part_id = r.doc_part_id;
            return ret;
        });

        if(save){
            await this.saveItemOrder(data);
            this.$emit("triggerNotification", "Part Order Updated","success");
        }

        
    },
    async checkEnd(evt) {
      if (evt.to.id === evt.from.id) {
        if(evt.newIndex !== evt.oldIndex){
          //same doc drag_drop => reorder
          let source = this.docCompareItems.find((x) => x.doc_id === Number(evt.from.id));
          await this.changeOrder(source, true);
        }
        return;
      }
      this.updateSourceDoc(evt);
    },
    clearSelected() {
      this.selectedActiveParts = [];
      this.docCompareItems.forEach((x) => {
        x.activeParts.forEach((p) => {
          p.selected = false;
        });
        this.updateDocCheckStatus(x.doc_id);
      });
    },
    async updateSourceDoc(event) {
      let processList = [];
      let sourceItems = this.docCompareItems.find(
        (x) => x.doc_id === Number(event.from.id)
      );
      let targetItems = this.docCompareItems.find(
        (x) => x.doc_id === Number(event.to.id)
      );

      let item = event.clone.id.split("|");

      if (targetItems.locked || targetItems.lockedByPermission) {
        return;
      }

      targetItems.isUpdating = true;
      let tmpl_differ = sourceItems.tmpl_id !== targetItems.tmpl_id;
      let target_tp = tmpl_differ
        ? targetItems.parttypes.find((x) => x.type === this.targetPart)
            .tmpl_part_id
        : sourceItems.parttypes.find((x) => x.type === this.targetPart)
            .tmpl_part_id;
      let originalItem = Number(event.item.id.split("|")[1]);
      let draggedItem =
        targetItems.activeParts[event.newIndex] ??
        sourceItems.activeParts.find((x) => x.doc_part_id === Number(item[1]));
      let dragType = this.pullFunction() === "clone" ? "clone" : "replace";
      let targetMethod = this.isMultiPart ? "add" : "replace";
      if (dragType === "replace") {
        if (this.selectedActiveParts.length) {
          this.selectedActiveParts.forEach((sp) => {
            let docGroup = this.docCompareItems.find(
              (x) => x.doc_id === sp.doc_id
            );
            docGroup.isUpdating = true;
            processList.push({
              type: "remove",
              doc: docGroup,
              doc_part_id: sp.doc_part_id,
            });
            let source_item = docGroup.activeParts.find(
              (x) => x.text === sp.text
            );
            if (source_item) {
              let source_idx = docGroup.activeParts.indexOf(source_item);
              docGroup.activeParts.splice(source_idx, 1);
            }
          });

          let draggedOriginalSelected = this.selectedActiveParts.find(
            (x) => x.doc_part_id === originalItem
          );
          if (!draggedOriginalSelected) {
            sourceItems.isUpdating = true;
            processList.push({
              type: "remove",
              doc: sourceItems,
              doc_part_id: originalItem,
            });
          }
        } else {
          if (targetMethod === "add") {
            sourceItems.isUpdating = true;
            processList.push({
              type: "remove",
              doc: sourceItems,
              doc_part_id: originalItem,
            });
          }
        }

        if (sourceItems.activeParts.length === 0) {
          let copy = JSON.parse(JSON.stringify(draggedItem));
          copy.text = "";
          sourceItems.isUpdating = true;
          copy.doc_part_id = Number(originalItem);
          //sourceItems.activeParts.push(copy);
          processList.push({
            type: "save",
            doc: sourceItems,
            item: copy,
            sequence: 1,
          });
        }
      }

      let idx = event.newIndex;

      switch (targetMethod) {
        case "replace":
          targetItems.activeParts.splice(idx, 1);
          targetItems.activeParts[0].text = draggedItem.text;
          processList.push({
            type: "save",
            doc: targetItems,
            item: targetItems.activeParts[0],
            sequence: targetItems.activeParts[0].sequence,
          });
          break;
        case "add":
          targetItems.activeParts.splice(idx, 1);
          if (this.selectedActiveParts.length) {
            this.selectedActiveParts.forEach((sp) => {
              let newItem = this.clone(targetItems.activeParts[0]);
              newItem.text = sp.text;
              newItem.tmpl_part_id = target_tp;
              if (sp.attributes.length >= 1) {
                newItem.attributes.forEach((na) => {
                  let sourceAtt = sp.attributes.find(
                    (x) => x.tpa_id === na.tpa_id
                  );
                  na.text = sourceAtt.text;
                });
              }
              processList.push({
                type: "save",
                doc: targetItems,
                item: newItem,
                sequence: idx + 1,
              });
            });

            let draggedOriginalSelected = this.selectedActiveParts.find(
              (x) => x.doc_part_id === originalItem
            );
            if (!draggedOriginalSelected) {
              let newItem = this.clone(targetItems.activeParts[0]);
              newItem.text = draggedItem.text;
              newItem.tmpl_part_id = target_tp;
              processList.push({
                type: "save",
                doc: targetItems,
                item: newItem,
                sequence: idx + 1,
              });
            }
          } else {
            let newItem = this.clone(draggedItem);
            newItem.text = draggedItem.text;
            newItem.tmpl_part_id = target_tp;
            if (draggedItem.tmpl_part_metadata.attributes.length >= 1) {
              newItem.attributes.forEach((na) => {
                let sourceAtt = draggedItem.attributes.find(
                  (x) => x.tpa_id === na.tpa_id
                );
                na.text = sourceAtt.text;
              });
            }
            processList.push({
              type: "save",
              doc: targetItems,
              item: newItem,
              sequence: idx + 1,
            });
          }

          break;
      }

      this.refreshDocs();
      let partType = this.targetPart;
      await Promise.all(
        processList.map(async (item) => {
          switch (item.type) {
            case "remove":
              return await this.removeDocPart(item.doc, item.doc_part_id);
            case "save":
              return await this.updateDocPart(
                item.doc,
                item.item,
                item.sequence,
                partType
              );
          }
        })
      ).then((resps) => {
        this.processResults(resps);
        this.clearSelected();
      });
    },
    removeItemFromDoc(doc_id, item) {
      let sourceItem = this.docCompareItems.find((x) => x.doc_id === doc_id);
      sourceItem.isUpdating = false;
      if (sourceItem) {
        let aIdx = sourceItem.activeParts.find((a) => a.doc_part_id === item);
        if (aIdx) {
          sourceItem.activeParts.splice(
            sourceItem.activeParts.indexOf(aIdx),
            1
          );
        }
        /*else {
          this.loadParts();
        }*/
        let sourcePart = sourceItem.parttypes.find(
          (p) => p.type === this.targetPart
        );
        let iIdx = sourcePart.parts.find((a) => a.doc_part_id === item);
        if (iIdx) {
          sourcePart.parts.splice(sourcePart.parts.indexOf(iIdx), 1);
        }
        let selectedItem = this.selectedActiveParts.find(
          (x) => x.doc_part_id === item
        );
        if (selectedItem) {
          this.selectedActiveParts.splice(
            this.selectedActiveParts.indexOf(selectedItem),
            1
          );
        }
      }
    },
    addNewItemToDoc(source, newItem, newAttrs) {
      let sourceItem = this.docCompareItems.find(
        (x) => x.doc_id === source.doc_id
      );
      let sourcePart = sourceItem.parttypes.find(
        (x) => x.type === source.doc_part_type
      );

      if (newItem["tmpl_part_id"] === undefined) {
        newItem.tmpl_part_id = sourcePart.tmpl_part_id;
      }
      let addedItem = sourceItem.activeParts.find(
        (x) => x.text === source.text && x.doc_part_id === source.doc_part_id
      );
      if (addedItem) {
        addedItem.doc_part_id = Number(newItem.doc_part_id);
        sourcePart.parts.push(addedItem);
      } else {
        let tmpl_part_id = sourceItem.parttypes.find(
          (x) => x.type === this.targetPart
        ).tmpl_part_id;
        let cloned = this.addNewRow(
          sourceItem,
          false,
          source.text,
          newItem.dp_name,
          tmpl_part_id,
          Number(newItem.doc_part_id),
          false,
          source
        );
        let restored = this.updateFromAudit(newItem, cloned, newAttrs);
        sourceItem.activeParts.push(restored);
        //let sourcePart = sourceItem.parttypes.find(p => p.type === newItem.dp_name);
        sourcePart.parts.push(restored);
      }
    },
    updateItemInDoc(source, newItem, newAttrs) {
      let list = this.docCompareItems.find((x) => x.doc_id === source.doc_id);
      list.isUpdating = false;
      list.editing = false;

      let sourcePart = list.parttypes.find(
        (x) => x.type === source.doc_part_type
      );
      let checks = this.getWordChecksForPart(
        source.tmpl_part_id,
        list.wordChecks,
        list.docHierarchies,
        list.tmpl_id
      );

      let existingPart = list.activeParts.find(
        (x) => x.doc_part_id === source.doc_part_id
      );
      if (existingPart) {
        existingPart.text = source.text;
        existingPart.textStatus = utils.applyHighlights(
          source.text,
          checks,
          true,
          false
        );
        existingPart.highlightedText = existingPart.textStatus.highlightedText;
        if (newAttrs) {
          newAttrs.forEach((a) => {
            let updateItem = existingPart.attributes.find(
              (x) => x.tpa_id === a.tpa_id
            );
            updateItem.doc_part_id = Number(newItem.doc_part_id);
            updateItem.dpa_id = Number(a.dpa_id);
          });
        }
      }
      let existingItem = sourcePart.parts.find(
        (x) => x.doc_part_id === source.doc_part_id
      );
      if (existingItem) {
        existingItem.text = source.text;
        existingItem.textStatus = utils.applyHighlights(
          source.text,
          checks,
          true,
          false
        );
        existingItem.highlightedText = existingItem.textStatus.highlightedText;
        if (newAttrs) {
          newAttrs.forEach((a) => {
            let updateItem = existingPart.attributes.find(
              (x) => x.tpa_id === a.tpa_id
            );
            updateItem.doc_part_id = Number(newItem.doc_part_id);
            updateItem.dpa_id = Number(a.dpa_id);
          });
        }
      }

      if (newItem && !existingPart && !existingItem) {
        this.addNewItemToDoc(source, newItem, newAttrs);
      }
    },
    updateFromAudit(newRow, cloned, newAttrs) {
      let updated = {
        ...cloned,
        doc_part_id: Number(newRow.doc_part_id),
      };

      if (newAttrs && newAttrs.length > 0) {
        updated.attributes.forEach((a) => {
          let sourceAttr = newAttrs.find((x) => x.tpa_id === a.tpa_id);
          if (sourceAttr) {
            a.dpa_id = Number(sourceAttr.dpa_id);
            a.doc_part_id = Number(sourceAttr.dp_id);
            a.text = sourceAttr.value;
          }
        });
      }

      return updated;
    },
    restoreDelete(doc_id, newRow, newRowAttributes) {
      let sourceItem = this.docCompareItems.find((x) => x.doc_id === doc_id);
      if (sourceItem) {
        let cloned = this.addNewRow(
          sourceItem,
          false,
          newRow.text,
          newRow.dp_name
        );
        let restored = this.updateFromAudit(newRow, cloned, newRowAttributes);
        let sourcePart = sourceItem.parttypes.find(
          (p) => p.type === newRow.dp_name
        );
        sourcePart.parts.push(restored);
        sourceItem.isUpdating = false;
      }
    },
    processResults(resps) {
      let messages = [];
      let docs = [];
      let reverse = [...new Set(resps.map((x) => x.type))].length > 1;
      if (reverse) {
        resps = resps.reverse();
      }
      resps.forEach((r) => {
        let type = r.type;
        let source = r.request;
        let result = r.result.Data;
        switch (type) {
          case "Updated":
            this.updateItemInDoc(
              source,
              r.result.Data.newRow,
              r.result.Data.newRowAttributes
            );
            break;
          case "Removed":
            this.removeItemFromDoc(source.doc_id, result.audit.key);
            break;
          case "Reverted":
            this.restoreDelete(
              source.doc_id,
              result.newRow,
              result.newRowAttributes
            );
        }

        let list = this.docCompareItems.find((x) => x.doc_id === source.doc_id);

        messages.push(
          `${source.system_number} Updated: ${source.doc_part_type} ${type}`
        );

        docs.push(source.doc_id);

        if (
          this.summaryMenu.show &&
          this.summaryMenu.summaryDoc === source.doc_id
        ) {
          this.setSummaryData(list);
        }
      });

      let updateDocs = [...new Set(docs)];
      updateDocs.forEach((d) => {
        this.updateDocCheckStatus(d);
        this.refreshCheckSummary(d);
      });

      this.refreshDocs();

      if (messages.length) {
        this.$emit("triggerNotification", messages.join("<br/>"), "success");
      }
    },
    refreshCheckSummary(d) {
      let sourceItem = this.docCompareItems.find((x) => x.doc_id === d);
      if (sourceItem) {
        let allChecks = this.getAllChecks(sourceItem.tmpl_id);
        sourceItem.checkSummary = utils.documentCheckSummary(
          sourceItem,
          allChecks,
          2
        );
      }
    },
    async undoRemoveDocPart(doc, doc_part_id, sequence) {
      let processPromise = new Promise((resolve, reject) => {
        let data = {
          doc_part_id: doc_part_id,
          doc_part_type: this.targetPart,
          sequence: sequence,
        };

        axios
          .post("document/undoremovedocpart/", data)
          .then((resp) => {
            resolve({
              type: "Reverted",
              request: { ...doc, ...data },
              result: resp.data,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });

      return processPromise;
    },
    async removeDocPart(doc, doc_part_id, addHistory = true) {
      let processPromise = new Promise((resolve, reject) => {
        let data = {
          doc_part_id: doc_part_id,
          doc_part_type: this.targetPart,
        };

        axios
          .post("document/removedocpart/", data)
          .then((resp) => {
            if (addHistory) {
              this.historyItems.unshift({
                type: "Delete",
                selected: false,
                doc: { doc_id: doc.doc_id, system_number: doc.system_number },
                item: data,
              });
            }
            resolve({
              type: "Removed",
              request: { ...doc, ...data },
              result: resp.data,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });

      return processPromise;
    },
    async updateDocPart(
      targetDoc,
      item,
      sequence,
      type,
      addHistory = true,
      attr
    ) {
      let processPromise = new Promise((resolve, reject) => {
        let isAttr = item.tmpl_part_metadata.attributes?.length >= 1;

        if (isAttr && attr) {
          attr.isDirty = true;
          //attr.isDirty = data.text !== attr.text;
          attr.text = item.text;
          item.text = utils.attributesToText(
            item.tmpl_part_metadata.attributes,
            item.attributes
          );
          item.subParts = utils.partToSubParts(item.text);
        }

        let data = {
          text: item.text,
          doc_part_id: item.doc_part_id,
          doc_part_type: type,
          doc_id: targetDoc.doc_id,
          system_number: targetDoc.system_number,
          tmpl_part_id: item.tmpl_part_id,
          notes: null,
          parent_dp_id: null,
          is_essential: item.is_essential ? 1 : 0,
          quality: 0,
          sequence: sequence,
          tmpl_part_metadata: item.tmpl_part_metadata,
          attributes: isAttr
            ? item.attributes
                .filter((a) => a.isDirty)
                .map((a) => {
                  return { tpa_id: a.tpa_id, dpa_id: a.dpa_id, text: a.text };
                })
            : [],
        };

        axios
          .post("document/savedocpart/", data)
          .then((resp) => {
            if (addHistory) {
              let historyType = item.doc_part_id === 0 ? "Add" : "Update";

              if (historyType === "Add") {
                data.doc_part_id = Number(resp.data.Data.audit.key);
                item.doc_part_id = Number(resp.data.Data.audit.key);
              }

              this.historyItems.unshift({
                type: historyType,
                selected: false,
                doc: {
                  doc_id: targetDoc.doc_id,
                  system_number: targetDoc.system_number,
                },
                item: data,
                diff:
                  historyType === "Add"
                    ? null
                    : {
                        old: item.previousValue,
                        new: item.text,
                        content: this.runCompare(item.previousValue, item.text),
                      },
              });
            }
            this.refreshDocs();
            resolve({ type: "Updated", request: data, result: resp.data });
          })
          .catch((error) => {
            reject(error);
          });
      });

      return processPromise;
    },
    clone(item) {
      let newItem = Object.assign({}, item);
      delete newItem.doc_part_id;
      //delete newItem.highlightedText;
      delete newItem.text;
      delete newItem.subParts;
      delete newItem.previousValue;

      newItem = {
        ...newItem,
        doc_part_id: 0,
        text: "",
        editing: false,
        highlightedText: "",
        previousValue: "",
        subParts: [],
      };

      if (newItem.attributes) {
        newItem.attributes = item.attributes.map((object) => ({ ...object }));
        newItem.attributes.forEach((a) => {
          a.doc_part_id = 0;
          a.dpa_id = 0;
          a.isDirty = true;
          a.text = "";
        });
      }

      return newItem;
    },
    pullFunction() {
      return !this.controlOnStart ? "clone" : true;
    },
    start({ originalEvent }) {
      this.controlOnStart = originalEvent.ctrlKey;
    },
    clearHidden() {
      this.hiddenDocs.forEach((x) => (x.visible = true));
      this.refreshDocs();
    },
    clearLocked() {
      this.lockedDocs.forEach((x) => (x.locked = false));
      this.refreshDocs();
    },
    runCompare(source, compare) {
      const diff = JsDiff[this.diffType](source, compare, {
        ignoreWhitespace: false,
      });
      let returnString = "";
      diff.forEach((part) => {
        const color = part.added ? "green" : part.removed ? "red" : "grey";
        let fragment = "";
        switch (color) {
          case "green":
            fragment = `<ins>${part.value}</ins>`;
            break;
          case "red":
            fragment = `<del>${part.value}</del>`;
            break;
          case "grey":
            fragment = `${part.value}`;
            break;
        }
        returnString = returnString += fragment;
      });

      return returnString;
    },
    clearCompare() {
      this.isComparing = false;
      this.docCompareItems = this.docCompareItems.map((d) => {
        return {
          ...d,
          activeParts: d.activeParts.map((p) => {
            return { ...p, compareHtml: "" };
          }),
        };
      });
      this.refreshDocs();
    },
    compareTest() {
      this.compareLoading = true;
      let source = this.pinnedDoc;
      if (source.activeParts.length === 1) {
        let compareSource = source.activeParts[0].text;
        this.activeDocs.forEach((i) => {
          if (i.activeParts.length >= 1) {
            let compareText = i.activeParts[0].text;
            let compareHtml = this.runCompare(compareSource, compareText);
            i.activeParts[0].compareHtml = compareHtml;
          }
        });
      } else {
        source.activeParts.forEach((sp, indx) => {
          let compareSource = sp.text;
          this.activeDocs.forEach((i) => {
            let test = i.activeParts[indx];
            if (test) {
              let compareText = test.text;
              let compareHtml = this.runCompare(compareSource, compareText);
              test.compareHtml = compareHtml;
            }
          });
        });
      }
      this.docCompareItems = JSON.parse(JSON.stringify(this.docCompareItems));
      this.compareLoading = false;
      this.isComparing = true;
      this.compareFilter = false;
    },
    unPinItem(item) {
      item.pinned = false;
      this.$nextTick(() => {
        this.refreshDocs();
      });
    },
    pinItem(item) {
      let existing = this.docCompareItems.find((x) => x.pinned);
      if (existing) {
        existing.pinned = false;
      }
      item.pinned = true;
      this.$nextTick(() => {
        this.refreshDocs();
      });
    },
    initDocItem(item) {
      let autoPin = this.items.some(x => x.doc_id === item.doc_id && x.pinned) ? true : false;

      return {
        ...item,
        pinned: autoPin,
        locked: false,
        lockedByPermission: !item.state.canEditDocParts || item.state.triggerDCAction !== null,
        visible: true,
        isUpdating: false,
        previousValue: null,
        created_date: moment(item.created_date).format("DD MMM YYYY"),
      };
    },
    setupDocCompareData(data) {
      this.allCompareData = data;
      let docList = [];
      let docs = data;
      /*if(this.pinnedDoc){
        docs = docs.filter(x => x.doc_id !== this.pinnedDoc.doc_id);
      } */

      docList = docs.map((d, idx) => {
        let docItem = this.initDocItem(d.document);
        docItem.position_order = idx;
        docItem.wordChecks = d.wordChecks;
        docItem.docHierarchies = d.docHierarchies;
        docItem.checkSummary = utils.documentCheckSummary(
          docItem,
          docItem.wordChecks,
          2
        );
        return docItem;
      });

      /*docs.forEach((d) => {
        let docItem = d.document;
        docItem = this.initDocItem(docItem);
        docItem.wordChecks = d.wordChecks;
        docItem.docHierarchies = d.docHierarchies;
        docItem.checkSummary = utils.documentCheckSummary(
          docItem,
          docItem.wordChecks,
          2
        );
        docList.push(docItem);
      });*/

      this.docCompareItems = docList;
      //this.refreshDocs();
      //this.docCompareItems = JSON.parse(JSON.stringify(this.docCompareItems));
      this.compareLoading = false;
      this.showHierarchyFilter(false);
      this.loadParts();
    },
    init(ids) {
      if (ids === undefined) {
        return;
      }

      let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);
      this.useSpellCheck = languageOpts.spellCheck.enabled;

      /*this.useInlineEditor = this.$loginState.user.settings.some(
        (s) => s.setting === "use_inline_editor_component" && s.value === "true"
      );*/

      this.compareLoading = true;
      axios
        .post("document/docswithpartsList", ids)
        .then((resp) => {
          this.allChecksData = resp.data.checks;
          let data = resp.data.documents.map((d) => {
            let checks = this.getAllChecks(d.tmpl_id);
            let setup = utils.setupDocument([d], checks, this.statuses);
            return {
              ...setup,
              wordChecks: checks,
            };
          });
          this.setupDocCompareData(data);
        })
        .catch((err) => {
          console.log(err);
          this.compareLoading = false;
        });
    },
    loadPartDetails() {
      let item = this.docCompareItems[0].parttypes.find(
        (x) => x.type === this.targetPart
      );
      if (item) {
        this.targetPartDetails = item;
      } else {
        this.targetPart = this.docCompareItems[0].parttypes[0].type;
        this.targetPartDetails = this.docCompareItems[0].parttypes[0];
      }
    },
    loadParts() {
      this.docPartFilter = false;
      this.loadPartDetails();
      this.selectedActiveParts = [];
      let parts = this.docCompareItems.map((x) =>
        x.parttypes
          .filter((x) => x.tmpl_part_metadata.tp_locked === 0)
          .map((p) => {
            return p.type;
          })
      );
      let array = [];
      parts.forEach((p) => {
        array = array.concat(p);
      });

      this.allParts = [...new Set(array)];

      if (this.targetPart === "") {
        this.targetPart = this.allParts[0];
      }

      let setData = this.docCompareItems.map((d) => {
        d.allSelected = false;
        d.activeParts = [];
        let newGroup = d.parttypes.find((p) => p.type === this.targetPart);
        if (newGroup) {
          let checks = this.getWordChecksForPart(
            this.targetPartDetails.tmpl_part_id,
            d.wordChecks,
            d.docHierarchies,
            d.tmpl_id
          );
          let newParts = newGroup.parts.map((x) => ({
            ...x,
            attributes:
              newGroup.tmpl_part_metadata.attributes.length > 0
                ? x.attributes.map((a) => {
                    let attrChecks = this.getWordChecksForPart(
                      this.targetPartDetails.tmpl_part_id,
                      d.wordChecks,
                      d.docHierarchies,
                      d.tmpl_id,
                      a
                    );
                    return {
                      ...a,
                      wordChecks: attrChecks,
                    };
                  })
                : x.attributes,
            selected: false,
            wordChecks: checks,
            compareHtml: "",
            previousValue: x.text,
            tmpl_part_metadata: newGroup.tmpl_part_metadata,
            forceRefresh: false,
          }));
          d.activeParts = newParts.map((object) => ({ ...object }));
        }

        return d;
      });

      this.docCompareItems = setData;

      /*this.docCompareItems.forEach((x) => {
        x.allSelected = false;
        x.activeParts = [];
        let newGroup = x.parttypes.find((p) => p.type === this.targetPart);
        if (newGroup) {

          let checks = this.getWordChecksForPart(this.targetPartDetails.tmpl_part_id,x.wordChecks,x.docHierarchies,x.tmpl_id);      

          /*newGroup.parts.forEach((p) => {
            p.selected = false;
            p.wordChecks = checks;
            p.compareHtml = "";
            p.previousValue = p.text;
          });
          x.activeParts = newGroup.parts;

          x.activeParts = newGroup.parts.map(x => (
            { 
              ...x, 
              selected: false,
              wordChecks: checks,
              compareHtml: "",
              previousValue: x.text
            }
          ));
        }
      });*/
      this.$nextTick(() => {
        this.refreshDocs();
      });
    },
    initCompare() {
      let ids = this.docCompareItems.map((x) => {
        return x.system_number;
      });

      if (ids.length > 0) {
        this.init(ids);
      }
    },
    changeView(val) {
      this.$emit("changeView", val);
    },

    getDocTypeFilterText(item, index) {
      if (item && index > 0) return "";
      let included = this.docTypeSummary.filter((s) =>
        this.docTypeIncluded.some((si) => s.docType === si.docType)
      );
      if (included.length === this.docTypeSummary.length)
        return included.length > 0 ? "All" : "None";

      if (included.length > 2) return `${included.length} items`;

      return included.reduce((prev, curr) => {
        return prev + (prev ? ", " : "") + curr.docType;
      }, "");
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      let included = this.docStatusSummary.filter((s) =>
        this.docStatusIncluded.some((si) => s.status === si.status)
      );
      if (included.length === this.docStatusSummary.length)
        return included.length > 0 ? "All" : "None";

      if (included.length > 2) return `${included.length} items`;

      return included.reduce((prev, curr) => {
        return prev + (prev ? ", " : "") + curr.status;
      }, "");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.docComparisonHolder {
  $pad-bottom: 5px;

  /** {
        font-family: 'Martel Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }  */

  .filterPanel {
    .v-expansion-panel-header {
      .v-expansion-panel-header__icon {
        margin-left: 50% !important;
      }
    }
  }

  del {
    text-decoration: line-through;
    color: #b30000;
    background: #f1a19a;
  }

  ins {
    text-decoration: underline;
    background: #59e700c7;
  }

  max-height: calc(100vh - 45px - #{$pad-bottom}) !important;
  overflow: hidden;
  height: calc(100vh - 45px - #{$pad-bottom}) !important;

  .v-card__title {
    height: 60px;
  }

  .v-card__text {
    max-height: calc(100vh - 60px) !important;
    height: calc(100vh - 60px) !important;
    //width: calc(100vw - 75px);
    //max-width: calc(100vw - 75px);
    background-color: #ffffff;
    overflow: hidden;
    padding-bottom: 0px !important;
    padding-right: 4px !important;
    padding-top: 3px !important;
    padding-left: 4px !important;
    overflow-x: hidden;

    $filter-height: 30px;
    $navHeight: 17px;

    .docComparisonFilterRowExpanded {
      height: 7px + $filter-height + $navHeight;
      //border: 1px solid #bbddfb;
      border-radius: 4px;
      font-family: "Martel Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7d93b4;
      padding-top: 8px;
      padding-right: 30px;
      padding-left: 15px;

      .items {
        font-family: "Martel Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #7d93b4;
        padding-top: 15px;

        .links {
          padding-left: 5px;
          font-family: "Martel Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #377dff;
        }
      }

      .col {
        height: $filter-height;

        .comparePartsFilters {
          height: 55px;
          padding-top: 5px;
        }
        .comparePartsActions {
          height: 50px;
          padding: 0px 15px;

          .header {
            font-family: "Martel Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #3e3e3e;
          }
        }

        .filterPanel {
          .v-expansion-panel-content {
            height: $filter-height;
          }
          .v-expansion-panel__header {
            min-height: $navHeight;
            height: $filter-height;
          }
        }
      }
    }

    .docComparisonFilterRow {
      border: 1px solid #bbddfb;
      border-radius: 4px;
      .row.col {
        .v-expansion-panels .v-expansion-panel {
          height: $filter-height + $navHeight;
        }
        .v-expansion-panel__header {
          min-height: $navHeight + $navHeight;
          height: $navHeight + $navHeight;
        }
      }
    }

    .docComparisonContent {
      height: calc(100% - 45px + #{$navHeight});
      max-height: calc(100% - 45px + #{$navHeight});
      .col {
        padding-top: 10px;
        height: 100%;
        max-height: 100%;
        padding-bottom: 0px;
      }
    }

    .docComparisonContentExpanded {
      height: calc(100vh - 110px - #{$filter-height} - #{$navHeight});
      max-height: calc(100vh - 110px - #{$filter-height} - #{$navHeight});
      margin-top: 0px;
      .col {
        padding-top: 10px;
        height: 100%;
        max-height: 100%;
        padding-bottom: 0px;
      }
    }
  }
}

.v-badge__badge {
  padding: 5px 4px !important;
}
.dialoge-select {
  font-size: 12px;
}
.dialoge-select > div.v-text-field__details {
  max-height: 0px;
  padding: 0;
  margin: 0;
}
.dialoge-select > div.v-messages {
  max-height: 0px;
  padding: 0;
  margin: 0;
}
.dialogue-nav-draw {
	left: 68px;
}
</style>