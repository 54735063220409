var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"absolute":"","position-x":_vm.context.posX,"position-y":_vm.context.posY,"offset-y":"","close-on-content-click":false,"nudge-width":"300"},model:{value:(_vm.contextMenu.show),callback:function ($$v) {_vm.$set(_vm.contextMenu, "show", $$v)},expression:"contextMenu.show"}},[(_vm.context.document)?_c('v-card',[(_vm.contextMenu.busy)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50","color":"grey"}})],1),_c('v-col',{attrs:{"cols":"5"}})],1)],1):_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.openDocument}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book-open")])],1),_c('v-list-item-title',[_vm._v("Open")])],1),(_vm.previewEnabled)?_c('v-list-item',{on:{"click":_vm.openPreview}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-list-item-title',[_vm._v("Preview")])],1):_vm._e(),(_vm.document && _vm.document.lifecycle_id)?_c('div',_vm._l((_vm.contextMenu.listActions),function(aa){return _c('v-list-item',{key:'d' + _vm.context.document.doc_id + 'a' + aa.action_available_id,attrs:{"dense":"","disabled":!!(!aa.allow_errors && _vm.context.document.error_count) ||
              !!(!aa.allow_errors && _vm.context.document.missing_data) ||
              !!(!aa.allow_warnings && _vm.context.document.warning_count)},on:{"click":function($event){return _vm.doWorkflowAction(aa)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.getIcon(aa)))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(aa.name)+" ")])],1)}),1):_vm._e()],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px","persistent":""},model:{value:(_vm.dataCapture.show),callback:function ($$v) {_vm.$set(_vm.dataCapture, "show", $$v)},expression:"dataCapture.show"}},[(
        _vm.dataCapture.show &&
        (_vm.dataCapture.action.definition.isCommentBased ||
          ['picklist', 'form'].some(
            (x) => x === _vm.dataCapture.action.definition.method
          ))
      )?_c('DataCaptureForm',{attrs:{"action":_vm.dataCapture.action,"isBusy":_vm.isLoading,"docId":_vm.context.document.doc_id},on:{"save":_vm.saveDataCapture,"cancel":_vm.cancelDataCapture,"toggleHidden":function($event){_vm.dca.isDataCaptureHidden = !_vm.dca.isDataCaptureHidden}}}):_vm._e(),(
        _vm.dataCapture.show &&
        _vm.dataCapture.action.definition.method === 'document_role'
      )?_c('DataCaptureDocumentRole',{attrs:{"action":_vm.dataCapture.action,"isBusy":_vm.isLoading,"docId":_vm.context.document.doc_id},on:{"save":_vm.saveDataCapture,"cancel":_vm.cancelDataCapture,"toggleHidden":function($event){_vm.dca.isDataCaptureHidden = !_vm.dca.isDataCaptureHidden}}}):(
        _vm.dataCapture.show &&
        _vm.dataCapture.action.definition.method === 'hierarchy'
      )?_c('DataCaptureHierarchy',{attrs:{"action":_vm.dataCapture.action,"documentHierarchies":_vm.context.document.hierarchies,"isBusy":_vm.isLoading},on:{"save":_vm.saveDataCapture,"cancel":_vm.cancelDataCapture,"toggleHidden":function($event){_vm.dataCapture.action = !_vm.dataCapture.action}}}):(
        _vm.dataCapture.show &&
        (_vm.dataCapture.action.definition.method === 'requisition' ||
          _vm.dataCapture.action.definition.method === 'requisition_new')
      )?_c('DataCaptureRequisitions',{attrs:{"action":_vm.dataCapture.action,"isBusy":_vm.isLoading},on:{"save":_vm.saveDataCapture,"cancel":_vm.cancelDataCapture,"toggleHidden":function($event){_vm.dataCapture.action.isDataCaptureHidden =
          !_vm.dataCapture.action.isDataCaptureHidden}}}):(
        _vm.dataCapture.show &&
        _vm.dataCapture.action.definition.method === 'review_group'
      )?_c('AssignReviewGroup',{attrs:{"docId":_vm.context.document.doc_id,"action":_vm.dataCapture.action},on:{"save":_vm.saveDataCapture,"cancel":function($event){_vm.dataCapture.show = false},"close":function($event){_vm.dataCapture.show = false}}}):_vm._e()],1),_c('v-dialog',{staticStyle:{"max-height":"600px"},attrs:{"max-width":"800px","scrollable":""},model:{value:(_vm.emailDialogue.show),callback:function ($$v) {_vm.$set(_vm.emailDialogue, "show", $$v)},expression:"emailDialogue.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subtitle-1"},[_c('span',[_vm._v("Send Document")])]),_c('v-spacer'),_c('v-btn',{staticClass:"btn-background",attrs:{"icon":"","large":""},on:{"click":function($event){_vm.emailDialogue.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"roundish mt-2",attrs:{"outlined":"","label":"Email Address","required":"","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.emailDialogue.value),callback:function ($$v) {_vm.$set(_vm.emailDialogue, "value", $$v)},expression:"emailDialogue.value"}})],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end px-6 pb-6"},[(!_vm.emailDialogue.sending)?_c('v-btn',{staticClass:"roundish",attrs:{"large":"","color":"primarylight","disabled":!_vm.emailDialogue.value},on:{"click":function($event){return _vm.emailDoc()}}},[_vm._v("Send")]):_c('v-progress-circular',{staticClass:"mx-2",attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }