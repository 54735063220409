<template>
  <div>
    <v-card>
      <v-card-title
        @click.prevent="toggleHide"
        :style="{ cursor: action.isDataCaptureEditPopup ? '' : 'pointer' }"
      >
        <b>{{ action.definition.title }}</b>
        <v-spacer></v-spacer>
        <v-icon
          v-if="
            action.definition.attachment_url !== null &&
            !this.action.isDataCaptureViewer
          "
          title="Download Questionnaire Attachment"
          class="mr-2"
          @click="downloadS3File(action.definition.attachment_url)"
          >mdi-cloud-download</v-icon
        >
        <v-icon
          v-if="action.dataCaptureEditAction && !action.isDataCaptureEditPopup"
          @click="triggerEdit"
        >
          mdi-square-edit-outline</v-icon
        >
        <v-icon
          v-if="action.isDataCaptureViewer && !action.isDataCaptureEditPopup"
          >{{
            action.isDataCaptureHidden ? `mdi-eye` : "mdi-eye-off"
          }}
          mdi-eye</v-icon
        >
        <v-chip
          outlined
          label
          color="green"
          v-if="
            action.isDataCaptureEditPopup &&
            dataCaptureData &&
            dataCaptureData.isCompleted
          "
          >Completed</v-chip
        >
        <v-chip
          outlined
          label
          color="amber"
          v-if="
            action.isDataCaptureEditPopup &&
            dataCaptureData &&
            !dataCaptureData.isCompleted
          "
          >In Progress</v-chip
        >
        <v-chip
          outlined
          class="ml-3"
          label
          color="info"
          v-if="
            dataCaptureData &&
            dataCaptureData.isCompleted &&
            dataCaptureData.score_calculation !== 'NONE'
          "
          >Overall Score: {{ dataCaptureData.overall_score }}</v-chip
        >
        <v-btn
          icon
          class="btn-background ml-2"
          v-if="action.isDataCaptureEditPopup"
          @click.stop="close"
          large
          >
          <v-icon>mdi-close</v-icon>
        </v-btn
        > </v-card-title
      ><v-divider></v-divider>
      <v-card-text v-if="!action.isDataCaptureViewer && currentQ" class="px-0">
        <v-stepper
          v-model="currentQ.sectionIndex"
          style="max-height: calc(90vh - 120px); overflow-y: auto;"
          vertical
          flat
        >
          <span
            v-for="s in dataCaptureData.sections"
            :key="`container_${s.sectionIndex}`"
          >
            <v-stepper-step
              :complete="s.sectionIndex < currentQ.sectionIndex"
              :step="s.title ? s.sectionIndex : currentQ.questionIndex"
              style="cursor: pointer;"
              @click.native="selectSection(s)"
              class="py-1"
            >
              <template v-if="currentQ.question_category && currentQ.sectionIndex === s.sectionIndex">
                <h3>{{ currentQ.question_category }}</h3>
              </template>
              <template v-else>
                {{ s.title }}
                {{
                  s.title && currentQ.sectionIndex === s.sectionIndex
                    ? `- Question ${currentQ.questionIndex} of ${s.questions.length}`
                    : ""
                }}
              </template>
            </v-stepper-step>
            <v-stepper-content
              :step="s.sectionIndex"
              style="border-left: 1px solid rgba(0, 0, 0, 0.12)"
            >
              <v-card
                flat
                class="mb-12"
                style="padding-left: 0"
                :id="`question${currentQ.questionnaire_question_id}`"
                v-if="currentQ"
              >
                <v-card-text style="padding-left: 10px">
                  <v-row dense>
                    <v-col dense>
                      <v-tooltip v-if="currentQ.attachment_url" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="downloadS3File(currentQ.attachment_url)"
                            v-bind="attrs"
                            v-on="on"
                            class="float-right pl-2"
                            >mdi-cloud-download</v-icon
                          >
                        </template>
                        <span v-html="currentQ.tooltip"></span>
                      </v-tooltip>
                      <v-tooltip
                        v-else-if="currentQ.tooltip"
                        top
                        max-width="800px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="float-right pl-2">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span v-html="currentQ.tooltip"></span>
                      </v-tooltip>
                      {{ currentQ.question }}
                    </v-col>
                    <v-col
                      v-if="
                        (currentAnswerDef && currentAnswerDef.note_requirement !== 'NONE') ||
                        (answer_array.length > 0 && currentQ.answers.length > 0 &&
                          currentQ.answers.some(a => answer_array.includes(a.value) && a.note_requirement !== 'NONE' ))
                      "
                      cols="5"
                    >
                      <div class="float-left" style="padding-top: 3px">
                        {{ currentQ.answers?.find(a => answer_array.includes(a.value) && a.note_requirement !== 'NONE' )?.note_label || currentAnswerDef.note_label }}
                      </div>
                      <v-tooltip
                        v-if="currentAnswerDef && currentAnswerDef.note_tooltip"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="pl-2"
                            >mdi-help-circle-outline</v-icon
                          >
                        </template>
                        <span>{{ currentAnswerDef.note_tooltip }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        v-if="currentQ.control_type === 'SELECT'"
                        v-model="currentQ.answer"
                        :items="currentQ.answers"
                        @change="pickAnswer(false)"
                        item-text="value"
                        dense
                      ></v-select>
                      <v-radio-group
                        v-else-if="currentQ.control_type === 'RADIO'"
                        v-model="currentQ.answer"
                      >
                        <v-radio
                          v-for="(a, idx) in currentQ.answers"
                          :key="idx"
                          :label="a.value"
                          :value="a.value"
                          class="underline"
                          @click="pickAnswer(false)"
                        >
                          <template v-slot:label>
                            <div class="answer">
                              <v-tooltip
                                top
                                v-if="a.tooltip !== null"
                                max-width="350px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  {{ a.value }}
                                  <v-icon class="ml-3" v-bind="attrs" v-on="on"
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span style="" v-html="a.tooltip"></span>
                              </v-tooltip>
                              <div v-else class="answer-text">
                                {{ a.value }}
                              </div>
                              <div
                                v-if="currentQ.display_score && a.score"
                                :class="
                                  currentQ.answer === a.value
                                    ? 'answer-score-selected'
                                    : 'answer-score'
                                "
                              >
                                {{ a.score }}
                              </div>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <v-textarea
                        outlined
                        v-else-if="currentQ.control_type === 'TEXT'"
                        v-model="currentQ.answer"
                        @input="currentQ.isDirty = true;  checkAnswer()"
                      >
                      </v-textarea>
                      <v-text-field
                        outlined
                        v-else-if="currentQ.control_type === 'MONEY'"
                        v-model="currentQ.answer"
                        prepend-icon="currency_pound"
                        dense
                        style="max-width: 200px;"
                        @input="currentQ.isDirty = true;  checkAnswer()"
                      >
                      </v-text-field>
                      <v-row v-else-if="currentQ.control_type === 'MULTI'">
                        <v-col v-for="(a, ai) in currentQ.answers" :key="'a' + ai" cols="12" class="pt-1 pb-0">
                          <v-checkbox
                            v-model="answer_array" multiple
                            :label="a.value"
                            :value="a.value"
                            @change="checkAnswer()"
                            :disabled="
                              !answer_array.includes(a.value) &&
                              currentQ.maximum_answers > 0 &&
                              answer_array.length >= currentQ.maximum_answers && !answer_array.some(x => x === a)
                            "
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col v-if="currentQ.allow_not_applicable" cols="12" class="py-1 pb-0">
                          <v-checkbox
                            v-model="answer_array" multiple
                            label="Not Applicable"
                            value="Not Applicable"
                            @change="checkAnswer()"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="1"
                      v-if="
                        (currentAnswerDef &&
                          currentAnswerDef.note_requirement !== 'NONE') ||
                        (answer_array.length > 0 && currentQ.answers.length > 0 &&
                          currentQ.answers.some(a => answer_array.includes(a.value) && a.note_requirement !== 'NONE' ))
                      "
                    ></v-col
                    ><v-col
                      cols="5"
                      v-if="
                        (currentAnswerDef &&
                          currentAnswerDef.note_requirement !== 'NONE') ||
                        (answer_array.length > 0 && currentQ.answers.length > 0 &&
                          currentQ.answers.some(a => answer_array.includes(a.value) && a.note_requirement !== 'NONE' ))
                      "
                    >
                      <v-text-field
                        v-if="
                          (currentAnswerDef &&
                            currentAnswerDef.note_currency) ||
                          (answer_array.length > 0 && currentQ.answers.length > 0 &&
                            currentQ.answers.some(a => answer_array.includes(a.value) && a.note_currency))"
                        v-model="currentQ.answer_notes"
                        outlined
                        dense
                        prepend-icon="currency_pound"
                        @input="currentQ.isDirty = true; checkAnswer()"
                        :error="!currentQ.isValid && !currentQ.answer_notes"
                        :error-messages="
                          currentQ.isValid || currentQ.answer_notes
                            ? []
                            : [ (currentQ.answers?.find(a => answer_array.includes(a.value) && a.note_requirement === 'MANDATORY' )?.note_label || currentAnswerDef.note_label) + ' required']
                        "
                      ></v-text-field>
                      <v-textarea
                        v-else
                        v-model="currentQ.answer_notes"
                        outlined
                        rows="3"
                        auto-grow
                        @input="currentQ.isDirty = true; checkAnswer()"
                        :error="!currentQ.isValid && !currentQ.answer_notes"
                        :error-messages="
                          currentQ.isValid || currentQ.answer_notes
                            ? []
                            : [ (currentQ.answers?.find(a => answer_array.includes(a.value) && a.note_requirement === 'MANDATORY' )?.note_label || currentAnswerDef.note_label) + ' required']
                        "
                      ></v-textarea></v-col
                      >
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    v-if="currentQ.questionnaire_question_id_previous"
                    color="primary"
                    @click="setPreviousQuestion"
                    :disabled="busy"
                    outlined
                    >Previous</v-btn
                  >
                  
                  <div v-else>
                    <v-btn
                      class="ml-2"
                      v-for="(a, ai) in handledActions(currentQ)"
                      :key="'a' + ai"
                      color="success"
                      @click="doAction(a)"
                    >
                      {{ a.name }}
                    </v-btn>
                  </div>
                  <v-spacer />
                  <v-progress-circular
                    v-if="busy"
                    :size="30"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <v-btn
                    v-if="
                      currentQ.isDirty &&
                      (
                        ['TEXT', 'MONEY', 'MULTI'].some(
                          (x) => x === currentQ.control_type
                        ) ||
                        (currentAnswerDef &&
                          currentAnswerDef.note_requirement !== 'NONE') ||
                        (answer_array.length > 0 && currentQ.answers.length > 0 &&
                          currentQ.answers.some(a => answer_array.includes(a.value) && a.note_requirement !== 'NONE' ))
                      )
                    "
                    @click="pickAnswer(true)"
                    color="primary"
                    >Save</v-btn
                  >
                  <v-btn
                    color="primary"
                    v-else-if="!currentQ.isLastQuestion"
                    @click="setNextQuestion"
                    :disabled="!currentQ.isValid || currentQ.isDirty || busy"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    color="primary"
                    v-if="action.isDataCaptureEditPopup && currentQ.isLastQuestion && dataCaptureData.isCompleted"
                    @click="close"
                    :disabled="busy"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </span>
        </v-stepper>
      </v-card-text>
      <v-card-text
        v-else-if="
          action.isDataCaptureViewer &&
          !action.isDataCaptureHidden &&
          readOnlyView
        "
        ><DataCaptureQuestionnaireSummary
          :data="readOnlyView"
          :isWithinDialogue="!!action.isDataCaptureEditPopup"
        ></DataCaptureQuestionnaireSummary>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="statusChanged" timeout="1500" centered color="success"
      >Questionnaire now {{ dataCaptureData ? dataCaptureData.status : "" }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="statusChanged = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import DataCaptureQuestionnaireSummary from "@/components/cDataCaptureQuestionnaireSummary.vue";
//import ScrollTo from "vue-scrollto";

export default {
  name: "DataCaptureQuestionnaire",
  props: {
    action: Object,
  },
  data: function () {
    return {
      dataCaptureData: {},
      completionData: [],
      resultsN: [],
      readOnlyView: null,
      readOnly: {
        pageSize: 5,
        page: 1,
        pages: 1,
        aCols: 3,
        qCols: 3,
        showPageSize: false,
      },
      open: [],
      currentQ: null,
      currentAnswerDef: null,
      busy: false,
      previousStatus: null,
      statusChanged: false,
		stepNumber: 0,
		answer_array: [],
    };
  },
  components: { DataCaptureQuestionnaireSummary },
  computed: {},
  created() {
    this.loadDataCapture();
  },
  watch: {
    "action.definition.response": function () {
      this.loadDataCapture();
    },
    "readOnly.pageSize": function () {
      this.setReadOnlyCols();
    },
  },
  methods: {
    handledActions(q) {
      let answerRestricted = this.action.handledActions.find(
        (a) =>
          a.questionnaire_answer_id_trigger ===
          q.questionnaire_answer_id_response
      );
      return this.action.handledActions.filter((x) =>
        answerRestricted
          ? x.questionnaire_answer_id_trigger ===
            answerRestricted.questionnaire_answer_id_trigger
          : !x.questionnaire_answer_id_trigger
      );
    },
    isLastQuestion(q) {
      return q && q.answers.some((x) => x.isResponse && x.isEndAnswer);
    },

    downloadS3File(key) {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("file/downloadFile/" + key)
        .then((resp) => {
          possibleError = true;
          window.open(resp.data, "_blank");
          this.isLoading = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    doAction(a) {
      this.$emit("actionTriggered", a);
    },
    pickAnswer(isManualSave) {
      this.setCurrentAnswerDef();
      this.checkAnswer(true);
      this.currentQ.isDirty = true;
      if (
        !this.currentAnswerDef ||
        !this.currentQ.isValid ||
        (!isManualSave && this.currentAnswerDef.note_requirement !== "NONE")
      )
        return;
      let dataItem = {
        data: {
          questionnaire_question_id: this.currentQ.questionnaire_question_id,
          answer: this.currentQ.control_type === "MULTI" ? this.answer_array : this.currentQ.answer,
          notes: this.currentQ?.answer_notes,
          control_type: this.currentQ.control_type,
          document_activity_id: this.dataCaptureData.document_activity_id,
        },
        action: this.action,
        document_data_capture_id: this.dataCaptureData.document_data_capture_id,
        questionnaire_action: "Answer",
      };

      this.$emit("saveAnswer", dataItem);
      this.busy = true;
      this.previousStatus = this.dataCaptureData.status;
    },
    selectSection(s) {
      if (!s.questions?.length)
        return;

      const index = this.dataCaptureData.sections.indexOf(s);
      const prevSection = this.dataCaptureData.sections[index - 1];

      if (prevSection && prevSection.questions.some(q => !q.answer))
        return;

      this.setCurrentQuestion(s.questions[0].questionnaire_question_id);
    },
    setCurrentQuestion(id) {
      let nextQ = null;
      this.dataCaptureData.sections.forEach((s) => {
        if (!nextQ) {
          nextQ = s.questions.find(
            (q) =>
              q.questionnaire_question_id === id || (!id && q.isStartQuestion)
          );
        }
      });
      nextQ.isDirty = false;
      nextQ.isValid = false;
      if (nextQ.control_type === "MULTI") this.answer_array = nextQ.answer ? nextQ.answer : [];
      this.currentQ = nextQ;
      this.setCurrentAnswerDef();
      this.checkAnswer(true);
      this.stepNumber =
        this.dataCaptureData.sections.length > 1
          ? nextQ.sectionIndex
          : nextQ.questionIndex;
      
      // this.$nextTick(() => {
      //   ScrollTo.scrollTo("#question" + nextQ.questionnaire_question_id, 500, {
      //     container: ".v-stepper",
      //     easing: "ease-in",
      //     offset: -60,
      //     force: true,
      //     cancelable: true,
      //     x: false,
      //     y: true,
      //   });
      // })
    },
    setCurrentAnswerDef() {
      switch (this.currentQ.control_type) {
        case "TEXT":
        case "MULTI":
        case "MONEY":
          this.currentAnswerDef =
            this.currentQ?.answers.length > 0 ? this.currentQ.answers[0] : null;
          break;
        default:
          this.currentAnswerDef = this.currentQ?.answers.find(
            (x) => x.value === this.currentQ.answer
          );
      }
    },
    checkAnswer(existing) {
      if (!existing) {
        this.currentQ.isDirty = true;
        this.setCurrentAnswerDef();
      }
      let isValid = true;
      if (this.currentQ.control_type === "MULTI") {
        let isNA =
          this.currentQ.allow_not_applicable &&
          this.answer_array.some((x) => x === "Not Applicable");
        if (isNA) {
          this.answer_array = ["Not Applicable"]; // clear out any other picks
        }
        if (
          !isNA &&
          (this.currentQ.maximum_answers || this.currentQ.minimum_answers)
        ) {
          let picks = this.answer_array.length;
          isValid =
            (!this.currentQ.maximum_answers ||
              picks <= this.currentQ.maximum_answers) &&
            (!this.currentQ.minimum_answers ||
              picks >= this.currentQ.minimum_answers);
        }
        if (this.currentQ.answers.some(a => this.answer_array.includes(a.value) && a.note_requirement === 'MANDATORY' ) && !this.currentQ.answer_notes) {
          isValid = false;
        }
      } else if (this.currentQ.control_type === "TEXT" || this.currentQ.control_type === "MONEY") {
        isValid = !!this.currentQ.answer;
      } else {
        isValid =
          !!this.currentAnswerDef &&
          (this.currentAnswerDef.note_requirement !== "MANDATORY" ||
            !!this.currentQ.answer_notes);
      }
      this.currentQ.isValid = isValid;
    },
    setNextQuestion() {
      let id = this.currentQ.questionnaire_question_id_next;
      if (id) this.setCurrentQuestion(id);
    },
    setPreviousQuestion() {
      let id = this.currentQ.questionnaire_question_id_previous;
      if (id) this.setCurrentQuestion(id);
    },
    loadDataCapture() {
      this.dataCaptureData = null;
      if (this.captureData !== null) {
        if (this.action.isDataCaptureViewer) {
          this.readOnlyView = JSON.parse(
            JSON.stringify(this.action.definition.view)
          );
        } else {
          let dataCaptureData = JSON.parse(
            JSON.stringify(this.action.definition.response)
          );
          dataCaptureData.sections.forEach((s) => {
            s.questions.forEach((q) => {
              q.isValid = false;
              q.isDirty = false;
            });
          });
          if (
            this.previousStatus &&
            this.previousStatus !== dataCaptureData.status
          ) {
            this.statusChanged = true;
          }
          this.dataCaptureData = dataCaptureData;
          if (this.dataCaptureData.lastCompleteQuestion) {
            this.setCurrentQuestion(
              this.dataCaptureData.lastCompleteQuestion
                .questionnaire_question_id
            );
            if (!this.dataCaptureData.lastCompleteQuestion.isLastQuestion) {
              this.setNextQuestion();
            }
          } else {
            this.setCurrentQuestion();
          }
        }
      }
      this.busy = false;
    },
    showSection(s) {
      s.show = !s.show;
    },
    setReadOnlyCols() {
      switch (this.readOnly.pageSize) {
        case 1:
          this.readOnly.qCols = 6;
          this.readOnly.aCols = 6;
          break;
        case 2:
          this.readOnly.qCols = 4;
          this.readOnly.aCols = 4;
          break;
        case 3:
          this.readOnly.qCols = 3;
          this.readOnly.aCols = 3;
          break;
        case 4:
          this.readOnly.qCols = 4;
          this.readOnly.aCols = 2;
          break;
        case 5:
          this.readOnly.qCols = 2;
          this.readOnly.aCols = 2;
          break;
      }
    },
    toggleHide() {
      this.$emit("toggleHidden");
    },
    triggerEdit() {
      this.$emit("requestEdit", this.action.dataCaptureEditAction);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
.questionnaireHeader {
  .v-expansion-panel-header {
    padding: 15px;
    margin-top: 5px;
    min-height: 40px !important;
    .active {
      background-color: rgba(68, 107, 235, 0.795) !important;
    }
  }
}

.questionnaireSectionHeader {
  .v-expansion-panel-header {
    padding: 15px;
    margin-top: 5px;
    min-height: 40px !important;
  }

  .v-expansion-panel-content {
    margin-top: 10px;
  }
}

.v-tooltip__content {
  background: rgba(97, 97, 97, 1);
}
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.underline {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 10px;
  padding-top: 5px;
  padding-right: 10px;

  div {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
  }
}
.underline:last-child {
  border-bottom: 0;
}

button.v-btn.stepper-right,
button.v-btn.stepper-left {
  min-width: 20px !important;
  height: 100%;
}
.stepper-right {
  margin-right: -25px;
  float: right;
}
.stepper-left {
  margin-left: -25px;
  float: left;
}
.answer-score-selected,
.answer-score {
  float: right;
  width: 30px;
  border: 1px green solid;
  border-radius: 5px;
  text-align: center;
  padding-top: 3px;
}
.answer-score-selected {
  border-width: 3px;
  font-weight: bold;
}
.answer {
  width: 100%;
  padding-top: 3px;
}
.answer-text {
  width: calc(100% - 40px);
  float: left;
}
::v-deep .v-input--selection-controls .v-input__slot > .v-label {
  white-space: unset !important;
}
</style>