<template>
  <div>
    <v-container fluid class="no-print pt-6">
      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <Loading :isVisible="isLoading" />

      <v-dialog v-model="show" persistent width="1200">
        <v-card>
          <v-card-title>
            <h3 class="py-2" v-if="user_data.isNew">Create New User</h3>
            <h3 class="py-2" v-else>Edit User - {{ user_data.name }}</h3>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!user_data.isNew"
              color="primary"
              outlined
              @click="deactivatedUser"
              :disabled="isDirty"
              >Make User Inactive</v-btn
            >
            <v-btn
              v-if="!user_data.isNew && allowPasswordEmail"
              :disabled="isDirty"
              color="primary"
              outlined
              @click="resetPassword('email')"
              class="ml-3"
              >Send Password Email</v-btn
            >
            <v-btn
              v-if="
                $loginState.canAdmin && !user_data.isNew && allowPasswordEmail
              "
              :disabled="isDirty"
              color="primary"
              outlined
              @click="resetPassword('copy')"
              class="ml-3"
              >Copy Set-Password Link</v-btn
            >
          </v-card-title>
          <v-card-text class="pt-3 pb-0">
            <v-form ref="userform">
              <v-row dense>
                <v-col cols="3">
                  <v-text-field
                    outlined
                    dense
                    class="roundish"
                    v-model="user_data.first_name"
                    label="First Name*"
                    :rules="[rules.required]"
                    @input="setDirty"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    outlined
                    dense
                    class="roundish"
                    v-model="user_data.last_name"
                    label="Last Name*"
                    :rules="[rules.required]"
                    @input="setDirty"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        outlined
                        dense
                        class="roundish"
                        v-model="user_data.username"
                        label="username*"
                        :error-messages="
                          usernameUnique ? '' : 'Username already in use'
                        "
                        :rules="[rules.required]"
                        @change="checkUnique"
                        @input="setDirty"
                      ></v-text-field>
                    </v-col>
                    <v-col style="max-width: 180px" class="d-flex justify-center">
                      <v-switch
                        v-model="user_data.sso_only"
                        label="SSO Only"
                        @change="setDirty"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    class="roundish"
                    v-model="user_data.email"
                    label="Email*"
                    :rules="[rules.required, rules.email]"
                    @input="setDirty"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-row dense>
                    <v-col>
                      <v-select
                        outlined
                        dense
                        class="roundish"
                        multiple
                        v-model="user_data.permissionGroups"
                        label="Permission Groups"
                        :items="permissionGroups"
                        :error-messages="permissionGroupsError ? 'Required' : ''"
                        item-text="name"
                        item-value="permission_group_id"
                        @change="checkPermissionGroups"
                        @input="setDirty"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="
                        $loginState.canAdmin &&
                        !user_data.permissionGroups.length
                      "
                    >
                      <v-select
                        outlined
                        dense
                        class="roundish"
                        v-model="user_data.upt_id"
                        label="User Level"
                        :error-messages="permissionGroupsError ? 'Required' : ''"
                        :items="userProfiles"
                        item-text="profile_type"
                        item-value="upt_id"
                        @change="checkPermissionGroups"
                        @input="setDirty"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" v-if="$loginState.canAdmin">
                  <v-select
                    outlined
                    dense
                    class="roundish"
                    v-model="user_data.impersonation_rights"
                    label="Impersonation Rights"
                    :items="impersonation_rights"
                    item-text="label"
                    item-value="value"
                    @input="setDirty"
                  ></v-select>
                </v-col>
                <v-col cols="6" v-if="
                  $loginState.canAdmin &&
                  user_data.impersonation_rights === 'SPECIFIC_USERS'
                ">
                  <v-combobox
                    outlined
                    dense
                    class="roundish"
                    v-model="user_data.impersonation_user_ids"
                    :items="userList"
                    item-text="name"
                    item-value="user_id"
                    label="Impersonation Users"
                    multiple
                    chips
                    clearable
                    :return-object="false"
                    @input="setDirty"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ getUserName(data.item) }}
                      </v-chip>
                    </template></v-combobox
                  >
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    class="roundish"
                    v-model="user_data.business_area"
                    label="Business/Org Area"
                    @input="setDirty"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-switch
                    v-model="user_data.restrict_to_hierarchy"
                    label="Restrict To Hierarchy?">
                  </v-switch>
                </v-col>
                <v-col
                  cols="6"
                  v-for="(h, hi) in hierarchies"
                  :key="'h' + hi"
                  class="pb-4"
                >
                  <HierarchyTreePicker
                    v-if="useTreePicker"
                    v-model="h.treePickerValue"
                    :options="h.treePickerOptions"
                    :fulldetail="true"
                    :placeholder="'Assign an optional ' + h.label"
                    :clearable="true"
                    :hierarchyType="h"
                    :multiple="true"
                    dense
                    @change="onHrySelect(h)"
                    @changeNodeIds="onHryNodeIdSelect(h, $event)"
                  >
                  </HierarchyTreePicker>
                  <v-autocomplete
                    v-else-if="!h.selectNew"
                    dense
                    outlined
                    clearable
                    class="roundish"
                    auto-select-first
                    :ref="'hcb' + hi"
                    :return-object="false"
                    :items="h.values"
                    item-value="text"
                    item-text="text"
                    :label="h.label"
                    multiple
                    v-model="h.hierarchy_text"
                    @focus="focusHTID = hi"
                    @keyup="searchText($event, h)"
                    @keyup.enter.exact="pickValue(h)"
                    @keyup.ctrl.enter="pickValue(h, true)"
                    :filter="utils.comboFilterPicker"
                    @change="onHrySelect(h)"
                    hide-details
                    :append-icon="h.icon"
                  >
                    
                    <template v-slot:prepend-item>
                      <div
                        v-if="h.showNewPrompt"
                        style="padding: 0px 0px 10px 10px; font-size: 14px"
                      >
                        <div>
                          Press <kbd>Enter</kbd> to pick the highlighted item
                        </div>
                      </div>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ h.not_in_list_message }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="$loginState.canAdmin" dense>
                <v-col cols="3" class="pb-4">
                  <v-switch label="Enable Spellcheck?" @change="setDirty" v-model="user_data.spellCheck.enabled" dense></v-switch>
                </v-col>
                <v-col cols="3" class="pb-4">
                  <v-switch v-if="user_data.spellCheck.enabled" @change="setDirty" label="Run on document load?" v-model="user_data.spellCheck.options.checkOnLoad" dense></v-switch>
                </v-col>  
                <v-col cols="3" class="pb-4 pt-2">
                  <div @click="toggleDictionaryDialog(true)" style="cursor:pointer">
                    <v-icon label="Edit Dictionary">mdi-book-alphabet</v-icon> Edit User Dictionary
                  </div>
                </v-col>                
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn v-if="!isDirty" color="primary" outlined @click="cancelEdit"
              >Close</v-btn
            >
            <v-btn v-if="isDirty" color="primary" outlined @click="cancelEdit"
              >Cancel</v-btn
            >
            <v-btn v-if="isDirty" color="primary" @click="saveUser">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <UserDictionary
        :user="{          
          user_id: user_data.user_id,
          spellCheck: user_data.spellCheck
        }"
        :show="showDictionary"
        @close="(val) => toggleDictionaryDialog(val)"
      >
      </UserDictionary>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import utils from "@/common/utils.js";
import UserDictionary from "@/components/common/UserDictionary";
import { mapState } from "vuex";

export default {
  name: "checkJP",
  components: {
    ResponseHandler,
    HierarchyTreePicker, UserDictionary
    // draggable
  },
  props: {
    user_id: String,
    showDialogue: Boolean,
  },
  data: function () {
    return {
      response: null,
      userList: [],
      hierarchies: [],
      utils: utils,
      isDirty: false,
      userProfiles: [],
      isLoading: false,
      useTreePicker: true,
      user_data: {
        isNew: false,
        user_id: null,
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        business_area: "",
        sso_only: 0,
        upt_id: 1,
        upt_ids: [ 1 ],
        impersonation_rights: "NONE",
        impersonation_user_ids: [],
        restrict_to_hierarchy: 0,
        hierarchies: [],
        permissionGroups: [],
        spellCheck: {
            enabled: false,
            options: {
              checkOnLoad: false,
              dictionary: []
            }
        }
      },
      permissionGroups: [],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      usernameUnique: true,
      permissionGroupsError: false,
      show: false,
      allowPasswordEmail: false,
      impersonation_rights: [
        { value: "NONE", label: "None" },
        { value: "ANY_USER", label: "Any User" },
        { value: "SPECIFIC_USERS", label: "Specific Users" },
      ],
      showDictionary:false
    };
  },
  watch: {
    showDialogue(val) {
      if (val) {
        this.fetchData();
      } else {
        this.show = false;
      }
    },
    hierarchiesLoading(val) {
      if (val) {
        this.setData();
      }
    },
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      config: (state) => state.settings.config,
    }),
  },
  created() {
    if (this.showDialogue) this.fetchData();
  },
  methods: {
    toggleDictionaryDialog(val){
      this.showDictionary = val;
    },
    setData() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.useTreePicker = this.$loginState.user.settings.find(
          (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
        );
        this.hierarchies = this.$store.state.hierarchies.hierarchies.map(
          (h) => {
            let hry = { ...h };
            switch (hry.label.toLowerCase()) {
              case "geography":
              case "location":
                h.icon = "mdi-map-marker-outline";
                break;
              default:
                h.icon = "mdi-menu-down";
                break;
            }
            return hry;
          }
        );
      } else {
        this.resetTreePicker();
      }
    },
    resetTreePicker() {
      if (this.hierarchies.length) {
        this.hierarchies.forEach((h) => (h.treePickerValue = []));
      }
    },
    fetchData() {
      this.setData();
      this.usernameUnique = true;
      this.permissionGroupsError = false;
      this.isLoading = true;
      let possibleError = false;
      axios
        .post("user/detail", { user_id: this.user_id })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.userProfiles = resp.data.Data.userProfileList;
            this.userList = resp.data.Data.userList;

            const user = resp.data.Data.user;
            this.user_data.isNew = !user.user_id;
            this.user_data.user_id = user.user_id;
            this.user_data.name = user.name;
            this.user_data.first_name = user.first_name;
            this.user_data.last_name = user.last_name;
            this.user_data.email = user.email;
            this.user_data.username = user.username;
            this.user_data.business_area = user.business_area;
            this.user_data.sso_only = user.sso_only;
            this.allowPasswordEmail = !user.sso_only;
            this.user_data.upt_id = Number(user.upt_id);
            this.user_data.upt_ids = [ Number(user.upt_id) ];
            this.user_data.restrict_to_hierarchy = user.restrict_to_hierarchy;
            this.user_data.hierarchies = user.hierarchies;
            this.user_data.hierarchy_nodes = user.hierarchy_nodes;
            this.hierarchies.forEach((ht) => {
              let uh = user.hierarchies.find((x) => x.ht_id === ht.ht_id);
              ht.treePickerValue = uh ? uh.hr_id : [];

              const findTreePickerId = (node_id, options) => {
                if (!options || options.length === 0)
                  return null;

                const opt = options.find(o => o.node_id === node_id);
                if (opt)
                  return opt.id;
                else {
                  for (let i = 0; i < options.length; i++) {
                    const id = findTreePickerId(node_id, options[i].children);
                    if (id)
                      return id;
                  }
                }
              }

              const uhn = user.hierarchy_nodes.find((x) => x.ht_id === ht.ht_id);
              if (uhn) {
                uhn.node_ids.forEach(node_id => {
                  const id = findTreePickerId(node_id, ht.treePickerOptions);
                  if (id && !ht.treePickerValue.includes(id))
                    ht.treePickerValue.push(id);
                })
              }
            });
            this.permissionGroups = resp.data.Data.permissionGroups;
            this.user_data.permissionGroups = user.permissionGroups.map(pg => pg.pgId);
            if (this.user_data.isNew && this.user_data.permissionGroups.length === 0 && this.permissionGroups.length) {
              const defaultPgName = this.config.settings.find(s => s.setting === "sso_permission_group_default")?.value || "Manager";
              const defaultPg = this.permissionGroups.find(pg => pg.name === defaultPgName) || this.permissionGroups[0];
              this.user_data.permissionGroups.push(defaultPg.permission_group_id);
            }

            this.user_data.impersonation_rights =
              user.impersonation_rights || "NONE";
            this.user_data.impersonation_user_ids = user.impersonation_user_ids;
            this.user_data.spellCheck = user.spellCheck;
          }
          this.isLoading = false;
          this.show = true;
          this.isDirty = false;
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    resetPassword(action = "email") {
      this.isLoading = true;
      axios
        .post("auth/setPasswordRequest/", {
          userId: this.user_data.user_id,
          action: action,
          //username: this.user_data.username,
        })
        .then((resp) => {
          this.response = resp.data;
          this.isLoading = false;

          if (action === "copy" && resp.data.Data.length) {
            const link = resp.data.Data[0].setPasswordLink;
            this.copyToClipboard(link);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    copyToClipboard(text) {
      let container;
      let dialog = document.getElementsByClassName("v-dialog--active");
      if (dialog && dialog.length) container = dialog[0];
      else container = document.body;

      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.setAttribute("readonly", "");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      container.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      container.removeChild(textarea);
    },
    setDirty() {
      if (!this.isLoading) this.isDirty = true;
    },
    cancelEdit() {
      this.resetTreePicker();
      this.$emit("closed");
      this.isDirty = false;
    },
    saveUser() {
      this.checkPermissionGroups();
      
      if (!this.usernameUnique || !this.$refs.userform.validate() || this.permissionGroupsError)
        return;

      if (this.user_data.permissionGroups.length > 0) {
        this.user_data.upt_ids = this.permissionGroups
          .filter(pg => this.user_data.permissionGroups.includes(pg.permission_group_id))
          .map(pg => pg.upt_id);
      } else {
        this.user_data.upt_ids = [ this.user_data.upt_id ];
      }
      this.isLoading = true;
      axios
        .post("user/save/", this.user_data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (this.user_data.isNew) {
              this.user_data.isNew = false;
              this.user_data.user_id = resp.data.Data.user.user_id;
              this.user_data.name =
                resp.data.Data.user.first_name +
                " " +
                resp.data.Data.user.last_name;
            } else {
              this.resetTreePicker();
            }
            this.$emit("saved", resp.data.Data.user);
            this.isDirty = false;
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    deactivatedUser() {
      if (!this.user_data.user_id) return;
      let data = { user_id: this.user_data.user_id };
      this.isLoading = true;
      axios
        .post("user/delete/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.$emit("deactivated", this.user_data);
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    checkUnique() {
      if (!this.user_data.username) return;
      axios
        .post("user/uniquenamecheck/", {
          user_id: this.user_data.user_id,
          username: this.user_data.username,
        })
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.usernameUnique = !resp.data.Data.inUse;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    checkPermissionGroups() {
      this.permissionGroupsError = !this.user_data.permissionGroups.length && !this.user_data.upt_id;
    },
    sortByProperty(property) {
      return function (a, b) {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;

        return 0;
      };
    },
    getUserName(user_id) {
      let user = this.userList.find((x) => x.user_id === user_id);
      return user ? user.name : "UNKNOWN";
    },
    onHrySelect(ht) {
      let h = this.user_data.hierarchies.find((x) => x.ht_id === ht.ht_id);
      let hr_id = this.useTreePicker
        ? ht.treePickerValue
        : ht.hierarchy_text.map(txt => ht.values.find((v) => v.text === txt)?.value);
      let isUpdate = !h || h.hr_id !== hr_id;
      if (h) {
        h.hr_id = hr_id;
      } else {
        this.user_data.hierarchies.push({
          ht_id: ht.ht_id,
          hr_id: hr_id,
        });
      }
      if (isUpdate) this.setDirty();
    },
    onHryNodeIdSelect(ht, selNodeIds) {
      let h = this.user_data.hierarchy_nodes.find((x) => x.ht_id === ht.ht_id);
      if (h) {
        h.node_ids = selNodeIds;
      } else {
        this.user_data.hierarchy_nodes.push({
          ht_id: ht.ht_id,
          node_ids: selNodeIds,
        });
      }
    }
  },
};
</script>
<style scoped></style>
