<template>
  <div v-if="action">
    <v-card v-if="isBusy" style="min-height: 500px" class="pa-5">
      <v-toolbar fixed dense flat color="transparent">
        <v-toolbar-title>{{ this.title }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="5"></v-col>
          <v-col>
            <v-progress-circular indeterminate size="50" color="grey">
            </v-progress-circular>
          </v-col>
          <v-col cols="5"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-else-if="!action.isDataCaptureViewer">
      <v-card
        v-if="action.definition && hierarchyPicker"
        style="min-height: 500px"
        class="pa-5"
      >
        <v-toolbar fixed dense flat color="transparent">
          <v-toolbar-title>{{ this.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn large icon class="btn-background ml-2" @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-label v-if="allowNewHierarchy">
                Select an existing {{ hierarchyPicker.hierarchy.label }} from
                the the list or pick "Add New Entry" at the appropriate level:
              </v-label>
              <v-label v-else>
                Select an existing {{ hierarchyPicker.hierarchy.label }} from
                the the list:
              </v-label>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :cols="
                ['longtext', 'hierarchy_picker_new', 'hierarchy_picker'].some(
                  (x) => x === ip.response_type
                )
                  ? 12
                  : 6
              "
              v-for="(ip, iip) in definition.inputs"
              :key="'ip' + iip"
            >
              <HierarchyTreePicker
                v-if="
                  (!ip.show || hierarchyPicker[ip.show]) &&
                  ['hierarchy_picker_new', 'hierarchy_picker'].some(
                    (x) => x === ip.response_type
                  )
                "
                v-model="ip.value"
                :options="hierarchyPicker.hierarchy.treePickerOptions"
                :placeholder="ip.question_text"
                dense
                @change="pickHierarchy"
              >
              </HierarchyTreePicker>
              <v-text-field
                v-else-if="
                  hierarchyPicker[ip.show] && ip.response_type === 'text'
                "
                v-model="ip.value"
                dense
                class="roundish"
                outlined
                clearable
                :label="ip.question_text"
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="validate"
              ></v-text-field
              ><v-textarea
                v-else-if="
                  hierarchyPicker[ip.show] && ip.response_type === 'longtext'
                "
                v-model="ip.value"
                dense
                class="roundish"
                outlined
                clearable
                :label="ip.question_text"
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="validate"
              ></v-textarea
              ><v-switch
                v-else-if="
                  hierarchyPicker[ip.show] && ip.response_type === 'boolean'
                "
                v-model="ip.value"
                :label="ip.question_text"
                dense
                class="roundish"
                outlined
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="validate"
              ></v-switch
              ><v-select
                v-else-if="
                  hierarchyPicker[ip.show] && ip.response_type === 'picklist'
                "
                v-model="ip.value"
                dense
                class="roundish"
                outlined
                clearable
                :label="ip.question_text"
                :items="ip.valid_values"
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="validate"
              ></v-select
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row dense>
            <v-col cols="8">
              <h4 class="font-weight-bold" v-if="isValid">
                {{ hierarchyPicker.description.text }}
              </h4>
              <div
                v-for="(l, li) in hierarchyPicker.description.levels"
                :key="'li' + li"
                :class="'mt-2 ml-' + (li + 1) * 3"
              >
                <v-icon small v-if="li > 0"
                  >mdi-subdirectory-arrow-right</v-icon
                >
                <v-chip small label>{{ l }}</v-chip>
              </div>
            </v-col>
            <v-col>
              <v-btn class="mr-2" @click="save" :disabled="!isValid"
                >Confirm</v-btn
              >
              <v-btn @click="cancel">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";

export default {
  name: "DataCaptureHierarchy",
  props: {
    action: Object,
    documentHierarchies: [],
    isBusy: Boolean,
  },
  data: function () {
    return {
      dataCaptureValue: null,
      isValid: false,
      definition: null,
      title: "",
      hierarchyPicker: null,
      allowNewHierarchy: false,
    };
  },
  components: {
    HierarchyTreePicker,
  },
  computed: {},
  created() {
    this.init();
  },
  watch: {
    action(newval) {
      if (newval) {
        this.init();
      }
    },
  },
  methods: {
    init() {
      this.definition = { ...this.action.definition };
      let hierarchyInput = this.definition.inputs.find(
        (x) =>
          ["hierarchy_picker_new", "hierarchy_picker"].indexOf(
            x.response_type
          ) >= 0
      ); // assumes only one h input
      this.allowNewHierarchy =
        hierarchyInput.response_type === "hierarchy_picker_new";
      if (hierarchyInput) {
        this.hierarchyPicker = this.setupHierarchyPicker(hierarchyInput.parm1);

        let hierarchyNameInput = this.definition.inputs.find(
          (x) =>
            x.destination_table === "hierarchy" &&
            x.destination_column === "level"
        ); // assumes only one h input

        let currentDocHierarchy = this.documentHierarchies.find(
          (x) => x.ht_id === this.hierarchyPicker.ht_id
        );
        if (this.allowNewHierarchy) {
          hierarchyNameInput.value = currentDocHierarchy.hierarchy1;
        }
        this.title = `Update ${this.hierarchyPicker.hierarchy.label} : ${currentDocHierarchy.hierarchy1}`;
      }
      if (!this.action.isDataCaptureViewer) {
        this.validate();
      }
    },
    setupHierarchyPicker(hierarchy_type_id) {
      let ht_id = Number(hierarchy_type_id);
      let hierarchy = JSON.parse(
        JSON.stringify(
          this.$store.state.hierarchies.hierarchies.find(
            (xx) => xx.ht_id === ht_id
          )
        )
      );

      let picker = {
        isNew: false,
        description: { text: "", levels: [] },
        item: null,
        hr_id: null,
        ht_id: ht_id,
        text: "",
        hr_id_copy: null,
        newItems: [],
        hierarchy: null,
        hierarchyInstance: null,
      };
      let currentLevels = [];
      let newId = 0;
      let checkForChildren = (item) => {
        if (item.children) {
          currentLevels.push(item.label);
          item.children.forEach((c, i) => {
            let copyId = checkForChildren(c);
            let label = `>> Add New Entry under [${currentLevels.join(" > ")}]`;
            if (copyId && i === 0) {
              newId--;
              item.children.unshift({
                id: newId,
                label: label,
              });
              picker.newItems.push({
                id: newId,
                levels: [...currentLevels],
                copyId: copyId,
              });
            }
          });
          return 0;
        } else {
          return item.id;
        }
      };
      hierarchy.treePickerOptions = hierarchy.treePickerOptions.filter(
        (x) => x.children && x.children.length
      );
      if (this.allowNewHierarchy) {
        hierarchy.treePickerOptions.forEach((o) => {
          currentLevels.splice(0);
          checkForChildren(o);
        });
      }
      picker.hierarchy = hierarchy;
      return picker;
    },
    setValue() {
      let data = {};
      this.definition.inputs.forEach((x) => {
        if (x.response_type === "hierarchy_picker_new") {
          data.isNew = this.hierarchyPicker.isNew;
          data.hr_id_copy = this.hierarchyPicker.hr_id_copy;
          data.hr_id = this.hierarchyPicker.hr_id;
          data.ht_id = this.hierarchyPicker.ht_id;
        } else if (x.response_type === "hierarchy_picker") {
          data.isNew = false;
          data.hr_id_copy = null;
          data.hr_id = this.hierarchyPicker.hr_id;
          data.ht_id = this.hierarchyPicker.ht_id;
        } else {
          data[x.destination_column] = x.value;
        }
      });
      this.dataCaptureValue = data;
    },
    save() {
      this.$emit("save", this.dataCaptureValue, this.action);
    },
    cancel() {
      this.$emit("cancel");
    },
    validate() {
      if (this.hierarchyPicker.hierarchyInstance) {
        this.setValue();
        let hValue = this.hierarchyPicker.hierarchyInstance;
        let title = [hValue.level1];
        if (hValue.level2) title.push(hValue.level2);
        if (hValue.level3) title.push(hValue.level3);
        if (hValue.level4) title.push(hValue.level4);
        if (hValue.level5) title.push(hValue.level5);
        if (hValue.level6) title.push(hValue.level6);
        if (this.hierarchyPicker.isNew) {
          title.splice(title.length - 1, 1);
          title.push(this.dataCaptureValue.level);
        }
        this.hierarchyPicker.description.text = `${
          this.hierarchyPicker.isNew ? "Add new" : "Link to existing"
        } ${this.hierarchyPicker.hierarchy.label}: `;
        this.hierarchyPicker.description.levels = title;
      }
      this.isValid = this.hierarchyPicker.isNew
        ? this.hierarchyPicker.hr_id_copy &&
          this.definition.inputs.every((i) => i.value || !i.mandatory)
        : !!this.hierarchyPicker.hr_id;
      return this.isValid;
    },
    toggleHide() {
      this.$emit("toggleHidden");
    },
    pickHierarchy(hr_id) {
      let hValue = this.hierarchyPicker.hierarchy.values.find(
        (x) => x.value === hr_id
      );
      if (!hValue) {
        let newItem = this.hierarchyPicker.newItems.find((x) => x.id === hr_id);
        hValue = this.hierarchyPicker.hierarchy.values.find(
          (x) => x.value === newItem.copyId
        );
        this.hierarchyPicker.isNew = true;
        this.hierarchyPicker.hr_id_copy = newItem.copyId;
        this.hierarchyPicker.hr_id = null;
      } else {
        this.hierarchyPicker.isNew = false;
        this.hierarchyPicker.hr_id_copy = null;
        this.hierarchyPicker.hr_id = hr_id;
      }
      this.hierarchyPicker.hierarchyInstance = hValue;
      this.validate();
    },
  },
};
</script>
<style scoped lang="scss">
</style>