var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$loginState.isAuthenticated)?_c('v-app-bar',{staticClass:"no-print",attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","dense":""}},[(!_vm.$loginState.noAccess)?_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"histBtn",attrs:{"icon":"","title":"Recent Documents"}},on),[(_vm.recentDocs.length)?_c('v-icon',[_vm._v("mdi-history")]):_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-history")])],1)]}}],null,false,4185714126)},[_c('v-list',{attrs:{"dense":""}},[(_vm.$loginState.user.createDocumentDialogOptions.length)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('addDocument')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-title',[_vm._v("Create New "+_vm._s(_vm.$loginState.user.createDocumentDialogOptions.length === 1 ? _vm.$loginState.user.createDocumentDialogOptions[0].tmpl_name : 'Document'))])],1):_vm._e(),_c('v-subheader',[_vm._v("RECENTLY VIEWED")]),_vm._l((_vm.recentDocs),function(d){return _c('v-list-item',{key:d.id,on:{"click":function($event){return _vm.$emit('openDocument', d)}}},[_c('v-list-item-icon',{staticStyle:{"margin-right":"8px"}},[(d.type === 'Job Advert' || !d.type)?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("@/assets/ja-icon.png")}}):_vm._e(),(d.type === 'Job Profile')?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("@/assets/jp-icon.png")}}):_vm._e(),(d.type === 'Job Description')?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("@/assets/jd-icon.png")}}):_vm._e(),(d.type === 'Intake Flow')?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("@/assets/if-icon.png")}}):_vm._e()]),_c('v-list-item-title',[_vm._v(_vm._s(d.id.substring(3, 12) + " - " + d.title))])],1)})],2)],1):_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),(
        _vm.$loginState.user &&
        _vm.$loginState.user.createDocumentDialogOptions.length &&
        (_vm.$route.meta.text !== 'Home' || _vm.screenName !== 'Home')
      )?_c('v-btn',{staticClass:"createBtn",attrs:{"text":"","title":'Create New ' + (_vm.$loginState.user.createDocumentDialogOptions.length === 1 ? _vm.$loginState.user.createDocumentDialogOptions[0].tmpl_name : 'Document')},on:{"click":function($event){return _vm.$emit('addDocument')}}},[_vm._v(" Create ")]):_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),(!_vm.$loginState.noAccess && _vm.qaActions.length > 1)?_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"menuBtn",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1541029247)},[_c('v-list',_vm._l((_vm.qaActions),function(n){return _c('v-list-item',{key:n.name,staticClass:"ml-0",attrs:{"link":"","to":n.name}},[_c('v-list-item-title',[_vm._v(_vm._s(n.meta.text))])],1)}),1)],1):_vm._e(),_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.$loginState.isAuthenticated ? _vm.$route.meta.text !== "Home" ? _vm.$route.meta.text : _vm.screenName : "RoleMapper"))])]),_c('v-spacer'),(
        _vm.$loginState.user.client !== 'Scottish Parliament' &&
        _vm.$loginState.user.client !== 'Akamai' &&
        _vm.$loginState.user.client !== 'Baker Hughes'
      )?_c('v-toolbar-title',{staticClass:"ml-0 pr-4",staticStyle:{"font-style":"italic","font-size":"14px","color":"#bd6b16"}},[_vm._v(_vm._s(_vm.$loginState.user.client))]):_vm._e(),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-divider',{attrs:{"vertical":""}}),(_vm.showWalkthrough)?_c('v-btn',{attrs:{"title":"Show Walkthrough","icon":""},on:{"click":function($event){return _vm.$emit('showHints')}}},[_c('v-icon',[_vm._v("mdi-play-circle-outline")])],1):_vm._e(),(_vm.$support.status)?_c('v-btn',{attrs:{"title":_vm.$support.status == 'visible' ? 'Hide Help' : 'Request Help',"icon":""},on:{"click":function($event){return _vm.$support.toggle()}}},[_c('v-icon',[_vm._v("mdi-chat-question-outline")])],1):_vm._e(),(
          _vm.$loginState.user &&
          _vm.$loginState.user.settings.some(
            (s) =>
              s.setting === 'user_settings' && s.value.indexOf('allow') === 0
          )
        )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openUserSettings')}}},[_c('v-icon',{attrs:{"title":"Settings"}},[_vm._v(" mdi-cog-outline ")])],1):_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),(!_vm.$loginState.isAuthenticated)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.showLogin()}}},[_vm._v("Login")]):(!_vm.$loginState.sso)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('logout')}}},[_vm._v("Logout")]):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }