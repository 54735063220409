<template>
  <v-container fluid class="pb-8 pt-8">
    <div class="flex-container">
      <div
        v-show="$vuetify.breakpoint.smAndUp && !showHierarchies"
        class="hierarchies-collapsed"
        v-if="definition.showHierarchyFilterPanel"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Advanced Filters"
          @click="showHierarchies = !showHierarchies"
          absolute
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-navigation-drawer
        v-show="$vuetify.breakpoint.smAndUp"
        v-model="showHierarchies"
        absolute
        class="left-col"
        style="height: 100vh"
        v-if="definition.showHierarchyFilterPanel"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Hide Advanced Filters"
          @click="showHierarchies = !showHierarchies"
          absolute
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="d-flex align-center pl-1 pt-5">
          <span class="ml-2">Advanced Filters</span>
        </v-toolbar-title>

        <HierarchySearch
          v-if="showHierarchies"
          :items="applicationListFiltered"
          :preSelected="preSelectedHierarchyFilters"
          :preSelectedClassifiers="preSelectedClassifierFilters"
          :hierarchyTypes="hierarchyTypes"
          :classifierTypes="classifierTypes"
          @filterChanged="doHierarchyFilter"
        ></HierarchySearch>
      </v-navigation-drawer>

      <v-container
        fluid
        :class="{
          'right-col': true,
          hierarchiesCollapsed: !showHierarchies && $vuetify.breakpoint.smAndUp,
          hierarchiesPinned: showHierarchies && $vuetify.breakpoint.lgAndUp,
        }"
      >
        <v-row
          ><v-col
            :cols="reportingStats.length > 1 ? 6 : 12"
            v-for="(r, ri) in reportingStats.filter((x) => x && hasResults)"
            :key="'r' + ri"
          >
            <v-row
              v-if="reportingStats.length > 1"
              @click="r.show = !r.show"
              style="cursor: pointer"
              ><v-col
                ><h3>
                  {{ r.title }}
                </h3></v-col
              ><v-col cols="1"
                ><v-icon>{{
                  r.show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="3" v-if="r.showPieChart && r.show">
                <div class="pieContainer" style="opacity: 0.7">
                  <DocumentDashboardPieChart
                    :section="r.pieChartData"
                    :settings="pieChartSettings"
                    @rowSelected="pieRowSelected"
                    @showMeasure="showMeasure(pieChartSettings, $event)"
                  ></DocumentDashboardPieChart>
                </div>
              </v-col>
              <v-col v-if="r.showCounts && r.show">
                <v-row class="statusCounts">
                  <v-col
                    v-for="(sc, sci) in r.data"
                    :key="sci"
                    @click="toggleStatusFilter(r, sc)"
                    :class="r.filter === sc.status ? 'active' : ''"
                  >
                    <div class="pr-2" style="opacity: 0.7">
                      <v-icon size="16" :color="sc.colour">circle</v-icon>
                    </div>
                    <div>
                      <h3>{{ sc.count }}</h3>
                      <div class="text-uppercase mt-1">{{ sc.status }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-col></v-row
            >
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="
            hasResults &&
            (definition.showHierarchyFilters ||
              definition.showManagerFilter ||
              definition.showDateFilter)
          "
        >
          <v-col v-if="definition.showReviewGroupsFilter">
            <v-autocomplete
              multiple
              v-model="reviewGroupsIncluded"
              label="Review Groups"
              :items="reviewGroupsSummary"
              outlined
              dense
              clearable
              class="roundish"
              @change="runSearch"
              :menu-props="{ offsetY: true }"
            >
            </v-autocomplete>
          </v-col>
          <v-col v-if="definition.showManagerFilter">
            <PeopleFilter
              v-model="peopleIncluded"
              roundish
              @doFilter="runSearch"
            >
            </PeopleFilter>
          </v-col>
          <template v-if="definition.showHierarchyFilters">
            <v-col v-for="(h, hi) in hierarchies" :key="'h' + hi">
              <HierarchyTreePicker
                v-if="useTreePicker"
                v-model="h.treePickerValue"
                :label="h.label"
                :options="h.treePickerOptions"
                :placeholder="h.label"
                :hierarchyType="h"
                clearable
                dense
                @change="onHrySelect"
              >
              </HierarchyTreePicker>
              <v-autocomplete
                v-else
                dense
                outlined
                clearable
                class="roundish"
                auto-select-first
                :ref="'hcb' + hi"
                return-object
                :items="h.values"
                item-value="text"
                item-text="text"
                :label="h.label"
                v-model="h.hierarchy_text"
                @focus="focusHTID = hi"
                @keyup="searchText($event, h)"
                :filter="utils.comboFilterPicker"
                @change="onHrySelect"
                hide-details
                :append-icon="h.icon"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content style="max-width: 800px">
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:prepend-item>
                  <div
                    v-if="h.showNewPrompt"
                    style="padding: 0px 0px 10px 10px; font-size: 14px"
                  >
                    <div>
                      Press <kbd>Enter</kbd> to pick the highlighted item
                    </div>
                  </div>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ h.not_in_list_message }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </template>
          <v-col cols="1" class="d-flex justify-end">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-btn
                  title="Customise Table"
                  large
                  v-on="on"
                  icon
                  class="btn-background ml-3"
                >
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list dense class="pa-3">
                <v-list-item>
                  <v-list-item-title class="subtitle-1"
                    >Customise Table</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  v-for="field in dtColumns.filter(
                    (c) => $loginState.canDemo || !c.demoOnly
                  )"
                  :key="field.value"
                >
                  <v-switch
                    class="v-input--reverse v-input--expand"
                    style="width: 200px"
                    dense
                    v-model="dtColumnsEnabled"
                    :label="field.text"
                    :title="field.text"
                    :value="field.value"
                  ></v-switch>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="applicationListHryFiltered.length !== 0" class="pb-6">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <DocumentDataTable
                  :headers="dtColumnsFiltered"
                  :items="applicationListHryFiltered"
                  :page.sync="currentPage"
                  :items-per-page="itemsPerPage"
                  density="normal"
                  @openDocument="openDocument"
                >
                </DocumentDataTable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div
          v-if="hasResults"
          class="d-flex justify-space-between footer-actions primary-background"
        >
          <PageDescription
            :totalItems="applicationListHryFiltered.length"
            :pageSize="itemsPerPage"
            :currentPage="currentPage"
          />
          <Pagination
            :totalItems="applicationListHryFiltered.length"
            :pageSize="itemsPerPage"
            :currentPage="currentPage"
            @pageNavigation="currentPage = $event"
          />
          <div>
            <v-btn
              outlined
              color="primary"
              small
              class="mr-2"
              @click="exportToCSV"
              >Export</v-btn
            >
            <v-menu offset-y z-index="301">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!$loginState.readOnly"
                  outlined
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPageText }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-for="item in itemsPerPageOptions"
                  :key="item.value"
                  @click="itemsPerPage = item.value"
                >
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-container>
    </div>
  </v-container>
</template>


<script>
import utils from "@/common/utils.js";
import moment from "moment";
import { mapState } from "vuex";
import DocumentDashboardPieChart from "@/components/cDocumentDashboardPieChart";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import PeopleFilter from "@/components/cPeopleFilter.vue";
import DocumentDataTable from "@/components/cDocumentDataTable.vue";
import HierarchySearch from "@/components/cHierarchySearch"; // @ is an alias to /src

export default {
  name: "DocumentList",
  components: {
    DocumentDashboardPieChart,
    Pagination,
    PageDescription,
    HierarchyTreePicker,
    PeopleFilter,
    DocumentDataTable,
    HierarchySearch,
  },
  props: {
    definition: { type: Object },
    docType: { type: Object },
  },
  data: function () {
    return {
      utils: utils,
      hierarchies: [],
      showHierarchies: false,
      useTreePicker: false,
      reviewGroupsIncluded: [],
      peopleIncluded: [],
      hasResults: false,
      applicationList: [],
      applicationListFiltered: [],
      applicationListHryFiltered: [],
      preSelectedHierarchyFilters: [],
      preSelectedClassifierFilters: [],
      reportingStats: [],
      dtColumns: [],
      dtColumnsEnabled: [],
      currentPage: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
        { text: "All", value: -1 },
      ],
      pieChartSettings: {
        measures: [
          {
            name: "StatusCounts",
            measureColumn: "count",
            sort: "",
          },
        ],
        activeMeasures: ["StatusCounts"],
        hideLegend: true,
        hideLabels: true,
      },
      questionnaireStatusColumns: [],
      questionnaires: [],
      doc_id_opened: null,
    };
  },
  computed: {
    ...mapState({
      docsLoading: (state) => state.docs.loading,
      docsList: (state) => state.docs.docsList,
      docsListUpdated: (state) => state.docs.listUpdated,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      reviewGroupsSummary: (state) => state.docs.reviewGroupsSummary,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      classifierTypes: (state) => state.docs.classifierTypes,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      statuses: (state) => state.hierarchies.statuses,
    }),
    docStatusIncluded() {
      return this.docStatusSummary.filter(
        (s) =>
          (!this.definition.statuses?.length && s.show) ||
          this.definition.statuses?.includes(s.status)
      );
    },
    docTypeIncluded() {
      return this.docType && this.docType.tmpl_name
        ? [
            this.docTypeSummary.find(
              (dt) =>
                dt.docType.toLowerCase() ===
                this.docType.tmpl_name.toLowerCase()
            ),
          ]
        : [];
    },
    lifecycleIdsIncluded() {
      if (!this.definition.lifecycles) return null;

      return this.docType?.lifecycles
        .filter((l) => this.definition.lifecycles.includes(l.lifecycle_name))
        .map((l) => l.lifecycle_id);
    },
    dtColumnsFiltered() {
      return [
        ...this.dtColumns.filter(
          (c) =>
            this.dtColumnsEnabled.indexOf(c.value) >= 0 &&
            (this.$loginState.canDemo || !c.demoOnly)
        ),
        //{ text: "", value: "export_menu", sortable: false, align: "end" },
      ];
    },
    filters() {
      if (
        this.docType &&
        this.docType.tmpl_name &&
        !this.docTypeSummary.some(
          (dt) =>
            dt.docType.toLowerCase() === this.docType.tmpl_name.toLowerCase()
        )
      )
        return null;

      const hierarchyFilters = this.useTreePicker
        ? this.hierarchies.map((h) => {
            return {
              ht_id: h.ht_id,
              selected: h.treePickerValue
                ? [{ id: `${h.treePickerValue}` }]
                : [],
            };
          })
        : this.hierarchies.map((h) => {
            return {
              ht_id: h.ht_id,
              selected: h.hierarchy_text
                ? [{ id: `${h.hierarchy_text.value}` }]
                : [],
            };
          });
      return {
        filterText: (this.search || "").trim().toLowerCase(),
        docStatusIncluded: this.docStatusIncluded,
        docTypeIncluded: this.docTypeIncluded,
        lifecycleIdsIncluded: this.lifecycleIdsIncluded,
        hierarchyFilters: hierarchyFilters,
        reviewGroupsIncluded: this.reviewGroupsIncluded,
        peopleIncluded: this.peopleIncluded,
      };
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
    isLoading() {
      return this.docsLoading || this.hierarchiesLoading;
    },
    isManager() {
      return true;
    },
    isHR() {
      return true;
    },
  },
  created() {
    if (!this.isLoading) {
      this.init();
    }
  },
  updated() {},
  watch: {
    isLoading(val) {
      if (!val) {
        this.init();
      }
    },
    docsListUpdated(val) {
      if (val && !this.isLoading) {
        this.runSearch();
      }
    },
    "definition.docListColumns": {
      deep: true,
      handler() {
        this.setColumns();
      },
    },
    "definition.statuses": {
      deep: true,
      handler() {
        this.runSearch();
      },
    },
    filters() {
      this.runSearch();
    },
  },
  methods: {
    setColumns() {
      if (
        this.definition.docListColumns &&
        this.definition.docListColumns.length
      ) {
        this.dtColumns = this.definition.docListColumns.map((c) => {
          if (c.value === "job_created_at" || c.value === "job_updated_at")
            c.sort = (a, b) =>
              moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
                ? 1
                : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
                ? -1
                : 0;
          return c;
        });

        this.dtColumnsEnabled = this.definition.docListColumns
          .filter((c) => c.visible)
          .map((c) => c.value);

        if (
          this.definition.showEvaluationStatus ||
          this.definition.showEvaluationScore
        ) {
          let questionnaireStatusColumns = [];
          this.questionnaires.forEach((r) => {
            if (this.definition.showEvaluationStatus) {
              questionnaireStatusColumns.push({
                title: `${r.name} Status`,
                name: r.name,
                listcolumn: `docQuestionnaireStatus_${r.id}`,
                column: `docQuestionnaireStatus`,
                listcolumnColour: `docQuestionnaireStatusColour_${r.id}`,
                columnColour: `docQuestionnaireStatusColour`,
                questionnaire_id: r.questionnaire_id,
              });
              questionnaireStatusColumns.push({
                title: `${r.name} Progress`,
                name: r.name,
                listcolumn: `questionnaireStatuses_${r.id}`,
                column: `questionnaireStatuses`,
                questionnaire_id: r.questionnaire_id,
              });
            }
            if (this.definition.showEvaluationScore) {
              questionnaireStatusColumns.push({
                title: `${r.name} Score`,
                name: r.name,
                listcolumn: `docQuestionnaireScore_${r.id}`,
                column: `docQuestionnaireScore`,
                questionnaire_id: r.questionnaire_id,
              });
            }
          });

          questionnaireStatusColumns.forEach((c) => {
            this.dtColumns.push({
              text: c.title,
              value: c.column,
              source: c.listcolumn,
              sourceColour: c.listcolumnColour,
              questionnaire_id: c.questionnaire_id,
            });
            this.dtColumnsEnabled.push(c.column);
          });

          this.questionnaireStatusColumns = questionnaireStatusColumns;
        }
      }
    },
    init() {
      this.hierarchies = this.$store.state.hierarchies.hierarchies.map((h) => {
        switch (h.label.toLowerCase()) {
          case "geography":
          case "location":
            h.icon = "mdi-map-marker-outline";
            break;
          default:
            h.icon = "mdi-menu-down";
            break;
        }
        h.treePickerValue = null;
        return h;
      });
      this.useTreePicker = !!this.$loginState.user.settings.find(
        (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
      );
      if (this.definition.showPieChart || this.definition.showCounts) {
        this.reportingStats.push({
          title: "Document Status",
          level: "document",
          column: "statusDisplayText",
          showPieChart: this.definition.showPieChart,
          showCounts: this.definition.showCounts,
          data: [],
          pieChartData: null,
          filter: null,
          show: true,
        });
      }
      if (
        this.definition.showEvaluationCounts ||
        this.definition.showEvaluationPieChart ||
        this.definition.showEvaluationStatus ||
        this.definition.showEvaluationScore
      ) {
        let docs = this.$store.getters["docs/filter"](this.filters);
        this.questionnaires = [];
        docs
          .filter((d) => d.questionnaires.length)
          .forEach((d) => {
            d.questionnaires
              .filter(
                (q) => !this.questionnaires.some((z) => z.name === q.name)
              )
              .forEach((q) =>
                this.questionnaires.push({
                  name: q.name,
                  id: q.id,
                  questionnaire_id: q.questionnaire_id,
                })
              );
          });
        if (
          this.definition.showEvaluationCounts ||
          this.definition.showEvaluationPieChart
        ) {
          this.questionnaires.forEach((q) =>
            this.reportingStats.push({
              title: q.name,
              id: q.id,
              level: "questionnaire",
              groupColumn: "name",
              column: "docQuestionnaireStatus",
              showPieChart: this.definition.showEvaluationPieChart,
              showCounts: this.definition.showEvaluationCounts,
              data: [],
              pieChartData: null,
              filter: null,
              show: true,
            })
          );
        }
      }

      this.setColumns();
      this.runSearch();
      this.hasResults = this.applicationList.length > 0;
    },
    onHrySelect() {
      this.runSearch();
    },
    doHierarchyFilter(hierarchyFilters, classifierFilters) {
      if (hierarchyFilters)
        hierarchyFilters = JSON.parse(
          JSON.stringify(
            hierarchyFilters.map((f) => {
              return { ht_id: f.ht_id, selected: f.selected };
            })
          )
        );
      if (classifierFilters)
        classifierFilters = JSON.parse(
          JSON.stringify(
            classifierFilters.map((f) => {
              return { ct_id: f.ct_id, selected: f.selected };
            })
          )
        );

      let docs = [...this.applicationListFiltered];

      if (hierarchyFilters) {
        hierarchyFilters.forEach((hf) => {
          if (hf && hf.selected.length) {
            docs = docs.filter((d) =>
              hf.selected.find((s) => {
                let ht = d.hierarchies.find((ht) => ht.ht_id === hf.ht_id);
                return ht && s.id.split("_")[0] == ht.hr_id; // && !s.children
              })
            );
          }
        });
      }

      if (classifierFilters) {
        classifierFilters.forEach((cf) => {
          if (cf && cf.selected.length) {
            const cvs = cf.selected.map((c) => c.cv_id);
            docs = docs.filter(
              (d) =>
                d.classifiers.some((c) => cvs.includes(c.cv_id)) ||
                (cvs.includes(0) &&
                  !d.classifiers.some((c) => c.ct_id === cf.ct_id))
            );
          }
        });
      }

      this.preSelectedHierarchyFilters = hierarchyFilters;
      this.preSelectedClassifierFilters = classifierFilters;

      this.applicationListHryFiltered = Object.freeze(docs);
    },
    runSearch() {
      if (!this.filters) return;

      const EndIn30 = "Ending in 30 days";
      let applicationList = this.$store.getters["docs/filter"](this.filters);
      this.reportingStats.forEach((r) => (r.data = []));
      applicationList.forEach((d) => {
        const requested = moment.utc(d.approval_requested_date);
        const ending = requested.add(90, "d");
        const remaining = ending.diff(moment(), "days");
        d.statusDisplayText = utils.resolveStatusChipText(d);
        d.ending = ending.format("DD MMM YYYY");
        d.endingInThirty =
          remaining > 0 && remaining < 30 && d.doc_status !== "APPROVED";
        if (remaining > 0 && d.doc_status !== "APPROVED")
          d.remainingTime = `${remaining} Days`;
        else d.remainingTime = "-";

        this.reportingStats.forEach((r) => {
          if (r.level === "document") {
            let item = r.data.find((s) => s.status === d[r.column]);
            if (!item) {
              item = {
                status: d[r.column],
                colour: utils.resolveHeaderStyle(d),
                count: 0,
              };
              r.data.push(item);
            }
            item.count++;
            if (
              this.docType.tmpl_name.toLowerCase() === "flex apply" &&
              d.endingInThirty
            ) {
              item = r.data.find((s) => s.status === EndIn30);
              if (!item) {
                item = {
                  status: EndIn30,
                  colour: "",
                  count: 0,
                };
                r.data.push(item);
              }
              item.count++;
            }
          } else if (r.level === "questionnaire") {
            let q = d.questionnaires.find((x) => x[r.groupColumn] === r.title);
            if (q) {
              let item = r.data.find((s) => s.status === q[r.column]);
              if (!item) {
                item = {
                  status: q[r.column],
                  colour: q[r.column + "Colour"],
                  count: 0,
                };
                r.data.push(item);
              }
              item.count++;
            }
          }
        });
        if (this.questionnaireStatusColumns.length) {
          d.questionnaires.forEach((q) => {
            this.questionnaireStatusColumns
              .filter((c) => c.name === q.name)
              .forEach((c) => {
                d[c.listcolumn] = q[c.column];
                if (c.listcolumnColour)
                  d[c.listcolumnColour] = q[c.columnColour];
              });
          });
        }
      });
      this.applicationList = applicationList;
      this.reportingStats
        .filter((x) => x.showPieChart)
        .forEach((r) => (r.pieChartData = this.setPieChartData(r.data)));

      let applicationListFiltered = [...applicationList];

      this.reportingStats
        .filter((x) => x.filter)
        .forEach((r) => {
          if (r.level === "document" && r.filter === EndIn30)
            applicationListFiltered = applicationListFiltered.filter(
              (d) => d.endingInThirty
            );
          else if (r.level === "document")
            applicationListFiltered = applicationListFiltered.filter(
              (d) => d[r.column] === r.filter
            );
          else if (r.level === "questionnaire")
            applicationListFiltered = applicationListFiltered.filter((d) =>
              d.questionnaires.some(
                (q) => q[r.groupColumn] === r.title && q[r.column] === r.filter
              )
            );
        });
      this.applicationListFiltered = applicationListFiltered;

      this.doHierarchyFilter(
        this.preSelectedHierarchyFilters,
        this.preSelectedClassifierFilters
      );
    },
    setPieChartData(data) {
      let pieChartData = {
        view: {
          data: data.map((s) => {
            return {
              key: s.status,
              count: s.count,
            };
          }),
          filterCriteria: [],
          dimensionColours: data.map((s) => {
            return {
              value: s.status,
              colour: s.colour,
            };
          }),
          centralTitle: `<h2>${this.applicationList.length}</h2>TOTAL`,
        },
      };
      return pieChartData;
    },
    pieRowSelected(stat, value) {
      if (value) {
        const status = stat.find((c) => c.status === value);
        if (status) this.toggleStatusFilter(stat, status);
      }
    },
    toggleStatusFilter(stat, status) {
      if (stat.filter && stat.filter === status.status) stat.filter = null;
      else stat.filter = status.status;

      this.reportingStats
        .filter((x) => x.title !== stat.title)
        .forEach((r) => (r.filter = null));
      this.runSearch();
    },
    openDocument(item) {
      this.doc_id_opened = item.doc_id;
      this.$emit("openDocument", this.docDetails(item));
    },
    docDetails(item) {
      return {
        doc_id: item.doc_id,
        tmpl_id: item.tmpl_id,
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
      };
    },
    searchText($event, hrchy) {
      let ctl = this.$refs["hcb" + this.focusHTID][0];
      if ([13, 37, 38, 39, 40].indexOf($event.keyCode) >= 0) return;
      if (ctl.lazySearch) {
        if ($event.keyCode !== 13) hrchy.searchText = ctl.lazySearch.trim();
        let match = ctl.filteredItems.find(
          (f) => f.text.toLowerCase() === ctl.lazySearch.toLowerCase()
        );
        if (match) {
          ctl.selectItem(match);
          hrchy.hierarchy_text = match.text;
          hrchy.hr_id = match.hr_id;
          hrchy.showNewPrompt = false;
        } else {
          hrchy.showNewPrompt =
            ctl.filteredItems.length &&
            (!hrchy.hierarchy_text ||
              hrchy.hierarchy_text.toLowerCase() !==
                hrchy.searchText.toLowerCase());
        }
      } else {
        hrchy.hierarchy_text = "";
        hrchy.showNewPrompt = false;
      }
    },
    showMeasure(settings, measure) {
      let item = settings.activeMeasures.findIndex((m) => m === measure.name);
      if (item >= 0) settings.activeMeasures.splice(item, 1);
      else settings.activeMeasures.push(measure.name);
    },
    exportToCSV() {
      let csvHeaders = [];
      this.dtColumns.forEach((c) => {
        if (c.value === "docQuestionnaireStatus") {
          csvHeaders.push({ text: c.text, value: c.source });
        } else if (c.value === "questionnaireStatuses") {
          const statuses = [];
          this.applicationListFiltered.forEach((d) => {
            const source = d[c.source];
            if (source && source.length && source[0].status !== "Unassigned") {
              source.forEach((rs) => {
                if (!statuses.includes(rs.status)) statuses.push(rs.status);
              });
            }
          });
          statuses.forEach((s) => {
            csvHeaders.push({
              text: `${c.text} ${s}`,
              value: "questionnaireStatuses",
              source: c.source,
              status: s,
            });
          });
        } else if (
          c.value === "doc_status_text" &&
          this.applicationListFiltered.some(
            (x) =>
              x.lifecycle_status_non_responders || x.lifecycle_status_responders
          )
        ) {
          csvHeaders.push(c);
          csvHeaders.push({
            text: `Waiting For`,
            value: "lifecycle_status_non_responders",
          });
          csvHeaders.push({
            text: `Completed By`,
            value: "lifecycle_status_responders",
          });
        } else {
          csvHeaders.push(c);
        }
      });

      let data = csvHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";

      this.applicationListFiltered.forEach((d) => {
        data +=
          csvHeaders
            .map((h) => {
              let raw = null;
              if (h.value === "questionnaireStatuses") {
                const source = d[h.source];
                if (
                  source &&
                  source.length &&
                  source[0].status !== "Unassigned"
                ) {
                  raw = source.find((s) => s.status === h.status)?.count || 0;
                }
              } else {
                raw = d[h.value];
              }
              return utils.csvEscape(utils.removeTags(raw));
            })
            .join(",") + "\n";
      });

      utils.downloadFile(
        data,
        `${this.$loginState.user.client.replace(
          new RegExp("\\s+", "g"),
          "_"
        )}_${this.$route.name.replace(
          new RegExp("\\s+", "g"),
          "_"
        )}_${moment().format("YYYYMMDDhhmmss")}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 48px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
  }

  .right-col {
    padding-top: 0;
    padding-right: 5px;
    transition: padding 200ms;
    &.hierarchiesPinned {
      padding-left: 370px;
    }
  }

  @media (max-width: 600px) {
    .right-col {
      padding-left: 0;
    }
  }
}

.hierarchies-collapsed {
  min-height: calc(100vh - 103px) !important;
  .showHierarchies {
    position: fixed;
    top: 82px;
    left: 5px;
  }
}

.showHierarchies {
  top: 22px;
  right: -15px;
}

.pieContainer {
  max-width: 150px;
  margin: auto;
}

.statusCounts {
  width: 100%;
  min-width: none;
  display: flex;
  justify-content: flex-start;
  padding: 5px;

  > div {
    display: flex;
    margin: 0 5px;
    min-width: 200px;
    max-width: 15%;
    cursor: pointer;
    border-radius: 5px;
    &:hover,
    &.active {
      background-color: rgba($color: #cccccc, $alpha: 0.2);
    }
  }
}

::v-deep .v-data-table {
  tbody > tr,
  tbody > tr span {
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .footer-actions {
    padding-left: 15px;
  }
}

.footer-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 4;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.footer-actions {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 55px;

  div {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
  }
}
</style>