<template>
  <v-row class="justify-center mt-6 mb-6 link-container">
    <draggable
      class="row px-3"
      group="landinglinks"
      v-model="links"
      :disabled="!editMode"
    >
      <v-col v-for="(l, li) in links" :key="li" :cols="l.tile_cols" class="pa-0">
        <v-hover v-slot="{ hover }">
          <v-list-item
            color="transparent"
            :class="{
              'quick-link link-container': true,
              'help-disabled': !l.url,
              'hover': hover,
              draggable: editMode,
            }"
            :to="editMode || l.passedParams || l.isExternal ? '' : l.url"
            :href="!editMode && l.isExternal ? l.url : ''"
            :target="l.open_newtab ? '_blank' : ''"
            @click="linkClick($event, l)"
            :ripple="!!(l.url && !editMode)"
          >
            <div v-if="editMode" class="edit-btn" v-show="hover">
              <v-btn icon small title="Delete" @click="deleteTile($event, l)"
                ><v-icon>delete</v-icon></v-btn
              >
              <v-btn icon small title="Copy" @click="copyTile($event, l)"
                ><v-icon>content_copy</v-icon></v-btn
              >
              <v-btn icon small title="Settings" @click="editTile($event, l)"
                ><v-icon>settings</v-icon></v-btn
              >
            </div>
            <template>
              <v-list-item-icon v-if="l.icon">
                <v-icon
                  :color="l.icon_colour"
                  :class="['pr-3', l.icon]"
                  :style="`font-size: ${l.icon_size || 24}px`"
                  >{{ l.icon.split(" ")[0] }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title>{{ l.title }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-hover>
      </v-col>
    </draggable>
    <v-btn
      v-if="editMode"
      class="add-btn"
      large
      icon
      title="Add Tile"
      @click="addTile($event)"
      ><v-icon>add_circle_outline</v-icon></v-btn
    >
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import landingUtils from "./landingUtils.js";

export default {
  name: "landingLinks",
  components: {
    draggable,
  },
  data: function () {
    return {
      iframeUrls: {},
      refreshTimeout: null,
      links: []
    };
  },
  props: {
    definition: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
  },
  mounted() {},
  watch: {
    definition: {
      deep: true,
      handler(val) {
        this.links = val.links;
      }
    },
    links(val) {
      const definition = this.definition;
      definition.links = val;
      this.$emit("updated:definition", definition);
    }
  },
  computed: {},
  created() {
    this.links = this.definition.links;
  },
  methods: {
    linkClick(ev, link) {
      landingUtils.handleLandingLinkClick(this, ev, link);
    },
    editTile(ev, t) {
      ev.preventDefault();
      this.$emit("editTile", { row: this.definition, link: t });
    },
    addTile(ev) {
      ev.preventDefault();
      this.$emit("addTile", { row: this.definition });
    },
    copyTile(ev, t) {
      ev.preventDefault();
      this.$emit("copyTile", { row: this.definition, link: t });
    },
    deleteTile(ev, t) {
      ev.preventDefault();
      this.$emit("deleteTile", { row: this.definition, link: t });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.link-container {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.steps .edit-btn {
  top: -20px;
  right: -20px;
}

.add-btn {
  position: absolute;
  bottom: 0;
  right: -35px;
  z-index: 1;
}

.draggable {
  cursor: move;
}

.quick-link {
  opacity: 0.8;
  border: 1px solid transparent;
  border-radius: 5px;
  .v-list-item__icon {
    margin: 8px 12px 8px 0;
  }
  .v-list-item__title {
    font-size: 15px;
    font-weight: 600;
  }
  &:hover { 
    &::before {
      opacity: 0;
    }
    background-color: transparent !important;
    border: 1px solid #D0D6EC; 
  }
}

</style>