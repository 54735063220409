<template>
  <v-container fluid v-if="value" :class="{ 'px-0': true, 'tabbed': value.tabs && value.tabs.length > 1 }">
    <v-tabs v-if="value.tabs && value.tabs.length > 1" v-model="activeTab" class="mb-6 tabs" grow>
      <v-tab v-for="(tab, ti) in value.tabs" :key="'tabh' + ti">
        <v-btn small icon class="tab-move-left"
          v-if="editMode"
          @click.stop="moveTabLeft(tab)">
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <div v-html="tab.title"></div>
        <div class="tab-edit-actions" v-if="editMode">
          <v-btn small icon @click="$emit('tabEdit', tab)">
            <v-icon>settings</v-icon>
          </v-btn>
          <v-btn small icon @click.stop="deleteTab(tab)">
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn small icon @click.stop="moveTabRight(tab)">
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="(tab, ti) in value.tabs" :key="'tabi' + ti">
        <v-row v-if="activeTab === ti">
          <v-col v-for="(col, coli) in tab.cols" :key="'col' + coli" cols="12" :lg="col.cols ? col.cols : ''">
            <div class="col-edit-actions" v-if="editMode">
              <v-btn icon title="Column Settings" @click="$emit('colEdit', col)">
                <v-icon>settings</v-icon>
              </v-btn>
              <v-btn v-if="tab.cols.length > 1" icon title="Delete Col" @click="$emit('deleteCol', col)">
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
            <draggable v-model="col.rows" :group="'tab' + ti" handle=".draghandle" :disabled="!editMode" tag="div" :class="['row', editMode ? 'px-6' : 'px-12' ]" class="controlcontainer">
              <v-col
                v-for="(r, ri) in col.rows"
                :key="'row' + ri"
                :class="['landing-row', 'py-0', editMode ? 'px-12' : 'px-3' ]"
                v-show="editMode || r.controlType !== 'TaskList' || tasksLoaded"
                :cols="r.cols ? r.cols : '12'"
              >
                <div class="row-edit-actions" v-if="editMode">
                  <v-btn icon title="Control Settings" @click="$emit('rowEdit', r)">
                    <v-icon>settings</v-icon>
                  </v-btn>
                  <v-btn icon title="Delete Control" @click="$emit('deleteRow', r, col)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>

                <v-icon class="draghandle" v-if="editMode">drag_indicator</v-icon>
    
                <v-row :key="'title' + ri" class="mt-6 mb-3" v-if="r.title">
                  <v-col cols="8">
                    <h2 v-if="r.style && r.style.startsWith('collapsible')" @click="r.collapsed = !r.collapsed" style="cursor: pointer">
                      {{ r.title }}
                      <v-icon>{{ r.collapsed ? 'expand_more' : 'expand_less'}}</v-icon>
                    </h2>
                    <h2 v-else>
                      {{ r.title }}
                    </h2>
                  </v-col>

                  <v-col v-if="r.createButton" cols="4" class="d-flex justify-end">
                    <v-btn color="primary" @click="createDocument" v-if="createAction"
                      >Make an application</v-btn
                    >
                  </v-col>
                </v-row>

                <v-slide-y-transition>
                  <div v-show="!r.style || !r.style.startsWith('collapsible') || !r.collapsed ">
                    <div
                      :key="'CreateJD' + ri"
                      v-if="r.controlType === 'CreateDocLinks'"
                    >
                      <CreateDocLinks :editMode="editMode" @addDocument="addDocument">
                      </CreateDocLinks>
                    </div>

                    <div
                      :key="'Tasks' + ri"
                      v-else-if="r.controlType === 'TaskList'"
                    >
                      <Tasks
                        :editMode="editMode"
                        :showTitle="false"
                        :fullScreen="false"
                        :filters="tasksFilters"
                        :minmalColumns="true"
                        @tasksLoaded="tasksLoaded = true"
                        @openDocument="openDocument"
                      >
                      </Tasks>
                    </div>

                    <div
                      class="bggradient"
                      :key="'DocList' + ri"
                      v-else-if="r.controlType === 'DocumentList'"
                    >
                      <DocumentList
                        :definition="r"
                        :docType="docType"
                        :editMode="editMode"
                        @openDocument="openDocument"
                      >
                      </DocumentList>
                    </div>

                    <div v-else-if="r.controlType === 'Stepper'" :key="'stepper' + ri">
                      <LandingStepper
                        :definition="r"
                        :editMode="editMode"
                        :qaActions="qaActions"
                        @editTile="$emit('editTile', $event)"
                        @addTile="$emit('addTile', $event)"
                        @deleteTile="$emit('deleteTile', $event)"
                        @copyTile="$emit('copyTile', $event)"
                        @addDocument="addDocument"
                      >
                      </LandingStepper>
                    </div>

                    <div v-else-if="r.controlType === 'StepperSmall'" :key="'stepper' + ri">
                      <LandingStepperSmall
                        :definition="r"
                        :editMode="editMode"
                        :qaActions="qaActions"
                        @editTile="$emit('editTile', $event)"
                        @addTile="$emit('addTile', $event)"
                        @deleteTile="$emit('deleteTile', $event)"
                        @copyTile="$emit('copyTile', $event)"
                        @addDocument="addDocument"
                      >
                      </LandingStepperSmall>
                    </div>

                    <div v-else-if="r.controlType === 'LinkTilesAlt'" :key="'linksalt' + ri">
                      <LandingLinksAlt
                        :definition="r"
                        :editMode="editMode"
                        :qaActions="qaActions"
                        @editTile="$emit('editTile', $event)"
                        @addTile="$emit('addTile', $event)"
                        @deleteTile="$emit('deleteTile', $event)"
                        @copyTile="$emit('copyTile', $event)"
                        @addDocument="addDocument"
                      >
                      </LandingLinksAlt>
                    </div>

                    <div v-else-if="r.controlType === 'QuickLinks'" :key="'quicklinks' + ri">
                      <LandingQuickLinks
                        :definition="r"
                        :editMode="editMode"
                        :qaActions="qaActions"
                        @editTile="$emit('editTile', $event)"
                        @addTile="$emit('addTile', $event)"
                        @deleteTile="$emit('deleteTile', $event)"
                        @copyTile="$emit('copyTile', $event)"
                        @addDocument="addDocument"
                      >
                      </LandingQuickLinks>
                    </div>
                    
                    <div v-else-if="r.controlType === 'Metabase'" :key="'metabase' + ri">
                      <LandingMetabaseFrame
                        :definition="r"
                        :editMode="editMode"
                        :qaActions="qaActions"
                        @editTile="$emit('editTile', $event)"
                        @addTile="$emit('addTile', $event)"
                        @deleteTile="$emit('deleteTile', $event)"
                        @copyTile="$emit('copyTile', $event)"
                      >
                      </LandingMetabaseFrame>
                    </div>

                    <div v-else :key="'links' + ri">
                      <LandingLinks
                        :definition="r"
                        :editMode="editMode"
                        :qaActions="qaActions"
                        @editTile="$emit('editTile', $event)"
                        @addTile="$emit('addTile', $event)"
                        @deleteTile="$emit('deleteTile', $event)"
                        @copyTile="$emit('copyTile', $event)"
                        @addDocument="addDocument"
                      >
                      </LandingLinks>
                    </div>
                  </div>
                </v-slide-y-transition>
              </v-col>
            </draggable>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <Loading :isVisible="hierarchiesLoading || docsLoading || isLoading" />
  </v-container>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import LandingLinks from "./cLandingLinks";
import LandingLinksAlt from "./cLandingLinksAlt";
import LandingQuickLinks from "./cLandingQuickLinks";
import LandingStepper from "./cLandingStepper";
import LandingStepperSmall from "./cLandingStepperSmall";
import LandingMetabaseFrame from "./cLandingMetabaseFrame";
import CreateDocLinks from "./cCreateDocLinks";
import DocumentList from "./cDocumentList";
import Tasks from "@/components/cTasks";
import { mapState } from "vuex";

export default {
  name: "cLanding",
  components: {
    draggable,
    LandingLinks,
    LandingLinksAlt,
    LandingQuickLinks,
    LandingStepper,
    LandingStepperSmall,
    LandingMetabaseFrame,
    CreateDocLinks,
    DocumentList,
    Tasks,
  },
  props: {
    value: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
    route: { type: String },
  },
  data: function () {
    return {
      isLoading: false,
      docType: null,
      createAction: null,
      tasksFilters: null,
      tasksLoaded: false,
      activeTab: 0
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  watch: {
    hierarchiesLoading(val) {
      if (!val) this.init();
    },
    activeTab(val) {
      if (!isNaN(val)) {
        this.$emit('changeActiveTab', val);
        this.$loginState.saveSetting(this.$route.name + "_activeTab", val);
      }
    }
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
      docsLoading: (state) => state.docs.loading,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      docTypes: (state) => state.hierarchies.docTypes,
    })
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    addDocument(docType) {
      this.$emit("addDocument", docType || this.value.documentType);
    },
    openDocument(item) {
      this.$emit("openDocument", item);
    },
    copyDocument(item, tmpl_id) {
      this.$emit("copyDocument", item, tmpl_id);
    },
    documentChangedHandler(item) {
      this.$emit("documentChangedHandler", item);
    },
    init() {
      let activeTab = null;
      if (!this.editMode)
        activeTab = this.$loginState.getSetting(this.$route.name + "_activeTab", null);
      if (activeTab === null)
        activeTab = this.value.defaultTabIndex;

      if (isNaN(activeTab) || activeTab >= this.value.tabs.length || activeTab < 0)
        this.activeTab = 0;
      else
        this.activeTab = activeTab;
        
      this.docType = this.docTypes.find(
        (dt) =>
          dt.tmpl_name.toLowerCase() === this.value.documentType?.toLowerCase()
      );
      if (this.docType) {
        this.tasksFilters = {
          docTypeIncluded: [ { docType: this.docType.tmpl_name } ]
        };
        const createOption = this.$loginState.user.createDocumentOptions.find(
          (a) => a.tmpl_name === this.docType.tmpl_name
        );
        if (createOption && createOption.lifecycles.length === 1) {
          this.createAction = createOption.lifecycles[0];
        } else if (createOption && createOption.lifecycles.length > 1) {
          this.createAction = createOption.lifecycles.find((x) => x.isDefault);
        }
      }
    },
    moveTabLeft(tab) {
      const currTab = this.value.tabs[this.activeTab];
      this.$emit('tabMoveLeft', tab);
      this.activeTab = this.value.tabs.indexOf(currTab);
    },
    moveTabRight(tab) {
      const currTab = this.value.tabs[this.activeTab];
      this.$emit('tabMoveRight', tab);
      this.activeTab = this.value.tabs.indexOf(currTab);
    },
    deleteTab(tab) {
      this.$emit('deleteTab', tab);
      if (this.activeTab >= this.value.tabs.length)
        this.activeTab = 0;
    },
    createDocument() {
      if (!this.docType || !this.createAction) return;

      const data = {
        recruiter: "",
        docType: this.docType.tmpl_name,
        hierarchies: [],
        docClassifiers: [],
        action: this.createAction,
      };
      this.isLoading = true;
      axios
        .post("document/createDocument/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.creationDisabled = true;
            this.$emit("documentCreated", resp.data.Data);
            this.$store.dispatch("docs/updateDoc", resp.data.Data);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.landing-row {
  position: relative;
}

.tab-move-left {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.tab-edit-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  .v-btn {
    margin-left: -5px;
  }
}

.v-tab:hover {
  .tab-move-left, .tab-edit-actions {
    display: block;
  }
} 

::v-deep {
  .v-tabs-items, .v-tabs-bar {
    background-color: transparent !important;
  }
  .v-tab {
    border-bottom: 2px solid rgba(gray, 0.2);
    text-transform: none;
  }
}

.col-edit-actions {
  width: 100%;
  text-align: center;
}

.row-edit-actions {
  position: absolute;
  top: 15px;
  right: 5px;
  display: flex;
  flex-direction: column;
}

.controlcontainer {
  min-height: 100px;
}

.draghandle {
  position: absolute;
  top: 45%;
  left: 10px;
  cursor: move;
}

.tabbed {
  height: 100%;
  h2 {
    font-size: 18px;
  }
}

h2 {
  font-size: 26px;
}

.help-card {
  border-radius: 10px !important;
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
}

.v-avatar {
  .headline {
    font-weight: bold;
  }
  &.red {
    color: #bf012f;
    background-color: rgba(#bf012f, 0.2) !important;
  }
  &.purple {
    color: #6938b9;
    background-color: rgba(#6938b9, 0.2) !important;
  }
  &.orange {
    color: #cf8a38;
    background-color: rgba(#cf8a38, 0.2) !important;
  }
}

.chevron.right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 65px;
  height: 120px;
  border: 1px solid transparent;
  border-radius: 100px;
}
.chevron.right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 60px;
  height: 60px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(-45deg);
  right: 20px;
  top: 30px;
}
.chevron.right.chevron-red::after {
  border-color: rgba(#bf012f, 0.5);
}
.chevron.right.chevron-purple::after {
  border-color: rgba(#6938b9, 0.5);
}

.theme--light .bggradient {
  border-top: 1px solid #eee;
  margin-left: -48px;
  margin-right: -48px;
  padding-left: 48px;
  padding-right: 48px;
  background: linear-gradient(180deg, #ffffff 0%, $background-color-light 100%);
  min-height: 50vh;
}

.theme--light .tabbed {
  background-color: white;
}
</style>