<template>
  <div>
    <v-card style="min-height: 500px" flat class="ma-4">
      <v-tabs vertical>
        <v-tab left v-for="category in categories" :key="category.csc_id">
          <v-icon left>{{ category.icon }} </v-icon>
          {{ category.name }}
        </v-tab>

        <v-tab-item v-for="category in categories" :key="category.csc_id" style="padding: 0px 20px 40px 11px">
          <v-card flat>
            <v-row>
              <v-col v-for="col in category.cols">
                <v-tooltip v-for="setting in col.settings" :key="setting.setting" bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <div class="d-flex justify-space-between align-center">
                      <v-switch v-if="setting.data_type === 'bool'" :label="setting.name" v-model="setting.value">
                      </v-switch>
                      <v-select v-else-if="setting.data_type === 'list' && setting.structure && setting.structure.length"
                        multiple small-chips :label="setting.name" v-model="setting.value" :items="setting.structure"
                        item-value="value" item-text="text">
                      </v-select>
                      <v-combobox v-else-if="setting.data_type === 'list'" :label="setting.name" multiple small-chips
                        v-model="setting.value" :items="setting.value">
                      </v-combobox>
                      <template v-else-if="setting.data_type === 'json' && setting.structure && setting.structure.length">
                        <div class="d-flex flex-column" style="width: 100%">
                          <h3>{{ setting.name }}</h3>
                          <template v-for="field in setting.structure">
                            <v-switch v-if="field.type === 'bool'" :label="field.name"
                              v-model="setting.value[field.name]">
                            </v-switch>
                            <v-select v-else-if="field.type === 'list' && field.values && field.values.length"
                              :label="field.name" multiple small-chips v-model="setting.value[field.name]"
                              :items="field.values" item-value="value" item-text="text">
                            </v-select>
                            <v-combobox v-else-if="field.type === 'list'" :label="field.name" multiple small-chips
                              v-model="setting.value[field.name]" :items="setting.value[field.name]">
                            </v-combobox>
                            <v-select v-else-if="field.values && field.values.length" :label="field.name"
                              v-model="setting.value[field.name]" :items="field.values" item-value="value"
                              item-text="text">
                            </v-select>
                            <v-text-field v-else-if="field.type === 'number'" type="number" :label="field.name"
                              v-model="setting.value[field.name]">
                            </v-text-field>
                            <v-text-field v-else :label="field.name" v-model="setting.value[field.name]">
                            </v-text-field>
                          </template>
                        </div>
                      </template>
                      <v-select v-else-if="setting.structure && setting.structure.length" :label="setting.name"
                        v-model="setting.value" :items="setting.structure" item-value="value" item-text="text">
                      </v-select>
                      <v-text-field v-else :label="setting.name" v-model="setting.value">
                      </v-text-field>
                      <v-icon v-if="setting.description" style="cursor: pointer;" v-on="{ ...tooltip }">info_outline</v-icon>
                    </div>
                  </template>
                  {{ setting.description }}
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="dirtySettings.length === 0">
          <v-btn dense color="primary" disabled>Save</v-btn>
        </template>
        <template v-else>
          <v-btn dense color="primary" @click="revert" outlined>Revert Changes</v-btn>
          <v-btn dense color="primary" @click="saveData">Save ({{ dirtySettings.length }})</v-btn>
        </template>
      </v-card-actions>
    </v-card>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <Loading :isVisible="isLoading" />

    <v-snackbar v-model="showErrorSnack" :timeout="snackTimeout" :color="snackColor" :multi-line="errorText.length > 50"
      top>
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src

export default {
  name: "settingsAdmin",
  data: function () {
    return {
      isLoading: false,
      response: null,
      categories: [],
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
    };
  },
  components: {
    ResponseHandler,
  },
  watch: {},
  computed: {
    dirtySettings() {
      const settings = [];
      this.categories.forEach(cat => {
        cat.cols.forEach(col => {
          col.settings.forEach(s => {
            if (JSON.stringify(s.value) !== s.orig_value) {
              settings.push(s);
            }
          })
        })
      })
      return settings;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let possibleError = false;
      axios
        .get("admin/settings/")
        .then((resp) => {
          possibleError = true;
          const categories = resp.data.Data;
          categories.forEach(cat => {
            cat.cols.forEach(col => {
              col.settings.forEach(s => {
                s.orig_value = JSON.stringify(s.value);
              })
            })
          })

          this.categories = categories;
          this.isLoading = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    revert() {
      this.dirtySettings.forEach(s => {
        s.value = JSON.parse(s.orig_value);
      })
    },
    saveData() {
      const data = this.dirtySettings.map(s => {
        return {
          setting: s.setting,
          value: s.value
        }
      })
      this.isLoading = true;
      let possibleError = false;
      axios
        .post("admin/settings/", data)
        .then((resp) => {
          this.isLoading = false;

          if (resp.data.Status == "OK") {
            this.triggerNotification("Settings Saved Successfully", "success");
            this.dirtySettings.forEach(s => {
              s.orig_value = JSON.stringify(s.value);
            })
          } else {
            this.triggerNotification(resp.data.Message, "error");
          }
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-tabs-slider-wrapper {
  left: auto !important;
  right: 0;
}
</style>