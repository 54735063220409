var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    displayBorder: _vm.displayBorder,
    displayTitle: _vm.displayTitle,
    selectable: _vm.node.drilldown || _vm.allowExpand,
  },attrs:{"title":_vm.node.name},on:{"click":function($event){_vm.node.drilldown ? _vm.doAction('drilldown') : null},"contextmenu":function($event){return _vm.openContextMenu($event)}}},[_c('div',{class:{
      overflow: _vm.displayBorder || _vm.displayTitle,
    }},[(!_vm.displayTitle && !_vm.hideDrilldown && _vm.node.drilldown)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-triple-down ")])]}}],null,false,2598491940)},[_c('span',[_vm._v("Drilldown to "+_vm._s(_vm.node.nextLevel)+" for: "+_vm._s(_vm.node.name))])]):_vm._e(),(_vm.allowExpand)?_c('v-icon',[_vm._v(_vm._s(_vm.node.show ? "mdi-chevron-up" : "mdi-chevron-down"))]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical-circle-outline ")])]}}])},[_c('v-list',_vm._l((_vm.getActions()),function(a,ai){return _c('v-list-item',{key:'am' + ai,on:{"click":function($event){return _vm.doAction(a.type, a)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":a.colour ? a.colour : ''}},[_vm._v(_vm._s(a.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(a.title))])],1)}),1)],1),(!_vm.hideDescriptor && _vm.node.descriptor)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":""},on:{"click":function($event){$event.stopPropagation();return _vm.doAction('editHierarchyDescriptor')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,2510664253)},[_c('span',[_vm._v("Description: "+_vm._s(_vm.node.descriptor.description))])]):_vm._e(),(
        !_vm.hideReview &&
        _vm.node.reviewStatus &&
        _vm.node.reviewStatus.docsAsParent_open &&
        _vm.node.reviewStatus.docsAsParent_open.length
      )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":_vm.node.reviewStatus.hasDataAsParent ? 'orange' : 'blue'},on:{"click":function($event){$event.stopPropagation();return _vm.doAction('showReviewParent')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-settings-outline ")])]}}],null,false,3630280954)},[_c('span',[_vm._v("Has "+_vm._s(_vm.node.nextLevel)+"s currently "+_vm._s(_vm.node.reviewStatus.docsAsParent_open[0].status + (_vm.node.reviewStatus.hasDataAsParent ? " (has feedback)" : " (no feedback)")))])]):_vm._e(),(
        !_vm.hideReview && _vm.node.reviewStatus && _vm.node.reviewStatus.docs_open.length
      )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":_vm.node.reviewStatus.hasData ? 'orange' : 'blue'},on:{"click":function($event){$event.stopPropagation();return _vm.doAction('showReview')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-settings ")])]}}],null,false,2860331397)},[_c('span',[_vm._v("Currently "+_vm._s(_vm.node.reviewStatus.docs_open[0].status + (_vm.node.reviewStatus.hasData ? " (has feedback)" : " (no feedback)")))])]):_vm._e(),_vm._v(" "+_vm._s(_vm.node.name)+" ")],1),_c('v-menu',{attrs:{"absolute":"","position-x":_vm.contextMenu.posX,"position-y":_vm.contextMenu.posY,"offset-y":"","close-on-content-click":false,"nudge-width":"300"},model:{value:(_vm.contextMenu.show),callback:function ($$v) {_vm.$set(_vm.contextMenu, "show", $$v)},expression:"contextMenu.show"}},[_c('v-card',[(_vm.contextMenu.busy)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50","color":"grey"}})],1),_c('v-col',{attrs:{"cols":"5"}})],1)],1):_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_c('div',{staticClass:"menuSubject"},[_vm._v(" "+_vm._s(_vm.contextMenu.title)+" ")])])],1),_vm._l((_vm.contextMenu.actions),function(a,ai){return _c('v-list-item',{key:'cma' + ai,on:{"click":function($event){return _vm.doContextMenuAction(a)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":a.colour || 'gray'}},[_vm._v(_vm._s(a.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(a.title))])],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }