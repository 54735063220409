<template>
  <div>
    <v-container class="d-flex justify-center">
      
    </v-container>
    <v-container class="d-flex justify-center">
      <div style="width: 1000px">
        <v-row class="mx-2 mb-4">
          <h1 class="title" >Hierarchy Configuration</h1>
          <v-spacer></v-spacer>
          <v-btn icon title="List View" @click="loadHierarchyView('list')"><v-icon>list</v-icon></v-btn>
          <v-btn icon title="Tree View" v-if="hierarchy && hierarchy.name === 'Job'" @click="loadHierarchyView('tree')"><v-icon>account_tree</v-icon></v-btn>
        </v-row>
        
        <v-card class="pa-6">
          <v-card-text>
            <v-row>
              <v-select
                v-model="hierarchy"
                :items="hierarchies"
                label="Hierarchy Type"
                item-text="name"
                return-object
                outlined
                class="roundish"
                dense
              >
              </v-select>
            </v-row>
            <template v-if="hierarchy && hierarchy.levels">
              <v-row class="pb-2">
                <v-col>
                  <v-text-field
                    v-model="hierarchy.label"
                    label="Label"
                    outlined
                    dense
                    class="roundish"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="hierarchy.codeName"
                    label="Code Name"
                    outlined
                    dense
                    class="roundish"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-for="l in visibleLevels" :key="`h${l.level}`" class="pb-2">
                <v-col :class="`d-flex align-center ${l.active ? '' : 'disabled'}`">
                  <v-text-field
                    v-model="l.name"
                    :label="`Level ${l.level} Label`"
                    outlined
                    dense
                    class="roundish"
                    :disabled="!l.active"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" :class="` ${l.active ? '' : 'disabled'}`">
                  <v-checkbox
                    v-model="l.useInDesc"
                    :disabled="!l.active"
                    :label="`Include in title`"
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-model="l.hasDescriptor"
                    :disabled="!l.active"
                    :label="`Can have description`"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="1" class="d-flex align-center">
                  <v-btn icon color="primary" @click="toggleLevel(l)">
                    <v-icon v-if="l.active">remove_circle</v-icon>
                    <v-icon v-else>add_circle_outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              outlined
              @click="addLevel"
              v-if="hierarchy && hierarchy.maxEnabledLevel < 6"
            >
              <v-icon left>add</v-icon> Add Level</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="fetchData" outlined>Revert Changes</v-btn>
            <v-btn color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </div>

      <Loading :isVisible="isLoading" />
      <ResponseHandler :serviceResponse="response"></ResponseHandler>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src

export default {
  name: "HierarchyConfig",
  data: function () {
    return {
      response: null,
      hierarchies: null,
      hierarchy: null,
      isLoading: false,
    };
  },
  components: {
    ResponseHandler,
  },
  watch: {},
  computed: {
    visibleLevels() {
      if (!this.hierarchy) return null;

      return this.hierarchy.levels.filter(
        (l) => l.level <= this.hierarchy.maxEnabledLevel
      );
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("hierarchy/structure")
        .then((resp) => {
          this.isLoading = false;
          const data = resp.data.Data;
          this.hierarchies = data;
          if (this.hierarchies.length) {
            const currHierarchy = this.hierarchy?.name;
            this.hierarchy = this.hierarchies.find(h => h.name === currHierarchy) || this.hierarchies[0];
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    addLevel() {
      const h = this.hierarchy;
      if (h.maxEnabledLevel < 6) {
        const newMaxLevel = h.maxEnabledLevel + 1;
        h.levels.find((l) => l.level === newMaxLevel).active = true;
        h.maxEnabledLevel = newMaxLevel;
      }
    },
    toggleLevel(l) {
      const h = this.hierarchy;
      l.active = !l.active;
      if (!l.active && l.level === h.maxEnabledLevel) {
        h.maxEnabledLevel = h.maxEnabledLevel - 1;
      }
    },
    save() {
      const data = this.hierarchy;
      axios
        .post("hierarchy/savestructure", data)
        .then((resp) => {
          this.isLoading = false;
          const data = resp.data.Data;
          this.hierarchies = data;
          if (this.hierarchies.length) {
            const currHierarchy = this.hierarchy?.name;
            this.hierarchy = this.hierarchies.find(h => h.name === currHierarchy) || this.hierarchies[0];
            this.response = { Status: "OK", Message: "Saved successfully" };
            this.$store.dispatch("hierarchies/refresh");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    loadHierarchyView(view) {
      switch (view) {
        case "tree":
          this.$passedParams.set({ view: "Tree View"});
          break;
        case "list":
        default:
          switch (this.hierarchy.name) {
            case "Geography":
              this.$passedParams.set({ view: "Location List View"});
              break;
            case "Business Org":
              this.$passedParams.set({ view: "Organisation List View"});
              break;
            case "Job":
            default:
              this.$passedParams.set({ view: "Job Family List View"});
              break;
          }
          break;
      }
      this.$router.push( { name: "hierarchyview" });
    }
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
}
</style>