<template>
  <div class="hryContainer">
    <v-expansion-panels v-if="newUi" accordion flat v-model="expanded">
      <v-expansion-panel v-for="(c, ic) in classifiers" :key="'c' + ic">
        <v-expansion-panel-header>{{ c.ct_name }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-treeview
            :ref="'ctv' + ic"
            dense
            selectable
            return-object
            item-key="cv_id"
            open-on-click
            :items="c.items"
            v-model="c.selected"
            :class="['treeview' + ic, 'newUi']"
            @input="doFilter"
          >
            <template v-slot:label="{ item }">
              <span :title="item.value">
                {{ item.value }} ( {{ item.count }} )
              </span>
            </template>
          </v-treeview>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="(hry, ih) in hierarchies" :key="'h' + ih">
        <v-expansion-panel-header>{{ hry.label }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            style="margin-right: 15px"
            dense
            clearable
            label="Filter"
            :value="hry.filterText"
            @keypress.enter="triggerTreeFilter(ih)"
            @input="(v) => changeTreeFilterText(hry, v)"
            @click:clear="clearTreeFilterText(ih)"
            append-outer-icon="mdi-filter-remove"
            :class="'filter' + ih"
            @click:append-outer="clearTreeFilters(ih)"
          ></v-text-field>
          <v-treeview
            :ref="'tv' + ih"
            dense
            selectable
            return-object
            item-key="id"
            open-on-click
            :items="hry.items"
            :open="hry.open"
            v-model="hry.selected"
            :search="hry.filterText"
            :filter="filterHryItems"
            :class="['treeview' + ih, 'newUi']"
            @input="doFilter"
          >
            <template v-slot:label="{ item }">
              <span :title="item.name" v-html="item.name">
              </span>
            </template>
          </v-treeview>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <div v-for="(hry, ih) in hierarchies" :key="'h' + ih">
        <v-text-field
          style="max-width: 95%; margin-top: 5px"
          dense
          clearable
          :label="hry.label + ' filter'"
          :value="hry.filterText"
          @keypress.enter="triggerTreeFilter(ih)"
          @input="(v) => changeTreeFilterText(hry, v)"
          @click:clear="clearTreeFilterText(ih)"
          append-outer-icon="mdi-filter-remove"
          :class="'filter' + ih"
          @click:append-outer="clearTreeFilters(ih)"
        ></v-text-field>
        <v-treeview
          :ref="'tv' + ih"
          dense
          selectable
          return-object
          item-key="id"
          open-on-click
          :items="hry.items"
          :open="hry.open"
          v-model="hry.selected"
          :search="hry.filterText"
          :class="['treeview' + ih, 'oldUi']"
          @input="doFilter"
        >
          <template v-slot:label="{ item }" >
            <span :title="item.name" v-html="item.name">
            </span>
          </template>
        </v-treeview>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/common/utils.js";
import { mapState } from "vuex";

export default {
  name: "HierarchySearch",
  components: {},
  props: {
    items: null,
    preSelected: null,
    preSelectedClassifiers: null,
    hierarchyTypes: null,
    classifierTypes: null,
  },
  data: function () {
    return {
      hierarchies: [],
      classifiers: [],
      expanded: 0,
      selectionChanged: false,
      refreshing: false,
    };
  },
  created() {
    this.buildClassifiers();
    this.buildHierarchies();
  },
  watch: {
    items() {
      this.buildClassifiers();
      this.buildHierarchies();
    },
    preSelected() {
      this.buildClassifiers();
      this.buildHierarchies();
    },
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
    }),
  },
  methods: {
    buildClassifiers() {
      if (!this.items || !this.classifierTypes || !this.classifierTypes.length)
        return;

      if (!this.classifiers.length && this.classifierTypes) {
        this.classifiers = this.classifierTypes.map((ct) => {
          return {
            ct_id: ct.ct_id,
            ct_name: ct.ct_name,
            items: [],
            selected: [],
          };
        });
      }

      const preSelectedClassifiers = [];

      this.classifiers.forEach((c) => {
        if (this.preSelectedClassifiers) {
          const preSelectedProp = this.preSelectedClassifiers.find(
            (s) => s.ct_id === c.ct_id
          );
          preSelectedClassifiers.push(
            preSelectedProp
              ? JSON.parse(JSON.stringify(preSelectedProp.selected))
              : []
          );
        } else {
          preSelectedClassifiers.push(JSON.parse(JSON.stringify(c.selected)));
        }
        c.items.splice(0);
        c.selected.splice(0);
      });

      this.items.forEach((d) => {
        this.classifiers.forEach((c) => {
          let docc = d.classifiers.find((dc) => dc.ct_id === c.ct_id);
          this.decorateClassifier(c.items, docc);
        });
      });

      this.classifiers.forEach((c, i) => {
        this.sortHierarchy(c.items);
        c.selected = preSelectedClassifiers[i];
      });
    },
    buildHierarchies() {
      if (!this.items || !this.hierarchyTypes || !this.hierarchyTypes.length)
        return;

      let preSelected = [];

      if (!this.hierarchies.length) {
        this.hierarchies = this.hierarchyTypes.map((h) => {
          let levels = Array(
            h.use_h1 + h.use_h2 + h.use_h3 + h.use_h4 + h.use_h5 + h.use_h6
          ).fill(0);
          return {
            ht_id: h.ht_id,
            label: h.label,
            linklevel: h.linklevel,
            cols: levels.map((x, i) => "hierarchy" + (levels.length - i)),
            items: [],
            open: [],
            selected: [],
            filterText: "",
            tempText: "",
          };
        });
      }
      this.hierarchies.forEach((h) => {
        if (this.preSelected) {
          const preSelectedProp = this.preSelected.find(
            (s) => s.ht_id === h.ht_id
          );
          preSelected.push(
            preSelectedProp
              ? JSON.parse(JSON.stringify(preSelectedProp.selected))
              : []
          );
        } else {
          //   preSelected.push(JSON.parse(JSON.stringify(h.selected)));
          preSelected.push(h.selected.map((s) => {
            return {
              id: s.id,
              name: s.name,
              value: s.value,
              count: s.count,
            };
          }));
        }
        this.clearHierarchy(h.items);
        this.clearHierarchy(h.selected);
        this.clearHierarchy(h.open);
      });

      this.items.forEach((d) => {
        this.hierarchies.forEach((h) => {
          let doch = d.hierarchies.find((dh) => dh.ht_id === h.ht_id);
          if (doch) this.decorateHierarchy(h.items, h.cols, doch);
        });
      });

      this.hierarchies.forEach((h) => {
        this.sortHierarchy(h.items);
      });
      this.tvIgnoreInputEvent = true;
      this.hierarchies.forEach((h, i) => {
        h.selected = preSelected[i];
      });

      const firstSelectedHierarchyIdx = this.hierarchies.findIndex(
        (h) => h.selected.length
      );
      if (
        this.hierarchies[this.expanded].selected.length == 0 &&
        firstSelectedHierarchyIdx > -1
      ) {
        this.expanded = this.classifiers.length + firstSelectedHierarchyIdx;
      }

      this.refreshing = this.selectionChanged;
      this.selectionChanged = false;
    },
    clearHierarchy(items) {
      items.forEach((c) => {
        if (c.children && c.children.length) {
          this.clearHierarchy(c.children);
        }
        delete c.children;
      });
      items.length = 0;
    },
    decorateHierarchy(topitem, levels, item) {
      let current = topitem;
      let parent;
      levels.forEach((l, i) => {
        let value = utils.removeTags(item[levels[i]] || "UNCLASSIFIED").trim(),
          h = current.find((l) => l.value === value),
          includeChildren = i < levels.length - 1;
        // if (parent && value === parent.value && includeChildren) {
        //   h = parent;
        // } else {
          if (!h) {
            h = {
              id: item.hr_id + (includeChildren ? "_" + i : ""),
              name: "",
              count: 0,
              value: value,
              parent: parent,
            };
            if (includeChildren) h.children = [];
            current.push(h);
          }
          h.count++;
          h.name = h.value + " (" + h.count + ")";
          current = h.children;
          parent = h;
        // }
      });
    },
    decorateClassifier(topitem, item) {
      const value = (item?.value || "UNCLASSIFIED").trim();
      const cv_id = item?.cv_id || 0;
      const c = topitem.find((v) => v.cv_id === cv_id);
      if (c) {
        c.count++;
      } else {
        topitem.push({ cv_id: cv_id, value: value, count: 1 });
      }
    },
    sortHierarchy(h) {
      if (h && h.length > 0) {
        h.sort((a, b) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0));
        h.forEach((c) => {
          if (c.children) this.sortHierarchy(c.children);
        });
      }
    },
    changeTreeFilterText(treeview, val) {
      treeview.tempText = val;
    },
    clearTreeFilters(index) {
      this.clearTreeFilterText(index);
      this.clearHierarchy(this.hierarchies[index].selected);
      this.ignoreTVInputEvent = true;
      this.doFilter();
    },
    clearTreeFilterText(index) {
      let tv = this.hierarchies[index];
      tv.tempText = "";
      tv.filterText = "";
      if (tv.selected.length === 0) {
        // const ref = this.$refs["tv" + index];
        // if (ref && ref.length) ref[0].updateAll(false);
        tv.open.splice(0);
        this.doFilter();
      }
    },
    triggerTreeFilter: function (index) {
      this.hierarchies[index].filterText = this.hierarchies[index].tempText;
      // const ref = this.$refs["tv" + index];
      // if (ref && ref.length) ref[0].updateAll(true);
      this.doFilter();
    },
    filterHryItems(item, search, textKey) {
      const match =
        item[textKey].toLowerCase().indexOf(search.toLowerCase()) > -1;
      if (match) {
        const tv =
          this.hierarchies[this.expanded - this.classifierTypes.length];
        if (tv) {
          let parent = item;
          while (parent !== undefined) {
            tv.open.push(parent);
            parent = parent.parent;
          }
        }
      }
      return match;
    },
    doFilter: function () {
      //   if (this.refreshing) {
      //     this.refreshing = false;
      //     this.selectionChanged = false;
      //     return;
      //   }
      //   this.selectionChanged = true;
      const hierarchies = JSON.parse(
        JSON.stringify(this.hierarchies, (key, value) =>
          key === "parent" ? undefined : value
        )
      );
      const classifiers = JSON.parse(JSON.stringify(this.classifiers));
      this.$emit("filterChanged", hierarchies, classifiers);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-treeview.oldUi {
  div.v-treeview-node__label {
    font-size: 12px;
  }
  .v-treeview-node__toggle {
    max-width: 10px !important;
  }
  .v-treeview-node__root {
    padding: 0 !important;
    min-height: 25px !important;
  }
  .v-input__icon--append-outer .v-icon {
    color: grey;
    font-size: 18px;
  }
}

::v-deep .v-expansion-panels {
  padding-right: 1px;
  .v-expansion-panel {
    background-color: transparent !important;
  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 45px;
  }
  .v-expansion-panel-header {
    padding: 0 15px;
  }
  .v-expansion-panel-content__wrap {
    padding-right: 0;
  }
}

::v-deep .v-treeview.newUi {
  .v-treeview-node__toggle {
    position: absolute;
    right: 22px;
    &::before {
      content: "\F0140"; //mdi-chevron-down
    }
  }
  .v-treeview-node__checkbox {
    margin-left: 0;
  }
  .v-treeview-node__root {
    align-items: center;
  }
  .v-treeview-node__label {
    padding-right: 40px;
    padding-top: 0.1rem;
    font-size: 0.875rem;
  }

  .v-treeview-node__children {
    margin-left: -15px;
    padding-left: 15px;
  }

  &.theme--light {
    > .v-treeview-node {
      > .v-treeview-node__children {
        background-color: #eef5ff;
        > .v-treeview-node > .v-treeview-node__children {
          background-color: #d5e5fb;
          > .v-treeview-node > .v-treeview-node__children {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
