<template>
  <v-container fluid>
    <div v-if="templateParts">
      <v-row>
        <v-col>
          <v-card v-if="templateParts && templateParts.length" class="template">
            <v-card-text>
              <Draggable
                v-model="templateParts"
                handle=".draghandle"
                @change="saveOrder"
              >
                <v-row
                  v-for="part in templateParts"
                  :key="part.tp_id"
                  :class="{ inactive: !part.tp_active }"
                >
                  <v-col class="tp-name">
                    <v-chip small class="draghandle" :title="part.tp_name" can>
                      <!-- <v-icon>drag_indicator</v-icon> -->
                      {{ part.tp_name }}
                    </v-chip>
                    <div class="icons">
                      <v-btn
                        icon
                        x-small
                        :class="{ 'no-content': !part.why_text }"
                        @click="configureInsights(part)"
                      >
                        <v-icon>mdi-lightbulb-outline</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        x-small
                        :class="{ 'no-content': !part.how_to_text }"
                        @click="configureHelp(part)"
                      >
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    :class="[
                      'tp-body',
                      part.format,
                      part.otherLayoutFlags.join(' '),
                      part.parent_tp_id ? 'isChild' : '',
                    ]"
                    
                  >
                    <v-row>
                      <v-col v-if="part.showLabel" cols="4" class="inner-gutter" @click="configurePart(part)">
                        {{ part.tp_name }}
                      </v-col>
                      <v-col>
                        <template v-if="part.attributes && part.attributes.filter(a => a.active).length !== 0">
                          <v-row>
                            <v-col v-for="(attr, ai) in part.attributes.filter(a => a.active)"
                              :key="'a' + ai" :cols="attr.cols"
                              @click="configurePart(part, attr)"
                              :class="attr.css_class_name">
                              <div
                                v-if="attr.default_text"
                                v-html="attr.default_text.replaceAll('\n', '<br />')"
                              ></div>
                              <div
                                v-else
                                class="placeholder"
                                v-html="attr.placeholder"
                              ></div>    
                            </v-col>
                          </v-row>
                        </template>
                        <div v-else @click="configurePart(part)" style="min-height: 20px"> 
                          <div
                            v-if="part.default_text"
                            v-html="part.default_text.replaceAll('\n', '<br />')"
                          ></div>
                          <div
                            v-else
                            class="placeholder"
                            v-html="part.placeholder"
                          ></div>
                        </div>
                        <div @click="configurePart(part)" class="tp-body-right">
                          <v-chip
                            dense
                            hide-details
                            disabled
                            v-if="part.uses_essential_flag"
                            outlined
                            class="mr-6"
                          >
                            {{ part.uses_essential_flag === 2 ? 'Essential' : 'Desirable' }}
                          </v-chip>
                          <v-icon v-if="part.tp_locked"> mdi-lock </v-icon>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="tp-actions"> </v-col>
                </v-row>
              </Draggable>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="actions">
          <v-btn class="mb-3" color="secondary" outlined @click="configuteTemplate"
            ><v-icon left>mdi-cog</v-icon> Settings</v-btn
          >
          <v-btn class="mb-3" color="secondary" outlined v-if="templateHeader && templateHeader.tmpl_id" @click="uploadTemplate"
            ><v-icon left>mdi-upload</v-icon> Upload</v-btn
          >
          <v-btn class="mb-3" color="secondary" outlined v-if="templateHeader && templateHeader.tmpl_id" @click="downloadTemplate"
            ><v-icon left>mdi-download</v-icon> Download</v-btn
          >
          <v-btn class="mb-3" color="secondary" outlined v-if="templateHeader && templateHeader.tmpl_id" @click="addPart"
            ><v-icon left>mdi-plus</v-icon>Add Part</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="showEditTemplate" max-width="1000px">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <v-tabs v-model="headerTab">
                <v-tab>Settings</v-tab>
                <v-tab>How to Text</v-tab>
                <v-tab>Insights</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-btn icon @click="showEditTemplate = false"
                ><v-icon>close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="templateHeader">
          <v-tabs-items v-model="headerTab" style="min-height: 550px">
            <v-tab-item>
              <v-row>
                <v-col cols="7">
                  <v-switch
                    label="Active"
                    v-model="templateHeader.tmpl_active">
                  </v-switch>
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        label="Name"
                        v-model="templateHeader.tmpl_name"
                        :error="!templateHeader.tmpl_name">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        label="Abbreviation"
                        v-model="templateHeader.abbreviation"
                        :error="!templateHeader.abbreviation">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- <v-col cols="4">
                      <v-select
                        label="Show Scoring"
                        v-model="templateHeader.show_scoring"
                        :items="showScoringOptions"
                        item-text="text"
                        item-value="value">
                      </v-select>
                    </v-col> -->
                    <v-col cols="8">
                      <v-text-field
                        label="Score Title"
                        v-model="templateHeader.score_title">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-switch
                    label="Derived from Flex"
                    v-model="templateHeader.derived_from_flex">
                  </v-switch>
                  <v-switch
                    label="Flex Fixed Results"
                    v-model="templateHeader.flex_fixed_results">
                  </v-switch>
                  <v-switch
                    label="Show in AllJobs"
                    v-model="templateHeader.show_in_alljobs">
                  </v-switch>
                  <v-switch
                    label="Show in Create Dialog"
                    v-model="templateHeader.show_in_create_dialog">
                  </v-switch>
                  <v-switch
                    label="Hierarchy Editor Restrict"
                    v-model="templateHeader.hierarchy_editor_restrict">
                  </v-switch>
                  <v-switch
                    label="Use Essential DDL rather than checkbox"
                    v-model="templateHeader.use_temp_essentials">
                  </v-switch>
                  <v-switch
                    label="Use Database View"
                    v-model="templateHeader.use_database_view">
                  </v-switch>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-textarea
                rows="10"
                outlined
                auto-grow
                v-model="templateHeader.how_to_text"
              ></v-textarea>
            </v-tab-item>
            <v-tab-item>
              <v-textarea
                rows="10"
                outlined
                auto-grow
                v-model="templateHeader.why_text"
              ></v-textarea>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-6 pb-4">
          <v-btn color="primary" @click="save" :disabled="saveTemplateDisabled">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditPart" max-width="1200px">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <v-tabs v-model="partTab">
                <v-tab>Settings</v-tab>
                <v-tab>Attributes</v-tab>
                <v-tab v-if="templatePart && templatePart.attributes.length === 0">Overrides</v-tab>
                <v-tab>Restrictions</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-btn icon @click="showEditPart = false"
                ><v-icon>close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="templatePart">
          <v-tabs-items v-model="partTab" style="min-height: 450px">
            <v-tab-item>
              <v-row>
                <v-col>
                  <v-switch class="ml-1" label="Active" v-model="templatePart.tp_active">
                  </v-switch>
                  <v-switch class="ml-1" label="Locked" v-model="templatePart.tp_locked">
                  </v-switch>
                  <v-combobox
                    label="Type"
                    v-model="templatePart.partType"
                    :items="partTypes"
                    item-text="pt_name"
                    return-object
                    @change="setPtName"
                    :error="!templatePart.partType"
                  >
                  </v-combobox>
                  <v-text-field
                    label="Name"
                    v-model="templatePart.tp_name"
                    :error="!templatePart.tp_name"
                  ></v-text-field>
                  <v-select
                    label="Parent Part"
                    v-model="templatePart.parent_tp_id"
                    :items="templateParts"
                    @change="setChildOrder"
                    item-text="tp_name"
                    item-value="tp_id"
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    label="Uses Essential"
                    v-model="templatePart.uses_essential_flag"
                    :items="essentialOpts"
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                  <v-combobox
                    label="Formatting"
                    v-model="templatePart.format"
                    :items="templateHeader.formattingOpts"
                    item-text="text"
                    item-value="value"
                    :return-object="false"
                  >
                  </v-combobox>
                  <v-combobox
                    label="Export Section"
                    v-model="templatePart.html_export_section"
                    :items="templateHeader.exportSectionOpts"
                    item-text="text"
                    item-value="value"
                    :return-object="false"
                  >
                  </v-combobox>
                  <v-combobox
                    label="Export Format"
                    v-model="templatePart.html_export_tag"
                    :items="templateHeader.exportFormattingOpts"
                    item-text="text"
                    item-value="value"
                    :return-object="false"
                  >
                  </v-combobox>
                </v-col>
                <v-col>
                  <v-switch label="Show Label" v-model="templatePart.showLabel">
                  </v-switch>
                  <v-switch
                    label="Restrict to Lookup"
                    v-model="templatePart.restrict_to_lookup"
                  >
                  </v-switch>
                  <v-switch
                    label="Allow Edit Dialog"
                    v-model="templatePart.allow_edit_dialog"
                  >
                  </v-switch>
                  <v-switch
                    label="Suggestion Part"
                    v-model="templatePart.suggestion_part"
                  >
                  </v-switch>
                  <v-switch
                    label="Hide Approved Content"
                    v-model="templatePart.hide_approved_content"
                  >
                  </v-switch>
                  <v-switch
                    label="Hide Generic Content"
                    v-model="templatePart.hide_generic_content"
                  >
                  </v-switch>
                  <v-switch
                    label="Use Default for Link Copy"
                    v-model="templatePart.use_default_for_link_copy"
                  >
                  </v-switch>
                  <v-switch
                    label="Hide Add / Delete"
                    v-model="templatePart.hide_add_delete"
                  >
                  </v-switch>
                </v-col>
              </v-row>

              

              <v-textarea
                label="Placeholder"
                rows="2"
                auto-grow
                v-model="templatePart.placeholder"
              ></v-textarea>

              <v-select label="Auto Fill Type"
                :items="partDefaultValueTypes"
                item-text="text"
                item-value="value"
                v-model="templatePart.default_value_type">
              </v-select>
              <v-textarea
                label="Default Text"
                rows="2"
                v-if="templatePart.default_value_type === 'static'"
                auto-grow
                v-model="templatePart.default_text"
              ></v-textarea>
              <template v-else-if="templatePart.default_value_type === 'hierarchy'">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      label="Hierarchy Override"
                      v-model="templatePart.hr_override_text"
                      :items="hierarchies"
                      item-text="label"
                      item-value="ht_id"
                      hide-details
                      clearable>
                    </v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      type="number"
                      label="Level"
                      v-model="templatePart.hr_override_level"
                      hide-details>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-row class="mt-4">
                      <v-switch class="ml-1" label="Apply on Insert"
                        v-model="templatePart.hr_override_apply_on_insert">
                      </v-switch>
                      <v-switch class="ml-1" label="Apply on Update"
                        v-model="templatePart.hr_override_apply_on_update">
                      </v-switch>
                      <v-switch class="ml-1" label="Sync with hierarchy"
                        title="Use hierarchy picker and update hierarchy when attribute is updated"
                        v-model="templatePart.hr_override_sync">
                      </v-switch>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>
            <v-tab-item>
              <v-expansion-panels accordion v-model="attrPanel" class="attributes-list py-2">
                <Draggable
                  v-model="templatePart.attributes"
                  draghandle=".draghandle"
                  >
                  <v-expansion-panel v-for="(attr, ai) in templatePart.attributes" :key="'a' + ai" style="width: 1150px">
                    <v-expansion-panel-header :class="{ 'attribute': true, 'inactive': !attr.active }">
                      <v-row>
                        <v-col cols="1" class="d-flex align-center">
                          <v-icon class="draghandle">mdi-drag</v-icon>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <v-list-item-title>{{ attr.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ attr.default_text || attr.placeholder }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-center">
                          {{ attr.cols }} cols
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="2">
                          <v-tabs vertical v-model="attrTab">
                            <v-tab>Settings</v-tab>
                            <v-tab>Overrides</v-tab>
                            <v-tab>Checks</v-tab>
                          </v-tabs>
                        </v-col>
                        <v-col>
                          <v-tabs-items vertical v-model="attrTab">
                            <v-tab-item>
                              <v-row>
                                <v-col>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-switch class="ml-1" label="Active"
                                        v-model="attr.active">
                                      </v-switch>
                                      <v-switch class="ml-1" label="Locked" v-model="attr.locked">
                                      </v-switch>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        type="Number"
                                        label="Cols"
                                        v-model="attr.cols">
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-text-field
                                    label="Placeholder"
                                    v-model="attr.placeholder">
                                  </v-text-field>
                                  <v-text-field
                                    label="CSS Class"
                                    v-model="attr.css_class_name">
                                  </v-text-field>
                                  <v-row>
                                    <v-col>
                                      <v-text-field
                                        label="Prefix"
                                        v-model="attr.prefix">
                                      </v-text-field>
                                    </v-col>
                                    <v-col>
                                      <v-text-field
                                        label="Suffix"
                                        v-model="attr.suffix">
                                      </v-text-field>
                                    </v-col>
                                    <v-col>
                                      <v-text-field
                                        label="Separator"
                                        v-model="attr.separator">
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    label="Title"
                                    v-model="attr.title">
                                  </v-text-field>
                                  <v-select label="Auto Fill Type"
                                    :items="attrDefaultValueTypes"
                                    item-text="text"
                                    item-value="value"
                                    v-model="attr.default_value_type">
                                  </v-select>
                                  <v-text-field
                                    label="Default Text"
                                    v-if="attr.default_value_type === 'static'"
                                    v-model="attr.default_text">
                                  </v-text-field>
                                  <template v-else-if="attr.default_value_type === 'hierarchy'">
                                    <v-row>
                                      <v-col>
                                        <v-select
                                          label="Hierarchy Override"
                                          v-model="attr.hr_override_text"
                                          :items="hierarchies"
                                          item-text="label"
                                          item-value="ht_id"
                                          hide-details
                                          clearable>
                                        </v-select>
                                      </v-col>
                                      <v-col>
                                        <v-text-field
                                          type="number"
                                          label="Level"
                                          v-model="attr.hr_override_level"
                                          hide-details>
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-switch class="ml-1" label="Apply on Insert"
                                        v-model="attr.hr_override_apply_on_insert">
                                      </v-switch>
                                      <v-switch class="ml-1" label="Apply on Update"
                                        v-model="attr.hr_override_apply_on_update">
                                      </v-switch>
                                      <v-switch class="ml-1" label="Sync with hierarchy"
                                        title="Use hierarchy picker and update hierarchy when attribute is updated"
                                        v-model="attr.hr_override_sync">
                                      </v-switch>
                                    </v-row>
                                  </template>
                                  <v-row v-else-if="attr.default_value_type === 'workflow'">
                                    <v-col>
                                      <v-select
                                        label="Activity Type"
                                        v-model="attr.workflow_activity_type"
                                        :items="workflowActivityTypes"
                                        item-text="name"
                                        item-value="name">
                                      </v-select>
                                    </v-col>
                                    <v-col>
                                      <v-select
                                        label="Action Value"
                                        v-model="attr.workflow_activity_attr"
                                        :items="workflowActivityAttrs"
                                        item-text="text"
                                        item-value="value">
                                      </v-select>
                                    </v-col>
                                  </v-row>
                                  <v-row v-else-if="attr.default_value_type === 'data_capture'">
                                    <v-col>
                                      <v-select
                                        label="Data Capture Type"
                                        v-model="attr.data_capture_type_id"
                                        :items="dataCaptureTypes"
                                        item-text="name"
                                        item-value="id">
                                      </v-select>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col>
                                  <v-switch label="Required" v-model="attr.required">
                                  </v-switch>
                                  <v-switch label="Allow Adding" v-model="attr.allow_adding">
                                  </v-switch>
                                  <v-switch label="Include in Part Val" v-model="attr.include_in_part_val">
                                  </v-switch>
                                  <v-switch label="Include in Link Copy" v-model="attr.include_in_link_copy">
                                  </v-switch>
                                  <v-switch label="Restrict to Lookup"
                                    v-model="attr.restrict_to_lookup">
                                  </v-switch>
                                  <v-row dense v-if="attr.restrict_to_lookup" style="padding-top: 15px;">
                                    <v-col cols="5" dense>
                                      <v-select
                                        label="Lookup Type"
                                        dense
                                        v-model="attr.lookup_type"
                                        :items="lookupTypes"
                                        item-text="name"
                                        item-value="lookup_type_id">
                                      </v-select>
                                    </v-col>
                                    <v-col cols="5" dense>
                                      <v-text-field
                                        dense
                                        label="Lookup Key"
                                        v-model="attr.lookup_key">
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="2" dense>
                                      <v-btn small icon @click="openLookupAdmin" color="primary" title="Edit Lookup Values">
                                        <v-icon>mdi-cog</v-icon>
                                      </v-btn>    
                                    </v-col>
                                    <v-col cols="6">
                                      <v-switch
                                        dense
                                        label="Multi Select?"
                                        v-model="attr.multi_select">
                                      </v-switch>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        dense
                                        v-if="attr.multi_select"
                                        label="Separator"
                                        v-model="attr.multi_select_separator">
                                      </v-text-field>
                                    </v-col>                                    
                                  </v-row>                             
                                </v-col>
                              </v-row>
                            </v-tab-item>
                            <v-tab-item>
                              <v-row>
                                <v-col>
                                  <TemplateBuilderStructureOverrides
                                    :model="attr"
                                    :overrideTypes="overrideTypes"
                                    :lookupTypes="lookupTypes"
                                    width="900px"
                                    @addCriteria="addCriteria"
                                    @deleteOverride="deleteOverride"
                                    @removeCriteria="removeCriteria">
                                  </TemplateBuilderStructureOverrides>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-btn
                                  small
                                  color="primary"
                                  class="ma-2 white--text"
                                  @click="selectAllAttrCheck(attr)"
                                >
                                Select All
                                <v-icon
                                  right
                                  dark
                                >
                                  mdi-plus-circle
                                </v-icon>
                              </v-btn> 
                              <v-btn
                                  small
                                  color="secondary"
                                  class="ma-2 white--text"
                                  @click="deselectAllAttrCheck(attr)"
                                >
                                Deselect All
                                <v-icon
                                  right
                                  dark
                                >
                                  mdi-minus-circle
                                </v-icon>
                              </v-btn>
                              <v-combobox
                                v-model="attr.skip_checks"
                                :items="checkTypes"
                                chips 
                                deletable-chips
                                label="Select Word Check Types to Ignore for this attribute part"
                                multiple
                              ></v-combobox>
                            </v-tab-item>
                          </v-tabs-items>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </Draggable>
              </v-expansion-panels>
            </v-tab-item>
            <v-tab-item v-if="templatePart.attributes.length === 0">
              <v-expansion-panels accordion v-model="ovrPanel" class="attributes-list py-2">
                <Draggable
                  v-model="templatePart.overrides"
                  draghandle=".draghandle"
                  @start="ovrPanel = null"
                  >
                  <TemplateBuilderStructureOverrides
                    :model="templatePart"
                    :overrideTypes="overrideTypes"
                    :lookupTypes="lookupTypes"
                    @addCriteria="addCriteria"
                    @deleteOverride="deleteOverride"
                    @removeCriteria="removeCriteria">
                  </TemplateBuilderStructureOverrides>
                </Draggable>
              </v-expansion-panels>
            </v-tab-item>
            <v-tab-item>
              <v-row dense>
                <v-col>
                  <b><i>Restrict Actions from Part:</i></b>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox v-model="templatePart.exclusions.drag" label="Database View Drag and Drop"></v-checkbox>
                </v-col>
              </v-row>  
              <v-row dense>
                <v-col>
                  <v-checkbox v-model="templatePart.exclusions.spell_check" label="Spell Check"></v-checkbox>
                </v-col>
              </v-row> 
              <v-row dense>
                <v-col>
                  <v-checkbox v-model="templatePart.exclusions.summarise" label="NLP Summarise"></v-checkbox>
                </v-col>
              </v-row> 
              <v-row dense style="padding-top:10px; padding-bottom:10px">
                <v-col>
                  <b><i>Restrict Characters from input:</i></b>
                </v-col>
              </v-row> 
              <v-row dense>
                <v-col>
                  <v-combobox
                    v-model="templatePart.exclusions.restrict_chars"
                    multiple dense
                    ref="restrictedChars" 
                    @keyup="updateOnSpace('restrictedChars',$event)"
                    chips clearable
                  >
                    <template #selection="{ item }">
                      <v-chip @click="removeRestrictedChar(item)">
                        <b>{{item}}</b>
                        <v-icon style="padding-left:5px" x-small>mdi-close</v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>             
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions v-if="templatePart" class="d-flex justify-end pr-6 pb-4">
          <v-btn v-if="partTab === 1" outlined color="primary" @click="addAttribute(templatePart)">Add Attribute</v-btn>
          <v-btn v-if="partTab === 1 && attrTab === 1" outlined color="primary" @click="addAttrOverride(templatePart)">Add Override</v-btn>
          <v-btn v-if="partTab === 2" outlined color="primary" @click="addOverride(templatePart)">Add Override</v-btn>
          <v-btn color="primary" @click="savePart()" :disabled="savePartDisabled">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditInsights" max-width="1000px">
      <v-card>
        <v-card-title>
          Insights
          <v-spacer />
          <v-btn icon @click="showEditInsights = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text v-if="templatePart">
          <v-textarea
            label="Insights"
            rows="1"
            outlined
            auto-grow
            v-model="templatePart.why_text"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-6 pb-4">
          <v-btn color="primary" @click="savePart()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditHelp" max-width="1000px">
      <v-card>
        <v-card-title>
          Help Text
          <v-spacer />
          <v-btn icon @click="showEditHelp = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text v-if="templatePart">
          <v-textarea
            label="Help Text"
            rows="1"
            outlined
            auto-grow
            v-model="templatePart.how_to_text"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-6 pb-4">
          <v-btn color="primary" @click="savePart()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lookups.show" max-width="1200px" max-height="600px">
        <v-card>
            <v-card-title class="d-flex align-center">
                Edit Lookups
            </v-card-title>
            <v-card-text>
                <LookupAdmin />
            </v-card-text>
            <v-card-actions class="d-flex justify-end px-6 pb-4">
                <v-btn @click="lookups.show = false">Close</v-btn>  
            </v-card-actions>
        </v-card>
      </v-dialog>
    <Loading :isVisible="isLoading || isUploading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <v-file-input v-show="false" accept=".json" ref="inputFile" dense @change="uploadTemplateFromFile" label="Select File"></v-file-input>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ResponseHandler from "@/components/ResponseHandler";
import TemplateBuilderStructureOverrides from "./cTemplateBuilderStructureOverrides"
import Draggable from "vuedraggable";
import utils from "@/common/utils.js";
import LookupAdmin from '@/components/common/LookupAdmin';

export default {
  name: "cTemplateBuilderStructure",
  components: {
    Draggable,
    ResponseHandler,
    TemplateBuilderStructureOverrides,LookupAdmin
  },
  props: {
    value: { type: Number, required: false },
  },
  data: function () {
    return {
      isLoading: false,
      isUploading: false,
      response: null,
      templateHeader: null,
      templateParts: [],
      templatePart: null,
      partTypes: [],
      lookupTypes: [],
      showEditTemplate: false,
      headerTab: 0,
      showEditPart: false,
      partTab: 0,
      attrTab: 0,
      showEditInsights: false,
      showEditHelp: false,
      attrPanel: null,
      ovrPanel: null,
      checkTypes: [],
      lookups: {
          show:false
      },
      showScoringOptions: [
        { text: "Never", value: "NEVER" },
        { text: "For Editors Only", value: "EDITORS_ONLY" },
        { text: "Always", value: "ALWAYS" },
      ],
      essentialOpts: [
        { text: "Not used", value: 0 },
        { text: "Default Off", value: 1 },
        { text: "Default On", value: 2 },
      ],
      formattingOpts: [
        { text: "Standard", value: "" },
        { text: "HeaderPart", value: "HeaderPart" },
        { text: "CentralHeaderPart", value: "CentralHeaderPart" },
        { text: "Title", value: "Title" },
        { text: "CentralTitle", value: "CentralTitle" },
        { text: "DocumentHdr", value: "DocumentHdr"},
      ],
      exportFormattingOpts: [
        { text: "Standard", value: "" },
        { text: "Bold", value: "bold_left" },
        { text: "Bold Centre", value: "bold_centre" },
        { text: "Large Bold", value: "bold_left_large" },
        { text: "Large Bold Centre", value: "bold_centre_large" },
        { text: "List", value: "list" },
        { text: "Bold List", value: "bold_list" },
      ],
      exportSectionOpts: [
        { text: "Default", value: "" },
        { text: "Section 1", value: "Section 1" },
        { text: "Section 2", value: "Section 2" },
      ],
      partDefaultValueTypes: [
        { text: 'Static Text', value: "static" },
        { text: 'Hierarchy', value: "hierarchy" },
      ],
      attrDefaultValueTypes: [
        { text: 'Static Text', value: "static" },
        { text: 'Hierarchy', value: "hierarchy" },
        { text: 'Workflow Activity', value: "workflow" },
        { text: 'Data Capture', value: "data_capture" },
      ],
      workflowActivityAttrs: [
        { text: 'User', value: 'USER' },
        { text: 'Date', value: 'DATE' }
      ],
      workflowActivityTypes: [],
      dataCaptureTypes: [],
      overrideTypes: [
        { text: "Default Text", value: "default_text", desc: "Static default text" },
        { text: "HR Code Lookup", value: "hrcode_lookup", desc: "Read text from lookup by hierarchy code" },
        { text: "Doc Part", value: "doc_part", desc: "Read text from another document with matching criteria" },
        { text: "Hierarchy", value: "hierarchy", desc: "Read text from hierarchy item itself" },
      ],
      entityTypes: [
        {
          entity: "template",
          key: "tmpl_id",
          key2: "tt_id",
          cols: [
            { name: "tmpl_name" },
            { name: "tmpl_active" },
            { name: "why_text" },
            { name: "how_to_text" },
            { name: "hierarchy_editor_restrict" },
            { name: "use_temp_essentials" },
            { name: "use_database_view" },
            { name: "derived_from_flex" },
            { name: "flex_fixed_results" },
            { name: "score_title" },
            { name: "enable_flow_design" },
            { name: "show_in_alljobs" },
            { name: "show_in_create_dialog" },
            { name: "show_scoring" },
            { name: "abbreviation" },
          ]
        },
        {
          entity: "tmpl_part",
          key: "tp_id",
          cols: [
            { name: "partType" },
            { name: "tp_name" },
            { name: "showLabel" },
            { name: "format" },
            { name: "otherLayoutFlags" },
            { name: "default_text" },
            { name: "tp_active" },
            { name: "parent_tp_id" },
            { name: "placeholder" },
            { name: "tp_locked" },
            { name: "uses_essential_flag" },
            { name: "html_export_tag" },
            { name: "html_export_section" },
            { name: "use_default_for_link_copy" },
            { name: "export_default_text" },
            { name: "why_text" },
            { name: "how_to_text" },
            { name: "restrict_to_lookup" },
            { name: "hide_approved_content" },
            { name: "hide_generic_content" },
            { name: "part_section" },
            { name: "suggestion_part" },
            { name: "allow_edit_dialog" },
            { name: "hide_add_delete" },
            { name: "hr_override_text" },
            { name: "hr_override_level" },
            { name: "hr_override_apply_on_insert" },
            { name: "hr_override_apply_on_update" },
            { name: "hr_override_sync" },
          ]
        },
        {
          entity: "tmpl_part_attr",
          key: "tpa_id",
          cols: [
            { name: "title" },
            { name: "cols" },
            { name: "placeholder" },
            { name: "restrict_to_lookup" },
            { name: "lookup_type" },
            { name: "lookup_key" },
            { name: "multi_select" },
            { name: "multi_select_separator" },
            { name: "include_in_part_val" },
            { name: "include_in_link_copy" },
            { name: "prefix" },
            { name: "suffix" },
            { name: "separator" },
            { name: "default_text" },
            { name: "locked" },
            { name: "css_class_name" },
            { name: "required" },
            { name: "hr_override_text" },
            { name: "hr_override_level" },
            { name: "hr_override_apply_on_insert" },
            { name: "hr_override_apply_on_update" },
            { name: "hr_override_sync" },
            { name: "allow_adding" },
            { name: "active" },
            { name: "workflow_activity_type" },
            { name: "workflow_activity_attr" },
            { name: "data_capture_type_id" },
          ]
        },
        {
          entity: "tmpl_part_override",
          key: "tpo_id",
          cols: [
            { name: "override_type" },
            { name: "default_text" },
            { name: "lookup_type" },
            { name: "apply_on_insert" },
            { name: "apply_on_update" },
            { name: "apply_on_edit" },
            { name: "comments" },
            { name: "tpa_id" },
          ]
        },
        {
          entity: "tmpl_part_override_criteria",
          key: "tpoc_id",
          key2: "tpocl_id",
          cols: [
            { name: "type" },
            { name: "ht_id" },
            { name: "hr_id" },
            { name: "h_level1" },
            { name: "h_level2" },
            { name: "h_level3" },
            { name: "h_level4" },
            { name: "h_level5" },
            { name: "h_level6" },
            { name: "ct_id" },
            { name: "cl_value" },
            { name: "cl_category" }
          ]
        }
      ]
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  watch: {
    value() {
      this.init();
    },
    attrPanel() {
      this.attrTab = 0;
    }
  },
  computed: {
    ...mapState({
      docsLoading: (state) => state.docs.loading,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      docTypes: (state) => state.hierarchies.docTypes,
      hierarchies: (state) => state.hierarchies.hierarchies,
      docClassifiers: (state) => state.hierarchies.docClassifiers
    }),
    saveTemplateDisabled() {
      return !this.templateHeader || !this.templateHeader.tmpl_name || !this.templateHeader.abbreviation;
    },
    savePartDisabled() {
      return !this.templatePart || !this.templatePart.partType || !this.templatePart.tp_name;
    }
  },
  methods: {
 
    openLookupAdmin(){
      this.lookups.show = true;
    },    
    selectAllAttrCheck(attr){
      attr.skip_checks = [...this.checkTypes];
    },
    deselectAllAttrCheck(attr){
      attr.skip_checks = [];
    },
    removeRestrictedChar(item){
      if(this.templatePart.exclusions?.restrict_chars.length === 0) { return; }

      let idx = this.templatePart.exclusions.restrict_chars.indexOf(item);
      if(idx > -1){
        this.templatePart.exclusions.restrict_chars.splice(idx,1);
      }
    },
    updateOnSpace(element, $event) { 
      this.$refs[element].onEnterDown($event); 
    },    
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("templateBuilder/templateDetails/" + this.value)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            const header = data.header;
            let parts = data.parts;
            const partTypes = data.partTypes;
            const formattingOpts = [...this.formattingOpts];
            const exportFormattingOpts = [...this.exportFormattingOpts];
            const exportSectionOpts = [...this.exportSectionOpts];
            parts.forEach((p) => {
              p.partType = partTypes.find((pt) => pt.pt_id === p.pt_id);
              const layoutOpts = p.layout.split("|");
              p.showLabel = layoutOpts[0] === "InnerGutter";
              p.format = layoutOpts.length > 1 ? layoutOpts[1] : "";
              p.otherLayoutFlags = layoutOpts.length > 2 ? layoutOpts.slice(2, layoutOpts.length) : [];

              if (p.format && !formattingOpts.some((o) => o.value === p.format))
                formattingOpts.push({ text: p.format, value: p.format });

              if (p.html_export_tag && !exportFormattingOpts.some((o) => o.value === p.html_export_tag))
                exportFormattingOpts.push({ text: p.html_export_tag, value: p.html_export_tag });

              if (p.html_export_section && !exportSectionOpts.some((o) => o.value === p.html_export_section))
                exportSectionOpts.push({ text: p.html_export_section, value: p.html_export_section });

              p.overrides.forEach(o => {
                o.override_type = this.overrideTypes.find(ot => ot.value === o.override_type);
              });

              p.default_value_type = this.derivePartDefaultValueType(p);

              p.attributes.forEach(a => {
                a.default_value_type = this.deriveAttrDefaultValueType(a);
                //a.skip_checks = a.skip_checks;
                a.overrides.forEach(o => {
                  o.override_type = this.overrideTypes.find(ot => ot.value === o.override_type);
                });
              });
            });
            
            header.formattingOpts = formattingOpts;
            header.exportFormattingOpts = exportFormattingOpts;
            header.exportSectionOpts = exportSectionOpts;
            this.templateHeader = header;
            this.checkTypes = resp.data.Data.checks.map(c => {
              return c.tc_type
            });
            this.templateParts = parts;
            this.partTypes = partTypes;
            this.lookupTypes = data.lookupTypes;
            this.workflowActivityTypes = data.workflowActivityTypes;
            this.dataCaptureTypes = data.dataCaptureTypes;
            this.setChildOrder();

            if (!this.value) {
              this.showEditTemplate = true;
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    derivePartDefaultValueType(p) {
      let valueType = "static";

      if (p.hr_override_text)
        valueType = "hierarchy";
      
      return valueType;
    },
    deriveAttrDefaultValueType(a) {
      let valueType = "static";

      if (a.workflow_activity_type)
        valueType = "workflow";
      else if (a.hr_override_text)
        valueType = "hierarchy";
      else if (a.data_capture_type_id)
        valueType = "data_capture";

      return valueType;
    },
    setChildOrder() {
      this.templateParts
        .filter(p => p.parent_tp_id)
        .reverse()
        .forEach(p => {
          const childIdx = this.templateParts.indexOf(p);
          if (childIdx >= 0) this.templateParts.splice(childIdx, 1);

          const parentIdx = p.parent_tp_id
            ? this.templateParts.findIndex((pp) => pp.tp_id === p.parent_tp_id)
            : null;
          if (parentIdx)
            this.templateParts.splice(parentIdx + 1, 0, p);
          else
            this.templateParts.push(p);
        });
    },
    configuteTemplate() {
      this.showEditTemplate = true;
    },
    downloadTemplate() {
      const definitionJson = JSON.stringify({
        templateHeader: this.templateHeader,
        templateParts: this.templateParts.filter(p => p.tp_active),
        hierarchies: this.hierarchies.map(h => { return { ht_id: h.ht_id, ht_name: h.ht_name }}),
        docClassifiers: this.docClassifiers.map(c => { return { ht_id: c.ct_id, name: c.name }})
      });
      const fileName =  `${this.templateHeader.tmpl_name.replaceAll(" ","_")}.json`;
      utils.downloadFile(
        definitionJson,
        fileName,
        "text/json"
      );
    },
    uploadTemplate() {
      if (confirm("Update template based on uploaded definition?\nAll configuration will be overwritten!"))
        this.$refs.inputFile.$refs.input.click();
    },
    uploadTemplateFromFile(file) {
      if (file) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          const uploadedDefinition = JSON.parse(reader.result);
          this.updateTemplateFromUpload(uploadedDefinition);
        }, false);
        reader.readAsText(file, "UTF-8");
        this.$refs.inputFile.$refs.input.value = null;
      }
    },
    async updateTemplateFromUpload(definition) {
      
      const updateInstanceFromUpload = (part, def, localInstance, uploadedInstance) => {
        localInstance.fromUpload = true;
        def.cols.forEach(c => {
          if (localInstance[c.name] !== uploadedInstance[c.name]) {
            part.isDirty = true;
            localInstance[c.name] = uploadedInstance[c.name];
          }
        });
      }

      this.isUploading = true;
      try {
        // const def = this.entityTypes.find(x => x.entity === "template");
        // const name = this.templateHeader.tmpl_name;
        // updateInstanceFromUpload(this.templateHeader, def, this.templateHeader, definition.templateHeader);
        // this.templateHeader.tmpl_name = name;
        // await this.save();

        const partDef = this.entityTypes.find(x => x.entity === "tmpl_part");
        definition.templateParts.forEach(p => {
          let localPart = this.templateParts.find(lp => !lp.fromUpload && lp.tp_name === p.tp_name)
          if (!localPart) {
            localPart = this.addPart();
            const insertAt = this.templateParts.findLastIndex(p => p.fromUpload) + 1;
            if (insertAt > 0)
              this.templateParts.splice(insertAt, 0, localPart)
            else
              this.templateParts.push(localPart);
          }
          updateInstanceFromUpload(localPart, partDef, localPart, p);
          localPart.partType = this.partTypes.find(pt => pt.pt_id === p.partType.pt_id)
            || this.partTypes.find(pt => pt.pt_name === p.partType.pt_name)
            || p.partType.pt_name;

          const attrDef = this.entityTypes.find(x => x.entity === "tmpl_part_attr");
          p.attributes?.forEach((a, ai) => {
            let localAttr = localPart.attributes && localPart.attributes.length > ai ? localPart.attributes[ai] : this.addAttribute(localPart);
            updateInstanceFromUpload(localPart, attrDef, localAttr, a);
          });
          localPart.attributes?.filter(a => !a.fromUpload).forEach(a => a.active = false);

          const ovrDef = this.entityTypes.find(x => x.entity === "tmpl_part_override");
          const ovrcDef = this.entityTypes.find(x => x.entity === "tmpl_part_override_criteria");
          p.overrides?.forEach((o, oi) => {
            let localOvr = localPart.overrides && localPart.overrides.length > oi ? localPart.overrides[oi] : this.addOverride(localPart);
            updateInstanceFromUpload(localPart, ovrDef, localOvr, o);
            localOvr.lookup_type = this.lookupTypes.length !== 0 ? this.lookupTypes[0].lookup_type_id : null,
            o.criteria?.forEach((c, ci) => {
              let localOvrc = localOvr.criteria && localOvr.criteria.length > ci ? localOvr.criteria[ci] : this.addCriteria(localOvr);
              updateInstanceFromUpload(localPart, ovrcDef, localOvrc, c);
              if (c.ht_id) {
                const htName = definition.hierarchies.find(h => h.ht_id === c.ht_id)?.ht_name;
                localOvrc.ht_id = this.hierarchies.find(h => h.ht_name === htName)?.ht_id;
              }
              if (c.ct_id) {
                const ctName = definition.docClassifiers.find(c => c.ct_id === c.ct_id)?.name;
                localOvrc.ct_id = this.docClassifiers.find(c => c.name === ctName)?.ct_id;
              }
            });
          })
        });

        this.templateParts.filter(p => !p.fromUpload).forEach(p => {
          p.tp_active = 0;
          p.isDirty = true;
        });
        await Promise.all(this.templateParts.filter(p => p.isDirty && !p.parent_tp_id).map(async (p) => await this.savePart(p)));
        await Promise.all(this.templateParts.filter(p => p.isDirty && p.parent_tp_id).map(async (p) => {
          const parentName = definition.templateParts.find(pp => pp.tp_id === p.parent_tp_id)?.tp_name;
          p.parent_tp_id = this.templateParts.find(lp => lp.tp_name === parentName)?.tp_id || null;
          return await this.savePart(p);
        }));
        this.isUploading = false;
      } catch (e) {
        this.isUploading = false;
        alert("Unexpected Error");
        console.error(e);
      }

      this.templateParts.forEach(tp => {
        tp.fromUpload = false;
        tp.isDirty = false;
      });
    },
    configurePart(part, attr) {
      this.templatePart = part;
      this.showEditPart = true;
      this.partTab = part.attributes && part.attributes.length !== 0 ? 1 : 0;
      this.attrPanel = null;
      this.attrTab = 0;
      this.ovrPanel = null;
      if (attr)
        this.configureAttribute(attr, part);
    },
    configureAttribute(attr, part) {
      this.attrPanel = part.attributes.indexOf(attr);
    },
    setPtName() {
      if (this.templatePart.partType && !this.templatePart.tp_name) {
        this.templatePart.tp_name = this.templatePart.partType.pt_name;
      }
    },
    addAttribute(part) {
      const newAttr = {
        tpa_id: null,
        title: "",
        position: (Math.max.apply(Math, part.attributes.map(function(a) { return a.position; })) || 0) + 1,
        cols: 6,
        placeholder: "",
        lookup_type: null,
        lookup_key: null,
        multi_select: 0,
        multi_select_separator: null,
        restrict_to_lookup: 0,
        include_in_part_val: 1,
        include_in_link_copy: 1,
        prefix: "",
        suffix: "",
        separator: " - ",
        default_text: "",
        locked: 0,
        css_class_name: null,
        required: 0,
        hr_override_text: null,
        hr_override_level: null,
        hr_override_apply_on_insert: 1,
				hr_override_apply_on_update: 1,
				hr_override_sync: 0,
        allow_adding: null,
        active: 1,
        workflow_activity_type: null,
        workflow_activity_attr: null,
        overrides: [],
        default_value_type: "static",
        data_capture_type_id: null,
        skip_checks: []
      }
      part.attributes.push(newAttr);
      this.configureAttribute(newAttr, part);
      return newAttr;
    },
    addOverride(part) {
      const newOvr = {
        tpo_id: null,
        override_type: this.overrideTypes[0],
        default_text: "",
        lookup_type: this.lookupTypes.length !== 0 ? this.lookupTypes[0].lookup_type_id : null,
        lookup_val: "value1",
        apply_on_insert: 1,
        apply_on_update: 0,
        apply_on_edit: 0,
        comments: "",
        criteria: [],
        tpa_id: null,
      };
      part.overrides.push(newOvr);
      this.ovrPanel = part.overrides.length - 1;
      return newOvr;
    },
    addAttrOverride(part) {
      const attr = part.attributes[this.attrPanel];
      if (attr) {
        const newOvr = {
          tpo_id: null,
          override_type: this.overrideTypes[0],
          default_text: "",
          lookup_type: this.lookupTypes.length !== 0 ? this.lookupTypes[0].lookup_type_id : null,
          lookup_val: "value1",
          apply_on_insert: 1,
          apply_on_update: 0,
          apply_on_edit: 0,
          comments: "",
          criteria: []
        };
        attr.overrides.push(newOvr);
        this.ovrPanel = attr.overrides.length - 1;
        return newOvr;
      }
    },
    addCriteria(override) {
      const newOvr = {
        tpoc_id: null,
        type: "hierarchy",
        ht_id: this.hierarchies.length !== 0 ? this.hierarchies[0].ht_id : null,
        hr_id: null,
        h_level1: null,
        h_level2: null,
        h_level3: null,
        h_level4: null,
        h_level5: null,
        h_level6: null,
        tpocl_id: null,
        ct_id: this.docClassifiers.length !== 0 ? this.docClassifiers[0].ct_id : null,
        cl_value: null,
        cl_category: null
      };
      override.criteria.push(newOvr);
      return newOvr;
    },
    deleteOverride(request) {
      const part = request.templatePart;
      if (confirm("Delete override?")) {
        const idx = part.overrides.indexOf(request.override);
        if (idx >= 0) {
          part.overrides.splice(idx, 1);
        }
      }
    },
    removeCriteria(request) {
      const idx = request.override.criteria.indexOf(request.criteria);
      if (idx >= 0) {
        request.override.criteria.splice(idx, 1);
      }
    },
    configureInsights(part) {
      this.templatePart = part;
      this.showEditInsights = true;
    },
    configureHelp(part) {
      this.templatePart = part;
      this.showEditHelp = true;
    },
    async save() {
      if (this.saveTemplateDisabled)
        return;

      const def = this.entityTypes.find(x => x.entity === "template");
      const request = {};
      request[def.key] = this.templateHeader[def.key];
      request[def.key2] = this.templateHeader[def.key2];
      def.cols.forEach(c => request[c.name] = this.templateHeader[c.name]);

      this.isLoading = true;
      let possibleError = false;
      await axios
        .post("templateBuilder/updateTemplateHeader", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.templateHeader.tmpl_id = resp.data.Data.tmpl_id;
            this.templateHeader.tt_id = resp.data.Data.tt_id;
            this.$emit('headerUpdated', resp.data.Data);
          }
          this.showEditTemplate = false;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    async saveOrder() {
      this.setChildOrder();

      let parts = this.templateParts
        .map((p, pi) => {
          if (p.positionRow !== pi + 1)
            return {
              tp_id: p.tp_id,
              positionRow: pi + 1,
            };
        })
        .filter((p) => p);

      if (parts.length !== 0) {
        this.isLoading = true;
        let possibleError = false;
        await axios
          .post("templateBuilder/setPartOrder", {
            tmpl_id: this.value,
            parts: parts,
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              parts.forEach((u) => {
                let local = this.templateParts.find((p) => p.tp_id === u.tp_id);
                if (local) local.positionRow = u.positionRow;
              });
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
    async savePart(part) {
      if (this.savePartDisabled)
        return;

      const templatePart = part || this.templatePart;
      const def = this.entityTypes.find(x => x.entity === "tmpl_part");
      const layoutOpts = [
        templatePart.showLabel ? "InnerGutter" : "NoGutter",
        templatePart.format,
        ...(!templatePart.otherLayoutFlags || templatePart.otherLayoutFlags.length === 0 ? [''] : templatePart.otherLayoutFlags)
      ];
      let positionRow = this.templateParts.indexOf(templatePart) + 1;
      if (positionRow <= 0) {
        positionRow = this.templateParts.length + 1;
      }
      if (templatePart.default_value_type !== "hierarchy") {
        templatePart.hr_override_text = null;
        templatePart.hr_override_level = null;
        templatePart.hr_override_apply_on_insert = 1;
        templatePart.hr_override_apply_on_update = 1;
        templatePart.hr_override_sync = 0;
      }
      const request = {
        tp_id: templatePart.tp_id,
        tmpl_id: this.value,
        pt_id: templatePart.partType?.pt_id,
        pt_name: templatePart.partType?.pt_name || templatePart.partType,
        positionRow: positionRow,
        layout: layoutOpts.join("|"),
      };
      def.cols.forEach(c => {
        request[c.name] = templatePart[c.name]
      });

      const attrDef = this.entityTypes.find(x => x.entity === "tmpl_part_attr");
      const ovrDef = this.entityTypes.find(x => x.entity === "tmpl_part_override");
      const ovrcDef = this.entityTypes.find(x => x.entity === "tmpl_part_override_criteria");
      const mapOverrides = (parent) => {
        return parent.overrides.filter(o => o.override_type).map((o, oi) => {
          const ovr = {
            tpo_id: o.tpo_id,
            priority: parent.overrides.length - oi,
          };
          ovrDef.cols.forEach(c => ovr[c.name] = o[c.name]);
          ovr.override_type = o.override_type.value;
          ovr.criteria = o.criteria.map(cr => {
            const ovrc = {
              tpoc_id: cr.tpoc_id,
              tpocl_id: cr.tpocl_id
            };
            ovrcDef.cols.forEach(c => ovrc[c.name] = cr[c.name]);
            return ovrc;
          });
          return ovr;
        });
      }
      request.attributes = templatePart.attributes.map((a, ai) => {
        const attr = {
          tpa_id: a.tpa_id,
          position: ai + 1
        }
        attrDef.cols.forEach(c => attr[c.name] = a[c.name]);
        if (!attr.restrict_to_lookup) {
          attr.lookup_key = null;
          attr.lookup_type = null;
          attr.multi_select = 0;
        }
        if(!attr.multi_select){
          attr.multi_select_separator = null;
        }
        if (a.default_value_type !== "workflow") {
          attr.workflow_activity_attr = null;
          attr.workflow_activity_type = null;
        }
        if (a.default_value_type !== "hierarchy") {
          attr.hr_override_text = null;
          attr.hr_override_level = null;
          attr.hr_override_apply_on_insert = 1;
          attr.hr_override_apply_on_update = 1;
          attr.hr_override_sync = 0;
        }
        if (a.default_value_type !== "data_capture") {
          attr.data_capture_type_id = null;
        }

        attr.overrides = mapOverrides(a);
        attr.skip_checks = a.skip_checks.length > 0 ? JSON.stringify(a.skip_checks) : null;
        return attr;
      });

      
      request.exclusions = templatePart.exclusions;
      request.overrides = mapOverrides(templatePart);

      let possibleError = false;
      this.isLoading = true;
      this.showEditPart = false;

      await axios
        .post("templateBuilder/updatePart", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            templatePart.isDirty = false;
            templatePart.tp_id = resp.data.Data.tp_id;
            if (!request.pt_id) {
              const newPT = {
                pt_id: resp.data.Data.pt_id,
                pt_name: resp.data.Data.pt_name,
              };
              this.partTypes.push(newPT);
              templatePart.partType = newPT;
            }
            templatePart.default_value_type = this.derivePartDefaultValueType(templatePart);
            const newAttributes = resp.data.Data.attributes;
            newAttributes.forEach(a => {
              a.default_value_type = this.deriveAttrDefaultValueType(a);
                
              a.overrides.forEach(o => {
                o.override_type = this.overrideTypes.find(ot => ot.value === o.override_type);
              });
            });
            templatePart.attributes.splice(0);
            templatePart.attributes.push(...newAttributes);
            const newOverrides = resp.data.Data.overrides;
            newOverrides.forEach(o => {
              o.override_type = this.overrideTypes.find(ot => ot.value === o.override_type);
            });
            templatePart.overrides.splice(0);
            templatePart.overrides.push(...newOverrides);
            if (!this.templateParts.some(p => p.tp_id === templatePart.tp_id)) {
              this.templateParts.push(templatePart);
            }
            templatePart.exclusions = resp.data.Data.exclusions;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    addPart() {
      const newPart = {
        pt_id: null,
        pt_name: null,
        tp_id: null,
        tp_name: "",
        default_text: "",
        tp_active: 1,
        parent_tp_id: null,
        placeholder: "",
        tp_locked: 0,
        uses_essential_flag: 0,
        html_export_section: null,
        html_export_tag: null,
        use_default_for_link_copy: 0,
        export_default_text: null,
        why_text: null,
        how_to_text: null,
        restrict_to_lookup: 0,
        hide_approved_content: 0,
        hide_generic_content: 0,
        part_section: null,
        suggestion_part: 0,
        allow_edit_dialog: 1,
        hide_add_delete: 0,
        showLabel: 0,
        format: "",
        otherLayoutFlags: [],
        attributes: [],
        overrides: [],
        exclusions: {
          "summarise": false,
          "spell_check":false,
          "drag": false,
          "restrict_chars": []
        },
        default_value_type: "static",
        hr_override_text: null,
        hr_override_level: null,
        hr_override_apply_on_insert: 1,
        hr_override_apply_on_update: 1,
        hr_override_sync: 0,
      }
      this.configurePart(newPart);
      return newPart;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

tr.inactive {
  background-color: #eee;
}

.template {
  border-radius: 10px;
  margin-left: 200px;
  margin-right: 130px;

  .inactive {
    text-decoration: line-through;
    opacity: 0.5;
  }

  .tp-name {
    width: 260px;
    max-width: 260px;
    margin-left: -200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .icons {
      min-width: 40px;
      .no-content {
        opacity: 0.2;
      }
    }

    .draghandle {
      max-width: 175px;
      ::v-deep .v-chip__content {
        padding-top: 3px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .tp-body {
    max-width: 100%;
    cursor: pointer;
    position: relative;
    padding-right: 40px;
    border: solid 1px transparent;
    border-radius: 5px;
    &:hover {
      border-color: rgba(#ccc, 0.5);
    }
    
    &.HeaderPart,
    &.CentralHeaderPart {
      font-weight: 700;
      font-size: 18px;
    }

    &.CentralTitle,
    &.Title {
      font-size: 1.625rem;
      font-weight: bold;
    }

    &.DocumentHdr {
      font-size: 1.25rem;
      font-style: italic;
    }

    &.CentralHeaderPart,
    &.CentralTitle {
      text-align: center;
    }

    &.AttrHeaderPart {
      font-weight: bold;
      font-size: 1rem;
      line-height: normal;
    }

    .placeholder {
      opacity: 0.4;
    }

    &.isChild {
      padding-left: 50px;
    }

    .inner-gutter {
      font-weight: bold;
      font-size: 14px;
    }

    .tp-body-right {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    
    .v-icon {
      opacity: 0.5;
    }
  }

  .tp-actions {
    width: 100px;
    max-width: 100px;
    margin-right: -100px;
    text-align: right;
  }
}

.actions {
  max-width: 140px;
  text-align: right;
  position: fixed;
  top: 120px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.draghandle {
  cursor: move;
}

.attributes-list {
  .attribute {
    cursor: pointer;
    padding: 0 10px;
  }
  .inactive {
    text-decoration: line-through;
  }
}
</style>