<template>
  <v-dialog v-model="show" persistent max-width="980px" @keydown.esc="close">
    <v-card v-if="document" class="px-1 py-2">
      <v-card-title class="d-flex align-center">
        <div class="subtitle-1">Document Details</div>
        <v-spacer />
        <v-btn
          icon
          large
          class="btn-background"
          @click="close"
          :disabled="!UIState.canCancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text dense>
        <v-row wrap dense style="padding-top: 10px">
          <v-col cols="8">
            <template
              v-if="
                document.state.canViewHierarchies ||
                !document.state.uses_workflow
              "
            >
              <v-row dense v-for="(h, hi) in hierarchies" :key="'h' + hi">
                <v-col>
                  <HierarchyTreePicker
                    v-if="useTreePicker"
                    v-model="h.treePickerValue"
                    :label="h.label"
                    :hint="h.hint"
                    :options="h.treePickerOptionsToUse"
                    :placeholder="h.label"
                    :error="h.error"
                    :textValue="h.hierarchy_text"
                    class="mb-7"
                    :readonly="!document.state.canEditHierarchies"
                    :clearable="document.state.canEditHierarchies"
                    :hierarchyType="h"
                    @change="setDirty(h, ...arguments)"
                  >
                  </HierarchyTreePicker>
                  <v-autocomplete
                    v-else-if="!h.selectNew"
                    class="roundish"
                    outlined
                    auto-select-first
                    :ref="'hcb' + hi"
                    :readonly="!document.state.canEditHierarchies"
                    :clearable="document.state.canEditHierarchies"
                    :return-object="false"
                    :items="h.values"
                    item-value="text"
                    item-text="text"
                    :label="h.label"
                    v-model="h.hierarchy_text"
                    @focus="focusHTID = hi"
                    @keyup="searchText($event, h)"
                    @keyup.enter.exact="pickValue(h)"
                    @keyup.ctrl.enter="pickValue(h, true)"
                    :filter="utils.comboFilterPicker"
                    @input="setDirty"
                  >
                    <template v-slot:item="{ item }">
                      <p style="max-width: 800px">{{ item.text }}</p>
                    </template>
                    <template v-slot:prepend-item>
                      <div
                        v-if="h.showNewPrompt"
                        style="padding: 0px 0px 10px 10px; font-size: 14px"
                      >
                        <div v-if="canAddHierarchy !== 0">
                          Press <kbd>Ctrl</kbd> + <kbd>Enter</kbd> to create a
                          new {{ h.label }} <mark>{{ h.searchText }}</mark> or
                          <kbd>Enter</kbd> to pick the highlighted item
                        </div>
                        <div v-else>
                          Press <kbd>Enter</kbd> to pick the highlighted item
                        </div>
                      </div>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title v-if="canAddHierarchy !== 0">
                            No matching results. Press
                            <kbd>Ctrl</kbd> + <kbd>enter</kbd> key to create the
                            new
                            {{ h.label }}
                          </v-list-item-title>
                          <v-list-item-title v-else>
                            {{ h.not_in_list_message }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <v-row
                    dense
                    v-else
                    style="
                      border: solid #1976d2 2px;
                      margin: 2px;
                      border-radius: 4px;
                    "
                  >
                    <v-col cols="6"
                      ><h4
                        style="
                          text-transform: initial;
                          padding-bottom: 10px;
                          padding-top: 5px;
                        "
                      >
                        New {{ h.label }}: <mark>{{ h.hierarchy_text }}</mark>
                      </h4>
                      Please select the hierarchy elements if you can or press
                      <v-btn elevation="2" x-small @click="cancelNew(h)"
                        >Cancel</v-btn
                      >
                      to pick an existing {{ h.label }} <br /><br />
                      Note: If any levels are left empty the new
                      {{ h.label }} will be saved as "Unclassified"
                    </v-col>
                    <v-col cols="5">
                      <v-row
                        dense
                        v-for="(l, li) in h.levels"
                        :key="'h' + hi + 'l' + li"
                      >
                        <v-col cols="1"></v-col>
                        <v-col cols="11">
                          <v-text-field
                            :label="'Level ' + (li + 1)"
                            class="roundish"
                            readonly
                            v-if="li === h.levels.length - 1"
                            v-model="h.hierarchy_text"
                          ></v-text-field>
                          <v-select
                            v-else
                            class="roundish"
                            :menu-props="{ 'offset-y': true }"
                            v-model="l.selected"
                            return-object
                            :label="'Level ' + (li + 1)"
                            :items="l.items"
                            item-text="text"
                            item-value="text"
                            @change="classifyHierarchy(h, l)"
                          >
                          </v-select>
                        </v-col> </v-row
                    ></v-col>
                    <v-col cols="1"
                      ><v-icon
                        class="float-right"
                        @click="cancelNew(h)"
                        style="color: #1976d2"
                        >mdi-close</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <v-row dense v-if="docClassifiers.length">
              <v-col
                cols="12"
                v-for="(c, ci) in docClassifiers"
                :key="'c' + ci"
              >
                <v-combobox
                  outlined
                  class="roundish"
                  :return-object="true"
                  :items="c.values"
                  :label="c.name"
                  item-text="value"
                  item-value="cv_id"
                  v-model="c.selected"
                  :readonly="!document.state.canEditHierarchies"
                  :clearable="document.state.canEditHierarchies"
                  :filter="utils.comboFilterPicker"
                  hide-details
                  @change="setDirty"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content style="max-width: 800px">
                      <v-list-item-title>
                        {{ item.value }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No matching results.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row dense v-if="document.state.canViewRecruiter">
              <v-col cols="12">
                <v-combobox
                  v-if="!recruiterRestrict && canAddRecruiter !== 0"
                  class="roundish userPilotRecruiterDropDown"
                  outlined
                  :readonly="!document.state.canEditRecruiter"
                  :clearable="document.state.canEditRecruiter"
                  :return-object="false"
                  :items="recruiters"
                  :label="recruiterAltLabel"
                  item-text="text"
                  item-value="text"
                  v-model="document.recruiter"
                  :error="!document.recruiter"
                  @input="setDirty"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No matching results. Press
                          <kbd>enter</kbd> to link to a new
                          {{ recruiterAltLabel }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
                <v-autocomplete
                  v-else
                  class="roundish userPilotRecruiterDropDown"
                  outlined
                  :readonly="!document.state.canEditRecruiter"
                  :clearable="document.state.canEditRecruiter"
                  :return-object="false"
                  :items="recruiters"
                  :label="recruiterAltLabel"
                  item-text="text"
                  item-value="text"
                  v-model="document.recruiter"
                  :error="!document.recruiter"
                  @input="setDirty"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select
                  v-if="!document.lifecycle_status_id"
                  class="roundish"
                  outlined
                  :menu-props="{ 'offset-y': true }"
                  :readonly="$loginState.readOnly"
                  :return-object="false"
                  :items="statuses"
                  label="Status"
                  item-text="text"
                  item-value="status"
                  v-model="document.doc_status"
                  @change="setDirty"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-if="document.state.canViewQuality"
                  :readonly="!document.state.canEditQuality"
                  style="min-width:190px; min"
                  class="roundish"
                  outlined
                  :menu-props="{ 'offset-y': true }"
                  :return-object="false"
                  :items="utils.qualities"
                  label="Quality"
                  item-text="text"
                  item-value="value"
                  v-model="document.quality"
                  @change="setDirty"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-if="!document.state.uses_workflow && enableHiringManagerFilter"
          dense
        >
          <v-col cols="12">
            <b>Taleo Integration Details:</b>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (!document.state.uses_workflow && canAddReqId) ||
            enableHiringManagerFilter
          "
          dense
        >
          <v-col cols="12" left>
            <v-combobox
              v-model="requisitions"
              :items="requisitions"
              :disabled="!canAddReqId"
              chips
              label="Requisition IDs"
              multiple
              @change="setDirty"
            ></v-combobox>
            <v-alert
              dense
              type="error"
              v-if="reqIDMandatory && !document.requisitions"
              >Requisition Id is required</v-alert
            >
          </v-col>
        </v-row>
        <v-row v-if="dataCaptures.length" dense>
          <v-col
            cols="8"
            left
            v-for="(dc, dci) in dataCaptures"
            :key="'dc' + dci"
          >
            <!-- <DataCaptureQuestionnaire
              v-if="dc.data_capture_method === 'questionnaire'"
              :action="dc"
              @saveAnswer="updateQuestionnaireAnswer"
              @actionTriggered="doStateAction"
            /> -->
            <DataCaptureRequisitions
              v-if="dc.data_capture_method === 'requisition'"
              :action="dc"
              :isBusy="isLoading"
              :editState="dataCaptureStates[dci]"
              @save="saveDataCapture"
              @updateState="updateDCState(dataCaptureStates[dci], $event)"
              @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
            />
            <!-- @requestEdit="doStateAction" -->
            <DataCaptureForm
              v-else-if="dc.data_capture_method === 'form'"
              :action="dc"
              :isBusy="isLoading"
              :editState="dataCaptureStates[dci]"
          :docId="document.doc_id"
              @save="saveDataCapture"
              @updateState="updateDCState(dataCaptureStates[dci], $event)"
              @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
            />
            <DataCaptureDocumentRole
              v-else-if="dc.data_capture_method === 'document_role'"
              :action="dc"
              :isBusy="isLoading"
              :editState="dataCaptureStates[dci]"
          :docId="document.doc_id"
              @save="saveDataCapture"
              @updateState="updateDCState(dataCaptureStates[dci], $event)"
              @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
            />
            <AssignReviewGroup
              v-else-if="dc.data_capture_method === 'review_group'"
              :docId="document.doc_id"
              :action="dc"
              :editState="dataCaptureStates[dci]"
              @isDirty="updateDCState(dataCaptureStates[dci], $event)"
              @save="saveDataCapture"
            >
            </AssignReviewGroup>
            <!-- @requestEdit="doStateAction" -->
          </v-col>
        </v-row>
        <v-row v-if="enableHiringManagerFilter" dense>
          <v-col cols="12" right>
            <v-select
              v-model="hiring_managers"
              :items="hiring_managers"
              disabled
              chips
              label="Hiring Managers"
              multiple
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense v-if="warningText || errorText">
          <v-col v-if="errorText">
            <v-alert type="error">{{ errorText }}</v-alert>
          </v-col>
          <v-col v-if="warningText">
            <v-alert type="warning">{{ warningText }}</v-alert>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="text-right">
            <v-btn
              large
              color="primarylight"
              class="roundish px-10"
              :disabled="!UIState.canSave"
              @click="save"
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" />
    <v-dialog v-model="confirmNew"> </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import utils from "@/common/utils.js";
import { mapState } from "vuex";
import DataCaptureForm from "@/components/cDataCaptureForm.vue";
import DataCaptureRequisitions from "@/components/cDataCaptureRequisitions.vue";
import AssignReviewGroup from "@/components/cAssignReviewGroup.vue";
import DataCaptureDocumentRole from "@/components/cDataCaptureDocumentRole.vue";

export default {
  name: "DocumentHeader",
  components: {
    ResponseHandler,
    HierarchyTreePicker,
    DataCaptureRequisitions,
    DataCaptureForm,
    AssignReviewGroup,
    DataCaptureDocumentRole,
  },
  props: {
    open: null,
    source: null,
    checks: null,
  },
  data: function () {
    return {
      show: false,
      matches: [],
      hierarchies: [],
      docClassifiers: [],
      recruiters: [],
      managers: [],
      statuses: [],
      response: null,
      document: null,
      isLoading: false,
      isDirty: false,
      utils: utils,
      focusHTID: null,
      confirmNew: false,
      canSave: true,
      errorText: "",
      warningText: "",
      currentlyApproved: false,
      selectedClassifierLevel: null,
      recruiterAltLabel: "Recruiter",
      enableHiringManagerFilter: false,
      requisitions: [],
      hiring_managers: [],
      canAddReqId: false,
      useTreePicker: false,
      reqIDMandatory: false,
      dataCaptures: [],
      dataCaptureStates: [],
      UIState: {
        isDirty: false,
        canSave: true,
        canCancel: true,
      },
      recruiterRestrict: false,
    };
  },
  updated() {},
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      canAddHierarchy: (state) => state.hierarchies.canAddHierarchy,
      canAddRecruiter: (state) => state.hierarchies.canAddRecruiter,
    }),
  },
  watch: {
    open: {
      immediate: true,
      handler(val) {
        if (val && this.source) {
          this.show = true;
          this.document = JSON.parse(JSON.stringify(this.source));
          this.setupData();
          this.resetUIState();
          if (!this.document.state.uses_workflow) {
            this.document.state.canEditRecruiter =
              this.document.doc_status !== "APPROVED";
            this.document.state.canEditHierarchies =
              this.document.doc_status !== "APPROVED";
          }
          this.currentlyApproved = this.document.doc_status === "APPROVED";
        }
      },
    },
    document: {
      deep: true,
      handler(val, oldval) {
        if (oldval && val && this.open) this.isDirty = true;
        this.errorText = "";
        this.warningText = "";
        this.canSave = true;
        if (
          this.document &&
          !this.document.state.uses_workflow &&
          this.checks &&
          ["PENDING_APPROVAL", "APPROVED"].indexOf(this.document.doc_status) >=
            0
        ) {
          let doc_status_text = this.statuses.find(
            (x) => x.status === this.document.doc_status
          ).text;
          let aan =
            "aehiou".indexOf(doc_status_text.substr(0, 1).toLowerCase()) >= 0
              ? "an "
              : "a ";
          if (this.checks.errors.length) {
            this.canSave =
              this.currentlyApproved &&
              this.document.doc_status === "PENDING_APPROVAL"; // approved can be set to pending if errors - affects legacy docs
            this.errorText =
              "There " +
              (this.checks.errors.length === 1 ? "is" : "are") +
              " " +
              this.checks.errors.length +
              " document error" +
              (this.checks.errors.length === 1 ? "" : "s") +
              " that " +
              (this.canSave
                ? "require" +
                  (this.checks.errors.length === 1 ? "s" : "") +
                  " assessment."
                : "prevent" +
                  (this.checks.errors.length === 1 ? "s " : " ") +
                  aan +
                  doc_status_text +
                  " document from being saved.");
          }
          if (this.checks.warnings.length)
            this.warningText =
              "There " +
              (this.checks.warnings.length === 1 ? "is" : "are") +
              " " +
              this.checks.warnings.length +
              " warning" +
              (this.checks.warnings.length === 1 ? "" : "s") +
              " that should be assessed before a document is set to " +
              doc_status_text +
              ".";
        }

        if (
          this.document &&
          this.document.recruiter &&
          (this.recruiterRestrict || this.canAddRecruiter === 0) &&
          !this.recruiters.some((r) => r.text === this.document.recruiter)
        )
          this.document.recruiter = "";

        if (
          this.document &&
          this.document.state.canViewRecruiter &&
          !this.document.recruiter
        )
          this.canSave = false;
        this.setUIState();
      },
    },
    hierarchiesLoading(val) {
      if (val) {
        this.setupData();
      }
    },
    requisitions(val) {
      if (val) {
        this.document.requisitions = val.join(",");
      }
    },
    dataCaptureStates: {
      deep: true,
      handler() {
        this.setUIState();
      },
    },
  },
  methods: {
    setupData() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        let hierarchies = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.hierarchies)
        );
        hierarchies.forEach((h) => {
          let hr = this.document.hierarchies.find((dh) => dh.ht_id === h.ht_id);
          h.hr_id = hr ? hr.hr_id : null;
          let hItem = h.values.find((v) => v.value === h.hr_id);
          h.hierarchy_text = hr && hItem ? hItem.text : null;
          h.missing = hr ? false : true;
          h.treePickerValue = h.hr_id;
          h.allowAddLeafNode = this.document.state.ht_id_can_add_node.some(
            (a) => h.ht_id === a
          );
          h.treePickerOptionsToUse =
            hItem && !hItem.hr_active
              ? h.treePickerOptions
              : h.treePickerOptionsActive;
        });
        this.useTreePicker = !!this.$loginState.user.settings.find(
          (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
        );

        let altLabel = this.$loginState.user.settings.find(
          (s) => s.setting === "RecruiterAltLabel"
        );
        if (altLabel !== undefined && altLabel.value !== " ") {
          this.recruiterAltLabel = altLabel.value;
        }

        let hmSearch = this.$loginState.user.settings.find(
          (s) => s.setting === "EnableHiringManagerFilter"
        );
        if (hmSearch !== undefined && hmSearch.value === "true") {
          this.enableHiringManagerFilter = true;
          this.requisitions =
            this.document.requisitions == ""
              ? []
              : this.document.requisitions.split(",");
          this.hiring_managers =
            this.document.hiring_manager == ""
              ? []
              : this.document.hiring_manager.split(",");
        }

        let manualReqNumberEntry = this.$loginState.user.settings.find(
          (s) => s.setting === "ManualReqEntry"
        );
        if (
          manualReqNumberEntry !== undefined &&
          manualReqNumberEntry.value === "true"
        ) {
          this.requisitions =
            this.document.requisitions == ""
              ? []
              : this.document.requisitions.split(",");
          this.canAddReqId = true;
        }
        this.reqIDMandatory =
          this.canAddReqId &&
          this.$loginState.user.settings.some(
            (s) =>
              s.setting === "createdialog_req_id_required" && s.value === "true"
          );

        if (!this.document.state.uses_workflow) {
          let hideReqFieldSetting = this.$loginState.user.settings.find(
            (s) => s.setting === "createdialog_hide_recruiter_field"
          );
          if (hideReqFieldSetting !== undefined) {
            let docTypes = hideReqFieldSetting.value.split("|").map((a) => {
              return a;
            });
            this.document.state.canViewRecruiter = !docTypes.some(
              (x) => x.toLowerCase() === this.document.doc_type.toLowerCase()
            );
          } else {
            this.document.state.canViewRecruiter = true;
          }
        }

        this.recruiterRestrict = !!this.$loginState.user.settings.find(
          (s) =>
            s.setting === "recruiter_restrict_to_users" && s.value === "true"
        );

        this.hierarchies = hierarchies;
        this.docClassifiers = this.$store.state.hierarchies.docClassifiers.map(
          (c) => {
            return {
              ct_id: c.ct_id,
              name: c.name,
              selected: c.values.find((cv) =>
                this.document.classifiers.map((c) => c.cv_id).includes(cv.cv_id)
              ),
              values: c.values,
            };
          }
        );
        // this.docClassifiers.forEach(c => {
        //   c.selected = c.values.filter(cv => currentClassifierIds.includes(cv.cv_id));
        // });
        this.recruiters = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.recruiters)
        );
        this.managers = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.managers)
        );
        this.statuses = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.statuses)
        );
        this.dataCaptures = this.document.state.dataCaptureActionsHeader;
        this.dataCaptureStates =
          this.document.state.dataCaptureActionsHeader.map((x) => {
            return {
              data_capture_type_id: x.data_capture_type_id,
              isDirty: false,
              canSave: true,
              canCancel: true,
              isValid: true,
              doSave: false,
            };
          });
      }
    },
    updateDCState(state, newState) {
      state.isDirty = newState.isDirty;
      state.canSave = newState.canSave;
      state.canCancel = newState.canCancel;
      state.isValid = newState.isValid;
    },
    close() {
      if (this.UIState.canCancel) {
        this.show = false;
        this.document = null;
        this.$emit("closed");
      }
    },
    checkNew() {
      let data = {
        system_number: this.document.system_number,
        doc_id: this.document.doc_id,
        doc_status: this.document.doc_status,
        doc_status_text: this.statuses.find(
          (x) => x.status === this.document.doc_status
        ).text,
        requisition_numbers:
          !this.document.state.uses_workflow &&
          (this.enableHiringManagerFilter || this.canAddReqId)
            ? this.document.requisitions
            : "",
        hiring_manager: this.document.hiring_manager,
        recruiter: !this.document.state.canViewRecruiter
          ? this.$loginState.user.name
          : this.document.recruiter,
        contact: this.document.contact,
        approval_requested_date: this.document.approval_requested_date,
        start_date: this.document.start_date,
        quality: this.document.quality,
        hierarchies: this.hierarchies.map((h) => {
          let hr = h.values.find(
            (v) =>
              v.text.toLowerCase() === (h.hierarchy_text || "").toLowerCase() &&
              v.value
          );
          let ret = {
            ht_id: h.ht_id,
            hr_id: hr ? hr.value : h.hierarchy_text,
            isNew: hr ? false : true,
            wasMissing: h.missing,
          };
          if (this.useTreePicker && h.newLeafNodeValue) {
            h.wasMissing = ret.wasMissing;
            ret = h.newLeafNodeValue;
          } else {
            if (this.useTreePicker) {
              ret.hr_id = h.treePickerValue || null;
              ret.isNew = ret.hr_id ? false : true;
            }
            [1, 2, 3, 4, 5, 6].forEach((n) => (ret["level" + n] = null));
            if (ret.isNew && !h.levels.some((l) => !l.selected)) {
              let missing = false;
              h.levels.forEach((l) => {
                ret["level" + l.index] = l.selected.text;
                if (!l.selected.text) missing = true;
              });
              if (missing) {
                h.levels.forEach((l) => {
                  ret["level" + l.index] = null;
                });
              }
            }
          }
          return ret;
        }),
        docClassifiers: this.docClassifiers.map((c) => {
          return {
            ct_id: c.ct_id,
            cv_id: c.selected?.cv_id,
          };
        }),
      };
      return data;
    },
    classifyHierarchy(hierarchy, level) {
      if (level.index === hierarchy.levels[hierarchy.levels.length - 1].index) {
        return;
      }
      this.setDirty();
      let penultimateIndex =
        hierarchy.levels[hierarchy.levels.length - 1].index;
      let doneN = false;
      hierarchy.levels.forEach((l) => {
        if (l.index > level.index) {
          l.items.splice(0);
          if (!doneN) {
            l.items.push(...level.selected.items);
            if (l.index === penultimateIndex) {
              let newItem = {
                text: hierarchy.hierarchy_text,
                level: penultimateIndex,
                items: [],
              };
              l.items.push(newItem);
              hierarchy.levels[hierarchy.levels.length - 1].selected = newItem;
              // } else if (l.items.length === 1) {
              // 	hierarchy.levels[li].selected = l.items[0];
              // 	this.classifyHierarchy(hierarchy, l);
            }

            doneN = true;
          }
        }
      });
    },
    save() {
      if (this.document.state.canViewRecruiter && !this.document.recruiter)
        return;
      if (
        this.reqIDMandatory &&
        !this.document.state.uses_workflow &&
        !this.document.requisitions
      )
        return;
      let data = this.checkNew();
      if (!data) return;
      this.isLoading = true;
      //   if (this.dataCaptureEditState.isDirty)
      //     this.dataCaptureEditState.doSave = true;
      this.dataCaptureStates
        .filter((x) => x.isDirty)
        .forEach((x) => (x.doSave = true));

      axios
        .post("document/saveDocumentHeader/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.$store.dispatch("docs/updateDoc", resp.data.Data.documents[0]);
            this.$emit("saved", resp.data);
          } else {
            alert(resp.data.Message);
          }
          if (data.hierarchies.some((x) => x.isNew))
            this.$store.dispatch("hierarchies/refresh");
          this.response = resp.data;
          this.isLoading = false;
          this.show = false;
          this.document = null;
          this.hierarchies.splice(0);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    pickValue(hrchy, isnew) {
      let ctl = this.$refs["hcb" + this.focusHTID][0],
        h = this.hierarchies[this.focusHTID];
      ctl.isMenuActive = false;
      if (
        this.canAddHierarchy !== 0 &&
        (isnew || !ctl.filteredItems.length) &&
        hrchy.searchText
      ) {
        h.hierarchy_text = hrchy.searchText;
        if (this.canAddHierarchy === 1) {
          hrchy.selectNew = true;
        } else {
          hrchy.values.push({ text: hrchy.searchText });
        }
      } else {
        ctl.lazySearch = h.hierarchy_text;
      }
      hrchy.showNewPrompt = false;
    },
    searchText($event, hrchy) {
      let ctl = this.$refs["hcb" + this.focusHTID][0];
      if ([13, 37, 38, 39, 40].indexOf($event.keyCode) >= 0) return;
      if (ctl.lazySearch) {
        if ($event.keyCode !== 13) hrchy.searchText = ctl.lazySearch.trim();
        let match = ctl.filteredItems.find(
          (f) => f.text.toLowerCase() === ctl.lazySearch.toLowerCase()
        );
        if (match) {
          ctl.selectItem(match);
          hrchy.hierarchy_text = match.text;
          hrchy.hr_id = match.hr_id;
          hrchy.showNewPrompt = false;
        } else {
          hrchy.showNewPrompt =
            ctl.filteredItems.length &&
            (!hrchy.hierarchy_text ||
              hrchy.hierarchy_text.toLowerCase() !==
                hrchy.searchText.toLowerCase());
        }
      } else {
        hrchy.hierarchy_text = "";
        hrchy.showNewPrompt = false;
      }
    },
    cancelNew(h) {
      h.selectNew = false;
      h.searchText = "";
      h.showNewPrompt = false;
    },
    resetUIState() {
      this.dataCaptureStates.forEach((x) => {
        x.isDirty = false;
        x.canSave = true;
        x.canCancel = true;
        x.isValid = true;
        x.doSave = false;
      });
      this.UIState.isDirty = false;
      this.UIState.canSave = false;
      this.UIState.canCancel = true;
    },
    setUIState() {
      this.UIState.canCancel = true; //this.dataCaptureStates.every((x) => x.canCancel);
      this.UIState.canSave =
        this.dataCaptureStates.every((x) => x.isValid) &&
        (this.UIState.isDirty ||
          this.dataCaptureStates.some((x) => x.isDirty)) &&
        (this.document && this.document.state.uses_workflow
          ? this.document.state.canEditDocParts
          : this.canSave && (!this.reqIDMandatory || this.requisitions)) &&
        this.hierarchies.filter((h) =>
          this.useTreePicker
            ? !!h.newLeafNodeValue || !!h.treePickerValue
            : h.hierarchy_text
        ).length === this.hierarchies.length;
    },
    setDirty(ht, value, newNode) {
      if (this.useTreePicker && ht && ht.newLeafNodeValue !== undefined) {
        ht.newLeafNodeValue = newNode ? newNode : null;
      }
      this.UIState.isDirty = true;
      this.setUIState();
    },
    saveDataCapture(def, action) {
      this.$emit("saveDataCapture", def, action);
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
