<template>
  <div v-if="action">
    <v-card v-if="isBusy" style="min-height: 500px" class="pa-5">
      <v-toolbar fixed dense flat color="transparent">
        <v-toolbar-title>{{ definition.data_capture_name }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="5"></v-col>
          <v-col>
            <v-progress-circular indeterminate size="50" color="grey">
            </v-progress-circular>
          </v-col>
          <v-col cols="5"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card
        v-if="action.definition"
        :class="{ 'pa-5': viewType === 'dialogue' }"
      >
        <v-card-title
          v-if="
            viewType === 'dialogue' ||
            (definition.inputs && definition.inputs.length > 1)
          "
        >
          {{ definition.data_capture_name }}
          <v-spacer></v-spacer>
          <v-icon v-if="action.dataCaptureEditAction" @click="triggerEdit">
            mdi-square-edit-outline</v-icon
          >
          <v-icon v-if="action.isDataCaptureViewer && viewType === 'inline'"
            >{{
              action.isDataCaptureHidden ? `mdi-eye` : "mdi-eye-off"
            }}
            mdi-eye</v-icon
          >
          <template v-if="viewType === 'dialogue'">
            <v-spacer></v-spacer>
            <v-btn
              large
              :disabled="!UIstate.canCancel"
              icon
              class="btn-background ml-2"
              @click="cancel"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="max-height: 800px"
          v-if="!action.isDataCaptureViewer"
        >
          <v-row
            dense
            v-if="
              (viewType === 'inline' || viewType === 'contained') &&
              UIstate.errorMessage
            "
          >
            <v-col cols="12">
              <v-alert dense type="warning">
                {{ UIstate.errorMessage }}</v-alert
              ></v-col
            ></v-row
          >
          <v-textarea
            v-if="definition.isCommentBased"
            v-model="definition.value"
            :label="definition.input_label"
            :rules="[(value) => !!value || 'Required.']"
            hide-details="auto"
            @input="setUIstate(true)"
          ></v-textarea>
          <v-autocomplete
            v-else-if="definition.method === 'picklist'"
            v-model="definition.value"
            :label="definition.input_label"
            :items="definition.list_values"
            item-text="text"
            item-value="id"
            :multiple="!!definition.multiple"
            @input="setUIstate(true)"
          ></v-autocomplete>
          <v-row
            dense
            v-else-if="definition.inputs && definition.inputs.length"
          >
            <v-col
              :cols="ip.response_type === 'longtext' ? 12 : 6"
              v-for="(ip, iip) in definition.inputs"
              :key="'ip' + iip"
              ><v-text-field
                v-if="ip.response_type === 'text'"
                v-model="ip.value"
                :label="ip.question_text"
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="setUIstate(true)"
              ></v-text-field
              ><v-textarea
                v-else-if="ip.response_type === 'longtext'"
                v-model="ip.value"
                :label="ip.question_text"
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="setUIstate(true)"
              ></v-textarea
              ><v-switch
                v-else-if="ip.response_type === 'boolean'"
                v-model="ip.value"
                :label="ip.question_text"
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="setUIstate(true)"
              ></v-switch
              ><v-select
                v-else-if="ip.response_type === 'picklist'"
                v-model="ip.value"
                :label="ip.question_text"
                :items="ip.valid_values"
                :rules="ip.mandatory ? [(value) => !!value || 'Required.'] : []"
                hide-details="auto"
                @input="setUIstate(true)"
              ></v-select
            ></v-col>
            <v-col v-if="viewType === 'inline'" cols="6">
              <template v-if="isDirty">
                <v-btn class="mr-2" @click="save()" :disabled="!UIstate.canSave"
                  >Save</v-btn
                >
                <v-btn @click="cancel" :disabled="!UIstate.canCancel"
                  >Cancel</v-btn
                >
              </template>
            </v-col>
          </v-row>
          <v-row v-if="definition.use_review_group"
            ><v-col>
              <AssignReviewGroup
                :docId="docId"
                :action="action"
                :editState="editState"
                @isDirty="setUIstate(true)"
                @save="save()"
              >
              </AssignReviewGroup> </v-col
          ></v-row>
          <v-row dense v-if="viewType === 'dialogue'">
            <v-col cols="8">
              <v-alert v-if="UIstate.errorMessage" dense type="warning">
                {{ UIstate.errorMessage }}</v-alert
              ></v-col
            >
            <v-col>
              <v-spacer></v-spacer>
              <v-btn class="mr-2" @click="save()" :disabled="!UIstate.canSave"
                >Save Changes</v-btn
              >
              <v-btn @click="cancel" :disabled="!UIstate.canCancel"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-else-if="action.isDataCaptureViewer && !action.isDataCaptureHidden"
          style="max-height: 800px"
        >
          <v-textarea
            v-if="definition.isCommentBased"
            v-model="definition.value"
            :label="definition.input_label"
            disabled
          ></v-textarea>
          <template
            v-else-if="
              !action.isDataCaptureHidden &&
              definition.inputs &&
              definition.inputs.length
            "
          >
            <v-row
              dense
              v-for="(ip, iip) in definition.inputs"
              :key="'ip' + iip"
            >
              <v-col cols="4"
                ><b>{{ ip.question_text }}:</b>
              </v-col>
              <v-col
                ><i>{{ ip.value }}</i>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import AssignReviewGroup from "@/components/cAssignReviewGroup.vue";

export default {
  name: "DataCaptureForm",
  props: {
    action: Object,
    isBusy: Boolean,
    editState: Object,
    docId: Number,
  },
  data: function () {
    return {
      isDirty: false,
      definition: null,
      hasSavedData: false,
      savedData: null,
      viewType: "", // inline, dialogue, contained
      UIstate: {
        canSave: false,
        canCancel: false,
        // canDelete: true,
        errorMessage: "",
      },
    };
  },
  components: {AssignReviewGroup,},
  computed: {},
  created() {
    this.init();
  },
  watch: {
    action(newval) {
      if (newval) {
        this.init();
      }
    },
    "editState.doSave"(newval) {
      if (newval) {
        this.save(true);
      }
    },
  },
  methods: {
    init() {
      //   let def = { ...this.action.definition };
      let def = JSON.parse(JSON.stringify(this.action.definition));
      this.savedData = JSON.parse(JSON.stringify(this.action.definition));
      this.hasSavedData = def.inputs
        ? !def.inputs.some((x) => x.mandatory && !x.value)
        : !!def.value;
      this.definition = def;
      this.isDirty = false;
      this.viewType = this.action.isDataCaptureEditInHeader
        ? "contained"
        : this.action.isDataCaptureEditInPage
        ? "inline"
        : "dialogue";
      this.setUIstate();
    },
    setUIstate(makeDirty) {
      let hasData = false;
      let message = "";
      if (makeDirty) this.isDirty = true;
      hasData = this.isValid();
      let isValid = !this.action.data_capture_mandatory || hasData;
      this.UIstate.canSave = this.isDirty && isValid;
      this.UIstate.canCancel = true;
      // !this.action.data_capture_mandatory || this.hasSavedData;

      if (this.editState) {
        this.$emit("updateState", {
          isDirty: this.isDirty,
          canSave: this.UIstate.canSave,
          canCancel: this.UIstate.canCancel,
          isValid: isValid,
        });
      }
      message =
        this.action.data_capture_mandatory && !hasData
          ? `This document must have a ${this.definition.data_capture_name}`
          : "";
      //   }
      this.UIstate.errorMessage = message;
    },
    save(forceSave) {
      if (forceSave && forceSave === true) {
        this.$emit("save", this.definition, this.action);
      } else {
        switch (this.viewType) {
          case "inline":
          case "dialogue":
            this.$emit("save", this.definition, this.action);
            this.isDirty = false;
            break;
          case "contained":
            break;
        }
      }
      this.setUIstate();
    },
    cancel() {
      this.$emit("cancel");
      if (this.viewType === "inline") {
        if (this.definition.inputs) {
          this.definition.inputs.forEach((x, xi) => {
            x.value = this.savedData.inputs[xi].value;
          });
        } else {
          this.definition.value = this.savedData.value;
        }
        this.isDirty = false;
        this.setUIstate();
      }
    },
    triggerEdit() {
      this.$emit("requestEdit", this.action.dataCaptureEditAction);
    },
    isValid() {
      if (this.action.isDataCaptureViewer) {
        return true;
      } else {
        let valid = true;
        if (this.definition.inputs && this.definition.inputs.length) {
          valid = this.definition.inputs.every((i) => i.value || !i.mandatory);
        }
        if (
          this.definition.isCommentBased ||
          this.definition.method === "picklist"
        ) {
          valid = valid && this.definition.value;
        }
        return valid;
      }
    },
    toggleHide() {
      this.$emit("toggleHidden");
    },
  },
};
</script>
<style scoped lang="scss">
</style>