<template>
  <div v-if="nodes">
    <v-row
      v-for="node in nodes.filter((x) => x.visible)"
      :key="'n' + node.hierarchy_node_id"
      dense
      ><v-col>
        <v-row dense
          ><v-col
            ><div
              class="nodeContainer"
              @click="
                node.expanded = !node.expandable ? node.expanded :
                  node.expandable && node.level < viewLevel && !node.expanded
              "
              :title="(node.name || 'UNCLASSIFIED') + ' (' + (node.hasDocs === 0 ? 'UNUSED BUT ACTIVE)' : node.hasDocs + ' linked  documents)')"
              :style="{
                marginLeft: (node.level - 1) * 10 + '%',
              }"
            >
              <v-icon v-if="node.expandable && node.level < viewLevel">{{
                node.expanded ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
              <v-icon
                v-if="node.pickable"
                class="pickable"
                @click.stop="pickNode(node)"
                >{{
                  node.picked ? "mdi-radiobox-marked" : "mdi-radiobox-blank"
                }}</v-icon
              >
              <div
                class="node"
                :class="[
                  node.pickable ? 'nodePickable' : 'nodeNonPickable',
                  node.expandable && node.level < viewLevel
                    ? 'nodeExpandable'
                    : '',
                  node.selected ? 'nodeSeleted' : '',
                  !node.selected && node.picked ? 'nodePicked' : '',
                ]"
				:style="{ backgroundColor: node.hasDocs === 0 ? 'lightsalmon' : ''}"
              >
                <div v-if="node.pickable" class="nodeInner"
                @click.stop="pickNode(node)">{{ (node.name || 'UNCLASSIFIED') + ' [' + node.hasDocs + ']' }}</div>
                <div v-else class="nodeInner">{{ (node.name || 'UNCLASSIFIED') + ' [' + node.hasDocs + ']' }}</div>
                <v-icon
                  small
                  class="nodeInfoIcon"
                  v-if="node.hasDescription"
                  :title="node.description"
                  >mdi-information</v-icon
                >
              </div>
            </div></v-col
          ></v-row
        >
        <div v-if="node.nodes && node.expanded">
          <HierarchyTreeNode :nodes="node.nodes" :viewLevel="viewLevel" @pickNode="pickNode">
          </HierarchyTreeNode>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "HierarchyTreeNode",
  props: {
    nodes: [],
    viewLevel: Number,
  },
  data: function () {
    return {
      nodeDetails: null,
    };
  },
  components: {},
  watch: {},
  computed: {},
  created: function () {},
  methods: {
    pickNode(node) {
      this.$emit("pickNode", node);
    },
  },
};
</script>

<style scoped lang="scss">
.nodeContainer {
  width: 50%;
  height: 24px;
  padding-left: 6px;
}
.nodeSeleted {
  background-color: rgb(205, 243, 205);
}
.nodePicked {
  background-color: #e8e7e7;
}
.nodeInfoIcon {
  float: right;
  padding-top: 2px;
}
.nodeInner {
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  float: left;
}
.node {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: right;
}
.nodePickable {
  width: 80%;
}
.nodeNonPickable {
  width: 90%;
}
.nodeExpandable {
  cursor: pointer;
}
.pickable {
  width: 10%;
  float: right;
  cursor: grab;
}
</style>