<template>
  <div>
    <div class="flex-container" v-show="!isLoading">
      <div
        v-show="$vuetify.breakpoint.smAndUp && !showHierarchies"
        class="hierarchies-collapsed"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Advanced Filters"
          @click="showHierarchies = !showHierarchies"
          absolute
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndUp"
        v-model="showHierarchies"
        absolute
        class="left-col"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Hierarchies"
          @click="showHierarchies = !showHierarchies"
          absolute
          right
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="d-flex align-center pl-1 pt-5">
          <span class="ml-2">Filter By Hierarchy</span>
        </v-toolbar-title>

        <HierarchySearch
          v-show="showHierarchies"
          :items="documentsForSearch"
          :hierarchyTypes="hierarchyTypes"
          :classifierTypes="classifierTypes"
          @filterChanged="doHierarchyFilter"
        ></HierarchySearch>
      </v-navigation-drawer>
      <v-container
        v-if="selectedView"
        fluid
        :class="{
          'right-col': true,
          hierarchiesCollapsed: !showHierarchies && $vuetify.breakpoint.smAndUp,
          hierarchiesPinned: showHierarchies && $vuetify.breakpoint.lgAndUp,
        }"
      >
        <!-- style="max-width: 100% !important" -->
        <!--<v-row>
        <v-col>
          <v-row>
            <v-col><h2>Upload Documents</h2></v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Select file to import:</v-col>
            <v-col cols="4"
              ><v-file-input
                show-size
                small-chips
                truncate-length="15"
              ></v-file-input></v-col
            ><v-col cols="1"><v-btn>Import Files</v-btn></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col><h2>Imported Documents</h2></v-col>
          </v-row>
          <v-row>
            <v-data-table></v-data-table>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col><h2>New Job => new hierarchy entry</h2></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="selectedHTIndex">
            <v-tab v-for="(ht, hti) in hierarchyTypes" :key="'ht' + hti">{{
              ht.label
            }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row> -->
        <!-- <v-card v-if="listView.show">
          <v-card-text>
            <v-row>
              <v-col cols="2"
                ><v-select
                  style="margin-left: 20px"
                  hide-details
                  outlined
                  dense
                  :items="hierarchyTypes"
                  item-text="title"
                  item-value="ht_id"
                  return-object
                  v-model="listView.hierarchyView"
                  label="View"
                ></v-select
              ></v-col>
              <v-col></v-col>
            </v-row>
          </v-card-text>
        </v-card> -->
        <v-row class="py-4 pl-4">
          <v-col cols="6"
            ><h1 class="title" style="padding-left: 20px">
              Job Architecture
              {{ selectedView ? " - " + selectedView.title : "" }}

              <v-menu bottom close-on-click nudge-bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon title="Add" v-bind="attrs" v-on="on">
                    <v-icon>add_circle</v-icon>
                  </v-btn> </template
                ><v-list>
                  <v-list-item
                    v-for="(h, hi) in hierarchies"
                    :key="'ha' + hi"
                    @click="addHierarchy(h)"
                  >
                    <v-list-item-title>Add {{ h.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h1>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              v-for="(v, vi) in primaryViews"
              :key="'v' + vi"
              icon
              small
              :title="v.view"
              class="ml-3"
              @click="selectedView = views.find((x) => x.title === v.view)"
            >
              <v-icon
                :color="selectedView.title === v.view ? 'blue darken-4' : ''"
                >{{ v.icon }}</v-icon
              >
            </v-btn>
            <v-menu bottom close-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  title="Other preset views"
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn> </template
              ><v-list>
                <v-list-item
                  v-for="(v, vi) in views"
                  :key="'vi' + vi"
                  @click="setViewMenu(v)"
                >
                  <v-list-item-title>{{ v.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu></v-col
          >
        </v-row>
        <div v-if="!haveData" class="d-flex justify-center mt-6">
          <v-alert type="info" width="500">
            <div class="d-flex justify-space-between align-center">
              <div>
                No {{ utils.pluralise(currentViewHierarchy.label) }} exist
              </div>
              <v-btn
                color="white"
                small
                outlined
                @click="addHierarchy(currentViewHierarchy)"
                >Create {{ currentViewHierarchy.label }}</v-btn
              >
            </div>
          </v-alert>
        </div>
        <v-card v-if="haveData && selectedView.viewType === 'catalogue'">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-for="(f, fi) in compareOptionsV.filter(
                    (x) => x.listValues && x.listValues.length
                  )"
                  :key="'f' + fi"
                >
                  <v-select
                    dense
                    hide-details
                    outlined
                    :items="f.listValues.map((x) => x.value)"
                    v-model="f.filterValues"
                    :label="f.name + ' Filter'"
                    @change="selectJDs"
                    multiple
                    clearable
                    :title="f.name + ' Filter'"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="item && index === 0"
                        class="blue--text text-caption"
                      >
                        ({{ f.filterValues.length }} item{{
                          f.filterValues.length > 1 ? "s" : ""
                        }})
                      </span>
                    </template></v-select
                  ></v-col
                >
                <v-col cols="1"></v-col>
                <v-col v-if="isDemo"
                  ><v-checkbox
                    v-model="hideHorizontal"
                    dense
                    hide-details
                    label=" Hide Columns?"
                    @click="buildCompare"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-select
                    v-if="!hideHorizontal"
                    dense
                    hide-details
                    label="Drilldown"
                    outlined
                    :items="compareOptionsD"
                    return-object
                    item-text="name"
                    :append-outer-icon="
                      measures.some((x) => x.aggregation !== 'count')
                        ? 'mdi-cog-outline'
                        : null
                    "
                    item-value="name"
                    @click:append="clearDetailCompareOption"
                    @click:append-outer="openMeasurePicker($event)"
                    v-model="detailCompareOption"
                    :append-icon="
                      !detailCompareOption || detailCompareOption.hideDetails
                        ? null
                        : 'mdi-close-box-outline'
                    "
                    @change="setDetailCompareOption"
                  ></v-select
                ></v-col>
                <v-col v-if="measureFilters.length">
                  <v-select
                    dense
                    hide-details
                    label="Similarity Filter"
                    outlined
                    :items="measureFilters"
                    return-object
                    item-text="title"
                    item-value="title"
                    @click:append="
                      measureFilter = measureFilters[0];
                      selectJDs();
                    "
                    v-model="measureFilter"
                    :append-icon="
                      !measureFilter || !measureFilter.type
                        ? null
                        : 'mdi-close-box-outline'
                    "
                    @change="selectJDs"
                  ></v-select
                ></v-col>
                <v-col v-if="isDemo"
                  ><v-checkbox
                    v-model="showSalary"
                    dense
                    hide-details
                    @click="setShowSalary"
                    label="Salary Analysis?"
                  ></v-checkbox
                ></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <br />
        <v-card v-if="haveData && selectedView.viewType === 'list'">
          <v-card-text>
            <v-data-table
              :headers="lvColumnsFiltered"
              :items="groupedDocs"
              :sort-by.sync="listView.sortColumn"
              :sort-desc.sync="listView.sortDesc"
              :dense="listView.density == 'dense'"
              :class="['mt-2', listView.density]"
              :page.sync="listView.currentPage"
              hide-default-footer
              :items-per-page="listView.itemsPerPage"
              :fixed-header="!!listView.tableHeight"
              :height="listView.tableHeight"
            >
              <template v-slot:top>
                <v-row
                  ><v-col>
                    <!-- <v-spacer></v-spacer -->
                    <v-chip
                      small
                      v-for="(f, fi) in filterList"
                      :key="'f' + fi"
                      close
                      @click:close="removeFilter(f)"
                    >
                      {{ f.title }}</v-chip
                    ></v-col
                  >
                  <v-col v-if="measureFilters.length" cols="2">
                    <v-select
                      dense
                      hide-details
                      label="Similarity Filter"
                      outlined
                      :items="measureFilters"
                      return-object
                      item-text="title"
                      item-value="title"
                      @click:append="
                        measureFilter = measureFilters[0];
                        selectJDs();
                      "
                      v-model="measureFilter"
                      :append-icon="
                        !measureFilter || !measureFilter.type
                          ? null
                          : 'mdi-close-box-outline'
                      "
                      @change="selectJDs"
                    ></v-select></v-col
                  ><v-col class="text-right" cols="1">
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          title="Customise View"
                          large
                          v-on="on"
                          icon
                          class="btn-background ml-3"
                        >
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense class="pa-3">
                        <v-list-item>
                          <v-list-item-title class="subtitle-1"
                            >Customise Table</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          v-for="field in listView.columns.filter(
                            (c) =>
                              !c.ht_id ||
                              c.ht_id === listView.hierarchyView.ht_id
                          )"
                          :key="field.value"
                        >
                          <v-switch
                            class="v-input--reverse v-input--expand"
                            style="width: 200px"
                            dense
                            v-model="listView.columnsEnabled"
                            :label="field.text"
                            :value="field.value"
                            @change="saveColumnSelection"
                          ></v-switch>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </template>
              <!-- eslint-disable vue/valid-v-slot -->
              <template
                v-for="(h, hi) in lvColumnsFiltered"
                v-slot:[`header.${h.value}`]="{ header }"
                ><div style="white-space: nowrap" :key="h.value">
                  {{ header.text }}
                  <v-menu
                    v-if="header.filterSettings"
                    v-model="header.filterSettings.show"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    @input="openListviewFilter(header.filterSettings)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="blue" small v-bind="attrs" v-on="on" icon>
                        <v-icon small>
                          {{
                            header.filterSettings.isActive
                              ? "mdi-filter-settings"
                              : "mdi-filter-outline"
                          }}</v-icon
                        >
                      </v-btn>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ header.text }} Filters</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="filterClear(header.filterSettings)"
                            >
                              Clear
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="header.filterSettings.show = false"
                            >
                              Close
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              small
                              @click="filterHeading(header.filterSettings)"
                            >
                              Apply
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                placeholder="type to filter values"
                                hide-details
                                append-icon="mdi-check-all"
                                @click:append="
                                  filterSearchAll(header.filterSettings)
                                "
                                @input="filterSearch(header.filterSettings)"
                                dense
                                clearable
                                v-model="header.filterSettings.searchText"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="header.filterSettings.pages > 1"
                          >
                            <v-pagination
                              v-model="header.filterSettings.page"
                              :length="header.filterSettings.pages"
                              :total-visible="7"
                            ></v-pagination>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-list style="max-height: 100px; overflow: auto">
                        <v-list-item
                          dense
                          v-for="(v, vi) in header.filterSettings.values.filter(
                            (x) => !x.visible && x.selected
                          )"
                          :key="'head' + hi + 'vs' + vi"
                        >
                          <v-list-item-content>
                            <v-switch
                              v-model="v.selected"
                              color="blue"
                              :label="`${v.text} (${v.count})`"
                            ></v-switch>
                            <!-- @change="filterHeading(header.filterSettings)" -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list
                        style="max-height: 400px; overflow: none"
                        @wheel.stop="
                          filterScroll($event, header.filterSettings)
                        "
                      >
                        <v-list-item
                          dense
                          v-for="(v, vi) in header.filterSettings.values.filter(
                            (x) =>
                              x.visible && x.page === header.filterSettings.page
                          )"
                          :key="'head' + hi + 'v' + vi"
                        >
                          <v-list-item-content>
                            <v-switch
                              v-model="v.selected"
                              color="blue"
                              :label="`${v.text} (${v.count})`"
                            ></v-switch>
                            <!-- @change="filterHeading(header.filterSettings)" -->
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <v-simple-checkbox
                    v-if="h.value === 'selected'"
                    :key="h.value"
                    v-model="h.selected"
                    @click="selectAllListViewJob(h)"
                  ></v-simple-checkbox>
                  <!-- :disabled="!h.selected && groupedDocs.length > 30" -->
                </div>
              </template>
              <!-- eslint-enable vue/valid-v-slot -->
              <template v-slot:item="{ item }">
                <tr>
                  <td v-for="col in lvColumnsFiltered" :key="col.value">
                    <div v-if="col.value === 'selected'">
                      <v-simple-checkbox
                        v-model="item.selected"
                        @click="selectListviewJob(item)"
                      ></v-simple-checkbox>
                    </div>
                    <MeasureCount
                      v-else-if="col.measure"
                      :item="item"
                      :selectable="col.value === 'job_count'"
                      :measure="col.measure"
                      @click="selectListviewJob(item, true)"
                      @showDocList="openDocListMenu"
                    ></MeasureCount>
                    <div v-else-if="col.value === 'doc_status_text'">
                      <DocStatus :doc_id="item.doc_id" sideAddendum></DocStatus>
                    </div>
                    <div
                      v-else-if="item[col.value + 'isNode']"
                      class="col_header"
                    >
                      <cHierarchyNodeDisplay
                        :node="item[col.value + 'isNode']"
                        :listviewColumn="col"
                        :listviewItem="item"
                        :canDelete="canDelete"
                        @action="doMenuAction($event)"
                      ></cHierarchyNodeDisplay>
                    </div>
                    <div
                      v-else
                      class="col_header"
                      @contextmenu="openContextMenu($event, item, col)"
                    >
                      {{ item[col.value] }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div
              class="d-flex justify-space-between footer-actions primary-background"
            >
              <PageDescription
                :totalItems="groupedDocs.length"
                :pageSize="listView.itemsPerPage"
                :currentPage="listView.currentPage"
              />
              <Pagination
                :totalItems="groupedDocs.length"
                :pageSize="listView.itemsPerPage"
                :currentPage="listView.currentPage"
                @pageNavigation="listView.currentPage = $event"
              />
              <div>
                <v-btn
                  color="primary"
                  small
                  outlined
                  class="mx-2"
                  @click="exportLVToCSV"
                >
                  <span>Export</span>
                </v-btn>
                <v-menu offset-y v-if="listView.pageSize === 0" z-index="301">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!$loginState.readOnly"
                      outlined
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPageText }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-for="item in itemsPerPageOptions"
                      :key="item.value"
                      @click="setLVPageSize(item.value)"
                    >
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-if="haveData && selectedView.viewType === 'grouped'">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-for="(f, fi) in compareOptionsV.filter(
                    (x) => x.listValues && x.listValues.length
                  )"
                  :key="'f' + fi"
                >
                  <v-select
                    dense
                    hide-details
                    outlined
                    :items="f.listValues.map((x) => x.value)"
                    v-model="f.filterValues"
                    :label="f.name + ' Filter'"
                    @change="selectJDs"
                    multiple
                    clearable
                    :title="f.name + ' Filter'"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="item && index === 0"
                        class="blue--text text-caption"
                      >
                        ({{ f.filterValues.length }} selected)
                      </span>
                    </template></v-select
                  ></v-col
                >
                <v-col v-if="measureFilters.length">
                  <v-select
                    dense
                    hide-details
                    label="Similarity Filter"
                    outlined
                    :items="measureFilters"
                    return-object
                    item-text="title"
                    item-value="title"
                    @click:append="
                      measureFilter = measureFilters[0];
                      selectJDs();
                    "
                    v-model="measureFilter"
                    :append-icon="
                      !measureFilter || !measureFilter.type
                        ? null
                        : 'mdi-close-box-outline'
                    "
                    @change="selectJDs"
                  ></v-select
                ></v-col>
              </v-row>
              <v-row v-if="layout && layout.detailRow && filterList.length">
                <v-col>
                  <v-chip
                    small
                    v-for="(f, fi) in filterList"
                    :key="'f' + fi"
                    close
                    @click:close="removeFilter(f)"
                  >
                    {{ f.title }}</v-chip
                  ></v-col
                >
              </v-row>
              <v-row ref="columnHeadings" class="tableHeader">
                <v-col
                  v-for="(c, ci) in groupedViewLevelColumns[0]"
                  :key="'lc' + ci"
                  :cols="c.cols"
                  >{{ c.text }}</v-col
                >
              </v-row>
              <v-row v-for="(r1, r1i) in groupedViewDocs" :key="'r1_' + r1i">
                <v-col>
                  <v-row ref="rowHeading_1">
                    <v-col
                      v-for="(c, ci) in groupedViewLevelColumns[0]"
                      :key="'r1_' + r1i + 'c' + ci"
                      :cols="c.cols"
                    >
                      <div v-if="c.ht_id" @click="groupViewDrilldown(r1)">
                        <cHierarchyNodeDisplay
                          :node="r1.node"
                          :allowExport="true"
                          :allowExpand="true"
                          :hideDescriptor="true"
                          :canDelete="canDelete"
                          displayBorder
                          @action="doMenuAction($event)"
                        ></cHierarchyNodeDisplay>
                      </div>
                      <MeasureCount
                        v-else-if="c.measure"
                        :item="r1"
                        :selectable="c.value === 'job_count'"
                        :measure="c.measure"
                        @click="selectGroupedJob(r1)"
                        @showDocList="openDocListMenu"
                      ></MeasureCount>
                      <div v-else-if="r1.node.descriptor" style="width: 100%">
                        <div
                          style="cursor: pointer; width: 90%; float: left"
                          @click.stop="editHierarchyDescriptor(r1.node)"
                        >
                          {{
                            c.isDescriptor
                              ? r1.node.descriptor.description
                              : r1[c.value]
                          }}
                          <v-icon class="text-right" small>mdi-pencil</v-icon
                          ><v-spacer />
                        </div>
                        <v-icon
                          v-if="r1.node.descriptor.historyCount"
                          title="view history"
                          color="blue-grey-darken-3"
                          style="float: right; margin-right: 20px"
                          @click="getNodeHistory(r1.node)"
                          >mdi-history</v-icon
                        >
                      </div></v-col
                    >
                  </v-row>
                  <div
                    ref="rowHeading_1_detail_rows"
                    v-if="r1.show && r1.rows.length"
                  >
                    <v-row ref="detailColumnHeadings" class="flex-nowrap">
                      <v-col cols="3"></v-col>
                      <v-col
                        v-for="(c, ci) in groupedViewDetailColumns.filter(
                          (x) => !x.ht_id
                        )"
                        :key="'lcd' + ci"
                        :cols="c.cols"
                        class="tableHeader"
                        >{{ c.text }}</v-col
                      >
                    </v-row>
                    <v-row
                      v-for="(dr, dri) in r1.rows"
                      :key="'r1_' + r1i + 'dr_' + dri"
                      class="flex-nowrap"
                    >
                      <v-col></v-col>
                      <v-col
                        v-for="(dc, dci) in groupedViewDetailColumns.filter(
                          (x) => !x.ht_id
                        )"
                        :key="'r1_' + r1i + 'dr_' + dri + 'dc' + dci"
                      >
                        {{ dr[dc.value] }}</v-col
                      >
                    </v-row>
                  </div>
                  <div
                    ref="rowHeading_1_nodes"
                    v-else-if="r1.show && r1.nodes.length"
                  >
                    <v-row
                      ref="rowHeading_1_node"
                      v-for="(r2, r2i) in r1.nodes"
                      :key="'r1_' + r1i + 'r2_' + r2i"
                    >
                      <v-col>
                        <v-row>
                          <v-col
                            v-for="(dc, dci) in groupedViewLevelColumns[1]"
                            :key="'r1_' + r1i + 'r2_' + r2i + 'dc' + dci"
                            :cols="dc.cols"
                            :style="{ paddingLeft: dc.ht_id ? '40px' : '' }"
                          >
                            <div
                              v-if="dc.ht_id"
                              @click="groupViewDrilldown(r2)"
                            >
                              <cHierarchyNodeDisplay
                                :node="r2.node"
                                :allowExport="true"
                                :allowExpand="true"
                                :hideDescriptor="true"
                                :canDelete="canDelete"
                                displayBorder
                                @action="doMenuAction($event)"
                              ></cHierarchyNodeDisplay>
                            </div>
                            <MeasureCount
                              v-else-if="dc.measure"
                              :item="r2"
                              :selectable="dc.value === 'job_count'"
                              :measure="dc.measure"
                              @click="selectGroupedJob(r2)"
                              @showDocList="openDocListMenu"
                            ></MeasureCount>
                            <div
                              v-else-if="r2.node.descriptor"
                              style="width: 100%"
                            >
                              <div
                                style="cursor: pointer; width: 90%; float: left"
                                @click.stop="editHierarchyDescriptor(r2.node)"
                              >
                                {{
                                  dc.isDescriptor
                                    ? r2.node.descriptor.description
                                    : r2[dc.value]
                                }}
                                <v-icon class="text-right" small
                                  >mdi-pencil</v-icon
                                >
                              </div>
                              <v-icon
                                v-if="r2.node.descriptor.historyCount"
                                title="view history"
                                color="blue-grey-darken-3"
                                style="float: right; margin-right: 20px"
                                @click="getNodeHistory(r2.node)"
                                >mdi-history</v-icon
                              >
                            </div></v-col
                          >
                        </v-row>
                        <div
                          ref="rowHeading_2_detail_rows"
                          v-if="r2.show && r2.rows.length"
                        >
                          <v-row ref="detailColumnHeadings" class="flex-nowrap">
                            <v-col cols="3"></v-col>
                            <v-col
                              v-for="(c, ci) in groupedViewDetailColumns.filter(
                                (x) => !x.ht_id
                              )"
                              :key="'lcd' + ci"
                              :cols="c.cols"
                              class="tableHeader"
                              >{{ c.text }}</v-col
                            >
                          </v-row>
                          <v-row
                            v-for="(dr, dri) in r2.rows"
                            :key="'r1_' + r1i + 'r2_' + r2i + 'dr_' + dri"
                            class="flex-nowrap"
                          >
                            <v-col></v-col>
                            <v-col
                              v-for="(
                                dc, dci
                              ) in groupedViewDetailColumns.filter(
                                (x) => !x.ht_id
                              )"
                              :key="
                                'r1_' +
                                r1i +
                                'r2_' +
                                r2i +
                                'rd1_' +
                                dri +
                                'dc' +
                                dci
                              "
                            >
                              {{ dr[dc.value] }}</v-col
                            >
                          </v-row>
                        </div>
                        <div
                          ref="rowHeading_2_nodes"
                          v-else-if="r2.show && r2.nodes.length"
                        >
                          <v-row
                            ref="rowHeading_2_node"
                            v-for="(r3, r3i) in r2.nodes"
                            :key="'r1_' + r1i + 'r2_' + r2i + 'r3_' + r3i"
                          >
                            <v-col>
                              <v-row>
                                <v-col
                                  v-for="(
                                    dc, dci
                                  ) in groupedViewLevelColumns[2]"
                                  :key="
                                    'r1_' +
                                    r1i +
                                    'r2_' +
                                    r2i +
                                    'r3_' +
                                    r3i +
                                    'dc' +
                                    dci
                                  "
                                  :cols="dc.cols"
                                  :style="{
                                    paddingLeft: dc.ht_id ? '80px' : '',
                                  }"
                                >
                                  <div
                                    v-if="dc.ht_id"
                                    @click="groupViewDrilldown(r3)"
                                  >
                                    <cHierarchyNodeDisplay
                                      :node="r3.node"
                                      :allowExport="true"
                                      :allowExpand="true"
                                      :hideDescriptor="true"
                                      :canDelete="canDelete"
                                      displayBorder
                                      @action="doMenuAction($event)"
                                    ></cHierarchyNodeDisplay>
                                  </div>
                                  <MeasureCount
                                    v-else-if="dc.measure"
                                    :item="r3"
                                    :selectable="dc.value === 'job_count'"
                                    :measure="dc.measure"
                                    @click="selectGroupedJob(r3)"
                                    @showDocList="openDocListMenu"
                                  ></MeasureCount>
                                  <div
                                    v-else-if="r3.node.descriptor"
                                    style="width: 100%"
                                  >
                                    <div
                                      style="
                                        cursor: pointer;
                                        width: 90%;
                                        float: left;
                                      "
                                      @click.stop="
                                        editHierarchyDescriptor(r3.node)
                                      "
                                    >
                                      {{
                                        dc.isDescriptor
                                          ? r3.node.descriptor.description
                                          : r3[dc.value]
                                      }}
                                      <v-icon class="text-right" small
                                        >mdi-pencil</v-icon
                                      >
                                    </div>
                                    <v-icon
                                      v-if="r3.node.descriptor.historyCount"
                                      title="view history"
                                      color="blue-grey-darken-3"
                                      style="float: right; margin-right: 20px"
                                      @click="getNodeHistory(r3.node)"
                                      >mdi-history</v-icon
                                    >
                                  </div></v-col
                                >
                              </v-row>
                              <div
                                ref="rowHeading_3_detail_rows"
                                v-if="r3.show && r3.rows.length"
                              >
                                <v-row
                                  ref="detailColumnHeadings"
                                  class="flex-nowrap"
                                >
                                  <v-col cols="3"></v-col>
                                  <v-col
                                    v-for="(
                                      c, ci
                                    ) in groupedViewDetailColumns.filter(
                                      (x) => !x.ht_id
                                    )"
                                    :key="'lcd' + ci"
                                    :cols="c.cols"
                                    class="tableHeader"
                                    >{{ c.text }}</v-col
                                  >
                                </v-row>
                                <v-row
                                  v-for="(dr, dri) in r3.rows"
                                  :key="
                                    'r1_' +
                                    r1i +
                                    'r2_' +
                                    r2i +
                                    'r3_' +
                                    r3i +
                                    'dr_' +
                                    dri
                                  "
                                  class="flex-nowrap"
                                >
                                  <v-col cols="3"></v-col>
                                  <v-col
                                    v-for="(
                                      dc, dci
                                    ) in groupedViewDetailColumns.filter(
                                      (x) => !x.ht_id
                                    )"
                                    :key="
                                      'r1_' +
                                      r1i +
                                      'r2_' +
                                      r2i +
                                      'r3_' +
                                      r3i +
                                      'rd1_' +
                                      dri +
                                      'dc' +
                                      dci
                                    "
                                    :cols="dc.cols ? dc.cols : ''"
                                  >
                                    <MeasureCount
                                      v-if="dc.measure"
                                      :item="dr"
                                      :selectable="dc.value === 'job_count'"
                                      :measure="dc.measure"
                                      @click="selectGroupedJob(dr)"
                                      @showDocList="openDocListMenu"
                                    ></MeasureCount>
                                    <div v-else>
                                      {{ dr[dc.value] }}
                                    </div></v-col
                                  >
                                </v-row>
                              </div>
                              <div
                                ref="rowHeading_3_nodes"
                                v-else-if="r3.show && r3.nodes.length"
                              >
                                <v-row
                                  ref="rowHeading_2_node"
                                  v-for="(r4, r4i) in r3.nodes"
                                  :key="
                                    'r1_' +
                                    r1i +
                                    'r2_' +
                                    r2i +
                                    'r3_' +
                                    r3i +
                                    'r4_' +
                                    r4i
                                  "
                                >
                                  <v-col>
                                    <v-row>
                                      <v-col
                                        v-for="(
                                          dc, dci
                                        ) in groupedViewLevelColumns[3]"
                                        :key="
                                          'r1_' +
                                          r1i +
                                          'r2_' +
                                          r2i +
                                          'r3_' +
                                          r3i +
                                          'r4_' +
                                          r4i +
                                          'dc' +
                                          dci
                                        "
                                        :cols="dc.cols"
                                        :style="{
                                          paddingLeft: dc.ht_id ? '120px' : '',
                                        }"
                                      >
                                        <div
                                          v-if="dc.ht_id"
                                          @click="groupViewDrilldown(r4)"
                                        >
                                          <cHierarchyNodeDisplay
                                            :node="r4.node"
                                            :allowExport="true"
                                            :allowExpand="true"
                                            :hideDescriptor="true"
                                            :canDelete="canDelete"
                                            displayBorder
                                            @action="doMenuAction($event)"
                                          ></cHierarchyNodeDisplay>
                                        </div>
                                        <MeasureCount
                                          v-else-if="dc.measure"
                                          :item="r4"
                                          :selectable="dc.value === 'job_count'"
                                          :measure="dc.measure"
                                          @click="selectGroupedJob(r4)"
                                          @showDocList="openDocListMenu"
                                        ></MeasureCount>
                                        <div
                                          v-else-if="r4.node.descriptor"
                                          style="width: 100%"
                                        >
                                          <div
                                            style="
                                              cursor: pointer;
                                              width: 90%;
                                              float: left;
                                            "
                                            @click.stop="
                                              editHierarchyDescriptor(r4.node)
                                            "
                                          >
                                            {{
                                              dc.isDescriptor
                                                ? r4.node.descriptor.description
                                                : r4[dc.value]
                                            }}
                                            <v-icon class="text-right" small
                                              >mdi-pencil</v-icon
                                            >
                                          </div>
                                          <v-icon
                                            v-if="
                                              r4.node.descriptor.historyCount
                                            "
                                            title="view history"
                                            color="blue-grey-darken-3"
                                            style="
                                              float: right;
                                              margin-right: 20px;
                                            "
                                            @click="getNodeHistory(r4.node)"
                                            >mdi-history</v-icon
                                          >
                                        </div></v-col
                                      >
                                    </v-row>
                                    <div
                                      ref="rowHeading_4_detail_rows"
                                      v-if="r4.show && r4.rows.length"
                                    >
                                      <v-row
                                        ref="detailColumnHeadings"
                                        class="flex-nowrap"
                                      >
                                        <v-col cols="3"></v-col>
                                        <v-col
                                          v-for="(
                                            c, ci
                                          ) in groupedViewDetailColumns.filter(
                                            (x) => !x.ht_id
                                          )"
                                          :key="'lcd' + ci"
                                          :cols="c.cols"
                                          class="tableHeader"
                                          >{{ c.text }}</v-col
                                        >
                                      </v-row>
                                      <v-row
                                        v-for="(dr, dri) in r4.rows"
                                        :key="
                                          'r1_' +
                                          r1i +
                                          'r2_' +
                                          r2i +
                                          'r3_' +
                                          r3i +
                                          'r4_' +
                                          r4i +
                                          'dr_' +
                                          dri
                                        "
                                        class="flex-nowrap"
                                      >
                                        <v-col cols="3"></v-col>
                                        <v-col
                                          v-for="(
                                            dc, dci
                                          ) in groupedViewDetailColumns.filter(
                                            (x) => !x.ht_id
                                          )"
                                          :key="
                                            'r1_' +
                                            r1i +
                                            'r2_' +
                                            r2i +
                                            'r3_' +
                                            r3i +
                                            'r4_' +
                                            r4i +
                                            'rd1_' +
                                            dri +
                                            'dc' +
                                            dci
                                          "
                                          :cols="dc.cols ? dc.cols : ''"
                                        >
                                          <MeasureCount
                                            v-if="dc.measure"
                                            :item="dr"
                                            :selectable="
                                              dc.value === 'job_count'
                                            "
                                            :measure="dc.measure"
                                            @click="selectGroupedJob(dr)"
                                            @showDocList="openDocListMenu"
                                          ></MeasureCount>
                                          <div v-else>
                                            {{ dr[dc.value] }}
                                          </div></v-col
                                        >
                                      </v-row>
                                    </div>
                                    <v-div
                                      ref="rowHeading_4_nodes"
                                      v-else-if="r4.show && r4.nodes.length"
                                    >
                                    </v-div>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-if="haveData && selectedView.viewType === 'catalogue'">
          <v-card-text>
            <v-row>
              <v-col>
                <v-row v-if="layout && layout.detailRow && filterList.length">
                  <v-col>
                    <v-chip
                      small
                      v-for="(f, fi) in filterList"
                      :key="'f' + fi"
                      close
                      @click:close="removeFilter(f)"
                    >
                      {{ f.title }}</v-chip
                    ></v-col
                  >
                </v-row>
                <v-row v-if="layout && layout.headerRow" class="flex-nowrap">
                  <v-col :cols="layout.leftPanel.cols"
                    ><v-row
                      ><v-col
                        :cols="
                          layout.leftPanel.colsHierarchy +
                          layout.leftPanel.colsTitle
                        "
                      >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="columnPicker"
                              v-bind="attrs"
                              v-on="on"
                              :title="layout.leftPanel.text"
                            >
                              {{ layout.leftPanel.text
                              }}<v-icon small right> mdi-chevron-down </v-icon>
                            </div>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(co, coi) in compareOptionsV.filter(
                                (x) => !x.hidden
                              )"
                              :key="'co' + coi"
                              @click="setVerticalCompareOption(co)"
                            >
                              <v-list-item-title>{{
                                co.name
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu></v-col
                      ><v-col
                        v-if="layout.leftPanel.colsDescriptor"
                        :cols="layout.leftPanel.colsDescriptor"
                        >Descriptor</v-col
                      ><v-col :cols="layout.leftPanel.colsTotal"
                        >Totals</v-col
                      ></v-row
                    ><v-row
                      ><v-col
                        :cols="
                          layout.leftPanel.colsHierarchy +
                          layout.leftPanel.colsTitle
                        "
                      ></v-col
                      ><v-col
                        v-if="layout.leftPanel.colsDescriptor"
                        :cols="layout.leftPanel.colsDescriptor"
                      ></v-col
                      ><v-col :cols="layout.leftPanel.colsTotal"
                        ><v-row dense justify="space-between"
                          ><v-col
                            v-if="display.showLeafCount"
                            class="measure-column"
                            :cols="display.total_col.colWidthLeafCount"
                            ><div
                              class="leaf_node_count"
                              :title="display.total_col.leafCountTitle"
                            >
                              {{ display.total_col.leafCountLabel }}
                            </div></v-col
                          ><v-col
                            v-for="(m, mi) in visibleMeasures"
                            :key="'mh' + mi"
                            :cols="m.total_col_width"
                            class="measure-column"
                            ><MeasureCount :measure="m" isTitle></MeasureCount
                          ></v-col> </v-row></v-col></v-row
                  ></v-col>
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                    ><v-row
                      ><v-col style="text-align: center"
                        ><div class="columnPicker">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                {{ layout.headerRow.text
                                }}<v-icon small right>
                                  mdi-chevron-down
                                </v-icon>
                              </span>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(co, coi) in compareOptionsH.filter(
                                  (x) => !x.hidden
                                )"
                                :key="'co' + coi"
                                @click="setHorizontalCompareOption(co)"
                              >
                                <v-list-item-title>{{
                                  co.name
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-col></v-row
                    ><v-row
                      v-for="(hr, hri) in layout.hierarchyHeaderRows"
                      :key="'hr' + hri"
                      style="overflow: hidden; margin-top: 0"
                      :ref="hr.ref"
                      class="flex-nowrap"
                      ><v-col
                        v-for="(c, ci) in hr.columns"
                        :key="'hr' + hri + 'c' + ci"
                        :cols="c.cols"
                        :class="c.class"
                        :style="{
                          minWidth: c.cols * 150 + 'px',
                          paddingBottom: '0px',
                        }"
                      >
                        <div style="position: relative">
                          <div
                            v-if="hri > 0"
                            style="
                              margin-left: 50%;
                              border-left: 1px solid grey;
                              height: 10px;
                              width: 1px;
                              position: relative;
                              top: 0;
                            "
                          ></div>
                          <cHierarchyNodeDisplay
                            v-if="c.node"
                            :node="c.node"
                            :canDelete="canDelete"
                            @action="doMenuAction($event)"
                          ></cHierarchyNodeDisplay>
                          <div
                            style="
                              margin-left: 50%;
                              border-left: 1px solid grey;
                              height: 10px;
                              width: 1px;
                            "
                          ></div>
                          <div
                            v-if="c.childsets && c.childsets.length > 1"
                            :style="{
                              marginLeft: c.lineMarginLeft,
                              borderTop: '1px solid grey',
                              height: '1px',
                              width: c.lineWidth,
                            }"
                          ></div></div></v-col
                    ></v-row>
                    <v-row
                      v-for="(hr, hri) in layout.groupHeaderRow.hRows"
                      :key="'ghrh' + hri"
                      :ref="hr.ref"
                      style="overflow: hidden"
                      class="flex-nowrap"
                      dense
                      ><v-col :style="{ paddingLeft: 20 * hri + 'px' }">
                        <div class="rowHeader">
                          <v-icon
                            small
                            dense
                            @click="
                              removeGroupHeader(hr, 'horizontalCompareOption')
                            "
                            >mdi-close-circle</v-icon
                          >{{ hr.value }}
                        </div></v-col
                      ></v-row
                    >
                    <v-row
                      :style="{
                        overflow: 'hidden',
                        marginTop:
                          layout.hierarchyHeaderRows.length ||
                          layout.groupHeaderRow.hRows.length
                            ? '0'
                            : '',
                      }"
                      :ref="layout.headerRow.ref"
                      class="flex-nowrap"
                      ><v-col
                        v-for="(c, ci) in layout.headerRow.columns"
                        :key="'c' + ci"
                        :cols="c.cols"
                        :class="c.class"
                        :style="{
                          minWidth: c.cols * 150 + 'px',
                          paddingLeft:
                            ci === 0 && layout.groupHeaderRow.hRows.length
                              ? 20 * layout.groupHeaderRow.hRows.length + 'px'
                              : '',
                        }"
                      >
                        <div
                          v-if="c.isDocument"
                          class="document_cell"
                          @click="openDocumentID(c.value)"
                          @contextmenu="openDocActionsMenuContext($event, c)"
                          :title="'Click to open ' + catalogueDocType"
                        >
                          <span :title="c.title">{{ c.title }}</span
                          ><v-badge
                            color="red"
                            v-if="c.match_count && 1 === 0"
                            :content="c.match_count"
                            offset-y="10"
                          >
                            <v-icon
                              class="float-fight"
                              color="blue"
                              small
                              title="click to compare similar docments"
                              @click="showMatches(c)"
                              >mdi-content-duplicate</v-icon
                            >
                          </v-badge>
                        </div>
                        <v-checkbox
                          v-else-if="c.isSelectable"
                          v-model="c.selected"
                          :label="c.title"
                          style="margin-top: 0; text-align: left"
                          @click="selectJob(c)"
                        >
                        </v-checkbox>
                        <div
                          v-else-if="layout.hierarchyHeaderRows.length"
                          style="position: relative"
                        >
                          <div
                            style="
                              margin-left: 50%;
                              border-left: 1px solid grey;
                              height: 10px;
                              width: 1px;
                              position: relative;
                              top: 0;
                            "
                          ></div>
                          <cHierarchyNodeDisplay
                            v-if="c.node"
                            :node="c.node"
                            :canDelete="canDelete"
                            @action="doMenuAction($event)"
                          ></cHierarchyNodeDisplay>
                        </div>
                        <cHierarchyNodeDisplay
                          v-else-if="c.node"
                          :node="c.node"
                          :canDelete="canDelete"
                          displayBorder
                          @action="
                            doMenuAction($event, c, 'horizontalCompareOption')
                          "
                        ></cHierarchyNodeDisplay>
                        <div class="rowHeader" v-else :title="c.title">
                          {{ c.title }}
                        </div>
                      </v-col></v-row
                    ></v-col
                  >
                </v-row>
                <v-row
                  v-if="layout && layout.groupHeaderRow.vRows.length"
                  ref="groupHeaderRow"
                  style="border-bottom: 1px solid gray"
                >
                  <v-col :cols="layout.leftPanel.cols">
                    <v-row
                      v-for="(r, ri) in layout.groupHeaderRow.vRows"
                      :key="'gr' + ri"
                    >
                      <v-col
                        :cols="layout.leftPanel.colsTitle"
                        :style="{ paddingLeft: 20 * ri + 'px' }"
                      >
                        <div
                          class="rowHeader"
                          :title="r.name + ' - ' + r.value"
                        >
                          <v-icon
                            small
                            dense
                            @click="
                              removeGroupHeader(r, 'verticalCompareOption')
                            "
                            >mdi-close-circle</v-icon
                          >
                          {{ r.value }}
                        </div>
                      </v-col>
                      <v-col
                        v-if="layout.leftPanel.colsDescriptor"
                        :cols="layout.leftPanel.colsDescriptor"
                      >
                        <div class="rowHeader">{{ r.column }}</div>
                      </v-col>
                      <v-col :cols="layout.leftPanel.colsTotal">
                        <v-row justify="space-between" dense
                          ><v-col
                            v-if="display.showLeafCount"
                            class="measure-column"
                            :cols="display.total_col.colWidthLeafCount"
                            ><div class="leaf_node_count">
                              {{ r.leaf_node_count }}
                            </div></v-col
                          ><v-col
                            v-for="(m, mi) in visibleMeasures"
                            :key="'mh' + mi"
                            :cols="m.total_col_width"
                            class="measure-column"
                            ><MeasureCount
                              :item="r"
                              :measure="m"
                              :selectable="m.type === 'job_count'"
                              @click="selectJob(r)"
                              @showDocList="openDocListMenu"
                            ></MeasureCount
                          ></v-col> </v-row
                      ></v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                  >
                    <v-row
                      v-for="(r, ri) in layout.groupHeaderRow.vRows"
                      :key="'grd' + ri"
                      class="flex-nowrap"
                      :ref="layout.groupHeaderRow.ref"
                      style="overflow: hidden"
                    >
                      <v-col
                        v-for="(c, ci) in r.columns.filter((x) => x.visible)"
                        :key="'grd' + ri + 'c' + ci"
                        :cols="c.cols"
                        :style="{ minWidth: c.cols * 150 + 'px' }"
                      >
                        <v-row dense>
                          <v-col
                            v-if="showDetail && display.z_col.colWidthLabel"
                            :cols="display.z_col.colWidthLabel"
                          ></v-col>
                          <v-col
                            v-for="(m, mi) in visibleMeasures"
                            :key="'grd' + ri + 'c' + ci + 'm' + mi"
                            :cols="m.h_col_width"
                            class="measure-column"
                          >
                            <MeasureCount
                              :item="c"
                              :measure="m"
                              :selectable="m.type === 'job_count'"
                              @click="selectJob(c)"
                              @showDocList="openDocListMenu"
                            ></MeasureCount
                          ></v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="layout && layout.detailRow"
                  style="height: 500px"
                  class="flex-nowrap"
                  @wheel.stop="wheelVScroll"
                  ref="detailRow"
                >
                  <v-col :cols="layout.leftPanel.cols">
                    <v-row>
                      <v-col
                        v-if="layout.detailRow.hierarchyHeadings"
                        :cols="layout.leftPanel.colsHierarchy"
                      >
                        <v-row
                          v-for="(l, li) in layout.detailRow.hierarchyHeadings
                            .pageLevels"
                          :key="'l' + li"
                          class="flex-nowrap"
                        >
                          <v-col
                            :cols="l.cols"
                            class="d-flex"
                            :style="{
                              height: l.height,
                            }"
                          >
                            <cHierarchyNodeDisplay
                              v-if="l.node"
                              :node="l.node"
                              :canDelete="canDelete"
                              displayBorder
                              @action="doMenuAction($event)"
                            ></cHierarchyNodeDisplay>
                            <div
                              v-if="l.levels && l.levels.length"
                              style="width: 1px; position: relative; top: 0"
                            >
                              <svg width="12" height="3">
                                <line
                                  x1="0"
                                  y1="0"
                                  x2="12"
                                  y2="0"
                                  style="stroke: black; stroke-width: 2"
                                />
                              </svg>
                            </div>
                          </v-col>
                          <v-col v-if="l.levels.length" :cols="l.childCols">
                            <v-row
                              v-for="(l1, l1i) in l.levels"
                              :key="'l' + li + 'l1' + l1i"
                              class="flex-nowrap"
                            >
                              <v-col
                                :cols="l1.cols"
                                class="d-flex"
                                :style="{
                                  height: l1.height,
                                }"
                              >
                                <div
                                  style="
                                    width: 2px;
                                    height: calc(100% + 24px);
                                    position: relative;
                                    left: -24px;
                                    top: -12px;
                                  "
                                >
                                  <svg width="24" height="100%">
                                    <line
                                      :x1="l1.isFirst ? 0 : 12"
                                      y1="24"
                                      x2="24"
                                      y2="24"
                                      style="stroke: black; stroke-width: 2"
                                    />
                                    <line
                                      v-if="l1.vLineY2"
                                      x1="12"
                                      :y1="l1.vLineY1"
                                      x2="12"
                                      :y2="l1.vLineY2"
                                      style="stroke: black; stroke-width: 2"
                                    />
                                  </svg>
                                </div>
                                <cHierarchyNodeDisplay
                                  v-if="l1.node"
                                  :node="l1.node"
                                  :canDelete="canDelete"
                                  displayBorder
                                  @action="doMenuAction($event)"
                                ></cHierarchyNodeDisplay
                              ></v-col>
                              <v-col
                                v-if="l1.levels.length"
                                :cols="l1.childCols"
                              >
                                <v-row
                                  v-for="(l2, l2i) in l1.levels"
                                  :key="'l' + li + 'l1' + l1i + 'l2' + l2i"
                                  :class="l2.class"
                                >
                                  <v-col
                                    :cols="l2.cols"
                                    class="d-flex"
                                    :style="{
                                      height: l2.height,
                                    }"
                                  >
                                    <div
                                      style="
                                        width: 2px;
                                        height: calc(100% + 24px);
                                        position: relative;
                                        left: -24px;
                                        top: -12px;
                                      "
                                    >
                                      <svg width="24" height="100%">
                                        <line
                                          :x1="l2.isFirst ? 0 : 12"
                                          y1="24"
                                          x2="24"
                                          y2="24"
                                          style="stroke: black; stroke-width: 2"
                                        />
                                        <line
                                          v-if="l2.vLineY2"
                                          x1="12"
                                          :y1="l2.vLineY1"
                                          x2="12"
                                          :y2="l2.vLineY2"
                                          style="stroke: black; stroke-width: 2"
                                        />
                                      </svg>
                                    </div>
                                    <cHierarchyNodeDisplay
                                      v-if="l2.node"
                                      :node="l2.node"
                                      :canDelete="canDelete"
                                      displayBorder
                                      @action="doMenuAction($event)"
                                    ></cHierarchyNodeDisplay
                                  ></v-col>
                                  <v-col
                                    v-if="l2.levels.length"
                                    :cols="l2.childCols"
                                  >
                                    <v-row
                                      v-for="(l3, l3i) in l2.levels"
                                      :key="
                                        'l' +
                                        li +
                                        'l1' +
                                        l1i +
                                        'l2' +
                                        l2i +
                                        'l3' +
                                        l3i
                                      "
                                      class="flex-nowrap"
                                    >
                                      <v-col
                                        :cols="l3.cols"
                                        class="d-flex"
                                        :style="{
                                          height: l3.height,
                                        }"
                                      >
                                        <div
                                          style="
                                            width: 2px;
                                            height: calc(100% + 24px);
                                            position: relative;
                                            left: -24px;
                                            top: -12px;
                                          "
                                        >
                                          <svg width="24" height="100%">
                                            <line
                                              :x1="l3.isFirst ? 0 : 12"
                                              y1="24"
                                              x2="24"
                                              y2="24"
                                              style="
                                                stroke: black;
                                                stroke-width: 2;
                                              "
                                            />
                                            <line
                                              v-if="l3.vLineY2"
                                              x1="12"
                                              :y1="l3.vLineY1"
                                              x2="12"
                                              :y2="l3.vLineY2"
                                              style="
                                                stroke: black;
                                                stroke-width: 2;
                                              "
                                            />
                                          </svg>
                                        </div>
                                        <cHierarchyNodeDisplay
                                          v-if="l3.node"
                                          :node="l3.node"
                                          :canDelete="canDelete"
                                          displayBorder
                                          @action="doMenuAction($event)"
                                        ></cHierarchyNodeDisplay
                                      ></v-col>
                                      <v-col :cols="l3.childCols"></v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        ><v-row
                          v-for="(h, hi) in layout.detailRow.rowHeadings.filter(
                            (x) =>
                              x.dIndexStart <= vScroll.dEnd &&
                              x.dIndexEnd >= vScroll.dStart
                          )"
                          :key="'h' + hi"
                        >
                          <v-col
                            v-if="!layout.detailRow.hierarchyHeadings"
                            :class="h.class"
                            :cols="layout.leftPanel.colsTitle"
                            :style="{
                              paddingLeft:
                                20 * layout.groupHeaderRow.vRows.length + 'px',
                            }"
                          >
                            <div
                              v-if="h.isDocument"
                              class="document_cell"
                              @click="openDocumentID(h.value)"
                              @contextmenu="
                                openDocActionsMenuContext($event, h)
                              "
                              :title="'Click to open ' + catalogueDocType"
                            >
                              <span :title="h.title">{{ h.title }}</span
                              ><v-badge
                                color="red"
                                v-if="h.match_count && 1 === 0"
                                :content="h.match_count"
                                offset-y="10"
                              >
                                <v-icon
                                  class="float-fight"
                                  color="blue"
                                  small
                                  title="click to compare similar docments"
                                  @click="showMatches(h)"
                                  >mdi-content-duplicate</v-icon
                                >
                              </v-badge>
                            </div>
                            <v-checkbox
                              v-else-if="h.isSelectable"
                              :label="h.title"
                              v-model="h.selected"
                              style="margin-top: 0; text-align: left"
                              @click="selectJob(h)"
                            >
                            </v-checkbox>
                            <cHierarchyNodeDisplay
                              v-else-if="h.node"
                              :node="h.node"
                              :canDelete="canDelete"
                              displayBorder
                              @action="
                                doMenuAction($event, h, 'verticalCompareOption')
                              "
                            ></cHierarchyNodeDisplay>
                            <div class="rowHeader" :title="h.title" v-else>
                              {{ h.title }}
                            </div>
                          </v-col>
                          <v-col
                            v-if="layout.leftPanel.colsDescriptor"
                            :cols="layout.leftPanel.colsDescriptorDetail"
                          >
                            <div
                              :class="setDescriptorHeight(h)"
                              @click.stop="editHierarchyDescriptor(h.node)"
                              :title="
                                h.node.descriptor
                                  ? h.node.descriptor.description
                                  : ''
                              "
                            >
                              {{
                                h.node.descriptor
                                  ? h.node.descriptor.description
                                  : ""
                              }}
                            </div></v-col
                          >
                          <v-col :cols="layout.leftPanel.colsTotalDetail">
                            <v-row
                              dense
                              justify="space-between"
                              v-if="
                                h.dIndexStart <= vScroll.dEnd &&
                                h.dIndexStart >= vScroll.dStart
                              "
                            >
                              <v-col
                                v-if="display.showLeafCount"
                                :cols="display.total_col.colWidthLeafCount"
                                class="measure-column"
                                ><div class="leaf_node_count">
                                  {{ h.leaf_node_count }}
                                </div></v-col
                              >
                              <v-col
                                v-for="(m, mi) in visibleMeasures"
                                :cols="m.total_col_width"
                                :key="'h' + hi + 'm' + mi"
                                class="measure-column"
                              >
                                <MeasureCount
                                  :item="h"
                                  :measure="m"
                                  :selectable="m.type === 'job_count'"
                                  @click="selectJob(h)"
                                  @showDocList="openDocListMenu"
                                ></MeasureCount
                              ></v-col>
                            </v-row>
                            <v-row
                              dense
                              justify="space-between"
                              v-for="(g, gi) in h.categories.filter(
                                (x) =>
                                  x &&
                                  x.dIndex >= vScroll.dStart &&
                                  x.dIndex <= vScroll.dEnd
                              )"
                              :key="'h' + hi + 'g' + gi"
                            >
                              <v-col
                                v-if="display.total_col.colWidthLeafCount"
                                :cols="display.total_col.colWidthLeafCount"
                              ></v-col>
                              <v-col
                                v-for="(m, mi) in visibleMeasures.filter(
                                  (m) => m.z_col_width
                                )"
                                :cols="m.total_col_width"
                                :key="'h' + hi + 'g' + gi + 'm' + mi"
                                class="measure-column"
                              >
                                <MeasureCount
                                  v-if="m.z_show"
                                  :item="g"
                                  :measure="m"
                                  :backColour="g.colour"
                                  :selectable="m.type === 'job_count'"
                                  @click="selectJob(g)"
                                  @showDocList="openDocListMenu"
                                ></MeasureCount>
                                <span v-else class="label_cell">
                                  {{ g.title }}
                                </span></v-col
                              >
                            </v-row>
                            <v-row
                              dense
                              v-for="(g, gi) in h.detailCountHeader.filter(
                                (x) =>
                                  x.dIndex >= vScroll.dStart &&
                                  x.dIndex <= vScroll.dEnd
                              )"
                              :key="'h' + hi + 'g' + gi"
                            >
                              <v-col cols="12" class="measure-column">
                                <div class="label_cell">
                                  {{ " " }}
                                </div></v-col
                              >
                            </v-row>
                          </v-col></v-row
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                    ><v-row style="overflow: hidden" ref="detailH"
                      ><v-col>
                        <v-row
                          class="flex-nowrap"
                          style="min-height: 50px"
                          v-for="(d, di) in layout.detailRow.rowDetails.filter(
                            (x) =>
                              x.dIndexStart <= vScroll.dEnd &&
                              x.dIndexEnd >= vScroll.dStart
                          )"
                          :key="'d' + di"
                        >
                          <v-col
                            v-for="(c, ci) in d.columns"
                            :key="'d' + di + 'c' + ci"
                            :cols="c.cols"
                            :style="{ minWidth: 150 * c.cols + 'px' }"
                          >
                            <v-row
                              dense
                              v-if="
                                d.dIndexStart <= vScroll.dEnd &&
                                d.dIndexStart >= vScroll.dStart &&
                                c.job_count &&
                                (!showDetail || !display.z_col.showLabel)
                              "
                            >
                              <v-col
                                v-for="(m, mi) in visibleMeasures.filter(
                                  (x) => x.h_col_width
                                )"
                                :key="'d' + di + 'c' + ci + 'm' + mi"
                                :cols="m.h_col_width"
                                class="measure-column"
                              >
                                <MeasureCount
                                  :item="c"
                                  :measure="m"
                                  :selectable="m.type === 'job_count'"
                                  @click="selectJob(c)"
                                  @showDocList="openDocListMenu"
                                ></MeasureCount
                              ></v-col>
                            </v-row>
                            <v-row
                              dense
                              v-for="(g, gi) in c.categories.filter(
                                (x) =>
                                  x &&
                                  showDetail &&
                                  x.dIndex >= vScroll.dStart &&
                                  x.dIndex <= vScroll.dEnd
                              )"
                              :key="'d' + di + 'c' + ci + 'g' + gi"
                            >
                              <v-col
                                v-if="display.z_col.colWidthLabel"
                                :cols="display.z_col.colWidthLabel"
                              >
                                <div
                                  v-if="g.isDocument"
                                  class="document_cell"
                                  @click="openDocumentID(g.value)"
                                  @contextmenu="
                                    openDocActionsMenuContext($event, g)
                                  "
                                  :title="'Click to open ' + catalogueDocType"
                                >
                                  <span :title="g.title">{{ g.title }} </span
                                  ><v-badge
                                    color="red"
                                    v-if="g.match_count && 1 === 0"
                                    :content="g.match_count"
                                    offset-y="10"
                                  >
                                    <v-icon
                                      class="float-fight"
                                      color="blue"
                                      small
                                      title="click to compare similar docments"
                                      @click="showMatches(g)"
                                      >mdi-content-duplicate</v-icon
                                    >
                                  </v-badge>
                                </div>
                                <v-checkbox
                                  v-else-if="g.isSelectable"
                                  v-model="g.selected"
                                  class="label_cell"
                                  :label="g.title"
                                  style="margin-top: 0"
                                  @click="selectJob(g)"
                                >
                                </v-checkbox>
                                <div v-else class="label_cell">
                                  <span>{{ g.value }}</span>
                                </div></v-col
                              >
                              <v-col
                                v-for="(m, mi) in visibleMeasures.filter(
                                  (m) => m.z_col_width
                                )"
                                :cols="m.z_col_width"
                                :key="'d' + di + 'c' + ci + 'g' + gi + 'm' + mi"
                                class="measure-column"
                              >
                                <MeasureCount
                                  v-if="m.z_show"
                                  :item="g"
                                  :measure="m"
                                  :backColour="g.colour"
                                  :selectable="m.type === 'job_count'"
                                  @click="selectJob(g)"
                                  @showDocList="openDocListMenu"
                                ></MeasureCount
                              ></v-col>
                            </v-row> </v-col></v-row></v-col></v-row
                  ></v-col>

                  <v-col
                    style="
                      overflow: auto;
                      padding: 12px 0px;
                      height: 100%;
                      margin-left: -10px;
                    "
                    ref="scrollContainer"
                    @scroll="setVScroll_"
                  >
                    <!-- -->
                    <div ref="scrollBar" style="height: 20px; width: 1px"></div>
                  </v-col>
                </v-row>
                <v-row v-if="layout && layout.headerRow" class="flex-nowrap">
                  <v-col :cols="layout.leftPanel.cols" class="scrollCol"
                    ><v-row><v-col class="scrollCol"></v-col></v-row
                  ></v-col>
                  <v-col
                    v-if="layout.rightPanel.cols"
                    :cols="layout.rightPanel.cols"
                    class="scrollCol"
                  >
                    <v-row
                      style="overflow: auto"
                      ref="colfoot"
                      class="flex-nowrap"
                      @scroll="setHScroll"
                      ><v-col
                        v-for="(c, ci) in layout.headerRow.columns"
                        :key="'cf' + ci"
                        :cols="c.cols"
                        class="scrollCol"
                        :style="{ minWidth: 150 * c.cols + 'px' }"
                      ></v-col></v-row
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="selectedView.viewType === 'audit'">
          <v-card-title>{{ nodeHistoryReport.label }}</v-card-title>
          <v-card-actions>
            <v-row dense>
              <v-col>
                <DateRangePicker
                  :DateRange="nodeHistoryReport.dateRangeFilter"
                  label="Items Updated Between"
                  @updated="filterAuditReportDateRange"
                ></DateRangePicker>
              </v-col>
              <v-col>
                <v-select
                  multiple
                  outlined
                  dense
                  hide-details
                  label="Items Updated By"
                  v-model="nodeHistoryReport.userFilter"
                  clearable
                  :items="nodeHistoryReport.userList"
                  @change="filterAuditReport()"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(true)"
                  ><v-icon>mdi-eye</v-icon> Show All Changes</v-btn
                >
              </v-col>
              <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(false)"
                  ><v-icon>mdi-eye-off</v-icon> Hide All Changes</v-btn
                >
              </v-col>
            </v-row></v-card-actions
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <HierarchyTreeNodeAudit
                    :nodes="nodeHistoryReport.nodes"
                    :levels="4"
                  ></HierarchyTreeNodeAudit>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-if="selectedView.viewType === 'audit_data'">
          <v-card-title>{{ nodeAuditData.label }}</v-card-title>
          <v-card-actions>
            <v-row dense>
              <v-col>
                <DateRangePicker
                  :DateRange="nodeAuditData.dateRangeFilter"
                  label="Updates Between"
                  @updated="filterAuditDataDateRange"
                ></DateRangePicker>
              </v-col>
              <v-col>
                <v-select
                  multiple
                  outlined
                  dense
                  hide-details
                  label="Updates By"
                  v-model="nodeAuditData.userFilter"
                  clearable
                  :items="nodeAuditData.userList"
                  @change="filterAuditData()"
                ></v-select>
              </v-col>
              <!-- <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(true)"
                  ><v-icon>mdi-eye</v-icon> Show All Changes</v-btn
                >
              </v-col>
              <v-col cols="2">
                <v-btn dense @click="showAuditReportChanges(false)"
                  ><v-icon>mdi-eye-off</v-icon> Hide All Changes</v-btn
                >
              </v-col> -->
            </v-row></v-card-actions
          >
          <v-card-text v-if="nodeAuditData.data">
            <v-data-table
              :headers="nodeAuditData.headers"
              :items="nodeAuditData.items"
              single-expand
              :expanded.sync="nodeAuditData.expanded"
              item-key="hierarchy_transaction_id"
              show-expand
              class="elevation-1"
              @click:row="selectAuditRow"
            >
              <template v-slot:[`item.transaction_description`]="{ item }">
                <div
                  v-html="getAuditDescriptionHTML(item.transaction_description)"
                ></div>
              </template>
              <template v-slot:[`item.user_name`]="{ item }">
                <v-chip dark small :title="item.user_email">
                  {{ item.user_name }}
                </v-chip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-container>
                    <v-row
                      dense
                      v-for="(n, ni) in item.nodes_affected"
                      :key="'t' + item.hierarchy_transaction_id + 'n' + ni"
                    >
                      <v-col cols="1">-</v-col>
                      <v-col :cols="n.difference ? 6 : 11"
                        ><div
                          v-html="getAuditDescriptionHTML(n.effect_description)"
                        ></div>
                      </v-col>
                      <v-col v-if="n.difference"
                        ><div v-html="n.difference"></div
                      ></v-col> </v-row
                  ></v-container>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card v-if="selectedView.viewType === 'reviews'">
          <v-card-title>Reviews</v-card-title>
          <v-card-text>
            <v-container v-for="(t, ti) in reviewDocs" :key="'t' + ti">
              <v-row
                ><v-col
                  ><h3 @click="t.show = !t.show">
                    {{ t.type
                    }}<v-badge :content="t.docs.length">
                      <v-icon>{{
                        t.show ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></v-badge
                    >
                  </h3></v-col
                ></v-row
              >
              <div v-if="t.show">
                <v-row v-for="(r, ri) in t.docs" :key="'t' + ti + 'd' + ri">
                  <v-col
                    style="
                      border: solid 1px gray;
                      border-radius: 10px;
                      background-color: #eceff1;
                    "
                    class="mb-4"
                  >
                    <v-row @click="r.showNextLevel = !r.showNextLevel">
                      <v-col cols="2"
                        ><v-btn outlined small @click="openDocument(r)"
                          ><v-icon>mdi-file-eye</v-icon>
                          {{ r.created_date }}</v-btn
                        >
                      </v-col>
                      <v-col cols="2">
                        {{ r.nodesParent[0].level_name }}
                      </v-col>
                      <v-col>
                        {{ r.nodesParent[0].hierarchy_node_name }}
                      </v-col>
                      <v-col cols="1"
                        ><v-menu
                          v-model="r.showComments"
                          :close-on-content-click="false"
                          :nudge-width="400"
                          offset-x
                          v-if="r.overall_comment_count"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              title="View Comments"
                              v-on="on"
                              >mdi-comment-alert</v-icon
                            >
                          </template>
                          <v-card>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >{{
                                      r.nodesParent[0].level_name
                                    }}
                                    Comments</v-list-item-title
                                  >
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-icon @click="r.showComments = false"
                                    >mdi-close-thick</v-icon
                                  >
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-content>
                                  <div style="height: 100%; position: relative">
                                    <div
                                      style="
                                        overflow-y: scroll;
                                        max-height: 200px;
                                      "
                                    >
                                      <DocumentComments
                                        :document="r"
                                        hideHeader
                                      ></DocumentComments>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col cols="2">
                        {{
                          r.nodesMain.length +
                          " " +
                          utils.pluralise(r.nodesMain[0].level_name)
                        }}
                      </v-col>
                      <v-col cols="1">
                        <v-icon>{{
                          r.showNextLevel
                            ? "mdi-chevron-up"
                            : "mdi-chevron-down"
                        }}</v-icon>
                      </v-col>
                    </v-row>
                    <v-row v-if="r.showNextLevel">
                      <v-col cols="2">
                        <div style="padding-left: 15px">
                          <div
                            v-for="(a, ai) in r.roles"
                            :key="'rd' + r.doc_id + 'r' + ai"
                          >
                            {{
                              a.role_type +
                              (a.role_users.length > 1 ? "s" : "")
                            }}:
                            <div
                              class="review-user"
                              v-for="(c, ci) in a.role_users"
                              :key="'rd' + r.doc_id + 'r' + ai + 'c' + ci"
                            >
                              {{ c }}
                            </div>
                          </div>
                        </div></v-col
                      >
                      <v-col cols="2"
                        ><h4>
                          {{ utils.pluralise(r.nodesMain[0].level_name) }}:
                        </h4></v-col
                      >
                      <v-col>
                        <v-row
                          v-for="(rm, rmi) in r.nodesMain"
                          :key="'t' + ti + 'd' + ri + 'm' + rmi"
                        >
                          <v-col>
                            <v-row
                              v-for="(n, ni) in ['name', 'description']"
                              dense
                              :key="'t' + ti + 'd' + ri + 'm' + rmi + '_' + ni"
                            >
                              <v-col>
                                <h4 v-if="n === 'name'">
                                  {{ rm[n + "_original"] }}
                                </h4>
                                <span v-else>{{
                                  rm[n + "_original"]
                                }}</span></v-col
                              >
                              <v-col cols="1">
                                <v-icon
                                  v-if="
                                    n !== 'description' ||
                                    rm.name_status !== rm[n + '_status']
                                  "
                                  :color="
                                    rm[n + '_status'] === 'Change Applied'
                                      ? 'green'
                                      : rm[n + '_status'] ===
                                        'Change Not Applied'
                                      ? 'red'
                                      : ''
                                  "
                                  :title="rm[n + '_status'] + ' to ' + n"
                                  >mdi-{{
                                    rm[n + "_status"] === "Change Applied"
                                      ? "check-circle"
                                      : rm[n + "_status"] ===
                                        "Change Not Applied"
                                      ? "alert-circle"
                                      : "cancel"
                                  }}</v-icon
                                ><v-menu
                                  v-model="rm.showComments"
                                  :close-on-content-click="false"
                                  :nudge-width="400"
                                  offset-x
                                  v-if="rm.part_comment_count && ni === 0"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-if="rm.part_comment_count && ni === 0"
                                      v-bind="attrs"
                                      title="View Comments"
                                      v-on="on"
                                      >mdi-comment-alert</v-icon
                                    >
                                  </template>
                                  <v-card>
                                    <v-list>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            >{{
                                              rm.level_name
                                            }}
                                            Comments</v-list-item-title
                                          >
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-icon
                                            @click="rm.showComments = false"
                                            >mdi-close-thick</v-icon
                                          >
                                        </v-list-item-action>
                                      </v-list-item>
                                      <v-divider></v-divider>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <div
                                            style="
                                              height: 100%;
                                              position: relative;
                                            "
                                          >
                                            <div
                                              style="
                                                overflow-y: scroll;
                                                max-height: 200px;
                                              "
                                            >
                                              <DocumentComments
                                                :document="rm"
                                                :doc_part_id="rm.dp_id"
                                                hideHeader
                                              ></DocumentComments>
                                            </div>
                                          </div>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-card>
                                </v-menu>
                                <!-- {{ rm[n + '_status'] !== 'No change' ? rm[n + '_status'] : ''}} -->
                              </v-col>
                              <v-col>
                                <span
                                  v-if="
                                    rm[n + '_status'] === 'Change Not Applied'
                                  "
                                  v-html="rm[n + '_html']"
                                ></span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
        <!-- <v-row
          ><v-col><div id="tree"></div></v-col
        ></v-row>
        <v-row
          ><v-col><div id="htree"></div></v-col
        ></v-row>
        <v-row
          ><v-col><div id="vtree"></div></v-col
        ></v-row> -->

        <v-menu
          v-model="contextMenu.show"
          absolute
          :position-x="contextMenu.posX"
          :position-y="contextMenu.posY"
          offset-y
          :close-on-content-click="false"
          nudge-width="300"
        >
          <v-card>
            <v-card-text v-if="contextMenu.busy">
              <v-row>
                <v-col cols="5"></v-col>
                <v-col>
                  <v-progress-circular indeterminate size="50" color="grey">
                  </v-progress-circular>
                </v-col>
                <v-col cols="5"></v-col>
              </v-row>
            </v-card-text>
            <v-list dense v-else>
              <v-list-item>
                <v-list-item-title
                  ><div class="menuSubject">
                    {{ contextMenu.title }}
                  </div></v-list-item-title
                ></v-list-item
              >
              <v-list-item
                v-for="(a, ai) in contextMenu.actions"
                :key="'cma' + ai"
                @click="doContextMenuAction(a)"
              >
                <v-list-item-icon>
                  <v-icon>{{ a.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ a.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <DocListMenu
          :context="docListMenu"
          @openDocument="openDocument"
          @copyDocument="$emit('copyDocument', $event)"
          showAttributeCompare
          @editDocs="editDocs"
        ></DocListMenu>

        <v-menu
          v-model="measurePicker.show"
          absolute
          :position-x="measurePicker.posX"
          :position-y="measurePicker.posY"
          offset-y
          :close-on-content-click="false"
          nudge-width="300"
        >
          <v-card max-height="400">
            <v-list dense>
              <v-list-item>
                <v-list-item-title>Visible Measures</v-list-item-title>
                <v-list-item-action>
                  <v-icon title="Close" @click="measurePicker.show = false"
                    >mdi-close</v-icon
                  ></v-list-item-action
                >
              </v-list-item>
              <v-list-item v-for="(m, mi) in measures" :key="'me' + mi">
                <v-list-item-title
                  ><v-chip label outlined small
                    ><v-icon left small>mdi-tune-variant</v-icon
                    >{{ m.title }} ({{ m.aggregation }})</v-chip
                  ></v-list-item-title
                ><v-list-item-action
                  ><v-switch
                    hide-details
                    v-model="m.visible"
                    @change="buildCompare"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <ResponseHandler :serviceResponse="response"></ResponseHandler>
      </v-container>
    </div>
    <Loading :isVisible="isLoading || isBuilding || isSaving" />
    <v-dialog
      v-model="skillsDialogue.show"
      :max-width="skillsDialogue.items.length === 1 ? '800px' : ''"
      scrollable
    >
      <v-card v-if="skillsDialogue.show">
        <v-card-title>
          <h4 v-if="skillsDialogue.items.length === 1">
            Selected Document Skills
          </h4>
          <h4 v-else>Skills Comparison</h4>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            v-if="skillsDialogue.items.length === 1"
            @click="identifySimilarJobs(skillsDialogue.items[0])"
            >Identify Similar Jobs</v-btn
          >
          <v-btn color="primary" @click="skillsDialogue.show = false"
            >Close</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="skillsDialogue.matchBands.length">
              <v-col style="position: relative; min-height: 300px">
                <div
                  v-for="(b, bi) in skillsDialogue.matchBands"
                  :key="'mb' + bi"
                  :style="{
                    height: 3 * b.pct + 'px',
                    width: '8%',
                    margin: '1%',
                    position: 'absolute',
                    bottom: '40px',
                    left: (750 * bi) / 10 + 'px',
                    backgroundColor: 'blue',
                    color: 'white',
                  }"
                >
                  {{ b.count > 0 ? b.count + " docs" : "" }}
                </div>
                <div
                  v-for="(b, bi) in skillsDialogue.matchBands"
                  :key="'mbh' + bi"
                  :style="{
                    height: '40px',
                    width: '8%',
                    margin: '1%',
                    position: 'absolute',
                    bottom: 0,
                    left: (750 * bi) / 10 + 'px',
                    color: 'blue',
                  }"
                >
                  {{ b.name }}
                </div>
              </v-col>
            </v-row>
            <v-row v-if="skillsDialogue.items.length">
              <v-col :cols="skillsDialogue.items.length === 1 ? 5 : 2">
                <v-row
                  dense
                  class="dialogueRow"
                  v-if="skillsDialogue.items.length > 1"
                  ><v-col></v-col
                ></v-row>
                <v-row dense class="dialogueRow"><v-col></v-col></v-row>
                <v-row dense class="dialogueRow"><v-col></v-col></v-row>
                <v-row
                  dense
                  v-for="(sc, sci) in skillsDialogue.categories"
                  :key="'sc_' + sci"
                  ><v-col
                    ><v-row dense class="dialogueRow"
                      ><v-col
                        ><h3>{{ sc.category }}</h3></v-col
                      ></v-row
                    >
                    <v-row
                      class="dialogueRow"
                      dense
                      v-for="(s, si) in sc.skills"
                      :key="'sc_' + sci + 's' + si"
                    >
                      <v-col class="pl-5">{{ s.name }}</v-col></v-row
                    >
                  </v-col></v-row
                >
              </v-col>
              <v-col :cols="skillsDialogue.items.length === 1 ? 6 : 10">
                <v-row
                  dense
                  class="dialogueRow"
                  v-if="skillsDialogue.items.length > 1"
                  ><v-col cols="1"
                    ><v-btn
                      small
                      @click="dialogueNextPage(skillsDialogue, false)"
                      :disabled="skillsDialogue.page === 0"
                      ><v-icon>mdi-chevron-left</v-icon></v-btn
                    ></v-col
                  ><v-col
                    ><h3 style="text-align: center">
                      Selected Documents {{ this.skillsDialogue.pageText }}:
                    </h3></v-col
                  >
                  <v-col cols="1"
                    ><v-btn
                      small
                      @click="dialogueNextPage(skillsDialogue, true)"
                      :disabled="
                        skillsDialogue.page === skillsDialogue.pages - 1
                      "
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    ></v-col
                  ></v-row
                >
                <v-row dense class="dialogueRow flex-nowrap">
                  <v-col
                    class="dialogueColumnTop"
                    :cols="skillsDialogue.colSize"
                    v-for="(d, di) in skillsDialogue.items.filter(
                      (x) => x.page === skillsDialogue.page
                    )"
                    :key="'dc1_' + di"
                  >
                    <v-chip small label outlined @click="openDocument(d)">{{
                      d.system_number
                    }}</v-chip>
                    <v-btn small class="float-end" @click="editDocSkills(d)"
                      >Edit Skills</v-btn
                    ></v-col
                  ></v-row
                >
                <v-row dense class="dialogueRow flex-nowrap">
                  <v-col
                    class="dialogueColumn"
                    :cols="skillsDialogue.colSize"
                    v-for="(d, di) in skillsDialogue.items.filter(
                      (x) => x.page === skillsDialogue.page
                    )"
                    :key="'dc2_' + di"
                    ><v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"
                        ><span v-bind="attrs" v-on="on">{{
                          d.doc_name
                        }}</span></template
                      ><span>{{ d.doc_name }}</span></v-tooltip
                    ></v-col
                  ></v-row
                >
                <v-row dense class="flex-nowrap">
                  <v-col
                    class="dialogueColumnBottom"
                    :cols="skillsDialogue.colSize"
                    v-for="(d, di) in skillsDialogue.items.filter(
                      (x) => x.page === skillsDialogue.page
                    )"
                    :key="'dc3_' + d.system_number"
                  >
                    <v-row
                      v-for="(c, ci) in d.categories"
                      :key="'dc3_' + d.system_number + 'c' + ci"
                      dense
                      ><v-col>
                        <v-row dense class="dialogueRow"><v-col></v-col></v-row>
                        <v-row
                          v-for="(s, si) in c.skills"
                          :key="'dc3_' + d.system_number + 'c' + ci + 's' + si"
                          dense
                          class="dialogueRow"
                          ><v-col style="text-align: center">
                            <v-chip
                              v-if="s"
                              label
                              :color="s.colour"
                              :text-color="s.textColour"
                            >
                              <v-icon
                                small
                                left
                                @click="removeDocSkill(d, s.name)"
                                >mdi-close-circle</v-icon
                              >
                              {{ s.level }}
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon right v-bind="attrs" v-on="on">
                                    mdi-chevron-down
                                  </v-icon>
                                </template>
                                <v-list>
                                  <v-list-item>
                                    <v-list-item-title
                                      >Proficiency level:</v-list-item-title
                                    > </v-list-item
                                  ><v-divider></v-divider>
                                  <v-list-item
                                    v-for="(l, li) in skillLevels"
                                    :key="'dc1_' + di + 'sl' + li"
                                    @click="updateSkillLevel(s, l)"
                                  >
                                    <v-list-item-title
                                      >{{ l.level
                                      }}<v-icon v-if="l.level === s.level"
                                        >mdi-check</v-icon
                                      ></v-list-item-title
                                    >
                                  </v-list-item>
                                </v-list>
                              </v-menu></v-chip
                            >
                            <v-menu offset-y v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">
                                  mdi-plus-circle
                                </v-icon>
                              </template>
                              <v-list>
                                <v-list-item>
                                  <v-list-item-title
                                    >Add at level:</v-list-item-title
                                  > </v-list-item
                                ><v-divider></v-divider>
                                <v-list-item
                                  v-for="(l, li) in skillLevels"
                                  :key="'dc1_' + di + 'sl' + li"
                                  @click="addDocSkill(d, null, ci, li)"
                                >
                                  <v-list-item-title>{{
                                    l.level
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            <!-- <v-icon v-else small>mdi-plus</v-icon> -->
                          </v-col></v-row
                        ></v-col
                      >
                    </v-row>
                  </v-col></v-row
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialogue.show"
      scrollable
      :max-width="editDialogue.items.length === 1 ? '1000px' : ''"
    >
      <!-- :max-width="editDialogue.items.length === 1 ? '500px' : ''" -->
      <v-card v-if="editDialogue.show">
        <!-- <v-card-title v-if="editDialogue.items.length === 1">
          <h4>
            {{ "Update " + editDialogue.items[0].system_number }}
          </h4>
          <br />
          <h4>
            {{ editDialogue.items[0].doc_name }}
          </h4>
        </v-card-title>
        <v-card-title v-else> -->
        <v-card-title>
          <h4>
            Detail Comparison - select the documents and properties to be
            updated
          </h4>
        </v-card-title>
        <v-card-text>
          <v-row v-if="editDialogue.items.length >= 1">
            <v-col cols="2">
              <v-row dense class="dialogueRow"><v-col>:</v-col></v-row>
              <v-row dense class="dialogueRow"
                ><v-col><h3>Properties:</h3></v-col></v-row
              >
              <v-row dense class="dialogueRow"><v-col></v-col></v-row>
              <v-row
                dense
                v-for="(a, ai) in editDialogue.columns"
                :key="'aa1_' + ai"
                :class="{
                  dialogueRow: a.tpa_id,
                  dialogueRowHierarchy: a.ht_id,
                }"
                ><v-col
                  ><v-badge
                    v-if="editDialogueCheckCount(a.value_column)"
                    :content="editDialogueCheckCount(a.value_column)"
                    ><span style="font-weight: bold; font-size: 15px">{{
                      a.label
                    }}</span></v-badge
                  >
                  <span v-else style="font-weight: bold; font-size: 15px">{{
                    a.label
                  }}</span
                  ><v-btn
                    v-if="editDialogue.items.length > 1"
                    x-small
                    class="float-right"
                    :title="
                      'Apply ' +
                      a.label +
                      ' updates for all ' +
                      editDialogue.items.length +
                      ' documents'
                    "
                    @click="editDialogueCheckAll(a.value_column)"
                  >
                    <v-icon small
                      >mdi-checkbox-multiple-marked-outline</v-icon
                    ></v-btn
                  >
                </v-col></v-row
              >
            </v-col>
            <v-col cols="7">
              <v-row dense class="dialogueRow"
                ><v-col cols="1"
                  ><v-btn
                    small
                    @click="dialogueNextPage(editDialogue, false)"
                    :disabled="editDialogue.page === 0"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  ></v-col
                ><v-col
                  ><h3 style="text-align: center">
                    Selected Documents {{ this.editDialogue.pageText }}:
                  </h3></v-col
                >
                <v-col cols="1"
                  ><v-btn
                    small
                    @click="dialogueNextPage(editDialogue, true)"
                    :disabled="editDialogue.page === editDialogue.pages - 1"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  ></v-col
                ></v-row
              >
              <v-row dense class="dialogueRow flex-nowrap">
                <v-col
                  class="dialogueColumnTop"
                  :cols="editDialogue.colSize"
                  v-for="(d, di) in editDialogue.items.filter(
                    (x) => x.page === editDialogue.page
                  )"
                  :key="'dd1_' + di"
                  ><v-chip small label outlined @click="openDocument(d)">{{
                    d.system_number
                  }}</v-chip
                  ><DocStatus
                    :doc_id="d.doc_id"
                    style="margin-left: 10px"
                  ></DocStatus></v-col
              ></v-row>
              <v-row dense class="dialogueRow flex-nowrap">
                <v-col
                  class="dialogueColumnBottom"
                  :cols="editDialogue.colSize"
                  v-for="(d, di) in editDialogue.items.filter(
                    (x) => x.page === editDialogue.page
                  )"
                  :key="'dd2_' + di"
                  ><v-tooltip top
                    ><template v-slot:activator="{ on, attrs }"
                      ><span v-bind="attrs" v-on="on">{{
                        d.doc_name
                      }}</span></template
                    ><span>{{ d.doc_name }}</span></v-tooltip
                  ></v-col
                ></v-row
              >
              <v-row
                class="flex-nowrap"
                :class="{
                  dialogueRow: a.tpa_id,
                  dialogueRowHierarchy: a.ht_id,
                }"
                dense
                v-for="(a, ai) in editDialogue.columns"
                :key="'aa2_' + ai + 'attr' + ai"
                ><v-col
                  :class="{
                    dialogueColumnBottom:
                      ai === editDialogue.columns.length - 1,
                    dialogueColumn: ai !== editDialogue.columns.length - 1,
                    dialogueColumnSame: a.same,
                  }"
                  :cols="editDialogue.colSize"
                  v-for="(d, di) in editDialogue.items.filter(
                    (x) => x.page === editDialogue.page
                  )"
                  :key="'aa2_' + ai + 'd' + di"
                >
                  <v-checkbox
                    v-if="a.ht_id"
                    v-model="d['change_' + a.value_column]"
                    dense
                    hide-details
                    :title="
                      a.tpa_id
                        ? d[a.value_column]
                        : d[a.value_column + '_label']
                    "
                    @click="setCanSave"
                  >
                    <template v-slot:label>
                      <li
                        v-for="(hl, hli) in d[a.value_column + '_label'].split(
                          ' > '
                        )"
                        :key="'aa2_' + ai + 'attr' + ai + 'hl' + hli"
                        :title="hl"
                        class="dialogueColumnCheckBoxRow"
                        :style="{
                          marginLeft: hli * 15 + 'px',
                          listStyleType: hli ? 'disclosure-closed' : 'none',
                        }"
                      >
                        {{ hl }}
                      </li>
                    </template></v-checkbox
                  >
                  <v-checkbox
                    v-else
                    v-model="d['change_' + a.value_column]"
                    dense
                    hide-details
                    :label="
                      a.tpa_id
                        ? d[a.value_column]
                        : d[a.value_column + '_label']
                    "
                    :title="
                      a.tpa_id
                        ? d[a.value_column]
                        : d[a.value_column + '_label']
                    "
                    @click="setCanSave"
                  ></v-checkbox> </v-col
              ></v-row>
            </v-col>
            <v-col cols="3">
              <v-row dense class="dialogueRow"><v-col></v-col></v-row>
              <v-row dense class="dialogueRow"><v-col></v-col></v-row>
              <v-row dense class="dialogueRow"
                ><v-col style="text-align: center"
                  >Enter the new values for each property:</v-col
                ></v-row
              >
              <v-row
                dense
                v-for="(a, ai) in editDialogue.columns.filter((x) => x.tpa_id)"
                :key="'av' + ai"
                :class="{
                  dialogueRow: a.tpa_id,
                  dialogueRowHierarchy: a.ht_id,
                }"
                ><v-col v-if="a.values.length"
                  ><v-select
                    :items="a.values"
                    :class="a.isDirty ? 'changedSelect' : ''"
                    v-model="a.value"
                    :label="a.label + ':'"
                    outlined
                    dense
                    hide-details
                    prepend-icon="mdi-plus"
                    @input="attributePicked(a)"
                    @click:prepend="addAttribute(a)"
                  ></v-select></v-col
                ><v-col cols="1" v-if="!a.values.length"></v-col
                ><v-col cols="11" v-if="!a.values.length"
                  ><DatePicker
                    v-if="a.value_data_type === 'date'"
                    v-model="a.value"
                    :css_class="a.isDirty ? 'changedSelect' : ''"
                    :label="a.label"
                    @input="attributePicked(a)"
                  ></DatePicker>
                  <v-text-field
                    v-else
                    outlined
                    :class="a.isDirty ? 'changedSelect' : ''"
                    dense
                    hide-details
                    :label="a.label + ':'"
                    v-model="a.value"
                    @input="attributePicked(a)"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row
                dense
                v-for="(h, hi) in editDialogue.columns.filter((x) => x.ht_id)"
                :key="'hv' + hi"
                :class="{
                  dialogueRow: h.tpa_id,
                  dialogueRowHierarchy: h.ht_id,
                }"
                ><v-col cols="1"></v-col
                ><v-col cols="11">
                  <div
                    class="subjectPicker selectable"
                    :class="h.isDirty ? 'changed' : ''"
                    @click="pickHierarchy(h, editDialogue)"
                  >
                    <div class="subjectText">
                      <li
                        v-for="(hl, hli) in h.hLabel"
                        :key="'hv' + hi + 'hl' + hli"
                        :title="hl"
                        class="dialogueColumnCheckBoxRow"
                        :style="{
                          marginLeft: hli * 15 + 'px',
                          listStyleType: hli ? 'disclosure-closed' : 'none',
                        }"
                      >
                        {{ hl }}
                      </li>
                    </div>
                    <!-- <v-icon class="subjectIcon">mdi-pencil-box-outline</v-icon> -->
                    <v-btn
                      x-small
                      class="subjectIcon"
                      :title="h.label + ' Picker'"
                      >...</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-container v-else>
            <v-row
              v-for="(a, ai) in editDialogue.columns.filter((x) => x.tpa_id)"
              :key="'as' + ai"
              :class="{
                dialogueRow: a.tpa_id,
                dialogueRowHierarchy: a.ht_id,
              }"
            >
              <v-col v-if="a.values.length">
                <v-select
                  :items="a.values"
                  :class="a.isDirty ? 'changedSelect' : ''"
                  v-model="a.value"
                  :label="a.label + ':'"
                  outlined
                  dense
                  hide-details
                  prepend-icon="mdi-plus"
                  @input="attributePicked(a)"
                  @click:prepend="addAttribute(a)"
                ></v-select> </v-col
              ><v-col cols="1" v-if="!a.values.length"></v-col
              ><v-col cols="11" v-if="!a.values.length"
                ><DatePicker
                  v-if="a.value_data_type === 'date'"
                  v-model="a.value"
                  :css_class="a.isDirty ? 'changedSelect' : ''"
                  :label="a.label"
                  @input="attributePicked(a)"
                ></DatePicker
                ><v-text-field
                  v-else
                  outlined
                  :class="a.isDirty ? 'changedSelect' : ''"
                  dense
                  hide-details
                  :label="a.label + ':'"
                  v-model="a.value"
                  @input="attributePicked(a)"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row
              v-for="(h, hi) in editDialogue.columns.filter((x) => x.ht_id)"
              :key="'hv' + hi"
              :class="{
                dialogueRow: h.tpa_id,
                dialogueRowHierarchy: h.ht_id,
              }"
              ><v-col cols="1"></v-col
              ><v-col cols="11">
                <div
                  class="subjectPicker selectable"
                  :class="h.isDirty ? 'changed' : ''"
                  @click="pickHierarchy(h, editDialogue)"
                >
                  <div class="subjectText">
                    <span style="font-size: 11px; font-style: italic"
                      >{{ h.label }}:</span
                    >
                    <li
                      v-for="(hl, hli) in h.hLabel"
                      :key="'hv' + hi + 'hl' + hli"
                      :title="hl"
                      class="dialogueColumnCheckBoxRow"
                      :style="{
                        marginLeft: hli * 15 + 'px',
                        listStyleType: hli ? 'disclosure-closed' : 'none',
                      }"
                    >
                      {{ hl }}
                    </li>
                  </div>
                  <v-btn
                    x-small
                    class="subjectIcon"
                    :title="h.label + ' Picker'"
                    >...</v-btn
                  >
                </div></v-col
              >
            </v-row>
          </v-container> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!editDialogue.canSave"
            @click="saveDocs"
            >Apply Changes</v-btn
          >
          <v-btn color="primary" @click="editDialogue.show = false"
            >Close</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="docSkillDialogue.show" max-width="1000px">
      <v-card v-if="docSkillDialogue.show">
        <v-card-title
          >Skills for {{ docSkillDialogue.doc.system_number }} -
          {{ docSkillDialogue.doc.doc_name }}<v-spacer></v-spacer>
          <!-- <v-btn @click="saveDocSkills" color="info">Save</v-btn> -->
          <v-btn @click="docSkillDialogue.show = false" color="info"
            >Close</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row
              v-for="(dsc, li) in docSkillDialogue.categories"
              :key="'dsc' + li"
            >
              <v-col cols="2"
                ><h4>{{ dsc.category }}</h4></v-col
              >
              <v-col>
                <v-autocomplete
                  v-model="dsc.skills"
                  :items="skills.filter((x) => x.category === dsc.category)"
                  filled
                  chips
                  return-object
                  color="blue-grey lighten-2"
                  item-text="name"
                  item-value="name"
                  multiple
                  small-chips
                  deletable-chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :color="getDocSkill(dsc.skills, data.item.name).colour"
                      :text-color="
                        getDocSkill(dsc.skills, data.item.name).textColour
                      "
                      label
                      @click="data.select"
                    >
                      <v-icon
                        small
                        left
                        @click="
                          removeDocSkill(docSkillDialogue.doc, data.item.name)
                        "
                      >
                        mdi-close-circle
                      </v-icon>
                      {{ data.item.name }}
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon label v-bind="attrs" v-on="on"
                            >mdi-chevron-down</v-icon
                          >
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title
                              >Proficiency level:</v-list-item-title
                            > </v-list-item
                          ><v-divider></v-divider>
                          <v-list-item
                            v-for="(l, li) in skillLevels"
                            :key="'dc1_' + data.item.level + 'sl' + li"
                            @click="
                              setSkillLevel(dsc.skills, data.item.name, l)
                            "
                          >
                            <v-list-item-title
                              >{{ l.level
                              }}<v-icon
                                v-if="
                                  l.level ===
                                  getDocSkill(dsc.skills, data.item.name).level
                                "
                                >mdi-check</v-icon
                              ></v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-chip>
                  </template>
                  <!-- <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <img :src="data.item.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template> -->
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="hierarchyDescriptorDialogue.show" max-width="800px">
      <v-card v-if="hierarchyDescriptorDialogue.node">
        <v-card-title
          >{{ hierarchyDescriptorDialogue.label }} Description:
          {{ hierarchyDescriptorDialogue.node.name }}</v-card-title
        >
        <v-card-text
          ><v-textarea
            outlined
            clearable
            v-model="hierarchyDescriptorDialogue.text"
          ></v-textarea
        ></v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="saveHierarchyDescriptor">Save</v-btn>
          <v-btn @click="hierarchyDescriptorDialogue.show = false"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="attributeAddDialogue.show" max-width="800px">
      <v-card>
        <v-card-title>{{ attributeAddDialogue.label }}</v-card-title>
        <v-card-text
          ><v-text-field
            outlined
            v-model="attributeAddDialogue.text"
          ></v-text-field
        ></v-card-text>
        <v-card-actions
          ><v-alert v-if="!isDemo" dense type="warning"
            >Feature coming soon...</v-alert
          ><v-spacer></v-spacer
          ><v-btn :disabled="!isDemo" @click="saveAttribute">Save</v-btn>
          <v-btn @click="attributeAddDialogue.show = false"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="nodeHistoryDialogue.show" width="70%">
      <v-card>
        <v-card-title>{{ nodeHistoryDialogue.label }}</v-card-title>
        <v-card-text>
          <v-timeline dense>
            <!-- <v-timeline-item
              v-for="(r, ri) in nodeHistoryDialogue.rows"
              :key="'hna' + ri"
            >
              <v-card class="elevation-2">
                <v-card-title class="text-h5">
                  {{ r.title }}
                </v-card-title>
                <v-card-text
                  ><h3 v-html="r.name_html"></h3>
                  <div v-html="r.description_html"></div>
                </v-card-text>
              </v-card>
            </v-timeline-item> -->
            <v-timeline-item
              v-for="(r, ri) in nodeHistoryDialogue.data"
              :key="'hna' + ri"
            >
              <v-card class="elevation-2">
                <v-card-title class="text-h5">
                  Updated {{ r.transaction_date }} by
                  <v-chip small :title="r.user_email">{{ r.user_name }}</v-chip
                  ><v-spacer></v-spacer>{{ r.transaction_sub_type }}
                </v-card-title>
                <v-card-text
                  ><h3
                    v-html="getAuditDescriptionHTML(r.transaction_description)"
                  ></h3>
                  <ul style="padding-left: 50px">
                    <li
                      v-for="(n, ni) in r.nodes_affected"
                      :key="'hna' + ri + 'n' + ni"
                      style="padding-top: 12px"
                      v-html="getAuditDescriptionHTML(n.effect_description)"
                    ></li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn @click="nodeHistoryDialogue.show = false"
            >Close</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="nodeReviewDialogue.show">
      <v-card v-if="nodeReviewDialogue.node">
        <v-card-title
          ><span>{{ nodeReviewDialogue.node.label }} Review Summary</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeHierarchyNodeReview">Close</v-btn></v-card-title
        >
        <v-divider></v-divider><br />
        <v-card-text>
          <HierarchyNodeReview
            :reviewNode="decorateReviewNode(nodeReviewDialogue.node, true)"
            :nodeChildLabel="nodeReviewDialogue.node.nextLevel"
            :nodeParentLabel="nodeReviewDialogue.node.prevLevel"
            showAllReviews
            @action="doMenuAction"
            @openDocument="openDocument"
            @nodeSaved="nodeSaved"
          ></HierarchyNodeReview
        ></v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="nodeDescriptorView.show">
      <v-card
        v-if="nodeDescriptorView.node && nodeDescriptorView.view === 'initiate'"
      >
        <v-card-title>
          <v-row
            ><v-col cols="2"> {{ nodeDescriptorView.labels[0] }}:</v-col
            ><v-col cols="8">
              <span style="font-weight: bold">
                {{ nodeDescriptorView.node.name }}</span
              > </v-col
            ><v-col
              ><v-spacer></v-spacer
              ><v-btn @click="reviewHierarchy" class="mr-4"
                >Initiate Review</v-btn
              >
              <v-btn @click="nodeDescriptorView.show = false"
                >Close</v-btn
              ></v-col
            ></v-row
          ></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-row
            v-if="
              nodeDescriptorView.node.reviewStatus &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_open &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_open.length
            "
          >
            <v-col cols="2"><h3>Open Reviews</h3></v-col>
            <v-col
              ><v-row>
                <v-col
                  v-for="rd in nodeDescriptorView.node.reviewStatus
                    .docsAsParent_open"
                  :key="'dpr' + rd.doc_id"
                  cols="2"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        small
                        @click="openDocument(rd)"
                        ><v-icon>mdi-file-eye</v-icon>
                        {{ rd.created_date }}</v-btn
                      >
                    </template>
                    <div
                      v-for="(a, ai) in rd.roles"
                      :key="'rd' + rd.doc_id + 'r' + ai"
                      style="padding-top: 5px"
                    >
                      {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                      <div
                        class="review-user"
                        style="color: gray"
                        v-for="(c, ci) in a.role_users"
                        :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                      >
                        {{ c }}
                      </div>
                    </div>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="
              nodeDescriptorView.node.reviewStatus &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_completed &&
              nodeDescriptorView.node.reviewStatus.docsAsParent_completed.length
            "
          >
            <v-col cols="2"><h3>Completed Reviews</h3></v-col>
            <v-col
              ><v-row>
                <v-col
                  v-for="rd in nodeDescriptorView.node.reviewStatus
                    .docsAsParent_completed"
                  :key="'dpr' + rd.doc_id"
                  cols="2"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        small
                        @click="openDocument(rd)"
                        ><v-icon>mdi-file-eye</v-icon>
                        {{ rd.created_date }}</v-btn
                      >
                    </template>
                    <div
                      v-for="(a, ai) in rd.roles"
                      :key="'rd' + rd.doc_id + 'r' + ai"
                      style="padding-top: 5px"
                    >
                      {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                      <div
                        class="review-user"
                        style="color: gray"
                        v-for="(c, ci) in a.role_users"
                        :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                      >
                        {{ c }}
                      </div>
                    </div>
                  </v-tooltip>
                </v-col></v-row
              ></v-col
            >
          </v-row>
          <v-row class="flex-nowrap">
            <v-col cols="2"
              ><h3>
                {{ utils.pluralise(nodeDescriptorView.labels[1]) }}
              </h3></v-col
            >
            <v-col>
              <v-row>
                <v-col
                  v-for="(n, ni) in nodeDescriptorView.node.nodes"
                  :key="'nd' + ni"
                  cols="3"
                >
                  <div
                    style="
                      border: solid 1px gray;
                      border-radius: 5px;
                      padding: 10px;
                      height: 100%;
                    "
                  >
                    <!-- style="/*border: solid 1px gray; border-radius: 5px; margin: 0px 6px;*/"  -->

                    <v-row class="flex-nowrap">
                      <v-col>
                        <v-tooltip
                          v-if="
                            n.reviewStatus && n.reviewStatus.docs_open.length
                          "
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              left
                              @click.stop="showHierarchyNodeReview(n)"
                              v-bind="attrs"
                              v-on="on"
                              :color="
                                n.reviewStatus.hasData ? 'orange' : 'blue'
                              "
                            >
                              mdi-eye-settings
                            </v-icon>
                          </template>
                          <span
                            >Currently {{ n.reviewStatus.docs_open[0].status
                            }}{{
                              n.reviewStatus.hasData
                                ? " (has feedback)"
                                : " (no feedback)"
                            }}</span
                          > </v-tooltip
                        ><span style="font-weight: bold">{{ n.name }}</span>
                      </v-col>
                      <v-col
                        cols="2"
                        v-for="(m, mi) in visibleMeasures"
                        :key="'nd' + ni + 'm' + mi"
                        class="measure-column"
                      >
                        <MeasureCount :item="n" :measure="m"></MeasureCount>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>{{ n.description }} </v-col>
                    </v-row>
                    <v-row v-if="n.nodes && n.nodes.length">
                      <v-col>
                        <v-row @click="n.showNodes = !n.showNodes"
                          ><v-col cols="10"
                            >{{ nodeDescriptorView.labels[2] }}s ({{
                              n.nodes.length
                            }})</v-col
                          >
                          <v-col>
                            <v-icon v-if="n.showNodes">mdi-chevron-up</v-icon>
                            <v-icon v-else>mdi-chevron-down</v-icon></v-col
                          >
                        </v-row>
                        <div v-if="n.showNodes">
                          <v-row
                            v-for="(nn, nni) in n.nodes"
                            :key="'nd' + ni + 'ndn' + nni"
                          >
                            <v-col>{{ nn.name }} </v-col>
                            <v-col
                              cols="2"
                              v-for="(m, mi) in visibleMeasures"
                              :key="'nd' + ni + 'ndn' + nni + 'm' + mi"
                            >
                              <MeasureCount
                                :item="nn"
                                :measure="m"
                              ></MeasureCount>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="nodeDescriptorView.node && nodeDescriptorView.view === 'existing'"
      >
        <v-card-title>
          {{ nodeDescriptorView.labels[0] }} Review Feedback<v-spacer></v-spacer
          ><v-btn @click="reviewHierarchy" class="mr-4"
            >Initiate New Review</v-btn
          >
          <v-btn @click="nodeDescriptorView.show = false"
            >Close</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row
              style="
                border: solid 1px gray;
                border-radius: 5px;
                padding: 10px;
                height: 100%;
                margin-bottom: 10px;
              "
            >
              <v-col cols="4" style="border-right: solid grey 2px"
                ><h3>
                  <cHierarchyNodeDisplay
                    :node="nodeDescriptorView.node"
                    hideReview
                    @action="doMenuAction($event)"
                  ></cHierarchyNodeDisplay></h3
              ></v-col>
              <v-col cols="8">
                <v-row class="feedback-row">
                  <v-col cols="2"><h3>Review</h3></v-col>
                  <v-col cols="10"
                    ><h3>{{ nodeDescriptorView.labels[0] }} Comments</h3></v-col
                  > </v-row
                ><v-row
                  v-for="(rd, rdi) in nodeDescriptorView.node.reviewStatus
                    .docsAsParent_open"
                  :key="'rdo' + rdi"
                  class="feedback-row"
                >
                  <v-col cols="2"
                    ><v-tooltip right>
                      <template v-slot:activator="{ on, attrs }"
                        ><v-btn
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          small
                          @click="openDocument(rd)"
                          ><v-icon>mdi-file-eye</v-icon>
                          {{ rd.created_date }}</v-btn
                        >
                      </template>
                      <div
                        v-for="(a, ai) in rd.activity"
                        :key="'rd' + rd.doc_id + 'a' + ai"
                      >
                        {{ a }}
                      </div>
                    </v-tooltip>
                    <div
                      v-for="(a, ai) in rd.roles"
                      :key="'rd' + rd.doc_id + 'r' + ai"
                      style="padding-top: 5px"
                    >
                      {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                      <div
                        class="review-user"
                        v-for="(c, ci) in a.role_users"
                        :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                      >
                        {{ c }}
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="rd.overall_comment_count">
                    <div style="height: 100%; position: relative">
                      <div style="overflow-y: scroll; max-height: 200px">
                        <DocumentComments
                          :document="rd"
                          hideHeader
                        ></DocumentComments>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-else> No feedback comments </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><h2>
                  {{ utils.pluralise(nodeDescriptorView.labels[1]) }}
                  within {{ nodeDescriptorView.node.name }}:
                </h2></v-col
              >
              <v-col cols="3"
                ><v-switch
                  class="mt-0 mb-4"
                  v-model="nodeDescriptorView.showFeedbackOnly"
                  :label="
                    'Only show ' +
                    nodeDescriptorView.labels[1] +
                    ' items with feedback'
                  "
                ></v-switch></v-col
            ></v-row>
            <HierarchyNodeReview
              v-for="(n, ni) in nodeDescriptorView.node.nodes.filter(
                (x) =>
                  !nodeDescriptorView.showFeedbackOnly ||
                  x.reviewStatus.docs_open.some((d) => d.hasData)
              )"
              :key="'ndr' + ni"
              :reviewNode="n"
              :nodeChildLabel="nodeDescriptorView.labels[2]"
              nestedMode
              @action="doMenuAction"
              @openDocument="openDocument"
              @nodeSaved="nodeSaved"
            ></HierarchyNodeReview>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sideBySide.show">
      <DocumentSideBySide
        v-if="sideBySide.show"
        :items="sideBySide.items"
        :isDialogue="true"
        @close="sideBySide.show = false"
        @openContext="openDocActionsMenuContext"
      ></DocumentSideBySide>
    </v-dialog>
    <v-dialog v-model="fullCompareView.show" style="background-color: white">
      <v-card>
        <v-card-title>
          Document Comparison
          <v-spacer></v-spacer>
          <v-switch
            v-model="fullCompareView.highlightChanges"
            label="Highlight Differences"
            class="mr-4"
          ></v-switch>
          <v-btn
            icon
            large
            class="btn-background"
            @click="fullCompareView.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DocumentVersionCompare
            class="pt-6 px-2"
            style="height: 100%"
            v-if="fullCompareView.show"
            :compareList="fullCompareView.items"
            :highlightChanges="fullCompareView.highlightChanges"
            hideCompareVersion
            multiDoc
            showWorkflowActions
            @openDocument="openDocument"
            @copyDocument="$emit('copyDocument', $event)"
          >
          </DocumentVersionCompare>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-footer
      v-if="compareItems && compareItems.length && compareView && 1 === 0"
      fixed
      padless
    >
      <v-card width="100%" class="blue-grey lighten-4 text-left">
        <v-card-text>
          <v-btn v-if="isDemo" small @click="compareDocSkills" class="mx-4"
            >Show Job Skills</v-btn
          >
          <v-btn small @click="compareDocDetails" class="mx-4"
            >Show Job Profiles</v-btn
          >
          <v-btn small @click="editDocs()" class="mx-4">Show Details</v-btn>
          <v-btn v-if="isDemo" small class="mx-4">Send For Review</v-btn>
          <v-btn small @click="resetCompare" class="mx-4 my-2">Clear</v-btn>
          <v-btn small @click="compareView = false" class="mx-4 my-2"
            >Hide</v-btn
          >
          <v-btn
            v-for="doc in compareItems.filter((x, i) => i < 6)"
            :key="doc.system_number"
            class="mx-4"
            outlined
            small
            @click="openDocument(doc)"
          >
            {{ doc.doc_name }}
          </v-btn>
          <span v-if="compareItems.length > 6"
            >(... + {{ compareItems.length - 6 }} more)</span
          >
        </v-card-text>
      </v-card>
    </v-footer> -->
    <!-- v-if="compareItems.length && !compareView"     @click="compareView = true"-->
    <v-badge
      v-if="compareItems.length"
      color="green"
      left
      overlap
      :content="compareItems.length"
      style="position: fixed; left: 35px; bottom: 25px"
    >
      <v-chip label color="blue darken-2" dark @click="editDocs()">
        Selected
        {{ catalogueDocType + (compareItems.length > 1 ? "s" : "") }}</v-chip
      ><v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        @click="compareDocDetailsFull(compareItems)"
      >
        <v-icon title="Full compare view">mdi-content-duplicate</v-icon></v-chip
      >
      <v-chip
        label
        color="blue darken-2"
        dark
        @click="editDocs()"
        style="margin-left: 2px"
        ><v-icon title="Show Details">mdi-view-week</v-icon></v-chip
      ><v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        :disabled="compareItems.length > 15"
        @click="compareDocDetails(compareItems)"
      >
        <v-icon title="Side-by-side view">mdi-compare</v-icon></v-chip
      ><v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        @click="openDocListMenu($event)"
      >
        <v-icon title="Selected document list"
          >mdi-view-list-outline</v-icon
        ></v-chip
      ><v-chip
        label
        dark
        color="blue darken-2"
        style="margin-left: 2px"
        @click="resetCompare"
      >
        <v-icon title="Clear">mdi-close-circle-outline</v-icon></v-chip
      >
      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="blue darken-2" dark small v-bind="attrs" v-on="on">
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="compareItems.length === 1"
            @click="openDocument(compareItems[0])"
          >
            <v-list-item-title>View Document</v-list-item-title>
          </v-list-item>
          <v-list-item @click="editDocs()">
            <v-list-item-title>Show Details</v-list-item-title>
          </v-list-item>
          <v-list-item @click="compareDocDetails">
            <v-list-item-title>Side-by-side view</v-list-item-title>
          </v-list-item>
          <v-list-item @click="resetCompare">
            <v-list-item-title>Clear</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isDemo" @click="compareDocSkills">
            <v-list-item-title>Compare Skills</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isDemo">
            <v-list-item-title>Send For Review</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-badge>

    <AdminHierarchyNodeDetail
      :node="hierarchyEditDialogue.node"
      :mode="hierarchyEditDialogue.mode"
      :action="hierarchyEditDialogue.action"
      :show="hierarchyEditDialogue.show"
      :showAddNodeDescriptor="hierarchyEditDialogue.showAddNodeDescriptor"
      @close="hierarchyEditDialogue.show = false"
      @saved="nodeUpdated"
      @picked="nodePicked"
      @deleted="hierarchyEditDialogue.show = false"
    >
    </AdminHierarchyNodeDetail>

    <AdminHierarchyAdd
      :ht_id="hierarchyAddDialogue.ht_id"
      :show="hierarchyAddDialogue.show"
      @close="hierarchyAddDialogue.show = false"
      @saved="nodeUpdated"
    >
    </AdminHierarchyAdd>
    <DocActionsMenu
      :context="docActionsMenuContext"
      @openDocument="openDocument"
      @copyDocument="$emit('copyDocument', $event)"
    ></DocActionsMenu>
  </div>
</template>

<script>
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";
import HierarchySearch from "@/components/cHierarchySearch";
import { mapState } from "vuex";
// import * as d3 from "d3";
import dayJS from "dayjs";
import * as similarity from "string-similarity";
// const d3 = require("d3");
import DocumentSideBySide from "@/components/cDocumentSideBySide.vue";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import AdminHierarchyNodeDetail from "@/components/hierarchy/cAdminHierarchyNodeDetail";
import AdminHierarchyAdd from "@/components/hierarchy/cAdminHierarchyAdd";
import cHierarchyNodeDisplay from "@/components/hierarchy/cHierarchyNodeDisplay";
import axios from "axios";
import HierarchyTreeNodeAudit from "@/components/hierarchy/cHierarchyTreeNodeAudit";
import DateRangePicker from "@/components/common/DateRangePicker";
import DocumentComments from "@/components/comments/CommentsContainer";
import HierarchyNodeReview from "@/components/hierarchy/cHierarchyNodeReview";
import DocStatus from "@/components/common/DocStatus";
import DatePicker from "@/components/common/DatePicker";
import DocActionsMenu from "@/components/common/DocActionsMenu";
import MeasureCount from "@/components/cHierarchyViewMeasureCount.vue";
import DocumentVersionCompare from "@/components/audit/cDocumentVersionCompare";
import DocListMenu from "@/components/common/DocListMenu";

let _descriptors = {};
let _documents = {};
let _reviewNodes = {};
let _hierarchyTypeNodes = {};
// let _reviewDocuments = {};

export default {
  name: "cHiearchyView",
  components: {
    ResponseHandler,
    HierarchySearch,
    DocumentSideBySide,
    Pagination,
    PageDescription,
    AdminHierarchyNodeDetail,
    AdminHierarchyAdd,
    cHierarchyNodeDisplay,
    HierarchyTreeNodeAudit,
    DateRangePicker,
    DocumentComments,
    HierarchyNodeReview,
    DocStatus,
    DatePicker,
    DocActionsMenu,
    MeasureCount,
    DocumentVersionCompare,
    DocListMenu,
  },
  props: {},
  data: function () {
    return {
      utils: utils,
      response: null,
      items: [],
      isDirty: false,
      isDemo: false,
      canDelete: false,
      reviewDocs: [],
      documentsForSearch: [],
      hierarchyFilters: [],
      csvHeaders: [],
      dialog: false,
      options: { itemsPerPage: 15 },
      filteredItems: [],
      showHierarchies: false,
      hierarchyTypes: [],
      jobTitles: [],
      selectedDocs: [],
      groupedDocs: [],
      groupedViewDocs: [],
      groupedViewLevelColumns: [],
      groupedViewDetailColumns: [],
      filterList: [],
      layout: null,
      compareOptionsV: [],
      compareOptionsH: [],
      compareOptionsD: [],
      compareCriteria: [],
      compareItems: [],
      compareSkillMatrix: [],
      sideBySide: { show: false, items: [] },
      fullCompareView: { show: false, highlightChanges: false, items: [] },
      compareView: true,
      horizontalCompareOption: null,
      verticalCompareOption: null,
      detailCompareOption: null,
      showDuplicatesOnly: false,
      showDetail: false,
      hideHorizontal: false,
      showSalary: false,
      isBuilding: true,
      resetIsBuilding: false,
      doSetView: false,
      catalogueDocType: false,
      measureFilters: [],
      measureFilter: null,
      display: {
        showLeafCount: false,
        v_col: { colWidth: 1 },
        h_col: { colWidth: 1 },
        total_col: {
          colWidth: 1,
          showZ: false,
          colWidthLeafCount: 0,
          leafCountLabel: "",
          leafCountTitle: "",
        },
        z_col: {
          show: false,
          colWidth: 1,
          showLabel: false,
          colWidthLabel: 0,
        },
      },
      isSaving: false,
      isLoadingData: false,
      isDataLoaded: false,
      haveData: false,
      selectedView: null,
      viewTypes: [
        { viewType: "catalogue", isInitialised: false },
        { viewType: "list", isInitialised: false },
        { viewType: "grouped", isInitialised: false },
        { viewType: "audit", isInitialised: false },
        { viewType: "audit_data", isInitialised: false },
        { viewType: "reviews", isInitialised: false },
      ],
      primaryViews: [
        { view: "Job Family List View", icon: "mdi-view-sequential" },
        { view: "Job Family Summary", icon: "mdi-view-comfy" },
        { view: "Tree View", icon: "mdi-file-tree-outline" },
        { view: "Job Family Descriptor View", icon: "mdi-receipt-text" },
        // { view: "Job Family Audit Report", icon: "mdi-delta" },
        { view: "Job Family Audit Data", icon: "mdi-radar" },
        { view: "Job Family Reviews", icon: "mdi-comment-text-multiple" },
      ],
      views: [
        { title: "Job Family By Level", viewType: "catalogue" },
        { title: "Job Family By Grade", viewType: "catalogue" },
        { title: "Job Title By Job Family", viewType: "catalogue" },
        { title: "Default", viewType: "catalogue" },
        { title: "Pay Transparency", viewType: "catalogue" },
        { title: "Job Family Summary", viewType: "catalogue" },
        { title: "Tree View", viewType: "catalogue" },
        { title: "Job Family List View", viewType: "list", hierarchy: "Job" },
        {
          title: "Job Family Descriptor View",
          viewType: "grouped",
          hierarchy: "Job",
        },
        {
          title: "Organisation List View",
          viewType: "list",
          hierarchy: "Business Org",
        },
        {
          title: "Organisation Descriptor View",
          viewType: "grouped",
          hierarchy: "Business Org",
        },
        {
          title: "Location List View",
          viewType: "list",
          hierarchy: "Geography",
        },
        {
          title: "Location Descriptor View",
          viewType: "grouped",
          hierarchy: "Geography",
        },
        // {
        //   title: "Job Family Audit Report",
        //   viewType: "audit",
        //   hierarchy: "Job",
        // },
        {
          title: "Job Family Audit Data",
          viewType: "audit_data",
          hierarchy: "Job",
        },
        {
          title: "Job Family Reviews",
          viewType: "reviews",
          hierarchy: "Job",
        },
      ],
      vScroll: {
        rows: 0,
        pageSize: 0,
        pageHeight: 0,
        start: 0,
        end: 0,
        scrollValue: 0,
        pages: 1,
        pageText: "",
        doSetup: false,
        dStart: 0,
        dEnd: 0,
        dMax: 0,
        inProgress: false,
      },
      drilldownFilters: [],
      editDialogue: {
        show: false,
        canSave: false,
        items: [],
        columns: [],
        page: 0,
        pageSize: 4,
        colSize: 3,
      },
      skillsDialogue: {
        show: false,
        items: [],
        categories: [],
        page: 0,
        pageSize: 6,
        colSize: 2,
        matchBands: [],
      },
      docSkillDialogue: {
        show: false,
        categories: [],
        doc: null,
      },
      hierarchyDescriptorDialogue: {
        show: false,
        node: null,
        label: "",
        text: "",
      },
      attributeAddDialogue: {
        show: false,
        label: "",
        text: "",
        attribute: null,
      },
      nodeHistoryDialogue: {
        show: false,
        label: "",
        data: [],
      },
      nodeReviewDialogue: {
        show: false,
        node: null,
      },
      nodeHistoryReport: {
        show: false,
        label: "",
        nodes: [],
        dateRangeFilter: [],
        userFilter: [],
        userList: [],
      },
      nodeAuditData: {
        show: false,
        label: "",
        ht_id: null,
        data: [],
        dateRangeFilter: [],
        userFilter: [],
        userList: [],
        headers: [
          { text: "Date", value: "transaction_date" },
          { text: "Type", value: "transaction_sub_type" },
          { text: "User", value: "user_name" },
          { text: "Description", value: "transaction_description" },
          { text: "", value: "data-table-expand" },
        ],
        expanded: [],
      },
      hierarchyEditDialogue: {
        show: false,
        mode: "edit",
        node: null,
        showAddNodeDescriptor: false,
      },
      hierarchyAddDialogue: {
        show: false,
        ht_id: null,
      },
      contextMenu: {
        show: false,
        busy: false,
        item: null,
        title: null,
        actions: [],
        posX: 0,
        posY: 0,
      },
      docListMenu: {
        show: false,
        busy: false,
        title: null,
        documents: [],
        matchSets: [],
        posX: 0,
        posY: 0,
        matchColumn: false,
        showSuggestions: false,
        documentType: this.catalogueDocType,
      },
      measurePicker: {
        show: false,
        busy: false,
        title: null,
        posX: 0,
        posY: 0,
      },
      docActionsMenuContext: {
        show: false,
        busy: false,
        document: null,
        posX: 0,
        posY: 0,
      },
      visibleMeasures: [],
      measures: [
        {
          column: "job_count",
          type: "job_count",
          title: "Job Descriptions",
          abbreviation: "JDs",
          relative_width: 1,
          h_col_width: 0,
          h_col_label_width: 0,
          z_col_width: 0,
          z_col_label_width: 0,
          z_show: false,
          total_col_width: 0,
          total_col_label_width: 0,
          aggregation: "count",
          format: "0",
          aggregation_quantity_column: null,
          aggregation_exclude_zero: false,
          visible: true,
          alignRight: false,
        },
        {
          column: "employee_count",
          type: "employee_count",
          title: "Employees",
          abbreviation: "EEs",
          relative_width: 1,
          h_col_width: 0,
          h_col_label_width: 0,
          z_col_width: 0,
          z_col_label_width: 0,
          z_show: false,
          total_col_width: 0,
          total_col_label_width: 0,
          aggregation: "sum",
          format: "0",
          aggregation_quantity_column: null,
          aggregation_exclude_zero: false,
          visible: true,
          alignRight: false,
        },
        {
          column: "salary",
          type: "salary",
          title: "Salary",
          abbreviation: "Sal",
          relative_width: 2,
          h_col_width: 0,
          h_col_label_width: 0,
          z_col_width: 0,
          z_col_label_width: 0,
          z_show: false,
          total_col_width: 0,
          total_col_label_width: 0,
          aggregation: "mean",
          format: "£1k",
          aggregation_quantity_column: "employee_count",
          aggregation_exclude_zero: true,
          alignRight: false,
          visible: false,
        },
      ],
      attributes: [
        {
          column: "grade",
          title: "Grade",
          values: [
            { value: "PB1", sort_key: "PB1" },
            { value: "LPB1", sort_key: "LPB1" },
            { value: "LPB2", sort_key: "LPB2" },
            { value: "LPB3", sort_key: "LPB3" },
            { value: "SPB1", sort_key: "SPB1" },
            { value: "SPB2", sort_key: "SPB2" },
            { value: "SPB3", sort_key: "SPB3" },
            { value: "EB1", sort_key: "EB1" },
            { value: "EB2", sort_key: "EB2" },
          ],
        },
        {
          column: "level",
          title: "Level",
          values: [
            { value: "Manager", sort_key: "Manager" },
            {
              value: "Individual Conributer",
              sort_key: "Individual Conributer",
            },
            { value: "Technical", sort_key: "Technical" },
            { value: "Executives", sort_key: "Executives" },
            { value: "Specialist", sort_key: "Specialist" },
          ],
        },
        {
          column: "band",
          title: "Band",
          values: [
            { value: "PB", sort_key: "PB" },
            { value: "LPB", sort_key: "LPB" },
            { value: "SPB", sort_key: "SPB" },
            { value: "EB", sort_key: "EB" },
          ],
        },
        {
          column: "job_code",
          title: "Job Code",
          values: [],
          generateList: ["A", "B", "C", "D"],
        },
      ],
      skills: [
        { name: "Project Management", category: "Organisational" },
        { name: "Team Management", category: "Organisational" },
        { name: "Data Analysis", category: "Technical" },
        { name: "Web Development", category: "Technical" },
        { name: "Application Development", category: "Technical" },
        { name: "Sales Management", category: "Organisational" },
        { name: "Test Planning", category: "Organisational" },
        { name: "Software Testing", category: "Technical" },
        { name: "Test Automation", category: "Technical" },
        { name: "Technical Writing", category: "Technical" },
        { name: "Sales Training", category: "Technical" },
        { name: "Transact-SQL", category: "Technical" },
      ],
      skillCats: [
        { category: "Organisational", colour: "green darken-2" },
        { category: "Technical", colour: "light-green lighten-2" },
      ],
      skillLevels: [
        {
          level: "expert",
          colour: "green darken-2",
          textColour: "white",
          score: 3,
        },
        {
          level: "practioner",
          colour: "light-green lighten-2",
          textColour: "black",
          score: 2,
        },
        { level: "novice", colour: "orange", textColour: "black", score: 1 },
      ],
      listView: {
        show: false,
        columnsEnabled: [],
        columns: [],
        filteredItems: [],
        sortColumn: null,
        sortDesc: null,
        pageSize: 0,
        density: "dense",
        currentPage: 1,
        itemsPerPage: 10,
        tableHeight: null,
        hierarchyView: null,
        renderCount: 0,
      },
      hierarchyDescriptors: [],
      nodeDescriptorView: {
        show: false,
        node: null,
        view: "initiate",
        labels: [],
        showFeedbackOnly: false,
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    isLoading(val) {
      if (!val) this.fetchData();
    },
    showDuplicatesOnly() {
      this.buildCompare();
    },
    selectedView() {
      this.initSetView();
    },
    $route() {
      this.setDefaultView();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.docs.loading,
      cleanItems: (state) => state.docs.docsList,
      hierarchies: (state) => state.docs.hierarchyTypes,
      classifierTypes: (state) => state.docs.classifierTypes,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      useTranslation: (state) => state.docs.useTranslation,
    }),
    lvColumnsFiltered() {
      const headersEnabled = this.listView.columnsEnabled;
      const columns = [];
      return [
        ...columns,
        { text: "", value: "selected", sortable: false, selected: false },
        ...this.listView.columns.filter(
          (c) =>
            (!c.isAttribute && !c.ht_id) ||
            ((c.isAttribute ||
              (c.ht_id && c.ht_id === this.listView.hierarchyView.ht_id)) &&
              headersEnabled.indexOf(c.value) >= 0)
        ),
      ];
    },
    itemsPerPageOptions() {
      return [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
      ];
    },
    itemsPerPage() {
      if (this.listView.pageSize !== 0) return this.listView.pageSize;
      else return this.listView.itemsPerPage;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.listView.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
    currentPageItems() {
      if (this.listView.itemsPerPage < 0) return this.filteredItems;

      const pageFrom =
        this.listView.itemsPerPage * (this.listView.currentPage - 1);
      const pageTo = this.listView.itemsPerPage * this.listView.currentPage;
      return this.filteredItems.slice(pageFrom, pageTo);
    },
    currentViewHierarchy() {
      return (
        this.hierarchyTypes.find(
          (h) => h.ht_name === this.selectedView.hierarchy
        ) || this.hierarchyTypes.find((h) => h.ht_name === "Job")
      );
    },
  },
  created() {
    this.fetchData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "docs/update" && mutation.payload.doc_id && state) {
        let newVal = mutation.payload;
        if (["DELETED", "ARCHIVED"].some((x) => x === newVal.doc_status)) {
          let ind = _documents.findIndex((d) => d.doc_id === newVal.doc_id);
          if (ind >= 0) {
            _documents.splice(ind, 1);
            ind = this.compareItems.findIndex(
              (d) => d.doc_id === newVal.doc_id
            );
            if (ind >= 0) {
              this.compareItems.splice(ind, 1);
              if (this.compareItems.length === 0) {
                this.resetCompare();
              } else if (this.editDialogue.items?.length) {
                ind = this.editDialogue.items.findIndex(
                  (d) => d.doc_id === newVal.doc_id
                );
                if (ind >= 0) this.editDialogue.items.splice(ind, 1);
              }
            }
            this.selectJDs();
          }
        } else {
          // get job cat view of doc
          let self = this;
          let possibleError = false;
          this.isLoadingData = true;

          axios
            .get("document/jobcataloguedoc/" + newVal.doc_id)
            .then((resp) => {
              possibleError = true;
              if (resp.data.Data)
                self.afterDocumentUpdate(self, resp.data.Data.docs);
              this.isLoadingData = false;
            })
            .catch((err) => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.isLoadingData = false;
            });
        }
      }
    });
  },
  beforeDestroy: function () {
    //console.log("beforeDestroy()", this.resetIsBuilding, this.isBuilding);
    this.unsubscribe();
  },
  updated() {
    this.$nextTick(function () {
      this.afterInit();
      if (this.doSetView) {
        this.doSetView = false;
        this.setView();
        return;
      }
    });
    // console.log(Date.now(), "updated", this.resetIsBuilding, this.isBuilding);
    //this.isFiltering = false;
  },
  mounted() {
    // console.log(Date.now(), "mounted", this.resetIsBuilding, this.isBuilding);
    if (this.isDataLoaded) {
      this.setDefaultView();
    }
    this.afterInit();
  },
  methods: {
    getRowNo() {
      this.listView.renderCount++;
      return "1";
    },
    setDefaultView() {
      if (this.$route.name === "jobfamily") {
        this.selectedView = this.views.find(
          (x) => x.title === "Job Family List View"
        );
      } else {
        const passedParams = this.$passedParams.get();
        const requestedView = this.views.find(
          (x) => x.title === passedParams?.view
        );

        this.selectedView =
          requestedView ||
          this.views.find((x) => x.title === "Job Family Summary");
      }
    },
    setLVPageSize(value) {
      if (this.listView.pageSize === 0) {
        localStorage.setItem("jcRPP", value);
        this.listView.itemsPerPage = value;
      }
    },
    buildNodeDescriptorView(node, showSubLevel) {
      this.nodeDescriptorView.labels = [node.label];
      let data = {
        name: node.name,
        type: node.label,
        hierarchy_node_id: node.hierarchy_node_id,
        description: node.descriptor.description,
        nodes: [],
      };
      if (!node.nodes?.length && !node.rows?.length) {
        this.getNodes(node.level + 1, node.filterKeys || [], node);
      }
      if (node.nodes?.length) {
        data.labels.push(node.nodes[0].label);
        node.nodes.forEach((n) => {
          let displayNode = {
            name: n.name,
            type: n.label,
            hierarchy_node_id: n.node.hierarchy_node_id,
            ht_id: n.node.ht_id,
            level: n.node.level,
            description: n.node.descriptor?.description,
            reviewStatus: this.getHierarchyNodeReviewStatus(
              n.node.hierarchy_node_id
            ),
            nodes: [],
            showNodes: false,
          };
          this.appendMeasureColumns(displayNode, 0, n);
          this.appendNodeHierarchyLevels(displayNode);
          if (showSubLevel) {
            if (!n.nodes?.length && !n.rows?.length) {
              this.getNodes(n.level + 1, n.filterKeys, n);
            }
            data.labels.push(n.nodes[0].label);
            n.nodes.forEach((nn) => {
              let subNode = {
                name: nn.name || "UNKNOWN",
                type: nn.label,
                hierarchy_node_id: nn.node.hierarchy_node_id,
                ht_id: nn.node.ht_id,
                level: nn.node.level,
                description: nn.node.descriptor?.description,
              };
              this.appendMeasureColumns(subNode, 0, nn);
              this.appendNodeHierarchyLevels(subNode);
              displayNode.nodes.push(subNode);
            });
          }
          data.nodes.push(displayNode);
        });
      }
      this.nodeDescriptorView.node = data;
      this.nodeDescriptorView.show = true;
    },
    decorateReviewNode(n, showSubLevel) {
      let sumFunc = (p, c) => {
        this.measures.forEach((m) => {
          p[m.type] += c[m.type];
        });
        return p;
      };
      let tot = this.appendMeasureColumns(null, 0);
      let tots = _documents
        .filter(
          (x) =>
            x["ht_id_" + n.ht_id + "_" + n.level + "_nodeID"] ===
            n.hierarchy_node_id
        )
        .reduce(sumFunc, tot);
      if (n.reviewStatus) n.reviewStatus.editMode = false;
      this.appendMeasureColumns(n, 0, tots);
      (n.description = n.descriptor ? n.descriptor.description : null),
        (n.showNodes = false);
      if (showSubLevel && n.nodes) {
        n.nodes.forEach((nn) => {
          let tot2 = this.appendMeasureColumns(null, 0);
          let ntots = _documents
            .filter(
              (x) =>
                x["ht_id_" + nn.ht_id + "_" + nn.level + "_nodeID"] ===
                nn.hierarchy_node_id
            )
            .reduce(sumFunc, tot2);
          this.appendMeasureColumns(nn, 0, ntots);
        });
      }
      return n;
    },
    buildNodeDescriptorViewReview(node, showSubLevel, view) {
      let isNode = (nodes, hierarchy_node_id) => {
        let found = nodes.find(
          (x) => x.hierarchy_node_id === hierarchy_node_id
        );
        if (!found) {
          nodes.forEach((n) => {
            if (!found && n.nodes) found = isNode(n.nodes, hierarchy_node_id);
          });
        }
        return found;
      };
      let _node = isNode(
        _hierarchyTypeNodes.find((x) => x.ht_id === node.ht_id).nodes,
        node.hierarchy_node_id
      );

      this.nodeDescriptorView.labels = [_node.label];
      if (_node.nodes?.length) {
        this.nodeDescriptorView.labels.push(_node.nodes[0].label);
        _node.nodes.forEach((n) => {
          this.decorateReviewNode(n, showSubLevel);
          if (showSubLevel && n.nodes) {
            this.nodeDescriptorView.labels.push(n.nodes[0].label);
          }
        });
      }
      this.getNodeReviewActivity(_node, () => {
        this.nodeDescriptorView.node = _node;
        this.nodeDescriptorView.show = true;
        this.nodeDescriptorView.view = view || "initiate";
      });
    },
    exportNodeDescriptors(node, showSubLevel) {
      let data = `"${node.label.toUpperCase()}:","${node.name}"\n\n`;
      if (!node.nodes?.length && !node.rows?.length) {
        this.getNodes(node.level + 1, node.filterKeys, node);
      }
      if (node.nodes?.length) {
        let line1 = `"${node.nodes[0].label.toUpperCase()}:"`;
        let line2 = `"EMPLOYEES:"`;
        let line3 = `"DESCRIPTION:"`;
        let line4 = [];
        let separator = ",";
        let line4fill = `""`;
        node.nodes.forEach((n) => {
          if (showSubLevel) {
            if (!n.nodes?.length && !n.rows?.length) {
              this.getNodes(n.level + 1, n.filterKeys, n);
            }
            if (!line4.length) {
              line4.push(`"${n.nodes[0].label.toUpperCase()}:"`);
            }
            line4.forEach((l) => (l += `,`));
            n.nodes.forEach((nn, nni) => {
              if (line4.length - 1 < nni) line4.push(line4fill);
              line4[nni] += `,"${nn.name || "UNKNOWN"} [${nn.employee_count}]"`;
            });
            line4.forEach((ll, li) => {
              if (li >= n.nodes.length) {
                line4[li] = ll + `,""`;
              }
            });
            line4fill += `,`;
          }
          line1 += `${separator}"${n.name}"`;
          line2 += `${separator}${n.employee_count}`;
          let desc = n.node.descriptor
            ? n.node.descriptor.description || ""
            : "";
          line3 += `${separator}"${desc
            .replace(/"/g, '""')
            .replace(/\n/g, " ")}"`;
        });
        data += `${line1}\n${line2}\n${line3}\n` + line4.join(`\n`);
      }
      utils.downloadFile(
        data,
        `${node.label} - ${node.name} Descriptors.csv`,
        "text/csv;encoding:utf-8"
      );
    },
    exportLVToCSV() {
      let cols = this.lvColumnsFiltered
        .map((x, xi) => {
          return {
            text: x.text,
            value: x.value,
            sequence: x.ht_id ? xi * -1 : xi,
          };
        })
        .sort((a, b) =>
          a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0
        );
      let data = cols.map((h) => '"' + h.text + '"').join(",");
      data += "\n";

      this.groupedDocs.forEach((d) => {
        data += cols
          .map((h) =>
            utils.csvEscape(utils.removeTags(d[h.value]).replace(/\n/g, "|"))
          )
          .join(",");
        data += "\n";
      });

      utils.downloadFile(data, "JobFamily.csv", "text/csv;encoding:utf-8");
    },
    afterInit() {
      if (this.resetIsBuilding) {
        this.isBuilding = false;
        this.resetIsBuilding = false;
      }
      if (this.selectedView?.viewType !== "catalogue" || this.isLoadingData)
        return;
      this.setUpVscroll_();
      const isLoadingData = this.isLoadingData;
      this.isLoadingData = false;
      if (isLoadingData) {
        this.buildCompare();
      }
    },
    setHScroll() {
      //   let hs = this.$refs.colhead.scrollLeft;
      let hd = this.$refs.detailH.scrollLeft;
      let fs = this.$refs.colfoot.scrollLeft;
      if (fs !== hd) {
        this.$refs[this.layout.headerRow.ref].scrollLeft = fs;
        this.layout.hierarchyHeaderRows.forEach((hr) => {
          this.$refs[hr.ref].forEach((r) => (r.scrollLeft = fs));
        });
        // this.layout.groupHeaderRow.vRows.forEach((gh) => {
        //   this.$refs[gh.ref].forEach((r) => (r.scrollLeft = fs));
        // });
        if (this.layout.groupHeaderRow.vRows.length) {
          this.$refs[this.layout.groupHeaderRow.ref].forEach((h) => {
            h.scrollLeft = fs;
          });
        }
        this.$refs.detailH.scrollLeft = fs;
      }
    },
    wheelVScroll(data) {
      if (data) {
        this.setPage_(data.deltaY >= 0);
      }
    },
    setVScroll_() {
      if (this.vScroll.inProgress) {
        this.vScroll.inProgress = false;
        return;
      }
      let sl = this.$refs.scrollContainer.scrollTop;
      let max = this.$refs.scrollContainer.scrollHeight;
      if (sl === 0) {
        this.vScroll.dStart = 0;
      } else {
        this.vScroll.dStart = parseInt((this.vScroll.dMax * sl) / max);
      }
      this.getNextPageSize();
    },
    setUpVscroll_() {
      if (this.vScroll.doSetup && this.$refs.detailRow) {
        const pos = this.$refs.detailRow.getBoundingClientRect();
        const detailRowHeight = window.innerHeight - pos.top - 30;
        this.$refs.detailRow.style.height = detailRowHeight + "px";
        this.vScroll.pageHeight = detailRowHeight;
        this.vScroll.dStart = 0;
        this.vScroll.pageSize = this.getNextPageSize();
        let rowHeight = detailRowHeight / this.vScroll.pageSize; //this.display.z_col.show ? 35 : 50;
        this.$refs.scrollBar.style.height =
          this.vScroll.dMax * (rowHeight + 5) + "px";
        this.vScroll.doSetup = false;
      }
    },
    getNextPageSize() {
      let i = this.vScroll.dStart;
      let available = this.vScroll.pageHeight;
      let usedHeight = 0;
      let endI = i; //this.vScroll.dMax;
      this.layout.detailRow.rowHeadings.some((x) => {
        if (x.dIndexEnd >= i) {
          if (x.dIndexStart === x.dIndexEnd) {
            usedHeight += 50;
            if (usedHeight < available) {
              endI = x.dIndexStart;
            }
          } else {
            let isFullSection = !(
              x.detailCountHeader.length || x.categories.some((c) => c.dIndex)
            );
            if (!isFullSection && endI > i) usedHeight += 16;
            usedHeight += isFullSection ? 50 : 34;
            if (usedHeight < available) {
              if (!isFullSection) {
                // adj = 1;
                endI++;
                x.detailCountHeader
                  .filter((d) => d.dIndex >= endI)
                  .some((d) => {
                    usedHeight += 34;
                    if (usedHeight < available) {
                      endI = d.dIndex;
                    }
                    return usedHeight >= available;
                  });
                x.categories
                  .filter((d) => d.dIndex >= endI)
                  .some((d) => {
                    usedHeight += 32;
                    if (usedHeight < available) {
                      endI = d.dIndex;
                    }
                    return usedHeight >= available;
                  });
              } else {
                endI = x.dIndexEnd;
              }
            } else {
              return true;
            }
          }
        }
        return usedHeight >= available;
      });
      this.vScroll.dEnd = endI;
      if (
        this.vScroll.dEnd >= this.vScroll.dMax &&
        this.vScroll.dStart > 0 &&
        usedHeight + 34 < available
      ) {
        this.vScroll.dEnd = this.vScroll.dMax;
        return this.getPreviousPageSize();
      } else {
        this.setCurrentHierarchyPage();
        return this.vScroll.dEnd - this.vScroll.dStart + 1;
      }
    },
    getPreviousPageSize() {
      let i = this.vScroll.dEnd;
      let available = this.vScroll.pageHeight;
      let usedHeight = 0;
      let startI = i;
      this.layout.detailRow.rowHeadings
        .slice()
        .reverse()
        .some((x) => {
          if (x.dIndexStart <= i) {
            if (x.dIndexStart === x.dIndexEnd) {
              usedHeight += 50;
              if (usedHeight < available) {
                startI = x.dIndexStart;
              }
            } else {
              let isFullSection = !(
                x.detailCountHeader.length || x.categories.some((c) => c.dIndex)
              );
              if (!isFullSection && startI < i) usedHeight += 16;
              x.categories
                .filter((d) => d.dIndex <= startI)
                .slice()
                .reverse()
                .some((d) => {
                  usedHeight += 32;
                  if (usedHeight < available) {
                    startI = d.dIndex;
                  }
                  return usedHeight >= available;
                });
              x.detailCountHeader
                .filter((d) => d.dIndex <= startI)
                .slice()
                .reverse()
                .some((d) => {
                  usedHeight += 34;
                  if (usedHeight < available) {
                    startI = d.dIndex;
                  }
                  return usedHeight >= available;
                });
              usedHeight += isFullSection ? 50 : 34;
              if (usedHeight < available) {
                startI--;
              }
            }
          }
          return usedHeight >= available;
        });
      this.vScroll.dStart = startI;
      if (
        this.vScroll.dStart <= 0 &&
        this.vScroll.dEnd < this.vScroll.dMax &&
        usedHeight + 34 < available
      ) {
        this.vScroll.dStart = 0;
        return this.getNextPageSize();
      } else {
        this.setCurrentHierarchyPage();
        return this.vScroll.dEnd - this.vScroll.dStart + 1;
      }
    },
    setCurrentHierarchyPage() {
      if (this.layout.detailRow.hierarchyHeadings) {
        const dStart = this.vScroll.dStart;
        const dEnd = this.vScroll.dEnd;
        let heightCalc = (l) => {
          return !l.detailCountHeader
            ? 50
            : l.detailCountHeader
                .filter((x) => x >= dStart && x <= dEnd)
                .reduce((p) => p + 34, l.dIndexStart >= dStart ? 50 : 16);
        };
        let mapLevels = (levels) => {
          return levels
            .filter((x) => x.dIndexStart <= dEnd && x.dIndexEnd >= dStart)
            .map((l, li) => {
              let childLevels = mapLevels(l.levels);
              let height = childLevels.length
                ? childLevels.reduce((p, c) => p + c.heightVal, 0)
                : heightCalc(l);
              let isFirst = l.isFirst || li === 0;

              let currLevel = this.verticalCompareOption.hierarchyLevels.find(
                (h) => h.hLevel === l.level
              );
              let nextLevel = this.verticalCompareOption.hierarchyLevels.find(
                (h) => h.hLevel === l.level + 1
              );
              let prevLevel =
                l.level > 1
                  ? this.verticalCompareOption.hierarchyLevels.find(
                      (h) => h.hLevel === l.level - 1
                    )
                  : null;
              let hierarchyDescriptor = currLevel.usesDescriptor
                ? this.getHierarchyTypeNode(l.ht_id, l.hierarchy_node_id)
                : null;
              let node = {
                level: l.level,
                hierarchy_node_id: l.hierarchy_node_id,
                name: l.value,
                descriptor: hierarchyDescriptor,
                ht_id: l.ht_id,
                label: currLevel.ht_label,
                nextLevel: nextLevel ? nextLevel.ht_label : null,
                previousLevel: prevLevel ? prevLevel.ht_label : null,
                // hasHistory: this.getNode(l.ht_id, l.hierarchy_node_id)
                //   ?.hasHistory,
                canAdd:
                  !this.isDemo &&
                  l.level === this.verticalCompareOption.linklevel - 1,
                canAddDocumentType:
                  !this.isDemo && !nextLevel && l.job_count === 0
                    ? this.catalogueDocType
                    : null,
                reviewStatus: this.getHierarchyNodeReviewStatus(
                  l.hierarchy_node_id
                ),
                showNodes: null,
              };
              this.appendMeasureColumns(node);
              this.appendNodeHierarchyLevels(node);

              return {
                cols: l.cols,
                value: l.value,
                hierarchy_node_id: l.hierarchy_node_id,
                ht_id: l.ht_id,
                level: l.level,
                heightVal: height,
                height: height + "px",
                childCols: l.childCols,
                isFirst: isFirst,
                isLast: l.isLast,
                vLineY1: isFirst && !l.isLast ? 24 : 0,
                vLineY2:
                  l.isLast && !isFirst ? 24 : isFirst && l.isLast ? 0 : "100%",
                levels: childLevels,
                class: l.detailCountHeader?.length ? "" : "flex-nowrap",
                hierarchyDescriptor: hierarchyDescriptor,
                node: node,
              };
            });
        };
        this.layout.detailRow.hierarchyHeadings.pageLevels = mapLevels(
          this.layout.detailRow.hierarchyHeadings.levels
        );
      }
    },
    setScroll() {
      this.vScroll.inProgress = true;
      let max = this.$refs.scrollContainer.scrollHeight;
      this.$refs.scrollContainer.scrollTop =
        (max * this.vScroll.dStart) / this.vScroll.dMax;
    },
    setPage_(plus) {
      if (
        plus &&
        this.vScroll.dStart <= this.vScroll.dMax &&
        this.vScroll.dEnd < this.vScroll.dMax
      ) {
        this.vScroll.dStart = this.vScroll.dEnd + 1;
        this.getNextPageSize();
        this.setScroll();
      } else if (!plus && this.vScroll.dEnd > 0 && this.vScroll.dStart > 0) {
        this.vScroll.dEnd = this.vScroll.dStart - 1;
        this.getPreviousPageSize();
        this.setScroll();
      }
    },
    deriveCols(isHierarchyTree) {
      this.display.showLeafCount =
        !!this.verticalCompareOption.ht_id && !isHierarchyTree;
      let label = this.catalogueDocType;
      if (this.display.showLeafCount) {
        label = this.verticalCompareOption.drillDownLevels
          ? this.verticalCompareOption.drillDownLevels[
              this.verticalCompareOption.drillDownLevels.length - 1
            ].ht_label
          : this.verticalCompareOption.hierarchyLevels[
              this.verticalCompareOption.hierarchyLevels.length - 1
            ].ht_label;
        this.display.total_col.leafCountLabel =
          label
            .split(" ")
            .filter((x) => x)
            .map((x) => x.substr(0, 1))
            .join("") + "s";
        this.display.total_col.leafCountTitle = `Number of ${label}s in Hierarchy`;
      }
      let expandedH = this.display.z_col.showLabel && this.showDetail;

      let hColsMeasureWidth = this.measures
        .filter((m) => m.visible)
        .reduce((p, c) => p + c.relative_width, 0);
      if (expandedH) {
        this.display.z_col.colWidthLabel = 12 - hColsMeasureWidth;
      } else {
        this.display.z_col.colWidthLabel = 0;
      }
      //   let visMeasures = this.measures.filter((m) => m.visible).length;
      this.measures.forEach((m) => {
        m.h_col_width = !m.visible
          ? 0
          : m.relative_width *
            (expandedH ? 1 : parseInt(12 / hColsMeasureWidth));
        m.z_col_width = m.h_col_width;
        // m.alignRight = visMeasures === 2 && mi === 0;
        m.total_col_width = !m.visible
          ? 0
          : m.relative_width *
            parseInt(
              12 / (hColsMeasureWidth + (this.display.showLeafCount ? 1 : 0))
            );
      });

      this.display.total_col.colWidthLeafCount = !this.display.showLeafCount
        ? 0
        : parseInt(12 / (hColsMeasureWidth + 1));

      let z_colShowCount =
        !this.detailCompareOption.isSubLevel &&
        !this.horizontalCompareOption.isSubLevel &&
        !this.verticalCompareOption.isSubLevel;

      this.measures.forEach(
        (m) => (m.z_show = m.aggregation === "count" ? z_colShowCount : true)
      );

      this.display.h_col.colWidth = this.hideHorizontal
        ? 0
        : expandedH
        ? this.display.showLeafCount
          ? 4
          : 3
        : 1;
    },
    drawOrgChart() {
      return;
    },
    selectJDs() {
      this.isBuilding = true;
      let filters = this.compareOptionsV
        .filter((x) => x.filterValues.length)
        .concat(this.compareOptionsH.filter((x) => x.filterValues.length));
      let hfilters = this.hierarchyFilters
        .filter((x) => x.selected.length)
        .map((x) => {
          return {
            ht_id: x.ht_id,
            label: x.label,
            selected: x.selected.map((s) => {
              return {
                value: Number(s.id.split("_")[0]),
                name: s.value,
              };
            }),
          };
        });
      let cfilters = this.lvColumnsFiltered
        .filter(
          (x) =>
            x.filterSettings && x.filterSettings.values.some((v) => v.selected)
        )
        .map((v) => {
          return {
            column: v.value,
            filterValues: v.filterSettings.values
              .filter((vv) => vv.selected)
              .map((vv) => vv.text),
          };
        });
      this.selectedDocs = _documents.filter((d) => {
        if (
          filters.length === 0 &&
          hfilters.length === 0 &&
          this.drilldownFilters.length === 0 &&
          cfilters.length === 0 &&
          !this.measureFilter?.type
        ) {
          return true;
        } else {
          if (
            this.measureFilter &&
            this.measureFilter.type &&
            (d[this.measureFilter.type] || 0) === 0
          )
            return false;
          let result = true;
          hfilters.forEach((hf) => {
            if (
              !hf.selected.some((s) => {
                return d[`ht_id_${hf.ht_id}`] === s.value;
              })
            )
              result = false;
          });
          this.drilldownFilters.forEach((df) => {
            // if (df.value === "UNCLASSIFIED") {
            //   if (d[df.column]) result = false;
            // } else {
            if (d[df.column] !== df.value) result = false;
            // }
          });
          return (
            result &&
            filters.every((f) => {
              return f.filterValues.some((fv) => d[f.column] === fv);
            }) &&
            (this.drilldownFilters.length === 0 ||
              this.drilldownFilters.every(
                (df) =>
                  // df.value === "UNCLASSIFIED"
                  //   ? !d[df.column]
                  //	  :
                  d[df.column] === df.value
              )) &&
            (cfilters.length === 0 ||
              cfilters.every((f) => {
                return f.filterValues.some((fv) => d[f.column] === fv);
              }))
          );
        }
      });
      this.filterList = filters.map((f) => {
        return {
          title: `${f.name} = [${f.filterValues.join(", ")}]`,
          name: f.name,
        };
      });
      hfilters
        .filter((x) => x.selected.length)
        .forEach((f) => {
          this.filterList.push({
            title: `${f.label} = [${
              f.selected.length > 3
                ? f.selected.length + " items"
                : f.selected.map((x) => x.name).join(", ")
            }]`,
            name: f.label,
            ht_id: f.ht_id,
          });
        });
      this.drilldownFilters.forEach((df) => {
        let htF = this.filterList.find((x) => x.ht_id === df.ht_id && x.level);
        if (htF) {
          htF.title += " -> " + df.value;
        } else {
          this.filterList.push({
            title: `${df.name} = ${df.value}`,
            name: df.name,
            ht_id: df.ht_id,
            level: df.level,
          });
        }
      });
      this.lvColumnsFiltered
        .filter(
          (x) =>
            x.filterSettings && x.filterSettings.values.some((v) => v.selected)
        )
        .forEach((f) => {
          let values = f.filterSettings.values.filter((v) => v.selected);
          this.filterList.push({
            title: `${f.text} = [${
              values.length > 3
                ? values.length + " items"
                : values.map((x) => x.text).join(", ")
            }]`,
            name: f.text,
            column: f,
          });
        });
      if (this.selectedView.viewType === "catalogue") {
        this.buildCompare();
      } else {
        this.buildGroupedDocs();
      }
      this.resetIsBuilding = true;
    },
    getAggregateMeasures() {
      let aggregateColumns = this.measures.filter(
        (m) => m.aggregation === "mean"
      );
      if (!aggregateColumns.length && this.showSalary)
        aggregateColumns.push({
          type: "salary",
          aggregation: "mean",
          aggregation_quantity_column: "employee_count",
          aggregation_exclude_zero: true,
          format: "£2k",
        });
      return aggregateColumns;
    },
    getMeasureColumns() {
      let ms = this.measures.map((m) => m.type);
      ms.push("leaf_node_count");
      return ms;
    },
    appendMeasureColumns(subject, defaultValue, source) {
      let item = subject || {};
      this.measures.map((m) => {
        item[m.type] =
          source && source[m.type] !== undefined
            ? Number(source[m.type])
            : defaultValue !== undefined
            ? defaultValue
            : null;
      });
      if (!subject) return item;
    },
    appendAggregateColumns(subject, defaultValue, source) {
      let item = subject || {};
      this.measures
        .filter((m) => m.aggregation === "mean")
        .forEach((m) => {
          item[m.type + "_mean"] =
            source && source[m.type + "_mean"] !== undefined
              ? source[m.type + "_mean"]
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_mean_number"] =
            source && source[m.type + "_mean_number"] !== undefined
              ? Number(source[m.type + "_mean_number"])
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_mean_value_count"] =
            source && source[m.type + "_mean_value_count"] !== undefined
              ? Number(source[m.type + "_mean_value_count"])
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_std_dev"] =
            source && source[m.type + "_std_dev"] !== undefined
              ? Number(source[m.type + "_std_dev"])
              : defaultValue !== undefined
              ? defaultValue
              : null;
          item[m.type + "_category"] =
            source && source[m.type + "_category"] !== undefined
              ? source[m.type + "_category"]
              : null;
        });
      if (!subject) return item;
    },
    buildGroupedDocs() {
      let hchyCols = this.lvColumnsFiltered.filter((c) => c.ht_id);
      let sortCol = hchyCols.find((x) => x.level === 1)?.value;
      let sortFunc = (a, b) =>
        a[sortCol] > b[sortCol] ? 1 : a[sortCol] < b[sortCol] ? -1 : 0;
      let groupCols = this.lvColumnsFiltered
        .filter((x) => x.isAttribute || x.ht_id)
        .map((x) => x.value);
      hchyCols.forEach((x) => {
        groupCols.push(x.value + "nodeID");
      });
      let isGroupedView = this.selectedView.viewType === "grouped";
      let groups = this.groupByKeys(
        this.selectedDocs,
        groupCols,
        this.getMeasureColumns(),
        null,
        this.getAggregateMeasures()
      );
      let groupedDocs = groups.rows.map((x) => {
        x.selected = false;
        if (!isGroupedView) {
          hchyCols.forEach((c) => {
            let nextLevel = hchyCols.find(
              (h) => h.ht_id === c.ht_id && h.level === c.level + 1
            );
            let prevLevel = hchyCols.find(
              (h) => h.ht_id === c.ht_id && h.level === c.level - 1
            );
            let hierarchyDescriptor = c.usesDescriptor
              ? this.getHierarchyTypeNode(c.ht_id, x[c.value + "nodeID"])
              : null;
            let node = {
              level: c.level,
              hierarchy_node_id: x[c.value + "nodeID"],
              //hierarchy_node_id_parent: prevLevel ? x[prevLevel.value + "nodeID"] : null,
              name: x[c.value],
              descriptor: hierarchyDescriptor,
              ht_id: c.ht_id,
              label: c.text,
              nextLevel: nextLevel ? nextLevel.text : null,
              previousLevel: prevLevel ? prevLevel.text : null,
              canAdd:
                !this.isDemo &&
                c.level ===
                  this.hierarchyTypes.find((y) => y.ht_id === c.ht_id)
                    .linklevel -
                    1,
              canAddDocumentType:
                !this.isDemo && !nextLevel && x.job_count === 0
                  ? this.catalogueDocType
                  : null,
              reviewStatus: this.getHierarchyNodeReviewStatus(
                x[c.value + "nodeID"]
              ),
              show: false,
              showNodes: null,
            };
            this.appendMeasureColumns(node);
            this.appendNodeHierarchyLevels(node);
            x[c.value + "isNode"] = node;
          });
        }
        return x;
      });
      this.groupedDocs = groupedDocs.sort(sortFunc);
      if (isGroupedView) {
        this.buildGroupedViewDocs(1);
      } else {
        this.measures.forEach((m) => {
          let mc = this.listView.columns.find((c) => c.value === m.type);
          if (mc && !mc.filterSettings.values.length) {
            mc.filterSettings.values = this.distinctValues(groupedDocs, [
              m.type,
            ])
              .map((x, xi) => {
                return {
                  text: x[m.type],
                  selected: false,
                  visible: true,
                  available: true,
                  count: x._count,
                  searchValues: [x[m.type]],
                  page: parseInt(xi / 7) + 1,
                };
              })
              .sort((a, b) =>
                Number(a.text) > Number(b.text)
                  ? 1
                  : Number(a.text) < Number(b.text)
                  ? -1
                  : 0
              );
            mc.filterSettings.pages =
              parseInt((mc.filterSettings.values.length - 1) / 7) + 1;
          }
          if (mc)
            groups.keyValues.push({
              key: m.type,
              values: mc.filterSettings.values.map((x) => x.text),
            });
        });
      }
      this.groupsKeyValues = groups.keyValues;
      this.resetIsBuilding = true;
    },
    openListviewFilter(filterSettings) {
      if (!filterSettings.show) {
        filterSettings.values
          .filter((v) => v.selected && !v.applied)
          .forEach((v) => (v.selected = false));
        filterSettings.values
          .filter((v) => !v.selected && v.applied)
          .forEach((v) => (v.selected = true));
        return;
      }
      filterSettings.values
        .filter((v) => !v.selected && v.applied)
        .forEach((v) => (v.applied = false));
      if (filterSettings.mostRecent) return;
      let usedValues = this.groupsKeyValues.find(
        (x) => x.key === filterSettings.column
      )?.values;
      let valueCount = 0;
      if (
        usedValues.length === filterSettings.values.length ||
        (this.activeFilterCount === 1 && filterSettings.isActive)
      ) {
        valueCount = filterSettings.values.length;
        filterSettings.values.forEach((x, xi) => {
          x.visible = true;
          x.page = parseInt(xi / 7) + 1;
        });
      } else {
        filterSettings.values.forEach((v) => {
          v.visible = usedValues.some((uv) => uv === v.text);
          v.page = v.visible ? parseInt(valueCount / 7) + 1 : 0;
          if (v.visible) valueCount++;
        });
      }
      filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    buildFilterSettingsValues(keyValues) {
      this.lvColumnsFiltered
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          let usedValues = keyValues.find((x) => x.key === c.value)?.values;
          let valueCount = 0;
          if (usedValues) {
            c.filterSettings.values.forEach((v) => {
              v.available = usedValues.some((uv) => uv === v.text);
              v.page = v.available ? parseInt(valueCount / 7) + 1 : 0;
              if (v.available) valueCount++;
            });
          } else {
            valueCount = c.filterSettings.values.length;
          }
          c.filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
          c.filterSettings.page = 1;
        });
    },
    buildGroupedViewDocs(level) {
      let groupedViewDetailColumns = this.lvColumnsFiltered.filter(
        (x) => x.isAttribute || (x.ht_id && x.level === level)
      );
      this.measures
        .filter((m) => m.visible)
        .forEach((m) => {
          groupedViewDetailColumns.push({
            text: m.abbreviation,
            value: m.type,
            sortable: true,
            cols: 1,
            measure: m,
          });
        });
      let groupedViewLevelColumns = this.lvColumnsFiltered
        .filter((x) => x.ht_id)
        .map((x) => {
          x.cols = 3;
          let ret = [
            x,
            {
              text: "Description",
              value: "description",
              isDescriptor: true,
              measure: null,
            },
          ];
          this.measures.forEach((m) => {
            ret.push({
              text: m.abbreviation,
              value: m.type,
              sortable: true,
              cols: 1,
              measure: m,
            });
          });
          return ret;
        });
      this.groupedViewLevelColumns = groupedViewLevelColumns;
      this.groupedViewDetailColumns = groupedViewDetailColumns;
      let groupedViewDocs = this.getNodes(1, []);
      this.groupedViewDocs = groupedViewDocs;
      this.resetIsBuilding = true;
    },
    groupViewDrilldown(groupItem) {
      if (
        !groupItem.show &&
        !groupItem.nodes?.length &&
        !groupItem.rows?.length
      ) {
        this.getNodes(groupItem.level + 1, groupItem.filterKeys, groupItem);
      }
      groupItem.show = !groupItem.show;
      groupItem.node.show = !groupItem.node.show;
    },
    getNodes(level, parentKeys, parent, doNested) {
      if (level - 1 < this.groupedViewLevelColumns.length) {
        let levelCol = this.groupedViewLevelColumns[level - 1][0];
        let sk = levelCol.value;
        let sortFunc = (a, b) => (a[sk] > b[sk] ? 1 : a[sk] < b[sk] ? -1 : 0);
        let nodes = this.groupByKeys(
          !parent
            ? this.groupedDocs
            : this.groupedDocs.filter((x) =>
                parentKeys.every((k) => x[k.col] === k.value)
              ),
          [sk, sk + "nodeID"],
          this.getMeasureColumns(),
          null,
          this.getAggregateMeasures()
        )
          .rows.sort(sortFunc)
          .map((x) => {
            x.show = false;
            x.name = x[sk];
            x.label = levelCol.text;
            return x;
          });
        nodes.forEach((n) => {
          n.keyVal = (parent ? parent.keyVal : "") + n.keyVal;
          n.filterKeys = parentKeys.concat([{ col: sk, value: n[sk] }]);
          n.level = level;
          n.selected = false;
          n.nodes = [];
          n.rows = [];
          let nextLevel =
            this.groupedViewLevelColumns.length > level
              ? this.groupedViewLevelColumns[level][0]
              : null;
          let prevLevel =
            level > 1 ? this.groupedViewLevelColumns[level - 2][0] : null;
          let hierarchyDescriptor = levelCol.usesDescriptor
            ? this.getHierarchyTypeNode(levelCol.ht_id, n[sk + "nodeID"])
            : null;
          let node = {
            level: n.level,
            hierarchy_node_id: n[sk + "nodeID"],
            name: n[sk],
            descriptor: hierarchyDescriptor,
            ht_id: levelCol.ht_id,
            label: n.label,
            nextLevel: nextLevel ? nextLevel.text : null,
            previousLevel: prevLevel ? prevLevel.text : null,
            // hasHistory: this.getNode(n.ht_id, n.hierarchy_node_id)?.hasHistory,
            canAdd:
              !this.isDemo &&
              n.level ===
                this.hierarchyTypes.find((y) => y.ht_id === levelCol.ht_id)
                  .linklevel -
                  1,
            canAddDocumentType:
              !this.isDemo && !nextLevel && n.job_count === 0
                ? this.catalogueDocType
                : null,
            reviewStatus: this.getHierarchyNodeReviewStatus(n[sk + "nodeID"]),
            show: false,
            filterKeys: n.filterKeys,
            showNodes: null,
          };
          this.appendMeasureColumns(node);
          this.appendAggregateColumns(node, 0, n);
          this.appendNodeHierarchyLevels(node);
          n.node = node;
          if (doNested) {
            this.getNodes(level + 1, n.filterKeys, n, doNested);
          }
        });
        if (parent) {
          parent.nodes = nodes;
        } else {
          return nodes;
        }
      } else {
        parent.rows = this.groupedDocs
          .filter((x) => parentKeys.every((k) => x[k.col] === k.value))
          .map((x) => {
            x.filterKeys = parentKeys.concat(
              this.groupedViewDetailColumns
                .filter((c) => c.isAttribute)
                .map((c) => {
                  return { col: c.value, value: x[c.value] };
                })
            );
            x.level = 999;
            return x;
          });
      }
    },
    removeFilter(f) {
      [this.compareOptionsV, this.compareOptionsH].forEach((o) => {
        let fl = o.find((x) => x.name === f.name);
        if (fl) {
          fl.filterValues = [];
          if (fl.currentDrillDownLevel !== undefined)
            fl.currentDrillDownLevel = 1;
        }
      });
      let hfilter = this.hierarchyFilters.find((x) => x.ht_id === f.ht_id);
      if (hfilter) hfilter.selected = [];
      let lvfilter = this.lvColumnsFiltered.find(
        (x) => x.value === f.column?.value
      );
      if (lvfilter) {
        lvfilter.filterSettings.values.forEach((x) => {
          x.selected = false;
          x.applied = false;
        });
        lvfilter.filterSettings.isActive = false;
      }
      if (
        this.drilldownFilters.length &&
        f.level &&
        this.drilldownFilters.find((x) => x.ht_id === f.ht_id)
      ) {
        this.drilldownFilters = this.drilldownFilters.filter(
          (x) => x.ht_id !== f.ht_id
        );
        this.compareOptionsV
          .filter((x) => x.ht_id === f.ht_id && x.hLevel > 1)
          .forEach((x) => (x.hidden = true));
        this.compareOptionsH
          .filter((x) => x.ht_id === f.ht_id && x.hLevel > 1)
          .forEach((x) => (x.hidden = true));

        if (
          this.horizontalCompareOption.ht_id === f.ht_id &&
          this.horizontalCompareOption.hLevel
        ) {
          this.horizontalCompareOption = this.compareOptionsH.find(
            (x) => x.ht_id === f.ht_id && x.hLevel === 1
          );
        }
        if (
          this.verticalCompareOption.ht_id === f.ht_id &&
          this.verticalCompareOption.hLevel
        ) {
          this.verticalCompareOption = this.compareOptionsV.find(
            (x) => x.ht_id === f.ht_id && x.hLevel === 1
          );
        }
        if (
          this.verticalCompareOption.ht_id === f.ht_id &&
          this.verticalCompareOption.currentDrillDownLevel
        ) {
          this.verticalCompareOption.currentDrillDownLevel = 1;
        }
        if (
          this.horizontalCompareOption.ht_id === f.ht_id &&
          this.horizontalCompareOption.currentDrillDownLevel
        ) {
          this.horizontalCompareOption.currentDrillDownLevel = 1;
        }
      }
      this.selectJDs();
    },
    doDrilldown(item, compareOptionName) {
      if (!item.drilldown) return;
      let compareOption = this[compareOptionName];
      let cols =
        item.dIndexEnd === undefined
          ? []
          : this.layout.detailRow.rowDetails
              .find(
                (x) =>
                  x.dIndexStart === item.dIndexStart &&
                  x.dIndexEnd === item.dIndexEnd
              )
              .columns.filter((x) => this.measures.some((m) => x[m.type]))
              .map((x) => {
                let ret = {
                  cols: x.cols,
                  visible: true,
                  keyVal: x.col.keyVal,
                  matchColumns: [
                    this.getColumnName(compareOption),
                    x.col.column,
                  ],
                  matchKey:
                    "|" + item.drilldown.value + "||" + x.col.keyVal + "|",
                  selected: false,
                };
                this.appendMeasureColumns(ret, 0, x);
                this.appendAggregateColumns(ret, 0, x);
                return ret;
              });
      this.drilldownFilters
        .filter(
          (x) =>
            x.ht_id === compareOption.ht_id &&
            x.columns?.length &&
            x.compareOptionName === compareOptionName
        )
        .forEach((x) => {
          x.columns.forEach(
            (c) => (c.visible = cols.some((v) => v.keyVal === c.keyVal))
          );
        });
      let f = {
        column: this.getColumnName(compareOption),
        matchColumns: [this.getColumnName(compareOption)],
        matchKey: "|" + item.drilldown.value + "|",
        value: item.drilldown.value,
        ht_id: compareOption.ht_id,
        level: compareOption.currentDrillDownLevel,
        name: compareOption.name,
        leaf_node_count: item.leaf_node_count,
        columns: cols,
        compareOptionName: compareOptionName,
        selected: false,
      };
      this.appendMeasureColumns(f, 0, item);
      this.appendAggregateColumns(f, 0, item);
      this.drilldownFilters.push(f);
      compareOption.currentDrillDownLevel++;
      this.selectJDs();
    },
    removeGroupHeader(ghRow, compareOptionName) {
      let compareOption = this[compareOptionName];
      let toRemove = this.drilldownFilters
        .filter(
          (x) =>
            x.ht_id === ghRow.ht_id &&
            x.level >= ghRow.level &&
            x.compareOptionName === compareOptionName
        )
        .map((x) => x.level);
      toRemove.forEach((x) => {
        let i = this.drilldownFilters.findIndex(
          (f) =>
            f.ht_id === ghRow.ht_id &&
            f.level === x &&
            f.compareOptionName === compareOptionName
        );
        this.drilldownFilters.splice(i, 1);
      });
      if (ghRow.columns) {
        // vgroupHeader
        let currentFilter = this.drilldownFilters.find(
          (f) =>
            f.ht_id === ghRow.ht_id &&
            f.level === ghRow.level - 1 &&
            f.compareOptionName === compareOptionName
        );
        if (currentFilter)
          this.drilldownFilters
            .filter(
              (x) =>
                x.ht_id === compareOption.ht_id &&
                x.columns?.length &&
                x.compareOptionName === compareOptionName
            )
            .forEach((x) => {
              x.columns.forEach(
                (c) =>
                  (c.visible = currentFilter.columns.some(
                    (v) => v.keyVal === c.keyVal
                  ))
              );
            });
      }
      if (compareOption.currentDrillDownLevel !== undefined)
        compareOption.currentDrillDownLevel = ghRow.level;
      this.selectJDs();
    },
    buildCompare() {
      if (this.isLoadingData) return;
      this.resetCompare();
      if (this.selectedDocs && this.selectedDocs.length) {
        this.isBuilding = true;
        let docs = this.selectedDocs.filter(
          // for now until work out how to expand right cols to have a row
          //(x) => x.job_count && (x.similar_count_JT || !this.showDuplicatesOnly)
          (x) => x.similar_count_JT || !this.showDuplicatesOnly
        );
        if (!docs.length) return;
        let hCompare = this.horizontalCompareOption;
        let vCompare = this.verticalCompareOption;
        let zCompare = this.detailCompareOption;
        if (!hCompare || !vCompare || !zCompare) return;
        let isBreakdown = vCompare.isBreakdown;
        let salM = this.measures.find((m) => m.type === "salary");
        if (salM) salM.visible = this.showSalary;
        // this.display.z_col.show = this.showDetail;
        this.display.z_col.showLabel = this.showDetail && !zCompare.isSubLevel;
        this.display.total_col.showZ = !!zCompare.isSubLevel;
        this.visibleMeasures = this.measures.filter((m) => m.visible);
        const vComparecolumn = this.getColumnName(vCompare);
        const hComparecolumn = this.getColumnName(hCompare);
        const zComparecolumn = this.getColumnName(zCompare);
        let vCols = this.groupByKeysNested(
          docs,
          [vCompare, hCompare, zCompare],
          this.getMeasureColumns(),
          null,
          this.getAggregateMeasures()
        )[vComparecolumn + "s"];
        let hCols = this.groupByKeysNested(
          docs,
          [hCompare],
          this.getMeasureColumns(),
          null,
          null
        )[hComparecolumn + "s"].filter((x) => x.job_count);
        let decorateCols = (cols, comparer) => {
          let isHierarchyTree = null;
          cols.forEach((g) => {
            let ht = comparer.ht_id
              ? this.hierarchyTypes.find((ht) => ht.ht_id === comparer.ht_id)
              : null;
            let val = g.title;
            if (ht && !comparer.currentDrillDownLevel) isHierarchyTree = ht;
            // g.hierarchyLevel = ht
            //   ? ht["level" + comparer.currentDrillDownLevel + "_name"]
            //   : null;
            if (ht && comparer.drillDownLevels) {
              let nextLevel =
                comparer.drillDownLevels.length > comparer.currentDrillDownLevel
                  ? comparer.drillDownLevels.find(
                      (dl) => dl.hLevel === comparer.currentDrillDownLevel + 1
                    )
                  : null;
              let prevLevel =
                comparer.currentDrillDownLevel > 1
                  ? comparer.drillDownLevels.find(
                      (dl) => dl.hLevel === comparer.currentDrillDownLevel - 1
                    )
                  : null;
              let currLevel = comparer.drillDownLevels.find(
                (dl) => dl.hLevel === comparer.currentDrillDownLevel
              );
              let hierarchyDescriptor = currLevel.usesDescriptor
                ? this.getHierarchyTypeNode(comparer.ht_id, g.hierarchy_node_id)
                : null;
              let node = {
                level: comparer.currentDrillDownLevel,
                hierarchy_node_id: g.hierarchy_node_id,
                name: val,
                descriptor: hierarchyDescriptor,
                ht_id: comparer.ht_id,
                label: currLevel.ht_label,
                nextLevel: nextLevel ? nextLevel.ht_label : null,
                previousLevel: prevLevel ? prevLevel.ht_label : null,
                // hasHistory: this.getNode(comparer.ht_id, g.hierarchy_node_id)
                //   ?.hasHistory,
                canAdd:
                  !this.isDemo &&
                  comparer.currentDrillDownLevel ===
                    this.hierarchyTypes.find((y) => y.ht_id === comparer.ht_id)
                      .linklevel -
                      1,
                canAddDocumentType:
                  !this.isDemo && !nextLevel && g.job_count === 0
                    ? this.catalogueDocType
                    : null,
                reviewStatus: this.getHierarchyNodeReviewStatus(
                  g.hierarchy_node_id
                ),
                drilldown: nextLevel
                  ? {
                      column: comparer.column,
                      ht_id: comparer.ht_id,
                      value: val,
                      hLevel: comparer.currentDrillDownLevel,
                    }
                  : null,
                showNodes: null,
                leaf_node_count:
                  ht.linklevel === comparer.currentDrillDownLevel
                    ? 1
                    : ht.values.filter(
                        (x) =>
                          x["hierarchy_node_id_level" + g.hLevel] ===
                          g.hierarchy_node_id
                      ).length,
              };
              this.appendMeasureColumns(node);
              g.leaf_node_count = node.leaf_node_count;
              this.appendNodeHierarchyLevels(node);
              g.node = node;
            }
          });
          cols.sort((a, b) => {
            return a.sortKey > b.sortKey ? 1 : a.sortKey < b.sortKey ? -1 : 0;
          });
          return isHierarchyTree;
        };
        let buildZCat = (cat, zDef, parent) => {
          let value = cat[zDef.column];
          let valDef = zDef.values
            ? zDef.values.find((x) => x.value === value)
            : null;
          let doc = zDef.isDocument
            ? this.selectedDocs.find((x) => x.system_number === value)
            : null;
          let item = {
            value: value,
            title:
              valDef && valDef.title
                ? valDef.title
                : (doc ? doc.doc_name : value || ""),
            isSelectable: zDef.showCheckbox,
            isDocument: zDef.isDocument,
            selected: false,
            colour: valDef ? valDef.colour : "",
            matchKey: parent ? parent.matchKey + "|" + value + "|" : "",
            matchColumns: parent
              ? [...parent.matchColumns, zComparecolumn]
              : [],
          };
          this.appendMeasureColumns(item, 0, cat);
          this.appendAggregateColumns(item, 0, cat);
          return item;
        };
        let setSDCategory = (item, mean, stdev, column) => {
          let value = Number(item[column + "_mean_number"]);
          item[column + "_category"] =
            value < mean - stdev
              ? "_low"
              : value > mean + stdev
              ? "_high"
              : "_mid";
          item.categories.forEach((cat) => {
            cat[column + "_category"] =
              cat[column + "_mean_number"] < mean - stdev
                ? "_low"
                : cat[column + "_mean_number"] > mean + stdev
                ? "_high"
                : "_mid";
          });
        };
        let hIsHierarchyTree = decorateCols(hCols, hCompare);
        let vIsHierarchyTree = decorateCols(vCols, vCompare);
        this.deriveCols(!!vIsHierarchyTree);
        let standardCols = [
          { title: "", isHeader: true, cols: 1, leftPanel: true },
          { title: "#Docs #Emps", isHeader: true, cols: 1, leftPanel: true },
        ];
        if (vCompare.showSimCount) {
          standardCols.push({
            title: "#Similar JDs",
            isHeader: true,
            cols: 1,
            leftPanel: true,
          });
        }
        let vTextMax = vCols.reduce(
          (p, c) => (c.title && c.title.length > p ? c.title.length : p),
          0
        );
        let title = this.showDetail
          ? this.detailCompareOption.name + " within "
          : "";
        title +=
          this.verticalCompareOption.name +
          (title.length ? " and " : " by ") +
          this.horizontalCompareOption.name;
        title +=
          " - " +
          this.selectedDocs.length +
          " documents" +
          (_documents.length > this.selectedDocs.length ? " (filtered)" : "");
        let rowHeadings = vCols.map((h) => {
          let zCols = [];
          if (this.display.total_col.showZ && this.showDetail) {
            h[hComparecolumn + "s"].forEach((hc) => {
              hc[zComparecolumn + "s"].forEach((zc) => {
                let val = zc[zComparecolumn];
                let zCol = zCols.find((z) => z[zComparecolumn] === val);
                if (zCol) {
                  this.measures.forEach((m) => (zCol[m.type] += zc[m.type]));
                } else {
                  zCols.push(zc);
                }
              });
            });
          }
          let value = h[vCompare.column];
          let valDef = vCompare.values
            ? vCompare.values.find((x) => x.value === value)
            : null;
          let doc = vCompare.isDocument
            ? this.selectedDocs.find((x) => x.system_number === value)
            : null;
          let head = {
            value: value,
            title:
              valDef && valDef.title
                ? valDef.title
                : value + (doc ? " - " + doc.doc_name : ""),
            class: "col_header",
            isSelectable: !!vCompare.showCheckbox,
            isDocument: !!vCompare.isDocument,
            selected: false,
            drilldown: h.node?.drilldown,
            leaf_node_count: h.node?.leaf_node_count,
            node: h.node,
            // hierarchyLevel: h.hierarchyLevel,
            cols: "",
            categories: zCols.map((g) => {
              return buildZCat(g, zCompare);
            }),
            matchKey: h.matchKey,
            matchColumns: h.matchColumns,
          };
          this.appendMeasureColumns(head, 0, h);
          this.appendAggregateColumns(head, 0, h);
          this.getAggregateMeasures().forEach((m) => {
            setSDCategory(
              head,
              h[m.type + "_mean_number"],
              h[m.type + "_std_dev"],
              m.type
            );
          });
          return head;
        });

        let rowDetails = vCols.map((v) => {
          return {
            row: v,
            columns: hCols.map((g) => {
              let resVH = v[hComparecolumn + "s"].find(
                (x) => x[hComparecolumn] === g[hComparecolumn]
              );
              let resV_H_V = resVH ? resVH[zComparecolumn + "s"] : [];
              let det = {
                cols: 1 * this.display.h_col.colWidth,
                categories: resV_H_V
                  .filter((g) => g.job_count)
                  .map((g) => {
                    return buildZCat(g, zCompare, resVH);
                  }),
                col: g,
                selected: false,
                matchKey: resVH ? resVH.matchKey : "",
                matchColumns: resVH ? resVH.matchColumns : [],
              };
              this.appendMeasureColumns(det, "", resVH);
              this.appendAggregateColumns(det, "", resVH);
              this.getAggregateMeasures().forEach((m) => {
                setSDCategory(
                  det,
                  v[m.type + "_mean_number"],
                  v[m.type + "_std_dev"],
                  m.type
                );
              });
              return det;
            }),
          };
        });
        let rowDetailsFlat = !isBreakdown
          ? []
          : rowDetails.reduce((p, c) => {
              if (!p.length) {
                p = c.columns;
              } else {
                c.columns.forEach((col, ci) => {
                  if (this.measures.some((m) => col[m.type])) p[ci] = col;
                });
              }
              return p;
            }, []);
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  title / filters applied list
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  headerRow
        //  | leftPanel.cols                                                                    | rightPanel.cols                               |
        //  |-----------------------------------------------------------------------------------|-----------------------------------------------|
        //  ||lp.colsHierarchy + lp.colsTitle       | lp.colsDescriptor   |   lp.colsTotal     |||  <columnPicker>                             ||
        //  || <columnPicker>                       |    "Descriptor"     |     "TOTAL"        ||                                               |
        //  |-----------------------------------------------------------------------------------|[--------for-hierarchyHeaderRows---------------|
        //  ||lp.colsHierarchy + lp.colsTitle       | lp.colsDescriptor   |   lp.colsTotal     || [|          for columns                     |]|
        //  ||                                      |                     |--------------------||]                                              |
        //  ||                                      |                     || lc | jc | ec | s  ||-----------------------------------------------|
        //  |                                                                                   |[|         for headerRow.columns             |]|
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  groupHeaderRow
        //  | leftPanel.cols                                                                    | rightPanel.cols                               |
        //  |[--for-groupHeaderRow.vRows--------------------------------------------------------|[--for-groupHeaderRow.vRows--------------------|
        //  | |lp.colsTitle                         | lp.colsDescriptor   |   lp.colsTotal     || [|         for columns                       ||
        //  | |                                     |                     |--------------------||  | ...if  | ...if  | ...if  | ...if | ...if  ||
        //  | |                                     |                     || lc | jc | ec | s  || ]                                             |
        //  |]                                                                                  |]                                              |
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  detailRow
        //  | leftPanel.cols                                                                    | rightPanel.col                                |
        //  |-----------------------------------------------------------------------------------|                                               |
        //  | if lp.colsHierarchy          |                                                    |                                               |
        //  |[--for dr.hierarchyHeadings---|[---for-dr.rowHeadings------------------------------|-----------------------------------------------|
        //  | | -> nested hierarchy       || | lp.colsTitle |lp.colsDescDe| lp.colsTotalDetail |||[---for-dr.rowDetails]-----------------------||
        //  |]                             |]                             |--------------------||| [|         for columns                     |||
        //  |                              |                              || lc | jc | ec | s  |||  |----...if--------------------------------|||
        //  |                              |                              |[----for-categories-|||  || ...if | ...if | ...if | ...if | ...if ||||
        //  |                              |                              | |  jc  |  ec  |  s |||  |[---for-categories-----------------------|||
        //  |                              |                              |]                   |||  | | ...if | ...if | ...if | ...if | ..if ||||
        //  |                              |                              |[----for-detCntHdr--|||  |]                                        |||
        //  |                              |                              | |  jc  |  ec  |  s ||| ]                                          |||
        //  |                              |                              |]                   |||]                                           |||
        //  -------------------------------------------------------------------------------------------------------------------------------------
        //  scroll row
        //  | leftPanel.cols                                                                    | rightPanel.cols                               |
        //  |                                                                                   |-----------------------------------------------|
        //  |                                                                                   |[|         for headerRow.columns             |]|
        //  -------------------------------------------------------------------------------------------------------------------------------------

        let leftPanelCols = vIsHierarchyTree
          ? 8
          : vTextMax > 10 || this.verticalCompareOption.currentDrillDownLevel
          ? 3
          : 2;
        let leftPanelColsT = vIsHierarchyTree ? 0 : vTextMax > 10 ? 8 : 6;
        let leftPanelColsH = vIsHierarchyTree ? 10 : 0;
        let totCols =
          this.measures
            .filter((m) => m.visible)
            .reduce((p, c) => p + c.relative_width, 0) +
          (this.display.showLeafCount && !vIsHierarchyTree ? 1 : 0);
        if (this.hideHorizontal) leftPanelCols = 12;
        else {
          if (totCols > 2) {
            leftPanelCols++;
            if (vIsHierarchyTree) leftPanelColsH--;
          }
          if (totCols > 4) {
            leftPanelCols++;
            if (!vIsHierarchyTree) leftPanelColsT--;
          }
        }
        let leftPanelColsTotal = 12 - leftPanelColsH - leftPanelColsT;
        let layout = {
          title: title,
          leftPanel: {
            cols: leftPanelCols,
            colsTitle: leftPanelColsT,
            colsTotal: leftPanelColsTotal,
            colsTotalDetail: vIsHierarchyTree ? 12 : leftPanelColsTotal,
            colsHierarchy: leftPanelColsH,
            colsDescriptor: 0,
            colsDescriptorDetail: vIsHierarchyTree ? 6 : 0,
            text: this.verticalCompareOption.name,
          },
          rightPanel: {
            cols: !this.hideHorizontal ? 12 - leftPanelCols : 0,
            text: "",
          },
          hierarchyHeaderRows: hIsHierarchyTree
            ? Array.from(Array(hIsHierarchyTree.linklevel - 1)).map(
                (hr, hri) => {
                  let row = {
                    columns: [],
                    ref: "colhead" + hri,
                  };
                  let expansionFactor = this.display.h_col.colWidth;
                  hCols.forEach((c) => {
                    let hval = c.sortKey.split(" > ")[hri];
                    // if (!hval) hval = "UNCLASSIFIED";
                    //   (c.hierarchy ? c.hierarchy["level" + (hri + 1)] : null) ||
                    //   "UNCLASSIFIED";
                    if (
                      row.columns.length &&
                      row.columns[row.columns.length - 1].text === hval
                    ) {
                      row.columns[row.columns.length - 1].cols +=
                        expansionFactor;
                      row.columns[row.columns.length - 1].hItems.push(
                        c.ht_id ? c["ht_id_" + c.ht_id] : hval
                      );
                    } else {
                      row.columns.push({
                        text: hval,
                        isHeader: true,
                        cols: expansionFactor,
                        class: "title_cell",
                        hItems: [c.ht_id ? c["ht_id_" + c.ht_id] : hval],
                        hierarchyDescriptor: this.getHierarchyTypeNode(
                          c.ht_id,
                          c.hierarchy_node_id
                        ),
                        reviewStatus: this.getHierarchyNodeReviewStatus(
                          c.hierarchy_node_id
                        ),
                      });
                    }
                  });
                  return row;
                }
              )
            : [],
          headerRow: {
            text: this.horizontalCompareOption.name,
            ref: "colhead",
            columns: hCols.map((h) => {
              return {
                title: h.title,
                isSelectable: !!hCompare.showCheckbox,
                isDocument: !!hCompare.isDocument,
                selected: false,
                cols: 1 * this.display.h_col.colWidth,
                class: "title_cell",
                drilldown: h.node?.drilldown,
                // hierarchyLevel: h.hierarchyLevel,
                node: h.node,
                ht_id: h.ht_id,
                hr_id: h.ht_id ? h.keyVal : null,
                hItems: h.ht_id ? [h.keyVal] : [],
                matchKey: h.matchKey,
                matchColumns: h.matchColumns,
              };
            }),
          },
          groupHeaderRow: {
            vRows: this.drilldownFilters.filter(
              (x) =>
                x.ht_id === this.verticalCompareOption.ht_id &&
                x.compareOptionName === "verticalCompareOption"
            ),
            ref: "grouphead",
            hRows: this.drilldownFilters.filter(
              (x) =>
                x.ht_id === this.horizontalCompareOption.ht_id &&
                x.compareOptionName === "horizontalCompareOption"
            ),
          },
          detailRow: {
            rowHeadings: rowHeadings,
            hierarchyHeadings: vIsHierarchyTree ? { levels: [] } : null,
            rowDetails: rowDetails,
          },
          breakdownRow: {
            columns: rowDetailsFlat,
            hierarchyHeadings: vIsHierarchyTree ? { levels: [] } : null,
            rowDetails: rowDetails,
          },
          //   footerRow: {},
        };
        if (layout.groupHeaderRow.vRows.length) {
          let matchedCols =
            layout.groupHeaderRow.vRows[layout.groupHeaderRow.vRows.length - 1]
              .matchColumns;
          let matchKey =
            layout.groupHeaderRow.vRows[layout.groupHeaderRow.vRows.length - 1]
              .matchKey;
          let vCols = layout.detailRow.rowDetails.reduce((p, c) => {
            if (!p) {
              return c.columns.map((x) => {
                let ret = {
                  cols: x.cols,
                  visible: true,
                  keyVal: x.col.keyVal,
                  matchColumns: [...matchedCols, ...x.col.matchColumns],
                  matchKey: matchKey + x.col.matchKey,
                  selected: false,
                };
                this.appendMeasureColumns(ret, 0, x);
                this.appendAggregateColumns(ret, 0, x);
                return ret;
              });
            } else {
              p.forEach((x, xi) => {
                this.measures.forEach((m) => {
                  x[m.type] += Number(c.columns[xi][m.type]);
                });
              });
              return p;
            }
          }, null);
          layout.groupHeaderRow.vRows.forEach((r) => {
            if (vCols) {
              r.columns.forEach((c) => (c.cols = this.display.h_col.colWidth));
            }
            r.columns = vCols;
          });
        }
        let dIndex = 0;
        // set row title height
        layout.detailRow.rowHeadings.forEach((x, xi) => {
          x.detailCount = this.showDetail
            ? layout.detailRow.rowDetails[xi].columns.reduce((p, c) => {
                return c.categories.length > p ? c.categories.length : p;
              }, 0)
            : 0;
          x.detailCountHeader = !this.display.total_col.showZ
            ? Array.from({ length: x.detailCount - 1 }, (v, i) => i).map(
                (i) => {
                  return { dIndex: dIndex + i + 1 };
                }
              )
            : [];
          if (this.display.total_col.showZ && x.detailCount > 0) {
            x.categories.forEach((c, ci) => (c.dIndex = dIndex + ci + 1));
          }

          x.dIndexStart = dIndex;
          if (x.detailCount) {
            let i =
              dIndex +
              (!this.showDetail || !this.display.z_col.showLabel ? 1 : 0);
            layout.detailRow.rowDetails[xi].columns.forEach((c) =>
              c.categories.forEach((cc, cci) => (cc.dIndex = i + cci))
            );
            dIndex += x.detailCount + (this.display.total_col.showZ ? 0 : -1);
            //   } else {
            //     dIndex++;
          }
          // dIndex += x.detailCount + (this.display.total_col.showZ ? 0 : -1);
          x.dIndexEnd = dIndex < 1 ? 0 : dIndex;
          layout.detailRow.rowDetails[xi].dIndexStart = x.dIndexStart;
          layout.detailRow.rowDetails[xi].dIndexEnd = x.dIndexEnd;
          dIndex++;
        });
        this.vScroll.dMax = dIndex - 1;

        // derive hierarchy children for layout
        if (layout.hierarchyHeaderRows.length) {
          let brow = layout.headerRow;
          for (let i = layout.hierarchyHeaderRows.length - 1; i >= 0; i--) {
            let trow = layout.hierarchyHeaderRows[i];
            brow.columns.forEach((bc) => {
              let val = bc.hItems ? bc.hItems[0] : bc.text;
              let tcol = trow.columns.find((x) =>
                x.hItems.some((y) => y === val)
              );
              if (!tcol.childsets) tcol.childsets = [];
              tcol.childsets.push(bc.childsets ? bc.childsets.length : 1);
            });
            brow = trow;
          }
          layout.hierarchyHeaderRows.forEach((r) => {
            r.columns.forEach((c) => {
              let totCols = 1;
              let lMargin = 46;
              let rMargin = 46;
              if (c.childsets) {
                totCols = c.childsets.reduce((p, c) => c + p, 0);
                lMargin = (46.5 * c.childsets[0]) / totCols;
                rMargin =
                  (46.5 * c.childsets[c.childsets.length - 1]) / totCols;
              }
              c.lineMarginLeft = `${lMargin}%`;
              c.lineWidth = `${100 - lMargin - rMargin}%`;
            });
          });
        }
        // derive row header hierarchy
        let levels = [];
        if (vIsHierarchyTree) {
          let getColFactor = (level) => {
            switch (vIsHierarchyTree.linklevel - level + 1) {
              case 1:
                return 12;
              case 2:
                return 6;
              case 3:
                return 4;
              case 4:
                return 3;
              default:
                return 2;
            }
          };
          let applyToLevel = (levels, col, pos, detailRow) => {
            let isLeaf = pos === vIsHierarchyTree.linklevel;
            let val = col.sortKey
              ? col.sortKey.split(" > ")[pos - 1]
              : "UNCLASSIFIED";
            let key = isLeaf ? col.keyVal : val;
            let level = levels.find((x) => x.key === key);
            if (!level) {
              if (!val && pos === 1) val = "UNCLASSIFIED";
              let colFactor = getColFactor(pos);
              level = {
                value: val,
                key: key,
                levels: [],
                ht_id: vIsHierarchyTree.ht_id,
                hierarchy_node_id:
                  col[`ht_id_${vIsHierarchyTree.ht_id}_${pos}_nodeID`],
                level: pos,
                cols: colFactor,
                childCols: 12 - colFactor,
                dIndexStart: detailRow.dIndexStart,
                dIndexEnd: detailRow.dIndexEnd,
                detailCount: detailRow.detailCount,
              };
              if (isLeaf) {
                level.categories = detailRow.categories
                  .filter((c) => c.dIndex)
                  .map((c) => c.dIndex);
                level.detailCountHeader = detailRow.detailCountHeader
                  .filter((c) => c.dIndex)
                  .map((c) => c.dIndex);
              }
              levels.push(level);
            } else {
              if (level.dIndexStart > detailRow.dIndexStart)
                level.dIndexStart = detailRow.dIndexStart;
              if (level.dIndexEnd < detailRow.dIndexEnd)
                level.dIndexEnd = detailRow.dIndexEnd;
              level.detailCount += detailRow.detailCount;
            }
            return level;
          };
          vCols.forEach((c, di) => {
            let levs = levels;
            let detailRow = layout.detailRow.rowHeadings[di];
            for (let i = 1; i <= vIsHierarchyTree.linklevel; i++) {
              let lev = applyToLevel(levs, c, i, detailRow);
              levs = lev.levels;
            }
          });
          let setFirstLast = (levels) => {
            levels.forEach((x, xi) => {
              x.isFirst = xi === 0;
              x.isLast = xi === levels.length - 1;
              if (x.levels?.length) setFirstLast(x.levels);
            });
          };
          setFirstLast(levels);
          layout.detailRow.hierarchyHeadings.levels = levels;
          layout.detailRow.hierarchyHeadings.pageLevels = [];
        }

        this.layout = layout;
      }

      this.resetIsBuilding = true;
      this.vScroll.doSetup = true;
    },
    selectJob(item) {
      let matchKey = item.matchKey;
      if (this.compareCriteria.some((x) => x.matchKey === matchKey)) {
        this.compareCriteria = this.compareCriteria.filter(
          (x) => x.matchKey !== matchKey
        );
        item.selected = false;
      } else {
        this.compareCriteria.push({
          matchKey: matchKey,
          matchColumns: item.matchColumns,
        });
        item.selected = true;
      }
      this.setCompareItems();
    },
    buildMatchItem(item, matchColumns) {
      let match = "";
      matchColumns.forEach((c) => {
        match += "|" + item[c] + "|";
      });
      return match;
    },
    resetSelectedGroupedJobs(level) {
      let reset = (nodes) => {
        nodes.forEach((n) => {
          if (n.level !== level && n.selected) n.selected = false;
          if (n.nodes) reset(n.nodes, level);
          if (n.rows) reset(n.rows, level);
        });
      };
      reset(this.groupedViewDocs);
      this.compareCriteria = this.compareCriteria.filter(
        (x) => x.level === level
      );
    },
    selectGroupedJob(item) {
      let matchKey = item.keyVal;
      if (this.compareCriteria.some((x) => x.matchKey === matchKey)) {
        this.compareCriteria = this.compareCriteria.filter(
          (x) => x.matchKey !== matchKey
        );
        item.selected = false;
      } else {
        this.resetSelectedGroupedJobs(item.level);
        this.compareCriteria.push({
          matchKey: matchKey,
          gvFilter: true,
          level: item.level || 999,
          item: item,
          filterKeys: item.filterKeys,
        });
        item.selected = true;
      }
      this.setGroupCompareItems();
    },
    buildGroupedMatch(l1, l2, l3, detail) {
      let match = "";
      if (!l1.doc_id) {
        match = detail?.keyVal || l1?.keyVal + l2?.keyVal + l3?.keyVal;
      } else {
        this.groupedViewDetailColumns
          .filter((x) => x.isAttribute || x.ht_id)
          .forEach((c) => {
            match += "|" + l1[c.value];
          });
      }
      return match;
    },
    setGroupCompareItems() {
      this.compareItems = this.selectedDocs.filter((x) =>
        this.compareCriteria.some(
          (d) =>
            x.job_count > 0 && d.filterKeys.every((f) => x[f.col] === f.value)
          //   d.matchKey === this.buildGroupedMatch(x)
        )
      );
    },
    selectListviewJob(item, notBound) {
      let matchKey = this.buildListviewMatch(item);
      if (this.compareCriteria.some((x) => x.matchKey === matchKey)) {
        this.compareCriteria = this.compareCriteria.filter(
          (x) => x.matchKey !== matchKey
        );
        if (notBound) item.selected = false;
      } else {
        this.compareCriteria.push({
          matchKey: matchKey,
          lvFilter: true,
          item: item,
        });
        if (notBound) item.selected = true;
      }
      this.setListviewCompareItems();
    },
    selectAllListViewJob(header) {
      this.compareCriteria.splice(0, this.compareCriteria.length);
      this.groupedDocs.forEach((x) => {
        if (x.selected !== header.selected) {
          x.selected = header.selected;
          //   this.selectListviewJob(x);
        }
        if (x.selected) {
          let matchKey = this.buildListviewMatch(x);
          this.compareCriteria.push({
            matchKey: matchKey,
            lvFilter: true,
            item: x,
          });
        }
      });
      this.setListviewCompareItems();
    },
    buildListviewMatch(item) {
      let match = "";
      this.lvColumnsFiltered
        .filter((x) => x.isAttribute || x.ht_id)
        .forEach((c) => {
          match += "|" + item[c.value];
        });
      return match;
    },
    resetCompare() {
      this.compareCriteria.splice(0, this.compareCriteria.length);
      this.compareView = true;
      if (
        this.layout &&
        this.layout.headerRow &&
        this.layout.headerRow.columns
      ) {
        this.layout.headerRow.columns
          .filter((x) => x.selected)
          .forEach((x) => (x.selected = false));
        this.layout.detailRow.rowHeadings
          .filter((x) => x.selected)
          .forEach((x) => (x.selected = false));
        this.layout.detailRow.rowDetails.forEach((r) =>
          r.columns.forEach((x) => {
            x.selected = false;
            x.categories
              .filter((c) => c.selected)
              .forEach((c) => (c.selected = false));
          })
        );
      }
      if (
        this.layout &&
        this.layout.groupHeaderRow &&
        this.layout.groupHeaderRow.vRows
      ) {
        this.layout.groupHeaderRow.vRows.forEach((r) =>
          r.columns.forEach((c) => (c.selected = false))
        );
      }
      this.groupedDocs.forEach((x) => (x.selected = false));
      this.lvColumnsFiltered
        .filter((x) => x.selected)
        .forEach((x) => (x.selected = false));
      this.drilldownFilters
        .filter((x) => x.selected)
        .forEach((x) => (x.selected = false));
      this.resetSelectedGroupedJobs();
      this.compareItems.splice(0, this.compareItems.length);
    },
    setCompareItems() {
      this.compareItems = this.selectedDocs.filter((x) =>
        this.compareCriteria.some(
          (d) =>
            x.job_count > 0 &&
            d.matchKey === this.buildMatchItem(x, d.matchColumns)
        )
      );
    },
    setListviewCompareItems() {
      this.compareItems = this.selectedDocs.filter((x) =>
        this.compareCriteria.some(
          (d) => x.job_count > 0 && d.matchKey === this.buildListviewMatch(x)
        )
      );
    },
    compareDocDetails(items) {
      this.sideBySide.show = true;
      this.sideBySide.items = items || this.compareItems;
    },
    compareDocDetailsFull(items) {
      this.fullCompareView.show = true;
      this.fullCompareView.items = items || this.compareItems;
    },
    buildDocCompareSkillsMatrix() {
      let skillMatrix = [];
      if (this.compareItems.length) {
        this.compareItems.forEach((c) => {
          c.skills.forEach((s) => {
            let cat = skillMatrix.find((x) => x.category === s.category);
            if (!cat) {
              cat = { category: s.category, skills: [] };
              skillMatrix.push(cat);
            }
            let skill = cat.skills.find((x) => x.name === s.name);
            if (!skill) {
              skill = { name: s.name };
              cat.skills.push(skill);
            }
          });
        });
      }
      return skillMatrix;
    },
    compareDocSkills() {
      if (this.compareItems.length) {
        this.skillsDialogue.show = true;
        this.skillsDialogue.categories = this.buildDocCompareSkillsMatrix();
        this.skillsDialogue.items = this.compareItems.map((x) => {
          let d = JSON.parse(JSON.stringify(x));
          //let d = x;
          d.categories = this.skillsDialogue.categories.map((c) => {
            let dc = {
              category: c.category,
              skills: c.skills.map((s) => {
                return d.skills.find((ds) => ds.name === s.name);
                // return {
                //   level: ds ? ds.level : null,
                //   name: s.name,
                //   colour: sl?.colour,
                //   textColour: sl?.textColour,
                // };
              }),
            };
            return dc;
          });
          return d;
        });
        // this.compareSkillMatrix = rows;
        this.initPaging(this.skillsDialogue);
      }
    },
    setDocHierarchyValues(doc, hierarchyType, hr_id) {
      let hr = hierarchyType.values.find((v) => v.value === hr_id);
      doc["ht_id_" + hierarchyType.ht_id] = hr ? hr.value : null;
      doc["change_ht_id_" + hierarchyType.ht_id] = false;
      doc["ht_id_" + hierarchyType.ht_id + "_label"] = "";
      [1, 2, 3, 4, 5, 6].forEach((i) => {
        if (hierarchyType["col" + i] > 0) {
          let val = hr ? hr["level" + i] || "UNCLASSIFIED" : "UNCLASSIFIED";
          doc["ht_id_" + hierarchyType.ht_id + "_" + i + "_"] = val;
          doc["ht_id_" + hierarchyType.ht_id + "_text"] = val;
          doc["ht_id_" + hierarchyType.ht_id + "_label"] =
            (doc["ht_id_" + hierarchyType.ht_id + "_label"]
              ? doc["ht_id_" + hierarchyType.ht_id + "_label"] + " > "
              : "") + val;
          doc["ht_id_" + hierarchyType.ht_id + "_" + i + "_nodeID"] = hr
            ? hr["hierarchy_node_id_level" + i]
            : null;
        }
      });
    },
    saveColumnSelection() {
      this.$loginState.saveSetting(
        "lvwJCColumnSelection",
        this.listView.columnsEnabled
      );
      this.buildGroupedDocs();
    },
    fetchData(isRefresh) {
      if (this.isLoading) return;
      this.isLoadingData = true;
      let possibleError = false;
      let self = this;
      axios
        .get("document/jobcatalogue")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Data) self.setupData(resp.data.Data, isRefresh);
          this.isLoadingData = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoadingData = false;
        });
    },
    appendHierarchiesToRawData(docs) {
      // only needed so that side hierarchy search works....
      docs.forEach((d) => {
        d.hierarchies = this.hierarchyTypes.map((ht) => {
          let ret = {
            ht_id: ht.ht_id,
            hr_id: d[`ht_id_${ht.ht_id}`],
          };
          let c = 1;
          for (let i = ht.linklevel; i > 0; i--) {
            ret[`hierarchy${i}`] = d[`ht_id_${ht.ht_id}_${c}_`];
            c++;
          }
          return ret;
        });
      });
    },
    setupDescriptors(data) {
      data.hierarchyTypeNodes.forEach((x) => {
        _descriptors["ht_o_" + x.ht_id] = x.descObject;
        //   descriptors["ht_l_" + x.ht_id] = x.descriptors;
        delete x.descObject;
        delete x.descriptors;
      });
    },
    setupData(data, isRefresh) {
      if (data?.hierarchyTypeNodes) {
        this.setupDescriptors(data);
        _hierarchyTypeNodes = data.hierarchyTypeNodes;
      }
      this.setUpHierarchies();

      let tmplName = data?.template || "Job Description";
      this.canDelete = data.canDelete;
      this.catalogueDocType = tmplName;
      let docStatusList = data?.template ? [] : ["APPROVED", "DRAFT"];
      let docs = [];
      let compareOptions = [];
      let salaries = [15000, 12000, 20000, 25000, 32000, 40000, 50000];
      let reviewed = [
        { value: 0, sort_key: 0 },
        { value: 1, sort_key: 1 },
        { value: 2, sort_key: 2 },
        { value: 3, sort_key: 3 },
        { value: 4, sort_key: 4 },
        { value: 5, sort_key: 5 },
        { value: 6, sort_key: 6 },
      ];
      _reviewNodes = data.reviewNodes || [];
      let rdocs =
        data.reviewDocs.map((x) => {
          x.showNextLevel = false;
          x.showComments = false;
          x.nodesMain.forEach((n) => (n.showComments = false));
          return x;
        }) || [];
      this.reviewDocs = [
        {
          type: "Open",
          docs: rdocs.filter((x) => x.status_type === "Open"),
          show: false,
        },
        {
          type: "Completed",
          docs: rdocs.filter((x) => x.status_type === "Completed"),
          show: false,
        },
      ];

      if (data?.template) {
        this.attributes = data.attributes;
        data.measures.forEach((m) => {
          m.relative_width = 1;
          m.visible = true;
          m.alignRight = false;
          m.h_col_width = 0;
          m.h_col_label_width = 0;
          m.z_col_width = 0;
          m.z_show = false;
          m.z_col_label_width = 0;
          m.total_col_width = 0;
          m.total_col_label_width = 0;
        });
        this.measures = data.measures;
        this.measureFilters = data.measures
          .filter((x) => x.filterPresence)
          .map((x) => {
            return { title: x.title, type: x.type };
          });
        if (this.measureFilters.length) {
          this.measureFilters.unshift({
            title: "No Similarity Filter",
            type: null,
          });
          this.measureFilter = this.measureFilters[0];
        }
        // only needed so that side hierarchy search works....
        this.appendHierarchiesToRawData(data.docs);

        _documents = data.docs;
        this.haveData = _documents.length !== 0;
        // _reviewDocuments = data.reviewDocuments;
        this.documentsForSearch = data.docs;
        this.isDemo = false;
      } else {
        this.isDemo = true;
        let setValuesDemo = (d, di, gender) => {
          this.attributes.forEach((a) => {
            d["change_" + a.value_column] = true;
            if (a.generateList && a.generateList.length) {
              d[a.column] =
                a.generateList[di % a.generateList.length] +
                a.generateList[di % (a.generateList.length - 1)] +
                ((di % 15) * 1000 + 11).toString().substr(0, 4);
              if (!a.values.includes(d[a.column])) a.values.push(d[a.column]);
            } else {
              d[a.column] = a.values[di % a.values.length].value;
            }
          });
          let empCount = gender === "Male" ? (di % 5) + 1 : 1;
          if (di % 15 === 1) empCount = 0; // wante some zero entries
          d.gender = gender;
          d.employee_count = empCount;
          d.job_count = gender === "Male" ? 1 : 0;
          d.salary = d.employee_count * salaries[di % salaries.length];
          d.last_reviewed = reviewed[di % reviewed.length].value;
          let y = di % 3;
          let z = this.skills.length - 1;
          d.skills = this.skills
            .filter((x, xi) => xi % 2 === di % 2 && xi > y && xi < z)
            .map((x) => {
              let s = JSON.parse(JSON.stringify(x));
              let l = this.skillLevels[di % this.skillLevels.length];
              s.level = l.level;
              s.colour = l.colour;
              s.textColour = l.textColour;
              return s;
            });
        };
        this.cleanItems
          .filter(
            (x) =>
              x.doc_type === tmplName &&
              (!docStatusList.length ||
                docStatusList.some((s) => s === x.doc_status))
          )
          .forEach((x, xi) => {
            let d = JSON.parse(JSON.stringify(x));
            this.hierarchyTypes.forEach((ht) => {
              let hr = d.hierarchies.find((h) => h.ht_id === ht.ht_id);
              this.setDocHierarchyValues(d, ht, hr?.hr_id);
            });
            d.doc_name = (d.doc_name || "NO JOB TITLE").trim();
            d.id = d.doc_id;
            if (data?.template) {
              // let dAt = data.doc_attributes.find((da) => da.doc_id === d.doc_id);
              // setValues(d, dAt ? dAt.attributes : [], xi);
              delete d.hierarchies;
              let dd = data.docs.find((da) => da.doc_id === d.doc_id);
              let dAt = { ...d, ...dd };
              docs.push(dAt);
            } else {
              // demo generated
              setValuesDemo(d, xi, "Male");
              docs.push(d);
              d = JSON.parse(JSON.stringify(d));
              setValuesDemo(d, xi, "Female");
              docs.push(d);
            }
          });
        let identifySimilarJT = (jobtitles) => {
          jobtitles.forEach((x) => {
            x.similar_list_JT = [];
            jobtitles.forEach((y) => {
              if (
                x.id !== y.id &&
                similarity.compareTwoStrings(x.doc_name, y.doc_name) > 0.8
              ) {
                x.similar_list_JT.push({ id: y.id, doc_name: y.doc_name });
              }
            });
            x.similar_count_JT = x.similar_list_JT.length;
          });
        };
        let identifySimilarJC = (jobtitles) => {
          jobtitles.forEach((x) => {
            x.similar_list_JC = [];
            jobtitles.forEach((y) => {
              if (x.id !== y.id && x.job_code === y.job_code) {
                x.similar_list_JC.push({ id: y.id, doc_name: y.doc_name });
              }
            });
            x.similar_count_JC = x.similar_list_JC.length;
          });
        };
        //   identifySimilarJT(jobTitles);
        if (this.showDuplicatesOnly) identifySimilarJT(docs); // forced off
        if (this.showDuplicatesOnly) identifySimilarJC(docs); // forced off
        //   this.jobTitles = jobTitles;
        _documents = docs;
        this.haveData = _documents.length !== 0;
        this.documentsForSearch = docs;
      }
      this.attributes.forEach((a) => {
        let vals;
        if (a.values.length === 0) {
          vals = this.distinctValues(_documents, [a.column]).map((x) => {
            return { sort_key: x[a.column], value: x[a.column] };
          });
          if (vals.length > 30) vals = [];
        }
        compareOptions.push({
          name: a.title,
          column: a.column,
          tpa_id: a.tpa_id,
          useSortKey: a.useSortKey,
          listValues: a.values.length ? a.values : vals,
          filterValues: [],
        });
      });
      compareOptions.push({
        name: this.catalogueDocType + " Title",
        column: "doc_name",
        showSimCount: true,
        showCheckbox: true,
        filterValues: [],
      });
      compareOptions.push({
        name: this.catalogueDocType + " Document",
        column: "system_number",
        showSimCount: true,
        hideHorizontal: true,
        hideVertical: true,
        isDocument: true,
        showCheckbox: false,
        filterValues: [],
      });
      if (this.isDemo)
        compareOptions.push(
          {
            name: "Gender",
            column: "gender",
            isSubLevel: true,
            values: [
              { value: "Male", colour: "light-blue lighten-2", title: "M" },
              { value: "Female", colour: "red accent-1", title: "F" },
            ],
            filterValues: [],
          },
          {
            name: "Years Since Review",
            column: "last_reviewed",
            listValues: reviewed,
            filterValues: [],
          }
        );
      this.hierarchyTypes.forEach((ht) => {
        let levels = [1, 2, 3, 4, 5, 6].filter((i) => ht["col" + i] > 0);
        compareOptions.push({
          name: ht.label + " (Tree)",
          column: "ht_id_" + ht.ht_id,
          ht_id: ht.ht_id,
          ht_name: ht.ht_name,
          ht_label: ht.label,
          linklevel: ht.linklevel,
          filterValues: [],
          hideDDrilldown: true,
          hideHorizontal: true,
          hierarchyLevels: levels.map((i) => {
            let usesDescriptor = ht["descriptor_h" + i];
            return {
              node_column: `ht_id_${ht.ht_id}_${i}_nodeID`,
              ht_label: ht["level" + i + "_name"],
              hLevel: i,
              usesDescriptor: usesDescriptor,
            };
          }),
        });
        compareOptions.push({
          name: ht.label + " (Drilldown)",
          column: `ht_id_${ht.ht_id}_1_`,
          ht_id: ht.ht_id,
          ht_name: ht.ht_name,
          ht_label: ht.label,
          linklevel: ht.linklevel,
          filterValues: [],
          hideDDrilldown: true,
          currentDrillDownLevel: 1,
          drillDownLevels: levels.map((i) => {
            let usesDescriptor = ht["descriptor_h" + i];
            return {
              column: `ht_id_${ht.ht_id}_${i}_`,
              ht_label: ht["level" + i + "_name"],
              hLevel: i,
              usesDescriptor: usesDescriptor,
            };
          }),
        });
        // compareOptions.push({
        //   name: ht.label + " (Breakdown)",
        //   column: `ht_id_${ht.ht_id}_1_`,
        //   ht_id: ht.ht_id,
        //   ht_name: ht.ht_name,
        //   ht_label: ht.label,
        //   isBreakdown: true,
        //   filterValues: [],
        //   hideDDrilldown: true,
        //   currentDrillDownLevel: 1,
        //   drillDownLevels: [1, 2, 3, 4, 5, 6]
        //     .filter((i) => ht["col" + i] > 0)
        //     .map((i) => {
        //       return {
        //         column: `ht_id_${ht.ht_id}_${i}_`,
        //         ht_label: ht["level" + i + "_name"],
        //         hLevel: i,
        //       };
        //     }),
        // });
      });
      this.compareOptionsV = JSON.parse(
        JSON.stringify(compareOptions.filter((x) => !x.hidevertical))
      );
      this.compareOptionsH = JSON.parse(
        JSON.stringify(compareOptions.filter((x) => !x.hideHorizontal))
      );
      this.compareOptionsD = JSON.parse(
        JSON.stringify(compareOptions.filter((x) => !x.hideDDrilldown))
      );
      this.compareOptionsD.push({
        name: " No Drilldown",
        hideDetails: true,
      });
      this.compareOptionsD.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );

      this.showDetail = false;
      this.showSalary = false;
      this.showDuplicatesOnly = false;
      this.isLoadingData = false;
      this.isSaving = false;
      this.isDataLoaded = true;
      if (isRefresh) {
        this.selectJDs();
      } else {
        this.setDefaultView();
      }
    },
    checkViewTypeInitialised() {
      let vt = this.viewTypes.find(
        (x) => x.viewType === this.selectedView.viewType
      );
      if (!vt.isInitialised) {
        switch (vt.viewType) {
          case "list":
          case "grouped":
            this.listView.itemsPerPage =
              Number(localStorage.getItem("jcRPP")) || 20;
            this.listView.columns = [];
            this.listView.hierarchyView = this.hierarchyTypes[0];
            this.hierarchyTypes.forEach((ht) => {
              ht.usedLevels = 0;
              [1, 2, 3, 4, 5, 6].forEach((l) => {
                if (ht["use_h" + l]) {
                  let usesDescriptor = ht["descriptor_h" + l];
                  ht.usedLevels++;
                  let col = ht[`level${l}_name`]
                    ? ht[`level${l}_name`]
                    : `${ht.label} Level ${ht.usedLevels}`;
                  let column = `ht_id_${ht.ht_id}_${l}_`;
                  this.listView.columns.push({
                    text: col,
                    value: column,
                    ht_id: ht.ht_id,
                    level: l,
                    usesDescriptor: !!usesDescriptor,
                    filterSettings: {
                      show: false,
                      column: column,
                      values: [],
                      active: false,
                      searchText: "",
                      page: 1,
                      pages: 1,
                    },
                  });
                }
              });
            });
            this.attributes.forEach((a) => {
              this.listView.columns.push({
                text: a.title,
                value: a.column,
                isAttribute: true,
                filterSettings: {
                  show: false,
                  column: a.column,
                  values: [],
                  active: false,
                  searchText: "",
                  page: 1,
                  pages: 1,
                },
              });
            });
            this.listView.columns.forEach((c) => {
              if (c.isAttribute || c.ht_id) {
                c.filterSettings.values = this.groupBy(
                  _documents,
                  c.value,
                  "job_count"
                ).map((g, gi) => {
                  return {
                    text: g[c.value],
                    selected: false,
                    visible: true,
                    available: true,
                    count: g.job_count,
                    searchValues: (g[c.value] || "")
                      .toString()
                      .toLowerCase()
                      .split(" ")
                      .filter((x) => x),
                    page: parseInt(gi / 7) + 1,
                  };
                });
                c.filterSettings.pages =
                  parseInt((c.filterSettings.values.length - 1) / 7) + 1;
              }
            });
            this.viewTypes.find(
              (x) => x.viewType === "list"
            ).isInitialised = true;
            this.viewTypes.find(
              (x) => x.viewType === "grouped"
            ).isInitialised = true;
            this.listView.columnsEnabled = this.$loginState.getSetting(
              "lvwJCColumnSelection",
              this.listView.columnsEnabled
            );
            if (!this.listView.columnsEnabled.length) {
              this.listView.columnsEnabled = this.listView.columns.map(
                (x) => x.value
              );
            }
            this.measures.forEach((m) => {
              this.listView.columns.push({
                text: m.abbreviation,
                value: m.type,
                measure: m,
                sortable: true,
                filterSettings: {
                  show: false,
                  column: m.type,
                  values: [],
                  active: false,
                  searchText: "",
                  page: 1,
                  pages: 1,
                },
              });
            });
            break;
          case "catalogue":
          case "audit":
          case "audit_data":
          case "reviews":
            break;
        }
      }
    },
    filterScroll(data, filterSettings) {
      if (data) {
        let page = filterSettings.page + (data.deltaY >= 0 ? 1 : -1);
        if (page > filterSettings.pages || page < 1) return;
        filterSettings.page = page;
      }
    },
    setFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values.forEach((x) => {
          x.selected = x.text === value;
        });
        this.filterHeading(column.filterSettings);
      }
    },
    removeFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values
          .filter((x) => x.text === value)
          .forEach((x) => (x.selected = false));
        this.filterHeading(column.filterSettings);
      }
    },
    filterHeading(filterSettings) {
      filterSettings.values
        .filter((v) => v.selected)
        .forEach((v) => (v.applied = true));
      filterSettings.isActive = filterSettings.values.some((x) => x.selected);
      filterSettings.show = false;
      let activeCount = 0;
      this.lvColumnsFiltered
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          c.filterSettings.mostRecent = false;
          if (c.filterSettings.isActive) activeCount++;
        });
      filterSettings.mostRecent = filterSettings.isActive;
      this.activeFilterCount = activeCount;
      this.selectJDs();
    },
    filterSearchAll(filterSettings) {
      if (filterSettings.searchText) {
        let newVal = !filterSettings.values.every(
          (x) => !x.visible || x.selected
        );
        filterSettings.values.forEach((x) => {
          if (x.visible || !newVal) x.selected = newVal;
        });
      } else {
        let newVal = !filterSettings.values.every((x) => x.selected);
        filterSettings.values.forEach((x) => {
          x.selected = newVal;
        });
      }
    },
    filterSearch(filterSettings) {
      let index = 0;
      let search = (filterSettings.searchText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      filterSettings.values.forEach((x) => {
        const visible =
          !search.length ||
          search.every((s) => x.searchValues.some((v) => v.indexOf(s) >= 0)); // x.text.toLowerCase().indexOf(search) >= 0;
        x.visible = visible;
        if (visible) {
          x.page = parseInt(index / 7) + 1;
          index++;
        } else {
          x.page = 0;
        }
      });
      filterSettings.pages = parseInt((index - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    filterClear(filterSettings) {
      filterSettings.searchText = "";
      filterSettings.values.forEach((x, xi) => {
        x.visible = true;
        x.selected = false;
        x.applied = false;
        x.page = parseInt(xi / 7) + 1;
      });
      filterSettings.pages =
        parseInt((filterSettings.values.length - 1) / 7) + 1;
      this.filterHeading(filterSettings);
    },
    doHierarchyFilter(filters) {
      this.hierarchyFilters = filters || [];
      this.selectJDs();
    },
    openDocumentID(id) {
      let doc = _documents.find((d) => d.system_number === id);
      if (doc) this.openDocument(doc);
    },
    openDocument(item) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        viewAction: item.viewAction,
      });
    },
    exportToCSV() {
      //   this.csvHeaders = JSON.parse(JSON.stringify(this.dtHeaders));
      this.csvHeaders.push({
        text: "Created By",
        value: "created_by",
      });
      let data =
        this.csvHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.items.forEach((d) => {
        data +=
          this.csvHeaders
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Documents.csv", "text/csv;encoding:utf-8");
    },

    setUpHierarchies() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        let hierarchyTypes = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.hierarchies)
        );

        hierarchyTypes.forEach((x) => {
          x.h_levels = [];
          let i = 1;
          while (i <= x.linklevel) {
            x.h_levels.push(x[`level${i}_name`]);
            i++;
          }
        });
        this.hierarchyTypes = hierarchyTypes;
      }
    },
    editHierarchyDescriptor(node) {
      if (!node.descriptor) return;
      this.hierarchyDescriptorDialogue.show = true;
      this.hierarchyDescriptorDialogue.node = node.descriptor;
      this.hierarchyDescriptorDialogue.label = node.descriptor.label;
      this.hierarchyDescriptorDialogue.text = node.descriptor.description;
    },
    saveHierarchyDescriptor() {
      let possibleError = false;

      axios
        .post("hierarchy/saveNodeDescription/", {
          hierarchy_node_id:
            this.hierarchyDescriptorDialogue.node.hierarchy_node_id,
          description: this.hierarchyDescriptorDialogue.text,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.hierarchyDescriptorDialogue.node.description =
              this.hierarchyDescriptorDialogue.text;
            this.hierarchyDescriptorDialogue.show = false;
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    getHierarchyTypeNode(ht_id, hierarchy_node_id) {
      return _descriptors["ht_o_" + ht_id]["hn" + hierarchy_node_id];
      //return descriptors["ht_l_" + ht_id].find(x => x.hierarchy_node_id === hierarchy_node_id);
      // 	return this.hierarchyTypeNodes.find(
      //     (x) => x.ht_id === ht_id
      //   ).descObject["hn" + hierarchy_node_id];
      //   let data = this.hierarchyTypeNodes.find(
      //     (x) => x.ht_id === ht_id
      //   ).descriptors;
      //   let len = data.length;
      //   let result = null;
      //   for (let i = 0; i < len; i++) {
      //     if (data[i].hierarchy_node_id === hierarchy_node_id) {
      //       result = data[i];
      //       break;
      //     }
      //   }
      //   return result;
      //   return this.hierarchyTypeNodes
      //     .find((x) => x.ht_id === ht_id)
      //     .descriptors.find((x) => x.hierarchy_node_id === hierarchy_node_id);
    },
    getHierarchyNodeReviewStatus(hierarchy_node_id) {
      let rev = _reviewNodes.find(
        (x) => x.hierarchy_node_id === hierarchy_node_id
      );
      if (rev) rev.editMode = false;
      return rev;
    },
    addAttribute(a) {
      this.attributeAddDialogue.show = true;
      this.attributeAddDialogue.text = "";
      this.attributeAddDialogue.label = `Add new ${a.label} value:`;
      this.attributeAddDialogue.attribute = a;
    },
    saveAttribute() {
      if (!this.attributeAddDialogue.text) return;
      this.attributeAddDialogue.show = false;
      this.attributeAddDialogue.attribute.values.push(
        this.attributeAddDialogue.text
      );
    },
    addHierarchy(ht) {
      this.hierarchyAddDialogue.ht_id = ht.ht_id;
      this.hierarchyAddDialogue.show = true;
    },
    saveHierarchy() {
      if (!this.hierarchyAddDialogue.text) return;
      this.hierarchyAddDialogue.show = false;
      this.hierarchyAddDialogue.hierarchyType.values.push(
        this.hierarchyAddDialogue.text
      );
    },
    pickHierarchy(hierarchy, property) {
      let node = {
        ht_id: hierarchy.ht_id,
        level: hierarchy.linklevel,
        label: hierarchy.label,
        name: null,
        hierarchy_node_id: null,
        property: property,
      };
      this.setUpEditHierarchy(node, "pick");
    },
    doMenuAction(action, item, compareOption) {
      switch (action.action) {
        case "edit":
        case "rename":
        case "move":
        case "merge":
        case "add":
        case "delete":
          this.setUpEditHierarchy(action.node, action.action, action);
          break;
        case "exportNodeDescriptors":
          this.exportNodeDescriptors(action.node);
          break;
        case "viewNodeDescriptors":
          //   this.buildNodeDescriptorView(action.node, true);
          this.buildNodeDescriptorViewReview(action.node, true);
          break;
        case "exportNodeDescriptorsNested":
          this.exportNodeDescriptors(action.node, true);
          break;
        case "editDescriptor":
        case "editHierarchyDescriptor":
          this.setUpEditHierarchy(action.node, "editDescriptor");
          break;
        case "drilldown":
          this.doDrilldown(item, compareOption);
          break;
        case "addHierarchyDocument":
          this.addHierarchyDocument(action.node);
          break;
        case "showReview":
          this.showHierarchyNodeReview(action.node);
          break;
        case "showReviewParent":
          this.buildNodeDescriptorViewReview(action.node, true, "existing");
          break;
        case "addFilter":
          this.setFilterValue(action.listviewItem, action.listviewColumn);
          break;
        case "removeFilter":
          this.removeFilterValue(action.listviewItem, action.listviewColumn);
          break;
        case "history":
          this.getNodeHistory(action.node);
          break;
      }
    },
    setUpEditHierarchy(h, mode, action) {
      this.hierarchyEditDialogue.show = true;
      this.hierarchyEditDialogue.node = h;
      this.hierarchyEditDialogue.mode = mode;
      this.hierarchyEditDialogue.action = action;
      this.hierarchyEditDialogue.showAddNodeDescriptor =
        mode === "add" &&
        _hierarchyTypeNodes.some(
          (x) => x.ht_id === h.ht_id && x["descriptor_h" + h.level + 1]
        );
    },
    attributePicked(attribute) {
      attribute.isDirty = true;
      if (this.editDialogue.items.length === 1) {
        this.editDialogue.items[0]["change_" + attribute.value_column] = true;
      }
      this.setCanSave();
    },
    nodePicked(details) {
      let target = this.editDialogue.columns.find(
        (x) => x.ht_id === details.original.ht_id
      );
      if (target.value !== details.updated.hr_id) {
        let labels = [details.updated.node.name];
        if (details.updated.node.parents)
          details.updated.node.parents.forEach((p) => labels.unshift(p.name));
        target.value = details.updated.hr_id;
        target.text = details.updated.node.name;
        target.isDirty = true;
        target.hLabel = labels;
        if (this.editDialogue.items.length === 1) {
          this.editDialogue.items[0]["change_" + target.value_column] = true;
        }
      }
      this.setCanSave();
      this.hierarchyEditDialogue.show = false;
    },
    addHierarchyDocument(node) {
      let possibleError = false;
      this.isSaving = true;

      axios
        .post("document/createHierarchyDocument/", {
          hierarchy_node_id: node.hierarchy_node_id,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.docAdded([resp.data.Data.document[0]]);
          }
          this.response = resp.data;
          this.isSaving = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    setCanSave() {
      this.editDialogue.canSave = this.editDialogue.columns.some(
        (c) =>
          c.isDirty &&
          //   (this.editDialogue.items.length === 1 ||
          this.editDialogue.items.some((d) => d["change_" + c.value_column])
        // )
      );
    },
    nodeUpdated(details) {
      let updateColumn = details.original
        ? `ht_id_${details.original.ht_id}_${details.original.level}_`
        : null;
      let matchColumn = `${updateColumn}nodeID`;
      //   let updateObject = null;
      let desc = null;
      switch (details.action) {
        case "rename":
        case "edit":
          _documents
            .filter(
              (d) => d[matchColumn] === details.original.hierarchy_node_id
            )
            .forEach((d) => (d[updateColumn] = details.updated.new_name));
          if (details.updated.affected_docs) {
            details.updated.affected_docs.forEach((d) => {
              let doc = _documents.find((x) => x.doc_id === d.doc_id);
              if (doc) doc.doc_name = d.doc_name;
            });
          }
          break;
        case "editDescriptor":
          desc = this.getHierarchyTypeNode(
            details.original.ht_id,
            details.original.hierarchy_node_id
          );
          if (desc) {
            desc.description = details.updated.description;
          }
          break;
        case "add":
        case "move":
        case "merge":
          this.hierarchyEditDialogue.show = false;
          this.hierarchyAddDialogue.show = false;
          this.fetchData(true);
          return;
        case "delete":
          _documents = _documents.filter(
            (d) => d[matchColumn] !== details.original.hierarchy_node_id
          );
          break;
      }
      this.hierarchyEditDialogue.show = false;
      this.hierarchyAddDialogue.show = false;
      this.selectJDs();
    },
    docAdded: function (documents) {
      if (documents.length) _documents.push(...documents);

      if (documents.length === 1 && documents[0].doc_id > 0)
        this.editDocs(documents);

      this.haveData = _documents.length !== 0;
    },
    nodeSaved(original, updates) {
      let descriptor = this.getHierarchyTypeNode(
        original.ht_id,
        original.hierarchy_node_id
      );
      descriptor.description = updates.description;
      original.name = updates.name;
      original.description = updates.description;
    },
    closeHierarchyNodeReview() {
      this.nodeReviewDialogue.node = null;
      this.nodeReviewDialogue.show = false;
    },
    showHierarchyNodeReview(node) {
      this.getNodeReviewActivity(node, () => {
        this.nodeReviewDialogue.node = node;
        this.nodeReviewDialogue.show = true;
      });
    },
    getNodeReviewActivity(node, callback) {
      let possibleError = false;
      let setViewActivity = (_node, docs) => {
        if (docs.length) {
          [
            "docs_open",
            "docs_completed",
            "docsAsParent_open",
            "docsAsParent_completed",
          ].forEach((t) => {
            _node.reviewStatus[t].forEach((d) => {
              let activity = docs.filter((r) => r.doc_id === d.doc_id);
              if (activity.length) {
                d.viewAction = activity[0].viewAction;
                d.activity = activity.map((a) => {
                  let detail =
                    Number(a.events) === 1
                      ? ` once by ${a.activity_by_name} on ${a.activity_date_first}`
                      : ` ${a.events} times by ${a.activity_by_name} between ${a.activity_date_first} and ${a.activity_date_last}`;
                  switch (a.activity_type) {
                    case "Create":
                      return `Created ${a.activity_date_first} by ${a.activity_by_name}`;
                    case "View":
                    case "Edit":
                      return `${a.activity_type}ed ${detail}`;
                    default:
                      return `${a.activity_type}: ${detail}`;
                  }
                });
              }
            });
          });
          _node.reviewStatus.docs_open.forEach((x) => x.tab === null);
          if (_node.nodes) {
            _node.nodes
              .filter((n) => n.reviewStatus)
              .forEach((n) => {
                setViewActivity(n, docs);
              });
          }
        }
      };
      axios
        .get("hierarchy/getHierarchyNodeReviews/" + node.hierarchy_node_id, {
          hierarchy_node_id: node.hierarchy_node_id,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data) {
            setViewActivity(node, resp.data.Data);
            if (callback) callback();
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    getNodeHistory(node) {
      let possibleError = false;
      axios
        .post("hierarchy/getHierarchyNodeAuditData", {
          ht_id: node.ht_id,
          hierarchy_node_id: node.hierarchy_node_id,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data.length) {
            this.nodeHistoryDialogue.data = resp.data.Data;
            this.nodeHistoryDialogue.label = node.name + " History";
            this.nodeHistoryDialogue.show = true;
          } else {
            resp.data.Message = "No changes present for this node";
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    findNode(nodes, node_id) {
      let n = nodes.find((x) => x.hierarchy_node_id === node_id);
      if (n) return n;
      let found = null;
      nodes.forEach((n) => {
        if (!found && n.nodes) {
          found = this.findNode(n.nodes, node_id);
        }
        if (found) return;
      });
      return found;
    },
    getNode(ht_id, node_id) {
      const ht = _hierarchyTypeNodes.find((x) => x.ht_id === ht_id);
      return this.findNode(ht.nodes, node_id);
    },
    // showNodeHistory(node) {
    //   let possibleError = false;
    //   axios
    //     .get("hierarchy/getHierarchyNodeHistory/" + node.hierarchy_node_id)
    //     .then((resp) => {
    //       possibleError = true;
    //       if (resp.data.Status === "OK" && resp.data.Data.length) {
    //         this.nodeHistoryDialogue.rows = resp.data.Data;
    //         this.nodeHistoryDialogue.label =
    //           resp.data.Data[0].level_name + " History";
    //         this.nodeHistoryDialogue.show = true;
    //       }
    //       this.response = resp.data;
    //     })
    //     .catch((err) => {
    //       if (possibleError) {
    //         alert("Code Error");
    //       } else if (err.response && err.response.status === 401) {
    //         this.$emit("sessionExpired", err);
    //       } else {
    //         alert(err.response ? err.response.data.message : err);
    //       }
    //       console.log(err);
    //       this.isSaving = false;
    //     });
    // },
    showAuditReport(ht_id) {
      let possibleError = false;
      axios
        .post("hierarchy/getHierarchyNodeAuditReport", { ht_id: ht_id })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data.length) {
            this.filterAuditReport(resp.data.Data);
            this.nodeHistoryReport.nodes = resp.data.Data;
            this.nodeHistoryReport.label =
              resp.data.Data[0].level_name + " History";
            this.nodeHistoryReport.show = true;
            let userList = [];
            let getUsers = (nodes) => {
              nodes.forEach((n) => {
                if (n.changes) {
                  n.changes.forEach((c) => {
                    if (!userList.some((l) => l === c.updated_by_name))
                      userList.push(c.updated_by_name);
                  });
                }
                if (n.nodes) {
                  getUsers(n.nodes);
                }
              });
            };
            getUsers(resp.data.Data);
            this.nodeHistoryReport.userList = userList;
          }
          this.response = resp.data;
          this.isBuilding = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    selectAuditRow(item, row) {
      row.expand(!row.isExpanded);
    },
    getAuditDescriptionHTML(text) {
      let html = "";
      text = text || "";
      let posS = text.indexOf('"');
      let posE = text.indexOf('"', posS + 1);
      let start = 0;
      while (posS >= 0 && posE > posS) {
        html += `${text.substring(
          start,
          posS - 1
        )} <div class="node-description">${text.substring(
          posS + 1,
          posE
        )}</div>`;
        start = posE + 1;
        posS = text.indexOf('"', start);
        posE = text.indexOf('"', posS + 1);
      }
      html += text.substring(start);
      return html;
    },
    showAuditData(ht_id) {
      this.nodeAuditData.ht_id = ht_id;
      if (this.nodeAuditData.dateRangeFilter.length < 2) {
        this.nodeAuditData.dateRangeFilter = [
          dayJS().subtract(1, "month").format("YYYY-MM-DD"),
          dayJS().format("YYYY-MM-DD"),
        ];
      }
      this.getAuditData();
    },
    filterAuditDataDateRange(range) {
      this.nodeAuditData.dateRangeFilter = range;
      this.getAuditData();
    },
    filterAuditData() {
      if (!this.nodeAuditData.userFilter.length) {
        this.nodeAuditData.items = this.nodeAuditData.data;
      } else {
        let users = this.nodeAuditData.userFilter;
        this.nodeAuditData.items = this.nodeAuditData.data.filter((x) =>
          users.some((u) => u === x.user_name)
        );
      }
    },
    getAuditData() {
      let possibleError = false;
      let data = {
        ht_id: this.nodeAuditData.ht_id,
        from: this.nodeAuditData.dateRangeFilter.length
          ? this.nodeAuditData.dateRangeFilter[0]
          : null,
        to:
          this.nodeAuditData.dateRangeFilter.length === 2
            ? dayJS(this.nodeAuditData.dateRangeFilter[1])
                .add(1, "day")
                .format("YYYY-MM-DD")
            : null,
      };
      axios
        .post("hierarchy/getHierarchyNodeAuditData", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data) {
            this.nodeAuditData.data = resp.data.Data;
            this.nodeAuditData.label = "Transactions";
            //   resp.data.Data[0].level_name + " History";
            this.nodeAuditData.show = true;
            let userList = [];
            resp.data.Data.forEach((d) => {
              if (!userList.some((l) => l === d.user_name))
                userList.push(d.user_name);
            });
            this.nodeAuditData.userList = userList;
            this.filterAuditData();
          }
          this.response = resp.data;
          this.isBuilding = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    showAuditReportChanges(show) {
      let setShow = (nodes) => {
        nodes.forEach((n) => {
          if (n.changes?.length) n.showChanges = show;
          if (n.nodes) {
            setShow(n.nodes);
          }
        });
      };
      setShow(this.nodeHistoryReport.nodes);
    },
    filterAuditReportDateRange(dateRange) {
      this.nodeHistoryReport.dateRangeFilter = dateRange.sort((a, b) =>
        a > b ? 1 : a < b ? -1 : 0
      );
      this.filterAuditReport();
    },
    filterAuditReport(nodes) {
      if (!nodes) nodes = this.nodeHistoryReport.nodes;
      let range = this.nodeHistoryReport.dateRangeFilter;
      let users = this.nodeHistoryReport.userFilter;
      let setVisible = (nodes) => {
        let hasVisible = false;
        nodes.forEach((n) => {
          n.visible = false;
          if (n.changes) {
            n.visible =
              range.length === 0 ||
              n.changes.some(
                (c) =>
                  (dayJS(c.updated_date).isSame(range[0]) ||
                    dayJS(c.updated_date).isAfter(range[0])) &&
                  (dayJS(c.updated_date).isSame(range[1]) ||
                    dayJS(c.updated_date).isBefore(range[1]))
              );
            if (n.visible) {
              n.visible =
                users.length === 0 ||
                n.changes.some((c) =>
                  users.some((u) => u === c.updated_by_name)
                );
            }
          }
          if (n.nodes) {
            if (setVisible(n.nodes)) n.visible = true;
          }
          if (n.visible) hasVisible = true;
        });
        return hasVisible;
      };
      setVisible(nodes);
    },
    groupBy: function (arr, key, measure, dateToMonth) {
      let list = [];
      arr.forEach(function (item) {
        let dKey = item[key];
        if (dateToMonth) dKey = dayJS(dKey).format("YYYY MM");
        let row = list.find((li) => li[key] === dKey);
        if (!row) {
          row = { count: 0 };
          row[key] = dKey;
          if (measure) row[measure] = 0;
          list.push(row);
        }
        if (measure) row[measure] += item[measure] || 0;
        row.count++;
      });
      return list.sort((a, b) =>
        a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0
      );
    },
    groupByKeys: function (
      arr,
      keys,
      measures,
      dateToMonth,
      sdMeasures,
      drilldownKey
    ) {
      let list = [];
      let addSDEnries = this.addAggregateColumns;
      let keyValues = keys.map((x) => {
        return { key: x, values: [] };
      });
      arr.forEach(function (item) {
        let key = {},
          keyVal = "";
        keyValues.forEach((k, i) => {
          let dKey = item[k.key];
          if (dateToMonth && i === 0) dKey = dayJS(dKey).format("YYYY MM");
          key[k.key] = dKey;
          keyVal += "|" + dKey + "|";
          if (!k.values.some((v) => v === dKey)) k.values.push(dKey);
        });

        let row = list.find(function (li) {
          return li.keyVal === keyVal;
        });
        // let addSDEnries = (row, item, col) => {
        //   if (!row[col.type + "_sd_values"]) row[col.type + "_sd_values"] = [];
        //   let val = Number(item[col.type]);
        //   if (col.aggregation_quantity_column) {
        //     let avg = val / Number(item[col.aggregation_quantity_column]);
        //     for (let e = 1; e <= item[col.aggregation_quantity_column]; e++) {
        //       row[col.type + "_sd_values"].push(avg);
        //     }
        //   } else {
        //     row[col.type + "_sd_values"].push(item[val]);
        //   }
        // };
        if (!row) {
          row = { keyVal: keyVal, matchKey: keyVal, matchColumns: keys };
          keys.forEach(function (k) {
            row[k] = key[k];
          });
          measures.forEach(function (m) {
            row[m] = item[m] || 0;
          });
          if (drilldownKey) {
            row[drilldownKey + "s"] = [];
          }
          list.push(row);
        } else {
          measures.forEach(function (m) {
            row[m] += item[m] || 0;
          });
        }
        if (sdMeasures && sdMeasures.length) {
          sdMeasures.forEach(function (sm) {
            addSDEnries(row, item, sm);
          });
        }
        if (drilldownKey) {
          let dd = row[drilldownKey + "s"].find(
            (x) => x[drilldownKey] === item[drilldownKey]
          );
          if (!dd) {
            dd = {};
            dd[drilldownKey] = item[drilldownKey];
            measures.forEach(function (m) {
              dd[m] = item[m] || 0;
            });
            row[drilldownKey + "s"].push(dd);
          } else {
            measures.forEach(function (m) {
              dd[m] += item[m] || 0;
            });
          }
        }
      });
      let formatNumber = this.formatAggregate;
      if (sdMeasures && sdMeasures.length) {
        list.forEach((row) => {
          sdMeasures.forEach(function (sm) {
            let values = row[sm.type + "_sd_values"];
            const n = values.length;
            if (!n) return;
            const mean = values.reduce((a, b) => a + b) / n;
            row[sm.type + "_std_dev"] = Math.sqrt(
              values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
                n
            );
            row[sm.type + "_mean"] = formatNumber(sm, mean);
            row[sm.type + "_mean_number"] = mean;
            row[sm.type + "_mean_value_count"] = n;
          });
        });
      }
      return { rows: list, keyValues: keyValues };
    },
    getColumnName: (compareOption) => {
      return compareOption.currentDrillDownLevel
        ? compareOption.drillDownLevels[compareOption.currentDrillDownLevel - 1]
            .column
        : compareOption.column;
    },
    appendNodeHierarchyLevels: function (node) {
      let ht = this.hierarchyTypes.find((x) => x.ht_id === node.ht_id);
      node.h_levels = ht?.h_levels;
    },
    formatAggregate: function (measure, data) {
      let format = measure.format || "0";
      if (format !== "0") {
        let prefix = ["£", "$"].find((x) => format.indexOf(x) >= 0) || "";
        let suffix = ["k", "m"].find((x) => format.indexOf(x) >= 0) || "";
        if (suffix === "k") data = data / 1000;
        if (suffix === "m") data = data / 1000000;
        format = format.replace(prefix, "").replace(suffix, "");
        return prefix + data.toFixed(parseInt(format) || 0) + suffix;
      } else {
        return data;
      }
    },
    addAggregateColumns: function (row, item, measure) {
      if (!row[measure.type + "_sd_values"])
        row[measure.type + "_sd_values"] = [];
      let val = item[measure.type];
      if (val === null || val === undefined || val === "") return;
      val = Number(item[measure.type]);
      if (val === 0 && measure.aggregation_exclude_zero) return;
      if (measure.aggregation_quantity_column) {
        let avg = val / Number(item[measure.aggregation_quantity_column]);
        for (let e = 1; e <= item[measure.aggregation_quantity_column]; e++) {
          row[measure.type + "_sd_values"].push(avg);
        }
      } else {
        row[measure.type + "_sd_values"].push(item[val]);
      }
    },
    groupByKeysNested: function (arr, keys, measures, dateToMonth, sdMeasures) {
      let data = {};
      let findKey = (key, data, item) => {
        const keyCol = this.getColumnName(key);
        const col = keyCol + "s";
        if (!data[col]) data[col] = [];
        let dKey = item[keyCol];
        if (!dKey && key.ht_id) dKey = "UNCLASSIFIED";
        if (dateToMonth) dKey = dayJS(dKey).format("YYYY MM");
        let row = data[col].find((li) => li.keyVal === dKey);
        if (!row) {
          let sortKey;
          if (key.useSortKey && key.listValues) {
            sortKey = key.listValues.find((x) => x.value === dKey)?.sort_key;
          }
          sortKey = sortKey || dKey;
          row = {
            keyVal: dKey,
            sortKey: sortKey,
            matchColumns: data.matchColumns
              ? [...data.matchColumns, keyCol]
              : [keyCol],
            matchKey: (data.matchKey || "") + "|" + dKey + "|",
            title: dKey,
            ht_id: key.ht_id,
            hLevel: key.currentDrillDownLevel,
            hierarchy_node_id:
              key.ht_id && key.currentDrillDownLevel
                ? item[keyCol + "nodeID"]
                : null,
          };
          if (key.ht_id && key.hierarchyLevels) {
            // add in node IDs
            key.hierarchyLevels.forEach(
              (l) => (row[l.node_column] = item[l.node_column])
            );
          }
          row[keyCol] = dKey;
          measures.forEach(function (m) {
            row[m] = item[m] || 0;
          });
          if (key.ht_id && !key.currentDrillDownLevel) {
            row.sortKey = item["ht_id_" + key.ht_id + "_label"];
            row.title = item["ht_id_" + key.ht_id + "_text"];
          }
          data[col].push(row);
        } else {
          measures.forEach(function (m) {
            row[m] += item[m] || 0;
          });
        }
        let addSDEnries = this.addAggregateColumns;
        if (sdMeasures && sdMeasures.length) {
          sdMeasures.forEach(function (sm) {
            addSDEnries(row, item, sm);
          });
        }
        return row;
      };
      let formatNumber = this.formatAggregate;
      let calculateAggregateValues = (keyIndex, data) => {
        if (keyIndex >= keys.length) return;
        const keyCol = this.getColumnName(keys[keyIndex]);
        data[keyCol + "s"].forEach((row) => {
          sdMeasures.forEach(function (sm) {
            let values = row[sm.type + "_sd_values"];
            const n = values.length;
            if (!n) return;
            const mean = values.reduce((a, b) => a + b) / n;
            row[sm.type + "_std_dev"] = Math.sqrt(
              values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
                n
            );
            row[sm.type + "_mean"] = formatNumber(sm, mean);
            row[sm.type + "_mean_number"] = mean;
            row[sm.type + "_mean_value_count"] = n;
          });
          calculateAggregateValues(keyIndex + 1, row);
        });
      };
      arr.forEach((item) => {
        let lst = data;
        keys.forEach((k) => {
          lst = findKey(k, lst, item);
        });
      });
      if (sdMeasures && sdMeasures.length) {
        calculateAggregateValues(0, data);
      }
      return data;
    },
    groupByM: function (arr, key, measures) {
      let list = [];
      arr.forEach(function (item) {
        let row = list.filter(function (li) {
          return li[key] === item[key];
        })[0];
        if (!row) {
          row = {};
          row[key] = item[key];
          measures.forEach(function (m) {
            row[m] = 0;
          });
          list.push(row);
        }
        measures.forEach(function (m) {
          if (item[m] !== 0) row[m] = (row[m] || 0) + item[m];
        });
      });
      return list;
    },
    distinctValues: function (arr, keys) {
      let list = [];
      arr.forEach(function (item) {
        let row = list.filter(function (li) {
          let match = true;
          keys.forEach(function (k) {
            if (li[k] !== item[k]) match = false;
          });
          return match;
        })[0];
        if (!row) {
          row = { _count: 1 };
          keys.forEach(function (k) {
            row[k] = item[k];
          });
          list.push(row);
        } else {
          row._count++;
        }
      });
      return list;
    },
    setCompareOption: function (val, compareOptionName) {
      if (this[compareOptionName] === val) return;
      if (val.currentDrillDownLevel !== undefined)
        val.currentDrillDownLevel = 1;
      this[compareOptionName] = val;
      if (
        this.verticalCompareOption.isBreakdown &&
        val === this.verticalCompareOption
      ) {
        this.horizontalCompareOption = this.compareOptionsH.find(
          (x) => x.column === val.column && x.isBreakdown
        );
      }
      this.buildCompare();
    },
    setHorizontalCompareOption: function (val) {
      this.setCompareOption(val, "horizontalCompareOption");
    },
    setVerticalCompareOption: function (val) {
      this.setCompareOption(val, "verticalCompareOption");
    },
    setDetailCompareOption: function () {
      this.showDetail = !this.detailCompareOption.hideDetails;
      this.buildCompare();
    },
    clearDetailCompareOption: function () {
      this.showDetail = false;
      this.detailCompareOption = this.compareOptionsD.find(
        (x) => x.hideDetails
      );
      this.buildCompare();
    },
    setShowDetail: function () {
      this.buildCompare();
    },
    setShowSalary: function () {
      this.buildCompare();
    },
    editDocs: function (items) {
      this.prepEditDialogue(this.editDialogue, items);
    },
    initPaging: function (dialogue) {
      dialogue.items.forEach((x, xi) => {
        x.page = parseInt(xi / dialogue.pageSize);
        dialogue.pages = x.page + 1;
        return x;
      });
      if (dialogue.items.length === 0) return;
      dialogue.show = true;
      dialogue.page = 0;
      this.dialoguePageText(dialogue);
    },
    prepEditDialogue: function (dialogue, items) {
      let itemList = null;
      if (items) {
        itemList = _documents.filter((x) =>
          items.some((i) => i.doc_id === x.doc_id)
        );
      } else {
        itemList = this.compareItems;
      }
      dialogue.items = itemList.map((x) => {
        this.attributes.forEach((a) => {
          x["change_" + a.value_column] = false;
        });
        this.hierarchies.forEach((h) => {
          x["change_ht_id_" + h.ht_id] = false;
        });
        return x;
      });
      if (dialogue.items.length === 0) return;
      dialogue.canSave = false;
      dialogue.columns.splice(0, dialogue.columns.length);
      this.attributes
        .filter((a) => !a.editUnavailable)
        .forEach((a, ai) => {
          let val = dialogue.items[0][a.value_column];
          let same = dialogue.items.every((x) => x[a.value_column] === val);
          dialogue.columns.push({
            column: a.column,
            value_column: a.value_column,
            data_type: a.data_type,
            value_data_type: a.value_data_type,
            label: a.value_label,
            tpa_id: a.tpa_id || ai + 1,
            same: same,
            value: same ? val : null,
            values: a.values ? a.values.map((v) => v.value) : null,
            isDirty: false,
          });
        });
      this.hierarchyTypes.forEach((h) => {
        let val = dialogue.items[0]["ht_id_" + h.ht_id];
        let text = dialogue.items[0][`ht_id_${h.ht_id}_text`];
        let hLabel = dialogue.items[0][`ht_id_${h.ht_id}_label`].split(" > ");
        let same = dialogue.items.every((x) => x["ht_id_" + h.ht_id] === val);
        dialogue.columns.push({
          column: "ht_id_" + h.ht_id,
          value_column: "ht_id_" + h.ht_id,
          label: h.label,
          ht_id: h.ht_id,
          linklevel: h.linklevel,
          same: same,
          value: same ? val : null,
          text: same ? text : null,
          isDirty: false,
          hLabel: same ? hLabel : [],
        });
      });
      this.initPaging(dialogue);
    },
    afterDocumentUpdate: (context, updatedDocs) => {
      if (updatedDocs) {
        context.appendHierarchiesToRawData(updatedDocs);
        updatedDocs.forEach((x) => {
          let ind = _documents.findIndex((d) => d.doc_id === x.doc_id);
          if (ind >= 0) {
            _documents.splice(ind, 1, x);
          } else {
            _documents.push(x);
          }
        });
      }
      context.selectJDs();
    },
    saveDocs: function () {
      let updated = false;
      let singleDoc = false; //this.editDialogue.items.length === 1;
      let data = { updates: [] };
      let ht = null;
      //   this.compareItems.forEach((d) => {
      this.editDialogue.items.forEach((d) => {
        this.editDialogue.columns.forEach((a) => {
          if (
            d[a.value_column] !== a.value &&
            (d["change_" + a.value_column] || singleDoc)
          ) {
            d[a.value_column] = a.value;
            updated = true;
            data.updates.push({
              doc_id: d.doc_id,
              tpa_id: a.tpa_id,
              ht_id: a.ht_id,
              value: a.value,
            });
            if (a.ht_id) {
              if (!ht || ht.ht_id !== a.ht_id)
                ht = this.hierarchyTypes.find((ht) => ht.ht_id === a.ht_id);
              this.setDocHierarchyValues(d, ht, a.value);
            }
          }
        });
      });
      let afterUpdate = (updatedDocs) => {
        this.resetCompare();
        this.afterDocumentUpdate(this, updatedDocs);
        this.editDialogue.show = false;
      };
      if (updated) {
        if (this.isDemo) {
          afterUpdate();
        } else {
          let possibleError = false;
          this.isSaving = true;

          axios
            .post("document/saveDocAttributes/", data)
            .then((resp) => {
              possibleError = true;
              if (resp.data.Status === "OK") {
                afterUpdate(resp.data.Data.docs);
              }
              this.response = resp.data;
              this.isSaving = false;
            })
            .catch((err) => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.isSaving = false;
            });
        }
      }
    },
    reviewHierarchy: function () {
      let data = { node: this.nodeDescriptorView.node };
      let possibleError = false;
      this.isSaving = true;

      axios
        .post("document/createHierarchyReviewDocument/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.openDocument(resp.data.Data);
          }
          this.response = resp.data;
          this.isSaving = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    editDialogueCheckCount(attibute) {
      let col = "change_" + attibute;
      return this.editDialogue.items.filter((x) => x[col]).length;
    },
    editDialogueCheckAll(attibute) {
      let col = "change_" + attibute;
      let onCount = 0;
      let offCount = 0;
      this.editDialogue.items.forEach((x) => {
        x[col] ? onCount++ : offCount++;
      });
      let newVal = onCount >= offCount ? false : true;
      this.editDialogue.items.forEach((x) => (x[col] = newVal));
      this.setCanSave();
    },
    dialogueNextPage(dialogue, plus) {
      dialogue.page += plus ? 1 : -1;
      if (dialogue.page >= dialogue.pages) dialogue.page = dialogue.pages - 1;
      else if (dialogue.page < 0) dialogue.page = 0;
      this.dialoguePageText(dialogue);
    },
    dialoguePageText(dialogue) {
      dialogue.colSize = "";
      if (dialogue.pages === 1) {
        dialogue.pageText = `(1 to ${dialogue.items.length} of ${dialogue.items.length})`;
      } else {
        let endno = (dialogue.page + 1) * dialogue.pageSize;
        if (endno > dialogue.items.length) {
          endno = dialogue.items.length;
          dialogue.colSize = parseInt(12 / dialogue.pageSize);
        }
        dialogue.pageText = `(${
          dialogue.page * dialogue.pageSize + 1
        } to ${endno} of ${dialogue.items.length})`;
      }
    },
    identifySimilarJobs(doc) {
      let matchBands = [
        {
          name: "91 - 100%",
          lower: 91,
          upper: 100,
          count: 0,
          pct: 0,
          items: [],
        },
        { name: "81 - 90%", lower: 81, upper: 90, count: 0, pct: 0, items: [] },
        { name: "90 - 80%", lower: 71, upper: 80, count: 0, pct: 0, items: [] },
        { name: "90 - 70%", lower: 61, upper: 70, count: 0, pct: 0, items: [] },
        { name: "90 - 60%", lower: 51, upper: 60, count: 0, pct: 0, items: [] },
        { name: "90 - 50%", lower: 41, upper: 50, count: 0, pct: 0, items: [] },
        { name: "90 - 40%", lower: 31, upper: 40, count: 0, pct: 0, items: [] },
        { name: "90 - 30%", lower: 21, upper: 30, count: 0, pct: 0, items: [] },
        { name: "11 - 20%", lower: 11, upper: 20, count: 0, pct: 0, items: [] },
        { name: "0 - 10%", lower: 0, upper: 10, count: 0, pct: 0, items: [] },
      ];
      let reqScore = 0;
      let reqSkills = doc.skills.map((x) => {
        let score =
          this.skillLevels.find((l) => l.level === x.level)?.score || 1;
        reqScore += score;
        return {
          name: x.name,
          score: score + 1,
        };
      });
      _documents.forEach((x) => {
        let score = 0;
        reqSkills.forEach((s) => {
          let hasSkill = x.skills.find((ds) => ds.name === s.name);
          if (hasSkill) {
            score += s.score;
            score -= Math.abs(
              s.score -
                this.skillLevels.find((l) => l.level === hasSkill.level)
                  ?.score || 1
            );
          }
        });
        let result = parseInt((100 * score) / reqScore);
        let matchBand = matchBands.find(
          (b) => b.lower <= result && b.upper >= result
        );
        matchBand.count++;
        matchBand.items.push(x.system_number);
      });
      matchBands.forEach((x) => (x.pct = (100 * x.count) / _documents.length));
      this.skillsDialogue.matchBands = matchBands;
      let matches = _documents.filter(
        (x) =>
          x.skills.length === doc.skills.length &&
          doc.skills.every((ds) =>
            x.skills.some((xs) => xs.name === ds.name && xs.level === ds.level)
          )
      );

      alert(matches.length + " jobs with same skill profile");
    },
    editDocSkills(doc) {
      this.docSkillDialogue.doc = doc;
      //   let docskills = JSON.parse(JSON.stringify(doc.skills));
      this.docSkillDialogue.categories = this.skillCats.map((x) => {
        return {
          category: x.category,
          skills: doc.skills.filter((s) => s.category === x.category),
        };
      });
      this.docSkillDialogue.show = true;
    },
    addDocSkill(doc, skill, catIndex, skillIndex) {
      let raw = _documents.find((x) => x.system_number === doc.system_number);
      doc.skills.findIndex((x) => x.name === skill);
      if (!skill) {
        let sk = this.skillsDialogue.categories[catIndex].skills[skillIndex];
        raw.skills.push(JSON.parse(JSON.stringify(sk)));
        this.skillsDialogue.categories = this.buildDocCompareSkillsMatrix();
      }
    },
    removeDocSkill(doc, skill) {
      let raw = _documents.find((x) => x.system_number === doc.system_number);
      let ind = raw?.skills.findIndex((x) => x.name === skill);
      if (ind >= 0) raw.skills.splice(ind, 1);
      ind = doc.skills.findIndex((x) => x.name === skill);
      if (ind >= 0) doc.skills.splice(ind, 1);
      if (
        this.docSkillDialogue.doc &&
        this.docSkillDialogue.doc.system_number === doc.system_number
      ) {
        ind = this.docSkillDialogue.doc.skills.findIndex(
          (x) => x.name === skill
        );
        if (ind >= 0) this.docSkillDialogue.doc.skills.splice(ind, 1);
        this.docSkillDialogue.categories.forEach((c) => {
          ind = c.skills.findIndex((x) => x.name === skill);
          if (ind >= 0) c.skills.splice(ind, 1);
        });
      }
      let sdDoc = this.skillsDialogue.items.find(
        (x) => x.system_number === doc.system_number
      );
      if (sdDoc) {
        ind = sdDoc.skills.findIndex((x) => x.name === skill);
        if (ind >= 0) sdDoc.skills.splice(ind, 1);
        this.skillsDialogue.categories = this.buildDocCompareSkillsMatrix();
        sdDoc.categories.forEach((c) => {
          ind = c.skills.findIndex((x) => x && x.name === skill);
          if (ind >= 0) delete c.skills[ind];
        });
      }
    },
    updateSkillLevel(docSkill, level) {
      docSkill.level = level.level;
      docSkill.colour = level.colour;
      docSkill.textColour = level.textColour;
    },
    setSkillLevel(skills, skill, level) {
      let item = skills.find((s) => s.name === skill);
      this.updateSkillLevel(item, level);
    },
    getDocSkill(skills, skill) {
      return skills.find((s) => s.name === skill);
    },
    openMeasurePicker(e) {
      this.measurePicker.show = false;
      this.measurePicker.posX = e.clientX;
      this.measurePicker.posY = e.clientY;
      if (this.measures.length) {
        this.$nextTick(() => {
          this.measurePicker.show = true;
        });
      }
    },
    openDocListMenu(e, item, measure) {
      if (e.preventDefault) e.preventDefault();
      this.docListMenu.show = false;
      this.docListMenu.posX = e.clientX;
      this.docListMenu.posY = e.clientY;
      let JF_ht_id = this.hierarchyTypes.find(
        (x) => x.ht_name.toLowerCase().indexOf("job") >= 0
      )?.ht_id;

      let docs = item ? this.getDocs(item) : this.compareItems;
      this.docListMenu.showSuggestions = docs.some(
        (x) =>
          x[`ht_id_${JF_ht_id}_1_`] === "UNCLASSIFIED" ||
          x[`ht_id_${JF_ht_id}_2_`] === "UNCLASSIFIED" ||
          x[`ht_id_${JF_ht_id}_3_`] === "UNCLASSIFIED"
      );
      this.docListMenu.matchColumn =
        measure && measure.type.indexOf("match_count") >= 0
          ? measure.type
          : null;
      this.docListMenu.documentType = this.catalogueDocType;
      if (this.docListMenu.matchColumn) {
        docs = docs.filter((d) => d[this.docListMenu.matchColumn] > 0);
      }
      this.docListMenu.documents = docs;
      this.docListMenu.title = measure?.description || this.catalogueDocType;
      if (this.docListMenu.documents.length) {
        this.$nextTick(() => {
          this.docListMenu.show = true;
        });
      }
    },
    buildMatchItem_(item, matchColumns) {
      let match = "";
      if (item)
        matchColumns.forEach((c) => {
          match += "|" + item[c] + "|";
        });
      return match;
    },
    getDocs(item) {
      if (item.job_count === 0) return [];
      const matchKey = item.matchKey;
      return this.selectedDocs.filter(
        (x) =>
          x.doc_id > 0 &&
          this.buildMatchItem_(x, item.matchColumns) === matchKey
      );
    },
    openContextMenu(e, item, column) {
      this.contextMenu.item = item;
      this.contextMenu.column = column;
      e.preventDefault();
      this.contextMenu.show = false;
      this.contextMenu.posX = e.clientX;
      this.contextMenu.posY = e.clientY;
      let itemText = item[column.value];
      this.contextMenu.title = itemText;
      let actions = [];
      if (column) {
        if (
          column.filterSettings.values.some(
            (x) => x.selected && x.text === itemText
          )
        ) {
          actions.push({
            title: `Remove from Filter`,
            icon: "mdi-filter-minus-outline",
            type: "removeFilter",
            column: null,
          });
        } else {
          actions.push({
            title: `Add to filter`,
            icon: "mdi-filter-plus-outline",
            type: "addFilter",
            column: null,
          });
        }
      }
      this.contextMenu.actions = actions;
      this.$nextTick(() => {
        this.contextMenu.show = true;
      });
    },
    doContextMenuAction(action) {
      switch (action.type) {
        case "addFilter":
          this.setFilterValue(this.contextMenu.item, this.contextMenu.column);
          break;
        case "removeFilter":
          this.removeFilterValue(
            this.contextMenu.item,
            this.contextMenu.column
          );
          break;
      }
      this.contextMenu.show = false;
    },
    openDocActionsMenuContext(event, document) {
      event.preventDefault();
      let doc = { doc_id: document.doc_id };
      if (document.isDocument) {
        doc.doc_id = this.selectedDocs.find(
          (x) => x.system_number === document.value
        )?.doc_id;
      }
      this.docActionsMenuContext.document = doc;
      this.docActionsMenuContext.posX = event.clientX;
      this.docActionsMenuContext.posY = event.clientY;
      this.docActionsMenuContext.show = false;
      this.$nextTick(() => {
        this.docActionsMenuContext.show = true;
      });
    },
    setDescriptorHeight: function (rowHeading) {
      let lines =
        rowHeading.detailCountHeader.filter(
          (x) =>
            x.dIndex >= this.vScroll.dStart && this.vScroll.dEnd >= x.dIndex
        ).length + 1;
      if (!this.showDetail) {
        return "descriptor descriptor1line";
      } else if (lines < 8) {
        return "descriptor descriptor" + lines + "line";
      } else {
        return "descriptor descriptor8line";
      }
    },
    setViewMenu: function (v) {
      this.selectedView = v;
    },
    initSetView: function () {
      this.isBuilding = true;
      this.resetIsBuilding = false;
      this.doSetView = true;
    },
    setView: function () {
      this.doSetView = false;
      this.resetCompare();
      if (
        this.selectedView.viewType === "list" ||
        this.selectedView.viewType === "grouped"
      ) {
        this.listView.hierarchyView = this.hierarchyTypes.find(
          (x) => x.ht_name === this.selectedView.hierarchy
        );
      } else if (this.selectedView.viewType === "catalogue") {
        this.hideHorizontal = false;
        this.detailCompareOption = this.compareOptionsD.find(
          (x) => x.hideDetails
        );
        this.showDetail = false;
        this.showSalary = false;
        switch (this.selectedView.title) {
          case "Default":
            this.horizontalCompareOption = this.compareOptionsH.find(
              (x) => x.name.toLowerCase().indexOf("grade") >= 0
            );
            this.verticalCompareOption = this.compareOptionsV.find(
              (x) => x.name.toLowerCase().indexOf("level") >= 0
            );
            this.showDuplicatesOnly = false;
            break;
          case "Job Family By Level":
            this.horizontalCompareOption = this.compareOptionsH.find(
              (x) =>
                x.name.indexOf("Job") >= 0 && x.name.indexOf("Drilldown") >= 0
            );
            this.verticalCompareOption = this.compareOptionsV.find(
              (x) => x.name.toLowerCase().indexOf("level") >= 0
            );
            this.detailCompareOption = this.compareOptionsD.find(
              (x) => x.name === "Title"
            );
            this.showDetail = true;
            this.showDuplicatesOnly = false;
            break;
          case "Job Family By Grade":
            this.horizontalCompareOption = this.compareOptionsH.find(
              (x) =>
                x.name.indexOf("Job") >= 0 && x.name.indexOf("Drilldown") >= 0
            );
            this.verticalCompareOption = this.compareOptionsV.find(
              (x) => x.name.toLowerCase().indexOf("grade") >= 0
            );
            this.detailCompareOption = this.compareOptionsD.find(
              (x) => x.name.toLowerCase() === "Title"
            );
            this.showDetail = true;
            this.showDuplicatesOnly = false;
            break;
          case "Job Title By Job Family":
            this.horizontalCompareOption = this.compareOptionsH.find(
              (x) =>
                x.name.indexOf("Job") >= 0 && x.name.indexOf("Drilldown") >= 0
            );
            this.verticalCompareOption = this.compareOptionsV.find(
              (x) => x.name === "Title"
            );
            this.showDuplicatesOnly = false;
            break;
          case "Pay Transparency":
            this.horizontalCompareOption = this.compareOptionsH.find(
              (x) => x.name.toLowerCase().indexOf("grade") >= 0
            );
            this.verticalCompareOption = this.compareOptionsV.find(
              (x) => x.name === "Title"
            );
            this.detailCompareOption = this.compareOptionsD.find(
              (x) => x.name === "Gender"
            );
            this.showDetail = true;
            this.showSalary = true;
            this.showDuplicatesOnly = false;
            break;
          case "Job Family Summary": // modified for Novanta but will make cleint setting
            this.horizontalCompareOption = this.compareOptionsH.find(
              (x) => x.name.toLowerCase().indexOf("management") >= 0
            );
            if (!this.horizontalCompareOption)
              this.horizontalCompareOption = this.compareOptionsH.find(
                (x) => x.name.toLowerCase().indexOf("grade") >= 0
              );
            this.verticalCompareOption = this.compareOptionsV.find(
              (x) =>
                x.name.indexOf("Job") >= 0 && x.name.indexOf("Drilldown") >= 0
            );
            this.showDuplicatesOnly = false;
            break;
          case "Tree View":
            this.horizontalCompareOption = this.compareOptionsH.find(
              (x) => x.name.toLowerCase().indexOf("grade") >= 0
            );
            this.verticalCompareOption = this.compareOptionsV.find(
              (x) => x.name.indexOf("Job") >= 0 && x.name.indexOf("Tree") >= 0
            );
            this.showDuplicatesOnly = false;
            this.hideHorizontal = true;
            break;
        }
        this.horizontalCompareOption =
          this.horizontalCompareOption || this.compareOptionsH[0];
        this.verticalCompareOption =
          this.verticalCompareOption || this.compareOptionsV[1];
      } else if (this.selectedView.viewType === "audit") {
        this.showAuditReport(
          this.hierarchyTypes.find(
            (x) => x.ht_name === this.selectedView.hierarchy
          ).ht_id
        );
        return;
      } else if (this.selectedView.viewType === "audit_data") {
        this.showAuditData(
          this.hierarchyTypes.find(
            (x) => x.ht_name === this.selectedView.hierarchy
          ).ht_id
        );
        return;
      }
      this.checkViewTypeInitialised();
      this.selectJDs();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 103px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
  }
  .right-col {
    transition: padding 200ms;
    // padding-bottom: 65px;
    padding-left: 10px;
    &.hierarchiesPinned {
      padding-left: 410px;
    }
  }
}

.hierarchies-collapsed {
  width: 20px;

  min-height: calc(100vh - 103px) !important;
  .showHierarchies {
    position: fixed;
    top: 72px;
    left: 5px;
  }
}

.theme--light .hierarchies-collapsed {
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--dark .hierarchies-collapsed,
.theme--dark.v-navigation-drawer {
  background-color: $primary-background-dark;
  border-right: 1px solid hsla(0, 0%, 100%, 0.12);
}

.showHierarchies {
  top: 22px;
  right: -15px;
}
</style>
<style>
div.v-treeview-node__label {
  font-size: 12px;
}
.v-treeview-node__toggle {
  max-width: 10px !important;
}
.v-treeview-node__root {
  padding: 0 !important;
  min-height: 25px !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .no-print-border,
  .no-print-border * {
    border: none !important;
    box-shadow: none !important;
  }
  .printFullWidth,
  .printFullWidth * {
    max-width: 100% !important;
    flex: none;
    padding-right: 6px !important;
  }
  .v-main {
    padding-top: 0;
  }
  .mainContent {
    padding: 0 !important;
  }
}
.v-input__icon--append-outer .v-icon {
  color: grey;
  font-size: 18px;
}

.customFlag {
  transform: scale(0.35) !important;
  -ms-transform: scale(0.35) !important;
  -webkit-transform: scale(0.35) !important;
  -moz-transform: scale(0.35) !important;
}

.narrowIcons {
  max-width: 27px;
  padding-top: 7px !important;
  padding-left: 0 !important;
}
</style>
<style>
/* set the CSS */

.node circle,
.node rect {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.compcell {
  text-align: center;
  border-right: solid lightgrey 1px;
}

.overflowHidden {
  overflow: hidden;
}
.overflowAuto {
  overflow: auto;
  height: 400px;
}

.title_cell {
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  font-weight: bold;
  font-size: 15px;
}

.label_cell label.v-label,
.document_cell,
.label_cell {
  text-align: left;
  margin-right: 5px;
  margin-left: 5px;
  color: blue;
  overflow: hidden;
  /* font-weight: bold; */
  font-size: 13px;
  padding-top: 0px;
}
.document_cell {
  border: solid 1px gray;
  border-radius: 5px;
  /* padding-top: 3px; */
  padding-left: 3px;
  cursor: pointer;
}
.compare_title {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
}
.compare_sub_title {
  margin-left: 25px;
  text-align: left;
  /* font-weight: bold; */
  font-size: 13px;
}
.compare_expert {
  background-color: darkgreen;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
  margin: 3px 5px;
}
.compare_practioner {
  background-color: lightgreen;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
  margin: 3px 5px;
}
.compare_novice {
  background-color: orange;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  border-radius: 8px;
  margin: 3px 5px;
}
div.v-input__slot {
  margin-bottom: 0;
}
.v-input--selection-controls .v-input__slot > .v-label,
/* .label_cell, */
.col_header {
  white-space: nowrap;
  /* width: 30px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  width: 100%;
}
.scrollCol {
  padding: 5px;
  margin-top: -1px;
}
.dialogueRow {
  min-height: 50px;
  max-height: 50px;
}
.dialogueRowHierarchy {
  min-height: 100px;
  max-height: 100px;
}
.dialogueColumn,
.dialogueColumnTop,
.dialogueColumnBottom {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  margin-right: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dialogueColumnSame {
  background-color: rgb(238 246 227);
}
.dialogueColumnCheckBoxRow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dialogueColumnTop {
  border-top: 1px solid gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.dialogueColumnBottom {
  border-bottom: 1px solid gray;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.rowHeader,
.rowHeaderDrilldown,
.columnPicker,
.menuSubject {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.rowHeaderDrilldown,
.selectable {
  cursor: pointer;
}
.columnPicker {
  background-color: #e0f2f1;
}
.menuSubject {
  width: fit-content;
  padding-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
}
.menuLabel {
  width: fit-content;
  padding-top: 5px;
}
.subjectPicker {
  cursor: pointer;
  display: block;
  border: solid 1px #004d40;
  border-radius: 5px;
  /* height: 32px; */
  height: 95px;
  font-size: 16px;
  padding: 6px;
  padding-left: 12px;
  width: 100%;
}
.subjectText {
  font-size: 14px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  width: 90%;
}
.subjectIcon {
  margin-top: -3px;
  padding-right: 6px;
  float: right;
}
.changed,
.changedSelect > div > div > fieldset {
  border: solid 2px red !important;
}
.tableHeader {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
}
.descriptor {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.descriptor1line {
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
}
.descriptor2Line {
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}
.descriptor3Line {
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
}
.descriptor4Line {
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
}
.descriptor5Line {
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
}
.descriptor6Line {
  -webkit-line-clamp: 6; /* number of lines to show */
  line-clamp: 6;
}
.descriptor7Line {
  -webkit-line-clamp: 7; /* number of lines to show */
  line-clamp: 7;
}
.descriptor8Line {
  -webkit-line-clamp: 8; /* number of lines to show */
  line-clamp: 8;
}

.review-user {
  padding-left: 10px;
  background-color: white;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 3px;
}
.feedback-row {
  background-color: #eceff1;
  border-radius: 8px;
  margin-left: 0;
  margin-bottom: 0;
}
.node-description {
  display: inline;
  border: solid 1px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 5px;
}
.measure-column {
  min-height: 34px;
  text-align: center;
}

.leaf_node_count {
  text-align: center;
  border-radius: 16px;
  font-size: 13px;
  color: black;
  max-width: 65px;
  min-width: 20px;
  padding: 0 8px;
  display: inline-block;
  line-height: 19px;
  border: solid gray 2px;
}
</style>