<template>
  <div
    :class="{
      displayBorder: displayBorder,
      displayTitle: displayTitle,
      selectable: node.drilldown || allowExpand,
    }"
    @click="node.drilldown ? doAction('drilldown') : null"
    :title="node.name"
    @contextmenu="openContextMenu($event)"
  >
    <div
      :class="{
        overflow: displayBorder || displayTitle,
      }"
    >
      <v-tooltip top v-if="!displayTitle && !hideDrilldown && node.drilldown">
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on">
            mdi-chevron-triple-down
          </v-icon>
        </template>
        <span>Drilldown to {{ node.nextLevel }} for: {{ node.name }}</span>
      </v-tooltip>
      <v-icon v-if="allowExpand">{{
        node.show ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small right v-bind="attrs" v-on="on">
            mdi-dots-vertical-circle-outline
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(a, ai) in getActions()"
            :key="'am' + ai"
            @click="doAction(a.type, a)"
          >
            <v-list-item-icon>
              <v-icon :color="a.colour ? a.colour : ''">{{ a.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ a.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip v-if="!hideDescriptor && node.descriptor" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            right
            @click.stop="doAction('editHierarchyDescriptor')"
            v-bind="attrs"
            v-on="on"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Description: {{ node.descriptor.description }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="
          !hideReview &&
          node.reviewStatus &&
          node.reviewStatus.docsAsParent_open &&
          node.reviewStatus.docsAsParent_open.length
        "
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            right
            @click.stop="doAction('showReviewParent')"
            v-bind="attrs"
            v-on="on"
            :color="node.reviewStatus.hasDataAsParent ? 'orange' : 'blue'"
          >
            mdi-eye-settings-outline
          </v-icon>
        </template>
        <span
          >Has {{ node.nextLevel }}s currently
          {{
            node.reviewStatus.docsAsParent_open[0].status +
            (node.reviewStatus.hasDataAsParent
              ? " (has feedback)"
              : " (no feedback)")
          }}</span
        >
      </v-tooltip>
      <v-tooltip
        v-if="
          !hideReview && node.reviewStatus && node.reviewStatus.docs_open.length
        "
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            right
            @click.stop="doAction('showReview')"
            v-bind="attrs"
            v-on="on"
            :color="node.reviewStatus.hasData ? 'orange' : 'blue'"
          >
            mdi-eye-settings
          </v-icon>
        </template>
        <span
          >Currently
          {{
            node.reviewStatus.docs_open[0].status +
            (node.reviewStatus.hasData ? " (has feedback)" : " (no feedback)")
          }}</span
        >
      </v-tooltip>
      {{ node.name }}
    </div>
    <v-menu
      v-model="contextMenu.show"
      absolute
      :position-x="contextMenu.posX"
      :position-y="contextMenu.posY"
      offset-y
      :close-on-content-click="false"
      nudge-width="300"
    >
      <v-card>
        <v-card-text v-if="contextMenu.busy">
          <v-row>
            <v-col cols="5"></v-col>
            <v-col>
              <v-progress-circular indeterminate size="50" color="grey">
              </v-progress-circular>
            </v-col>
            <v-col cols="5"></v-col>
          </v-row>
        </v-card-text>
        <v-list dense v-else>
          <v-list-item>
            <v-list-item-title
              ><div class="menuSubject">
                {{ contextMenu.title }}
              </div></v-list-item-title
            ></v-list-item
          >
          <v-list-item
            v-for="(a, ai) in contextMenu.actions"
            :key="'cma' + ai"
            @click="doContextMenuAction(a)"
          >
            <v-list-item-icon>
              <v-icon :color="a.colour || 'gray'">{{ a.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ a.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "cHierarchyNodeDisplay",
  props: {
    actions: [],
    node: Object,
    allowExport: Boolean,
    allowExpand: Boolean,
    hideDrilldown: Boolean,
    hideDescriptor: Boolean,
    displayBorder: Boolean,
    displayTitle: Boolean,
    hideEdit: Boolean,
    hideReview: Boolean,
    listviewColumn: Object,
    listviewItem: Object,
    canDelete: Boolean,
  },
  data: function () {
    return {
      contextMenu: {
        show: false,
        busy: false,
        item: null,
        title: null,
        actions: [],
        posX: 0,
        posY: 0,
      },
    };
  },
  components: {},
  watch: {},
  computed: {},
  created: function () {},
  methods: {
    doAction(actionName, action) {
      this.$emit("action", {
        action: actionName,
        actionDef: action,
        node: this.node,
        listviewColumn: this.listviewColumn,
        listviewItem: this.listviewItem,
      });
    },
    openContextMenu_(event) {
      this.$emit("contextmenu", event, this.node);
    },
    openContextMenu(e) {
      e.preventDefault();
      this.contextMenu.show = false;
      this.contextMenu.posX = e.clientX;
      this.contextMenu.posY = e.clientY;
      this.contextMenu.title = this.node.name;
      let actions = this.getActions();
      this.contextMenu.actions = actions;
      this.$nextTick(() => {
        this.contextMenu.show = true;
      });
    },
    doContextMenuAction(action) {
      this.doAction(action.type, action);
      this.contextMenu.show = false;
    },
    getActions() {
      let actions = [];
      if (this.listviewColumn) {
        if (
          this.listviewColumn.filterSettings.values.some(
            (x) => x.selected && x.text === this.node.name
          )
        ) {
          actions.push({
            title: `Remove from Filter`,
            icon: "mdi-filter-minus-outline",
            type: "removeFilter",
            column: null,
          });
        } else {
          actions.push({
            title: `Add to filter`,
            icon: "mdi-filter-plus-outline",
            type: "addFilter",
            column: null,
          });
        }
      }
      if (this.node) {
        if (this.allowExport) {
          actions.push({
            title: "Download Descriptions",
            icon: "mdi-download-box-outline",
            type: "exportNodeDescriptors",
            column: null,
          });
          if (this.node.nextLevel) {
            actions.push({
              title: `Download Descriptions and ${this.node.nextLevel}s`,
              icon: "mdi-download-box",
              type: "exportNodeDescriptorsNested",
              column: null,
            });
          }
        }
        if (
          !this.hideReview &&
          this.node.reviewStatus &&
          this.node.reviewStatus.docsAsParent_open &&
          this.node.reviewStatus.docsAsParent_open.length
        ) {
          actions.push({
            title: this.node.nextLevel + " Review Feedback",
            icon: "mdi-eye-settings-outline",
            type: "showReviewParent",
            colour: this.node.reviewStatus.hasDataAsParent ? "orange" : "blue",
            column: null,
          });
        }
        if (
          !this.hideReview &&
          this.node.reviewStatus &&
          this.node.reviewStatus.docs_open &&
          this.node.reviewStatus.docs_open.length
        ) {
          actions.push({
            title: this.node.label + " Review Feedback",
            icon: "mdi-eye-settings",
            type: "showReview",
            colour: this.node.reviewStatus.hasData ? "orange" : "blue",
            column: null,
          });
        }
        if (this.node.nextLevel) {
          actions.push({
            title: "Initiate Review",
            icon: "mdi-download-box-outline",
            type: "viewNodeDescriptors",
            column: null,
          });
        }
        actions.push({
          title: "Rename ",
          icon: "mdi-pencil",
          type: "rename",
          column: null,
        });
        if (this.node.h_levels?.length)
          this.node.h_levels.forEach((x, xi) => {
            if (
              (xi === 0 && this.node.level !== 1) ||
              (xi === this.node.h_levels.length - 1 &&
                this.node.level === this.node.h_levels.length) ||
              (xi > 0 && xi < this.node.h_levels.length - 1)
              // s  xi !== this.node.h_levels.length - 1
            ) {
              let tt =
                this.node.level === xi + 1 ? `Move ${x}` : `Make into ${x}`;
              actions.push({
                title: tt,
                icon: "mdi-file-move-outline",
                type: "move",
                newLevel: xi + 1,
                newLevelName: x,
                currentLevel: this.node.level,
                currentLevelName: this.node.h_levels[this.node.level - 1],
                column: null,
              });
            }
          });
        actions.push({
          title: "Merge ",
          icon: "mdi-merge",
          type: "merge",
          column: null,
        });
        if (this.canDelete)
          actions.push({
            title: "Delete ",
            icon: "mdi-delete",
            type: "delete",
            column: null,
          });
        if (this.node.canAddDocumentType) {
          actions.push({
            title: "Create " + this.node.canAddDocumentType,
            icon: "mdi-note-plus-outline",
            type: "addHierarchyDocument",
            column: null,
          });
        }
        if (this.node.canAdd && !this.isDemo) {
          actions.push({
            title: "Add new " + this.node.nextLevel,
            icon: "mdi-note-plus-outline",
            type: "add",
            column: null,
          });
        }
        if (this.node.descriptor) {
          actions.push({
            title: "Edit Description",
            icon: "mdi-information",
            type: "editDescriptor",
            column: null,
          });
        }
        // if (this.node.hasHistory) {
          actions.push({
            title: "View History",
            icon: "mdi-delta",
            type: "history",
            column: null,
          });
        // }
      }
      return actions;
    },
  },
};
</script>

<style scoped lang="scss">
.displayBorder {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  width: 100%;
}
.displayTitle {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 36px;
  font-family: "Martel Sans", sans-serif;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  //   width: 100%;
}
.overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //   width: 100%;
}
.selectable {
  cursor: pointer;
}
</style>