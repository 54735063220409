<template>
    <div class="doc-view-font">
        <div v-if="view === 'lookupList'">
            <div style="max-height:600px; overflow:hidden scroll">
                <v-btn small color="primary" @click="addItem" style="margin-bottom:10px">Add New</v-btn>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,idx) in lookupKeys" :key="idx">
                            <td>{{ item.key }}</td>
                            <td>{{ item.type }}</td>
                            <td>
                                <v-icon @click="editLookup(item)">
                                    mdi-cog
                                </v-icon>
                            </td>
                        </tr>
                    </tbody> 
                </v-simple-table>
            </div>
        </div>
        <div v-else-if="view === 'lookupDetail'"  style="height:600px; overflow:hidden scroll">
            <v-btn @click="view = 'lookupList'" color="primary" outlined class="mr-5">
                <v-icon left>mdi-arrow-left</v-icon>Back
            </v-btn>
            <v-row style="padding-top:20px" v-if="editingLookup.isNew">
                <v-col cols="6">
                    <v-text-field label="Lookup Key" v-model="editingLookup.key" @input="selectionValid" dense></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-select label="Lookup Type" v-model="editingLookup.id" @change="selectionValid" :items="lookup_types" item-value="lookup_type_id" item-text="name" dense></v-select>
                </v-col>
            </v-row>
            <v-row style="padding-top:20px" v-else>
                <v-col cols="6">{{ editingLookup.key }}</v-col>
                <v-col cols="6">{{ editingLookup.type }}</v-col>
            </v-row>            
            <v-row style="padding-top:10px" dense v-if="editingLookup.isValid && editingLookup.type === 'Dependent List'">
                <v-col cols="12">
                    <div>
                        <v-btn small color="primary" @click="addNewDependentNode" style="margin-bottom:10px">Add New Item</v-btn>
                        <v-treeview ref="treeview" item-key="lk_id" dense open-all selectable :items="editingLookup.items">
                            <template slot="label" slot-scope="{ item }">
                                <div v-if="item.editing === true" style="display:inline-flex; width: 100%">
                                    <v-text-field dense v-model="item.name" @input="item.isDirty = true;"></v-text-field>
                                    <v-icon title="Cancel" color="grey lighten-2" @click="cancelEditDependent(item)">mdi-close-thick</v-icon>
                                    <v-icon title="Save" color="grey lighten-2" @click="confirmEditDependent(item)">mdi-check-bold</v-icon>
                                </div>
                                <div v-else style="display:inline">
                                    {{ item.name }}
                                </div>
                                <v-btn small icon v-if="item.parent_id === null">
                                    <v-icon @click="addNewTreeItem(item)" small>mdi-subdirectory-arrow-right</v-icon>
                                </v-btn>
                            </template>
                        </v-treeview>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else dense>
                <v-col v-if="editingLookup.isValid" cols="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Value</th>
                                <th>Title</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,idx) in editingLookup.items" :key="idx">
                                <td>
                                    <div v-if="item.editing === true" style="display:inline-flex; width: 100%">
                                        <v-text-field dense v-model="item.value" @input="item.isDirty = true;"></v-text-field>
                                    </div>
                                    <div v-else>
                                        {{ item.value }}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.editing === true" style="display:inline-flex; width: 100%">
                                        <v-text-field dense v-model="item.title" @input="item.isDirty = true;"></v-text-field>
                                    </div>
                                    <div v-else>
                                        {{ item.title ?? "" }}
                                    </div>
                                </td>
                                <td width="250px">
                                    <v-icon v-if="!item.editing" @click="editLookupItem(item)">mdi-cog</v-icon>
                                    <v-icon v-if="item.editing" title="Cancel" @click="cancelEdit(item)">mdi-close-thick</v-icon>
                                    <v-icon v-if="item.editing" title="Save" @click="confirmEdit(item)">mdi-check-bold</v-icon>
                                </td>
                            </tr>
                        </tbody> 
                        <tfoot>
                            <tr>
                                <td colspan="3">
                                    <v-btn small color="primary" @click="addNewLookup">Add New</v-btn>
                                </td>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>

    </div>
</template>
  
<script>
import axios from "axios";

export default {
name: "LookupAdmin",
props: {
},
data: function () {
    return {
        view: "lookupList",
        lookup_types: [],
        lookups: [],
        lookupKeys: [],
        editingLookup: {},
        allowAddSub: false
    };
},
components: {},
created() {
    this.init();
},
watch: {
},
computed: {
},
methods: {
    selectionValid(){
        this.editingLookup.isValid = this.editingLookup.key !== "" && this.editingLookup.id !== null;
    },
    addItem(){
        this.view = "lookupDetail";
        this.editingLookup = {
            key: "",
            type: null,
            id: null,
            items: [],
            isNew: true,
            isValid: false
        }
    },
    baseLookupObj(key, parent, temporaryId){
        return {
            children: [],
            editing: true,
            id: `${parent ?? 0}|${temporaryId}`,
            isDirty: true,
            key: key,
            ld_id: 0,
            lds_id: 0,
            lk_id: 0,
            title: "",
            name: "",
            previousValue: "",
            previousTitle: "",
            value: "",
            parent_id: parent ? parent : null
        }
    },
    addNewDependentNode(){
        let newObj = {
            children: [],
            editing: true,
            id: null,
            isDirty: true,
            key: this.editingLookup.key,
            ld_id: 0,
            lds_id: 0,
            lk_id: 0,
            title: "",
            name: "",
            previousValue: "",
            previousTitle: "",
            value: "",
            parent_id: null
        }
        this.editingLookup.items.push(newObj);
    },
    cancelEditDependent(item){
        item.value = item.previousValue;
        item.title = item.previousTitle;
        item.editing = false;
    },
    confirmEditDependent(item){
        item.editing = false;
        if(item.name === item.previousValue){
            return;
        }

        let depItemType = this.lookup_types.find(x => x.name === "Dependent List Item");
        let depListType = this.lookup_types.find(x => x.name === "Dependent List");

        let req = {};

        if(item.parent_id){
            let parent = this.editingLookup.items.find(x => x.lk_id === item.parent_id);
            if(parent){
                req = {
                    item: {
                        ...item,
                        lookup_type: Number(depItemType.lookup_type_id),
                        key: parent.name,
                        value: item.name
                    },
                    parent: {
                        ...parent,
                        lookup_type_main: Number(depListType.lookup_type_id),
                        lookup_type_link: Number(depItemType.lookup_type_id) 
                    }
                }         
            }
        } else {
            req = {
                item: {
                    ...item,
                    value: item.name,
                    lookup_type: Number(depListType.lookup_type_id),
                    lookup_type_main: Number(depListType.lookup_type_id),
                    lookup_type_link: Number(depItemType.lookup_type_id) 
                },
                parent: null
            }
        }

        axios
        .post("admin/saveDependentLookup/", req)
        .then(resp => {
            console.log(resp);
        })
        .catch(err => {
            console.log(err);
        });   

    },
    addNewLookup(){
        let newObj = {
            editing: true,
            id: 0,
            isDirty: true,
            key: this.editingLookup.key,
            lk_id: 0,
            lookup_type: this.editingLookup.id,
            name: this.editingLookup.key,
            previousValue: "",
            value: ""
        }
        this.editingLookup.items.push(newObj);
    },
    cancelEdit(item){
        item.value = item.previousValue;
        item.title = item.previousTitle;
        item.editing = false;
    },
    confirmEdit(item){
        item.editing = false;
        if(item.value === item.previousValue && item.title === item.previousTitle){
            return;
        }

        if(this.editingLookup.isNew){
            let lookupType = this.lookup_types.find(x => x.lookup_type_id === this.editingLookup.id);
            item.id = Number(lookupType.lookup_type_id);
            item.lookup_type = Number(lookupType.lookup_type_id);
        }

        axios
        .post("admin/saveLookup/", item)
        .then(resp => {
            this.update(resp, item);
        })
        .catch(err => {
            console.log(err);
        });
    },
    update(resp, item){
        if(item.lk_id === 0){
            this.editingLookup.items.splice(this.editingLookup.items.indexOf(item), 1);

            if(this.editingLookup.isNew){
                this.editingLookup.isNew = false;
            }
        }

        let idx = this.editingLookup.items.findIndex(x => x.lk_id === item.lk_id);
        idx > -1 ? this.editingLookup.items.splice(idx, 1, resp.data) : this.editingLookup.items.push(resp.data);
    },
    addNewTreeItem(item){
        //let parent = this.editingLookup.items.find(x => x.lk_id === item.parent_id);

        let newObj = this.baseLookupObj(item.key, item.lk_id, item.children.length);

        /*let cloned = JSON.parse(JSON.stringify(item));
        cloned = {
            ...cloned,
            name: "Testing " + parent.children.length,
            id: parent.children.length,
            lk_id: 0,
            locked: false,
            editing: true,
            previousValue: ""
        }*/
        item.children.push(newObj);
    },
    editLookupItem(item){
        item.editing = true;
    },
    editLookup(item){
        this.view = "lookupDetail";
        this.editingLookup = {
            key: item.key,
            type: item.type,
            id: item.type_id,
            items: item.children,
            isNew: false,
            isValid: true
        }

        if(this.editingLookup.type === 'Dependent List'){
            let placeholder = this.baseLookupObj(item.key, null, item.children.length);
            this.editingLookup.items.push(placeholder);
        }
    },
    init(){
        axios
        .get("admin/clientLookups/")
        .then(resp => {
            this.setup(resp.data)
        })
        .catch(err => {
            console.log(err);
        });
    },
    setup(data){
        this.lookup_types = data.lookup_types;
        this.lookups = data.lookups;
        this.lookupKeys = data.lookups_grouped.filter(x => x.type !== 'Dependent List Item');
    }
},
};
</script>
<style scoped lang="scss">
</style>